import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { graphqlUrl } from 'utils/config';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.js';
import style from 'shopAdminViews/Reviews/Reply.module.scss';

const REPLY_CONVERSATION = gql`
	mutation sendMessage($conversation: ID!, $messageType: MessageType!, $text: String) {
		sendMessage(sendMessageInput: { conversation: $conversation, messageType: $messageType, text: $text }) {
			_id
			text
		}
	}
`;

const Reply = ({ conversation, close, refetch, justView }) => {
	const myRef = useRef(null);

	useEffect(() => {
		myRef.current.scrollIntoView();
	}, []);

	const [text, setText] = useState('');
	const [disableButton, setDisableButton] = useState(false);

	const [sendMessage, { data, error, loading }] = useMutation(REPLY_CONVERSATION);

	const onSend = () => {
		setDisableButton(true);
		sendMessage({
			variables: {
				conversation: conversation._id,
				messageType: 'Text',
				text,
			},
		})
			.then((res) => {
				refetch();
				close();
			})
			.catch((err) => {
				setDisableButton(false);
				console.log(err);
			});
	};

	//   const renderText = text => {
	//       let array = []
	//       while(text.length > 0){
	//           array.push(text.splice(0,30))
	//       }
	//       return array.join('/')
	//   }

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div className={style.title}>
					<div className={style.headerTitle}>{conversation.title}</div>
					{conversation.messages.sort((a, b) => (b.createdAt > a.createdAt ? -1 : 1))[0].user && (
						<div className={style.senderHeader}>
							<span>from {conversation.messages[0].user.fullName} </span>
							{conversation.messages.find((item) => item.senderType === 'Driver') && (
								<span>
									{' '}
									to{' '}
									{conversation.messages.find((item) => item.senderType === 'Driver') &&
										conversation.messages.find((item) => item.senderType === 'Driver').driver
											.fullName}
								</span>
							)}
							{conversation.shop && <span> to {conversation.shop.name}</span>}
						</div>
					)}
					{conversation.messages.sort((a, b) => (b.createdAt > a.createdAt ? -1 : 1))[0].driver && (
						<div className={style.senderHeader}>
							<span>from {conversation.messages[0].driver.fullName} </span>
							{conversation.messages.find((item) => item.senderType === 'User') && (
								<span>
									{' '}
									to{' '}
									{conversation.messages.find((item) => item.senderType === 'User') &&
										conversation.messages.find((item) => item.senderType === 'User').user.fullName}
								</span>
							)}
						</div>
					)}
					<div className={style.headerDate}>{moment(conversation.createdAt).format('MM/DD/YYYY HH:mm')}</div>
				</div>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
			</div>
			<div className={style.chatContainer}>
				{conversation.messages
					.sort((a, b) => (b.createdAt > a.createdAt ? -1 : 1))
					.map((message) => (
						<div
							className={
								style[
									message.senderType === 'Admin'
										? 'adminChatContainer'
										: message.senderType === 'Driver' &&
										  conversation.conversationCategory === 'MESSAGE'
										? 'adminChatContainer'
										: 'userChatContainer'
								]
							}
						>
							<div className={style.chatWrapper}>
								<div className={style.senderName}>
									{message.senderType && message.senderType.toLowerCase()}
									{(message.user && ' (' + message.user.fullName + ') ') ||
										(message.driver && ' (' + message.driver.fullName + ') ')}
								</div>
								<div
									className={
										style[
											message.senderType === 'Admin'
												? 'adminChatmainContainer'
												: message.senderType === 'Driver' &&
												  conversation.conversationCategory === 'MESSAGE'
												? 'adminChatmainContainer'
												: 'chatMainContainer'
										]
									}
								>
									<div
										className={
											style[
												message.senderType === 'Admin'
													? 'adminChat'
													: message.senderType === 'Driver' &&
													  conversation.conversationCategory === 'MESSAGE'
													? 'adminChat'
													: 'userChat'
											]
										}
									>
										{message.messageType === 'Upload' ? (
											<a title="open file" target="_blank" href={`${graphqlUrl}/${message.text}`}>
												<InsertDriveFileIcon style={{ color: 'white' }} />
											</a>
										) : message.messageType === 'Object' && typeof message.text === 'object' ? (
											Object.keys(JSON.parse(message.text)).map((key) => (
												<div>
													<span>{key}</span> :<span>{JSON.parse(message.text)[key]}</span>
												</div>
											))
										) : (
											message.text
										)}
										<span
											className={
												style[
													message.senderType === 'Admin'
														? 'adminMessageDate'
														: message.senderType === 'Driver' &&
														  conversation.conversationCategory === 'MESSAGE'
														? 'adminMessageDate'
														: 'messageDate'
												]
											}
										>
											{moment(message.createdAt).format('MM/DD/YYYY HH:mm')}
										</span>
									</div>
								</div>
							</div>
						</div>
					))}

				<div ref={myRef}></div>
			</div>

			{conversation.conversationCategory === 'SUPPORT_TICKET' && !justView && (
				<div className={style.footerPart}>
					<input
						className={style.input}
						value={text}
						placeholder="type your message..."
						onChange={(e) => setText(e.target.value)}
					/>
					<Button onClick={onSend}>Send</Button>
				</div>
			)}
		</div>
	);
};

export default Reply;
