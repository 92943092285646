import React from 'react';
import { Link } from 'react-router-dom';
import moment, { now } from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import Button from 'components/CustomButtons/Button.js';
import CardBody from 'components/Card/CardBody.js';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import CardFooter from 'components/Card/CardFooter.js';
import style from './Dashboard.module.scss';
import taxiImage from 'assets/img/taxi.jpg';
import usersImage from 'assets/img/users.jpg';
import financeImage from 'assets/img/finance.jpg';
import shopsImage from 'assets/img/shops.jpg';
import tripsImage from 'assets/img/trips.jpg';
import shopVerificationsImage from 'assets/img/stamp.jpg';
import driverVerificationsImage from 'assets/img/cars.jpg';
import conversationsImage from 'assets/img/conversations.jpg';
import failImage from 'assets/img/fail.jpg';

const GET_DRIVRES_COUNT = gql`
	query getDriversCountByAdmin($state: DriverState) {
		getDriversCountByAdmin(filters: { state: $state })
	}
`;

const GET_USERS_COUNT = gql`
	query getUsersByAdminCount($state: UserStateInput) {
		getUsersByAdminCount(filters: { state: $state })
	}
`;

const GET_SHOPS_COUNT = gql`
	query getShopsByAdminCount($active: Boolean) {
		getShopsByAdminCount(filters: { active: $active })
	}
`;

const GET_CATEGORIES = gql`
	query getCategories($limit: Int, $skip: Int, $parent: ID) {
		getCategories(pagination: { limit: $limit, skip: $skip }, filters: { parent: $parent }) {
			_id
			parent {
				_id
			}
			title
		}
	}
`;

const GET_TRIP_FINANCE = gql`
	query getTripOrdersDetailByAdmin($from: Date!, $to: Date!, $tripType: TripType) {
		getTripOrdersDetailByAdmin(filters: { from: $from, to: $to, tripType: $tripType }) {
			commissionForSpark
		}
	}
`;

const GET_SHOP_FINANCE = gql`
	query getOrdersDetailByAdmin($from: Date!, $to: Date!, $rootCategory: ID) {
		getOrdersDetailByAdmin(filters: { from: $from, to: $to, rootCategory: $rootCategory }) {
			benefitForSpark
		}
	}
`;

const GET_DRIVERS_VERIFICATIONS_COUNT = gql`
	query getDriversVerificationRequestsCountByAdmin($status: DriverVerificationRequestsStatus) {
		getDriversVerificationRequestsCountByAdmin(filters: { status: $status })
	}
`;

const GET_VERIFICATIONS_COUNT = gql`
	query getShopsByAdminCount($verified: Boolean) {
		getShopsByAdminCount(filters: {verified: $verified})
	}
`;

const GET_PAYMENTS_COUNT = gql`
	query getPaymentsByAdminCount(
		$_id: ID
		$user: ID
		$driver: ID
		$shop: ID
		$from: Date
		$for: PaymentFor
		$transactionId: String
		$to: Date
		$status: PaymentStatus
		$type: PaymentType
		$amount: Float
	) {
		getPaymentsByAdminCount(
			filters: {
				_id: $_id
				user: $user
				shop: $shop
				driver: $driver
				status: $status
				transactionId: $transactionId
				for: $for
				type: $type
				amount: $amount
				from: $from
				to: $to
			}
		)
	}
`;

const DashBoard = (props) => {
	const { loading: categoriesLoading, error: categoriesError, data: categoriesData } = useQuery(GET_CATEGORIES, {
		variables: {
			limit: 2,
			skip: 0,
			parent: null,
		},
	});

	const { loading, error, data } = useQuery(GET_TRIP_FINANCE, {
		variables: {
			from: moment()
				.subtract(7, 'days')
				.format('YYYY-MM-DD'),
			to: moment().format('YYYY-MM-DD'),
			tripType: 'DELIVERY',
		},
	});

	const { loading: rideLoading, error: rideError, data: rideData } = useQuery(GET_TRIP_FINANCE, {
		variables: {
			from: moment()
				.subtract(7, 'days')
				.format('YYYY-MM-DD'),
			to: moment().format('YYYY-MM-DD'),
			tripType: 'RIDE',
		},
	});

	const { loading: driversCountLoading, error: driversCountError, data: driversCountData } = useQuery(
		GET_DRIVRES_COUNT,
		{
			variables: {
				state: 'ACTIVE',
			},
		}
	);

	const { loading: usersCountLoading, error: usersCountError, data: usersCountData } = useQuery(GET_USERS_COUNT, {
		variables: {
			state: 'ACTIVE',
		},
	});

	const { loading: shopsCountLoading, error: shopsCountError, data: shopsCountData } = useQuery(GET_SHOPS_COUNT, {
		variables: {
			active: true,
		},
	});

	const {
		loading: driverVerificationsCountLoading,
		error: driverVerificationsCountError,
		data: driverVerificationsCountData,
	} = useQuery(GET_DRIVERS_VERIFICATIONS_COUNT, {
		variables: {
			status: 'PENDING',
		},
	});

	const {
		loading: shopVerificationsCountLoading,
		error: shopVerificationsCountError,
		data: shopVerificationsCountData,
	} = useQuery(GET_VERIFICATIONS_COUNT, {variables: {verified: false}});

	const { loading: failedPaymentsLoading, error: failedPaymentsCountError, data: failedPaymentsCountData } = useQuery(
		GET_PAYMENTS_COUNT,
		{
			variables: {
				status: 'FAILED',
			},
		}
	);

	const { loading: restaurantLoading, error: restaurantError, data: restaurantData } = useQuery(GET_SHOP_FINANCE, {
		variables: {
			from: moment()
				.subtract(7, 'days')
				.format('YYYY-MM-DD'),
			to: moment().format('YYYY-MM-DD'),
			rootCategory: categoriesData && categoriesData.getCategories[0]._id,
		},
	});

	const { loading: groceryLoading, error: groceryError, data: groceryData } = useQuery(GET_SHOP_FINANCE, {
		variables: {
			from: moment()
				.subtract(7, 'days')
				.format('YYYY-MM-DD'),
			to: moment().format('YYYY-MM-DD'),
			rootCategory: categoriesData && categoriesData.getCategories[1]._id,
		},
	});

	if (restaurantError) {
		console.log(restaurantError);
	}

	return (
		<div className={style.mainDiv}>
			<GridContainer>
				{restaurantData && categoriesData && (
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card>
							<CardHeader color="warning" stats icon>
								<CardIcon color="warning">
									<AttachMoneyOutlinedIcon />
								</CardIcon>
								<p className={style.title}>weekly sells</p>
								<div className={style.content}>
									<div className={style.innerContent}>
										{restaurantData.getOrdersDetailByAdmin.benefitForSpark}
									</div>
									<AttachMoneyOutlinedIcon />
								</div>
							</CardHeader>
							<CardFooter stats>
								<div>restaurant</div>
							</CardFooter>
						</Card>
					</GridItem>
				)}
				{groceryData && categoriesData && (
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card>
							<CardHeader color="success" stats icon>
								<CardIcon color="grocery">
									<AttachMoneyOutlinedIcon />
								</CardIcon>
								<p className={style.title}>weekly sells</p>
								<div className={style.content}>
									<div className={style.innerContent}>
										{' '}
										{groceryData.getOrdersDetailByAdmin.benefitForSpark}
									</div>
									<AttachMoneyOutlinedIcon />
								</div>
							</CardHeader>
							<CardFooter stats>
								<div>purchase</div>
							</CardFooter>
						</Card>
					</GridItem>
				)}

				{rideData && (
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card>
							<CardHeader color="danger" stats icon>
								<CardIcon color="ride">
									<AttachMoneyOutlinedIcon />
								</CardIcon>
								<p className={style.title}>weekly sells</p>
								<div className={style.content}>
									<div className={style.innerContent}>
										{rideData.getTripOrdersDetailByAdmin.commissionForSpark}
									</div>
									<AttachMoneyOutlinedIcon />
								</div>
							</CardHeader>
							<CardFooter stats>
								<div>ride</div>
							</CardFooter>
						</Card>
					</GridItem>
				)}
				{data && (
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card>
							<CardHeader color="delivery" stats icon>
								<CardIcon color="info">
									<AttachMoneyOutlinedIcon />
								</CardIcon>
								<p className={style.title}>weekly sells</p>
								<div className={style.content}>
									<div className={style.innerContent}>
										{data.getTripOrdersDetailByAdmin.commissionForSpark}
									</div>
									<AttachMoneyOutlinedIcon />
								</div>
							</CardHeader>
							<CardFooter stats>
								<div>delivery</div>
							</CardFooter>
						</Card>
					</GridItem>
				)}
			</GridContainer>
			<GridContainer>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img className={style.cardImage} src={taxiImage} />
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>
									active drivers: {driversCountData && driversCountData.getDriversCountByAdmin}
								</div>
								<Link
									className={style.link}
									style={{ textDecoration: 'none', color: 'white' }}
									to="/superAdmin/drivers"
								>
									<Button color="yellow">drivers</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img className={style.cardImage} src={usersImage} />
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>
									active users: {usersCountData && usersCountData.getUsersByAdminCount}
								</div>
								<Link
									className={style.link}
									style={{ textDecoration: 'none', color: 'white' }}
									to="/superAdmin/customers"
								>
									<Button color="yellow">users</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img className={style.cardImage} src={shopVerificationsImage} />
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>
									<div className={style.innerSentence}>
										{shopVerificationsCountData &&
											shopVerificationsCountData.getShopsByAdminCount}{' '}
										shops verification requests
									</div>
								</div>
								<Link
									className={style.link}
									style={{ textDecoration: 'none', color: 'white' }}
									to="/superAdmin/shopVerifications"
								>
									<Button color="yellow">See all</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img className={style.cardImage} src={driverVerificationsImage} />
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>
									<div className={style.innerSentence}>
										{driverVerificationsCountData &&
											driverVerificationsCountData.getDriversVerificationRequestsCountByAdmin}{' '}
										drivers verification requests
									</div>
								</div>
								<Link
									className={style.link}
									style={{ textDecoration: 'none', color: 'white' }}
									to="/superAdmin/verifications"
								>
									<Button color="yellow">see all</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img className={style.cardImage} src={failImage} />
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>
									<div className={style.innerSentence}>
										{failedPaymentsCountData && failedPaymentsCountData.getPaymentsByAdminCount}{' '}
										failed payments
									</div>
								</div>
								<Link
									className={style.link}
									style={{ textDecoration: 'none', color: 'white' }}
									to="/superAdmin/failedPayments"
								>
									<Button color="yellow">See all</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img className={style.cardImage} src={shopsImage} />
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>
									active shops: {shopsCountData && shopsCountData.getShopsByAdminCount}
								</div>
								<Link
									className={style.link}
									style={{ textDecoration: 'none', color: 'white' }}
									to="/superAdmin/shopsAndRestaurants"
								>
									<Button color="yellow">shops</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img className={style.cardImage} src={financeImage} />
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>checkout all incomes here</div>
								<Link
									className={style.link}
									style={{ textDecoration: 'none', color: 'white' }}
									to="/superAdmin/finance"
								>
									<Button color="yellow">finance</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img className={style.cardImage} src={tripsImage} />
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>
									<div className={style.innerSentence}>see all trips</div>
								</div>
								<Link
									className={style.link}
									style={{ textDecoration: 'none', color: 'white' }}
									to="/superAdmin/trips"
								>
									<Button color="yellow">trips</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img className={style.cardImage} src={conversationsImage} />
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>
									<div className={style.innerSentence}>read and answer messages</div>
								</div>
								<Link
									className={style.link}
									style={{ textDecoration: 'none', color: 'white' }}
									to="/superAdmin/tickets"
								>
									<Button color="yellow">tickets</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default DashBoard;
