import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { endPoint } from 'utils/config';
import moment from 'moment';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ClearIcon from '@material-ui/icons/Clear';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactTable from 'react-table';
import Modal from 'commonComponents/modal/Modal';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Pagination from 'commonComponents/pagination/Pagination';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';
import Checkbox from '@material-ui/core/Checkbox';
import PromotionDetails from 'superAdminViews/ride/promotions/PromotionDetails';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const GET_TRIP_PROMOTIONS = gql`
	query getTripPromotionsByAdmin(
		$limit: Int
		$skip: Int
		$condition: promotionConditionEnum
		$type: promotionTypeEnum
		$fromFrom: Date
		$from: Date
		$to: Date
		$toFrom: Date
		$percentFrom: Float
		$percent: Float
		$promotionCode: String
		$maximumPromotionFrom: Float
		$maximumPromotion: Float
		$percentSort: Float
		$maximumPromotionSort: Float
		$createdAtSort: Int
	) {
		getTripPromotionsByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				condition: $condition
				type: $type
				fromFrom: $fromFrom
				from: $from
				toFrom: $toFrom
				to: $to
				percentFrom: $percentFrom
				percent: $percent
				promotionCode: $promotionCode
				maximumPromotionFrom: $maximumPromotionFrom
				maximumPromotion: $maximumPromotion
			}
			sort: { percent: $percentSort, maximumPromotion: $maximumPromotionSort, createdAt: $createdAtSort }
		) {
			_id
			condition
			type
			canUse
			canNotUse
			percent
			useLimitCount
			maximumPromotion
			promotionCode
			from
			to
		}
	}
`;

const GET_TRIP_PROMOTIONS_COUNT = gql`
	query getTripPromotionsByAdminCount(
		$condition: promotionConditionEnum
		$type: promotionTypeEnum
		$fromFrom: Date
		$from: Date
		$to: Date
		$toFrom: Date
		$percentFrom: Float
		$percent: Float
		$promotionCode: String
		$maximumPromotionFrom: Float
		$maximumPromotion: Float
	) {
		getTripPromotionsByAdminCount(
			filters: {
				condition: $condition
				type: $type
				fromFrom: $fromFrom
				from: $from
				toFrom: $toFrom
				to: $to
				percentFrom: $percentFrom
				percent: $percent
				promotionCode: $promotionCode
				maximumPromotionFrom: $maximumPromotionFrom
				maximumPromotion: $maximumPromotion
			}
		)
	}
`;

const DELETE_PROMOTION = gql`
	mutation deleteTripPromotionByAdmin($idSet: [ID!]!) {
		deleteTripPromotionByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const Promotions = () => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const conditionOptions = ['ALL', 'TIMELY', 'FIRST TRIP'];
	const typeOptions = ['ALL', 'FIXED', 'PERCENT'];

	const [amountSort, setAmountSort] = useState('');
	const [percentSort, setPercentSort] = useState('');
	const [codeFilter, setCodeFilter] = useState('');
	const [amountFromFilter, setAmountFromFilter] = useState('');
	const [amountFilter, setAmountFilter] = useState('');
	const [percentFromFilter, setPercentFromFilter] = useState('');
	const [percentFilter, setPercentFilter] = useState('');
	const [startDateFromFilter, setStartDateFromFilter] = useState('');
	const [startDateFilter, setStartDateFilter] = useState('');
	const [endDateFromFilter, setEndDateFromFilter] = useState('');
	const [endDateFilter, setEndDateFilter] = useState('');
	const [typeFilter, setTypeFilter] = useState(typeOptions[0]);
	const [conditionFilter, setConditionFilter] = useState(conditionOptions[0]);

	const [idsForDelete, setIdsForDelete] = useState([]);
	const [checkAll, setCheckAll] = useState(false);
	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');
	const [startDateFromFilterError, setStartDateFromFilterError] = useState('');
	const [startDateFilterError, setStartDateFilterError] = useState('');
	const [endDateFromFilterError, setEndDateFromFilterError] = useState('');
	const [endDateFilterError, setEndDateFilterError] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [promotionForDelete, setPromotionForDelete] = useState([]);
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [detailsModal, setDetailsModal] = useState(false);
	const [promotionDetail, setPromotionDetail] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectPromotionDetail = (promotion) => {
		setDetailsModal(true);
		setPromotionDetail(promotion);
	};

	const [excelData, setExcelData] = useState([
		['code', 'maximum', 'percent', 'start date', 'end date', 'type', 'condition'],
	]);

	const [getTripPromotionsByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(
		GET_TRIP_PROMOTIONS
	);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getTripPromotionsByAdminCount > 0 &&
			excelData.length === countData.getTripPromotionsByAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getTripPromotionsByAdmin, (item) => [
					item.promotionCode,
					item.maximumPromotion ? item.maximumPromotion.toFixed(2) : '-',
					item.percent ? item.percent : '-',
					moment(item.startDate).format('MM-DD-YYYY'),
					moment(item.endDate).format('MM-DD-YYYY'),
					item.type.replace(/_/g, ' ').toLowerCase(),
					item.condition.replace(/_/g, ' ').toLowerCase(),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Promotions.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getTripPromotionsByAdmin({
				variables: { ...variables, skip: 0, limit: countData.getTripPromotionsByAdminCount },
			});
		}
	};

	const onDeletePromotion = (promotion) => {
		setDeleteModal(true);
		setPromotionForDelete([promotion._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		type: undefined,
		condition: undefined,
		fromFrom: undefined,
		from: undefined,
		toFrom: undefined,
		to: undefined,
		percentFrom: undefined,
		percent: undefined,
		maximumPromotionFrom: undefined,
		maximumPromotion: undefined,
		promotionCode: undefined,
		percentSort: undefined,
		maximumPromotionSort: undefined,
		createdAtSort: -1,
	});

	const [deleteTripPromotionByAdmin] = useMutation(DELETE_PROMOTION);

	const deletePromotion = () => {
		deleteTripPromotionByAdmin({ variables: { idSet: promotionForDelete } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		deleteTripPromotionByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const { loading, error, data, refetch } = useQuery(GET_TRIP_PROMOTIONS, {
		variables: variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_TRIP_PROMOTIONS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getTripPromotionsByAdminCount / perPage) - 1 &&
			countData.getTripPromotionsByAdminCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getTripPromotionsByAdminCount !== 0) {
			setPageCount(Math.ceil(countData.getTripPromotionsByAdminCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getTripPromotionsByAdmin.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	const onAmountSortChange = () => {
		if (!amountSort) {
			setAmountSort('up');
		} else {
			if (amountSort === 'up') {
				setAmountSort('down');
			} else {
				setAmountSort('');
			}
		}
	};
	const onPercentSortChange = () => {
		if (!percentSort) {
			setPercentSort('up');
		} else {
			if (percentSort === 'up') {
				setPercentSort('down');
			} else {
				setPercentSort('');
			}
		}
	};

	useEffect(() => {
		if (!startDateFromFilter) {
			setStartDateFromFilterError('');
		} else {
			if (
				moment(startDateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(startDateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setStartDateFromFilterError('');
			} else {
				setStartDateFromFilterError('invalid date');
			}
		}
	}, [startDateFromFilter]);

	useEffect(() => {
		if (!startDateFilter) {
			setStartDateFilterError('');
		} else {
			if (
				moment(startDateFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(startDateFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setStartDateFilterError('');
			} else {
				setStartDateFilterError('invalid date');
			}
		}
	}, [startDateFilter]);

	useEffect(() => {
		if (!endDateFromFilter) {
			setEndDateFromFilterError('');
		} else {
			if (
				moment(endDateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(endDateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setEndDateFromFilterError('');
			} else {
				setEndDateFromFilterError('invalid date');
			}
		}
	}, [endDateFromFilter]);

	useEffect(() => {
		if (!endDateFilter) {
			setEndDateFilterError('');
		} else {
			if (
				moment(endDateFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(endDateFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setEndDateFilterError('');
			} else {
				setEndDateFilterError('invalid date');
			}
		}
	}, [endDateFilter]);

	useEffect(() => {
		if (percentSort) {
			if (percentSort === 'up') {
				setVariables((variables) => ({ ...variables, percentSort: parseFloat(1) }));
			} else if (percentSort === 'down') {
				setVariables((variables) => ({ ...variables, percentSort: parseFloat(-1) }));
			}
		} else {
			setVariables((variables) => ({ ...variables, percentSort: undefined }));
		}
		if (amountSort) {
			if (amountSort === 'up') {
				setVariables((variables) => ({
					...variables,
					maximumPromotionSort: parseFloat(1),
				}));
			} else if (amountSort === 'down') {
				setVariables((variables) => ({
					...variables,
					maximumPromotionSort: parseFloat(-1),
				}));
			}
		} else {
			setVariables((variables) => ({
				...variables,
				maximumPromotionSort: undefined,
			}));
		}
	}, [amountSort, percentSort]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const linkPage = (route) => {
		window.location.href = endPoint + `/superAdmin/${route}`;
	};

	const submitFilter = () => {
		let hasFilter =
			codeFilter ||
			variables.promotionCode ||
			percentFromFilter ||
			variables.percentFrom ||
			percentFilter ||
			variables.percent ||
			amountFromFilter ||
			variables.maximumPromotionFrom ||
			amountFilter ||
			variables.maximumPromotion ||
			startDateFromFilter ||
			variables.fromFrom ||
			startDateFilter ||
			variables.from ||
			endDateFromFilter ||
			variables.toFrom ||
			endDateFilter ||
			variables.to ||
			!(typeFilter === 'ALL' && !variables.type) ||
			!(conditionFilter === 'ALL' && !variables.condition);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');
			if (codeFilter) {
				setVariables((variables) => ({
					...variables,
					promotionCode: codeFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, promotionCode: undefined }));
			}
			if (percentFromFilter) {
				setVariables((variables) => ({
					...variables,
					percentFrom: parseFloat(percentFromFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, percentFrom: undefined }));
			}
			if (percentFilter) {
				setVariables((variables) => ({
					...variables,
					percent: parseFloat(percentFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, percent: undefined }));
			}
			if (amountFromFilter) {
				setVariables((variables) => ({
					...variables,
					maximumPromotionFrom: parseFloat(amountFromFilter),
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					maximumPromotionFrom: undefined,
				}));
			}
			if (amountFilter) {
				setVariables((variables) => ({
					...variables,
					maximumPromotion: parseFloat(amountFilter),
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					maximumPromotion: undefined,
				}));
			}
			if (
				startDateFromFilter &&
				(moment(startDateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(startDateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					fromFrom: moment(startDateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, fromFrom: undefined }));
			}
			if (
				startDateFilter &&
				(moment(startDateFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(startDateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					from: moment(startDateFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, from: undefined }));
			}
			if (
				endDateFromFilter &&
				(moment(endDateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(endDateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					toFrom: moment(endDateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, toFrom: undefined }));
			}
			if (
				endDateFilter &&
				(moment(endDateFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(endDateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					to: moment(endDateFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, to: undefined }));
			}
			if (conditionFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					condition: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					condition: conditionFilter.replace(/ /g, '_').toUpperCase(),
				}));
			}
			if (typeFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					type: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					type: typeFilter.toUpperCase(),
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			type: undefined,
			condition: undefined,
			fromFrom: undefined,
			from: undefined,
			toFrom: undefined,
			to: undefined,
			percentFrom: undefined,
			percent: undefined,
			maximumPromotionFrom: undefined,
			maximumPromotion: undefined,
			promotionCode: undefined,
			maximumPromotionSort: undefined,
			percentSort: undefined,
			createdAtSort: -1,
		});
		setTypeFilter('ALL');
		setAmountFilter('');
		setPercentFilter('');
		setStartDateFilter('');
		setEndDateFilter('');
		setConditionFilter('ALL');
		setAmountSort('');
		setAmountFromFilter('');
		setPercentFromFilter('');
		setStartDateFromFilter('');
		setEndDateFromFilter('');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			submitFilter();
		}
	};

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	if (data) {
		console.log(data);
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			<div>
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<div className={style.linkButtonDiv}>
							<Button color="yellow" fullWidth onClick={() => linkPage('addPromotion')}>
								Add promotion
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<div>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}
				{idsForDelete.length > 0 && (
					<Button title="delete selected items" color="redButton" onClick={onDeleteSome}>
						Delete
					</Button>
				)}
			</div>
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={submitFilter}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getTripPromotionsByAdminCount === 0 ? 0 : pageCount * perPage + 1}</span>{' '}
							to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getTripPromotionsByAdmin.length)}</span>{' '}
							of <span>{countData.getTripPromotionsByAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0)
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={data.getTripPromotionsByAdmin.map((promotion) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(promotion._id)}
								onChange={() => {
									if (idsForDelete.includes(promotion._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== promotion._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, promotion._id]);
									}
								}}
							/>
						</div>
					),
					code: (
						<CopyToClipboard text={promotion.promotionCode}>
							<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
								{promotion.promotionCode}
							</span>
						</CopyToClipboard>
					),
					type: promotion.type && (
						<div className={`${style.inlineTag} ${style[promotion.type.toLowerCase() + 'Tag']}`}>
							{promotion.type.toLowerCase()}
						</div>
					),
					condition: promotion.condition && (
						<div
							className={`${style.inlineTag} ${
								style[promotion.condition.replace(/_/g, '').toLowerCase() + 'Tag']
							}`}
						>
							{promotion.condition.replace(/_/g, ' ').toLowerCase()}
						</div>
					),
					percent: promotion.percent && promotion.percent + '%',
					maximumPromotion: promotion.maximumPromotion ? '$' + promotion.maximumPromotion : '',
					startDate: moment(promotion.from).format('MM/DD/YYYY HH:mm'),
					endDate: moment(promotion.to).format('MM/DD/YYYY HH:mm'),
					actions: (
						<div className="actions-right">
							<button onClick={() => onSelectPromotionDetail(promotion)} className={style.detailIconDiv}>
								<span>i</span>
							</button>
							<IconButton
								id={promotion._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, promotion._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === promotion._id}
								onClose={handleClose}
							>
								<MenuItem
									onClick={() =>
										(window.location.href = endPoint + `/superAdmin/editPromotion/${promotion._id}`)
									}
								>
									edit
								</MenuItem>
								<MenuItem onClick={() => onDeletePromotion(promotion)}>delete</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getTripPromotionsByAdmin.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getTripPromotionsByAdmin, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Code</div>
								<CustomInput
									value={codeFilter}
									onChange={(e) => setCodeFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'code ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'code',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onAmountSortChange}>
									Maximum{' '}
									<span>
										{amountSort && (
											<span>
												{amountSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={amountFromFilter}
									onChange={(e) => setAmountFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={amountFilter}
									onChange={(e) => setAmountFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'maximumPromotion',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onPercentSortChange}>
									Percent{' '}
									<span>
										{percentSort && (
											<span>
												{percentSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={percentFromFilter}
									onChange={(e) => setPercentFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={percentFilter}
									onChange={(e) => setPercentFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'percent',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.titleDiv}>Start date</div>
								{startDateFromFilterError && (
									<div className={style.dateErrorDiv}>{startDateFromFilterError}</div>
								)}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={startDateFromFilter}
										closeOnSelect
										onChange={(date) => setStartDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{startDateFilterError && (
									<div className={style.dateErrorDiv}>{startDateFilterError}</div>
								)}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={startDateFilter}
										closeOnSelect
										onChange={(date) => setStartDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'startDate',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.titleDiv}>End date</div>
								{endDateFromFilterError && (
									<div className={style.dateErrorDiv}>{endDateFromFilterError}</div>
								)}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={endDateFromFilter}
										closeOnSelect
										onChange={(date) => setEndDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{endDateFilterError && <div className={style.dateErrorDiv}>{endDateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={endDateFilter}
										closeOnSelect
										onChange={(date) => setEndDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'endDate',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Type</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={typeFilter}
										onClick={(e) => setTypeFilter(e)}
										dropdownList={typeOptions}
									/>
								</div>
							</div>
						),
						accessor: 'type',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Condition</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={conditionFilter}
										onClick={(e) => setConditionFilter(e)}
										dropdownList={conditionOptions}
									/>
								</div>
							</div>
						),
						accessor: 'condition',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getTripPromotionsByAdminCount !== 0
							? Math.ceil(countData.getTripPromotionsByAdminCount / perPage)
							: 1
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={detailsModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<PromotionDetails model="trip" close={() => setDetailsModal(false)} promotion={promotionDetail} />
				</DialogContent>
			</Dialog>

			{promotionForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deletePromotion}
							text="Do you want to delete this promotion?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these promotions?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Promotions;
