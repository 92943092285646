import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import style from "layouts/shopAdmin/BankInfo.module.scss";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { endPoint } from "utils/config";
import OrderPaymentForm from "./OrderPaymentForm";
import Button from "components/CustomButtons/Button.js";

const OrderPaymentFormContainer = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [buttonText, setButtonText] = useState("Pay Now");

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [stripeError, setStripeError] = useState(false);
  const [stripeErrorText, setStripeErrorTextText] = useState("");

  let successTimeInterval = useRef(null);

  const onSuccessBack = () => {
    window.location = endPoint + `/payment/success`;
  };

  const onFailBack = () => {
    window.location = endPoint + `/payment/fail`;
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setButtonText("Progressing...");
    setIsButtonDisable(true);
    if (props.for === "order") {
      axios
        .get(`${window.env.API_URL}/api/v1/payment/order/${props.orderId}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            authorization: props.token,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.error) {
            setButtonText("Try Again");
            setIsButtonDisable(false);
            setErrorText(res.data.error.raw.message);
          } else {
            stripe
              .confirmCardPayment(res.data.client_secret, {
                payment_method: {
                  card: elements.getElement(CardElement),
                },
              })
              .then((response) => {
                console.log(response);
                axios
                  .post(
                    `${window.env.API_URL}/api/v1/alter/webhook/paymentIntent/Success`,
                    {
                      metadata: { orderId: props.orderId },
                      paymentIntent: response.paymentIntent.id,
                    },
                    {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        authorization: props.token,
                      },
                    }
                  )
                  .then((resp) => {
                    console.log(resp);
                    onSuccessBack();
                  })
                  .catch((error) => {
                    console.log(error);
                    onFailBack();
                  });
              })
              .catch((error) => {
                console.log(error);
                setButtonText("Try Again");
                setIsButtonDisable(false);
                setStripeError(true);
                setStripeErrorTextText(error.message);
              });
          }
        })
        .catch((err) => {
          setButtonText("Try Again");
          setIsButtonDisable(false);
          console.log(JSON.parse(JSON.stringify(err)));
          if (err.raw && err.error.raw.message) {
            setErrorText(err.error.raw.message);
          }
        });
    } else if (props.for === "trip") {
      axios
        .get(`${window.env.API_URL}/api/v1/payment/trip/${props.tripId}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            authorization: props.token,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.error) {
            setButtonText("Try Again");
            setIsButtonDisable(false);
            setErrorText(res.data.error.raw.message);
          } else {
            stripe
              .confirmCardSetup(res.data.client_secret, {
                payment_method: {
                  card: elements.getElement(CardElement),
                },
              })
              .then((response) => {
                console.log('response from stripe: ', response);
                // vpn problem
                // setErrorText(JSON.stringify(response));
                axios
                  .post(
                    `${window.env.API_URL}/api/v1/alter/webhook/payment/setupIntentSuccess`,
                    {
                      tripId: props.tripId,
                      paymentMethod: response.setupIntent.payment_method,
                    },
                    {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        authorization: props.token,
                      },
                    }
                  )
                  .then((resp) => {
                    console.log(resp);
                    onSuccessBack();
                  })
                  .catch((error) => {
                    console.log(error);
                    onFailBack();
                  });
              })
              .catch((error) => {
                console.log(error);
                setButtonText("Try Again");
                setIsButtonDisable(false);
                setStripeError(true);
                setStripeErrorTextText(error.message);
              });
          }
        })
        .catch((err) => {
          console.log(err);
          setButtonText("Try Again");
          setIsButtonDisable(false);
          if (err.raw && err.error.raw.message) {
            setErrorText(err.error.raw.message);
          }
        });
    } else if (props.for === "tripPay") {
      axios
        .get(`${window.env.API_URL}/api/v1/pay/trip/${props.tripId}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            authorization: props.token,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.error) {
            setButtonText("Try Again");
            setIsButtonDisable(false);
            setErrorText(res.data.error.raw.message);
          } else {
            stripe
              .confirmCardPayment(res.data.client_secret, {
                payment_method: {
                  card: elements.getElement(CardElement),
                },
              })
              .then((response) => {
                console.log(response);
                axios
                  .post(
                    `${window.env.API_URL}/api/v1/alter/webhook/paymentIntent/Success`,
                    {
                      metadata: { tripId: props.tripId },
                      paymentIntent: response.paymentIntent.payment_method,
                    },
                    {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        authorization: props.token,
                      },
                    }
                  )
                  .then((resp) => {
                    console.log(resp);
                    onSuccessBack();
                  })
                  .catch((error) => {
                    console.log(error);
                    onFailBack();
                  });
              })
              .catch((error) => {
                console.log(error);
                setButtonText("Try Again");
                setIsButtonDisable(false);
                setStripeError(true);
                setStripeErrorTextText(error.message);
              });
          }
        })
        .catch((err) => {
          setButtonText("Try Again");
          setIsButtonDisable(false);
          console.log(JSON.parse(JSON.stringify(err)));
          if (err.raw && err.error.raw.message) {
            setErrorText(err.error.raw.message);
          }
        });
    }
  };

  return (
    <div className={style.mainDiv} style={{ marginTop: "50px" }}>
      <GridContainer justify="center">
        <GridItem xs={10} sm={7} md={6} lg={4}>
          <form onSubmit={handleSubmit}>
            {errorText && <div className={style.errorDiv}>{errorText}</div>}
            {stripeError && (
              <div className={style.errorDiv}>{stripeErrorText}</div>
            )}
            <div className={style.cardFormContainer}>
              <OrderPaymentForm
                onChange={(e) => {
                  if (e.empty || e.error || !e.complete) {
                    setIsButtonDisable(true);
                  } else {
                    setIsButtonDisable(false);
                  }
                }}
              />
            </div>

            <Button
              type="submit"
              className={`${style.blueButton} ${style.fullButton}`}
              disabled={!stripe || isButtonDisable}
              color="primary"
            >
              {buttonText}
            </Button>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default OrderPaymentFormContainer;
