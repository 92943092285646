import React , {useContext} from 'react'

import Products from 'commonComponents/products/Products'
import {ThemeContext} from 'index'

const ShopProducts = props => {
    const theme = useContext(ThemeContext)
    return (
        <Products type="shopAdmin" theme={theme} />
    )
}

export default ShopProducts