import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import context from 'utils/context';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import Button from 'components/CustomButtons/Button.js';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CardBody from 'components/Card/CardBody.js';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import CardFooter from 'components/Card/CardFooter.js';
import style from './Dashboard.module.scss';
import addProductImage from 'assets/img/chef.jpg';
import foodImage from 'assets/img/food.jpg';
import basketImage from 'assets/img/productBasket.jpg';
import packing from 'assets/img/packing.jpg';
import ringbell from 'assets/img/ringbell.jpg';
import reviewsImage from 'assets/img/comments.jpg';
import { ThemeContext } from 'index';
import moment from 'moment';

const GET_PAYMENTS = gql`
	query getShopPayments($from: Date, $to: Date, $createdAt: Date) {
		getShopPayments(filters: { from: $from, to: $to, createdAt: $createdAt }) {
			amount
		}
	}
`;

const GET_DAILY_INFO = gql`
	query getSalaryByShopAdmin($paidAt: Int, $limit: Int, $skip: Int) {
		getSalaryByShopAdmin(sort: { paidAt: $paidAt }, pagination: { limit: $limit, skip: $skip }) {
			_id
			numberOfOrders
			income
		}
	}
`;

const GET_ORDERS_COUNT = gql`
	query getOrdersByShopAdmin($status: OrderStatus, $createdAt: Date, $createdAtFrom: Date, $finished: Boolean) {
		getOrdersByShopAdmin(
			filters: { status: $status, createdAt: $createdAt, createdAtFrom: $createdAtFrom, finished: $finished }
		) {
			_id
			status
		}
	}
`;

const GET_PRODUCTS_COUNT = gql`
	query getProductsByShopAdminCount {
		getProductsByShopAdminCount
	}
`;

const GET_COMMENTS_COUNT = gql`
	query getCommentsOnShopByShopAdminCount($fromDate: Date, $toDate: Date, $createdAt: Date) {
		getCommentsOnShopByShopAdminCount(filters: { fromDate: $fromDate, toDate: $toDate, createdAt: $createdAt })
	}
`;

const Dashboard = (props) => {
	const contextOrdersCount = useContext(context).ordersCount;

	const themeContext = useContext(ThemeContext);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
		linkColor: 'white',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
		linkColor: 'slategray',
	};
	const theme = themeContext === 'Purchase' ? greenTheme : orangeTheme;

	const [dailySells, setDailySells] = useState(0);
	const [weeklySells, setWeeklySells] = useState(0);
	const [monthlySells, setMonthlySells] = useState(0);

	const { loading, error, data } = useQuery(GET_DAILY_INFO, {
		variables: {
			paidAt: -1,
			limit: 1,
			skip: 0,
		},
		fetchPolicy: 'network-only',
	});

	const { loading: ordersCountLoading, error: ordersCountError, data: ordersCountData } = useQuery(GET_ORDERS_COUNT, {
		variables: { finished: false },
		fetchPolicy: 'network-only',
	});

	const { loading: ordersLoading, error: ordersError, data: ordersData } = useQuery(GET_ORDERS_COUNT, {
		variables: { createdAtFrom: moment().format('YYYY-MM-DD'), createdAt: moment().format('YYYY-MM-DD') },
		fetchPolicy: 'network-only',
	});

	const { error: paymentsError, loading: paymentsLoading, data: paymentsData } = useQuery(GET_PAYMENTS, {
		fetchPolicy: 'network-only',
		variables: {
			from: moment()
				// .subtract(1, 'days')
				.startOf()
				.format('YYYY-MM-DD'),
			to: moment().format('YYYY-MM-DD'),
		},
	});

	const { error: weeklyPaymentsError, loading: weeklyPaymentsLoading, data: weeklyPaymentsData } = useQuery(
		GET_PAYMENTS,
		{
			variables: {
				from: moment()
					.subtract(7, 'days')
					.format('YYYY-MM-DD'),
				to: moment().format('YYYY-MM-DD'),
			},
			fetchPolicy: 'network-only',
		}
	);

	const { error: monthlyPaymentsError, loading: monthlyPaymentsLoading, data: monthlyPaymentsData } = useQuery(
		GET_PAYMENTS,
		{
			variables: {
				from: moment()
					.subtract(1, 'months')
					.format('YYYY-MM-DD'),
				to: moment().format('YYYY-MM-DD'),
			},
			fetchPolicy: 'network-only',
		}
	);

	const { error: commentsError, loading: commentsLoading, data: commentsData } = useQuery(GET_COMMENTS_COUNT, {
		variables: {
			createdAt: moment().format('YYYY-MM-DD'),
		},
		fetchPolicy: 'network-only',
	});

	if (commentsError) {
		console.log(commentsError);
	}

	useEffect(() => {
		if (paymentsData) {
			let sells = 0;
			paymentsData.getShopPayments.map((item) => (sells = sells + item.amount));
			console.log(sells);
			setDailySells(sells);
		}
	}, [paymentsData, paymentsLoading]);

	useEffect(() => {
		if (weeklyPaymentsData) {
			let sells = 0;
			weeklyPaymentsData.getShopPayments.map((item) => (sells = sells + item.amount));
			console.log(sells);
			setWeeklySells(sells);
		}
	}, [weeklyPaymentsData, weeklyPaymentsLoading]);

	useEffect(() => {
		if (monthlyPaymentsData) {
			let sells = 0;
			monthlyPaymentsData.getShopPayments.map((item) => (sells = sells + item.amount));
			console.log(sells);
			setMonthlySells(sells);
		}
	}, [monthlyPaymentsData, monthlyPaymentsLoading]);

	const { data: productsCountData } = useQuery(GET_PRODUCTS_COUNT);

	return (
		<div className={style.mainDiv}>
			<GridContainer>
				{data && (
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card>
							<CardHeader color="warning" stats icon>
								<CardIcon color={themeContext === 'Purchase' ? 'grocery' : 'warning'}>
									<AttachMoneyOutlinedIcon />
								</CardIcon>
								<p className={style.title}>daily sells</p>
								<div className={style.content}>
									<div className={style.innerContent}>{paymentsData && dailySells.toFixed(2)}</div>
									<AttachMoneyOutlinedIcon />
								</div>
							</CardHeader>
							<CardFooter stats>
								<div>today's sells</div>
							</CardFooter>
						</Card>
					</GridItem>
				)}
				{data && (
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card>
							<CardHeader color="warning" stats icon>
								<CardIcon color={themeContext === 'Purchase' ? 'grocery' : 'warning'}>
									<AttachMoneyOutlinedIcon />
								</CardIcon>
								<p className={style.title}>weekly sells</p>
								<div className={style.content}>
									<div className={style.innerContent}>
										{weeklyPaymentsData && weeklySells.toFixed(2)}
									</div>
									<AttachMoneyOutlinedIcon />
								</div>
							</CardHeader>
							<CardFooter stats>
								<div>this week's sells</div>
							</CardFooter>
						</Card>
					</GridItem>
				)}
				{data && (
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card>
							<CardHeader color="warning" stats icon>
								<CardIcon color={themeContext === 'Purchase' ? 'grocery' : 'warning'}>
									<AttachMoneyOutlinedIcon />
								</CardIcon>
								<p className={style.title}>monthly sells</p>
								<div className={style.content}>
									<div className={style.innerContent}>
										{monthlyPaymentsData && monthlySells.toFixed(2)}
									</div>
									<AttachMoneyOutlinedIcon />
								</div>
							</CardHeader>
							<CardFooter stats>
								<div>this month's sells</div>
							</CardFooter>
						</Card>
					</GridItem>
				)}
				{data && (
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card>
							<CardHeader color="warning" stats icon>
								<CardIcon color={themeContext === 'Purchase' ? 'grocery' : 'warning'}>
									<ShoppingCartIcon />
								</CardIcon>
								<p className={style.title}>daily orders</p>
								<div className={style.content}>
									<div className={style.innerContent}>
										{ordersData && ordersData.getOrdersByShopAdmin.length}
									</div>
									<ShoppingCartIcon />
								</div>
							</CardHeader>
							<CardFooter stats>
								<div>todays orders</div>
							</CardFooter>
						</Card>
					</GridItem>
				)}
			</GridContainer>
			<GridContainer>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img
							className={style.cardImage}
							src={window.localStorage.getItem('shopType') === 'Restaurant' ? addProductImage : packing}
						/>
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>{`add product for your ${
									window.localStorage.getItem('shopType') === 'Restaurant' ? 'Restaurant' : 'shop'
								}`}</div>
								<Link
									className={style.link}
									style={{
										textDecoration: 'none',
										color: `${theme.linkColor}`,
									}}
									to="/shopAdmin/addProduct"
								>
									<Button color={theme.primaryColor}>add product</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img className={style.cardImage} src={ringbell} />
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>
									<div className={style.innerSentence}>
										you have{' '}
										<span className={style.numberSpan}>
											{contextOrdersCount
												? contextOrdersCount
												: ordersCountData &&
												  ordersCountData.getOrdersByShopAdmin.filter(
														(order) =>
															order.status !== 'DELIVERED' && order.status !== 'REJECTED'
												  ).length}
										</span>{' '}
										new orders
									</div>
								</div>
								<Link
									className={style.link}
									style={{
										textDecoration: 'none',
										color: `${theme.linkColor}`,
									}}
									to="/shopAdmin/orders"
								>
									<Button color={theme.primaryColor}>show All</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img
							className={style.cardImage}
							src={window.localStorage.getItem('shopType') === 'Restaurant' ? foodImage : basketImage}
						/>
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>
									<div className={style.innerSentence}>
										you have{' '}
										<span className={style.numberSpan}>
											{productsCountData && productsCountData.getProductsByShopAdminCount}{' '}
										</span>{' '}
										products
									</div>
								</div>

								<Link
									className={style.link}
									style={{
										textDecoration: 'none',
										color: `${theme.linkColor}`,
									}}
									to="/shopAdmin/products"
								>
									<Button color={theme.primaryColor}>show all</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<img className={style.cardImage} src={reviewsImage} />
						<CardBody>
							<div className={style.buttonContainer}>
								<div className={style.sentence}>
									<div className={style.innerSentence}>
										you have{' '}
										<span className={style.numberSpan}>
											{commentsData && commentsData.getCommentsOnShopByShopAdminCount}{' '}
										</span>{' '}
										reviews today!
									</div>
								</div>
								<Link
									className={style.link}
									style={{
										textDecoration: 'none',
										color: `${theme.linkColor}`,
									}}
									to="/shopAdmin/reviews"
								>
									<Button color={theme.primaryColor}>show all</Button>
								</Link>
							</div>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default Dashboard;
