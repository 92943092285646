import React from 'react'

import LoginForm from 'commonComponents/login/Login'

const SuperLogin = prop => {
    return (
        <LoginForm type="superAdmin"/>
    )
}

export default SuperLogin