import React, { useState, useEffect, useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import moment from 'moment';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import Modal from 'commonComponents/modal/Modal';
import BeatLoader from 'react-spinners/BeatLoader';
import ClearIcon from '@material-ui/icons/Clear';
import ReactTable from 'react-table';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import OrderDetails from 'shopAdminViews/orders/OrderDetails';
import Reply from './Reply';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { ThemeContext } from 'index';
import style from 'shopAdminViews/orders/Orders.module.scss';

const GET_CONVERSATIONS = gql`
	query getConversations(
		$limit: Int
		$skip: Int
		$conversationCategory: ConversationCategory
		$conversationType: ConversationType
		$_id: ID
		$user: ID
		$driverName: String
		$passengerName: String
		$driver: ID
		$shop: ID
		$repliedByAdmin: Boolean
		$closed: Boolean
		$title: String
		$createdAtSort: Int
		$createdAtFrom: Date
		$createdAt: Date
	) {
		getConversations(
			Pagination: { limit: $limit, skip: $skip }
			filters: {
				conversationCategory: $conversationCategory
				conversationType: $conversationType
				_id: $_id
				user: $user
				passengerName: $passengerName
				driverName: $driverName
				driver: $driver
				shop: $shop
				repliedByAdmin: $repliedByAdmin
				closed: $closed
				title: $title
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
			}
			sort: { createdAt: $createdAtSort }
		) {
			_id
			title
			closed
			user {
				_id
				fullName
				phoneNumber
			}
			shop {
				_id
				name
				phoneNumbers
			}
			driver {
				_id
				fullName
				phoneNumber
			}
			conversationCategory
			conversationType
			adminUnreadCount
			lastMessage {
				text
			}
			messages {
				_id
				text
				senderType
				user {
					_id
					fullName
				}
				driver {
					_id
					fullName
				}
				messageType
				createdAt
				updatedAt
			}
			createdAt
		}
	}
`;

const GET_CONVERSATIONS_COUNT = gql`
	query getConversationsCount(
		$conversationCategory: ConversationCategory
		$conversationType: ConversationType
		$_id: ID
		$user: ID
		$driverName: String
		$passengerName: String
		$driver: ID
		$shop: ID
		$repliedByAdmin: Boolean
		$closed: Boolean
		$title: String
		$createdAtFrom: Date
		$createdAt: Date
	) {
		getConversationsCount(
			filters: {
				conversationCategory: $conversationCategory
				conversationType: $conversationType
				_id: $_id
				passengerName: $passengerName
				driverName: $driverName
				user: $user
				driver: $driver
				shop: $shop
				repliedByAdmin: $repliedByAdmin
				closed: $closed
				title: $title
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
			}
		)
	}
`;

const ShopConversations = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);
	const themeContext = useContext(ThemeContext);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const theme = themeContext === 'Purchase' ? greenTheme : orangeTheme;

	const typeOptions = ['ALL', 'RIDE', 'DELIVERY', 'FOOD', 'GROCERY', 'SUPPORT', 'SHOP SUPPORT'];
	const categoryOPtions = ['ALL', 'MESSAGE', 'SUPPORT TICKET'];

	const [dateFromFilter, setDateFromFilter] = useState('');
	const [dateFromFilterError, setDateFromFilterError] = useState('');

	const [idFilter, setIdFilter] = useState('');
	const [driverNameFilter, setDriverNameFilter] = useState('');
	const [passengerNameFilter, setPassengerNameFilter] = useState('');
	const [shopAdminIdFilter, setShopAdminIdFilter] = useState('');
	const [titleFilter, setTitleFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');

	const [typeFilter, setTypeFilter] = useState(typeOptions[0]);
	const [dateSort, setDateSort] = useState('down');
	const [categoryFilter, setCategoryFilter] = useState(categoryOPtions[0]);
	const [dateFilterError, setDateFilterError] = useState('');
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [modal, setModal] = useState(false);
	const [detailModal, setDetailModal] = useState(false);
	const [orderDetail, setOrderDetail] = useState();
	const [replyConversation, setReplyConversation] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectReview = (conversation) => {
		setModal(true);
		setReplyConversation(conversation);
	};

	const onSelectOrderDetail = (order) => {
		setDetailModal(true);
		setOrderDetail(order);
	};

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		title: undefined,
		passengerName: undefined,
		driverName: undefined,
		createdAtSort: -1,
		createdAt: undefined,
		repliedByAdmin: true,
		closed: true,
		createdAtFrom: undefined,
	});

	const [excelData, setExcelData] = useState([['customer name', 'driver name', 'title', 'date']]);

	const [getConversations, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(
		GET_CONVERSATIONS
	);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getConversationsCount > 0 &&
			excelData.length === countData.getConversationsCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getConversations, (item) => [
					item.trip
						? item.trip.passengerDetail && item.trip.passengerDetail.fullName
						: item.user
						? item.user.fullName
						: '-',
					item.trip
						? item.trip.driver && item.trip.driver.fullName
						: item.driver
						? item.driver.fullName
						: '-',
					item.title,
					moment(item.createdAt).format('MM-DD-YYYY'),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Tickets.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getConversations({ variables: { ...variables, skip: 0, limit: countData.getConversationsCount } });
		}
	};

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('up');
			}
		}
	};

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}
	}, [dateSort]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getConversationsCount / perPage) - 1 &&
			countData.getConversationsCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getConversationsCount !== 0) {
			setPageCount(Math.ceil(countData.getConversationsCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFromFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	const onSubmitFilter = () => {
		let hasFilter =
			driverNameFilter ||
			variables.driverName ||
			passengerNameFilter ||
			variables.passengerName ||
			dateFromFilter ||
			variables.createdAtFrom ||
			dateFilter ||
			variables.createdAt ||
			titleFilter ||
			variables.title;

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
				console.log(moment(dateFilter).format('YYYY-MM-DD'));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (driverNameFilter) {
				setVariables((variables) => ({
					...variables,
					driverName: driverNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, driverName: undefined }));
			}

			if (passengerNameFilter) {
				setVariables((variables) => ({
					...variables,
					passengerName: passengerNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerName: undefined }));
			}

			if (titleFilter) {
				setVariables((variables) => ({
					...variables,
					title: titleFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, title: undefined }));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setDateFilter('');
		setTitleFilter('');
		setPassengerNameFilter('');
		setDriverNameFilter('');
		setDateSort('down');
		setDateFromFilter('');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			title: undefined,
			createdAtSort: -1,
			createdAt: undefined,
			repliedByAdmin: true,
			closed: true,
			createdAtFrom: undefined,
		});
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	const { loading, error, data, refetch } = useQuery(GET_CONVERSATIONS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_CONVERSATIONS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	if (data) {
		console.log(data);
	}

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onSubmitFilter} color={theme.primaryColor}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getConversationsCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getConversations.length)}</span> of{' '}
							<span>{countData.getConversationsCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0);
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={data.getConversations.map((conversation) => ({
					driverName: conversation.driver && conversation.driver.fullName,
					passengerName: conversation.user && conversation.user.fullName,
					title: conversation.title,
					createdAt: moment(conversation.createdAt).format('MM/DD/YYYY HH:mm'),
					actions: (
						<div className="actions-right">
							<IconButton
								id={conversation._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, conversation._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === conversation._id}
								onClose={handleClose}
							>
								<MenuItem onClick={() => onSelectReview(conversation)}>view messages</MenuItem>
								{conversation.order && (
									<MenuItem onClick={() => onSelectOrderDetail(conversation.order)}>
										order details
									</MenuItem>
								)}
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Customer Name</div>
								<CustomInput
									value={passengerNameFilter}
									onChange={(e) => setPassengerNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Driver Name</div>
								<CustomInput
									value={driverNameFilter}
									onChange={(e) => setDriverNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'driverName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Title</div>
								<CustomInput
									value={titleFilter}
									onChange={(e) => setTitleFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'title ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'title',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Date{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'createdAt',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
						minWidth: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getConversationsCount === 0 ? 1 : Math.ceil(countData.getConversationsCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={detailModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<OrderDetails type={themeContext} close={() => setDetailModal(false)} order={orderDetail} />
				</DialogContent>
			</Dialog>
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Reply
						refetch={() => refetch()}
						close={() => setModal(false)}
						justView={true}
						conversation={replyConversation}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ShopConversations;
