import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Checkbox from '@material-ui/core/Checkbox';
import { css } from '@emotion/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MoonLoader from 'react-spinners/MoonLoader';
import Files from 'react-butterfiles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BarLoader from 'react-spinners/BarLoader';
import XLSX from 'xlsx';
import ReactTable from 'react-table';
import BeatLoader from 'react-spinners/BeatLoader';
import Pagination from 'commonComponents/pagination/Pagination';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Modal from 'commonComponents/modal/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EditBrandAndModel from 'superAdminViews/setting/carOptions/EditBrandAndModel';
import style from 'superAdminViews/setting/defaults/DriverReadyComments.module.scss';

const ADD_BRAND_VIA_EXCEL = gql`
	mutation addCarBrandViaExcel($excelFile: Upload!) {
		addCarBrandViaExcel(excelFile: $excelFile)
	}
`;

const ADD_BRAND = gql`
	mutation createCarBrandByAdmin($name: String!) {
		createCarBrandByAdmin(input: { name: $name }) {
			_id
			name
		}
	}
`;

const GET_BRANDS = gql`
	query getCarBrands($nameSort: Int, $limit: Int, $skip: Int) {
		getCarBrands(sort: { name: $nameSort }, pagination: { limit: $limit, skip: $skip }) {
			_id
			name
			admin {
				_id
			}
		}
	}
`;

const GET_BRANDS_COUNT = gql`
	query getCarBrandsCount {
		getCarBrandsCount
	}
`;

const DELETE_BRAND = gql`
	mutation deleteCarBrandByAdmin($idSet: [ID!]!) {
		deleteCarBrandByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const Brands = () => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);
	const [name, setName] = useState('');

	const [nameError, setNameError] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [addModal, setAddModal] = useState(false);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [brandForDelete, setBrandForDelete] = useState([]);

	const [nameSort, setNameSort] = useState('');

	const onNameSortChange = () => {
		if (!nameSort) {
			setNameSort('up');
		} else {
			if (nameSort === 'up') {
				setNameSort('down');
			} else {
				setNameSort('');
			}
		}
	};

	useEffect(() => {
		if (nameSort) {
			if (nameSort === 'up') {
				setFilterVariables((variables) => ({ ...variables, nameSort: 1 }));
			} else if (nameSort === 'down') {
				setFilterVariables((variables) => ({ ...variables, nameSort: -1 }));
			}
		} else {
			setFilterVariables((variables) => ({ ...variables, nameSort: undefined }));
		}
	}, [nameSort]);

	const [modal, setModal] = useState(false);
	const [brandDetails, setBrandDetails] = useState();

	const [filterVariables, setFilterVariables] = useState({
		nameSort: undefined,
		limit: perPage,
		skip: pageCount * perPage,
	});

	const [
		addCarBrandViaExcel,
		{ data: addViaExcelData, error: addViaExcelError, loading: addViaExcelLoading },
	] = useMutation(ADD_BRAND_VIA_EXCEL);

	const [showMultipleAdd, setShowMultipleAdd] = useState(false);
	const [disableMultiButton, setDisableMultiButton] = useState(false);
	const [excelFile, setExcelFile] = useState('');
	const [excelFileError, setExcelFileError] = useState(false);

	const arrayForTemplateExcel = [['Name'], ['Benz'], ['Mazda']['Kia']];

	const onDownloadTemplate = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(arrayForTemplateExcel);
		XLSX.utils.book_append_sheet(wb, ws, 'Template');
		XLSX.writeFile(wb, 'Spark-Brands-Template.xlsx');
	};

	const uploadExcelFile = (file) => {
		setExcelFile(file.src.file);
	};

	const onAddMultiple = () => {
		setDisableMultiButton(true);
		addCarBrandViaExcel({
			variables: { excelFile: excelFile },
		})
			.then((res) => {
				console.log(res);
				setShowExcelButton('firstStatus');
				setShowMultipleAdd(false);
				setExcelFile('');
				refetch();
				refetchCount();
			})
			.catch((err) => {
				setDisableMultiButton(false);
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setErrorText(err.graphQLErrors[0].message);
					setErrorModal(true);
				}
			});
	};

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const excelHeader = [['name']];
	const [excelData, setExcelData] = useState(excelHeader);

	const [getCarBrands, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_BRANDS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		console.log('excelData: ', excelData);
		console.log('countData: ', countData);
		if (countData && excelData.length === countData.getCarBrandsCount + 1) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([...excelHeader, ...Array.from(lazyData.getCarBrands, (item) => [item.name])]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Brands.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getCarBrands({ variables: { skip: 0, limit: countData.getCarBrandsCount } });
		}
	};

	const onSelectBrandEdit = (brand) => {
		setModal(true);
		setBrandDetails(brand);
	};

	const onDeleteBrand = (brand) => {
		setDeleteModal(true);
		setBrandForDelete([brand._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const [deleteCarBrandByAdmin] = useMutation(DELETE_BRAND);

	const deleteBrand = () => {
		deleteCarBrandByAdmin({ variables: { idSet: brandForDelete } })
			.then((res) => {
				setShowExcelButton('firstStatus');
				setDeleteModal(false);
				refetch();
				refetchCount();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		deleteCarBrandByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setShowExcelButton('firstStatus');
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				refetch();
				refetchCount();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			getCarBrandsFunc().map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage, filterVariables]);

	// testing menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getCarBrandsCount / perPage) - 1 && countData.getCarBrandsCount !== 0) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getCarBrandsCount !== 0) {
			setPageCount(Math.ceil(countData.getCarBrandsCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setFilterVariables((variables) => ({
			...variables,
			skip: perPage * pageCount,
		}));
	}, [pageCount]);

	const { loading, error, data, refetch } = useQuery(GET_BRANDS, {
		variables: filterVariables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData, refetch: refetchCount } = useQuery(
		GET_BRANDS_COUNT,
		{
			fetchPolicy: 'network-only',
		}
	);

	const [createCarBrandByAdmin, { data: addMutationData, error: addMutationError }] = useMutation(ADD_BRAND);

	const onSubmit = () => {
		if (!name) {
			setNameError(true);
		} else {
			setNameError(false);
		}
		if (name) {
			setDisableButton(true);
			createCarBrandByAdmin({ variables: { name: name.trim() } })
				.then((res) => {
					refetch();
					refetchCount();
					setName('');
					setAddModal(true);
					setDisableButton(false);
					setShowExcelButton('firstStatus');
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	const [AZsortState, setAZsortState] = useState(false)
	const AZsort = () => {
		setAZsortState(AZsortState => !AZsortState)
		// console.log(sortByName(data.getCarBrands))
	}
	const sortByName = (arr) => {
		return arr.sort((a, b) => {
		  let nameA = a.name.toUpperCase(); 
		  let nameB = b.name.toUpperCase();
	  
		  if (nameA < nameB) {
			return -1; 
		  }
		  if (nameA > nameB) {
			return 1; 
		  }
		  return 0; 
		});
	}
	const getCarBrandsFunc = () => {
		if(data.getCarBrands) {
			if(!AZsortState) return data.getCarBrands
			else{
				return sortByName(data.getCarBrands)
			}
		}
	}

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<div>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}
				{idsForDelete.length > 0 && (
					<Button title="delete selected items" color="redButton" onClick={onDeleteSome}>
						Delete
					</Button>
				)}
			</div>
			{showMultipleAdd && (
				<div className={style.multipleAddDiv}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<p>upload an excel file like this template.</p>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<div className={style.multipleButtonDiv}>
								<Button fullWidth color="whiteButton" onClick={() => onDownloadTemplate()}>
									<i class="fas fa-cloud-download-alt"></i> download template
								</Button>
							</div>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<Files
								maxSize="10mb"
								multiple={false}
								convertToBase64={true}
								accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
								onSuccess={(file) => uploadExcelFile(file[0])}
								onError={(errors) => {
									setExcelFileError(true);
									console.log(errors);
								}}
							>
								{({ browseFiles, getDropZoneProps, getLabelProps }) => (
									<div style={{ textAlign: 'center' }}>
										{excelFileError && (
											<p
												style={{
													color: 'red',
													fontsize: '9px',
												}}
											>
												please upload an excel file
											</p>
										)}

										<div {...getDropZoneProps({ className: 'myDropZone' })} />
										{excelFile && (
											<Button
												onClick={() => setExcelFile('')}
												size="sm"
												justIcon
												round
												color="danger"
											>
												<CloseIcon />
											</Button>
										)}

										<Button fullWidth onClick={browseFiles} color="whiteButton">
											<i class="fas fa-cloud-upload-alt"></i>{' '}
											{`${excelFile ? 'edit' : 'upload'} excel file`}
										</Button>
									</div>
								)}
							</Files>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<div className={style.multipleButtonDiv}>
								<Button
									fullWidth
									disabled={!excelFile || disableMultiButton}
									color="whiteButton"
									onClick={() => onAddMultiple()}
								>
									{addViaExcelLoading && (
										<MoonLoader
											css={css`
												margin: 0 5px;
											`}
											size={15}
											margin={2}
											color={'gray'}
											loading={true}
										/>
									)}
									Submit
								</Button>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			)}
			{!showMultipleAdd && (
				<div className={style.toggleDiv}>
					<button onClick={() => setShowMultipleAdd(true)}>
						<KeyboardArrowDownIcon />
						<div className={style.buttonSentence}>add all brands with excel file</div>
					</button>
				</div>
			)}
			{showMultipleAdd && (
				<div className={style.toggleDiv}>
					<button onClick={() => setShowMultipleAdd(false)}>
						<KeyboardArrowUpIcon />
						<div className={style.buttonSentence}>hide</div>
					</button>
				</div>
			)}
			<div className={style.inputRow}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={8} lg={8}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={name}
										onChange={(e) => setName(e.target.value)}
										error={nameError}
										labelText={nameError ? "name can't be empty*" : 'name'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={4} lg={4}>
						<div className={style.inputButtonContainer}>
							<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
								Add Brand
							</Button>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={4} lg={4}>
						<div className={style.inputButtonContainer}>
						<Button disabled={disableButton} color="yellow" onClick={AZsort}>
							A-Z
						</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<ReactTable
				data={getCarBrandsFunc().map((brand) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(brand._id)}
								onChange={() => {
									if (idsForDelete.includes(brand._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== brand._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, brand._id]);
									}
								}}
							/>
						</div>
					),
					name: <div className={style.leftAlignCell}>{brand.name}</div>,
					actions: (
						<div>
							<IconButton
								id={brand._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, brand._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === brand._id}
								onClose={handleClose}
								onClick={handleClose}
							>
								<MenuItem onClick={() => onSelectBrandEdit(brand)}>edit</MenuItem>
								<MenuItem onClick={() => onDeleteBrand(brand)}>delete</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div className={style.fitTitleDivCheckbox}>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											getCarBrandsFunc().map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getCarBrands, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: (
							<div className="twoFilter" style={{ height: 'fit-content' }}>
								<div
									className={` ${style.sortDiv}`}
									style={{ justifyContent: 'left' }}
									onClick={onNameSortChange}
								>
									Name{' '}
									<span>
										{nameSort && (
											<span>
												{nameSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
							</div>
						),
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>

			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getCarBrandsCount === 0 ? 1 : Math.ceil(countData.getCarBrandsCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<EditBrandAndModel
						refetch={() => {
							refetch();
							refetchCount();
						}}
						close={() => setModal(false)}
						type="brand"
						item={brandDetails}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text="Brand added succesfully." />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
			{brandForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteBrand}
							text="Do you want to delete this brand?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setIdsForDelete([]);
							setDeleteSomeModal(false);
						}}
						func={deleteSome}
						text="Do you want to delete these brands?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Brands;
