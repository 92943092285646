import React from 'react';
import style from './ErrorPage.module.scss';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';

const ErrorPage = () => {
	const onClick = () => {
		window.location.reload();
	};
	return (
		<div className={style.mainDiv}>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<div className={style.sentence}>Error! couldn't fetch data, please refresh the page or try again later.</div>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<div>
						<button className={style.button} onClick={onClick}>
							<div>
								<RefreshRoundedIcon />
							</div>
							<div>refresh</div>
						</button>
					</div>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default ErrorPage;
