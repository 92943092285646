import React, { useState } from 'react';
import moment from 'moment';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Close from '@material-ui/icons/Close';
import { graphqlUrl } from 'utils/config';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import style from 'superAdminViews/ride/trips/TripDetails.module.scss';
import Slide from '@material-ui/core/Slide';

const VerificationDetails = ({ item, close }) => {
	const profileImages = item && [
		`${graphqlUrl}${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.profileImageUrl}`,
	];
	const [isProfileImagesOpen, setIsProfileImagesOpen] = useState(false);
	const [profileImageIndex, setProfileImageIndex] = useState(0);

	const licenceImages = item && [
		`${graphqlUrl}${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.drivingLicence.photoUrl}`,
	];
	const [isLicenceImagesOpen, setIsLicenceImagesOpen] = useState(false);
	const [licenceImageIndex, setLicenceImageIndex] = useState(0);

	const canadianImages = item && [
		`${graphqlUrl}${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.canadianVerificationPhotoUrl}`,
	];
	const [isCanadianImagesOpen, setIsCanadianImagesOpen] = useState(false);
	const [canadianImageIndex, setCanadianImageIndex] = useState(0);

	const backgroundImages = item && [
		`${graphqlUrl}${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.backgroundCheckDocumentPhotoUrl}`,
	];
	const [isbackgroundImagesOpen, setIsBackgroundImagesOpen] = useState(false);
	const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);

	const recordImages = item && [
		`${graphqlUrl}${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.drivingRecordPhotoUrl}`,
	];
	const [isRecordImagesOpen, setIsRecordImagesOpen] = useState(false);
	const [recordImageIndex, setRecordImageIndex] = useState(0);

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Verification details</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>ID:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{item._id}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Full name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{item.fullName}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Phone number:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>{item.phoneNumber}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Email:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>{item.email}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Gender:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>
										{
											item.verificationRequests[item.verificationRequests.length - 1]
												.verificationDetails.gender
										}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>License expire date:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>
										{moment(
											item.verificationRequests[item.verificationRequests.length - 1]
												.verificationDetails.drivingLicence.expireDate
										).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>License photo:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>
										{String(
											item.verificationRequests[item.verificationRequests.length - 1]
												.verificationDetails.drivingLicence.photoUrl
										).match(/\.pdf$/) ? (
											<a
												title="open file"
												target="_blank"
												href={`${graphqlUrl}/${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.drivingLicence.photoUrl}`}
											>
												<InsertDriveFileIcon style={{ color: 'gray', fontSize: '50px' }} />
											</a>
										) : (
											<img
												onClick={() => setIsLicenceImagesOpen(true)}
												className={style.profileImage}
												src={`${graphqlUrl}${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.drivingLicence.photoUrl}`}
											/>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Canadian verification expire date:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>
										{moment(
											item.verificationRequests[item.verificationRequests.length - 1]
												.verificationDetails.canadianVerificationExpireDate
										).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Canadian verification photo:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>
										{String(
											item.verificationRequests[item.verificationRequests.length - 1]
												.verificationDetails.canadianVerificationPhotoUrl
										).match(/\.pdf$/) ? (
											<a
												title="open file"
												target="_blank"
												href={`${graphqlUrl}/${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.canadianVerificationPhotoUrl}`}
											>
												<InsertDriveFileIcon style={{ color: 'gray', fontSize: '50px' }} />
											</a>
										) : (
											<img
												onClick={() => setIsLicenceImagesOpen(true)}
												className={style.profileImage}
												src={`${graphqlUrl}${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.canadianVerificationPhotoUrl}`}
											/>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Driving record photo:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>
										{String(
											item.verificationRequests[item.verificationRequests.length - 1]
												.verificationDetails.drivingRecordPhotoUrl
										).match(/\.pdf$/) ? (
											<a
												title="open file"
												target="_blank"
												href={`${graphqlUrl}/${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.drivingRecordPhotoUrl}`}
											>
												<InsertDriveFileIcon style={{ color: 'gray', fontSize: '50px' }} />
											</a>
										) : (
											<img
												onClick={() => setIsLicenceImagesOpen(true)}
												className={style.profileImage}
												src={`${graphqlUrl}${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.drivingRecordPhotoUrl}`}
											/>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Background check photo:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>
										{String(
											item.verificationRequests[item.verificationRequests.length - 1]
												.verificationDetails.backgroundCheckDocumentPhotoUrl
										).match(/\.pdf$/) ? (
											<a
												title="open file"
												target="_blank"
												href={`${graphqlUrl}/${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.backgroundCheckDocumentPhotoUrl}`}
											>
												<InsertDriveFileIcon style={{ color: 'gray', fontSize: '50px' }} />
											</a>
										) : (
											<img
												onClick={() => setIsLicenceImagesOpen(true)}
												className={style.profileImage}
												src={`${graphqlUrl}${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.backgroundCheckDocumentPhotoUrl}`}
											/>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>State:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{item.state}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Verification status:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>
										{item.verificationRequests[item.verificationRequests.length - 1].status}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Request date:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>
										{moment(
											item.verificationRequests[item.verificationRequests.length - 1].submitDate
										).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Profile image:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>
										{String(
											item.verificationRequests[item.verificationRequests.length - 1]
												.verificationDetails.profileImageUrl
										).match(/\.pdf$/) ? (
											'pdf'
										) : (
											<img
												onClick={() => setIsProfileImagesOpen(true)}
												className={style.profileImage}
												src={`${graphqlUrl}${item.verificationRequests[item.verificationRequests.length - 1].verificationDetails.profileImageUrl}`}
											/>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			{isProfileImagesOpen && (
				<Lightbox
					mainSrc={profileImages[profileImageIndex]}
					nextSrc={profileImages[(profileImageIndex + 1) % profileImages.length]}
					prevSrc={profileImages[(profileImageIndex + profileImages.length - 1) % profileImages.length]}
					onCloseRequest={() => setIsProfileImagesOpen(false)}
					onMovePrevRequest={() =>
						setProfileImageIndex((profileImageIndex + profileImages.length - 1) % profileImages.length)
					}
					onMoveNextRequest={() => setProfileImageIndex((profileImageIndex + 1) % profileImages.length)}
				/>
			)}
			{isLicenceImagesOpen && (
				<Lightbox
					mainSrc={licenceImages[licenceImageIndex]}
					nextSrc={licenceImages[(licenceImageIndex + 1) % licenceImages.length]}
					prevSrc={licenceImages[(licenceImageIndex + licenceImages.length - 1) % licenceImages.length]}
					onCloseRequest={() => setIsLicenceImagesOpen(false)}
					onMovePrevRequest={() =>
						setLicenceImageIndex((licenceImageIndex + licenceImages.length - 1) % licenceImages.length)
					}
					onMoveNextRequest={() => setLicenceImageIndex((licenceImageIndex + 1) % licenceImages.length)}
				/>
			)}
			{isCanadianImagesOpen && (
				<Lightbox
					mainSrc={canadianImages[canadianImageIndex]}
					nextSrc={canadianImages[(canadianImageIndex + 1) % canadianImages.length]}
					prevSrc={canadianImages[(canadianImageIndex + canadianImages.length - 1) % canadianImages.length]}
					onCloseRequest={() => setIsCanadianImagesOpen(false)}
					onMovePrevRequest={() =>
						setCanadianImageIndex((canadianImageIndex + canadianImages.length - 1) % canadianImages.length)
					}
					onMoveNextRequest={() => setCanadianImageIndex((canadianImageIndex + 1) % canadianImages.length)}
				/>
			)}
			{isbackgroundImagesOpen && (
				<Lightbox
					mainSrc={backgroundImages[backgroundImageIndex]}
					nextSrc={backgroundImages[(backgroundImageIndex + 1) % backgroundImages.length]}
					prevSrc={
						backgroundImages[(backgroundImageIndex + backgroundImages.length - 1) % backgroundImages.length]
					}
					onCloseRequest={() => setIsBackgroundImagesOpen(false)}
					onMovePrevRequest={() =>
						setBackgroundImageIndex(
							(backgroundImageIndex + backgroundImages.length - 1) % backgroundImages.length
						)
					}
					onMoveNextRequest={() =>
						setBackgroundImageIndex((backgroundImageIndex + 1) % backgroundImages.length)
					}
				/>
			)}
			{isRecordImagesOpen && (
				<Lightbox
					mainSrc={recordImages[recordImageIndex]}
					nextSrc={recordImages[(recordImageIndex + 1) % recordImages.length]}
					prevSrc={recordImages[(recordImageIndex + recordImages.length - 1) % recordImages.length]}
					onCloseRequest={() => setIsRecordImagesOpen(false)}
					onMovePrevRequest={() =>
						setRecordImageIndex((recordImageIndex + recordImages.length - 1) % recordImages.length)
					}
					onMoveNextRequest={() => setRecordImageIndex((recordImageIndex + 1) % recordImages.length)}
				/>
			)}
		</div>
	);
};

export default VerificationDetails;
