import React, { useState, useEffect, useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import XLSX from 'xlsx';
import Modal from 'commonComponents/modal/Modal';
import BarLoader from 'react-spinners/BarLoader';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import TransactionDetails from 'superAdminViews/finance/transactions/TransactionDetails';
import ReactTable from 'react-table';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';
import { ThemeContext } from 'index';

const GET_TRANSACTIONS = gql`
	query getShopTransactions(
		$limit: Int
		$skip: Int
		$_id: ID
		$transactionId: String
		$status: PaymentStatus
		$amount: Float
		$paidAt: Date
		$createdAt: Date
		$paidAtSort: Int
		$createdAtSort: Int
		$amountSort: Int
		$createdAtFrom: Date
		$paidAtFrom: Date
	) {
		getShopTransactions(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				status: $status
				amount: $amount
				paidAt: $paidAt
				paidAtFrom: $paidAtFrom
				createdAt: $createdAt
				createdAtFrom: $createdAtFrom
				transactionId: $transactionId
			}
			sort: { paidAt: $paidAtSort, amount: $amountSort, createdAt: $createdAtSort }
		) {
			_id
			transactionId
			status
			paidAt
			type
			amount
			reversed
			payments {
				_id
				for
				transactionId {
					_id
					transactionId
					status
					paidAt
					type
					amount
					reversed
					payments {
						_id
					}
					user {
						_id
					}
					driver {
						_id
					}
					shop {
						_id
					}
					paymentIntent
					createdAt

					updatedAt
				}
				trip {
					_id
				}
				order {
					_id
				}
				status
				description
				createdAt
				amount
				type
			}
			paymentIntent
			createdAt
			updatedAt
		}
	}
`;

const GET_TRANSACTIONS_COUNT = gql`
	query getShopTransactionsCount(
		$_id: ID
		$transactionId: String
		$status: PaymentStatus
		$amount: Float
		$paidAt: Date
		$createdAt: Date
		$createdAtFrom: Date
		$paidAtFrom: Date
	) {
		getShopTransactionsCount(
			filters: {
				_id: $_id
				status: $status
				amount: $amount
				paidAt: $paidAt
				paidAtFrom: $paidAtFrom
				createdAt: $createdAt
				createdAtFrom: $createdAtFrom
				transactionId: $transactionId
			}
		)
	}
`;

const Transactions = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		_id: undefined,
		transactionId: undefined,
		status: undefined,
		amount: undefined,
		paidAt: undefined,
		createdAt: undefined,
		createdAtSort: -1,
		paidAtSort: undefined,
		amountSort: undefined,
		paidAtFrom: undefined,
		createdAt: undefined,
		createdAtFrom: undefined,
	});

	const themeContext = useContext(ThemeContext);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
		buttonColor: 'orange',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
		buttonColor: 'green',
	};
	const theme = themeContext === 'Purchase' ? greenTheme : orangeTheme;

	const typeOptions = ['ALL', 'PAY_TO_SPARK', 'PAY_TO_SHOP', 'PAY_TO_DRIVER'];
	const statusOptions = ['ALL', 'PAID', 'UNPAID', 'FAILED'];

	const [dateFromFilterError, setDateFromFilterError] = useState('');
	const [paidAtFromFilterError, setPaidAtFromFilterError] = useState('');
	const [dateFromFilter, setDateFromFilter] = useState('');
	const [paidAtFromFilter, setPaidAtFromFilter] = useState('');

	const [idFilter, setIdFilter] = useState('');
	const [userIdFilter, setUserIdFilter] = useState('');
	const [driverIdFilter, setDriverIdFilter] = useState('');
	const [shopIdFilter, setShopIdFilter] = useState('');
	const [transactionIdFilter, setTransactionIdFilter] = useState('');
	const [amountFilter, setAmountFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');
	const [paidAtFilter, setPaidAtFilter] = useState('');
	const [typeFilter, setTypeFilter] = useState(typeOptions[0]);
	const [statusFilter, setStatusFilter] = useState(statusOptions[0]);
	const [dateFilterError, setDateFilterError] = useState('');
	const [paidAtFilterError, setPaidAtFilterError] = useState('');

	const [driverIdFilterError, setDriverIdFilterError] = useState(false);
	const [shopIdFilterError, setShopIdFilterError] = useState(false);
	const [userIdFilterError, setUserIdFilterError] = useState(false);
	const [idFilterError, setIdFilterError] = useState(false);
	const [transactionIdFilterError, setTransactionIdFilterError] = useState(false);

	const [dateSort, setDateSort] = useState('down');
	const [amountSort, setAmountSort] = useState('');
	const [paidAtSort, setPaidAtSort] = useState('');
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [transactionDetails, setTransactionDetails] = useState();
	const [modal, setModal] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectTransactionDetails = (transaction) => {
		setTransactionDetails(transaction);
		setModal(true);
	};

	const [excelData, setExcelData] = useState([['transaction ID', 'amount', 'created at', 'paid at', 'status']]);

	const [getShopTransactions, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(
		GET_TRANSACTIONS
	);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getShopTransactionsCount > 0 &&
			excelData.length === countData.getShopTransactionsCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getShopTransactions, (item) => [
					item.transactionId ? item.transactionId : '-',
					item.amount.toFixed(2),
					moment(item.createdAt).format('MM-DD-YYYY'),
					moment(item.paidAt).format('MM-DD-YYYY'),
					item.status.replace(/_/g, ' ').toLowerCase(),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Transactions.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getShopTransactions({ variables: { ...variables, skip: 0, limit: countData.getShopTransactionsCount } });
		}
	};

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('');
			}
		}
	};

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}
	}, [dateSort]);

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFromFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (!paidAtFromFilter) {
			setPaidAtFromFilterError('');
		} else {
			if (
				moment(paidAtFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(paidAtFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setPaidAtFromFilterError('');
			} else {
				setPaidAtFromFilterError('invalid date');
			}
		}
	}, [paidAtFromFilter]);

	useEffect(() => {
		if (!paidAtFilter) {
			setPaidAtFilterError('');
		} else {
			if (
				moment(paidAtFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(paidAtFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setPaidAtFilterError('');
			} else {
				setPaidAtFilterError('invalid date');
			}
		}
	}, [paidAtFilter]);

	useEffect(() => {
		if (paidAtSort) {
			if (paidAtSort === 'up') {
				setVariables((variables) => ({ ...variables, paidAtSort: 1 }));
			} else if (paidAtSort === 'down') {
				setVariables((variables) => ({ ...variables, paidAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, paidAtSort: undefined }));
		}
	}, [paidAtSort]);

	useEffect(() => {
		if (amountSort) {
			if (amountSort === 'up') {
				setVariables((variables) => ({ ...variables, amountSort: 1 }));
			} else if (amountSort === 'down') {
				setVariables((variables) => ({ ...variables, amountSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, amountSort: undefined }));
		}
	}, [amountSort]);

	const onPaidAtSortChange = () => {
		if (!paidAtSort) {
			setPaidAtSort('up');
		} else {
			if (paidAtSort === 'up') {
				setPaidAtSort('down');
			} else {
				setPaidAtSort('');
			}
		}
	};
	const onAmountSortChange = () => {
		if (!amountSort) {
			setAmountSort('up');
		} else {
			if (amountSort === 'up') {
				setAmountSort('down');
			} else {
				setAmountSort('');
			}
		}
	};

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getShopTransactionsCount / perPage) - 1 &&
			countData.getShopTransactionsCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getShopTransactionsCount !== 0) {
			setPageCount(Math.ceil(countData.getShopTransactionsCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	const onSubmitFilter = () => {
		let hasFilter =
			idFilter ||
			variables._id ||
			transactionIdFilter ||
			variables.transactionId ||
			dateFilter ||
			variables.createdAt ||
			paidAtFilter ||
			variables.paidAt ||
			paidAtFromFilter ||
			variables.paidAtFrom ||
			dateFromFilter ||
			variables.createdAtFrom ||
			amountFilter ||
			variables.amount ||
			!(statusFilter === 'ALL' && !variables.status);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');
			if (idFilter && idFilter.length === 24) {
				setVariables((variables) => ({
					...variables,
					_id: idFilter.trim(),
				}));
			} else if (!idFilter) {
				setVariables((variables) => ({ ...variables, _id: undefined }));
			}

			if (transactionIdFilter && transactionIdFilter.length === 24) {
				setVariables((variables) => ({
					...variables,
					transactionId: transactionIdFilter.trim(),
				}));
			} else if (!idFilter) {
				setVariables((variables) => ({ ...variables, transactionId: undefined }));
			}

			if (amountFilter) {
				setVariables((variables) => ({
					...variables,
					amount: parseFloat(amountFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, amount: undefined }));
			}

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
				console.log(moment(dateFilter).format('YYYY-MM-DD'));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (
				paidAtFromFilter &&
				(moment(paidAtFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(paidAtFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					paidAtFrom: moment(paidAtFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, paidAtFrom: undefined }));
			}

			if (
				paidAtFilter &&
				(moment(paidAtFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(paidAtFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					paidAt: moment(paidAtFilter).format('YYYY-MM-DD'),
				}));
				console.log(moment(paidAtFilter).format('YYYY-MM-DD'));
			} else {
				setVariables((variables) => ({ ...variables, paidAt: undefined }));
			}

			if (statusFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					status: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					status: statusFilter.toUpperCase(),
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			_id: undefined,
			transactionId: undefined,
			status: undefined,
			amount: undefined,
			paidAt: undefined,
			paidAtFrom: undefined,
			createdAt: undefined,
			createdAtFrom: undefined,
			createdAtSort: -1,
			paidAtSort: undefined,
			amountSort: undefined,
		});
		setIdFilter('');
		setDateFromFilter('');
		setPaidAtFromFilter('');
		setDateFilter('');
		setTypeFilter('ALL');
		setAmountFilter('');
		setStatusFilter('ALL');
		setTransactionIdFilter('');
		setAmountSort('');
		setDateSort('down');
		setPaidAtFilter('');
		setPaidAtSort('');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	const { loading, error, data, refetch } = useQuery(GET_TRANSACTIONS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_TRANSACTIONS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	// calculate total price
	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (data) {
			let number = 0;
			data.getShopTransactions.map((item) => {
				number = number + item.amount;
			});
			setTotal(number);
		}
	}, [data, loading]);

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onSubmitFilter} color={theme.primaryColor}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getShopTransactionsCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getShopTransactions.length)}</span> of{' '}
							<span>{countData.getShopTransactionsCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0);
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={[
					...data.getShopTransactions.map((transaction) => ({
						id: (
							<CopyToClipboard text={transaction._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{transaction._id}
								</span>
							</CopyToClipboard>
						),
						transactionId: (
							<CopyToClipboard text={transaction.transactionId}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{transaction.transactionId}
								</span>
							</CopyToClipboard>
						),
						user: transaction.user && (
							<CopyToClipboard text={transaction.user._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{transaction.user._id}
								</span>
							</CopyToClipboard>
						),
						driver: transaction.driver && (
							<CopyToClipboard text={transaction.driver._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{transaction.driver._id}
								</span>
							</CopyToClipboard>
						),
						shop: transaction.shop && (
							<CopyToClipboard text={transaction.shop._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{transaction.shop._id}
								</span>
							</CopyToClipboard>
						),
						type: transaction.type,
						status: transaction.status && (
							<div
								className={`${style.inlineTag} ${
									style[transaction.status.replace(/_/g, '').toLowerCase() + 'Tag']
								}`}
							>
								<button
									onClick={() => onSelectTransactionDetails(transaction)}
									className={style.detailIconDiv}
								>
									<span>i</span>
								</button>
								{transaction.status.toLowerCase()}
							</div>
						),
						amount: transaction.amount && <div>{'$' + transaction.amount.toFixed(2)}</div>,
						date: moment(transaction.createdAt).format('MM/DD/YYYY HH:mm'),
						paidAt: transaction.paidAt && moment(transaction.paidAt).format('MM/DD/YYYY HH:mm'),
					})),
					data.getShopTransactions.length > 0 && {
						transactionId: <div className={style.totalNumber}>Total:</div>,
						amount: <div className={style.totalNumber}> {'$' + total.toFixed(2)}</div>,
					},
				]}
				columns={[
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Transaction ID</div>
								<CustomInput
									value={transactionIdFilter}
									onChange={(e) => setTransactionIdFilter(e.target.value)}
									inputProps={{
										placeholder: 'transaction ID',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'transactionId',
						sortable: false,
						filterable: false,
						width: 220,
					},

					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onAmountSortChange}>
									Amount{' '}
									<span>
										{amountSort && (
											<span>
												{amountSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={amountFilter}
									onChange={(e) => setAmountFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={amountFilter}
									onChange={(e) => setAmountFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'amount',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Created at{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
						width: 140,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onPaidAtSortChange}>
									Paid At{' '}
									<span>
										{paidAtSort && (
											<span>
												{paidAtSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{paidAtFromFilterError && (
									<div className={style.dateErrorDiv}>{paidAtFromFilterError}</div>
								)}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={paidAtFromFilter}
										closeOnSelect
										onChange={(date) => setPaidAtFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{paidAtFilterError && <div className={style.dateErrorDiv}>{paidAtFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={paidAtFilter}
										closeOnSelect
										onChange={(date) => setPaidAtFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'paidAt',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},

					{
						Header: (
							<div>
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={statusFilter}
										onClick={(e) => setStatusFilter(e)}
										dropdownList={statusOptions}
									/>
								</div>
							</div>
						),
						accessor: 'status',
						sortable: false,
						filterable: false,
					},
					{
						Header: '',
						accessor: 'fix',
						sortable: false,
						filterable: false,
						width: 0,
					},
				]}
				defaultPageSize={perPage + 1}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getShopTransactionsCount === 0
							? 1
							: Math.ceil(countData.getShopTransactionsCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<TransactionDetails
						close={() => setModal(false)}
						transaction={transactionDetails}
						refetch={() => refetch()}
						hasRefunder={false}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Transactions;
