import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import moment, { now } from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import CircularProgress from '@material-ui/core/CircularProgress';
import Files from 'react-butterfiles';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import GridContainer from 'components/Grid/GridContainer.js';
import { graphqlUrl, endPoint } from 'utils/config';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Datetime from 'react-datetime';
import style from './AddAdvertisement.module.scss';

const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!, $folderName: String!) {
		uploadFile(data: { file: $file, folderName: $folderName }) {
			url
		}
	}
`;

const CREATE_ADVERTISEMENT = gql`
	mutation createAdvertisementByAdmin(
		$title: String!
		$description: String
		$redirectTo: String!
		$photoUrl: String!
		$startAt: Date!
		$endAt: Date!
	) {
		createAdvertisementByAdmin(
			input: {
				title: $title
				description: $description
				redirectTo: $redirectTo
				photoUrl: $photoUrl
				startAt: $startAt
				endAt: $endAt
			}
		) {
			_id
			title
			description
			redirectTo
			photoUrl
			startAt
			endAt
		}
	}
`;

const UPDATE_ADVERTISEMENT = gql`
	mutation updateAdvertisementByAdmin(
		$id: ID!
		$title: String!
		$description: String
		$redirectTo: String!
		$photoUrl: String!
		$startAt: Date!
		$endAt: Date!
	) {
		updateAdvertisementByAdmin(
			id: $id
			input: {
				title: $title
				description: $description
				redirectTo: $redirectTo
				photoUrl: $photoUrl
				startAt: $startAt
				endAt: $endAt
			}
		) {
			updatedAt
		}
	}
`;

const AddAdvertisement = (props) => {
	const [title, setTitle] = useState(props.advertisement ? props.advertisement.title : '');
	const [description, setDescription] = useState(props.advertisement ? props.advertisement.description : '');
	const [redirectTo, setRedirectTo] = useState(props.advertisement ? props.advertisement.redirectTo : '');
	const [startDate, setStartDate] = useState(
		props.advertisement ? moment(props.advertisement.startAt).format('MM/DD/YYYY hh:mm A') : ''
	);
	const [endDate, setEndDate] = useState(
		props.advertisement ? moment(props.advertisement.endAt).format('MM/DD/YYYY hh:mm A') : ''
	);
	const [image, setImage] = useState(props.advertisement ? props.advertisement.photoUrl : '');
	const [disableButton, setDisableButton] = useState(false);
	const [titleError, setTitleError] = useState(false);
	const [descriptionError, setDescriptionError] = useState(false);
	const [redirectToError, setRedirectToError] = useState(false);
	const [startDateError, setStartDateError] = useState(false);
	const [endDateError, setEndDateError] = useState('');
	const [imageError, setImageError] = useState(false);

	const [warningModal, setWarningModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [createAdvertisementByAdmin, { data, error: mutationError }] = useMutation(CREATE_ADVERTISEMENT);

	const [uploadFile, { data: uploadData, error: uploadError, loading: uploadLoading }] = useMutation(UPLOAD_FILE);

	const onUploadImage = (file) => {
		uploadFile({
			variables: { file: file.src.file, folderName: 'advertisementImage' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			setImage(res.data.uploadFile.url);
		});
	};

	const [updateAdvertisementByAdmin, { data: updateData, error: updateError }] = useMutation(UPDATE_ADVERTISEMENT);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			submit();
		}
	};

	// block navigation
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [shouldLeavePage, setShouldLeavePage] = useState(false);

	useEffect(() => {
		if (shouldLeavePage) {
			window.onbeforeunload = undefined;
		} else {
			if (shouldBlockNavigation) {
				window.onbeforeunload = () => true;
			} else {
				window.onbeforeunload = undefined;
			}
		}
	}, [shouldBlockNavigation]);

	useEffect(() => {
		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	useEffect(() => {
		if (props.advertisement) {
			if (
				title === props.advertisement.title &&
				description === props.advertisement.description &&
				moment(startDate).format('YYYY-DD-MM HH:mm') ===
					moment(props.advertisement.startAt).format('YYYY-DD-MM HH:mm') &&
				moment(endDate).format('YYYY-DD-MM HH:mm') ===
					moment(props.advertisement.endAt).format('YYYY-DD-MM HH:mm') &&
				image === props.advertisement.photoUrl &&
				redirectTo === props.advertisement.redirectTo
			) {
				setShouldBlockNavigation(false);
			} else {
				setShouldBlockNavigation(true);
			}
		} else {
			if (title || description || redirectTo || image || startDate || endDate) {
				setShouldBlockNavigation(true);
			} else {
				setShouldBlockNavigation(false);
			}
		}
	});

	const onCancel = () => {
		window.onbeforeunload = undefined;
		setShouldBlockNavigation(false);
		setShouldLeavePage(true);
		window.location.href = endPoint + '/superAdmin/advertisements';
	};

	const submit = () => {
		if (
			props.advertisement &&
			moment().isAfter(moment(props.advertisement.startAt)) &&
			moment().isBefore(moment(props.advertisement.endAt))
		) {
			setWarningModal(true);
		} else {
			onSubmit();
		}
	};

	const onSubmit = () => {
		if (!title) {
			setTitleError(true);
		} else {
			setTitleError(false);
		}
		if (!image) {
			setImageError(true);
		} else {
			setImageError(false);
		}

		if (!redirectTo) {
			setRedirectToError(true);
		} else {
			setRedirectToError(false);
		}
		if (!startDate) {
			setStartDateError("start date can't be empty*");
		} else {
			if (moment(startDate, 'MM/DD/YYYY hh:mm A', true).isValid()) {
				setStartDateError('');
			} else {
				setStartDateError('invalid date');
			}
		}
		if (!endDate) {
			setEndDateError("end date can't be empty*");
		} else {
			if (moment(endDate, 'MM/DD/YYYY hh:mm A', true).isValid()) {
				if (moment(endDate).isAfter(startDate)) {
					setEndDateError('');
				} else {
					setEndDateError('end date shout be after start date');
				}
			} else {
				setEndDateError('invalid date');
			}
		}

		if (
			title &&
			redirectTo &&
			image &&
			startDate &&
			moment(startDate, 'MM/DD/YYYY hh:mm A', true).isValid() &&
			moment(endDate, 'MM/DD/YYYY hh:mm A', true).isValid() &&
			endDate &&
			moment(endDate).isAfter(startDate)
		) {
			setDisableButton(true);
			window.onbeforeunload = undefined;
			setShouldBlockNavigation(false);
			setShouldLeavePage(true);
			if (props.advertisement) {
				updateAdvertisementByAdmin({
					variables: {
						id: props.advertisement._id,
						title,
						description: description ? description : undefined,
						startAt: moment(startDate).utc(),
						endAt: moment(endDate).utc(),
						redirectTo,
						photoUrl: image,
					},
				})
					.then((res) => {
						window.location.href = endPoint + '/superAdmin/advertisements';
					})
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			} else {
				createAdvertisementByAdmin({
					variables: {
						title,
						description: description ? description : undefined,
						startAt: moment(startDate).utc(),
						endAt: moment(endDate).utc(),
						redirectTo,
						photoUrl: image,
					},
				})
					.then((res) => {
						window.location.href = endPoint + '/superAdmin/advertisements';
					})
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}
		}
	};

	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDiv}>
			<Prompt when={shouldBlockNavigation} message="You have unsaved changes, are you sure you want to leave?" />
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Title:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={title}
										onChange={(e) => setTitle(e.target.value)}
										error={titleError}
										labelText={titleError ? "title can't be empty*" : 'title'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Description:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										error={descriptionError}
										labelText={descriptionError ? "description can't be empty*" : 'description'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>

						<div className={style.dateRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.dateLabel}>Start date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									{startDateError && <div className={style.dateError}>{startDateError}</div>}
									<Datetime
										initialViewDate={moment()}
										disableOnClickOutside
										value={moment(startDate)}
										closeOnSelect
										onChange={(date) => setStartDate(date)}
										inputProps={{
											placeholder: 'set start date',
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.dateRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.dateLabel}>End date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									{endDateError && <div className={style.dateError}>{endDateError}</div>}
									<Datetime
										initialViewDate={moment()}
										disableOnClickOutside
										value={moment(endDate)}
										closeOnSelect
										onChange={(date) => setEndDate(date)}
										input={true}
										inputProps={{
											placeholder: 'set end date',
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Link:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={redirectTo}
										onChange={(e) => setRedirectTo(e.target.value)}
										error={redirectToError}
										labelText={redirectToError ? "link can't be empty*" : 'link'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.imageRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.imageLabel}>Photo:</div>
									<div className={style.imageHint}>(upload an image less than 10MB)</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<div className={style.imageContainer}>
										<Files
											maxSize="2mb"
											multiple={false}
											convertToBase64={true}
											accept={['image/jpg', 'image/jpeg', 'image/png']}
											onSuccess={(file) => onUploadImage(file[0])}
											onError={(errors) => setImageError(true)}
										>
											{({ browseFiles, getDropZoneProps, getLabelProps }) => (
												<div style={{ textAlign: 'right' }}>
													{image && (
														<div>
															<img
																style={{ width: '200px', height: '200px' }}
																alt="advertisement photo"
																src={`${graphqlUrl}${image}`}
															/>
														</div>
													)}
													{uploadLoading && <CircularProgress style={{ color: 'gray' }} />}
													<div {...getDropZoneProps({ className: 'myDropZone' })} />
													<Button
														color="whiteButton"
														style={{ marginTop: '15px', marginRight: '10px' }}
														onClick={browseFiles}
													>
														Select Photo...
													</Button>
													{imageError && (
														<p
															style={{
																color: 'red',
																fontSize: '12px',
																fontWeight: '400',
															}}
														>
															please enter image file less than 2mb
														</p>
													)}
												</div>
											)}
										</Files>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.buttonContainer}>
				<Button color="whiteButton" onClick={onCancel}>
					Cancel
				</Button>

				<Button disabled={uploadLoading || disableButton} color="yellow" onClick={submit}>
					Submit
				</Button>
			</div>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={warningModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						text="Are you sure to save the changes? This ad is active now."
						close={() => {
							setWarningModal(false);
						}}
						func={() => {
							onSubmit();
							setWarningModal(false);
						}}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default AddAdvertisement;
