import React, { useEffect, useState } from 'react';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Products from 'superAdminViews/restaurant/shopPage/SuperProducts';
import AddProduct from 'commonComponents/addProduct/AddProduct';
import AddShopMenu from 'superAdminViews/restaurant/shopPage/SuperAddShopMenu';
import SuperOrders from './SuperOrder';
import EditProduct from './EditProdcut';
import style from './ShopPage.module.scss';

const GET_SHOP_BY_ADMIN = gql`
	query getShopByAdmin($id: ID!) {
		getShopByAdmin(id: $id) {
			rootCategory {
				_id
				title
			}
		}
	}
`;

const ShopPage = (props) => {
	const [categoryText, setCategoryText] = useState('Menu');
	const [subCategoryText, setSubCategoryText] = useState('Submenu');

	const { loading: shopSuperLoading, error: shopSuperError, data: shopSuperData } = useQuery(GET_SHOP_BY_ADMIN, {
		variables: { id: props.match.params.id },
	});

	useEffect(() => {
		if (shopSuperData && shopSuperData.getShopByAdmin.rootCategory) {
			if (shopSuperData.getShopByAdmin.rootCategory.title === 'Restaurant') {
				setCategoryText('Menu');
				setSubCategoryText('Submenu');
			} else {
				setCategoryText('Categories');
				setSubCategoryText('Categories');
			}
		}
	}, [shopSuperLoading, shopSuperData]);

	return (
		<div className={style.mainDiv}>
			<div className={style.navContainer}>
				<div>
					<NavLink
						className={style.nav}
						activeClassName={style.activeNav}
						to={`/superAdmin/${props.match.params.shopName}/${props.match.params.id}/subMenus`}
					>
						{`${categoryText} and ${
							shopSuperData &&
							shopSuperData.getShopByAdmin.rootCategory &&
							shopSuperData.getShopByAdmin.rootCategory.title === 'Restaurant'
								? 'foods'
								: 'products'
						}`}
					</NavLink>
				</div>
				<div>
					<NavLink
						className={style.nav}
						activeClassName={style.activeNav}
						to={`/superAdmin/${props.match.params.shopName}/${props.match.params.id}/products`}
					>
						{shopSuperData &&
						shopSuperData.getShopByAdmin.rootCategory &&
						shopSuperData.getShopByAdmin.rootCategory.title === 'Restaurant'
							? 'Foods'
							: 'Products'}{' '}
						list
					</NavLink>
				</div>
				<div>
					<NavLink
						className={style.nav}
						activeClassName={style.activeNav}
						to={`/superAdmin/${props.match.params.shopName}/${props.match.params.id}/orders`}
					>
						Orders
					</NavLink>
				</div>
			</div>
			<div>
				<Switch>
					<Route
						exact={true}
						path="/superAdmin/:shopName/:id/addProductInShopMenu/:subMenuId"
						render={(props) => (
							<AddProduct
								theme="superAdmin"
								type="superAdmin"
								key={Math.random()}
								subMenuId={props.match.params.subMenuId}
								id={props.match.params.id}
								shopName={props.match.params.shopName}
							/>
						)}
					/>
					<Route
						exact={true}
						path="/superAdmin/:shopName/:id/editProduct/:productId"
						render={(props) => (
							<EditProduct
								key={Math.random()}
								productId={props.match.params.productId}
								id={props.match.params.id}
								shopName={props.match.params.shopName}
							/>
						)}
					/>
					<Route
						exact
						path="/superAdmin/:shopName/:id/products"
						render={() => (
							<Products
								key={Math.random()}
								theme="superAdmin"
								type="superAdmin"
								id={props.match.params.id}
								shopName={props.match.params.shopName}
							/>
						)}
					/>
					<Route
						exact
						path="/superAdmin/:shopName/:id/orders"
						render={() => (
							<SuperOrders
								key={Math.random()}
								theme="superAdmin"
								type="superAdmin"
								id={props.match.params.id}
								shopName={props.match.params.shopName}
							/>
						)}
					/>
					<Route
						exact={true}
						path="/superAdmin/:shopName/:id/subMenus"
						render={() => (
							<AddShopMenu
								key={Math.random()}
								theme="superAdmin"
								type="superAdmin"
								id={props.match.params.id}
								shopName={props.match.params.shopName}
							/>
						)}
					/>
					<Redirect from="/superAdmin/:shopName/:id" to="/superAdmin/:shopName/:id/subMenus" />
				</Switch>
			</div>
		</div>
	);
};

export default ShopPage;
