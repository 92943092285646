import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Close from '@material-ui/icons/Close';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Modal from 'commonComponents/modal/Modal';
import style from 'superAdminViews/ride/promotions/PromotionDetails.module.scss';

const UPDATE_PAYMENT = gql`
	mutation manualResolvePayment($_id: ID!) {
		manualResolvePayment(_id: $_id) {
			_id
			status
		}
	}
`;

const PaymentDetails = ({ payment, close, hasResolver, refetch }) => {
	const [modal, setModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [manualResolvePayment, { error, data, loading }] = useMutation(UPDATE_PAYMENT);

	const resolve = () => {
		manualResolvePayment({ variables: { _id: payment._id } })
			.then((res) => {
				console.log(res);
				refetch();
				setModal(false);
				close();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setErrorText(err.graphQLErrors[0].message);
					setErrorModal(true);
				}
			});
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Payment details</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Payment ID:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{
											<CopyToClipboard text={payment._id}>
												<span
													title="Click To Copy To Clipboard"
													className={style.copyClipboard}
												>
													{payment._id}
												</span>
											</CopyToClipboard>
										}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						{payment.shop && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.label}>Shop name:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.value}>{payment.shop.name}</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{payment.driver && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.label}>Driver name:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.value}>
											{payment.driver.fullName}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{payment.amount && !isNaN(payment.amount) && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.label}>Amount:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.value}>${payment.amount.toFixed(2)}</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Created at:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{moment(payment.createdAt).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Updated at:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{moment(payment.updatedAt).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Status:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{payment.status.toLowerCase()}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Type:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{payment.type.replace(/_/g, ' ').toLowerCase()}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>For:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{payment.for.toLowerCase()}</div>
								</GridItem>
							</GridContainer>
						</div>
						{payment.transactionId && payment.transactionId.transactionId && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.label}>Transaction ID:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.value}>
											{
												<CopyToClipboard text={payment.transactionId.transactionId}>
													<span
														title="Click To Copy To Clipboard"
														className={style.copyClipboard}
													>
														{payment.transactionId.transactionId}
													</span>
												</CopyToClipboard>
											}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{payment.trip && payment.trip && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.label}>Trip ID:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.value}>
											{
												<CopyToClipboard text={payment.trip._id}>
													<span
														title="Click To Copy To Clipboard"
														className={style.copyClipboard}
													>
														{payment.trip._id}
													</span>
												</CopyToClipboard>
											}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{payment.order && payment.order && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.label}>Order ID:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.value}>
											{
												<CopyToClipboard text={payment.order._id}>
													<span
														title="Click To Copy To Clipboard"
														className={style.copyClipboard}
													>
														{payment.order._id}
													</span>
												</CopyToClipboard>
											}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Description:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{payment.description}</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.footerShow}>
				<Button color="yellow" onClick={close}>
					Close
				</Button>
				{hasResolver && payment.status !== 'PAID' && <Button color="redButton" onClick={() => setModal(true)}>Resolve</Button>}
			</div>
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setModal(false)} func={resolve} text="Do you want to resolve this payment ?" />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error ! Please Fill All Inputs Correctly And Try Again .'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default PaymentDetails;
