import React from "react";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ErrorPage from 'commonComponents/errorPage/ErrorPage'
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

import AddAdvertisement from "./AddAdvertisement";

const GET_ADVERTISEMENT = gql`
  query getSingleAdvertisement($id: ID!) {
    getSingleAdvertisement(id: $id) {
      _id
      title
      description
      startAt
      endAt
      redirectTo
      photoUrl
    }
  }
`;

const EditAdvertisement = (props) => {
  const { loading, error, data } = useQuery(GET_ADVERTISEMENT, {
    variables: { id: props.match.params.id },
  });

  if (loading)
    return (
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <BeatLoader
          css={css`
            display: block;
            margin: 0 auto;
            border-color: red;
          `}
          size={15}
          margin={2}
          color={"gray"}
          loading={true}
        />
      </div>
    );
  if (error) {
    console.log(error);
    return <ErrorPage/>;
  }
  if (data) {
    console.log(data);
    return (
      <AddAdvertisement
        advertisement={data.getSingleAdvertisement}
      />
    );
  }
};

export default EditAdvertisement;
