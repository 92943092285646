import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Modal from 'commonComponents/modal/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Slide from '@material-ui/core/Slide';
import PaymentDetails from 'superAdminViews/finance/pay/PaymentDetails';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import style from 'superAdminViews/ride/promotions/PromotionDetails.module.scss';

const REFUND_TRANSACTION = gql`
	mutation refundTransactionByAdmin($_id: ID!) {
		refundTransactionByAdmin(_id: $_id) {
			_id
			status
		}
	}
`;

const TransactionDetails = ({ transaction, close, refetch, hasRefunder, hasPaymentsDetails }) => {
	const [paymentDetails, setPaymentDetails] = useState();
	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [modal, setModal] = useState(false);
	const [refundModal, setRefundModal] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectPaymentDetails = (payment) => {
		setPaymentDetails(payment);
		setModal(true);
	};

	const [refundTransactionByAdmin, { error, data, loading }] = useMutation(REFUND_TRANSACTION);

	const refund = () => {
		refundTransactionByAdmin({ variables: { _id: transaction._id } })
			.then((res) => {
				console.log(res);
				refetch();
				setModal(false);
				close();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setErrorText(err.graphQLErrors[0].message);
					setErrorModal(true);
				}
			});
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Transaction details</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Transaction ID:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{
											<CopyToClipboard text={transaction.trnsactionId}>
												<span
													title="Click To Copy To Clipboard"
													className={style.copyClipboard}
												>
													{transaction.transactionId}
												</span>
											</CopyToClipboard>
										}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						{transaction.shop && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.label}>Shop ID:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.value}>
											{
												<CopyToClipboard text={transaction.shop._id}>
													<span
														title="Click To Copy To Clipboard"
														className={style.copyClipboard}
													>
														{transaction.shop._id}
													</span>
												</CopyToClipboard>
											}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{transaction.driver && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.label}>Driver ID:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.value}>
											{
												<CopyToClipboard text={transaction.driver._id}>
													<span
														title="Click To Copy To Clipboard"
														className={style.copyClipboard}
													>
														{transaction.driver._id}
													</span>
												</CopyToClipboard>
											}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{transaction.amount && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.label}>Amount:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.value}>${transaction.amount.toFixed(2)}</div>
									</GridItem>
								</GridContainer>
							</div>
						)}

						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Reversed:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{transaction.reversed ? (
											<div className={style.iconCell}>
												<CheckIcon style={{ fontSize: '15px', color: 'green' }} />
											</div>
										) : (
											<div className={style.iconCell}>
												<CloseIcon style={{ fontSize: '15px', color: 'red' }} />
											</div>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						{transaction.reversalId && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.label}>Reversal ID:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.value}>
											{
												<CopyToClipboard text={transaction.reversalId}>
													<span
														title="Click To Copy To Clipboard"
														className={style.copyClipboard}
													>
														{transaction.reversalId}
													</span>
												</CopyToClipboard>
											}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}

						{transaction.refundId && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.label}>Refund ID:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.value}>
											{
												<CopyToClipboard text={transaction.refundId}>
													<span
														title="Click To Copy To Clipboard"
														className={style.copyClipboard}
													>
														{transaction.refundId}
													</span>
												</CopyToClipboard>
											}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}

						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Paid at:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{moment(transaction.paidAt).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Created at:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{moment(transaction.createdAt).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Updated at:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{moment(transaction.updatedAt).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Status:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{transaction.status && transaction.status.replace(/_/g, ' ').toLowerCase()}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Type:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{transaction.type.replace(/_/g, ' ').toLowerCase()}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Payment intent:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{
											<CopyToClipboard text={transaction.paymentIntent}>
												<span
													title="Click To Copy To Clipboard"
													className={style.copyClipboard}
												>
													{transaction.paymentIntent}
												</span>
											</CopyToClipboard>
										}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<div className={style.label}>Payments:</div>
								</GridItem>
								<GridItem xs={12} sm={12} md={12}>
									{transaction.payments.map((payment) => (
										<div className={style.paymentRow}>
											<GridContainer>
												<GridItem xs={12} sm={9} md={9} lg={9}>
													<div className={style.paymentInfoWrapper}>
														<div className={style.paymentInfo}>
															<GridContainer>
																<GridItem xs={12} sm={12} md={12} lg={12}>
																	<span className={style.paymentId}>
																		<CopyToClipboard text={payment._id}>
																			<span
																				title="Click To Copy To Clipboard"
																				className={style.copyClipboard}
																			>
																				{payment._id}
																			</span>
																		</CopyToClipboard>
																	</span>
																</GridItem>
																<GridItem xs={12} sm={12} md={2} lg={12}>
																	{payment.amount && (
																		<span>${payment.amount.toFixed(2)}</span>
																	)}
																</GridItem>
																<GridItem xs={12} sm={12} md={8} lg={12}>
																	{payment.createdAt && (
																		<span className={style.paymentDate}>
																			{moment(payment.createdAt).format(
																				'MM/DD/YYYY HH:mm'
																			)}
																		</span>
																	)}
																</GridItem>
															</GridContainer>
														</div>
													</div>
												</GridItem>
												<GridItem xs={12} sm={3} md={3} lg={3}>
													<div className={style.iconContainer}>
														<button
															onClick={() => onSelectPaymentDetails(payment)}
															className={style.inlineButton}
														>
															details
														</button>
													</div>
												</GridItem>
											</GridContainer>
										</div>
									))}
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.footer} style={{ display: 'flex' }}>
				<Button color="yellow" onClick={close}>
					Close
				</Button>
				{hasRefunder && transaction.status === 'PAID' && !transaction.refundId && (
					<Button color="redButton" onClick={() => setRefundModal(true)}>
						Refund
					</Button>
				)}
			</div>
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<PaymentDetails close={() => setModal(false)} payment={paymentDetails} />
				</DialogContent>
			</Dialog>
			<Dialog open={refundModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setRefundModal(false)}
						func={refund}
						text="Do you want to refund this transaction ?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error ! Please Fill All Inputs Correctly And Try Again .'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default TransactionDetails;
