import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Close from '@material-ui/icons/Close';
import style from './EditItem.module.scss';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Modal from 'commonComponents/modal/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

const DELETE_LEGAL = gql`
	mutation removeLegalByAdmin($id: ID!) {
		removeLegalByAdmin(id: $id) {
			message
		}
	}
`;

const DELETE_HELP = gql`
	mutation removeHelpByAdmin($id: ID!) {
		removeHelpByAdmin(id: $id) {
			message
		}
	}
`;

const UPDATE_DRIVER_READY_COMMENT = gql`
	mutation updateDriverReadyCommentByAdmin($id: ID!, $type: String!) {
		updateDriverReadyCommentByAdmin(id: $id, input: { type: $type }) {
			updatedAt
			_id
			type
		}
	}
`;

const UPDATE_PASSENGER_READY_COMMENT = gql`
	mutation updatePassengerReadyCommentByAdmin($id: ID!, $type: String!) {
		updatePassengerReadyCommentByAdmin(id: $id, input: { type: $type }) {
			updatedAt
			_id
			type
		}
	}
`;

const UPDATE_SHOP_READY_COMMENT = gql`
	mutation updateShopReadyCommentByAdmin($id: ID!, $type: String!) {
		updateShopReadyCommentByAdmin(id: $id, input: { type: $type }) {
			updatedAt
			_id
			type
		}
	}
`;

const UPDATE_CANCEL_TRIP_REASON = gql`
	mutation updateCanceledTripReasonByAdmin(
		$id: ID!
		$title: String!
		$when: CanceledTripReasonWhen!
		$by: CanceledTripReasonBy!
		$type: CanceledTripReasonType!
	) {
		updateCanceledTripReasonByAdmin(id: $id, input: { title: $title, when: $when, by: $by, type: $type }) {
			updatedAt
			_id
			title
			when
			by
			type
		}
	}
`;

const UPDATE_READY_MESSAGE = gql`
	mutation updateReadyMessageByAdmin($id: ID!, $message: String!, $order: Int!, $type: ReadyMessageType!) {
		updateReadyMessageByAdmin(id: $id, input: { message: $message, order: $order, type: $type }) {
			updatedAt
			_id
			message
			order
			type
		}
	}
`;

const UPDATE_PARCEL_VOLUME = gql`
	mutation updateParcelVolumeByAdmin(
		$id: ID!
		$name: String!
		$value: Float!
		$order: Int
		$type: ParcelVolumeTypeOfAttribute!
	) {
		updateParcelVolumeByAdmin(
			id: $id
			input: { name: $name, value: $value, order: $order, typeOfAttribute: $type }
		) {
			updatedAt
			_id
			name
			value
			order
			typeOfAttribute
		}
	}
`;

const UPDATE_PARCEL_WEIGHT = gql`
	mutation updateParcelWeightByAdmin(
		$id: ID!
		$name: String!
		$value: Float!
		$order: Int
		$type: ParcelWeightTypeOfAttribute!
	) {
		updateParcelWeightByAdmin(
			id: $id
			input: { name: $name, value: $value, order: $order, typeOfAttribute: $type }
		) {
			updatedAt
			_id
			name
			value
			order
			typeOfAttribute
		}
	}
`;

const UPDATE_DRIVER_HOW_IT_WORKS = gql`
	mutation updateDriverHowItWorksByAdmin($id: ID!, $title: String!, $description: String!) {
		updateDriverHowItWorksByAdmin(id: $id, input: { title: $title, description: $description }) {
			_id
			title
			updatedAt
		}
	}
`;
const UPDATE_LEGAL = gql`
	mutation updateLegalByAdmin($id: ID!, $title: String!, $description: String!, $order: Int) {
		updateLegalByAdmin(id: $id, input: { title: $title, description: $description, order: $order }) {
			_id
			title
			updatedAt
		}
	}
`;

const UPDATE_HELP = gql`
	mutation updateHelpByAdmin(
		$id: ID!
		$title: String!
		$description: String!
		$name: HelpName!
		$type: HelpType!
		$order: Int
	) {
		updateHelpByAdmin(
			id: $id
			input: { title: $title, description: $description, name: $name, type: $type, order: $order }
		) {
			_id
			title
			name
			type
			updatedAt
		}
	}
`;

const EditItem = ({ comment, type, close, refetch }) => {
	var currentText;
	if (type === 'driverReadyComment' || type === 'shopReadyComment' || type === 'passengerReadyComment') {
		currentText = comment.type;
	} else if (type === 'cancelTrip') {
		currentText = comment.title;
	} else if (type === 'readyMessage') {
		currentText = comment.message;
	} else if (type === 'driverHowItWorks' || type === 'legal' || type === 'help') {
		currentText = comment.description;
	}
	const [text, setText] = useState(currentText);

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const typeOptionsAlt = { TAXI: 'ride', DELIVERY: 'delivery' };
	const typeOptionsAlternate = { ride: 'TAXI', delivery: 'DELIVERY' };

	const helpTypeOptionsAlt = {
		TAXI: 'ride',
		DELIVERY: 'delivery',
		FOOD: 'food',
		DRIVER_RIDE: 'driver ride',
		DRIVER_DELIVERY: 'driver delivery',
		GROCERY: 'shop',
	};
	const helpTypeOptionsAlternate = {
		ride: 'TAXI',
		delivery: 'DELIVERY',
		food: 'FOOD',
		'driver ride': 'DRIVER_RIDE',
		'driver delivery': 'DRIVER_DELIVERY',
		shop: 'GROCERY',
	};

	const [driverHowItWorksName, setDriverHowItWorksName] = useState(
		(type === 'driverHowItWorks' || type === 'legal' || type === 'help') && comment.name
	);
	const [by, setBy] = useState(comment.by);
	const [when, setWhen] = useState(comment.when);
	const [cancelType, setCancelType] = useState(type === 'cancelTrip' && comment.type);
	const [typeValue, setTypeValue] = useState(type === 'readyMessage' && typeOptionsAlt[comment.type]);

	const [textError, setTextError] = useState(false);
	const [orderError, setorderError] = useState(false);
	const [parcelOrderError, setParcelOrderError] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [valueError, setValueError] = useState(false);
	const [titleError, setTitleError] = useState(false);

	const [title, setTitle] = useState(
		(type === 'driverHowItWorks' || type === 'legal' || type === 'help') && comment.title
	);
	const [helpType, setHelpType] = useState(type === 'help' && helpTypeOptionsAlt[comment.type]);
	const [order, setOrder] = useState(
		(type === 'readyMessage' || type === 'legal' || type === 'help') && comment.order
	);
	const [parcelOrder, setParcelOrder] = useState(
		(type === 'parcelVolume' || type === 'parcelWeight') && comment.order
	);
	const [value, setValue] = useState((type === 'parcelVolume' || type === 'parcelWeight') && comment.value);
	const [name, setName] = useState((type === 'parcelVolume' || type === 'parcelWeight') && comment.name);
	const [parcelType, setParcelType] = useState(
		(type === 'parcelVolume' || type === 'parcelWeight') && comment.typeOfAttribute
	);

	const whenOptions = ['BEFORE_PICK_UP', 'DURING_TRIP'];
	const byOptions = ['DRIVER', 'PASSENGER', 'ADMIN'];
	const parcelTypeOptions = ['PERCENTAGE', 'NUMBER'];
	const typeOptions = ['DELIVERY', 'RIDE'];
	const typeValueOptions = ['DELIVERY', 'TAXI'];

	const nameOptions = [
		'ACCEPT_A_TRIP',
		'REPORT_A_PROBLEM',
		'PRIVACY_POLICY',
		'CUSTOMER_SERVICE',
		'SOFTWARE_LICENCE',
		'PAYMENT_ISSUES',
	];
	const helpTypeOtions = ['TAXI', 'DELIVERY', 'FOOD', 'DRIVER_RIDE', 'DRIVER_DELIVERY', 'GROCERY'];

	const helpNameOptions = [
		'I_WANT_TO_REPORT_A_SERVICE_ANIMAL_ISSUE',
		'I_LOST_AN_ITEM',
		'I_WAS_INVOLVED_IN_AN_ACCIDENT',
		'LEARN_ABOUT_SPARK',
		'ACCOUNT_AND_PAYMENT',
		'RIDE_GUIDE',
		'FOOD_GUIDE',
		'GROCERY_SHOPPING_GUIDE',
		'DELIVERY_GUIDE',
		'MY_PARCEL_WAS_NOT_DELIVERED',
		'MY_PARCEL_IS_MISSING_AN_ITEM',
		'MY_DELIVERY_HAS_BEEN_DELAYED',
		'DELIVERY_COSTED_MORE_THAN_ESTIMATED',
		'MY_PACKAGE_WAS_OPENED',
		'OTHER_ISSUES',
		'I_LOST_AN_ITEM_DELIVERY',
		'FOOD_MY_ORDER_COSTED_MORE_THAN_ESTIMATED',
		'FOOD_MY_ORDER_HAS_BEEN_DELAYED',
		'FOOD_MY_ORDER_WAS_DIFFERENT',
		'FOOD_OTHER_ISSUES',
		'GROCERY_MY_ORDER_COSTED_MORE_THAN_ESTIMATED',
		'GROCERY_MY_ORDER_HAS_BEEN_DELAYED',
		'GROCERY_MY_ORDER_WAS_DIFFERENT',
		'GROCERY_OTHER_ISSUES',
		'DRIVER_RIDE_HELP',
		'DRIVER_DELIVERY_HELP',
	];

	const [removeLegalByAdmin, { data: deleteLegalData, error: deleteLegalError }] = useMutation(DELETE_LEGAL);

	const [removeHelpByAdmin, { data: deleteHelpData, error: deleteHelpError }] = useMutation(DELETE_HELP);

	const [
		updateDriverReadyCommentByAdmin,
		{ data: updateDriverReadyCommentData, error: updateDriverReadyCommentError },
	] = useMutation(UPDATE_DRIVER_READY_COMMENT);

	const [
		updatePassengerReadyCommentByAdmin,
		{ data: updatePassengerReadyCommentData, error: updatePassengerReadyCommentError },
	] = useMutation(UPDATE_PASSENGER_READY_COMMENT);

	const [
		updateShopReadyCommentByAdmin,
		{ data: updateShopReadyCommentData, error: updateShopReadyCommentError },
	] = useMutation(UPDATE_SHOP_READY_COMMENT);

	const [
		updateCanceledTripReasonByAdmin,
		{ data: updateCancelTripReasonData, error: updateCancelTripReasonError },
	] = useMutation(UPDATE_CANCEL_TRIP_REASON);

	const [updateReadyMessageByAdmin, { data: updateReadyMessageData, error: updateReadyMessageError }] = useMutation(
		UPDATE_READY_MESSAGE
	);

	const [updateParcelVolumeByAdmin, { data: updateParcelVolumeData, error: updateParcelVolumeError }] = useMutation(
		UPDATE_PARCEL_VOLUME
	);

	const [updateParcelWeightByAdmin, { data: updateParcelWeightData, error: updateParcelWeightError }] = useMutation(
		UPDATE_PARCEL_WEIGHT
	);

	const [
		updateDriverHowItWorksByAdmin,
		{ data: updateDriverHowItWorksData, error: updateDriverHowItWorksError },
	] = useMutation(UPDATE_DRIVER_HOW_IT_WORKS);

	const [updateLegalByAdmin, { data: updateLegalData, error: updateLegalError }] = useMutation(UPDATE_LEGAL);

	const [updateHelpByAdmin, { data: updateHelpData, error: updateHelpError }] = useMutation(UPDATE_HELP);

	const onDelete = () => {
		if (type === 'legal') {
			removeLegalByAdmin({ variables: { id: comment._id } });
			refetch();
			close();
		}
		if (type === 'help') {
			removeHelpByAdmin({ variables: { id: comment._id } });
			refetch();
			close();
		}
	};

	const onChange = () => {
		if (!text) {
			setTextError(true);
		} else {
			setTextError(false);
		}
		if (type === 'readyMessage' && !order) {
			setorderError(true);
		} else {
			setorderError(false);
		}
		if ((type === 'driverHowItWorks' || type === 'legal' || type === 'help') && !title) {
			setTitleError(true);
		} else {
			setTitleError(false);
		}
		if (type === 'driverReadyComment' && text) {
			updateDriverReadyCommentByAdmin({
				variables: { id: comment._id, type: text },
			})
				.then((res) => {
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (type === 'passengerReadyComment' && text) {
			updatePassengerReadyCommentByAdmin({
				variables: { id: comment._id, type: text },
			})
				.then((res) => {
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (type === 'shopReadyComment' && text) {
			updateShopReadyCommentByAdmin({
				variables: { id: comment._id, type: text },
			})
				.then((res) => {
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (type === 'cancelTrip' && text) {
			updateCanceledTripReasonByAdmin({
				variables: { id: comment._id, title: text, when, by, type: cancelType },
			})
				.then((res) => {
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (type === 'readyMessage' && text && order) {
			updateReadyMessageByAdmin({
				variables: {
					id: comment._id,
					message: text,
					order: parseInt(order),
					type: typeOptionsAlternate[typeValue.toLowerCase()].toUpperCase(),
				},
			})
				.then((res) => {
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (type === 'parcelVolume' && name && value) {
			updateParcelVolumeByAdmin({
				variables: {
					id: comment._id,
					name: name.trim(),
					value: Number(value),
					order: parcelOrder ? Number(parcelOrder) : undefined,
					type: parcelType,
				},
			})
				.then((res) => {
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
				});
		}
		if (type === 'parcelWeight' && name && value) {
			updateParcelWeightByAdmin({
				variables: {
					id: comment._id,
					name: name.trim(),
					value: Number(value),
					order: parcelOrder ? Number(parcelOrder) : undefined,
					type: parcelType,
				},
			})
				.then((res) => {
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
				});
		}
		if (type === 'driverHowItWorks' && text && title) {
			updateDriverHowItWorksByAdmin({
				variables: {
					id: comment._id,
					title: title.trim(),
					description: text.trim(),
				},
			})
				.then((res) => {
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (type === 'legal' && text && title) {
			updateLegalByAdmin({
				variables: {
					id: comment._id,
					title: title.trim(),
					description: text.trim(),
					order: parseInt(order),
				},
			})
				.then((res) => {
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (type === 'help' && text && title) {
			updateHelpByAdmin({
				variables: {
					id: comment._id,
					name: driverHowItWorksName,
					type: helpTypeOptionsAlternate[helpType.toLowerCase()].toUpperCase(),
					title: title.trim(),
					description: text.trim(),
					order: parseInt(order),
				},
			})
				.then((res) => {
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Edit</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					{type === 'cancelTrip' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={3} md={3} lg={3}>
										<div className={style.label}>By: </div>
									</GridItem>
									<GridItem xs={12} sm={9} md={9} lg={9}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={by}
												onClick={(e) => setBy(e.toUpperCase())}
												dropdownList={byOptions}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}

					{type === 'help' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={3} md={3} lg={3}>
										<div className={style.label}>Name: </div>
									</GridItem>
									<GridItem xs={12} sm={9} md={9} lg={9}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={driverHowItWorksName}
												onClick={(e) => setDriverHowItWorksName(e.toUpperCase())}
												dropdownList={helpNameOptions}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'help' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={3} md={3} lg={3}>
										<div className={style.label}>Type: </div>
									</GridItem>
									<GridItem xs={12} sm={9} md={9} lg={9}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={helpType}
												onClick={(e) => setHelpType(e.toUpperCase())}
												dropdownList={Array.from(
													helpTypeOtions,
													(item) => helpTypeOptionsAlt[item]
												)}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'readyMessage' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={3} md={3} lg={3}>
										<div className={style.label}>Type: </div>
									</GridItem>
									<GridItem xs={12} sm={9} md={9} lg={9}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={typeValue}
												onClick={(e) => setTypeValue(e.toUpperCase())}
												dropdownList={Array.from(
													typeValueOptions,
													(item) => typeOptionsAlt[item]
												)}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{(type === 'parcelWeight' || type === 'parcelVolume') && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={3} md={3} lg={3}>
										<div className={style.label}>Type: </div>
									</GridItem>
									<GridItem xs={12} sm={9} md={9} lg={9}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={parcelType}
												onClick={(e) => setParcelType(e.toUpperCase())}
												dropdownList={parcelTypeOptions}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'cancelTrip' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={3} md={3} lg={3}>
										<div className={style.label}>Type: </div>
									</GridItem>
									<GridItem xs={12} sm={9} md={9} lg={9}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={cancelType}
												onClick={(e) => setCancelType(e.toUpperCase())}
												dropdownList={typeOptions}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'cancelTrip' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={3} md={3} lg={3}>
										<div className={style.label}>When: </div>
									</GridItem>
									<GridItem xs={12} sm={9} md={9} lg={9}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={when}
												onClick={(e) => setWhen(e.toUpperCase())}
												dropdownList={whenOptions}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}

					{(type === 'driverHowItWorks' || type === 'legal' || type === 'help') && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Title: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={title}
												onChange={(e) => setTitle(e.target.value)}
												error={titleError}
												labelText={titleError ? "title can't be empty*" : 'title'}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}

					{(type === 'readyMessage' || type === 'help' || type === 'legal') && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Order: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={order}
												onChange={(e) => setOrder(e.target.value)}
												error={orderError}
												labelText={orderError ? "order can't be empty*" : 'order'}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{(type === 'parcelWeight' || type === 'parcelVolume') && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Value: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={value}
												onChange={(e) => setValue(e.target.value)}
												error={valueError}
												labelText={valueError ? "value can't be empty*" : 'value'}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{(type === 'parcelWeight' || type === 'parcelVolume') && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Name: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={name}
												onChange={(e) => setName(e.target.value)}
												error={nameError}
												labelText={nameError ? "name can't be empty*" : 'name'}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					<GridItem xs={12} sm={12} md={12} lg={12}>
						{type !== 'parcelWeight' && type !== 'parcelVolume' && (
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={3} md={3} lg={3}>
										<div className={style.label}>Text: </div>
									</GridItem>
									<GridItem xs={12} sm={9} md={9} lg={9}>
										<div className={style.textAreaContainer}>
											{textError && <div className={style.errorMessage}>text can't be Empty</div>}
											<textarea
												value={text}
												onChange={(e) => setText(e.target.value)}
												placeholder="write text here ..."
												className={style.textArea}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
					</GridItem>
					{(type === 'parcelWeight' || type === 'parcelVolume') && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Order: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={parcelOrder}
												onChange={(e) => setParcelOrder(e.target.value)}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													type: 'number',
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.buttonsContainer}>
							<Button color="whiteButton" onClick={() => close()}>
								Cancel
							</Button>
							<Button color="yellow" onClick={onChange}>
								Submit
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default EditItem;
