import React from "react";
import { NavLink, Route, Switch, Redirect } from "react-router-dom";

import Categories from "superAdminViews/setting/productOptions/Categories";
import AttributeGroups from "superAdminViews/setting/productOptions/AttributeGroups";
import Attributes from "superAdminViews/setting/productOptions/Attributes";
import style from "superAdminViews/restaurant/shopPage/ShopPage.module.scss";

const ProductOptions = (props) => {
  return (
    <div className={style.mainDiv}>
      <div className={style.navContainer}>
        <div>
          <NavLink
            className={style.nav}
            activeClassName={style.activeNav}
            to={`/superAdmin/type&cuisine/categories`}
          >
            Categories
          </NavLink>
        </div>
        <div>
          <NavLink
            className={style.nav}
            activeClassName={style.activeNav}
            to={`/superAdmin/type&cuisine/attributeGroups`}
          >
            {" "}
            Attribute groups
          </NavLink>
        </div>
        <div>
          <NavLink
            className={style.nav}
            activeClassName={style.activeNav}
            to={`/superAdmin/type&cuisine/attributes`}
          >
            {" "}
            Attributes
          </NavLink>
        </div>
      </div>
      <div>
        <Switch>
          <Route
            exact={true}
            path="/superAdmin/type&cuisine/categories"
            component={Categories}
          />
          <Route
            exact={true}
            path="/superAdmin/type&cuisine/attributeGroups"
            component={AttributeGroups}
          />
          <Route
            exact={true}
            path="/superAdmin/type&cuisine/attributes"
            component={Attributes}
          />
          <Redirect
            from="/superAdmin/type&cuisine"
            to="/superAdmin/type&cuisine/categories"
          />
        </Switch>
      </div>
    </div>
  );
};

export default ProductOptions;
