import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import XLSX from 'xlsx';
import Modal from 'commonComponents/modal/Modal';
import BarLoader from 'react-spinners/BarLoader';
import ClearIcon from '@material-ui/icons/Clear';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import ReactTable from 'react-table';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import OrderDetails from 'shopAdminViews/orders/OrderDetails';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';

const GET_ORDERS = gql`
	query getOrdersByAdmin(
		$limit: Int
		$skip: Int
		$shop: ID
		$finalPrice: Float
		$commission: Float
		$finished: Boolean
		$status: OrderStatus
		$createdAt: Date
		$tracking: OrderTrackingQueryInput
		$createdAtSort: Int
		$finalPriceSort: Int
		$commissionSort: Int
		$passengerName: String
		$passengerPhoneNumber: String
		$createdAtFrom: Date
		$finalPriceFrom: Float
		$commissionFrom: Float
	) {
		getOrdersByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				shop: $shop
				tracking: $tracking
				finalPrice: $finalPrice
				commission: $commission
				finished: $finished
				status: $status
				createdAt: $createdAt
				passengerName: $passengerName
				passengerPhoneNumber: $passengerPhoneNumber
				createdAtFrom: $createdAtFrom
				finalPriceFrom: $finalPriceFrom
				commissionFrom: $commissionFrom
			}
			sort: { createdAt: $createdAtSort, finalPrice: $finalPriceSort, commission: $commissionSort }
		) {
			_id
			user {
				_id
				fullName
				phoneNumber
			}
			shipmentModel
			type
			finalPrice
			commission
			createdAt
			finished
			status
			tracking {
				trackId
			}
			cart {
				products {
					quantity
					product {
						_id
						title
					}
				}
			}
		}
	}
`;

const GET_ORDERS_COUNT = gql`
	query getOrdersByAdminCount(
		$finalPrice: Float
		$shop: ID
		$commission: Float
		$tracking: OrderTrackingQueryInput
		$finished: Boolean
		$status: OrderStatus
		$createdAt: Date
		$passengerName: String
		$passengerPhoneNumber: String
		$createdAtFrom: Date
		$finalPriceFrom: Float
		$commissionFrom: Float
	) {
		getOrdersByAdminCount(
			filters: {
				shop: $shop
				finalPrice: $finalPrice
				commission: $commission
				finished: $finished
				status: $status
				tracking: $tracking
				createdAt: $createdAt
				passengerName: $passengerName
				passengerPhoneNumber: $passengerPhoneNumber
				createdAtFrom: $createdAtFrom
				finalPriceFrom: $finalPriceFrom
				commissionFrom: $commissionFrom
			}
		)
	}
`;

const SuperOrders = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const statusOptions = [
		'ALL',
		'PENDING',
		'PAID',
		'SHIPPING',
		'DELIVERY NOT ACCEPTED',
		'DELIVERED',
		'FINISHED DUE TO NOT PAYING',
	];

	const shipmentModelOptions = ['ALL', 'SHOP', 'SPARK'];

	const finishedOptions = ['ALL', 'FINISHED', 'UNFINISHED'];

	const [excelData, setExcelData] = useState([
		['track ID', 'user name', 'user phone No', 'final price', 'commission', 'date', 'status'],
	]);

	const [getOrdersByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_ORDERS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getOrdersByAdminCount > 0 &&
			excelData.length === countData.getOrdersByAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getOrdersByAdmin, (item) => [
					item.tracking.trackId,
					item.user ? item.user.fullName : '-',
					item.user ? item.user.phoneNumber : '-',
					item.finalPrice.toFixed(2),
					item.commission.toFixed(2),
					moment(item.createdAt).format('MM-DD-YYYY'),
					item.status.replace(/_/g, ' ').toLowerCase(),
				]),
			]);
		}
		if (lazyError) {
			console.log(lazyError);
		}
	}, [, lazyData, lazyLoading, lazyError]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Orders.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getOrdersByAdmin({
				variables: {
					...variables,
					skip: 0,
					limit: countData.getOrdersByAdminCount,
				},
			});
		}
	};

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		shop: props.id,
		createdAtSort: -1,
		commissionSort: undefined,
		finalPriceSort: undefined,
		status: undefined,
		title: undefined,
		createdAt: undefined,
		finished: undefined,
		tracking: { trackId: undefined, estimatedDelivery: undefined },
		finalPrice: undefined,
		passengerName: undefined,
		commission: undefined,
		passengerPhoneNumber: undefined,
		createdAtFrom: undefined,
		finalPriceFrom: undefined,
		commissionFrom: undefined,
	});

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getOrdersByAdminCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getOrdersByAdminCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	const { loading, error, data, refetch } = useQuery(GET_ORDERS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_ORDERS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const [dateFromFilter, setDateFromFilter] = useState('');
	const [commissionFromFilter, setCommissionFromFilter] = useState('');
	const [dateFromFilterError, setDateFromFilterError] = useState('');
	const [finalPriceFromFilter, setFinalPriceFromFilter] = useState('');

	const [trackIdFilter, setTrackIdFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');
	const [passengerNameFilter, setPassengerNameFilter] = useState('');
	const [passengerPhoneNumberFilter, setPassengerPhoneNumberFilter] = useState('');
	const [finalPriceFilter, setFinalPriceFilter] = useState('');
	const [commissionFilter, setCommissionFilter] = useState('');
	const [dateFilterError, setDateFilterError] = useState('');

	const [trackIdFilterError, setTrackIdFilterError] = useState(false);
	const [status, setStatus] = useState(statusOptions[0]);
	const [finishedFilter, setFinishedFilter] = useState(finishedOptions[0]);

	const [dateSort, setDateSort] = useState('down');
	const [finalPriceSort, setFinalPriceSort] = useState('');
	const [commissionSort, setCommissionSort] = useState('');
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [modal, setModal] = useState(false);
	const [orderDetail, setOrderDetail] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectOrderDetail = (order) => {
		setModal(true);
		setOrderDetail(order);
	};

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('up');
			}
		}
	};

	const onFinalPriceSortChange = () => {
		if (!finalPriceSort) {
			setFinalPriceSort('up');
		} else {
			if (finalPriceSort === 'up') {
				setFinalPriceSort('down');
			} else {
				setFinalPriceSort('');
			}
		}
	};

	const onCommissionSortChange = () => {
		if (!commissionSort) {
			setCommissionSort('up');
		} else {
			if (commissionSort === 'up') {
				setCommissionSort('down');
			} else {
				setCommissionSort('');
			}
		}
	};

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFromFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (trackIdFilter && trackIdFilter.length !== 10) {
			setTrackIdFilterError(true);
		} else {
			setTrackIdFilterError(false);
		}
	}, [trackIdFilter]);

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}

		if (finalPriceSort) {
			if (finalPriceSort === 'up') {
				setVariables((variables) => ({ ...variables, finalPriceSort: 1 }));
			} else if (finalPriceSort === 'down') {
				setVariables((variables) => ({ ...variables, finalPriceSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, finalPriceSort: undefined }));
		}

		if (commissionSort) {
			if (commissionSort === 'up') {
				setVariables((variables) => ({ ...variables, commissionSort: 1 }));
			} else if (commissionSort === 'down') {
				setVariables((variables) => ({ ...variables, commissionSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, commissionSort: undefined }));
		}
	}, [dateSort, finalPriceSort, commissionSort]);

	const onFilterSubmit = () => {
		let hasFilter =
			trackIdFilter ||
			variables.tracking.trackId ||
			passengerNameFilter ||
			variables.passengerName ||
			passengerPhoneNumberFilter ||
			variables.passengerPhoneNumber ||
			dateFilter ||
			variables.createdAt ||
			finalPriceFilter ||
			variables.finalPrice ||
			commissionFilter ||
			variables.commission ||
			commissionFromFilter ||
			variables.commissionFrom ||
			finalPriceFromFilter ||
			variables.finalPriceFrom ||
			dateFromFilter ||
			variables.createdAtFrom ||
			!(status === 'ALL' && !variables.status) ||
			!(finishedFilter === 'ALL' && !variables.finished);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');
			if (trackIdFilter && trackIdFilter.length === 10) {
				setVariables((variables) => ({
					...variables,
					tracking: {
						trackId: trackIdFilter.trim(),
						estimatedDelivery: undefined,
					},
				}));
			} else if (!trackIdFilter) {
				setVariables((variables) => ({
					...variables,
					tracking: { trackId: undefined, estimatedDelivery: undefined },
				}));
			}

			if (passengerNameFilter) {
				setVariables((variables) => ({
					...variables,
					passengerName: passengerNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerName: undefined }));
			}

			if (finalPriceFromFilter) {
				setVariables((variables) => ({
					...variables,
					finalPriceFrom: parseFloat(finalPriceFromFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, finalPriceFrom: undefined }));
			}

			if (finalPriceFilter) {
				setVariables((variables) => ({
					...variables,
					finalPrice: parseFloat(finalPriceFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, finalPrice: undefined }));
			}

			if (commissionFromFilter) {
				setVariables((variables) => ({
					...variables,
					commissionFrom: parseFloat(commissionFromFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, commissionFrom: undefined }));
			}

			if (commissionFilter) {
				setVariables((variables) => ({
					...variables,
					commission: parseFloat(commissionFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, commission: undefined }));
			}

			if (passengerPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					passengerPhoneNumber: passengerPhoneNumberFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerPhoneNumber: undefined }));
			}

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (status === 'ALL') {
				setVariables((variables) => ({
					...variables,
					status: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					status: status.replace(/ /g, '_').toUpperCase(),
				}));
			}

			if (finishedFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					finished: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					finished: finishedFilter === 'FINISHED' ? true : false,
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			shop: props.id,
			createdAtSort: -1,
			commissionSort: undefined,
			finalPriceSort: undefined,
			status: undefined,
			title: undefined,
			createdAt: undefined,
			finished: undefined,
			tracking: { trackId: undefined, estimatedDelivery: undefined },
			finalPrice: undefined,
			passengerName: undefined,
			commission: undefined,
			passengerPhoneNumber: undefined,
			createdAtFrom: undefined,
			finalPriceFrom: undefined,
			commissionFrom: undefined,
		});
		setFinalPriceFilter('');
		setFinalPriceSort('');
		setPassengerNameFilter('');
		setPassengerPhoneNumberFilter('');
		setDateFilter('');
		setTrackIdFilter('');
		setDateSort('down');
		setFinishedFilter('ALL');
		setStatus('ALL');
		setCommissionFilter('');
		setCommissionSort('');
		setFinalPriceSort('');
		setFinalPriceFromFilter('');
		setDateFromFilter('');
		setCommissionFromFilter('');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onFilterSubmit();
		}
	};

	// calculate total price
	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (data) {
			let number = 0;
			data.getOrdersByAdmin.map((item) => {
				number = number + item.finalPrice;
			});
			setTotal(number);
		}
	}, [data, loading]);

	const [totalCommission, setTotalCommission] = useState(0);
	useEffect(() => {
		if (data) {
			let number = 0;
			data.getOrdersByAdmin.map((item) => {
				number = number + item.commission;
			});
			setTotalCommission(number);
		}
	}, [data, loading]);

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onFilterSubmit}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getOrdersByAdminCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getOrdersByAdmin.length)}</span> of{' '}
							<span>{countData.getOrdersByAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0)
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={[
					...data.getOrdersByAdmin.map((order) => ({
						trackId: (
							<CopyToClipboard text={order.tracking.trackId}>
								<span
									title="Click To Copy To Clipboard"
									className={style.copyClipboard}
									style={{ color: order.color && '#f78902' }}
								>
									{order.tracking.trackId}
								</span>
							</CopyToClipboard>
						),
						userId: (
							<CopyToClipboard text={order.user._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{order.user._id}
								</span>
							</CopyToClipboard>
						),
						passengerName: order.user && order.user.fullName,
						passengerPhoneNumber: order.user && order.user.phoneNumber,
						finalPrice: order.finalPrice && <div>{'$' + order.finalPrice.toFixed(2)}</div>,
						commission: order.commission && <div>{'$' + order.commission.toFixed(2)}</div>,
						date: moment(order.createdAt).format('MM/DD/YYYY HH:mm'),
						status: order.status && (
							<div
								className={`${style.inlineTag} ${
									style[order.status.replace(/_/g, '').toLowerCase() + 'order' + 'Tag']
								}`}
							>
								<button onClick={() => onSelectOrderDetail(order)} className={style.detailIconDiv}>
									<span>i</span>
								</button>
								{order.status.replace(/_/g, ' ').toLowerCase()}
							</div>
						),
						finished: order.finished ? (
							<div className={`${style.inlineTag} ${style.acceptedTag}`}>ended</div>
						) : (
							<div className={`${style.inlineTag} ${style.passengercanceledTag}`}>during</div>
						),
					})),
					data.getOrdersByAdmin.length > 0 && {
						trackId: <div className={style.totalNumber}>Total:</div>,
						finalPrice: <div className={style.totalNumber}> {'$' + total.toFixed(2)}</div>,
						commission: <div className={style.totalNumber}> {'$' + totalCommission.toFixed(2)}</div>,
					},
				]}
				columns={[
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Track ID</div>
								{trackIdFilter && trackIdFilterError && (
									<div className={style.dateErrorDiv}>must be 10 character</div>
								)}
								<CustomInput
									value={trackIdFilter}
									onChange={(e) => setTrackIdFilter(e.target.value)}
									// id="regular"
									green={props.type === 'grocery'}
									warning={props.type === 'restaurant'}
									error={trackIdFilter && trackIdFilterError ? true : false}
									inputProps={{
										placeholder: 'track ID ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'trackId',
						sortable: false,
						filterable: false,
						minWidth: 220,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>User Name</div>
								<CustomInput
									value={passengerNameFilter}
									onChange={(e) => setPassengerNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>User Phone No</div>
								<CustomInput
									value={passengerPhoneNumberFilter}
									onChange={(e) => setPassengerPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onFinalPriceSortChange}>
									Final price{' '}
									<span>
										{finalPriceSort && (
											<span>
												{finalPriceSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={finalPriceFromFilter}
									onChange={(e) => setFinalPriceFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={finalPriceFilter}
									onChange={(e) => setFinalPriceFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'finalPrice',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onCommissionSortChange}>
									Commission{' '}
									<span>
										{commissionSort && (
											<span>
												{commissionSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={commissionFromFilter}
									onChange={(e) => setCommissionFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={commissionFilter}
									onChange={(e) => setCommissionFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'commission',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Date{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},

					{
						Header: (
							<div>
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={status}
										onClick={(e) => setStatus(e)}
										dropdownList={statusOptions}
									/>
								</div>
							</div>
						),
						accessor: 'status',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Finished</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={finishedFilter}
										onClick={(e) => setFinishedFilter(e)}
										dropdownList={finishedOptions}
									/>
								</div>
							</div>
						),
						accessor: 'finished',
						sortable: false,
						filterable: false,
					},
				]}
				defaultPageSize={perPage + 1}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getOrdersByAdminCount === 0 ? 1 : Math.ceil(countData.getOrdersByAdminCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<OrderDetails
						model="superAdmin"
						type={orderDetail && orderDetail.type === 'PURCHASE' ? 'Purchase' : 'Restaurant'}
						refetch={() => refetch()}
						close={() => setModal(false)}
						order={orderDetail}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default SuperOrders;
