export default function getOption(value, defaultOptions) {
    if (Array.isArray(value)) {
        return value.map(singleValue =>
            defaultOptions.find(
                option => option && option.value === singleValue
            )
        );
    }

    return defaultOptions.find(option => option.value === value) || null;
}
