import React, { useContext } from "react";

import AddPromotion from 'commonComponents/addPromotion/AddPromotionForm'
import {ThemeContext} from 'index'

const ShopAddPromotion = ({promotion}) => {
  const theme = useContext(ThemeContext)

  return <AddPromotion theme={theme} model="shopAdmin"/>
};

export default ShopAddPromotion;
