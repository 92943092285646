import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { endPoint } from 'utils/config';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Step1 from 'superAdminViews/ride/addDriver/Step1';
import Step2 from 'superAdminViews/ride/addDriver/Step2';

const ADD_DRIVER = gql`
	mutation createDriverVerificationRequestByAdmin(
		$driverId: ID!
		$gender: DriverGender!
		$birthDate: Date!
		$address: DriverAddressByAdminInput!
		$profileImageUrl: String!
		$drivingLicence: DriverDrivingLicenceByAdminInput!
		$drivingRecordPhotoUrl: String!
		$canadianVerificationPhotoUrl: String!
		$canadianVerificationExpireDate: Date!
		$backgroundCheckDocumentPhotoUrl: String!
	) {
		createDriverVerificationRequestByAdmin(
			driverId: $driverId
			input: {
				gender: $gender
				birthDate: $birthDate
				address: $address
				profileImageUrl: $profileImageUrl
				drivingLicence: $drivingLicence
				drivingRecordPhotoUrl: $drivingRecordPhotoUrl
				canadianVerificationPhotoUrl: $canadianVerificationPhotoUrl
				canadianVerificationExpireDate: $canadianVerificationExpireDate
				backgroundCheckDocumentPhotoUrl: $backgroundCheckDocumentPhotoUrl
			}
		) {
			_id
			fullName
		}
	}
`;

const AddDriver = (props) => {
	const [step, setStep] = useState(1);
	const [info, setInfo] = useState({});

	const [warning, setWarning] = useState('');
	const [warningModal, setWarningModal] = useState(false);

	const [errorText, setErrorText] = useState('');
	const [errorModal, setErrorModal] = useState(false);
	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onNext = () => setStep((step) => step + 1);

	const [
		createDriverVerificationRequestByAdmin,
		{ data: addData, error: addError, loading: addLoading },
	] = useMutation(ADD_DRIVER);

	useEffect(() => {
		if (addError) {
			setErrorModal(true);
		}
	}, [addError]);

	const onCloseErrorModal = () => {
		setErrorModal(false);
		setStep(1);
	};

	const addInfo = (object) => {
		setInfo((info) => ({ ...info, ...object }));
	};

	useEffect(() => {
		if (info.drivingLicence) {
			console.log(info);

			createDriverVerificationRequestByAdmin({ variables: info })
				.then((res) => {
					if (warning) {
						setWarningModal(true);
					} else {
						window.location.href = endPoint + '/superAdmin/drivers';
					}
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	}, [info]);

	var form;
	if (step === 1) {
		form = <Step1 onNext={onNext} addInfo={addInfo} />;
	}
	if (step === 2) {
		form = <Step2 setWarning={(warning) => setWarning(warning)} onNext={onNext} addInfo={addInfo} />;
	}
	return (
		<div>
			
			{form}
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={onCloseErrorModal}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={warningModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							window.location.href = endPoint + '/superAdmin/drivers';
						}}
						type="notif"
						text={warning}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default AddDriver;
