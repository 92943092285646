import React from 'react';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import style from './Pagination.module.scss';

const Pagination = (props) => {
	return (
		<div className={style.mainDiv}>
			<GridContainer>
				<GridItem xs={1} sm={2} md={2} lg={2}>
					<button
						disabled={props.pageCount === 1}
						onClick={props.onFirstPage}
						className={`${style.doubleArrowContainer} ${
							style[props.pageCount === 1 ? 'disabledButton' : '']
						}`}
					>
						<i class="fas fa-angle-double-left"></i>
					</button>
				</GridItem>
				<GridItem xs={1} sm={2} md={2} lg={2}>
					<button
						disabled={props.pageCount === 1}
						onClick={props.onPrevPage}
						className={`${style.doubleArrowContainer} ${
							style[props.pageCount === 1 ? 'disabledButton' : '']
						}`}
					>
						<i class="fas fa-angle-left"></i>
					</button>
				</GridItem>
				<GridItem xs={7} sm={4} md={4} lg={4}>
					<div className={style.middle}>
						<div>
							page <span className={style.number}>{props.pageCount}</span> of{' '}
							<span className={style.number}>{props.totalCount}</span>
						</div>
					</div>
				</GridItem>
				<GridItem xs={1} sm={2} md={2} lg={2}>
					<button
						disabled={props.pageCount === props.totalCount}
						onClick={props.onNextPage}
						className={`${style.doubleArrowContainer} ${
							style[props.pageCount === props.totalCount ? 'disabledButton' : '']
						}`}
					>
						<i class="fas fa-angle-right"></i>
					</button>
				</GridItem>
				<GridItem xs={1} sm={2} md={2} lg={2}>
					<button
						disabled={props.pageCount === props.totalCount}
						onClick={props.onLastPage}
						className={`${style.doubleArrowContainer} ${
							style[props.pageCount === props.totalCount ? 'disabledButton' : '']
						}`}
					>
						<i class="fas fa-angle-double-right"></i>
					</button>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default Pagination;
