import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import AddShop from 'commonComponents/addShop/AddShop';
import { ThemeContext } from 'index';

const GET_SHOP = gql`
	query getShopByShopAdmin {
		getShopByShopAdmin {
			_id
			state
			active
			acceptCash
			name
			address
			budget
			phoneNumbers
			origin
			preparingTime
			location {
				coordinates
			}
			workingHoursInMinutes {
				type
				from
				to
			}
			notWorkingDays {
				type
			}
			description
			bannerUrl
			logoUrl
			location {
				coordinates
			}
			rootCategory {
				_id
				title
			}
			categories {
				_id
				title
			}
			attributes {
				_id
				name
			}
		}
	}
`;

const EditShop = (props) => {
	const theme = useContext(ThemeContext);
	const { loading, error, data } = useQuery(GET_SHOP);

	if (data) {
		console.log(data);
	}

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(JSON.parse(JSON.stringify(error)));
		return <ErrorPage />;
	}

	if (data) {
		console.log(data);
	}

	return (
		<AddShop
			editAfterRejection={props.editAfterRejection}
			theme={props.theme ? props.theme : theme}
			type="shopAdmin"
			shop={data.getShopByShopAdmin}
		/>
	);
};

export default EditShop;
