import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Prompt } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridItem from 'components/Grid/GridItem.js';
import { endPoint, graphqlUrl } from 'utils/config';
import Dialog from '@material-ui/core/Dialog';
import Files from 'react-butterfiles';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Datetime from 'react-datetime';
import CustomInput from 'components/CustomInput/CustomInput.js';
import style from './AddProduct.module.scss';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SelectSearch from 'react-select-search';

const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!, $folderName: String!) {
		uploadFile(data: { file: $file, folderName: $folderName }) {
			url
		}
	}
`;

const ADD_PRODUCT_BY_ADMIN = gql`
	mutation createProductByAdmin(
		$title: String!
		$subMenu: ID
		$attributes: [AttributeItemInput]
		$reqCarTypes: [ID!]!
		$shop: ID!
		$price: Float!
		$promotion: ProductPromotionInput
		$description: String
		$photoUrl: [String]
		$stock: Int
	) {
		createProductByAdmin(
			input: {
				title: $title
				subMenu: $subMenu
				attributes: $attributes
				reqCarTypes: $reqCarTypes
				shop: $shop
				price: $price
				promotion: $promotion
				description: $description
				photoUrl: $photoUrl
				stock: $stock
			}
		) {
			_id
			title
			promotion {
				percent
			}
			attributes {
				attributeGroup {
					name
				}
				att {
					name
				}
			}
		}
	}
`;

const ADD_PRODUCT_BY_SHOP_ADMIN = gql`
	mutation createProductByShopAdmin(
		$title: String!
		$subMenu: ID
		$attributes: [AttributeItemInput]
		$reqCarTypes: [ID!]!
		$price: Float!
		$promotion: ProductPromotionInput
		$description: String
		$photoUrl: [String]
		$stock: Int
	) {
		createProductByShopAdmin(
			input: {
				title: $title
				subMenu: $subMenu
				attributes: $attributes
				reqCarTypes: $reqCarTypes
				price: $price
				promotion: $promotion
				description: $description
				photoUrl: $photoUrl
				stock: $stock
			}
		) {
			_id
			title
			shop {
				shopMenu {
					_id
				}
			}
			promotion {
				percent
			}
			attributes {
				attributeGroup {
					name
				}
				att {
					name
				}
			}
		}
	}
`;

const EDIT_PRODUCT_BY_SHOP_ADMIN = gql`
	mutation updateProductByShopAdmin(
		$id: ID!
		$title: String!
		$subMenu: ID
		$attributes: [AttributeItemInput]
		$reqCarTypes: [ID!]!
		$price: Float!
		$promotion: ProductPromotionInput
		$description: String
		$photoUrl: [String]
		$stock: Int
	) {
		updateProductByShopAdmin(
			id: $id
			input: {
				title: $title
				subMenu: $subMenu
				attributes: $attributes
				reqCarTypes: $reqCarTypes
				price: $price
				promotion: $promotion
				description: $description
				photoUrl: $photoUrl
				stock: $stock
			}
		) {
			_id
		}
	}
`;

const EDIT_PRODUCT_BY_ADMIN = gql`
	mutation updateProductByAdmin(
		$id: ID!
		$title: String!
		$subMenu: ID
		$attributes: [AttributeItemInput]
		$reqCarTypes: [ID!]!
		$price: Float!
		$shop: ID!
		$promotion: ProductPromotionInput
		$description: String
		$photoUrl: [String]
		$stock: Int
	) {
		updateProductByAdmin(
			id: $id
			input: {
				title: $title
				subMenu: $subMenu
				shop: $shop
				attributes: $attributes
				reqCarTypes: $reqCarTypes
				price: $price
				promotion: $promotion
				description: $description
				photoUrl: $photoUrl
				stock: $stock
			}
		) {
			_id
		}
	}
`;

const GET_SHOP_BY_SHOP_ADMIN = gql`
	query getShopByShopAdmin {
		getShopByShopAdmin {
			_id
			shopMenu {
				_id
				subMenus {
					_id
					name
				}
			}
			rootCategory {
				_id
				title
			}
		}
	}
`;

const GET_SHOP_BY_ADMIN = gql`
	query getShopByAdmin($id: ID!) {
		getShopByAdmin(id: $id) {
			_id
			shopMenu {
				_id
				subMenus {
					_id
					name
				}
			}
			rootCategory {
				_id
				title
			}
		}
	}
`;

const GET_CATEGORIES = gql`
	query getCategories($limit: Int, $skip: Int) {
		getCategories(pagination: { limit: $limit, skip: $skip }) {
			_id
			title
		}
	}
`;

const GET_CAR_TYPES = gql`
	query getReqCarTypes($tripType: ReqCarTypeTripType) {
		getReqCarTypes(filters: { tripType: $tripType }) {
			_id
			name
		}
	}
`;

const GET_ATTRIBUTE_GROUPS = gql`
	query getAttributeGroups($rootCategory: ID) {
		getAttributeGroups(filters: { rootCategory: $rootCategory }) {
			_id
			name
			rootCategory {
				_id
				title
			}
			attributes {
				_id
				name
			}
		}
	}
`;

const AddProduct = (props) => {
	const [disableButton, setDisableButton] = useState(false);

	const [subMenuOptions, setSubMenuOptions] = useState([]);
	const [subMenuId, setSubMenuId] = useState(props.product && props.product.subMenuId ? props.product.subMenuId : '');
	const [subMenuName, setSubMenuName] = useState('');

	const [searchWord, setSearchWord] = useState('');

	const [categoryText, setCategoryText] = useState('Submenu');

	const [attributeGroupsVariables, setAttributeGroupsVariables] = useState({
		rootCategory: undefined,
	});
	const [errorModal, setErrorModal] = useState(false);
	const [noProductModal, setNoProductModal] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const { loading: shopLoading, error: shopError, data: shopData } = useQuery(GET_SHOP_BY_SHOP_ADMIN, {
		fetchPolicy: 'network-only',
	});

	const { loading: carTypesLoading, error: carTypesError, data: carTypesData } = useQuery(GET_CAR_TYPES, {
		variables: {
			tripType: 'DELIVERY',
		},
	});

	const { loading: shopSuperLoading, error: shopSuperError, data: shopSuperData } = useQuery(GET_SHOP_BY_ADMIN, {
		variables: { id: props.id },
		fetchPolicy: 'network-only',
	});

	const { loading: categoryLoading, error: categoryError, data: categoryData } = useQuery(GET_CATEGORIES, {
		variables: { limit: 50, skip: 0 },
	});

	const { loading: attributeGroupsLoading, error: attributeGroupsError, data: attributeGroupsData } = useQuery(
		GET_ATTRIBUTE_GROUPS,
		{
			variables: attributeGroupsVariables,
		}
	);

	useEffect(() => {
		if (carTypesData && carTypeId.length === 0) {
			setCarTypeId(carTypesData.getReqCarTypes[0]._id);
		}
	}, [carTypesData, carTypesLoading]);

	useEffect(() => {
		if (props.type === 'shopAdmin' && shopData && shopData.getShopByShopAdmin.shopMenu === null) {
			setNoProductModal(true);
		}
	}, [, shopData, shopLoading]);

	useEffect(() => {
		if (props.type === 'shopAdmin' && shopData) {
			setAttributeGroupsVariables({
				rootCategory: shopData.getShopByShopAdmin.rootCategory._id,
			});
		}
		if (props.type === 'superAdmin' && shopSuperData) {
			setAttributeGroupsVariables({
				rootCategory: shopSuperData.getShopByAdmin.rootCategory._id,
			});
		}
	}, [, shopData, shopSuperData, shopLoading, shopSuperLoading]);

	// useEffect(() => {
	// 	if (props.product && props.product.shopMenuName && subMenuOptions.length > 0) {
	// 		let id = subMenuOptions.find((item) => item.name === props.product.shopMenuName).value;
	// 		setSubMenuId(id);
	// 	}
	// }, [, subMenuOptions, shopData, shopSuperData, shopLoading, shopSuperLoading]);

	useEffect(() => {
		if (shopData && shopData.getShopByShopAdmin.rootCategory) {
			if (shopData.getShopByShopAdmin.rootCategory.title === 'Restaurant') {
				setCategoryText('Submenu');
			} else {
				setCategoryText('Category');
			}
		}
		if (shopSuperData && shopSuperData.getShopByAdmin.rootCategory) {
			if (shopSuperData.getShopByAdmin.rootCategory.title === 'Restaurant') {
				setCategoryText('Submenu');
			} else {
				setCategoryText('Category');
			}
		}
	}, [, shopData, shopSuperData, shopLoading, shopSuperLoading]);

	useEffect(() => {
		if (props.type === 'superAdmin' && shopSuperData) {
			setSubMenuOptions(
				Array.from(
					shopSuperData.getShopByAdmin.shopMenu.subMenus.filter((item) =>
						item.name.toLowerCase().includes(searchWord.toLowerCase())
					),
					(item) => ({
						value: item._id,
						name: item.name,
					})
				)
			);
		}
		if (props.type === 'shopAdmin' && shopData) {
			setSubMenuOptions(
				Array.from(
					shopData.getShopByShopAdmin.shopMenu.subMenus.filter((item) =>
						item.name.toLowerCase().includes(searchWord.toLowerCase())
					),
					(item) => ({
						value: item._id,
						name: item.name,
					})
				)
			);
		}
	}, [searchWord]);

	useEffect(() => {
		if (props.type === 'superAdmin' && shopSuperData) {
			setSubMenuOptions(
				Array.from(shopSuperData.getShopByAdmin.shopMenu.subMenus, (item) => ({
					value: item._id,
					name: item.name,
				}))
			);
		}
		if (props.type === 'shopAdmin' && shopData) {
			setSubMenuOptions(
				Array.from(shopData.getShopByShopAdmin.shopMenu.subMenus, (item) => ({
					value: item._id,
					name: item.name,
				}))
			);
		}
	}, [, shopData, shopSuperData, subMenuName, shopLoading, shopSuperLoading]);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const grayTheme = {
		checkboxColor: 'gray',
		primaryColor: 'yellow',
	};

	let theme = orangeTheme;
	if (props.theme === 'Purchase') {
		theme = greenTheme;
	} else if (props.theme === 'Restaurant') {
		theme = orangeTheme;
	} else if (props.theme === 'superAdmin') {
		theme = grayTheme;
	}

	const [stock, setStock] = useState(props.product ? props.product.stock : 0);
	const [title, setTitle] = useState(props.product ? props.product.title : '');
	const [description, setDescription] = useState(props.product ? props.product.description : '');
	const [price, setPrice] = useState(props.product ? String(props.product.price) : '');
	const [available, setAvailable] = useState(true);
	const [hasPromotion, setHasPromotion] = useState(
		props.product ? props.product.promotion !== null && props.product.promotion.percent !== null : false
	);
	const [percentageDiscount, setPercentageDiscount] = useState(
		props.product && props.product.promotion && props.product.promotion.percent
			? String(props.product.promotion.percent)
			: ''
	);
	const [startDate, setStartDate] = useState(
		props.product && props.product.promotion && props.product.promotion.discountFrom
			? moment(props.product.promotion.discountFrom).format('MM/DD/YYYY HH:mm')
			: ''
	);
	const [expireDate, setExpireDate] = useState(
		props.product && props.product.promotion && props.product.promotion.discountTo
			? moment(props.product.promotion.discountTo).format('MM/DD/YYYY HH:mm')
			: ''
	);

	const [titleError, setTitleError] = useState(false);
	const [stockError, setStockError] = useState(false);
	const [startDateError, setStartDateError] = useState('');
	const [expireDateError, setExpireDateError] = useState('');
	const [descriptionError, setDescriptionError] = useState(false);
	const [priceError, setPriceError] = useState(false);
	const [percentageError, setPercentageError] = useState(false);

	useEffect(() => {
		if (carTypesData && carTypesData.getReqCarTypes.some((item) => item.name === null)) {
			window.location.reload();
		}
	}, [carTypesData, carTypesLoading]);

	const [image, setImage] = useState(
		props.product && props.product.photoUrl && props.product.photoUrl.length > 0 ? props.product.photoUrl[0] : null
	);
	const [imageError, setImageError] = useState(false);

	const [image2, setImage2] = useState(
		props.product && props.product.photoUrl && props.product.photoUrl.length > 0 ? props.product.photoUrl[1] : null
	);
	const [imageError2, setImageError2] = useState(false);

	const [image3, setImage3] = useState(
		props.product && props.product.photoUrl && props.product.photoUrl.length > 0 ? props.product.photoUrl[2] : null
	);
	const [imageError3, setImageError3] = useState(false);

	const [image4, setImage4] = useState(
		props.product && props.product.photoUrl && props.product.photoUrl.length > 0 ? props.product.photoUrl[3] : null
	);
	const [imageError4, setImageError4] = useState(false);

	const [imagesLimit, setImagesLimit] = useState(
		props.product && props.product.photoUrl && props.product.photoUrl.length > 0
			? props.product.photoUrl.length + 1
			: 1
	);

	const [spinnerIndex, setSpinnerIndex] = useState();
	const [errorText, setErrorText] = useState('');

	const [imageUrls, setImageUrls] = useState([]);

	const [carTypeIds, setCarTypeIds] = useState(
		props.product && props.product.reqCarTypes ? Array.from(props.product.reqCarTypes, (item) => item._id) : ''
	);

	const [carTypeId, setCarTypeId] = useState(
		props.product && props.product.reqCarTypes ? props.product.reqCarTypes[0]._id : []
	);

	const [attributes, setAttributes] = useState({});
	const [attributesVariables, setAttributesVariables] = useState([]);
	const [showCheckboxes, setShowCheckboxes] = useState(false);

	const [createProductByAdmin, { data: addProductSuperData, error: addProductSuperError }] = useMutation(
		ADD_PRODUCT_BY_ADMIN
	);

	const [updateProductByAdmin, { data: updateProductSuperData, error: updateProductSuperError }] = useMutation(
		EDIT_PRODUCT_BY_ADMIN
	);

	const [createProductByShopAdmin, { data: addProductData, error: addProductError }] = useMutation(
		ADD_PRODUCT_BY_SHOP_ADMIN
	);

	const [updateProductByShopAdmin, { data: updateProductData, error: updateProductError }] = useMutation(
		EDIT_PRODUCT_BY_SHOP_ADMIN
	);

	const [uploadFile, { data: uploadData, error: uploadError, loading: uploadLoading }] = useMutation(UPLOAD_FILE);

	const uploadImage = (file) => {
		setSpinnerIndex(1);
		uploadFile({
			variables: { file: file.src.file, folderName: '' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			if (!image) {
				setImagesLimit((imagesLimit) => imagesLimit + 1);
			}
			setImage(res.data.uploadFile.url);
		});
	};

	const uploadImage2 = (file) => {
		setSpinnerIndex(2);
		uploadFile({
			variables: { file: file.src.file, folderName: '' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			if (!image2) {
				setImagesLimit((imagesLimit) => imagesLimit + 1);
			}
			setImage2(res.data.uploadFile.url);
		});
	};

	const uploadImage3 = (file) => {
		setSpinnerIndex(3);
		uploadFile({
			variables: { file: file.src.file, folderName: '' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			if (!image3) {
				setImagesLimit((imagesLimit) => imagesLimit + 1);
			}
			setImage3(res.data.uploadFile.url);
		});
	};

	const uploadImage4 = (file) => {
		setSpinnerIndex(4);
		uploadFile({
			variables: { file: file.src.file, folderName: '' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			setImage4(res.data.uploadFile.url);
		});
	};

	const onStartDateChange = (date) => {
		if (props.product || moment(date).isAfter(moment())) {
			setStartDateError('');
			setStartDate(date);
		} else {
			setStartDateError('start date should be from today or later*');
			setStartDate(date);
		}
	};

	const onExpireDateChange = (date) => {
		if (startDate && moment(date).isAfter(startDate)) {
			setExpireDateError('');
			setExpireDate(date);
		} else {
			setExpireDateError('expire date should be after start date*');
			setExpireDate(date);
		}
	};

	if (attributeGroupsData) {
		console.log(attributeGroupsData);
	}

	useEffect(() => {
		if (attributeGroupsData) {
			let array = {};
			attributeGroupsData.getAttributeGroups.map((attributeGroup) => {
				if (props.product) {
					array[attributeGroup._id] = [];
					props.product.attributes.map((attribute) => {
						if (attribute.attributeGroup._id === attributeGroup._id) {
							array[attributeGroup._id] = [...Array.from(attribute.att, (item) => item._id)];
						}
					});
				} else {
					array[attributeGroup._id] = [];
				}
			});
			setAttributes(array);
			setShowCheckboxes(true);
		}
	}, [, attributeGroupsData]);

	useEffect(() => {
		let array = [];
		Object.keys(attributes).map((attributeGroup) => {
			if (attributes[attributeGroup].length > 0) {
				array.push({ attributeGroup, att: attributes[attributeGroup] });
			}
		});
		setAttributesVariables(array);
	}, [attributes]);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	// block navigation
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [shouldLeavePage, setShouldLeavePage] = useState(false);

	useEffect(() => {
		if (shouldLeavePage) {
			window.onbeforeunload = undefined;
		} else {
			if (shouldBlockNavigation) {
				window.onbeforeunload = () => true;
			} else {
				window.onbeforeunload = undefined;
			}
		}
	}, [shouldBlockNavigation]);

	useEffect(() => {
		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	// check for changes in edit and add form, to warn while leaving the page
	useEffect(() => {
		if (props.product) {
			if (
				title === props.product.title &&
				price == props.product.price &&
				stock == props.product.stock &&
				description === props.product.description &&
				carTypeId === props.product.reqCarTypes[0]._id &&
				(!props.subMenuId && subMenuId === props.product.subMenuId) &&
				((!props.product.promotion && !percentageDiscount) ||
					(props.product.promotion &&
						props.product.promotion.percent &&
						props.product.promotion.percent == percentageDiscount)) &&
				((!props.product.promotion && !startDate) ||
					(props.product.promotion &&
						props.product.promotion.discountFrom &&
						moment(props.product.promotion.discountFrom).format('YYYY-DD-MM HH:mm') ==
							moment(startDate).format('YYYY-DD-MM HH:mm'))) &&
				((!props.product.promotion && !expireDate) ||
					(props.product.promotion &&
						props.product.promotion.discountTo &&
						moment(props.product.promotion.discountTo).format('YYYY-DD-MM HH:mm') ==
							moment(expireDate).format('YYYY-DD-MM HH:mm'))) &&
				image === props.product.photoUrl[0] &&
				((!props.product.photoUrl[1] && !image2) ||
					(props.product.photoUrl[1] && image2 === props.product.photoUrl[1])) &&
				((!props.product.photoUrl[2] && !image3) ||
					(props.product.photoUrl[2] && image3 === props.product.photoUrl[2])) &&
				((!props.product.photoUrl[3] && !image4) ||
					(props.product.photoUrl[3] && image4 === props.product.photoUrl[3]))
			) {
				setShouldBlockNavigation(false);
			} else {
				setShouldBlockNavigation(true);
			}
		} else {
			if (
				title ||
				price ||
				percentageDiscount ||
				startDate ||
				expireDate ||
				stock != 0 ||
				description ||
				image ||
				image2 ||
				image3 ||
				image4
			) {
				setShouldBlockNavigation(true);
			} else {
				setShouldBlockNavigation(false);
			}
		}
	});

	const onCancel = () => {
		window.onbeforeunload = null;
		setShouldBlockNavigation(false);
		if (props.type === 'superAdmin') {
			window.location.href = endPoint + `/superAdmin/${props.shopName}/${props.id}/products`;
		} else {
			if (props.theme === 'Restaurant') {
				window.location.href = endPoint + '/shopAdmin/foods';
			} else {
				window.location.href = endPoint + '/shopAdmin/products';
			}
		}
	};

	const onSubmit = () => {
		if (!title) {
			setTitleError(true);
		} else {
			setTitleError(false);
		}
		if (image || image2 || image3 || image4) {
			setImageError('');
		} else {
			setImageError('select at least one image');
		}
		if (!stock) {
			setStockError('');
		} else {
			if (String(stock).match(/^(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*)))$/)) {
				setStockError('');
			} else {
				setStockError('enter valid number');
			}
		}
		if (!description) {
			setDescriptionError(true);
		} else {
			setDescriptionError(false);
		}
		if (!price) {
			setPriceError(true);
		} else {
			setPriceError(false);
		}
		if (hasPromotion) {
			if (!percentageDiscount) {
				setPercentageError(true);
			} else {
				setPercentageError(false);
			}

			if (!startDate) {
				setStartDateError("start date can't be empty*");
			} else {
				if (
					moment(startDate, 'MM/DD/YYYY HH:mm', true).isValid() ||
					moment(startDate, 'MM/DD/YYYY HH:mm', true).isValid()
				) {
					if (props.product || moment(startDate).isAfter(moment())) {
						setStartDateError('');
					} else {
						setStartDateError('start date should be from today or later*');
					}
				} else {
					setStartDateError('invalid date');
				}
			}
			if (!expireDate) {
				setExpireDateError("expire date can't be empty*");
			} else {
				if (
					moment(expireDate, 'MM/DD/YYYY HH:mm', true).isValid() ||
					moment(expireDate, 'MM/DD/YYYY HH:mm', true).isValid()
				) {
					if (moment(expireDate).isAfter(startDate)) {
						setExpireDateError('');
					} else {
						setExpireDateError('expire date should be after start date*');
					}
				} else {
					setExpireDateError('invalid date');
				}
			}
		} else {
			setPercentageError(false);
		}

		if (
			title &&
			(image || image2 || image3 || image4) &&
			(!stock || String(stock).match(/^(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*)))$/)) &&
			description &&
			!isNaN(price) &&
			price &&
			(!hasPromotion ||
				(hasPromotion &&
					percentageDiscount &&
					startDate &&
					expireDate &&
					(moment(startDate, 'MM/DD/YYYY HH:mm', true).isValid() ||
						moment(startDate, 'MM/DD/YYYY HH:mm', true).isValid()) &&
					(moment(expireDate, 'MM/DD/YYYY HH:mm', true).isValid() ||
						moment(expireDate, 'MM/DD/YYYY HH:mm', true).isValid()) &&
					moment(expireDate).isAfter(startDate) &&
					(props.product || moment(startDate).isAfter(moment()))))
		) {
			setDisableButton(true);
			window.onbeforeunload = null;
			setShouldBlockNavigation(false);
			setShouldLeavePage(true);
			if (props.type === 'superAdmin' && !props.product) {
				window.onbeforeunload = null;
				setShouldBlockNavigation(false);
				setShouldLeavePage(true);
				createProductByAdmin({
					variables: {
						title,
						stock:
							stock && String(stock).match(/^(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*)))$/)
								? parseInt(stock)
								: 0,
						price: Number(price),
						description,
						shop: props.id,
						reqCarTypes: [carTypeId],
						attributes: attributesVariables,
						subMenu: props.subMenuId ? props.subMenuId : subMenuId ? subMenuId : undefined,
						promotion: hasPromotion
							? {
									percent: parseInt(percentageDiscount),
									discountFrom: moment(startDate).utc(),
									discountTo: moment(expireDate).utc(),
							  }
							: undefined,
						photoUrl: [image, image2, image3, image4].filter(
							(item) => item && item !== null && item !== undefined
						),
					},
				})
					.then((res) => {
						if (props.subMenuId) {
							window.location.href = endPoint + `/superAdmin/${props.shopName}/${props.id}/subMenus`;
						} else {
							window.location.href = endPoint + `/superAdmin/${props.shopName}/${props.id}/products`;
						}
					})
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors[0].message) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}
			if (props.type === 'superAdmin' && props.product) {
				window.onbeforeunload = null;
				setShouldBlockNavigation(false);
				setShouldLeavePage(true);
				updateProductByAdmin({
					variables: {
						id: props.product._id,
						title,
						stock:
							stock && String(stock).match(/^(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*)))$/)
								? parseInt(stock)
								: 0,
						price: Number(price),
						description,
						shop: props.id,
						subMenu: props.subMenuId ? props.subMenuId : subMenuId ? subMenuId : undefined,
						reqCarTypes: [carTypeId],
						attributes: attributesVariables,
						promotion: hasPromotion
							? {
									percent: parseInt(percentageDiscount),
									discountFrom: moment(startDate).utc(),
									discountTo: moment(expireDate).utc(),
							  }
							: undefined,
						photoUrl: [image, image2, image3, image4].filter(
							(item) => item && item !== null && item !== undefined
						),
					},
				})
					.then((res) => {
						window.location.href = endPoint + `/superAdmin/${props.shopName}/${props.id}/products`;
					})
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors[0].message) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}
			if (props.type !== 'superAdmin' && !props.product) {
				window.onbeforeunload = null;
				setShouldBlockNavigation(false);
				setShouldLeavePage(true);
				createProductByShopAdmin({
					variables: {
						title,
						stock:
							stock && String(stock).match(/^(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*)))$/)
								? parseInt(stock)
								: 0,
						price: Number(price),
						description,
						attributes: attributesVariables,
						reqCarTypes: [carTypeId],
						subMenu: props.subMenuId ? props.subMenuId : subMenuId ? subMenuId : undefined,
						promotion: hasPromotion
							? {
									percent: parseInt(percentageDiscount),
									discountFrom: moment(startDate).utc(),
									discountTo: moment(expireDate).utc(),
							  }
							: undefined,
						photoUrl: [image, image2, image3, image4].filter(
							(item) => item && item !== null && item !== undefined
						),
					},
				})
					.then((res) => {
						if (res.data.createProductByShopAdmin.shop.shopMenu === null || props.subMenuId) {
							window.location.href =
								endPoint +
								`/shopAdmin/${
									window.localStorage.getItem('shopType') === 'Restaurant' ? 'Menu' : 'Categories'
								}`;
						} else {
							if (props.theme === 'Restaurant') {
								window.location.href = endPoint + '/shopAdmin/foods';
							} else {
								window.location.href = endPoint + '/shopAdmin/products';
							}
						}
					})
					.catch((err) => {
						console.log(err);
						setDisableButton(false);
						if (err.graphQLErrors && err.graphQLErrors[0].message) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}
			if (props.type !== 'superAdmin' && props.product) {
				window.onbeforeunload = null;
				setShouldBlockNavigation(false);
				setShouldLeavePage(true);
				updateProductByShopAdmin({
					variables: {
						id: props.product._id,
						title,
						stock:
							stock && String(stock).match(/^(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*)))$/)
								? parseInt(stock)
								: 0,

						price: Number(price),
						description,
						subMenu: props.subMenuId ? props.subMenuId : subMenuId ? subMenuId : undefined,
						reqCarTypes: [carTypeId],
						attributes: attributesVariables,
						promotion: hasPromotion
							? {
									percent: parseInt(percentageDiscount),
									discountFrom: moment(startDate).utc(),
									discountTo: moment(expireDate).utc(),
							  }
							: undefined,
						photoUrl: [image, image2, image3, image4].filter(
							(item) => item && item !== null && item !== undefined
						),
					},
				})
					.then((res) => {
						if (props.theme === 'Restaurant') {
							window.location.href = endPoint + '/shopAdmin/foods';
						} else {
							window.location.href = endPoint + '/shopAdmin/products';
						}
					})
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors[0].message) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}
		}
	};

	return (
		<div className={style.mainDiv}>
			<Prompt when={shouldBlockNavigation} message="You have unsaved changes, are you sure you want to leave?" />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={6}>
					<div className={style.leftSide}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<GridContainer onKeyPress={(e) => handleEnter(e)}>
									<GridItem xs={12} sm={12} md={12}>
										<GridContainer>
											<GridItem xs={12} sm={2} md={4}>
												<div className={style.label}>Title:</div>
											</GridItem>
											<GridItem xs={12} sm={10} md={7}>
												<CustomInput
													value={title}
													onChange={(e) => setTitle(e.target.value)}
													green={props.type === 'grocery' && !titleError}
													warning={props.type === 'restaurant' && !titleError}
													error={titleError}
													labelText={titleError ? "title can't be empty*" : 'title'}
													id="float"
													formControlProps={{
														fullWidth: true,
													}}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer onKeyPress={(e) => handleEnter(e)}>
									<GridItem xs={12} sm={12} md={12}>
										<GridContainer>
											<GridItem xs={12} sm={2} md={4}>
												<div className={style.label}>Price:</div>
											</GridItem>
											<GridItem xs={12} sm={10} md={7}>
												<CustomInput
													value={price}
													onChange={(e) => setPrice(e.target.value)}
													green={props.type === 'grocery' && !priceError}
													warning={props.type === 'restaurant' && !priceError}
													error={priceError}
													labelText={priceError ? "price can't be empty*" : 'CAD$'}
													id="float"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{ type: 'number' }}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer onKeyPress={(e) => handleEnter(e)}>
									<GridItem xs={12} sm={12} md={12}>
										<GridContainer>
											<GridItem xs={12} sm={2} md={4}>
												<div className={style.label}>Stock:</div>
											</GridItem>
											<GridItem xs={12} sm={10} md={7}>
												<CustomInput
													value={stock}
													onChange={(e) => setStock(e.target.value)}
													green={props.type === 'grocery' && !stockError}
													warning={props.type === 'restaurant' && !stockError}
													error={stockError}
													labelText={stockError ? stockError : 'stock'}
													id="float"
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{ type: 'number' }}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer onKeyPress={(e) => handleEnter(e)}>
									<GridItem xs={12} sm={2} md={4}>
										<div className={style.label}>Description:</div>
									</GridItem>
									<GridItem xs={12} sm={10} md={7}>
										<CustomInput
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											green={props.type === 'grocery' && !descriptionError}
											warning={props.type === 'restaurant' && !descriptionError}
											error={descriptionError}
											labelText={descriptionError ? "description can't be empty*" : 'description'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
								{props.type === 'shopAdmin' && shopData && !props.subMenuId && (
									<div className={style.selectsearchDiv}>
										<GridContainer>
											<GridItem xs={12} sm={2} md={4}>
												<div className={`${style.label} ${style.selectSearchLabel}`}>
													{categoryText}:
												</div>
											</GridItem>
											<GridItem xs={12} sm={10} md={7}>
												<div className={style.dropdownContainer}>
													<SelectSearch
														options={subMenuOptions}
														value={subMenuId}
														name={categoryText}
														search={true}
														getOptions={(e) => setSearchWord(e)}
														placeholder={`choose ${categoryText.toLowerCase()}`}
														onChange={(e) => {
															setSubMenuId(e);
														}}
													/>
												</div>
											</GridItem>
										</GridContainer>
									</div>
								)}
								{props.type === 'superAdmin' && shopSuperData && !props.subMenuId && (
									<div className={style.selectsearchDiv}>
										<GridContainer>
											<GridItem xs={12} sm={2} md={4}>
												<div className={`${style.label} ${style.selectSearchLabel}`}>
													{categoryText}:
												</div>
											</GridItem>
											<GridItem xs={12} sm={10} md={7}>
												<div className={style.dropdownContainer}>
													<SelectSearch
														options={subMenuOptions}
														value={subMenuId}
														name={categoryText}
														search={true}
														getOptions={(e) => setSearchWord(e)}
														placeholder={`choose ${categoryText.toLowerCase()}`}
														onChange={(e) => {
															setSubMenuId(e);
														}}
													/>
												</div>
											</GridItem>
										</GridContainer>
									</div>
								)}

								<div className={style.availableContainer} style={{ marginTop: '50px' }}>
									<GridContainer onKeyPress={(e) => handleEnter(e)}>
										<GridItem xs={12} sm={2} md={4}>
											<div className={style.checkboxLabel}>Discount:</div>
										</GridItem>
										<GridItem xs={12} sm={5} md={5}>
											<FormControlLabel
												control={
													<Checkbox
														style={{ color: theme.checkboxColor }}
														name={available}
														checked={hasPromotion}
														onChange={(e) =>
															setHasPromotion((hasPromotion) => !hasPromotion)
														}
													/>
												}
											/>
										</GridItem>
									</GridContainer>
								</div>

								{hasPromotion && (
									<GridContainer onKeyPress={(e) => handleEnter(e)}>
										<GridItem xs={12} sm={2} md={4}>
											<div className={style.label}>Discount percentage:</div>
										</GridItem>
										<GridItem xs={12} sm={10} md={7}>
											<CustomInput
												value={percentageDiscount}
												onChange={(e) => setPercentageDiscount(e.target.value)}
												green={props.type === 'grocery' && !percentageError}
												warning={props.type === 'restaurant' && !percentageError}
												error={percentageError}
												labelText={
													percentageError
														? "discount percentage can't be empty*"
														: 'discount percentage'
												}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{ type: 'number' }}
											/>
										</GridItem>
									</GridContainer>
								)}
								{hasPromotion && (
									<div className={style.dateRow}>
										<GridContainer onKeyPress={(e) => handleEnter(e)}>
											<GridItem xs={12} sm={2} md={4}>
												<div className={style.dateLabel}>Start date:</div>
											</GridItem>
											<GridItem xs={12} sm={10} md={7}>
												{startDateError && (
													<div className={style.dateError}>{startDateError}</div>
												)}
												<Datetime
													initialViewDate={moment()}
													dateFormat="MM/DD/YYYY"
													timeFormat="HH:mm"
													disableOnClickOutside
													value={startDate}
													closeOnSelect
													onChange={(date) => onStartDateChange(date)}
													inputProps={{
														placeholder: 'set start date',
													}}
												/>
											</GridItem>
										</GridContainer>
									</div>
								)}
								{hasPromotion && (
									<div className={style.dateRow}>
										<GridContainer onKeyPress={(e) => handleEnter(e)}>
											<GridItem xs={12} sm={2} md={4}>
												<div className={style.dateLabel}>Expire date:</div>
											</GridItem>
											<GridItem xs={12} sm={10} md={7}>
												{expireDateError && (
													<div className={style.dateError}>{expireDateError}</div>
												)}
												<Datetime
													initialViewDate={moment()}
													dateFormat="MM/DD/YYYY"
													timeFormat="HH:mm"
													disableOnClickOutside
													value={expireDate}
													closeOnSelect
													onChange={(date) => onExpireDateChange(date)}
													inputProps={{ placeholder: 'set expire date' }}
												/>
											</GridItem>
										</GridContainer>
									</div>
								)}
							</GridItem>
						</GridContainer>
					</div>
				</GridItem>

				<GridItem xs={12} sm={12} md={12} lg={6}>
					<div onKeyPress={(e) => handleEnter(e)} className={style.rightSide}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								{carTypesData && (
									<div className={style.checkboxRow} style={{ marginBottom: '15px' }}>
										<GridContainer>
											<GridItem xs={12} sm={12} md={12}>
												<div className={style.checkboxLabel}>Delivery vehicle:</div>
											</GridItem>
											<GridItem xs={12} sm={12} md={12}>
												<GridContainer>
													{false &&
														carTypesData.getReqCarTypes.map((reqCarType) => (
															<GridItem
																key={reqCarType.name}
																xs={12}
																sm={6}
																md={6}
																lg={12}
															>
																<FormControlLabel
																	control={
																		<Checkbox
																			style={{ color: theme.checkboxColor }}
																			name={reqCarType.name}
																			checked={carTypeIds.includes(
																				reqCarType._id
																			)}
																			onChange={() => {
																				if (
																					carTypeIds.includes(reqCarType._id)
																				) {
																					setCarTypeIds(
																						carTypeIds.filter(
																							(id) =>
																								id !== reqCarType._id
																						)
																					);
																				} else {
																					setCarTypeIds([
																						...carTypeIds,
																						reqCarType._id,
																					]);
																				}
																			}}
																		/>
																	}
																	label={
																		reqCarType.name && reqCarType.name.toLowerCase()
																	}
																/>
															</GridItem>
														))}
													<div style={{ paddingLeft: '15px' }}>
														<RadioGroup
															aria-label="shopType"
															name="deliveryVehicle"
															value={carTypeId}
															onChange={(e) => {
																setCarTypeId(e.target.value);
															}}
														>
															{carTypesData.getReqCarTypes.map((carType) => (
																<FormControlLabel
																	key={carType._id}
																	value={carType._id}
																	label={carType.name.toLowerCase()}
																	control={<Radio color="gray" />}
																/>
															))}
														</RadioGroup>
													</div>
												</GridContainer>
											</GridItem>
										</GridContainer>
									</div>
								)}
								{attributeGroupsData &&
									showCheckboxes &&
									attributeGroupsData.getAttributeGroups
										.filter((item) => item.attributes.length > 0)
										.map((attributeGroup) => (
											<GridContainer key={attributeGroup._id}>
												<div
													className={style.checkBoxContainer}
													style={{ marginBottom: '35px' }}
												>
													<GridItem xs={12} sm={2} md={4}>
														<div className={style.checkboxLabel}>
															{attributeGroup.name.toLowerCase()}:
														</div>
													</GridItem>
													<GridItem xs={12} sm={10} md={8}>
														<GridContainer>
															{attributeGroup.attributes.map((attribute) => (
																<GridItem key={attribute._id} xs={12} sm={4} md={6}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				style={{ color: theme.checkboxColor }}
																				name={attribute.name}
																				checked={
																					attributeGroupsData &&
																					attributes[
																						attributeGroup._id
																					].includes(attribute._id)
																				}
																				onChange={() => {
																					if (
																						attributes[
																							attributeGroup._id
																						].includes(attribute._id)
																					) {
																						const {
																							[attributeGroup._id]: {},
																							...rest
																						} = attributes;
																						setAttributes({
																							...rest,
																							[attributeGroup._id]: attributes[
																								attributeGroup._id
																							].filter(
																								(att) =>
																									att !==
																									attribute._id
																							),
																						});
																					} else {
																						setAttributes({
																							...attributes,
																							[attributeGroup._id]: [
																								...attributes[
																									attributeGroup._id
																								],
																								attribute._id,
																							],
																						});
																					}
																				}}
																			/>
																		}
																		label={attribute.name}
																	/>
																</GridItem>
															))}
														</GridContainer>
													</GridItem>
												</div>
											</GridContainer>
										))}
							</GridItem>
						</GridContainer>
					</div>
				</GridItem>
			</GridContainer>
			<div onKeyPress={(e) => handleEnter(e)} className={style.imageRow}>
				<GridContainer>
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<div>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<div className={style.imageContainer}>
										<Files
											maxSize="2mb"
											multiple={false}
											convertToBase64={true}
											accept={['image/jpg', 'image/jpeg', 'image/png']}
											onSuccess={(file) => uploadImage(file[0])}
											onError={(errors) => setImageError(true)}
										>
											{({ browseFiles, getDropZoneProps, getLabelProps }) => (
												<div style={{ textAlign: 'center' }}>
													{image && (
														<div>
															<img
																style={{ width: '200px', height: '200px' }}
																alt="1th photo"
																src={`${graphqlUrl}${image}`}
															/>
														</div>
													)}
													{uploadLoading && spinnerIndex === 1 && (
														<CircularProgress style={{ color: 'gray' }} />
													)}
													<div {...getDropZoneProps({ className: 'myDropZone' })} />
													{image && (
														<Button
															onClick={() => setImage('')}
															size="sm"
															justIcon
															round
															color="danger"
														>
															<CloseIcon />
														</Button>
													)}

													<Button onClick={browseFiles} color="whiteButton">
														{`${image ? 'edit' : '+ select'} main photo...`}
													</Button>

													{imageError && (
														<p
															style={{
																color: 'red',
																fontsize: '9px',
															}}
														>
															please upload a photo less than 10MB size
														</p>
													)}
													{!imageError && (
														<div className={style.imageHint}>
															(upload an image less than 10MB)
														</div>
													)}
												</div>
											)}
										</Files>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={6} md={6} lg={3}>
						{imagesLimit >= 2 && (
							<div>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										<div className={style.imageContainer}>
											<Files
												maxSize="2mb"
												multiple={false}
												convertToBase64={true}
												accept={['image/jpg', 'image/jpeg', 'image/png']}
												onSuccess={(file) => uploadImage2(file[0])}
												onError={(errors) => setImageError2(true)}
											>
												{({ browseFiles, getDropZoneProps, getLabelProps }) => (
													<div style={{ textAlign: 'center' }}>
														{image2 && (
															<div>
																<img
																	style={{ width: '200px', height: '200px' }}
																	alt="2th photo"
																	src={`${graphqlUrl}${image2}`}
																/>
															</div>
														)}
														{uploadLoading && spinnerIndex === 2 && (
															<CircularProgress style={{ color: 'gray' }} />
														)}
														<div {...getDropZoneProps({ className: 'myDropZone' })} />
														{image2 && (
															<Button
																onClick={() => setImage2('')}
																size="sm"
																justIcon
																round
																color="danger"
															>
																<CloseIcon />
															</Button>
														)}
														<Button onClick={browseFiles} color="whiteButton">
															{`${image2 ? 'edit' : '+ select'} second photo...`}
														</Button>
														{imageError2 && (
															<p
																style={{
																	color: 'red',
																	fontsize: '9px',
																}}
															>
																please enter photo file less than 2mb size
															</p>
														)}
													</div>
												)}
											</Files>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
					</GridItem>
					<GridItem xs={12} sm={6} md={6} lg={3}>
						{imagesLimit >= 3 && (
							<div>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										<div className={style.imageContainer}>
											<Files
												maxSize="2mb"
												multiple={false}
												convertToBase64={true}
												accept={['image/jpg', 'image/jpeg', 'image/png']}
												onSuccess={(file) => uploadImage3(file[0])}
												onError={(errors) => setImageError3(true)}
											>
												{({ browseFiles, getDropZoneProps, getLabelProps }) => (
													<div style={{ textAlign: 'center' }}>
														{image3 && (
															<div>
																<img
																	style={{ width: '200px', height: '200px' }}
																	alt="3th photo"
																	src={`${graphqlUrl}${image3}`}
																/>
															</div>
														)}
														{uploadLoading && spinnerIndex === 3 && (
															<CircularProgress style={{ color: 'gray' }} />
														)}
														<div {...getDropZoneProps({ className: 'myDropZone' })} />
														{image3 && (
															<Button
																onClick={() => setImage3('')}
																size="sm"
																justIcon
																round
																color="danger"
															>
																<CloseIcon />
															</Button>
														)}
														<Button onClick={browseFiles} color="whiteButton">
															{`${image3 ? 'edit' : '+ select'} third photo...`}
														</Button>
														{imageError3 && (
															<p
																style={{
																	color: 'red',
																	fontsize: '9px',
																}}
															>
																please enter photo file less than 2mb size
															</p>
														)}
													</div>
												)}
											</Files>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
					</GridItem>
					<GridItem xs={12} sm={6} md={6} lg={3}>
						{imagesLimit >= 4 && (
							<div>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										<div className={style.imageContainer}>
											<Files
												maxSize="2mb"
												multiple={false}
												convertToBase64={true}
												accept={['image/jpg', 'image/jpeg', 'image/png']}
												onSuccess={(file) => uploadImage4(file[0])}
												onError={(errors) => setImageError4(true)}
											>
												{({ browseFiles, getDropZoneProps, getLabelProps }) => (
													<div style={{ textAlign: 'center' }}>
														{image4 && (
															<div>
																<img
																	style={{ width: '200px', height: '200px' }}
																	alt="4th photo"
																	src={`${graphqlUrl}${image4}`}
																/>
															</div>
														)}
														{uploadLoading && spinnerIndex === 4 && (
															<CircularProgress style={{ color: 'gray' }} />
														)}
														<div {...getDropZoneProps({ className: 'myDropZone' })} />
														{image4 && (
															<Button
																onClick={() => setImage4('')}
																size="sm"
																justIcon
																round
																color="danger"
															>
																<CloseIcon />
															</Button>
														)}
														<Button onClick={browseFiles} color="whiteButton">
															{`${image4 ? 'edit' : '+ select'} fourth image...`}
														</Button>
														{imageError4 && (
															<p
																style={{
																	color: 'red',
																	fontsize: '9px',
																}}
															>
																please enter photo file less than 2mb size
															</p>
														)}
													</div>
												)}
											</Files>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
					</GridItem>
				</GridContainer>
			</div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<div onKeyPress={(e) => handleEnter(e)} className={style.buttonContainer}>
						<Button color="whiteButton" onClick={onCancel}>
							Cancel
						</Button>

						<Button disabled={uploadLoading || disableButton} onClick={onSubmit} color={theme.primaryColor}>
							Submit
						</Button>
					</div>
				</GridItem>
			</GridContainer>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setErrorModal(false)} type="notif" text={errorText} />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default AddProduct;
