import React, { useRef, useEffect, useContext, useState } from 'react';
import cx from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import Provider from 'utils/Provider';
import ShopEditProduct from 'shopAdminViews/addProduct/ShopEditProduct';
import ShopAddPromotion from 'shopAdminViews/addPromotion/ShopAddPromotion';
import ShopAddProduct from 'shopAdminViews/addProduct/ShopAddProduct';
import ShopEditPromotion from 'shopAdminViews/addPromotion/ShopEditPromotion';
import FixedPlugin from 'components/FixedPlugin/FixedPlugin.js';
import sparkLogo from 'assets/img/sparkLogo.svg';
import { ThemeContext } from 'index';
import Sound from 'react-sound';
import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import routes from 'routes.js';

import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
import newOrderSound from 'assets/sounds/newOrder.mp3';
import updateOrderSound from 'assets/sounds/updateOrder.mp3';
import DashBoard from 'superAdminViews/dashboard/DashBoard';

const GET_ORDERS_SUBSCRIPTION = gql`
	subscription createOrder {
		createOrder {
			_id
			user {
				_id
			}
			shipmentModel
			type
			finalPrice
			commission
			createdAt
			finished
			status
			trip {
				_id
				trackId
			}
			tracking {
				trackId
			}
			cart {
				products {
					quantity
					product {
						_id
						title
					}
				}
			}
		}
	}
`;

const UPDATE_ORDERS_SUBSCRIPTION = gql`
	subscription updateOrder {
		updateOrder {
			_id
			user {
				_id
			}
			shipmentModel
			type
			finalPrice
			commission
			createdAt
			finished
			status
			trip {
				_id
				trackId
			}
			tracking {
				trackId
			}
			cart {
				products {
					quantity
					product {
						_id
						title
					}
				}
			}
		}
	}
`;

const GET_ORDERS = gql`
	query getOrdersByShopAdmin(
		$limit: Int
		$skip: Int
		$_id: ID
		$user: ID
		$shipmentModel: OrderShipmentModel
		$finalPrice: Float
		$commission: Float
		$finished: Boolean
		$tracking: OrderTrackingQueryInput
		$status: OrderStatus
		$createdAt: Date
		$createdAtSort: Int
		$finalPriceSort: Int
		$passengerName: String
		$passengerPhoneNumber: String
	) {
		getOrdersByShopAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				user: $user
				shipmentModel: $shipmentModel
				tracking: $tracking
				finalPrice: $finalPrice
				commission: $commission
				finished: $finished
				status: $status
				createdAt: $createdAt
				passengerName: $passengerName
				passengerPhoneNumber: $passengerPhoneNumber
			}
			sort: { createdAt: $createdAtSort, finalPrice: $finalPriceSort }
		) {
			_id
			finished
			status
		}
	}
`;

var ps;

const useStyles = makeStyles(styles);

export default function ShopAdmin(props) {
	// change title of the page in browser tab
	useEffect(() => {
		let routeName = window.location.pathname.split('/')[2];

		let titleName = routeName
			? routeName
					.replace(/([A-Z])/g, ' $1')
					.replace(/_/g, ' ')
					.toLowerCase()
			: '';
		let titleFinalName = titleName.charAt(0).toUpperCase() + titleName.slice(1);
		document.title = titleFinalName ? `Spark - ${titleFinalName}` : 'Spark';
	}, [, window.location.pathname.split('/')[2]]);

	const themeContext = useContext(ThemeContext);
	const [play, setPlay] = useState(false);
	const [updatePlay, setUpdatePlay] = useState(false);

	// let play = false

	const { error: subError, data: subData, loading: subLoading } = useSubscription(GET_ORDERS_SUBSCRIPTION);

	const { error: subUpdateError, data: subUpdateData, loading: subUpdateLoading } = useSubscription(
		UPDATE_ORDERS_SUBSCRIPTION
	);

	useEffect(() => {
		if (subData) {
			// console.log('sub data is:');
			// console.log(subData);
			setPlay(true);
		}
	}, [subData, subLoading]);

	useEffect(() => {
		if (subUpdateData) {
			// console.log('sub data is:');
			// console.log(subUpdateData);
			setUpdatePlay(true);
		}
	}, [subUpdateData, subUpdateLoading]);

	const scrollRef = useRef(null);
	useEffect(() => {
		scrollRef.current.scrollIntoView();
	}, [window.location.pathname]);
	const { ...rest } = props;
	// states and functions
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [miniActive, setMiniActive] = React.useState(false);
	const [image, setImage] = React.useState(require('assets/img/sidebar-2.jpg'));
	const [color, setColor] = React.useState('blue');
	const [bgColor, setBgColor] = React.useState('white');
	// const [hasImage, setHasImage] = React.useState(true);
	const [fixedClasses, setFixedClasses] = React.useState('dropdown');
	const [logo, setLogo] = React.useState(require('assets/img/logo-white.svg'));
	// styles
	const classes = useStyles();
	const mainPanelClasses =
		classes.mainPanel +
		' ' +
		cx({
			[classes.mainPanelSidebarMini]: miniActive,
			[classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
		});
	// ref for main panel div
	const mainPanel = React.createRef();
	// effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
	// React.useEffect(() => {
	// 	// if (navigator.platform.indexOf('Win') > -1) {
	// 	// 	ps = new PerfectScrollbar(mainPanel.current, {
	// 	// 		suppressScrollX: true,
	// 	// 		suppressScrollY: false,
	// 	// 	});
	// 	// 	document.body.style.overflow = 'hidden';
	// 	// }
	// 	window.addEventListener('resize', resizeFunction);

	// 	// Specify how to clean up after this effect:
	// 	return function cleanup() {
	// 		if (navigator.platform.indexOf('Win') > -1) {
	// 			ps.destroy();
	// 		}
	// 		window.removeEventListener('resize', resizeFunction);
	// 	};
	// });
	// functions for changeing the states from components
	const handleImageClick = (image) => {
		setImage(image);
	};
	const handleColorClick = (color) => {
		setColor(color);
	};
	const handleBgColorClick = (bgColor) => {
		switch (bgColor) {
			case 'white':
				setLogo(require('assets/img/logo.svg'));
				break;
			default:
				setLogo(require('assets/img/logo-white.svg'));
				break;
		}
		setBgColor(bgColor);
	};
	const handleFixedClick = () => {
		if (fixedClasses === 'dropdown') {
			setFixedClasses('dropdown show');
		} else {
			setFixedClasses('dropdown');
		}
	};
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.layout === '/shopAdmin') {
				return (
					<Route
						path={prop.layout + prop.path}
						render={() => <prop.component type={props.type} />}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	const sidebarMinimize = () => {
		setMiniActive(!miniActive);
	};
	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};
	return (
		<Provider>
			<div className={classes.wrapper}>
				{play && (
					<Sound
						url={newOrderSound}
						playStatus={Sound.status.PLAYING}
						// playFromPosition={300 /* in milliseconds */}
						// onLoading={this.handleSongLoading}
						// onPlaying={this.handleSongPlaying}
						onFinishedPlaying={() => setPlay(false)}
					/>
				)}
				{updatePlay && (
					<Sound
						url={updateOrderSound}
						playStatus={Sound.status.PLAYING}
						// playFromPosition={300 /* in milliseconds */}
						// onLoading={this.handleSongLoading}
						// onPlaying={this.handleSongPlaying}
						onFinishedPlaying={() => setUpdatePlay(false)}
					/>
				)}
				<Sidebar
					routes={routes.filter((route) => route.layout === '/shopAdmin')}
					logoText={'Spark'}
					logo={sparkLogo}
					handleDrawerToggle={handleDrawerToggle}
					open={mobileOpen}
					color={themeContext === 'Purchase' ? 'green' : 'orange'}
					bgColor={bgColor}
					miniActive={miniActive}
					{...rest}
				/>
				<div className={mainPanelClasses} ref={mainPanel}>
					<div ref={scrollRef}></div>
					<AdminNavbar
						sidebarMinimize={sidebarMinimize.bind(this)}
						miniActive={miniActive}
						// brandText={getActiveRoute(routes)}
						brandText={window.location.pathname.split('/')[2]}
						handleDrawerToggle={handleDrawerToggle}
						{...rest}
					/>
					{/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
					{getRoute() ? (
						<div className={classes.content}>
							<div className={classes.container}>
								<Switch>
									{getRoutes(routes)}
									<Route
										path="/shopAdmin/addPromotion"
										render={() => <ShopAddPromotion key={Math.random()} />}
									/>
									<Route
										path="/shopAdmin/addProduct"
										render={() => <ShopAddProduct key={Math.random()} />}
									/>
									<Route
										path="/shopAdmin/addFood"
										render={() => <ShopAddProduct key={Math.random()} />}
									/>
									<Route
										path="/shopAdmin/editProduct/:id"
										render={(props) => <ShopEditProduct key={Math.random()} {...props} />}
									/>
									<Route
										path="/shopAdmin/editFood/:id"
										render={(props) => <ShopEditProduct key={Math.random()} {...props} />}
									/>
									<Route
										path="/shopAdmin/addProdcutInShopMenu/:shopMenuId"
										render={(props) => <ShopAddProduct key={Math.random()} {...props} />}
									/>
									<Route
										path="/shopAdmin/addFoodInShopMenu/:shopMenuId"
										render={(props) => <ShopAddProduct key={Math.random()} {...props} />}
									/>
									<Route path="/shopAdmin/editPromotion/:id" component={ShopEditPromotion} />
									<Route
										path="/shopAdmin/dashboard"
										render={() => <DashBoard key={Math.random()} />}
									/>
									<Redirect from="/shopAdmin" to="/shopAdmin/dashboard" />
								</Switch>
							</div>
						</div>
					) : (
						<div className={classes.map}>
							<Switch>
								{getRoutes(routes)}
								<Route
									path="/shopAdmin/addPromotion"
									render={() => <ShopAddPromotion key={Math.random()} />}
								/>
								<Route
									path="/shopAdmin/addProduct"
									render={() => <ShopAddProduct key={Math.random()} />}
								/>
								<Route
									path="/shopAdmin/addFood"
									render={() => <ShopAddProduct key={Math.random()} />}
								/>
								<Route
									path="/shopAdmin/editProduct/:id"
									render={(props) => <ShopEditProduct key={Math.random()} {...props} />}
								/>
								<Route
									path="/shopAdmin/editFood/:id"
									render={(props) => <ShopEditProduct key={Math.random()} {...props} />}
								/>
								<Route
									path="/shopAdmin/addProdcutInShopMenu/:shopMenuId"
									render={(props) => <ShopAddProduct key={Math.random()} {...props} />}
								/>
								<Route
									path="/shopAdmin/addFoodInShopMenu/:shopMenuId"
									render={(props) => <ShopAddProduct key={Math.random()} {...props} />}
								/>
								<Route path="/shopAdmin/editPromotion/:id" component={ShopEditPromotion} />
								<Route path="/shopAdmin/dashboard" render={() => <DashBoard key={Math.random()} />} />
								<Redirect from="/shopAdmin" to="/shopAdmin/dashboard" />
							</Switch>
						</div>
					)}
				</div>
			</div>
		</Provider>
	);
}
