import React, { useContext } from 'react';

import AddProduct from '../../commonComponents/addProduct/AddProduct';
import { ThemeContext } from 'index';

const ShopAddProduct = (props) => {
	const theme = useContext(ThemeContext);
	const subMenuId = props.match && props.match.params.shopMenuId && props.match.params.shopMenuId;
	return <AddProduct subMenuId={subMenuId} theme={theme} type="shopAdmin" />;
};

export default ShopAddProduct;
