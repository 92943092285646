import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { endPoint } from 'utils/config';
import moment from 'moment';
import SelectSearch from 'react-select-search';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import GridContainer from 'components/Grid/GridContainer.js';
import Datetime from 'react-datetime';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import style from 'superAdminViews/ride/addDriver/AddDriver.module.scss';

const GET_DRIVERS = gql`
	query getDriversByAdmin($limit: Int, $skip: Int, $fullName: String, $IncompleteInformationDrivers: Boolean) {
		getDriversByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: { fullName: $fullName, IncompleteInformationDrivers: $IncompleteInformationDrivers }
		) {
			_id
			fullName
			phoneNumber
		}
	}
`;

const Step1 = (props) => {
	const [gender, setGender] = useState('MALE');
	const [birthDate, setBirthDate] = useState('');
	const [address, setAddress] = useState('');
	const [zipCode, setZipCode] = useState('');
	const [driverId, setDriverId] = useState('');

	const [birthDateError, setBirthDateError] = useState(false);
	const [addressError, setAddressError] = useState(false);
	const [zipCodeErrorText, setZipCodeErrorText] = useState('');
	const [driverIdError, setDriverIdError] = useState(false);

	const [driverSelectOptions, setDriverSelectOptions] = useState([]);

	// block navigation
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

	useEffect(() => {
		if (shouldBlockNavigation) {
			window.onbeforeunload = () => true;
		} else {
			window.onbeforeunload = undefined;
		}
	}, [shouldBlockNavigation]);

	useEffect(() => {
		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	useEffect(() => {
		if (driverId || birthDate || address || zipCode) {
			setShouldBlockNavigation(true);
		} else {
			setShouldBlockNavigation(false);
		}
	});

	const [
		getDriversByAdmin,
		{ loading: driversLazyLoading, error: driversLazyError, data: driversLazyData, fetchMore },
	] = useLazyQuery(GET_DRIVERS);

	const [fetchMoreSkip, setFetchMoreSkip] = useState(10);

	const onLoadMore = () => {
		fetchMore({
			query: GET_DRIVERS,
			variables: {
				limit: 10,
				skip: fetchMoreSkip,
				IncompleteInformationDrivers: true,
				fullName: search ? search : '',
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				console.log('this is new results:', fetchMoreResult.getDriversByAdmin);
				setDriverSelectOptions((driverSelectOptions) => [
					...driverSelectOptions,
					...Array.from(fetchMoreResult.getDriversByAdmin, (driver) => ({
						name: driver.fullName + ' - ' + driver.phoneNumber,
						value: driver._id,
					})),
				]);
			},
		});
		setFetchMoreSkip((fetchMoreSkip) => fetchMoreSkip + 10);
	};

	const handleScroll = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			onLoadMore();
		}
	};

	useEffect(() => {
		if (driversLazyData) {
			console.log(driversLazyData);
			setDriverSelectOptions(
				Array.from(driversLazyData.getDriversByAdmin, (driver) => ({
					name: driver.fullName + ' - ' + driver.phoneNumber,
					value: driver._id,
				}))
			);
		}
	}, [, driversLazyData, driversLazyLoading]);

	const [search, setSearch] = useState('');

	useEffect(() => {
		const handler = setTimeout(() => {
			getDriversByAdmin({
				variables: {
					limit: 10,
					skip: 0,
					fullName: search ? search.trim() : '',
					IncompleteInformationDrivers: true,
				},
			});
		}, 200);
		return () => clearTimeout(handler);
	}, [search]);

	const onCancel = () => {
		window.onbeforeunload = null;
		setShouldBlockNavigation(false);
			window.location.href = endPoint + `/superAdmin/addDriver`;
		
	};

	const onSubmit = () => {
		if (!birthDate) {
			setBirthDateError("birth date can't be empty*");
		} else {
			if (moment(birthDate, 'MM/DD/YYYY', true).isValid() || moment(birthDate, 'MM-DD-YYYY', true).isValid()) {
				if (moment().subtract(20, 'years') < moment(birthDate)) {
					setBirthDateError('driver should be at least 20 years old*');
				} else {
					setBirthDateError('');
				}
			} else {
				setBirthDateError('invalid date');
			}
		}
		if (!address) {
			setAddressError(true);
		} else {
			setAddressError(false);
		}
		if (!zipCode) {
			setZipCodeErrorText("zip code can't be empty");
		} else {
			setZipCodeErrorText('');
		}
		if (!driverId) {
			setDriverIdError(true);
		} else {
			setDriverIdError(false);
		}

		if (
			birthDate &&
			moment().subtract(20, 'years') >= moment(birthDate) &&
			(moment(birthDate, 'MM/DD/YYYY', true).isValid() || moment(birthDate, 'MM-DD-YYYY', true).isValid()) &&
			zipCode &&
			address &&
			driverId
		) {
			props.addInfo({
				birthDate: moment(birthDate).utc(),
				gender,
				address: { full: address, zipCode },
				driverId: driverId.trim(),
			});
			props.onNext();
		}
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	return (
		<div className={style.mainDiv}>
			<Prompt when={shouldBlockNavigation} message="You have unsaved changes, are you sure you want to leave?" />
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div className={style.leftSide}>
							{true && (
								<div style={{ margin: '33px 0' }} className={style.dropdownRow}>
									<GridContainer>
										<GridItem xs={12} sm={5} md={5}>
											<div className={style.label}>Driver:</div>
										</GridItem>
										<GridItem xs={12} sm={7} md={7}>
											<div>
												<SelectSearch
													options={driverSelectOptions}
													value={driverId}
													name=""
													onScroll={(e) => handleScroll(e)}
													getOptions={(searchWord) => {
														setSearch(searchWord);
													}}
													search
													placeholder="Choose driver"
													onChange={(value) => setDriverId(value)}
												/>
											</div>
										</GridItem>
									</GridContainer>
								</div>
							)}
							<div onKeyPress={(e) => handleEnter(e)} className={style.radioRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Gender:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<RadioGroup
											aria-label="gender"
											name="gender1"
											value={gender}
											onChange={(e) => setGender(e.target.value)}
										>
											<FormControlLabel
												value="FEMALE"
												control={<Radio color="gray" />}
												label="Female"
											/>
											<FormControlLabel
												value="MALE"
												control={<Radio color="gray" />}
												label="Male"
											/>
										</RadioGroup>
									</GridItem>
								</GridContainer>
							</div>
							<div onKeyPress={(e) => handleEnter(e)} className={style.dateRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.dateLabel}>Birth date:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										{birthDateError && <div className={style.dateError}>{birthDateError}</div>}
										<Datetime
											timeFormat={false}
											disableOnClickOutside
											value={birthDate}
											closeOnSelect
											onChange={(date) => setBirthDate(date)}
											inputProps={{
												placeholder: 'birth date',
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div onKeyPress={(e) => handleEnter(e)} className={style.rightSide}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Address:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={address}
											onChange={(e) => setAddress(e.target.value)}
											error={addressError}
											labelText={addressError ? "address can't be empty*" : 'address'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Zip code:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={zipCode}
											onChange={(e) => setZipCode(e.target.value)}
											error={zipCodeErrorText}
											labelText={zipCodeErrorText ? zipCodeErrorText : 'zip code'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					{driverIdError && <div className={style.selectSearchError}>please select a driver*</div>}

					<div className={style.buttonContainer}>
						<Button color="whiteButton" onClick={onCancel}>
							Cancel
						</Button>
						<Button color="yellow" onClick={onSubmit}>
							Next
						</Button>
					</div>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default Step1;
