import React, { useState, useEffect } from 'react';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ReactTable from 'react-table';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Checkbox from '@material-ui/core/Checkbox';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Modal from 'commonComponents/modal/Modal';
import Pagination from 'commonComponents/pagination/Pagination';
import EditItem from 'superAdminViews/setting/defaults/EditItem';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import style from './CancelTripReason.module.scss';

const ADD_HELP = gql`
	mutation createHelpByAdmin(
		$title: String!
		$description: String!
		$name: HelpName!
		$type: HelpType!
		$order: Int
	) {
		createHelpByAdmin(
			input: { name: $name, title: $title, description: $description, type: $type, order: $order }
		) {
			_id
			title
			name
			type
		}
	}
`;

const GET_HELPS = gql`
	query getHelps($limit: Int, $skip: Int) {
		getHelps(pagination: { limit: $limit, skip: $skip }) {
			_id
			title
			name
			type
			description
			order
		}
	}
`;

const GET_HELPS_COUNT = gql`
	query getHelpsCount {
		getHelpsCount
	}
`;

const DELETE_HELP = gql`
	mutation removeHelpByAdmin($idSet: [ID!]!) {
		removeHelpByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const Helps = (props) => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);
	const typeOptions = ['TAXI', 'DELIVERY', 'FOOD', 'DRIVER_RIDE', 'DRIVER_DELIVERY', 'GROCERY'];
	const typeOptionsAlt = {
		TAXI: 'ride',
		DELIVERY: 'delivery',
		FOOD: 'food',
		DRIVER_RIDE: 'driver ride',
		DRIVER_DELIVERY: 'driver delivery',
		GROCERY: 'shop',
	};
	const typeOptionsAlternate = {
		ride: 'TAXI',
		delivery: 'DELIVERY',
		food: 'FOOD',
		'driver ride': 'DRIVER_RIDE',
		'driver delivery': 'DRIVER_DELIVERY',
		shop: 'GROCERY',
	};
	const nameOptions = [
		'I_WANT_TO_REPORT_A_SERVICE_ANIMAL_ISSUE',
		'I_LOST_AN_ITEM',
		'I_WAS_INVOLVED_IN_AN_ACCIDENT',
		'LEARN_ABOUT_SPARK',
		'ACCOUNT_AND_PAYMENT',
		'RIDE_GUIDE',
		'FOOD_GUIDE',
		'GROCERY_SHOPPING_GUIDE',
		'DELIVERY_GUIDE',
		'MY_PARCEL_WAS_NOT_DELIVERED',
		'MY_PARCEL_IS_MISSING_AN_ITEM',
		'MY_DELIVERY_HAS_BEEN_DELAYED',
		'DELIVERY_COSTED_MORE_THAN_ESTIMATED',
		'MY_PACKAGE_WAS_OPENED',
		'OTHER_ISSUES',
		'I_LOST_AN_ITEM_DELIVERY',
		'FOOD_MY_ORDER_COSTED_MORE_THAN_ESTIMATED',
		'FOOD_MY_ORDER_HAS_BEEN_DELAYED',
		'FOOD_MY_ORDER_WAS_DIFFERENT',
		'FOOD_OTHER_ISSUES',
		'GROCERY_MY_ORDER_COSTED_MORE_THAN_ESTIMATED',
		'GROCERY_MY_ORDER_HAS_BEEN_DELAYED',
		'GROCERY_MY_ORDER_WAS_DIFFERENT',
		'GROCERY_OTHER_ISSUES',
		'DRIVER_RIDE_HELP',
		'DRIVER_DELIVERY_HELP',
	];
	const [name, setName] = useState(nameOptions[0]);
	const [type, setType] = useState('ride');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [order, setOrder] = useState('');

	const [orderError, setOrderError] = useState(false);

	const [titleError, setTitleError] = useState(false);
	const [descriptionError, setDescriptionError] = useState(false);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [itemForDelete, setItemForDelete] = useState();

	const [addModal, setAddModal] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const [modal, setModal] = useState(false);
	const [commentDetails, setCommentDetails] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
	});

	const [removeHelpByAdmin] = useMutation(DELETE_HELP);

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getHelps.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	// testing menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onSelectCommentDetail = (comment) => {
		setModal(true);
		setCommentDetails(comment);
	};

	const onDeleteItem = (item) => {
		setDeleteModal(true);
		setItemForDelete([item._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const deleteItem = () => {
		removeHelpByAdmin({ variables: { idSet: itemForDelete } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		removeHelpByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getHelpsCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getHelpsCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	const { loading, error, data, refetch } = useQuery(GET_HELPS, {
		variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_HELPS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const [createHelpByAdmin, { data: addMutationData, error: addMutationError }] = useMutation(ADD_HELP);

	useEffect(() => {
		if (addMutationData) {
			setAddModal(true);
		}
	}, [addMutationData]);

	const onSubmit = () => {
		if (!title) {
			setTitleError(true);
		} else {
			setTitleError(false);
		}
		if (!description) {
			setDescriptionError(true);
		} else {
			setDescriptionError(false);
		}
		if (!order) {
			setOrderError("order cant't be empty*");
		} else {
			if (String(order).match(/^[1-9][0-9]*$/)) {
				setOrderError('');
			} else {
				setOrderError('enter a valid number');
			}
		}
		if (title && description && String(order).match(/^[1-9][0-9]*$/)) {
			setDisableButton(true);
			createHelpByAdmin({
				variables: {
					title: title.trim(),
					name,
					description: description.trim(),
					type: typeOptionsAlternate[type],
					order: parseInt(order),
				},
			})
				.then((res) => {
					refetch();
					setTitle('');
					setDescription('');
					setDisableButton(false);
				})
				.catch((err) => {
					console.log(err);
					setDisableButton(false);
				});
		}
	};

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<div className={style.inputRow}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Title:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={title}
										onChange={(e) => setTitle(e.target.value)}
										error={titleError}
										labelText={titleError ? "title can't be empty*" : 'title'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Description:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										error={descriptionError}
										labelText={descriptionError ? "description can't be empty*" : 'description'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={6} md={6} lg={6}>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownLabel}>Name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={name}
											onClick={(e) => setName(e)}
											dropdownList={nameOptions}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={6} md={6} lg={6}>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownLabel}>Type:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={type}
											onClick={(e) => setType(e)}
											dropdownList={Array.from(typeOptions, (item) => typeOptionsAlt[item])}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>

					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Order:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={order}
										onChange={(e) => setOrder(e.target.value)}
										error={orderError}
										labelText={orderError ? "order can't be empty*" : 'order'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.inputButtonContainer}>
							<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
								Add
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			{idsForDelete.length > 0 && (
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<Button title="delete selected items" fullWidth color="redButton" onClick={onDeleteSome}>
							Delete
						</Button>
					</GridItem>
				</GridContainer>
			)}
			<ReactTable
				data={data.getHelps
					.sort((a, b) => a.order - b.order)
					.map((comment) => ({
						checkbox: (
							<div className={style.checkboxDivHeader}>
								<Checkbox
									color="default"
									checked={idsForDelete.includes(comment._id)}
									onChange={() => {
										if (idsForDelete.includes(comment._id)) {
											setIdsForDelete((idsForDelete) =>
												idsForDelete.filter((item) => item !== comment._id)
											);
										} else {
											setIdsForDelete([...idsForDelete, comment._id]);
										}
									}}
								/>
							</div>
						),
						title: comment.title,
						name: comment.name,
						type: typeOptionsAlt[comment.type],
						description: comment.description,
						order: comment.order,
						actions: (
							<div>
								<IconButton
									id={comment._id}
									aria-controls="simple-menu"
									aria-haspopup="true"
									onClick={(e) => {
										handleClick(e, comment._id);
									}}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									open={menuId === comment._id}
									onClose={handleClose}
									onClick={handleClose}
								>
									<MenuItem onClick={() => onSelectCommentDetail(comment)}>edit</MenuItem>
									<MenuItem onClick={() => onDeleteItem(comment)}>delete</MenuItem>
								</Menu>
							</div>
						),
					}))}
				columns={[
					{
						Header: (
							<div className={style.fitTitleDivCheckbox}>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getHelps.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getHelps, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: <div className={style.fitTitleDiv}>Title</div>,
						accessor: 'title',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Name</div>,
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Type</div>,
						accessor: 'type',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Order</div>,
						accessor: 'order',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Description</div>,
						accessor: 'description',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>

			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={Math.ceil(countData.getHelpsCount / perPage)}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<EditItem
						refetch={() => refetch()}
						close={() => setModal(false)}
						type="help"
						comment={commentDetails}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							refetch();
							setAddModal(false);
						}}
						type="notif"
						text="Help added succesfully."
					/>
				</DialogContent>
			</Dialog>
			{itemForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteItem}
							text="Do you want to delete this item?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these items?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Helps;
