import React, { useContext } from 'react';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';

import AddProduct from '../../commonComponents/addProduct/AddProduct';
import { ThemeContext } from 'index';

const GET_PRODUCT = gql`
	query getProductByShopAdmin($_id: ID!) {
		getProductByShopAdmin(_id: $_id) {
			_id
			title
			description
			price
			stock
			photoUrl
			shopMenuName
			subMenuId
			promotion {
				percent
				discountFrom
				discountTo
			}
			reqCarTypes {
				_id
				name
			}
			attributes {
				attributeGroup {
					_id
					name
				}
				att {
					_id
					name
				}
			}
		}
	}
`;

const GET_CAR_TYPES = gql`
	query getReqCarTypes($tripType: ReqCarTypeTripType) {
		getReqCarTypes(filters: { tripType: $tripType }) {
			_id
			name
			carTypes {
				_id
				type
			}
		}
	}
`;

const ShopEditProduct = (props) => {
	const theme = useContext(ThemeContext);
	const { loading, error, data } = useQuery(GET_PRODUCT, {
		variables: { _id: props.match.params.id },
		fetchPolicy: 'network-only',
	});

	if (data) {
		console.log(data);
	}

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return <AddProduct key={Math.random()} theme={theme} type="shopAdmin" product={data.getProductByShopAdmin} />;
};

export default ShopEditProduct;
