import React, { useState, useEffect } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';

// core components
import AdminNavbarLinks from './AdminNavbarLinks';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js';

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
	const [fontSize, setFontSize] = useState('30px');

	useEffect(() => {
		if (window.innerWidth > 1080) {
			setFontSize('45px');
		} else {
			setFontSize('15px');
		}
	}, [window.innerWidth]);
	const classes = useStyles();
	const { color, rtlActive, brandText } = props;

	const text = brandText
		? brandText
				.replace(/([A-Z])/g, ' $1')
				.replace(/_/g, ' ')
				.replace(/&/, ' & ')
				.toLowerCase()
		: '';
	var finalText = text.charAt(0).toUpperCase() + text.slice(1);

	const appBarClasses = cx({
		[' ' + classes[color]]: color,
	});
	const sidebarMinimize =
		classes.sidebarMinimize +
		' ' +
		cx({
			[classes.sidebarMinimizeRTL]: rtlActive,
		});
	return (
		<AppBar className={classes.appBar + appBarClasses}>
			<Toolbar className={classes.container}>
				<Hidden smDown implementation="css">
					<div className={sidebarMinimize}>
						{/** props.miniActive ? (
							<Button justIcon round color="primary" onClick={props.sidebarMinimize}>
								<ViewList className={classes.sidebarMiniIcon} />
							</Button>
						) : (
							<Button justIcon round color="primary" onClick={props.sidebarMinimize}>
								<MoreVert className={classes.sidebarMiniIcon} />
							</Button>
						) */}
					</div>
				</Hidden>
				<div className={classes.flex}>
					{/* Here we create navbar brand, based on route name */}
					<Button href="#" style={{ fontSize }} className={classes.title} color="transparent">
						{finalText}
					</Button>
				</div>
				{/**<Hidden smDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} />
            </Hidden> **/}
				<Hidden mdUp implementation="css">
					<Button
						className={classes.appResponsive}
						color="transparent"
						justIcon
						aria-label="open drawer"
						onClick={props.handleDrawerToggle}
					>
						<Menu />
					</Button>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
}

AdminNavbar.propTypes = {
	color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
	rtlActive: PropTypes.bool,
	brandText: PropTypes.string,
	miniActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	sidebarMinimize: PropTypes.func,
};
