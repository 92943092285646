import React, { useState, useEffect } from 'react';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { css } from '@emotion/core';
import XLSX from 'xlsx';
import Modal from 'commonComponents/modal/Modal';
import BarLoader from 'react-spinners/BarLoader';
import BeatLoader from 'react-spinners/BeatLoader';
import ClearIcon from '@material-ui/icons/Clear';
import ReactTable from 'react-table';
import Reply from './Reply';
import TripDetails from 'superAdminViews/ride/trips/TripDetails';
import OrderDetails from 'shopAdminViews/orders/OrderDetails';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';

const GET_CONVERSATIONS = gql`
	query getConversations(
		$limit: Int
		$skip: Int
		$conversationCategory: ConversationCategory
		$conversationType: ConversationType
		$_id: ID
		$user: ID
		$driver: ID
		$shop: ID
		$repliedByAdmin: Boolean
		$closed: Boolean
		$driverName: String
		$passengerName: String
		$shopName: String
		$driverPhoneNumber: String
		$passengerPhoneNumber: String
		$shopPhoneNumber: String
		$title: String
		$createdAtSort: Int
		$createdAtFrom: Date
		$createdAt: Date
	) {
		getConversations(
			Pagination: { limit: $limit, skip: $skip }
			filters: {
				conversationCategory: $conversationCategory
				conversationType: $conversationType
				_id: $_id
				user: $user
				driver: $driver
				shop: $shop
				repliedByAdmin: $repliedByAdmin
				closed: $closed
				driverName: $driverName
				passengerName: $passengerName
				shopName: $shopName
				driverPhoneNumber: $driverPhoneNumber
				passengerPhoneNumber: $passengerPhoneNumber
				shopPhoneNumber: $shopPhoneNumber
				title: $title
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
			}
			sort: { createdAt: $createdAtSort }
		) {
			_id
			title
			closed
			user {
				_id
				fullName
				phoneNumber
			}
			shop {
				_id
				name
				phoneNumbers
			}
			driver {
				_id
				fullName
				phoneNumber
			}
			conversationCategory
			conversationType
			adminUnreadCount
			lastMessage {
				text
			}
			messages {
				_id
				text
				senderType
				user {
					_id
					fullName
				}
				driver {
					_id
					fullName
					phoneNumber
				}
				messageType
				createdAt
				updatedAt
			}
			createdAt
			order {
				shop {
					_id
					name
					phoneNumbers
				}
				_id
				user {
					_id
					fullName
				}
				shipmentModel

				finalPrice
				commission
				createdAt
				finished
				status
				trip {
					_id
					trackId
					origin {
						coordinates
					}
					destinations {
						coordinates
					}
				}
				tracking {
					trackId
				}
				cart {
					products {
						quantity
						product {
							_id
							title
						}
					}
				}
			}
			trip {
				_id
				driver {
					_id
					fullName
					phoneNumber
				}
				passenger
				passengerDetail {
					_id
					fullName
					phoneNumber
				}
				ended
				car {
					_id
				}
				waitTimePrice
				optionsPrice
				optionsPriceDetails {
					option
					price
				}
				tipValue
				tripType
				promotion
				staticMapImageUrl
				tripDistance
				state
				cost
				tripType
				createdAt
				rate
			}
		}
	}
`;

const GET_CONVERSATIONS_COUNT = gql`
	query getConversationsCount(
		$conversationCategory: ConversationCategory
		$conversationType: ConversationType
		$_id: ID
		$user: ID
		$driver: ID
		$shop: ID
		$repliedByAdmin: Boolean
		$closed: Boolean
		$driverName: String
		$passengerName: String
		$shopName: String
		$driverPhoneNumber: String
		$passengerPhoneNumber: String
		$shopPhoneNumber: String
		$title: String
		$createdAtFrom: Date 
		$createdAt: Date
	) {
		getConversationsCount(
			filters: {
				conversationCategory: $conversationCategory
				conversationType: $conversationType
				_id: $_id
				user: $user
				driver: $driver
				shop: $shop
				repliedByAdmin: $repliedByAdmin
				closed: $closed
				driverName: $driverName
				passengerName: $passengerName
				shopName: $shopName
				driverPhoneNumber: $driverPhoneNumber
				passengerPhoneNumber: $passengerPhoneNumber
				shopPhoneNumber: $shopPhoneNumber
				title: $title
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
			}
		)
	}
`;

const ClosedConversations = () => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const typeOptions = ['ALL', 'RIDE', 'DELIVERY', 'FOOD', 'GROCERY', 'SUPPORT', 'SHOP SUPPORT'];
	const categoryOPtions = ['ALL', 'MESSAGE', 'SUPPORT TICKET'];

	const [idFilter, setIdFilter] = useState('');
	const [titleFilter, setTitleFilter] = useState('');
	const [dateFromFilter, setDateFromFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');

	const [driverNameFilter, setDriverNameFilter] = useState('');
	const [passengerNameFilter, setPassengerNameFilter] = useState('');
	const [shopNameFilter, setShopNameFilter] = useState('');
	const [driverPhoneNumberFilter, setDriverPhoneNumberFilter] = useState('');
	const [passengerPhoneNumberFilter, setPassengerPhoneNumberFilter] = useState('');
	const [shopPhoneNumberFilter, setShopPhoneNumberFilter] = useState('');
	const [dateFromFilterError, setDateFromFilterError] = useState('');
	const [dateFilterError, setDateFilterError] = useState('');

	const [typeFilter, setTypeFilter] = useState(typeOptions[0]);
	const [dateSort, setDateSort] = useState('down');
	const [categoryFilter, setCategoryFilter] = useState(categoryOPtions[0]);
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [modal, setModal] = useState(false);
	const [replyConversation, setReplyConversation] = useState();
	const [orderDetailModal, setOrderDetailModal] = useState(false);
	const [tripDetailModal, setTripDetailModal] = useState(false);
	const [itemDetail, setItemDetail] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectReview = (conversation) => {
		setModal(true);
		setReplyConversation(conversation);
	};

	const onSelectOrderDetail = (order) => {
		setOrderDetailModal(true);
		setItemDetail(order);
	};

	const onSelectTripDetail = (trip) => {
		setTripDetailModal(true);
		setItemDetail(trip);
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const [excelData, setExcelData] = useState([
		[
			'driver name',
			'driver phone No',
			'user name',
			'user phone No',
			'shop name',
			'shop phone No',
			'title',
			'date',
			'category',
			'type',
		],
	]);

	const [getConversations, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(
		GET_CONVERSATIONS
	);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getConversationsCount > 0 &&
			excelData.length === countData.getConversationsCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getConversations, (item) => [
					item.trip
						? item.trip.driver && item.trip.driver.fullName
						: item.driver
						? item.driver.fullName
						: '-',
					item.trip
						? item.trip.driver && item.trip.driver.phoneNumber
						: item.driver
						? item.driver.phoneNumber
						: '-',
					item.trip
						? item.trip.passengerDetail && item.trip.passengerDetail.fullName
						: item.user
						? item.user.fullName
						: '-',
					item.trip
						? item.trip.passengerDetail && item.trip.passengerDetail.phoneNumber
						: item.user
						? item.user.phoneNumber
						: '-',
					item.order && item.order.shop ? item.order.shop.name : '-',
					item.order &&
					item.order.shop &&
					item.order.shop.phoneNumbers &&
					item.order.shop.phoneNumbers.length > 0
						? item.order.shop.phoneNumbers[0]
						: '-',
					item.title,
					moment(item.createdAt).format('MM-DD-YYYY'),
					item.conversationCategory.replace(/_/g, ' ').toLowerCase(),
					item.conversationType.replace(/_/g, ' ').toLowerCase(),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Tickets.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getConversations({ variables: { ...variables, skip: 0, limit: countData.getConversationsCount } });
		}
	};

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		conversationCategory: undefined,
		title: undefined,
		driverName: undefined,
		driverPhoneNumber: undefined,
		passengerName: undefined,
		passengerPhoneNumber: undefined,
		shopName: undefined,
		shopPhoneNumber: undefined,
		conversationType: undefined,
		closed: true,
		createdAtSort: -1,
		createdAtFrom: undefined,
		createdAt: undefined,
	});

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('');
			}
		}
	};

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFromFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}
	}, [dateSort]);

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getConversationsCount / perPage) - 1 &&
			countData.getConversationsCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getConversationsCount !== 0) {
			setPageCount(Math.ceil(countData.getConversationsCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	const onSubmitFilter = () => {
		let hasFilter =
			driverNameFilter ||
			variables.driverName ||
			driverPhoneNumberFilter ||
			variables.driverPhoneNumber ||
			passengerNameFilter ||
			variables.passengerName ||
			passengerPhoneNumberFilter ||
			variables.passengerPhoneNumber ||
			shopNameFilter ||
			variables.shopName ||
			shopPhoneNumberFilter ||
			variables.shopPhoneNumber ||
			dateFromFilter ||
			variables.createdAtFrom ||
			dateFilter ||
			variables.createdAt ||
			titleFilter ||
			variables.title ||
			!(typeFilter === 'ALL' && !variables.conversationType) ||
			!(categoryFilter === 'ALL' && !variables.conversationCategory);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (driverNameFilter) {
				setVariables((variables) => ({
					...variables,
					driverName: driverNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, driverName: undefined }));
			}

			if (driverPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					driverPhoneNumber: driverPhoneNumberFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, driverPhoneNumber: undefined }));
			}

			if (passengerNameFilter) {
				setVariables((variables) => ({
					...variables,
					passengerName: passengerNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerName: undefined }));
			}

			if (passengerPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					passengerPhoneNumber: passengerPhoneNumberFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerPhoneNumber: undefined }));
			}

			if (shopNameFilter) {
				setVariables((variables) => ({
					...variables,
					shopName: shopNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, shopName: undefined }));
			}

			if (shopPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					shopPhoneNumber: shopPhoneNumberFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, shopPhoneNumber: undefined }));
			}

			if (titleFilter) {
				setVariables((variables) => ({
					...variables,
					title: titleFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, title: undefined }));
			}

			// if (titleFilter) {
			//   setVariables((variables) => ({
			//     ...variables,
			//     title: titleFilter.trim(),
			//   }));
			// } else {
			//   setVariables((variables) => ({ ...variables, title: undefined }));
			// }

			if (categoryFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					conversationCategory: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					conversationCategory: categoryFilter.replace(/ /g, '_').toUpperCase(),
				}));
			}
			if (typeFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					conversationType: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					conversationType: typeFilter.replace(/ /g, '_').toUpperCase(),
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			conversationCategory: undefined,
			title: undefined,
			driverName: undefined,
			driverPhoneNumber: undefined,
			passengerName: undefined,
			passengerPhoneNumber: undefined,
			shopName: undefined,
			shopPhoneNumber: undefined,
			conversationType: undefined,
			closed: true,
			createdAt: undefined,
			createdAtFrom: undefined,
			createdAtSort: -1,
		});
		setDateFromFilter('');
		setDateFilter('');
		setTypeFilter('ALL');
		setCategoryFilter('ALL');
		setTitleFilter('');
		setPassengerPhoneNumberFilter('');
		setPassengerNameFilter('');
		setShopNameFilter('');
		setShopPhoneNumberFilter('');
		setDriverPhoneNumberFilter('');
		setDriverNameFilter('');
		setDateSort('down');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	const { loading, error, data, refetch } = useQuery(GET_CONVERSATIONS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	if (data) {
		console.log(data);
	}

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_CONVERSATIONS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
			<div >
				<Button color="yellow" onClick={onSubmitFilter}>
					<SortIcon />
					Search
				</Button>
				<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
					<ClearIcon />
					Default
				</Button>
			</div>
			{data && countData && (
				<div className={style.rowConfigDriv}>
					<div className={style.itemsNumberDiv}>
						Displaying items{' '}
						<span>{countData.getConversationsCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
						<span>{pageCount * perPage + Math.min(perPage, data.getConversations.length)}</span> of{' '}
						<span>{countData.getConversationsCount}</span>
					</div>
					<div className={style.rowDropDownDiv}>
						<div className={style.rowLabel}>Row:</div>{' '}
						<CustomDropdown
							hoverColor="primary"
							buttonText={String(perPage)}
							onClick={(e) => {
								setPageCount(0)
								setPerPage(parseInt(e));
							}}
							dropdownList={perPageOptions}
						/>
					</div>
				</div>
			)}</div>
			<ReactTable
				data={data.getConversations.map((conversation) => ({
					
					driverName: conversation.trip
						? conversation.trip.driver && conversation.trip.driver.fullName
						: conversation.driver && conversation.driver.fullName,
					driverPhoneNumber: conversation.trip
						? conversation.trip.driver && conversation.trip.driver.phoneNumber
						: conversation.driver && conversation.driver.phoneNumber,
					passengerName: conversation.trip
						? conversation.trip.passengerDetail && conversation.trip.passengerDetail.fullName
						: conversation.user && conversation.user.fullName,
					passengerPhoneNumber: conversation.trip
						? conversation.trip.passengerDetail && conversation.trip.passengerDetail.phoneNumber
						: conversation.user && conversation.user.phoneNumber,
					shopName: conversation.order && conversation.order.shop && conversation.order.shop.name,
					shopPhoneNumber:
						conversation.order &&
						conversation.order.shop &&
						conversation.order.shop.phoneNumbers &&
						conversation.order.shop.phoneNumbers.length > 0 &&
						conversation.order.shop.phoneNumbers[0],
					title: conversation.title,
					createdAt: moment(conversation.createdAt).format('MM/DD/YYYY HH:mm'),
					category: conversation.conversationCategory && (
						<div
							className={`${style.inlineTag} ${
								style[conversation.conversationCategory.replace(/_/g, '').toLowerCase() + 'Tag']
							}`}
						>
							{conversation.conversationCategory.toLowerCase()}
						</div>
					),
					type: conversation.conversationType && (
						<div
							className={`${style.inlineTag} ${
								style[conversation.conversationType.replace(/_/g, '').toLowerCase() + 'Tag']
							}`}
						>
							{conversation.conversationType.toLowerCase()}
						</div>
					),
					actions: (
						<div className="actions-right">
							<IconButton
								id={conversation._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, conversation._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === conversation._id}
								onClose={handleClose}
							>
								<MenuItem onClick={() => onSelectReview(conversation)}>view messages</MenuItem>
								{conversation.order && (
									<MenuItem onClick={() => onSelectOrderDetail(conversation.order)}>
										order details
									</MenuItem>
								)}
								{conversation.trip && (
									<MenuItem onClick={() => onSelectTripDetail(conversation.trip)}>
										trip details
									</MenuItem>
								)}
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Driver Name</div>
								<CustomInput
									value={driverNameFilter}
									onChange={(e) => setDriverNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'driverName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Driver Phone No</div>
								<CustomInput
									value={driverPhoneNumberFilter}
									onChange={(e) => setDriverPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'driverPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>User Name</div>
								<CustomInput
									value={passengerNameFilter}
									onChange={(e) => setPassengerNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>User Phone No</div>
								<CustomInput
									value={passengerPhoneNumberFilter}
									onChange={(e) => setPassengerPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Shop Name</div>
								<CustomInput
									value={shopNameFilter}
									onChange={(e) => setShopNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'shopName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Shop Phone No</div>
								<CustomInput
									value={shopPhoneNumberFilter}
									onChange={(e) => setShopPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'shopPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},

					{
						Header: (
							<div>
								<div className={style.titleDiv}>Title</div>
								<CustomInput
									value={titleFilter}
									onChange={(e) => setTitleFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'title ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'title',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Date{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'createdAt',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Category</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={categoryFilter}
										onClick={(e) => setCategoryFilter(e)}
										dropdownList={categoryOPtions}
									/>
								</div>
							</div>
						),
						accessor: 'category',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Type</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={typeFilter}
										onClick={(e) => setTypeFilter(e)}
										dropdownList={typeOptions}
									/>
								</div>
							</div>
						),
						accessor: 'type',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
						minWidth: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getConversationsCount === 0 ? 1 : Math.ceil(countData.getConversationsCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={tripDetailModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<TripDetails close={() => setTripDetailModal(false)} trip={itemDetail} />
				</DialogContent>
			</Dialog>
			<Dialog open={orderDetailModal} transition={Transition} >
				<DialogContent id="modal-slide-description">
					<OrderDetails type="superAdmin" close={() => setOrderDetailModal(false)} order={itemDetail} />
				</DialogContent>
			</Dialog>
			<Dialog open={modal} transition={Transition} >
				<DialogContent id="modal-slide-description">
					<Reply
						refetch={() => refetch()}
						close={() => setModal(false)}
						justView={true}
						conversation={replyConversation}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ClosedConversations;
