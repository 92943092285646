import React , {useContext} from "react";
import { NavLink, Route, Switch, Redirect } from "react-router-dom";
import { ThemeContext } from "index";
import ClosedConversations from './ClosedConversations'
import ShopRepliedConversation from "./ShopRepliedConversation";
import ShopUnrepliedConversation from "./ShopUnrepliedConversation";
import style from "superAdminViews/restaurant/shopPage/ShopPage.module.scss";

const ShopConversations = (props) => {

  const themeContext = useContext(ThemeContext);

  const orangeTheme = {
    checkboxColor: "orange",
    primaryColor: "warning",
  };
  const greenTheme = {
    checkboxColor: "#8bc34a",
    primaryColor: "success",
  };
  const theme = themeContext === "Purchase" ? greenTheme : orangeTheme;

  return (
    <div className={style.mainDiv}>
      <div className={style.navContainer}>
      <div>
          <NavLink
            className={`${style.nav} ${style[theme.primaryColor + 'Nav']}`}
            activeClassName={`${style.activeNav} ${style[theme.primaryColor + 'ActiveNav']}`}
            to={`/shopAdmin/tickets/ClosedTickets`}
          >
            Closed conversations
          </NavLink>
        </div>
        <div>
          <NavLink
            className={`${style.nav} ${style[theme.primaryColor + 'Nav']}`}
            activeClassName={`${style.activeNav} ${style[theme.primaryColor + 'ActiveNav']}`}
            to={`/shopAdmin/tickets/RepliedTickets`}
          >
            Replied conversations
          </NavLink>
        </div>
        <div>
          <NavLink
          className={`${style.nav} ${style[theme.primaryColor + 'Nav']}`}
          activeClassName={`${style.activeNav} ${style[theme.primaryColor + 'ActiveNav']}`}
            to={`/shopAdmin/tickets/UnrepliedTickets`}
          >
            Unreplied conversations
          </NavLink>
        </div>
      </div>
      <div>
        <Switch>
        <Route
            exact={true}
            path="/shopAdmin/tickets/ClosedTickets"
            render={() => <ClosedConversations key={Math.random()} />}
          />
          <Route
            exact={true}
            path="/shopAdmin/tickets/RepliedTickets"
            render={() => <ShopRepliedConversation key={Math.random()} />}
          />
          <Route
            exact={true}
            path="/shopAdmin/tickets/UnrepliedTickets"
            render={() => <ShopUnrepliedConversation key={Math.random()} />}
          />

          <Redirect
            from="/shopAdmin/tickets"
            to="/shopAdmin/tickets/ClosedTickets"
          />
        </Switch>
      </div>
    </div>
  );
};

export default ShopConversations;