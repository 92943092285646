import Buttons from 'views/Components/Buttons.js';
import Calendar from 'views/Calendar/Calendar.js';
import Charts from 'views/Charts/Charts.js';
import Dashboard from 'views/Dashboard/Dashboard.js';
import ErrorPage from 'views/Pages/ErrorPage.js';
import ExtendedForms from 'views/Forms/ExtendedForms.js';
import ExtendedTables from 'views/Tables/ExtendedTables.js';
import FullScreenMap from 'views/Maps/FullScreenMap.js';
import GoogleMaps from 'views/Maps/GoogleMaps.js';
import GridSystem from 'views/Components/GridSystem.js';
import Icons from 'views/Components/Icons.js';
import StoreIcon from '@material-ui/icons/Store';
import LockScreenPage from 'views/Pages/LockScreenPage.js';
import LoginPage from 'views/Pages/LoginPage.js';
import Notifications from 'views/Components/Notifications.js';
import Panels from 'views/Components/Panels.js';
import PricingPage from 'views/Pages/PricingPage.js';
import RTLSupport from 'views/Pages/RTLSupport.js';
import ReactTables from 'views/Tables/ReactTables.js';
import RegisterPage from 'views/Pages/RegisterPage.js';
import RegularForms from 'views/Forms/RegularForms.js';
import RegularTables from 'views/Tables/RegularTables.js';
import SweetAlert from 'views/Components/SweetAlert.js';
import TimelinePage from 'views/Pages/Timeline.js';
import Typography from 'views/Components/Typography.js';
import UserProfile from 'views/Pages/UserProfile.js';
import ValidationForms from 'views/Forms/ValidationForms.js';
import VectorMap from 'views/Maps/VectorMap.js';
import Widgets from 'views/Widgets/Widgets.js';
import Wizard from 'views/Forms/Wizard.js';

// shop admin views
import Orders from 'shopAdminViews/orders/Orders';
import OrdersHistory from 'shopAdminViews/ordersHistory/OrdersHistory';
import Logout from 'commonComponents/logout/Logout';
import CommentPage from 'superAdminViews/ride/comments/CommentPage';
import ChangePassword from 'shopAdminViews/changePassword/ChangePassword';
import ShopProducts from 'shopAdminViews/products/ShopProducts';
import Reviews from 'shopAdminViews/Reviews/Reviews';
import Cars from 'superAdminViews/ride/cars/Cars';
import ShopPromotions from 'shopAdminViews/promotions/Promotions';
import ShopConversations from 'shopAdminViews/conversations/ShopConversations';
import ShopPayments from 'shopAdminViews/finance/Payments';
import ShopTransactions from 'shopAdminViews/finance/Transactions';
import EditShop from 'shopAdminViews/editShop/EditShop';
import ShopAdminDashboard from 'shopAdminViews/dashboard/Dashboard';
import ShopAddShopMenu from 'shopAdminViews/addShopMenu/ShopAddShopMenu';
import ShopAddPromotion from 'shopAdminViews/addPromotion/ShopAddPromotion';

// super admin views
import SuperAdminDashBoard from 'superAdminViews/dashboard/DashBoard';
import Users from 'superAdminViews/users/Users';
import Constants from 'superAdminViews/setting/constants/Constants';
import Restaurants from 'superAdminViews/restaurant/restaurants/Restaurants';
import Promotions from 'superAdminViews/ride/promotions/Promotions';
import States from 'superAdminViews/setting/states/States';
import Conversations from 'superAdminViews/conversations/Conversation';
import Drivers from 'superAdminViews/ride/drivers/Drivers';
import Trips from 'superAdminViews/ride/trips/Trips';
import Transactions from 'superAdminViews/finance/transactions/Transactions';
import Pay from 'superAdminViews/finance/pay/Pay';
import TotalFinance from 'superAdminViews/finance/TotalFinance';
import Payments from 'superAdminViews/finance/payments/Payments';
import SendNotif from 'superAdminViews/setting/sendNotif/SendNotif';
import Defaults from 'superAdminViews/setting/defaults/Defaults';
import CarOptions from 'superAdminViews/setting/carOptions/CarOptions';
import ProductOptions from 'superAdminViews/setting/productOptions/ProductOptions';
import Admins from 'superAdminViews/admins/admins/Admins';
import ShopPromotion from 'superAdminViews/restaurant/promotions/ShopPromotions';
import Advertisements from 'superAdminViews/advertisements/advertisements/Advertisements';

// @material-ui/icons
import Apps from '@material-ui/icons/Apps';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import EmailIcon from '@material-ui/icons/Email';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SettingsIcon from '@material-ui/icons/Settings';
import MoneyIcon from '@material-ui/icons/Money';
// import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRange from '@material-ui/icons/DateRange';
// import CardTravelSharpIcon from '@material-ui/icons/CardTravelSharp';
import GridOn from '@material-ui/icons/GridOn';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import Image from '@material-ui/icons/Image';
import Place from '@material-ui/icons/Place';
import Timeline from '@material-ui/icons/Timeline';
import WidgetsIcon from '@material-ui/icons/Widgets';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';

var dashRoutes = [
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: DashboardIcon,
		component: SuperAdminDashBoard,
		layout: '/superAdmin',
	},
	{
		collapse: true,
		layout: '/superAdmin',
		name: 'Ride and delivery',
		state: 'rideCollapse',
		icon: DirectionsCarIcon,
		state: 'rideCollapse',
		views: [
			{
				path: '/trips',
				name: 'Trips',
				component: Trips,
				permissionName: 'getTripsByAdmin',
				layout: '/superAdmin',
			},

			{
				path: '/vehicles',
				name: 'Vehicles',
				component: Cars,
				permissionName: 'getCarsByAdmin',
				layout: '/superAdmin',
			},
			{
				path: '/evaluations',
				name: 'Evaluations',
				component: CommentPage,
				permissionName: 'getCommentsOnDriverByAdmin',
				layout: '/superAdmin',
			},
			// {
			// 	path: '/addDriver',
			// 	name: 'Add driver',
			// 	component: AddDriver,
			// 	layout: '/superAdmin',
			// },
			// {
			// 	path: '/signupDriver',
			// 	name: 'Signup driver',
			// 	component: SignupDriver,
			// 	permissionName: 'driverSignUpByAdmin',
			// 	layout: '/superAdmin',
			// },
			// {
			// 	path: '/verifications',
			// 	name: 'Verifications',
			// 	component: Verifications,
			// 	permissionName: 'getDriversVerificationRequestsByAdmin',
			// 	layout: '/superAdmin',
			// },
			// {
			// 	path: '/addCar',
			// 	name: 'Add car for driver',
			// 	component: AddCar,
			// 	permissionName: 'addCarByAdmin',
			// 	layout: '/superAdmin',
			// },
			{
				path: '/tripsPromotions',
				name: 'Trips promotions',
				component: Promotions,
				permissionName: 'getTripPromotions',
				layout: '/superAdmin',
			},
		],
	},
	{
		collapse: true,
		layout: '/superAdmin',
		name: 'Shops and Restaurants',
		icon: StoreIcon,
		state: 'restaurantCollapse',
		views: [
			{
				path: '/shopsAndRestaurants',
				name: 'Shops and Restaurants',
				component: Restaurants,
				permissionName: 'getShopsByAdmin',
				layout: '/superAdmin',
			},
			// {
			// 	path: '/shopVerifications',
			// 	name: 'Verifications',
			// 	component: ShopVerifications,
			// 	permissionName: 'getWaitingVerificationStateShopAdminsByAdmin',
			// 	layout: '/superAdmin',
			// },
			// {
			// 	path: '/addShop',
			// 	name: 'Add shop',
			// 	component: SuperAddShop,
			// 	permissionName: 'createShopByAdmin',
			// 	layout: '/superAdmin',
			// },
			{
				path: '/promotions',
				name: 'Promotions',
				component: ShopPromotion,
				layout: '/superAdmin',
			},
			// {
			//   path: "/shopAddPromotion",
			//   name: "Add Promotion",
			//   component: AddShopPromotion,
			//   layout: "/superAdmin",
			// },
			// {
			//   path: "/addPromotion",
			//   name: "AddPromotion",
			//   icon: AddIcon,
			//   component: AddRidePromotion,
			//   layout: "/superAdmin",
			// },
		],
	},

	{
		collapse: true,
		layout: '/superAdmin',
		name: 'Users',
		icon: PeopleOutlineIcon,
		permissionName: 'getUsersByAdmin',
		state: 'usersCollapse',
		views: [
			{
				path: '/customers',
				name: 'Customers',
				component: Users,
				permissionName: 'getUsersByAdmin',
				layout: '/superAdmin',
			},
			{
				path: '/drivers',
				name: 'Drivers',
				component: Drivers,
				permissionName: 'getDriversByAdmin',
				layout: '/superAdmin',
			},
			{
				path: '/admins',
				name: 'Admins',
				component: Admins,
				permissionName: 'getAdminsByAdmin',
				layout: '/superAdmin',
			},
		],
	},
	{
		collapse: true,
		layout: '/superAdmin',
		name: 'Finance',
		icon: MoneyIcon,
		state: 'financeCollapse',
		views: [
			{
				path: '/finance',
				name: 'Finance',
				component: TotalFinance,
				layout: '/superAdmin',
			},
			{
				path: '/payments',
				name: 'Payments',
				component: Payments,
				layout: '/superAdmin',
			},
			{
				path: '/transactions',
				name: 'Transactions',
				component: Transactions,
				layout: '/superAdmin',
			},
			{
				path: '/toBePaid',
				name: 'To be paid',
				component: Pay,
				layout: '/superAdmin',
			},
		],
	},

	{
		layout: '/superAdmin',
		name: 'Tickets',
		path: '/tickets',
		icon: EmailIcon,
		component: Conversations,
	},

	{
		path: '/advertisements',
		name: 'Advertisements',
		component: Advertisements,
		icon: FeaturedVideoIcon,
		permissionName: 'getAdvertisements',
		layout: '/superAdmin',
	},

	{
		collapse: true,
		layout: '/superAdmin',
		name: 'Setting',
		icon: SettingsIcon,
		state: 'settingCollapse',
		views: [
			// {
			// 	layout: '/superAdmin',
			// 	name: 'Roles',
			// 	path: '/rolePage',
			// 	component: RolePage,
			// 	permissionName: 'roles',
			// },

			{
				path: '/sendNotification',
				name: 'Send notification',
				component: SendNotif,
				layout: '/superAdmin',
			},
			{
				path: '/defaults',
				name: 'Defaults',
				component: Defaults,
				layout: '/superAdmin',
			},
			{
				path: '/vehicleOptions',
				name: 'Vehicle options',
				component: CarOptions,
				layout: '/superAdmin',
			},
			{
				path: '/type&cuisine',
				name: 'Type/Cuisine',
				component: ProductOptions,
				layout: '/superAdmin',
			},
			{
				path: '/constants',
				name: 'Constants',
				component: Constants,
				layout: '/superAdmin',
			},
			{
				path: '/provinces',
				name: 'Provinces',
				component: States,
				layout: '/superAdmin',
			},
		],
	},
	// {
	// 	path: '/logout',
	// 	name: 'Log out',
	// 	icon: ExitToAppIcon,
	// 	component: Logout,
	// 	layout: '/superAdmin',
	// },
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: DashboardIcon,
		component: ShopAdminDashboard,
		layout: '/shopAdmin',
	},

	{
		path: window.localStorage.getItem('shopType') === 'Restaurant' ? '/foods' : '/products',
		name: window.localStorage.getItem('shopType') === 'Restaurant' ? 'Foods' : 'Products',
		icon: FastfoodIcon,
		component: ShopProducts,
		layout: '/shopAdmin',
	},
	{
		path: window.localStorage.getItem('shopType') === 'Restaurant' ? '/menu' : '/categories',
		name: window.localStorage.getItem('shopType') === 'Restaurant' ? 'Menu' : 'Categories',
		icon: ListAltOutlinedIcon,
		component: ShopAddShopMenu,
		layout: '/shopAdmin',
	},
	{
		collapse: true,
		layout: '/shopAdmin',
		name: 'Orders',
		icon: ListAltOutlinedIcon,
		state: 'ordersCollapse',
		views: [
			{
				path: '/orders',
				name: 'Orders',
				component: Orders,
				layout: '/shopAdmin',
			},
			{
				path: '/orderHistory',
				name: 'Orders history',
				component: OrdersHistory,
				layout: '/shopAdmin',
			},
		],
	},

	{
		path: '/promotions',
		name: 'Promotions',
		component: ShopPromotions,
		layout: '/shopAdmin',
		icon: MoneyOffIcon,
	},

	{
		layout: '/shopAdmin',
		name: 'Tickets',
		path: '/tickets',
		icon: EmailIcon,
		component: ShopConversations,
	},
	{
		collapse: true,
		layout: '/shopAdmin',
		name: 'Finance',
		icon: MoneyIcon,
		state: 'financeCollapse',
		views: [
			{
				path: '/payments',
				name: 'Payments',
				component: ShopPayments,
				layout: '/shopAdmin',
			},
			{
				path: '/transactions',
				name: 'Transactions',
				component: ShopTransactions,
				layout: '/shopAdmin',
			},
		],
	},
	{
		path: '/editShop',
		name: 'Edit shop',
		path: window.localStorage.getItem('shopType') === 'Restaurant' ? '/editRestaurant' : '/editShop',
		name: window.localStorage.getItem('shopType') === 'Restaurant' ? 'Edit restaurant' : 'Edit shop',
		icon: SettingsIcon,
		component: EditShop,
		layout: '/shopAdmin',
	},
	// {
	//   collapse: true,
	//   layout: "/shopAdmin",
	//   name: "Setting",
	//   icon: SettingsIcon,
	//   views: [
	//     {
	//       path: "/manageHours",
	//       name: "Manage Hours",
	//       icon: QueryBuilderIcon,
	//       component: ManageHours,
	//       layout: "/shopAdmin",
	//     },
	//   ],
	// },

	{
		path: '/reviews',
		name: 'Reviews',
		icon: SmsOutlinedIcon,
		component: Reviews,
		layout: '/shopAdmin',
	},
	{
		path: '/changePassword',
		name: 'Change password',
		icon: LockOpenIcon,
		component: ChangePassword,
		layout: '/shopAdmin',
	},
	// {
	// 	path: '/logout',
	// 	name: 'Log out',
	// 	icon: ExitToAppIcon,
	// 	component: Logout,
	// 	layout: '/shopAdmin',
	// },
	{
		path: '/dashboard',
		name: 'Dashboard',
		rtlName: 'لوحة القيادة',
		icon: DashboardIcon,
		component: Dashboard,
		layout: '/admin',
	},
	{
		collapse: true,
		name: 'Pages',
		rtlName: 'صفحات',
		icon: Image,
		state: 'pageCollapse',
		layout: '/admin',
		views: [
			{
				path: '/pricing-page',
				name: 'Pricing Page',
				rtlName: 'عالتسعير',
				mini: 'PP',
				rtlMini: 'ع',
				component: PricingPage,
				layout: '/auth',
			},
			{
				path: '/rtl-support-page',
				name: 'RTL Support',
				rtlName: 'صودعم رتل',
				mini: 'RS',
				rtlMini: 'صو',
				component: RTLSupport,
				layout: '/rtl',
			},
			{
				path: '/timeline-page',
				name: 'Timeline Page',
				rtlName: 'تيالجدول الزمني',
				mini: 'T',
				rtlMini: 'تي',
				component: TimelinePage,
				layout: '/admin',
			},
			{
				path: '/login-page',
				name: 'Login Page',
				rtlName: 'هعذاتسجيل الدخول',
				mini: 'L',
				rtlMini: 'هعذا',
				component: LoginPage,
				layout: '/auth',
			},
			{
				path: '/register-page',
				name: 'Register Page',
				rtlName: 'تسجيل',
				mini: 'R',
				rtlMini: 'صع',
				component: RegisterPage,
				layout: '/auth',
			},
			{
				path: '/lock-screen-page',
				name: 'Lock Screen Page',
				rtlName: 'اقفل الشاشة',
				mini: 'LS',
				rtlMini: 'هذاع',
				component: LockScreenPage,
				layout: '/auth',
			},
			{
				path: '/user-page',
				name: 'User Profile',
				rtlName: 'ملف تعريفي للمستخدم',
				mini: 'UP',
				rtlMini: 'شع',
				component: UserProfile,
				layout: '/admin',
			},
			{
				path: '/error-page',
				name: 'Error Page',
				rtlName: 'صفحة الخطأ',
				mini: 'E',
				rtlMini: 'البريد',
				component: ErrorPage,
				layout: '/auth',
			},
		],
	},
	{
		collapse: true,
		name: 'Components',
		rtlName: 'المكونات',
		icon: Apps,
		state: 'componentsCollapse',
		layout: '/admin',
		views: [
			{
				collapse: true,
				name: 'Multi Level Collapse',
				rtlName: 'انهيار متعدد المستويات',
				mini: 'MC',
				rtlMini: 'ر',
				state: 'multiCollapse',
				views: [
					{
						path: '/buttons',
						name: 'Buttons',
						rtlName: 'وصفت',
						mini: 'B',
						rtlMini: 'ب',
						component: Buttons,
						layout: '/admin',
					},
				],
			},
			{
				path: '/buttons',
				name: 'Buttons',
				rtlName: 'وصفت',
				mini: 'B',
				rtlMini: 'ب',
				component: Buttons,
				layout: '/admin',
			},
			{
				path: '/grid-system',
				name: 'Grid System',
				rtlName: 'نظام الشبكة',
				mini: 'GS',
				rtlMini: 'زو',
				component: GridSystem,
				layout: '/admin',
			},
			{
				path: '/panels',
				name: 'Panels',
				rtlName: 'لوحات',
				mini: 'P',
				rtlMini: 'ع',
				component: Panels,
				layout: '/admin',
			},
			{
				path: '/sweet-alert',
				name: 'Sweet Alert',
				rtlName: 'الحلو تنبيه',
				mini: 'SA',
				rtlMini: 'ومن',
				component: SweetAlert,
				layout: '/admin',
			},
			{
				path: '/notifications',
				name: 'Notifications',
				rtlName: 'إخطارات',
				mini: 'N',
				rtlMini: 'ن',
				component: Notifications,
				layout: '/admin',
			},
			{
				path: '/icons',
				name: 'Icons',
				rtlName: 'الرموز',
				mini: 'I',
				rtlMini: 'و',
				component: Icons,
				layout: '/admin',
			},
			{
				path: '/typography',
				name: 'Typography',
				rtlName: 'طباعة',
				mini: 'T',
				rtlMini: 'ر',
				component: Typography,
				layout: '/admin',
			},
		],
	},
	{
		collapse: true,
		name: 'Forms',
		rtlName: 'إستمارات',
		icon: 'content_paste',
		state: 'formsCollapse',
		layout: '/admin',
		views: [
			{
				path: '/regular-forms',
				name: 'Regular Forms',
				rtlName: 'أشكال عادية',
				mini: 'RF',
				rtlMini: 'صو',
				component: RegularForms,
				layout: '/admin',
			},
			{
				path: '/extended-forms',
				name: 'Extended Forms',
				rtlName: 'نماذج موسعة',
				mini: 'EF',
				rtlMini: 'هوو',
				component: ExtendedForms,
				layout: '/admin',
			},
			{
				path: '/validation-forms',
				name: 'Validation Forms',
				rtlName: 'نماذج التحقق من الصحة',
				mini: 'VF',
				rtlMini: 'تو',
				component: ValidationForms,
				layout: '/admin',
			},
			{
				path: '/wizard',
				name: 'Wizard',
				rtlName: 'ساحر',
				mini: 'W',
				rtlMini: 'ث',
				component: Wizard,
				layout: '/admin',
			},
		],
	},
	{
		collapse: true,
		name: 'Tables',
		rtlName: 'الجداول',
		icon: GridOn,
		state: 'tablesCollapse',
		layout: '/admin',
		views: [
			{
				path: '/regular-tables',
				name: 'Regular Tables',
				rtlName: 'طاولات عادية',
				mini: 'RT',
				rtlMini: 'صر',
				component: RegularTables,
				layout: '/admin',
			},
			{
				path: '/extended-tables',
				name: 'Extended Tables',
				rtlName: 'جداول ممتدة',
				mini: 'ET',
				rtlMini: 'هور',
				component: ExtendedTables,
				layout: '/admin',
			},
			{
				path: '/react-tables',
				name: 'React Tables',
				rtlName: 'رد فعل الطاولة',
				mini: 'RT',
				rtlMini: 'در',
				component: ReactTables,
				layout: '/admin',
			},
		],
	},
	{
		collapse: true,
		name: 'Maps',
		rtlName: 'خرائط',
		icon: Place,
		state: 'mapsCollapse',
		layout: '/admin',
		views: [
			{
				path: '/google-maps',
				name: 'Google Maps',
				rtlName: 'خرائط جوجل',
				mini: 'GM',
				rtlMini: 'زم',
				component: GoogleMaps,
				layout: '/admin',
			},
			{
				path: '/full-screen-maps',
				name: 'Full Screen Map',
				rtlName: 'خريطة كاملة الشاشة',
				mini: 'FSM',
				rtlMini: 'ووم',
				component: FullScreenMap,
				layout: '/admin',
			},
			{
				path: '/vector-maps',
				name: 'Vector Map',
				rtlName: 'خريطة المتجه',
				mini: 'VM',
				rtlMini: 'تم',
				component: VectorMap,
				layout: '/admin',
			},
		],
	},
	{
		path: '/widgets',
		name: 'Widgets',
		rtlName: 'الحاجيات',
		icon: WidgetsIcon,
		component: Widgets,
		layout: '/admin',
	},
	{
		path: '/charts',
		name: 'Charts',
		rtlName: 'الرسوم البيانية',
		icon: Timeline,
		component: Charts,
		layout: '/admin',
	},
	{
		path: '/calendar',
		name: 'Calendar',
		rtlName: 'التقويم',
		icon: DateRange,
		component: Calendar,
		layout: '/admin',
	},
];
export default dashRoutes;
