import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { css } from '@emotion/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReactTable from 'react-table';
import BeatLoader from 'react-spinners/BeatLoader';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Dialog from '@material-ui/core/Dialog';
import Pagination from 'commonComponents/pagination/Pagination';
import Modal from 'commonComponents/modal/Modal';
import EditItem from 'superAdminViews/setting/defaults/EditItem';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Checkbox from '@material-ui/core/Checkbox';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import style from './DriverReadyComments.module.scss';

const ADD_READY_COMMENT = gql`
	mutation createPassengerReadyCommentByAdmin($type: String!) {
		createPassengerReadyCommentByAdmin(input: { type: $type }) {
			_id
			type
		}
	}
`;

const GET_PASSENGER_READY_COMMENTS = gql`
	query getPassengerReadyCommentsByAdmin($limit: Int, $skip: Int) {
		getPassengerReadyCommentsByAdmin(pagination: { limit: $limit, skip: $skip }) {
			_id
			type
		}
	}
`;

const GET_PASSENGER_READY_COMMENTS_COUNT = gql`
	query getPassengerReadyCommentsByAdminCount {
		getPassengerReadyCommentsByAdminCount
	}
`;

const DELETE_PASSENGER_READY_COMMENT = gql`
	mutation removePassengerReadyCommentByAdmin($idSet: [ID!]!) {
		removePassengerReadyCommentByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const PassengerReadyComments = () => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);
	const [text, setText] = useState('');

	const [textError, setTextError] = useState(false);
	const [addModal, setAddModal] = useState(false);
	const [modal, setModal] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [commentDetails, setCommentDetails] = useState();

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [itemForDelete, setItemForDelete] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getPassengerReadyCommentsByAdmin.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	// testing menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onDeleteItem = (item) => {
		setDeleteModal(true);
		setItemForDelete([item._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const deleteItem = () => {
		removePassengerReadyCommentByAdmin({ variables: { idSet: itemForDelete } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		removePassengerReadyCommentByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const [
		removePassengerReadyCommentByAdmin,
		{ data: deletePassengerReadyCommentData, error: deletePassengerReadyCommentError },
	] = useMutation(DELETE_PASSENGER_READY_COMMENT);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
	});

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getPassengerReadyCommentsByAdminCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getPassengerReadyCommentsByAdminCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	const onSelectCommentDetail = (comment) => {
		setModal(true);
		setCommentDetails(comment);
	};

	const [createPassengerReadyCommentByAdmin, { data: addMutationData, error: addMutationError }] = useMutation(
		ADD_READY_COMMENT
	);

	useEffect(() => {
		if (addMutationData) {
			setAddModal(true);
		}
	}, [addMutationData]);

	const { loading, error, data, refetch } = useQuery(GET_PASSENGER_READY_COMMENTS, {
		variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_PASSENGER_READY_COMMENTS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const onSubmit = () => {
		if (!text) {
			setTextError(true);
		} else {
			setTextError(false);
		}

		if (text) {
			setDisableButton(true);
			createPassengerReadyCommentByAdmin({ variables: { type: text } })
				.then((res) => {
					setDisableButton(false);
					refetch();
					setText('');
				})
				.catch((err) => {
					console.log(err);
					setDisableButton(false);
				});
		}
	};

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}
	return (
		<div className={style.mainDiv}>
			<div className={style.inputRow}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Title:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={text}
										onChange={(e) => setText(e.target.value)}
										error={textError}
										labelText={textError ? "title can't be empty*" : 'title'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.inputButtonContainer}>
							<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
								Add
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			{idsForDelete.length > 0 && (
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<Button title="delete selected items" fullWidth color="redButton" onClick={onDeleteSome}>
							Delete
						</Button>
					</GridItem>
				</GridContainer>
			)}

			<ReactTable
				data={data.getPassengerReadyCommentsByAdmin.map((comment) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(comment._id)}
								onChange={() => {
									if (idsForDelete.includes(comment._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== comment._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, comment._id]);
									}
								}}
							/>
						</div>
					),
					text: <div className={style.leftAlignCell}>{comment.type}</div>,
					actions: (
						<div>
							<IconButton
								id={comment._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, comment._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === comment._id}
								onClose={handleClose}
							>
								<MenuItem onClick={() => onSelectCommentDetail(comment)}>edit</MenuItem>
								<MenuItem onClick={() => onDeleteItem(comment)}>delete</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div className={style.fitTitleDivCheckbox}>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getPassengerReadyCommentsByAdmin.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(
													data.getPassengerReadyCommentsByAdmin,
													(item) => item._id
												),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: <div className={`${style.fitTitleDiv} ${style.leftAlignCell}`}>Text</div>,
						accessor: 'text',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>

			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={Math.ceil(countData.getPassengerReadyCommentsByAdminCount / perPage)}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<EditItem
						refetch={() => refetch()}
						close={() => setModal(false)}
						type="passengerReadyComment"
						comment={commentDetails}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setAddModal(false)}
						type="notif"
						text="Passenger ready comment added succesfully."
					/>
				</DialogContent>
			</Dialog>
			{itemForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteItem}
							text="Do you want to delete this item?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these items?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default PassengerReadyComments;
