import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import moment from 'moment';
import { endPoint } from 'utils/config';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Files from 'react-butterfiles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { graphqlUrl } from 'utils/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from 'components/Grid/GridContainer.js';
import Datetime from 'react-datetime';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import style from 'superAdminViews/ride/addDriver/AddDriver.module.scss';

const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!, $folderName: String!) {
		uploadFile(data: { file: $file, folderName: $folderName }) {
			url
		}
	}
`;

const Step2 = (props) => {
	const [licenceImage, setLicenceImage] = useState();
	const [profileImage, setProfileImage] = useState();
	const [licenceId, setLicenceId] = useState('fakeLicenceId');
	const [licenceExpireDate, setLicenceExpireDate] = useState('');
	const [recordImage, setRecordImage] = useState();
	const [passportCardImage, setpassportCardImage] = useState();
	const [passportExpireDate, setpassportExpireDate] = useState('');
	const [backgroundCheckImage, setbackgroundCheckImage] = useState();

	const [licenceExpireDateError, setLicenceExpireDateError] = useState('');
	const [profileImageError, setProfileImageError] = useState(false);
	const [passportExpireDateError, setPassportExpireDateError] = useState('');
	const [licenceImageError, setLicenceImageError] = useState(false);
	const [recordImageError, setRecordImageError] = useState(false);
	const [passportCardImageError, setPassportCardImageError] = useState(false);
	const [backgroundCheckImageError, setBackgroundCheckImageError] = useState(false);

	const [spinnerIndex, setSpinnerIndex] = useState();

	// block navigation

	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

	useEffect(() => {
		if (shouldBlockNavigation) {
			window.onbeforeunload = () => true;
		} else {
			window.onbeforeunload = undefined;
		}
	}, [shouldBlockNavigation]);

	useEffect(() => {
		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	const [uploadFile, { data: uploadData, error: uploadError, loading: uploadLoading }] = useMutation(UPLOAD_FILE);

	const uploadProfileImage = (file) => {
		setSpinnerIndex(1);
		uploadFile({
			variables: { file: file.src.file, folderName: '' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			setProfileImage(res.data.uploadFile.url);
		});
	};

	const uploadLicenceImage = (file) => {
		setSpinnerIndex(2);
		uploadFile({
			variables: { file: file.src.file, folderName: '' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			setLicenceImage(res.data.uploadFile.url);
		});
	};

	const uploadRecordImage = (file) => {
		setSpinnerIndex(3);
		uploadFile({
			variables: { file: file.src.file, folderName: '' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			setRecordImage(res.data.uploadFile.url);
		});
	};

	const uploadPassportCardImage = (file) => {
		setSpinnerIndex(4);
		uploadFile({
			variables: { file: file.src.file, folderName: '' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			setpassportCardImage(res.data.uploadFile.url);
		});
	};

	const uploadBackgroudCheckImage = (file) => {
		setSpinnerIndex(5);
		uploadFile({
			variables: { file: file.src.file, folderName: '' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			setbackgroundCheckImage(res.data.uploadFile.url);
		});
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			console.log('stage 2');
			onSubmit();
		}
	};

	const onCancel = () => {
		window.onbeforeunload = null;
		setShouldBlockNavigation(false);
			window.location.href = endPoint + `/superAdmin/addDriver`;
		
	};

	const onSubmit = () => {
		if (!licenceExpireDate) {
			setLicenceExpireDateError("expire date can't be empty*");
		} else {
			if (
				moment(licenceExpireDate, 'MM/DD/YYYY', true).isValid() ||
				moment(licenceExpireDate, 'MM-DD-YYYY', true).isValid()
			) {
				if (moment(licenceExpireDate) < moment().add(3, 'months')) {
					setLicenceExpireDateError('expire date must be at least 3 month later from now*');
				} else {
					setLicenceExpireDateError('');
				}
			} else {
				setLicenceExpireDateError('invalid date');
			}
		}

		if (!passportExpireDate) {
			setPassportExpireDateError("expire date can't be empty*");
		} else {
			if (
				moment(passportExpireDate, 'MM/DD/YYYY', true).isValid() ||
				moment(passportExpireDate, 'MM-DD-YYYY', true).isValid()
			) {
				if (moment(passportExpireDate) < moment().add(3, 'months')) {
					setPassportExpireDateError('expire date must be at least 3 month later from now*');
				} else {
					setPassportExpireDateError('');
				}
			} else {
				setPassportExpireDateError('invalid date');
			}
		}
		if (!licenceImage) {
			setLicenceImageError(true);
		} else {
			setLicenceImageError(false);
		}
		if (!recordImage) {
			setRecordImageError(true);
		} else {
			setRecordImageError(false);
		}

		if (!passportCardImage) {
			setPassportCardImageError(true);
		} else {
			setPassportCardImageError(false);
		}
		if (!profileImage) {
			setProfileImageError(true);
		} else {
			setProfileImageError(false);
		}
		if (!backgroundCheckImage) {
			setBackgroundCheckImageError(true);
		} else {
			setBackgroundCheckImageError(false);
		}

		if (
			licenceExpireDate &&
			passportExpireDate &&
			(moment(passportExpireDate, 'MM/DD/YYYY', true).isValid() ||
				moment(passportExpireDate, 'MM-DD-YYYY', true).isValid()) &&
			(moment(licenceExpireDate, 'MM/DD/YYYY', true).isValid() ||
				moment(licenceExpireDate, 'MM-DD-YYYY', true).isValid()) &&
			backgroundCheckImage &&
			passportCardImage &&
			licenceImage &&
			recordImage &&
			profileImage &&
			moment(licenceExpireDate) >= moment().add(3, 'months') &&
			moment(passportExpireDate) >= moment().add(3, 'months')
		) {
			if (moment().add(6, 'months') > moment(licenceExpireDate)) {
				props.setWarning('license expires in 6 month, please update it.');
			}
			if (moment().add(6, 'months') > moment(passportExpireDate)) {
				props.setWarning('Warning! passport expires in 6 month, please update it.');
			}
			props.addInfo({
				drivingLicence: {
					licenceId,
					photoUrl: licenceImage,
					expireDate: moment(licenceExpireDate).format(),
				},
				profileImageUrl: profileImage,
				drivingRecordPhotoUrl: recordImage,
				canadianVerificationPhotoUrl: passportCardImage,
				canadianVerificationExpireDate: moment(passportExpireDate).format(),
				backgroundCheckDocumentPhotoUrl: backgroundCheckImage,
			});
		}
	};

	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDiv}>
			<Prompt when={shouldBlockNavigation} message="You have unsaved changes, are you sure you want to leave?" />
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div className={style.leftSide}>
							<div className={style.imageRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.imageLabel}>Profile photo:</div>
										<div className={style.imageHint}>(upload an image less than 10MB)</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.imageContainer}>
											<Files
												maxSize="10mb"
												multiple={false}
												convertToBase64={true}
												accept={['image/jpg', 'image/jpeg', 'image/png']}
												onSuccess={(file) => uploadProfileImage(file[0])}
												onError={(errors) => setProfileImageError(true)}
											>
												{({ browseFiles, getDropZoneProps, getLabelProps }) => (
													<div style={{ textAlign: 'right' }}>
														{profileImage && (
															<div>
																<img
																	style={{ width: '200px', height: '200px' }}
																	alt="advertisement photo"
																	src={`${graphqlUrl}${profileImage}`}
																/>
															</div>
														)}
														{uploadLoading && spinnerIndex === 1 && (
															<CircularProgress style={{ color: 'gray' }} />
														)}
														<div {...getDropZoneProps({ className: 'myDropZone' })} />
														<Button
															color="whiteButton"
															style={{ marginTop: '38px', marginRight: '10px' }}
															onClick={browseFiles}
														>
															Select Photo...
														</Button>
														{profileImageError && (
															<p className={style.imageError}>
																please enter image file less than 10MB size
															</p>
														)}
													</div>
												)}
											</Files>
										</div>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.imageRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.imageLabel}>Driving license:</div>
										<div className={style.imageHint}>(upload an image or pdf less than 10MB)</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.imageContainer}>
											<Files
												maxSize="10mb"
												multiple={false}
												convertToBase64={true}
												accept={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
												onSuccess={(file) => uploadLicenceImage(file[0])}
												onError={(errors) => setLicenceImageError(true)}
											>
												{({ browseFiles, getDropZoneProps, getLabelProps }) => (
													<div style={{ textAlign: 'right' }}>
														{licenceImage &&
															(licenceImage.match(/\.pdf$/) ? (
																<a
																	title="open file"
																	target="_blank"
																	href={`${graphqlUrl}/${licenceImage}`}
																>
																	<InsertDriveFileIcon
																		style={{ color: 'gray', fontSize: '50px' }}
																	/>
																</a>
															) : (
																<div>
																	<img
																		style={{ width: '200px', height: '200px' }}
																		alt="advertisement photo"
																		src={`${graphqlUrl}${licenceImage}`}
																	/>
																</div>
															))}
														{uploadLoading && spinnerIndex === 2 && (
															<CircularProgress style={{ color: 'gray' }} />
														)}
														<div {...getDropZoneProps({ className: 'myDropZone' })} />
														<Button
															color="whiteButton"
															style={{ marginTop: '38px', marginRight: '10px' }}
															onClick={browseFiles}
														>
															Select photo or pdf
														</Button>
														{licenceImageError && (
															<p className={style.imageError}>
																please enter image or pdf file less than 10MB size
															</p>
														)}
													</div>
												)}
											</Files>
										</div>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.dateRow}>
								<GridContainer>
									<GridItem xs={12} sm={8} md={8} lg={6}>
										<div className={style.dateLabel}>License expire date:</div>
									</GridItem>
									<GridItem xs={12} sm={4} md={4} lg={6}>
										{licenceExpireDateError && (
											<div className={style.dateError}>{licenceExpireDateError}</div>
										)}
										<Datetime
											timeFormat={false}
											disableOnClickOutside
											value={licenceExpireDate}
											closeOnSelect
											onChange={(date) => setLicenceExpireDate(date)}
											inputProps={{
												placeholder: 'license expire day',
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.imageRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.imageLabel}>Driving record:</div>
										<div className={style.imageHint}>(upload an image or pdf less than 10MB)</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.imageContainer}>
											<Files
												maxSize="10mb"
												multiple={false}
												convertToBase64={true}
												accept={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
												onSuccess={(file) => uploadRecordImage(file[0])}
												onError={(errors) => setRecordImageError(true)}
											>
												{({ browseFiles, getDropZoneProps, getLabelProps }) => (
													<div style={{ textAlign: 'right' }}>
														{recordImage &&
															(recordImage.match(/\.pdf$/) ? (
																<a
																	title="open file"
																	target="_blank"
																	href={`${graphqlUrl}/${recordImage}`}
																>
																	<InsertDriveFileIcon
																		style={{ color: 'gray', fontSize: '50px' }}
																	/>
																</a>
															) : (
																<div>
																	<img
																		style={{ width: '200px', height: '200px' }}
																		alt="advertisement photo"
																		src={`${graphqlUrl}${recordImage}`}
																	/>
																</div>
															))}
														{uploadLoading && spinnerIndex === 3 && (
															<CircularProgress style={{ color: 'gray' }} />
														)}
														<div {...getDropZoneProps({ className: 'myDropZone' })} />
														<Button
															color="whiteButton"
															style={{ marginTop: '38px', marginRight: '10px' }}
															onClick={browseFiles}
														>
															Select photo or pdf
														</Button>
														{recordImageError && (
															<p className={style.imageError}>
																please enter image or pdf file less than 10MB size
															</p>
														)}
													</div>
												)}
											</Files>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div className={style.rightSide}>
							<div className={style.imageRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.imageLabel}>Passport card:</div>
										<div className={style.imageHint}>(upload an image or pdf less than 10MB)</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.imageContainer}>
											<Files
												maxSize="10mb"
												multiple={false}
												convertToBase64={true}
												accept={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
												onSuccess={(file) => uploadPassportCardImage(file[0])}
												onError={(errors) => setPassportCardImageError(true)}
											>
												{({ browseFiles, getDropZoneProps, getLabelProps }) => (
													<div style={{ textAlign: 'right' }}>
														{passportCardImage &&
															(passportCardImage.match(/\.pdf$/) ? (
																<a
																	title="open file"
																	target="_blank"
																	href={`${graphqlUrl}/${passportCardImage}`}
																>
																	<InsertDriveFileIcon
																		style={{ color: 'gray', fontSize: '50px' }}
																	/>
																</a>
															) : (
																<div>
																	<img
																		style={{ width: '200px', height: '200px' }}
																		alt="advertisement photo"
																		src={`${graphqlUrl}${passportCardImage}`}
																	/>
																</div>
															))}
														{uploadLoading && spinnerIndex === 4 && (
															<CircularProgress style={{ color: 'gray' }} />
														)}
														<div {...getDropZoneProps({ className: 'myDropZone' })} />
														<Button
															color="whiteButton"
															style={{ marginTop: '38px', marginRight: '10px' }}
															onClick={browseFiles}
														>
															Select Photo or pdf
														</Button>
														{passportCardImageError && (
															<p className={style.imageError}>
																please enter image or pdf file less than 10MB size
															</p>
														)}
													</div>
												)}
											</Files>
										</div>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.dateRow}>
								<GridContainer>
									<GridItem xs={12} sm={8} md={8} lg={6}>
										<div className={style.dateLabel}>Passport expire date:</div>
									</GridItem>
									<GridItem xs={12} sm={4} md={4} lg={6}>
										{passportExpireDateError && (
											<div className={style.dateError}>{passportExpireDateError}</div>
										)}
										<Datetime
											timeFormat={false}
											disableOnClickOutside
											value={passportExpireDate}
											closeOnSelect
											onChange={(date) => setpassportExpireDate(date)}
											inputProps={{
												placeholder: 'passport expire day',
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.imageRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.imageLabel}>Background check:</div>
										<div className={style.imageHint}>(upload an image or pdf less than 10MB)</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.imageContainer}>
											<Files
												maxSize="10mb"
												multiple={false}
												convertToBase64={true}
												accept={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
												onSuccess={(file) => uploadBackgroudCheckImage(file[0])}
												onError={(errors) => setBackgroundCheckImageError(true)}
											>
												{({ browseFiles, getDropZoneProps, getLabelProps }) => (
													<div style={{ textAlign: 'right' }}>
														{backgroundCheckImage &&
															(backgroundCheckImage.match(/\.pdf$/) ? (
																<a
																	title="open file"
																	target="_blank"
																	href={`${graphqlUrl}/${backgroundCheckImage}`}
																>
																	<InsertDriveFileIcon
																		style={{ color: 'gray', fontSize: '50px' }}
																	/>
																</a>
															) : (
																<div>
																	<img
																		style={{ width: '200px', height: '200px' }}
																		alt="advertisement photo"
																		src={`${graphqlUrl}${backgroundCheckImage}`}
																	/>
																</div>
															))}
														{uploadLoading && spinnerIndex === 5 && (
															<CircularProgress style={{ color: 'gray' }} />
														)}
														<div {...getDropZoneProps({ className: 'myDropZone' })} />
														<Button
															color="whiteButton"
															style={{ marginTop: '38px', marginRight: '10px' }}
															onClick={browseFiles}
														>
															Select photo or pdf
														</Button>
														{backgroundCheckImageError && (
															<p className={style.imageError}>
																please enter image or pdf file less than 10MB size
															</p>
														)}
													</div>
												)}
											</Files>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div className={style.buttonContainer}>
					<Button color="whiteButton" onClick={onCancel}>
							Cancel
						</Button>
						<Button disabled={uploadLoading} color="yellow" onClick={onSubmit}>
							Next
						</Button>
					</div>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default Step2;
