import React, { useState, useEffect } from 'react';
import { graphqlUrl, endPoint } from 'utils/config';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import CarDetails from 'superAdminViews/ride/drivers/CarDetails';
import { css } from '@emotion/core';
import Pagination from 'commonComponents/pagination/Pagination';
import ReactTable from 'react-table';
import Modal from 'commonComponents/modal/Modal';
import BeatLoader from 'react-spinners/BeatLoader';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import SortIcon from '@material-ui/icons/Sort';
import Checkbox from '@material-ui/core/Checkbox';
import style from 'shopAdminViews/orders/Orders.module.scss';
import ClearIcon from '@material-ui/icons/Clear';

const GET_CARS = gql`
	query getCarsByAdmin(
		$limit: Int
		$skip: Int
		$_id: ID
		$plate: String
		$ride: Boolean
		$delivery: Boolean
		$carType: ID
		$brand: ID
		$model: ID
		$createdAt: Date
		$driverFullName: String
		$carStatus: CarStatus
		$driverPhoneNumber: String
		$createdAtSort: Int
	) {
		getCarsByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				plate: $plate
				ride: $ride
				delivery: $delivery
				brand: $brand
				model: $model
				carType: $carType
				createdAt: $createdAt
				driverFullName: $driverFullName
				driverPhoneNumber: $driverPhoneNumber
				carStatus: $carStatus
			}
			sort: { createdAt: $createdAtSort }
		) {
			_id
			plate
			status
			owner {
				_id
				fullName
				phoneNumber
				email
			}
			carType {
				_id
				type
			}
			color {
				_id
				name
			}
			pictures {
				inner {
					url
				}
				outer {
					url
				}
			}
			insurance {
				insuranceImageUrl
				expireDate
			}
			registrationDocumentUrl
			description
			ride
			delivery
			carOptions {
				inHurry
				orderingForSomeoneElse
				pet
				bagsWithMe
				reserved
				airConditioner
				welcomeSign
				driverAssistant
				withInfant
				waitTimesInMinutes
				tipValue
			}
			brand {
				_id
				name
			}
			model {
				_id
				name
			}
			manufacturingYear
		}
	}
`;

const GET_CARS_COUNT = gql`
	query getCarsCountByAdmin(
		$_id: ID
		$plate: String
		$ride: Boolean
		$delivery: Boolean
		$carType: ID
		$brand: ID
		$model: ID
		$createdAt: Date
		$driverFullName: String
		$driverPhoneNumber: String
		$carStatus: CarStatus
	) {
		getCarsCountByAdmin(
			filters: {
				_id: $_id
				plate: $plate
				ride: $ride
				carType: $carType
				delivery: $delivery
				brand: $brand
				model: $model
				createdAt: $createdAt
				driverFullName: $driverFullName
				driverPhoneNumber: $driverPhoneNumber
				carStatus: $carStatus
			}
		)
	}
`;

const DELETE_CAR = gql`
	mutation deleteCarByAdmin($idSet: [ID!]!) {
		deleteCarByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const GET_BRANDS = gql`
	query getCarBrands {
		getCarBrands {
			_id
			name
		}
	}
`;

const GET_MODELS = gql`
	query getCarModels($brand: ID) {
		getCarModels(filters: { brand: $brand }) {
			_id
			name
			brand {
				_id
			}
		}
	}
`;

const GET_CAR_TYPES = gql`
	query getCarTypes {
		getCarTypes {
			_id
			type
		}
	}
`;

const Cars = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);
	const statusOptions = ['all', 'online', 'offline', 'on a trip'];
	const statusOptionsAlt = { all: 'ALL', online: 'ONLINE', offline: 'OFFLINE', 'on a trip': 'INTRIP' };

	const [plateFilter, setPlateFilter] = useState('');
	const [driverNameFilter, setDriverNameFilter] = useState('');
	const [driverPhoneNumberFilter, setDriverPhoneNumberFilter] = useState('');
	const [modelFilter, setModelFilter] = useState('ALL');
	const [statusFilter, setStatusFilter] = useState(statusOptions[0]);
	const [brandFilter, setBrandFilter] = useState('ALL');
	const [carTypeFilter, setCarTypeFilter] = useState('ALL');
	const [openActionId, setOpenActionId] = useState();
	const [modelIdFilter, setModelIdFilter] = useState('');
	const [brandIdFilter, setBrandIdFilter] = useState('');
	const [carTypeIdFilter, setCarTypeIdFilter] = useState('');

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [carForDelete, setCarForDelete] = useState([]);
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [modal, setModal] = useState(false);
	const [carDetails, setCarDetails] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectCarDetail = (car) => {
		setModal(true);
		setCarDetails(car);
	};

	const onDeleteCar = (car) => {
		setDeleteModal(true);
		setCarForDelete([car._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const [excelData, setExcelData] = useState([
		['plate', 'driver name', 'driver phone No', 'status', 'brand', 'model', 'car type'],
	]);

	const [getCarsByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_CARS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (countData && countData.getCarsCountByAdmin > 0 && excelData.length === countData.getCarsCountByAdmin + 1) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getCarsByAdmin, (item) => [
					item.plate,
					item.owner ? item.owner.fullName : '-',
					item.owner ? item.owner.phoneNumber : '-',
					item.status === 'INTRIP' ? 'on a trip' : item.status.replace(/_/g, ' ').toLowerCase(),
					item.brand.name,
					item.model.name,
					item.carType.type.replace(/_/g, ' ').toLowerCase(),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Cars.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getCarsByAdmin({ variables: { ...variables, skip: 0, limit: countData.getCarsCountByAdmin } });
		}
	};

	const [deleteCarByAdmin] = useMutation(DELETE_CAR);

	const deleteCar = () => {
		deleteCarByAdmin({ variables: { idSet: carForDelete } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
				refetchCount();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		deleteCarByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				refetch();
				refetchCount();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getCarsCountByAdmin / perPage) - 1 &&
			countData.getCarsCountByAdmin !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getCarsCountByAdmin !== 0) {
			setPageCount(Math.ceil(countData.getCarsCountByAdmin / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	const { loading: brandsLoading, error: brandsError, data: brandsData } = useQuery(GET_BRANDS);

	const { loading: carTypesLoading, error: carTypesError, data: carTypesData } = useQuery(GET_CAR_TYPES);

	const { loading: modelsLoading, error: modelsError, data: modelsData } = useQuery(GET_MODELS);

	useEffect(() => {
		if (modelsData && modelFilter) {
			if (modelFilter === 'ALL') {
				setModelIdFilter('');
			} else {
				setModelIdFilter(modelsData.getCarModels.find((model) => model.name === modelFilter)._id);
			}
		}
	}, [, modelFilter]);

	useEffect(() => {
		if (brandsData && brandFilter) {
			if (brandFilter === 'ALL') {
				setBrandIdFilter('');
			} else {
				setBrandIdFilter(brandsData.getCarBrands.find((brand) => brand.name === brandFilter)._id);
			}
		}
	}, [, brandFilter]);

	useEffect(() => {
		if (carTypesData && carTypeFilter) {
			if (carTypeFilter === 'ALL') {
				setCarTypeIdFilter('');
			} else {
				setCarTypeIdFilter(carTypesData.getCarTypes.find((carType) => carType.type === carTypeFilter)._id);
			}
		}
	}, [, carTypeFilter]);

	// testing menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		plate: undefined,
		driverFullName: undefined,
		driverPhoneNumber: undefined,
		brand: undefined,
		model: undefined,
		carType: undefined,
		ride: undefined,
		delivery: undefined,
		createdAt: undefined,
		createdAtSort: -1,
		carStatus: undefined,
	});

	const { loading, error, data, refetch } = useQuery(GET_CARS, {
		variables: variables,
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,
	});

	const { data: countData, error: countError, loading: countLoading, refetch: refetchCount } = useQuery(
		GET_CARS_COUNT,
		{
			variables: variables,
			fetchPolicy: 'no-cache',
			notifyOnNetworkStatusChange: true,
		}
	);

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getCarsByAdmin.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	const linkPage = (route) => {
		window.location.href = endPoint + `/superAdmin/${route}`;
	};

	const onSubmitFilter = () => {
		let hasFilter =
			driverNameFilter ||
			variables.driverFullName ||
			plateFilter ||
			variables.plate ||
			driverPhoneNumberFilter ||
			variables.driverPhoneNumber ||
			!(statusFilter === 'all' && !variables.carStatus) ||
			!(modelFilter === 'ALL' && !variables.model) ||
			!(carTypeFilter === 'ALL' && !variables.carType) ||
			!(brandFilter === 'ALL' && variables.brand === undefined);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');
			if (plateFilter) {
				setVariables((variables) => ({
					...variables,
					plate: plateFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, plate: undefined }));
			}

			if (statusFilter === 'all') {
				setVariables((variables) => ({
					...variables,
					carStatus: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					carStatus: statusOptionsAlt[statusFilter].toUpperCase(),
				}));
			}

			if (driverNameFilter) {
				setVariables((variables) => ({
					...variables,
					driverFullName: driverNameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, driverFullName: undefined }));
			}

			if (driverPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					driverPhoneNumber: driverPhoneNumberFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, driverPhoneNumber: undefined }));
			}

			if (brandIdFilter) {
				setVariables((variables) => ({ ...variables, brand: brandIdFilter }));
			} else {
				setVariables((variables) => ({ ...variables, brand: undefined }));
			}

			if (modelIdFilter) {
				setVariables((variables) => ({ ...variables, model: modelIdFilter }));
			} else {
				setVariables((variables) => ({ ...variables, model: undefined }));
			}

			if (carTypeIdFilter) {
				setVariables((variables) => ({ ...variables, carType: carTypeIdFilter }));
			} else {
				setVariables((variables) => ({ ...variables, carType: undefined }));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			plate: undefined,
			driverFullName: undefined,
			driverPhoneNumber: undefined,
			brand: undefined,
			model: undefined,
			carType: undefined,
			ride: undefined,
			delivery: undefined,
			createdAt: undefined,
			createdAtSort: -1,
			carStatus: undefined,
		});
		setBrandFilter('ALL');
		setModelFilter('ALL');
		setCarTypeFilter('ALL');
		setPlateFilter('');
		setDriverNameFilter('');
		setDriverPhoneNumberFilter('');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	if (loading || carTypesLoading || modelsLoading || brandsLoading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			<div>
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<div style={{ padding: '0' }} className={style.linkButtonDiv}>
							<Button color="yellow" fullWidth onClick={() => linkPage('addVehicle')}>
								Add vehicle for driver
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<div>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}
				{idsForDelete.length > 0 && (
					<Button title="delete selected items" color="redButton" onClick={onDeleteSome}>
						Delete
					</Button>
				)}
			</div>
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onSubmitFilter}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getCarsCountByAdmin === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getCarsByAdmin.length)}</span> of{' '}
							<span>{countData.getCarsCountByAdmin}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0);
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={data.getCarsByAdmin.map((car) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(car._id)}
								onChange={() => {
									if (idsForDelete.includes(car._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== car._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, car._id]);
									}
								}}
							/>
						</div>
					),
					photo: car.pictures.outer && car.pictures.outer[0] && (
						<img
							style={{ width: '90px', height: '90px' }}
							alt="profile-photo"
							src={`${graphqlUrl}${car.pictures.outer[0].url}`}
						/>
					),

					brand: car.brand.name,
					model: car.model.name,
					plate: car.plate,
					status: car.status && (
						<div
							className={`${style.inlineTag} ${
								style[car.status.replace(/_/g, '').toLowerCase() + 'Tag']
							}`}
						>
							{car.status === 'INTRIP' ? 'on a trip' : car.status.replace(/_/g, ' ').toLowerCase()}
						</div>
					),
					carType: car.carType.type.replace(/_/g, ' ').toLowerCase(),
					driverName: car.owner.fullName,
					driverPhoneNumber: car.owner.phoneNumber,
					actions: (
						<div>
							<button onClick={() => onSelectCarDetail(car)} className={style.detailIconDiv}>
								<span>i</span>
							</button>
							<IconButton
								id={car._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, car._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu id="simple-menu" anchorEl={anchorEl} open={menuId === car._id} onClick={handleClose} onClose={handleClose}>
								<MenuItem
									onClick={() =>
										(window.location.href = endPoint + `/superAdmin/editVehicle/${car._id}`)
									}
								>
									edit
								</MenuItem>
								<MenuItem onClick={() => onDeleteCar(car)}>delete</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div className="inputWithoutFrom">
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getCarsByAdmin.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getCarsByAdmin, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDiv}>Plate</div>
								<CustomInput
									value={plateFilter}
									onChange={(e) => setPlateFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'plate ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'plate',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDivSmall}>Driver Name</div>
								<CustomInput
									value={driverNameFilter}
									onChange={(e) => setDriverNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'driverName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDivSmall}>Driver Phone No</div>
								<CustomInput
									value={driverPhoneNumberFilter}
									onChange={(e) => setDriverPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'driverPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div className="dropDownWithoutFrom">
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={statusFilter.toLowerCase()}
										onClick={(e) => {
											console.log(e);
											setStatusFilter(e);
										}}
										dropdownList={Array.from(statusOptions, (item) =>
											item.replace(/([A-Z])/g, ' $1').toLowerCase()
										)}
									/>
								</div>
							</div>
						),
						accessor: 'status',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="dropDownWithoutFrom">
								<div className={style.titleDiv}>Brand</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="gray"
										buttonText={brandFilter}
										onClick={(e) => setBrandFilter(e)}
										dropdownList={
											brandsData && [
												'ALL',
												...Array.from(brandsData.getCarBrands, (brand) => brand.name),
											]
										}
									/>
								</div>
							</div>
						),
						accessor: 'brand',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="dropDownWithoutFrom">
								<div className={style.titleDiv}>Model </div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="gray"
										buttonText={modelFilter}
										onClick={(e) => setModelFilter(e)}
										dropdownList={
											modelsData && [
												'ALL',
												...Array.from(modelsData.getCarModels, (model) => model.name),
											]
										}
									/>
								</div>
							</div>
						),
						accessor: 'model',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="dropDownWithoutFrom">
								<div className={style.titleDiv}>Car type</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="gray"
										buttonText={carTypeFilter}
										onClick={(e) => setCarTypeFilter(e)}
										dropdownList={
											carTypesData && [
												'ALL',
												...Array.from(carTypesData.getCarTypes, (carType) => carType.type),
											]
										}
									/>
								</div>
							</div>
						),
						accessor: 'carType',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="dropDownWithoutFrom">
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
						minWidth: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getCarsCountByAdmin !== 0 ? Math.ceil(countData.getCarsCountByAdmin / perPage) : 1
					}
					// isOnFirstPage={pageCount === 0}
					// inOnLastPage={
					// 	countData.getCarsCountByAdmin &&
					// 	pageCount === Math.ceil(countData.getCarsCountByAdmin / perPage) - 1
					// }
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<CarDetails close={() => setModal(false)} car={carDetails} />
				</DialogContent>
			</Dialog>
			{carForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteCar}
							text="Do you want to delete this car?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these cars?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Cars;
