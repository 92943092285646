import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import { login } from 'utils/auth';
import CardHeader from 'components/Card/CardHeader.js';
import { makeStyles } from '@material-ui/core/styles';
import CardIcon from 'components/Card/CardIcon.js';
import Modal from 'commonComponents/modal/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import ImageUpload from 'components/CustomUpload/ImageUpload.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Button from 'components/CustomButtons/Button.js';
import CardBody from 'components/Card/CardBody.js';
import style from './ShopAddShop.module.scss';
import { endPoint } from 'utils/config';

const Rules = () => {
	const nextStep = window.localStorage.getItem('nextStep');

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit()
		}
	};

	const onSubmit = () => {
		window.location.href = endPoint + nextStep;
	};

	return (
		<div onKeyPress={e => handleEnter(e)} className={style.mainDiv}>
			<GridContainer justify="center">
				<GridItem xs={11} sm={10} md={10} lg={9}>
					<div className={style.whiteCard}>
						<div className={style.header}>
							<div className={style.headerSentence}>Steps</div>
						</div>
						<div>
							<div className={style.listContainer}>
								<div style={{ margin: '50px 15px' }}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={3} lg={3}>
											<div className={style.listItem}>
												<div>1.Register</div>
												<NavigateNextIcon />
											</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={3} lg={3}>
											<div className={style.listItem}>
												<div>2.Provide shop information</div>
												<NavigateNextIcon />
											</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={3} lg={3}>
											<div className={style.listItem}>
												<div>3.Wait for verification</div>
												<NavigateNextIcon />
											</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={3} lg={3}>
											<div className={style.listItem}>4.Start sale</div>
										</GridItem>
									</GridContainer>
								</div>
							</div>
							<div className={style.buttonContainer}>
								<Button size="lg" onClick={onSubmit} color="yellow">
									Continue
								</Button>
							</div>
						</div>
					</div>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default Rules;
