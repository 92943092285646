import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Close from '@material-ui/icons/Close';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import style from './CommentDetails.module.scss';

const CommentDetails = ({ comment, close }) => {
	console.log(comment.driverComment);
	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Comment Details</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Trip ID:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{comment.trip._id}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Driver ID:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{comment.driver._id}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Driver Name:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{comment.driver.fullName}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Passenger ID:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{comment.passenger._id}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Passenger Name:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{comment.passenger.fullName}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{moment(comment.createdAt).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Text:</div>
								</GridItem>
								{comment.userComment && (
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<div className={style.value}>{comment.userComment}</div>
									</GridItem>
								)}
								{comment.driverComment && (
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<div className={style.value}>{comment.driverComment}</div>
									</GridItem>
								)}
							</GridContainer>
						</div>

						{comment.readyComments &&
							comment.readyComments.length > 0 &&
							comment.readyComments.map((readyComment) => (
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={10} md={10}>
											<div className={style.label}>
												{readyComment.readyComment && readyComment.readyComment.type}
											</div>
										</GridItem>
										<GridItem xs={12} sm={2} md={2}>
											<div className={style.value}>
												{readyComment.readyComment && readyComment.rate}
											</div>
										</GridItem>
									</GridContainer>
								</div>
							))}
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.footer}>
				<Button color="yellow" onClick={close}>
					Close
				</Button>
			</div>
		</div>
	);
};

export default CommentDetails;
