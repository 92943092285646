import React, { useState, useEffect } from 'react';
import { endPoint } from 'utils/config';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Checkbox from '@material-ui/core/Checkbox';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import XLSX from 'xlsx';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import BarLoader from 'react-spinners/BarLoader';
import BeatLoader from 'react-spinners/BeatLoader';
import ClearIcon from '@material-ui/icons/Clear';
import ReactTable from 'react-table';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import Slide from '@material-ui/core/Slide';
import Modal from 'commonComponents/modal/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import UserDetails from './UserDetails';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';
import moment from 'moment';
import userPhoto from 'assets/img/UserPhoto.svg';

const GET_USERS = gql`
	query getUsersByAdmin(
		$limit: Int
		$skip: Int
		$fullName: String
		$_id: ID
		$gender: UserGender
		$email: String
		$state: UserStateInput
		$phoneNumber: String
		$createdAtFrom: Date
		$createdAt: Date
		$createdAtSort: Int
		$birthDate: Date
		$birthDateSort: Int
	) {
		getUsersByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				fullName: $fullName
				email: $email
				gender: $gender
				state: $state
				phoneNumber: $phoneNumber
				_id: $_id
				birthDate: $birthDate
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
			}
			sort: { birthDate: $birthDateSort, createdAt: $createdAtSort }
		) {
			_id
			fullName
			email
			phoneNumber
			defaultAddress {
				full
				zipCode
			}
			addresses {
				zipCode
				full
			}
			state
			stripeCustomerId
			isVerified
			gender
			emailVerified
			phoneNumberVerified

			numberOfRates
			updatedAt
			createdAt
			hasNotification
			averageRate
			createdAt
			birthDate
		}
	}
`;

const GET_USERS_COUNT = gql`
	query getUsersByAdminCount(
		$fullName: String
		$_id: ID
		$gender: UserGender
		$email: String
		$state: UserStateInput
		$phoneNumber: String
		$birthDate: Date
		$createdAtFrom: Date
		$createdAt: Date
	) {
		getUsersByAdminCount(
			filters: {
				fullName: $fullName
				email: $email
				gender: $gender
				state: $state
				phoneNumber: $phoneNumber
				_id: $_id
				birthDate: $birthDate
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
			}
		)
	}
`;

const SUSPEND_USER = gql`
	mutation suspendUserByAdmin($idSet: [ID!]!) {
		suspendUserByAdmin(idSet: $idSet) {
			_id
			state
		}
	}
`;

const Users = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const stateOptions = ['ACTIVE', 'SUSPENDED', 'ALL'];
	const genderOptions = ['MALE', 'FEMALE', 'BOTH'];
	const [dateSort, setDateSort] = useState('down');

	const [dateFilterError, setDateFilterError] = useState('');
	const [dateFromFilterError, setDateFromFilterError] = useState('');
	const [nameFilter, setNameFilter] = useState('');
	const [stateFilter, setStateFilter] = useState(stateOptions[2]);
	const [dateFromFilter, setDateFromFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');
	const [phoneNumberFilter, setPhoneNumberFilter] = useState('');
	const [emailFilter, setEmailFilter] = useState('');
	const [genderFilter, setGenderFilter] = useState(genderOptions[2]);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount,
		fullName: undefined,
		email: undefined,
		gender: undefined,
		state: undefined,
		createdAtFrom: undefined,
		createdAt: undefined,
		phoneNumber: undefined,
		createdAtSort: -1,
	});

	const [suspendErrorModal, setSuspendErrorModal] = useState(false);
	const [suspendErrorText, setSuspendErrorText] = useState('');

	const [checkAll, setCheckAll] = useState(false);
	const [idsForSuspend, setIdsForSuspend] = useState([]);
	const [suspendSomeModal, setSuspendSomeModal] = useState(false);
	const [actionWord, setActionWord] = useState('suspend');
	const [singleActionWord, setSingleActionWord] = useState('suspend');
	const [groupError, setGroupError] = useState(false);

	const [suspendModal, setSuspendModal] = useState(false);
	const [userForSuspend, setUserForSuspend] = useState([]);
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [modal, setModal] = useState(false);
	const [userDetail, setUserDetail] = useState();

	useEffect(() => {
		if (idsForSuspend.length > 0) {
			let firstItem = data.getUsersByAdmin.find((item) => item._id === idsForSuspend[0]);
			if (firstItem.state === 'ACTIVE') {
				setActionWord('suspend');
			} else {
				setActionWord('activate');
			}
		}
	}, [idsForSuspend]);

	useEffect(() => {
		if (userForSuspend.length > 0) {
			let firstItem = data.getUsersByAdmin.find((item) => item._id === userForSuspend[0]);
			if (firstItem.state === 'ACTIVE') {
				setSingleActionWord('suspend');
			} else {
				setSingleActionWord('activate');
			}
		}
	}, [userForSuspend]);

	useEffect(() => {
		if (idsForSuspend.length > 1) {
			idsForSuspend.some((id) => {
				let firstItem = data.getUsersByAdmin.find((item) => item._id === idsForSuspend[0]);
				let currentItem = data.getUsersByAdmin.find((item) => item._id === id);
				if (firstItem.state !== currentItem.state) {
					setGroupError(true);
					return -1;
				} else {
					setGroupError(false);
				}
			});
		} else {
			setGroupError(false);
		}
	}, [idsForSuspend, checkAll]);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectUserDetail = (user) => {
		setModal(true);
		setUserDetail(user);
	};

	const onSuspendUser = (user) => {
		setSuspendModal(true);
		setUserForSuspend([user._id]);
	};

	const onSuspendSome = () => {
		setSuspendSomeModal(true);
	};

	const [excelData, setExcelData] = useState([
		['name', 'email', 'phone', 'created at', 'status', 'gender'],
	]);

	const [getUsersByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_USERS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getUsersByAdminCount > 0 &&
			excelData.length === countData.getUsersByAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			console.log(lazyData);

			setExcelData([
				...excelData,
				...Array.from(lazyData.getUsersByAdmin, (item) => [
					item.fullName,
					item.email,
					item.phoneNumber,
					moment(item.createdAt).format('MM-DD-YYYY'),
					item.state.toLowerCase(),
					item.gender && item.gender.toLowerCase(),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Customers.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getUsersByAdmin({ variables: { ...variables, skip: 0, limit: countData.getUsersByAdminCount } });
		}
	};

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('');
			}
		}
	};

	const [suspendUserByAdmin] = useMutation(SUSPEND_USER);

	const suspendUser = () => {
		suspendUserByAdmin({ variables: { idSet: userForSuspend } })
			.then((res) => {
				setSuspendModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setSuspendModal(false);
					setSuspendErrorText(err.graphQLErrors[0].message);
					setSuspendErrorModal(true);
				}
			});
	};

	const suspendSome = () => {
		suspendUserByAdmin({ variables: { idSet: idsForSuspend } })
			.then((res) => {
				setSuspendSomeModal(false);
				setIdsForSuspend([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setSuspendSomeModal(false);
					setIdsForSuspend([]);
					setSuspendErrorText(err.graphQLErrors[0].message);
					setSuspendErrorModal(true);
				}
			});
	};

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getUsersByAdmin.map((item) => {
				if (!idsForSuspend.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForSuspend, pageCount, perPage]);

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getUsersByAdminCount / perPage) - 1 &&
			countData.getUsersByAdminCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getUsersByAdminCount !== 0) {
			setPageCount(Math.ceil(countData.getUsersByAdminCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFromFilter]);

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}
	}, [dateSort]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onFilterSubmit = () => {
		let hasFilter =
			nameFilter ||
			variables.fullName ||
			phoneNumberFilter ||
			variables.phoneNumber ||
			dateFromFilter ||
			variables.createdAtFrom ||
			dateFilter ||
			variables.createdAt ||
			emailFilter ||
			variables.email ||
			!(stateFilter === 'ALL' && !variables.state) ||
			!(genderFilter === 'BOTH' && !variables.gender);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');
			if (nameFilter) {
				setVariables((variables) => ({
					...variables,
					fullName: nameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, fullName: undefined }));
			}

			if (emailFilter) {
				setVariables((variables) => ({
					...variables,
					email: emailFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, email: undefined }));
			}

			if (phoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					phoneNumber: phoneNumberFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, phoneNumber: undefined }));
			}

			if (genderFilter === 'BOTH') {
				setVariables((variables) => ({
					...variables,
					gender: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					gender: genderFilter.toUpperCase(),
				}));
			}

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (stateFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					state: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					state: stateFilter.toUpperCase(),
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount,
			fullName: undefined,
			email: undefined,
			gender: undefined,
			state: undefined,
			createdAt: undefined,
			phoneNumber: undefined,
			createdAtSort: -1,
		});
		setNameFilter('');
		setStateFilter('ALL');
		setDateFromFilter('');
		setDateFilter('');
		setEmailFilter('');
		setDateSort('down');
		setPhoneNumberFilter('');
		setGenderFilter('BOTH');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onFilterSubmit();
		}
	};

	const { loading, error, data, refetch } = useQuery(GET_USERS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_USERS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		// console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			<div>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}
				{idsForSuspend.length > 0 && !groupError && (
					<Button
						title={`${actionWord} selected items`}
						color={actionWord === 'suspend' ? 'redButton' : 'greenButton'}
						onClick={onSuspendSome}
					>
						{actionWord}
					</Button>
				)}
				{idsForSuspend.length > 0 && groupError && (
					<div className={style.groupErrorDiv}>selected items have different states.</div>
				)}
			</div>
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onFilterSubmit}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getUsersByAdminCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getUsersByAdmin.length)}</span> of{' '}
							<span>{countData.getUsersByAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0);
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={data.getUsersByAdmin.map((user) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForSuspend.includes(user._id)}
								onChange={() => {
									if (idsForSuspend.includes(user._id)) {
										setIdsForSuspend((idsForSuspend) =>
											idsForSuspend.filter((item) => item !== user._id)
										);
									} else {
										setIdsForSuspend([...idsForSuspend, user._id]);
									}
								}}
							/>
						</div>
					),
					photo: <img style={{ width: '90px', height: '90px' }} alt="profile-photo" src={userPhoto} />,

					name: user.fullName,
					email: user.email,
					phoneNumber: user.phoneNumber,
					gender: user.gender && (
						<div className={`${style.inlineTag} ${style[user.gender.toLowerCase() + 'Tag']}`}>
							{user.gender.toLowerCase()}
						</div>
					),
					address: user.defaultAddress.full,
					creditCardId: '',
					isVerified: user.isVerified ? 'yes' : 'no',
					date: moment(user.createdAt).format('MM/DD/YYYY HH:mm'),
					state: user.state && (
						<div className={`${style.inlineTag} ${style[user.state.toLowerCase() + 'Tag']}`}>
							{user.state.toLowerCase()}
						</div>
					),
					actions: (
						<div className="actions-right">
							<button onClick={() => onSelectUserDetail(user)} className={style.detailIconDiv}>
								<span>i</span>
							</button>
							<IconButton
								id={user._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, user._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === user._id}
								onClick={handleClose}
								onClose={handleClose}
							>
								<MenuItem
									onClick={() =>
										(window.location.href = endPoint + `/superAdmin/editUser/${user._id}`)
									}
								>
									edit
								</MenuItem>
								<MenuItem onClick={() => onSuspendUser(user)}>
									{user.state === 'ACTIVE' ? 'suspend' : 'activate'}
								</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getUsersByAdmin.map((item) => {
												setIdsForSuspend((idsForSuspend) =>
													idsForSuspend.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForSuspend((idsForSuspend) => [
												...idsForSuspend,
												...Array.from(data.getUsersByAdmin, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Photo </div>
							</div>
						),
						accessor: 'photo',
						sortable: false,
						filterable: false,
					},

					{
						Header: (
							<div>
								<div className={style.titleDiv}>Name</div>
								<CustomInput
									value={nameFilter}
									onChange={(e) => setNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Email</div>
								<CustomInput
									value={emailFilter}
									onChange={(e) => setEmailFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'email ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'email',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Phone</div>
								<CustomInput
									value={phoneNumberFilter}
									onChange={(e) => setPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone number ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'phoneNumber',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Created at{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}

								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										viewDate={moment(dateFromFilter).format('MM/DD/YYYY')}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>

								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}

								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										viewDate={moment(dateFilter).format('MM/DD/YYYY')}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},

					{
						Header: (
							<div>
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={stateFilter}
										onClick={(e) => setStateFilter(e)}
										dropdownList={stateOptions}
									/>
								</div>
							</div>
						),
						accessor: 'state',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Gender</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="gray"
										buttonText={genderFilter}
										onClick={(e) => setGenderFilter(e)}
										dropdownList={genderOptions}
									/>
								</div>
							</div>
						),
						accessor: 'gender',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
						minWidth: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getUsersByAdminCount !== 0 ? Math.ceil(countData.getUsersByAdminCount / perPage) : 1
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<UserDetails close={() => setModal(false)} user={userDetail} />
				</DialogContent>
			</Dialog>
			<Dialog open={suspendSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setSuspendSomeModal(false);
							setIdsForSuspend([]);
						}}
						func={suspendSome}
						text={`Do you want to ${actionWord} these customers?`}
					/>
				</DialogContent>
			</Dialog>
			{userForSuspend && (
				<Dialog open={suspendModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setSuspendModal(false)}
							func={suspendUser}
							text={`Do you want to ${singleActionWord} this user?`}
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={suspendErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setSuspendModal(false);
							setSuspendErrorModal(false);
						}}
						type="notif"
						text={
							suspendErrorText
								? suspendErrorText
								: 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Users;
