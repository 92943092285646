import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import 'react-image-lightbox/style.css';
import Close from '@material-ui/icons/Close';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import style from './DriverDetails.module.scss';
import Slide from '@material-ui/core/Slide';
import CarDetails from './CarDetails';
import Modal from 'commonComponents/modal/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const DELETE_CAR = gql`
	mutation deleteCarByAdmin($idSet: [ID!]!) {
		deleteCarByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const Cars = ({ driver, close, refetch }) => {
	const [allCars, setAllCars] = useState(driver.car);
	const [defaultCar, setDefaultCar] = useState(driver.defaultCar);
	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [carForDelete, setCarForDelete] = useState([]);

	const [modal, setModal] = useState(false);
	const [carDetails, setCarDetails] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectCarDetails = (car) => {
		setModal(true);
		setCarDetails(car);
	};

	const onDeleteCar = (car) => {
		setDeleteModal(true);
		setCarForDelete([car._id]);
	};

	const [deleteCarByAdmin] = useMutation(DELETE_CAR);

	const deleteCar = () => {
		deleteCarByAdmin({ variables: { idSet: carForDelete } })
			.then((res) => {
				console.log(res)
				setDeleteModal(false);
				setAllCars((allCars) => allCars.filter((item) => item._id !== res.data.deleteCarByAdmin[0]._id));
				if (res.data.deleteCarByAdmin[0]._id === defaultCar._id) {
					setDefaultCar('');
				}
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Driver cars</h4>
				</div>
			</div>
			<div className={style.content}>
				<div>Default car:</div>
				{defaultCar && (
					<div className={style.colorRow}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={3} lg={3}>
								{' '}
								<div className={style.item}>
									<div>
										<span className={style.label}>Plate:</span> {defaultCar.plate}
									</div>
								</div>
							</GridItem>
							<GridItem xs={12} sm={12} md={3} lg={3}>
								{' '}
								<div className={style.item}>
									<div>
										<span className={style.label}>Model:</span> {defaultCar.model.name}
									</div>
								</div>
							</GridItem>
							<GridItem xs={12} sm={12} md={3} lg={3}>
								{' '}
								<div className={style.item}>
									<div>
										<span className={style.label}>Type:</span>{' '}
										{defaultCar.carType && defaultCar.carType.type}
									</div>
								</div>
							</GridItem>
							<GridItem xs={12} sm={12} md={3} lg={3}>
								<button onClick={() => onSelectCarDetails(defaultCar)} className={style.inlineButton}>
									details
								</button>
								<button
									className={`${style.inlineButton} ${style.inlineDeleteButton}`}
									onClick={() => onDeleteCar(defaultCar)}
								>
									delete
								</button>
							</GridItem>
						</GridContainer>
					</div>
				)}
				<div>All cars:</div>
				<div>
					{allCars.map((car) => (
						<div className={style.colorRow}>
							<GridContainer>
								<GridItem xs={12} sm={12} md={3} lg={3}>
									{' '}
									<div className={style.item}>
										<div>
											<span className={style.label}>Plate:</span> {car.plate}{' '}
										</div>
									</div>
								</GridItem>
								<GridItem xs={12} sm={12} md={3} lg={3}>
									{' '}
									<div className={style.item}>
										<div>
											<span className={style.label}>Model:</span> {car.model.name}
										</div>
									</div>
								</GridItem>
								<GridItem xs={12} sm={12} md={3} lg={3}>
									{' '}
									<div className={style.item}>
										<div>
											<span className={style.label}>Type:</span> {car.carType && car.carType.type}
										</div>
									</div>
								</GridItem>
								<GridItem xs={12} sm={12} md={3} lg={3}>
									<button onClick={() => onSelectCarDetails(car)} className={style.inlineButton}>
										details
									</button>
									<button
										className={`${style.inlineButton} ${style.inlineDeleteButton}`}
										onClick={() => onDeleteCar(car)}
									>
										delete
									</button>
								</GridItem>
							</GridContainer>
						</div>
					))}
				</div>
			</div>
			<div className={style.footer}>
				<Button color="yellow" onClick={close}>
					Close
				</Button>
			</div>
			<Dialog open={modal} transition={Transition} onClose={() => setModal(false)}>
				<DialogContent id="modal-slide-description">
					<CarDetails close={() => setModal(false)} car={carDetails} />
				</DialogContent>
			</Dialog>
			{carForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteCar}
							text="Do you want to delete this car?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Cars;
