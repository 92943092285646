import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ReactTable from 'react-table';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Dialog from '@material-ui/core/Dialog';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import DialogContent from '@material-ui/core/DialogContent';
import Pagination from 'commonComponents/pagination/Pagination';
import Modal from 'commonComponents/modal/Modal';
import Slide from '@material-ui/core/Slide';
import Checkbox from '@material-ui/core/Checkbox';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import style from 'superAdminViews/setting/defaults/DriverReadyComments.module.scss';
import EditProductOption from './EditProductOption';

const ADD_ATTRIBUTE_GROUP = gql`
	mutation createAttributeGroupByAdmin($rootCategory: ID!, $name: String!) {
		createAttributeGroupByAdmin(input: { rootCategory: $rootCategory, name: $name }) {
			_id
			name
		}
	}
`;

const GET_ATTRIBUTE_GROUPS = gql`
	query getAttributeGroupsByAdmin($limit: Int, $skip: Int) {
		getAttributeGroupsByAdmin(pagination: { limit: $limit, skip: $skip }) {
			_id
			name
			attributes {
				_id
				name
			}
			rootCategory {
				_id
				title
			}
		}
	}
`;

const GET_ATTRIBUTE_GROUPS_COUNT = gql`
	query getAttributeGroupsByAdminCount {
		getAttributeGroupsByAdminCount
	}
`;

const GET_CATEGORIES = gql`
	query getCategories($parent: ID) {
		getCategories(filters: { parent: $parent }) {
			_id
			title
			parent {
				_id
			}
		}
	}
`;

const DELETE_ATTRIBUTE_GROUP = gql`
	mutation deleteAttributeGroupByAdmin($idSet: [ID!]!) {
		deleteAttributeGroupByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const AttributeGroups = (props) => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
	});

	const { loading: categoriesLoading, error: categoriesError, data: categoriesData } = useQuery(GET_CATEGORIES, {
		variables: {
			parent: null,
		},
	});

	const [name, setName] = useState('');
	const [categoryName, setCategoryName] = useState(
		categoriesData &&
			Array.from(
				categoriesData.getCategories.filter((category) => category.parent === null),
				(category) => category.title
			)[0]
	);
	const [categoryId, setCategoryId] = useState();

	const [excelData, setExcelData] = useState([['name', 'category']]);

	const [getAttributeGroupsByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(
		GET_ATTRIBUTE_GROUPS
	);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (countData && excelData.length === countData.getAttributeGroupsByAdminCount + 1) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getAttributeGroupsByAdmin, (item) => [item.name, item.rootCategory.title]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'AttributeGroups.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getAttributeGroupsByAdmin({
				variables: { ...variables, skip: 0, limit: countData.getAttributeGroupsByAdminCount },
			});
		}
	};

	useEffect(() => {
		if (categoriesData) {
			setCategoryName(categoriesData.getCategories.filter((category) => category.parent === null)[0].title);
		}
	}, [, categoriesData]);

	useEffect(() => {
		if (categoriesData) {
			setCategoryId(categoriesData.getCategories.find((category) => category.title === categoryName)._id);
		}
	}, [, categoryName]);

	const [nameError, setNameError] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [attributeGroupForDelete, setAttributeGroupForDelete] = useState();

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [addModal, setAddModal] = useState(false);
	const [modal, setModal] = useState(false);
	const [attributeGroupDetails, setAttributeGroupDetails] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getAttributeGroupsByAdmin.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	// testing menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onSelectAttributeGroupEdit = (attributeGroup) => {
		setModal(true);
		setAttributeGroupDetails(attributeGroup);
	};

	const onDeleteAttributeGroup = (attributeGroup) => {
		setDeleteModal(true);
		setAttributeGroupForDelete([attributeGroup._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const [createAttributeGroupByAdmin, { data: addMutationData, error: addMutationError }] = useMutation(
		ADD_ATTRIBUTE_GROUP
	);

	const [deleteAttributeGroupByAdmin] = useMutation(DELETE_ATTRIBUTE_GROUP);

	const deleteAttributeGroup = () => {
		deleteAttributeGroupByAdmin({ variables: { idSet: attributeGroupForDelete } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		deleteAttributeGroupByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getAttributeGroupsByAdminCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getAttributeGroupsByAdminCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	const { loading, error, data, refetch } = useQuery(GET_ATTRIBUTE_GROUPS, {
		variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_ATTRIBUTE_GROUPS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	if (countError) {
		console.log(countError);
	}

	const onSubmit = () => {
		if (!name) {
			setNameError(true);
		} else {
			setNameError(false);
		}
		if (name) {
			setDisableButton(true);
			createAttributeGroupByAdmin({
				variables: { name: name.trim(), rootCategory: categoryId },
			})
				.then((res) => {
					refetch();
					setName('');
					setAddModal(true);
					setDisableButton(false);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<div>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}
				{idsForDelete.length > 0 && (
					<Button title="delete selected items" color="redButton" onClick={onDeleteSome}>
						Delete
					</Button>
				)}
			</div>

			<div className={style.inputRow}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={name}
										onChange={(e) => setName(e.target.value)}
										error={nameError}
										labelText={nameError ? "name can't be empty*" : 'name'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					{categoriesData && (
						<GridItem xs={12} sm={6} md={6} lg={6}>
							<div className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.dropdownLabel}>Category:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={categoryName}
												onClick={(e) => setCategoryName(e)}
												dropdownList={
													categoriesData &&
													Array.from(
														categoriesData.getCategories,
														(category) => category.title
													)
												}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={`${style.inputButtonContainer} ${style.middleButtonContainer}`}>
							<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
								Add
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<ReactTable
				data={data.getAttributeGroupsByAdmin.map((attributeGroup) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(attributeGroup._id)}
								onChange={() => {
									if (idsForDelete.includes(attributeGroup._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== attributeGroup._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, attributeGroup._id]);
									}
								}}
							/>
						</div>
					),
					name: attributeGroup.name,
					category: attributeGroup.rootCategory && attributeGroup.rootCategory.title,
					actions: (
						<div>
							<IconButton
								id={attributeGroup._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, attributeGroup._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === attributeGroup._id}
								onClose={handleClose}
							>
								<MenuItem onClick={() => onSelectAttributeGroupEdit(attributeGroup)}>edit</MenuItem>
								<MenuItem onClick={() => onDeleteAttributeGroup(attributeGroup)}>delete</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div className={style.fitTitleDivCheckbox}>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getAttributeGroupsByAdmin.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getAttributeGroupsByAdmin, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: <div className={style.fitTitleDiv}>Name</div>,
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Category</div>,
						accessor: 'category',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && countData.getAttributeGroupsByAdminCount !== 0 && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getAttributeGroupsByAdminCount !== 0
							? Math.ceil(countData.getAttributeGroupsByAdminCount / perPage)
							: 1
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<EditProductOption
						refetch={() => refetch()}
						close={() => setModal(false)}
						type="attributeGroup"
						item={attributeGroupDetails}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text="Attribute group added succesfully." />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
			{attributeGroupForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteAttributeGroup}
							text="Do you want to delete this attribute group?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these attribute groups?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default AttributeGroups;
