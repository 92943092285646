import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import Modal from 'commonComponents/modal/Modal';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Dialog from '@material-ui/core/Dialog';
import GridItem from 'components/Grid/GridItem.js';
import style from './States.module.scss';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const GET_REGIONS = gql`
	query getRegions {
		getRegions {
			_id
			name
		}
	}
`;

const ADD_REGION = gql`
	mutation addRegion($RegionInput: [String]) {
		addRegion(RegionInput: $RegionInput)
	}
`;

const States = (props) => {
	const [stateOptions, setStateOptions] = useState([
		'alberta',
		'british columbia',
		'manitoba',
		'new brunswick',
		'newfoundland and labrador',
		'nova scotia',
		'ontario',
		'prince edward island',
		'saskatchewan',
		'québec',
	]);

	const [showInput, setShowInput] = useState(false);
	const [newStateError, setNewStateError] = useState(false);
	const [stateName, setStateName] = useState('');

	const onAddNewState = () => {
		if (stateName) {
			if (stateOptions.includes(stateName.toLowerCase())) {
				setNewStateError('this province is already added');
			} else {
				setStateOptions((stateOptions) => [...stateOptions, stateName]);
				setStateName('');
				setNewStateError(false);
			}
		}
	};

	const [errorModal, setErrorModal] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [states, setStates] = useState([]);

	const { data, error, loading } = useQuery(GET_REGIONS);

	const [addRegion] = useMutation(ADD_REGION);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onAddNewState();
		}
	};

	useEffect(() => {
		if (data) {
			let selectedStates = Array.from(data.getRegions, (item) => item.name);
			data.getRegions.map((item) => {
				if (!stateOptions.includes(item.name)) {
					setStateOptions((stateOptions) => [...stateOptions, item.name]);
				}
			});
			setStates(selectedStates);
		}
	}, [, loading, data]);

	const onSubmit = () => {
		addRegion({ variables: { RegionInput: states } })
			.then((res) => {
				setSuccessModal(true);
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors[0].message) {
					setErrorText(err.graphQLErrors[0].message);
					setErrorModal(true);
				}
			});
	};

	useEffect(() => {
		if (!stateName || showInput) {
			setNewStateError(false);
		}
	}, [stateName, showInput]);

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div className={style.sentence}>Please select provinces to be active for SPARK:</div>
				</GridItem>

				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div className={style.toggleDiv}>
						<button
							onClick={() => {
								setShowInput((showInput) => !showInput);
							}}
						>
							{showInput ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							<div className={style.buttonSentence}>
								{showInput ? 'hide' : 'add new province to the list'}
							</div>
						</button>
					</div>
				</GridItem>

				{showInput && (
					<GridItem xs={12} sm={12} md={12} lg={8}>
						<div onKeyPress={(e) => handleEnter(e)} className={style.inputSection}>
							<GridContainer>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<CustomInput
										value={stateName}
										onChange={(e) => setStateName(e.target.value)}
										id="float"
										error={newStateError}
										labelText={newStateError ? newStateError : 'add new province to the list'}
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.addButton}>
										<Button disabled={!stateName} color="yellow" onClick={onAddNewState}>
											Add
										</Button>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				)}

				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div>
						{data && (
							<div className={style.checkboxRow}>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										<GridContainer>
											{stateOptions.sort().map((state) => (
												<GridItem key={state} xs={12} sm={6} md={6} lg={4}>
													<FormControlLabel
														control={
															<Checkbox
																style={{ color: 'gray' }}
																color="gray"
																name={state}
																checked={states.includes(state)}
																onChange={() => {
																	if (states.includes(state)) {
																		setStates(
																			states.filter((item) => item !== state)
																		);
																	} else {
																		setStates([...states, state]);
																	}
																}}
															/>
														}
														label={state}
													/>
												</GridItem>
											))}
										</GridContainer>
									</GridItem>
								</GridContainer>
							</div>
						)}
					</div>
				</GridItem>

				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div className={style.buttonContainer}>
						<Button color="whiteButton" onClick={() => window.location.reload()}>
							Cancel
						</Button>
						<Button color="yellow" onClick={onSubmit}>
							Submit
						</Button>
					</div>
				</GridItem>
			</GridContainer>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setErrorModal(false)} type="notif" text={errorText} />
				</DialogContent>
			</Dialog>
			<Dialog open={successModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setSuccessModal(false)} type="notif" text="Regions updated successfully." />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default States;
