import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import XLSX from 'xlsx';
import moment from 'moment';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Modal from 'commonComponents/modal/Modal';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import BarLoader from 'react-spinners/BarLoader';
import ReactTable from 'react-table';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import TripDetails from './TripDetails';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ClearIcon from '@material-ui/icons/Clear';
import style from 'shopAdminViews/orders/Orders.module.scss';

const GET_TRIPS = gql`
	query getTripsByAdmin(
		$limit: Int
		$skip: Int
		$tripType: TripType
		$ended: Boolean
		$state: StateInput
		$_id: ID
		$driver: ID
		$passenger: ID
		$costFrom: Float
		$cost: Float
		$createdAt: Date
		$costSort: Int
		$driverName: String
		$passengerName: String
		$driverPhoneNumber: String
		$passengerPhoneNumber: String
		$startDateFrom: Date
		$startDate: Date
		$endDateFrom: Date
		$endDate: Date
		$startDateSort: Int
		$endDateSort: Int
	) {
		getTripsByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				tripType: $tripType
				_id: $_id
				ended: $ended
				state: $state
				costFrom: $costFrom
				cost: $cost
				driver: $driver
				passenger: $passenger
				createdAt: $createdAt
				driverName: $driverName
				passengerName: $passengerName
				driverPhoneNumber: $driverPhoneNumber
				passengerPhoneNumber: $passengerPhoneNumber
				startDateFrom: $startDateFrom
				startDate: $startDate
				endDateFrom: $endDateFrom
				endDate: $endDate
			}
			sort: { cost: $costSort, startDate: $startDateSort, endDate: $endDateSort }
		) {
			_id
			driver {
				_id
				fullName
				phoneNumber
			}
			passenger
			passengerDetail {
				_id
				fullName
				phoneNumber
			}
			ended
			car {
				_id
			}
			waitTimePrice
			optionsPrice
			optionsPriceDetails {
				option
				price
			}
			tipValue
			tripType
			promotion
			staticMapImageUrl
			tripDistance
			state
			cost
			tripType
			createdAt
			startDate
			endDate
			rate
		}
	}
`;

const GET_TRIPS_COUNT = gql`
	query getTripsByAdminCount(
		$tripType: TripType
		$ended: Boolean
		$state: StateInput
		$_id: ID
		$driver: ID
		$passenger: ID
		$costFrom: Float
		$cost: Float
		$createdAt: Date
		$driverName: String
		$passengerName: String
		$driverPhoneNumber: String
		$passengerPhoneNumber: String
		$startDateFrom: Date
		$startDate: Date
		$endDateFrom: Date
		$endDate: Date
	) {
		getTripsByAdminCount(
			filters: {
				tripType: $tripType
				_id: $_id
				ended: $ended
				state: $state
				costFrom: $costFrom
				cost: $cost
				driver: $driver
				passenger: $passenger
				createdAt: $createdAt
				driverName: $driverName
				passengerName: $passengerName
				driverPhoneNumber: $driverPhoneNumber
				passengerPhoneNumber: $passengerPhoneNumber
				startDateFrom: $startDateFrom
				startDate: $startDate
				endDateFrom: $endDateFrom
				endDate: $endDate
			}
		)
	}
`;

const END_TRIP = gql`
	mutation endTripByAdmin($tripId: ID!) {
		endTripByAdmin(tripId: $tripId) {
			_id
		}
	}
`;

const Trips = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);
	const stateOptions = [
		'All',
		'SEARCHING',
		'ACCEPTED',
		'DRIVER CANCELED',
		'PASSENGER CANCELED',
		'COMING',
		'ARRIVED',
		'WAITING',
		'RESERVED',
		'PICKED UP',
		'DESTINATION',
		'FINISHED DUE TO NOT PAYING',
		'PASSENGER CANCELED DURING TRIP',
	];

	const endedOptions = ['All', 'Completed', 'In progress'];
	const tripTypeOptions = ['All', 'Delivery', 'Ride'];

	const [excelData, setExcelData] = useState([
		[
			'driver name',
			'driver phone No',
			'passenger name',
			'passenger phone No',
			'cost',
			'start date',
			'end date',
			'type',
			'status',
			'procedure',
		],
	]);

	const [dateFilterError, setDateFilterError] = useState('');
	const [startDateFromFilterError, setStartDateFromFilterError] = useState('');
	const [startDateFilterError, setStartDateFilterError] = useState('');
	const [endDateFilterFromError, setEndDateFilterFromError] = useState('');
	const [endDateFilterError, setEndDateFilterError] = useState('');

	const [costFromFilter, setCostFromFilter] = useState('');
	const [costFilter, setCostFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');
	const [startdateFromFilter, setStartDateFromFilter] = useState('');
	const [startdateFilter, setStartDateFilter] = useState('');
	const [endDateFromFilter, setEndDateFromFilter] = useState('');
	const [endDateFilter, setEndDateFilter] = useState('');
	const [driverNameFilter, setDriverNameFilter] = useState(props.driverName ? props.driverName : '');
	const [passengerNameFilter, setPassengerNameFilter] = useState('');
	const [driverPhoneNumberFilter, setDriverPhoneNumberFilter] = useState(
		props.driverPhoneNumber ? props.driverPhoneNumber : ''
	);
	const [passengerPhoneNumberFilter, setPassengerPhoneNumberFilter] = useState('');

	const [endedFilter, setEndedFilter] = useState(endedOptions[0]);
	const [stateFilter, setStateFilter] = useState(stateOptions[0]);
	const [typeFilter, setTypeFilter] = useState(tripTypeOptions[0]);

	const [costSort, setCostSort] = useState('');
	const [startDateSort, setStartDateSort] = useState('down');
	const [endDateSort, setEndDateSort] = useState('');

	const [selectedIds, setSelectedIds] = useState([]);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		tripType: undefined,
		ended: undefined,
		state: undefined,
		costFrom: undefined,
		cost: undefined,
		driver: undefined,
		passenger: undefined,
		createdAt: undefined,
		startDateSort: -1,
		startDateFrom: undefined,
		startDate: undefined,
		endDateFrom: undefined,
		endDate: undefined,
		endDateSort: undefined,
		costSort: undefined,
		driverName: props.driverName ? props.driverName : undefined,
		passengerName: undefined,
		driverPhoneNumber: props.driverPhoneNumber ? props.driverPhoneNumber : undefined,
		passengerPhoneNumber: undefined,
	});
	const [endTripId, setEndTripId] = useState('');
	const [modal, setModal] = useState(false);
	const [tripDetail, setTripDetail] = useState();
	const [endModal, setEndModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const [noFilterModal, setNoFilterModal] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectTripDetail = (trip) => {
		setModal(true);
		setTripDetail(trip);
	};

	const onSelectTripEnd = (tripId) => {
		setEndTripId(tripId);
		setEndModal(true);
	};

	const [endTripByAdmin, { data: mutationData, error: mutationError }] = useMutation(END_TRIP);

	const endTrip = () => {
		endTripByAdmin({ variables: { tripId: endTripId } })
			.then((res) => {
				setEndModal(false);
				refetch();
				setEndModal(false);
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setErrorText(err.graphQLErrors[0].message);
					setErrorModal(true);
				}
			});
	};

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (!startdateFilter) {
			setStartDateFilterError('');
		} else {
			if (
				moment(startdateFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(startdateFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setStartDateFilterError('');
			} else {
				setStartDateFilterError('invalid date');
			}
		}
	}, [startdateFilter]);

	useEffect(() => {
		if (!startdateFromFilter) {
			setStartDateFilterError('');
		} else {
			if (
				moment(startdateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(startdateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setStartDateFromFilterError('');
			} else {
				setStartDateFromFilterError('invalid date');
			}
		}
	}, [startdateFromFilter]);

	useEffect(() => {
		if (!endDateFilter) {
			setEndDateFilterError('');
		} else {
			if (
				moment(endDateFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(endDateFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setEndDateFilterError('');
			} else {
				setEndDateFilterError('invalid date');
			}
		}
	}, [endDateFilter]);

	useEffect(() => {
		if (!endDateFromFilter) {
			setEndDateFilterFromError('');
		} else {
			if (
				moment(endDateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(endDateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setEndDateFilterFromError('');
			} else {
				setEndDateFilterFromError('invalid date');
			}
		}
	}, [endDateFromFilter]);

	const onCostSortChange = () => {
		if (!costSort) {
			setCostSort('up');
		} else {
			if (costSort === 'up') {
				setCostSort('down');
			} else {
				setCostSort('');
			}
		}
	};

	const onStartDateSortChange = () => {
		if (!startDateSort) {
			setStartDateSort('up');
		} else {
			if (startDateSort === 'up') {
				setStartDateSort('down');
			} else {
				setStartDateSort('');
			}
		}
	};

	const onEndDateSortChange = () => {
		if (!endDateSort) {
			setEndDateSort('up');
		} else {
			if (endDateSort === 'up') {
				setEndDateSort('down');
			} else {
				setEndDateSort('');
			}
		}
	};

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getTripsByAdminCount / perPage) - 1 &&
			countData.getTripsByAdminCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getTripsByAdminCount !== 0) {
			setPageCount(Math.ceil(countData.getTripsByAdminCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (startDateSort) {
			if (startDateSort === 'up') {
				setVariables((variables) => ({ ...variables, startDateSort: 1 }));
			} else if (startDateSort === 'down') {
				setVariables((variables) => ({ ...variables, startDateSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, startDateSort: undefined }));
		}
	}, [startDateSort]);

	useEffect(() => {
		if (endDateSort) {
			if (endDateSort === 'up') {
				setVariables((variables) => ({ ...variables, endDateSort: 1 }));
			} else if (endDateSort === 'down') {
				setVariables((variables) => ({ ...variables, endDateSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, endDateSort: undefined }));
		}
	}, [endDateSort]);

	useEffect(() => {
		if (costSort) {
			if (costSort === 'up') {
				setVariables((variables) => ({ ...variables, costSort: 1 }));
			} else if (costSort === 'down') {
				setVariables((variables) => ({ ...variables, costSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, costSort: undefined }));
		}
	}, [costSort]);

	const onSubmitFilter = () => {
		let hasFilter =
			driverNameFilter ||
			variables.driverName ||
			driverPhoneNumberFilter ||
			variables.driverPhoneNumber ||
			passengerNameFilter ||
			variables.passengerName ||
			passengerPhoneNumberFilter ||
			variables.passengerPhoneNumber ||
			costFromFilter ||
			variables.costFrom ||
			costFilter ||
			variables.cost ||
			startdateFromFilter ||
			startdateFilter ||
			variables.startDate ||
			variables.startDateFrom ||
			endDateFromFilter ||
			variables.endDateFrom ||
			endDateFilter ||
			variables.endDate ||
			!(typeFilter === 'All' && !variables.tripType) ||
			!(stateFilter === 'All' && !variables.state) ||
			!(endedFilter === 'All' && variables.ended === undefined);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');

			if (costFromFilter) {
				setVariables((variables) => ({
					...variables,
					costFrom: Number(costFromFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, costFrom: undefined }));
			}

			if (costFilter) {
				setVariables((variables) => ({
					...variables,
					cost: Number(costFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, cost: undefined }));
			}

			if (driverNameFilter) {
				setVariables((variables) => ({
					...variables,
					driverName: driverNameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, driverName: undefined }));
			}

			if (passengerNameFilter) {
				setVariables((variables) => ({
					...variables,
					passengerName: passengerNameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerName: undefined }));
			}

			if (driverPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					driverPhoneNumber: driverPhoneNumberFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, driverPhoneNumber: undefined }));
			}

			if (passengerPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					passengerPhoneNumber: passengerPhoneNumberFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerPhoneNumber: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
				console.log(moment(dateFilter).format('YYYY-MM-DD'));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (
				startdateFromFilter &&
				(moment(startdateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(startdateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					startDateFrom: moment(startdateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, startDateFrom: undefined }));
			}

			if (
				startdateFromFilter &&
				(moment(startdateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(startdateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					startDateFrom: moment(startdateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, startDateFrom: undefined }));
			}

			if (
				endDateFromFilter &&
				(moment(endDateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(endDateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					endDateFrom: moment(endDateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, endDateFrom: undefined }));
			}

			if (
				endDateFilter &&
				(moment(endDateFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(endDateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					endDate: moment(endDateFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, endDate: undefined }));
			}

			if (stateFilter === 'All') {
				setVariables((variables) => ({
					...variables,
					state: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					state: stateFilter.replace(/ /g, '_').toUpperCase(),
				}));
			}

			if (typeFilter === 'All') {
				setVariables((variables) => ({
					...variables,
					tripType: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					tripType: typeFilter.toUpperCase(),
				}));
			}

			if (endedFilter === 'All') {
				setVariables((variables) => ({
					...variables,
					ended: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					ended: endedFilter === 'Completed' ? true : false,
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			tripType: undefined,
			ended: undefined,
			state: undefined,
			costFrom: undefined,
			cost: undefined,
			driver: undefined,
			passenger: undefined,
			createdAt: undefined,
			startDateSort: -1,
			startDateFrom: undefined,
			startDate: undefined,
			endDateFrom: undefined,
			endDate: undefined,
			endDateSort: undefined,
			costSort: undefined,
			driverName: props.driverName ? props.driverName : undefined,
			passengerName: undefined,
			driverPhoneNumber: props.driverPhoneNumber ? props.driverPhoneNumber : undefined,
			passengerPhoneNumber: undefined,
		});
		setDriverNameFilter('');
		setDriverPhoneNumberFilter('');
		setPassengerNameFilter('');
		setPassengerPhoneNumberFilter('');
		setCostFromFilter('');
		setCostFilter('');
		setDateFilter('');
		setStartDateFromFilter('');
		setStartDateFilter('');
		setEndDateFromFilter('');
		setEndDateFilter('');
		setCostSort('');
		setStartDateSort('down');
		setStateFilter('All');
		setEndedFilter('All');
		setTypeFilter('All');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	const { loading, error, data, refetch } = useQuery(GET_TRIPS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_TRIPS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const [getTripsByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_TRIPS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getTripsByAdminCount > 0 &&
			excelData.length === countData.getTripsByAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countData, countData]);

	useEffect(() => {
		if (lazyData) {
			console.log(lazyData);

			setExcelData([
				...excelData,
				...Array.from(lazyData.getTripsByAdmin, (item) => [
					item.driver ? item.driver.fullName : '-',
					item.driver ? item.driver.phoneNumber : '-',
					item.passengerDetail ? item.passengerDetail.fullName : '-',
					item.passengerDetail ? String(item.passengerDetail.phoneNumber) : '-',
					item.cost.toFixed(2),
					moment(item.startDate).format('MM-DD-YYYY'),
					moment(item.endDate).format('MM-DD-YYYY'),
					item.tripType.toLowerCase(),
					item.state.replace(/_/g, ' ').toLowerCase(),
					item.ended ? 'completed' : 'in progress',
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Trips.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getTripsByAdmin({ variables: { ...variables, skip: 0, limit: countData.getTripsByAdminCount } });
		}
	};

	// calculate total price
	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (data) {
			let number = 0;
			data.getTripsByAdmin.map((item) => {
				number = number + item.cost;
			});
			setTotal(number);
		}
	}, [data, loading]);

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onSubmitFilter}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getTripsByAdminCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getTripsByAdmin.length)}</span> of{' '}
							<span>{countData.getTripsByAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0);
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={[
					...data.getTripsByAdmin.map((trip) => ({
						id: (
							<CopyToClipboard text={trip._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{trip._id}
								</span>
							</CopyToClipboard>
						),
						passengerId: (
							<CopyToClipboard text={trip.passenger}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{trip.passenger}
								</span>
							</CopyToClipboard>
						),
						passengerName: trip.passengerDetail && trip.passengerDetail.fullName,
						driverName: trip.driver && trip.driver.fullName,
						driverPhoneNumber: trip.driver && trip.driver.phoneNumber,
						passengerPhoneNumber: trip.passengerDetail && trip.passengerDetail.phoneNumber,
						driverId: (
							<CopyToClipboard text={trip.driver ? trip.driver._id : ''}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{trip.driver ? trip.driver._id : ''}
								</span>
							</CopyToClipboard>
						),
						startDate: trip.startDate ? moment(trip.startDate).format('MM/DD/YYYY HH:mm') : '',
						endDate: trip.endDate ? moment(trip.endDate).format('MM/DD/YYYY HH:mm') : '',
						cost: trip.cost && '$' + trip.cost.toFixed(2),
						type: (
							<div className={`${style.inlineTag} ${style[trip.tripType.toLowerCase() + 'Tag']}`}>
								{trip.tripType.toLowerCase()}
							</div>
						),
						ended: trip.ended ? (
							<div className={`${style.inlineTag} ${style.acceptedTag}`}>completed</div>
						) : (
							<div className={`${style.inlineTag} ${style.percentTag}`}>in progress</div>
						),
						state: (
							<div
								className={`${style.inlineTag} ${
									style[trip.state.replace(/_/g, '').toLowerCase() + 'Tag']
								}`}
							>
								{trip.state.replace(/_/g, ' ').toLowerCase()}
							</div>
						),
						actions: (
							<div>
								<button onClick={() => onSelectTripDetail(trip)} className={style.detailIconDiv}>
									<span>i</span>
								</button>
								<button
									disabled={trip.ended}
									className={`${style.inlineButton} ${style.inlineDeleteButton}`}
									onClick={() => onSelectTripEnd(trip._id)}
								>
									end
								</button>
							</div>
						),
					})),
					data.getTripsByAdmin.length > 0 && {
						driverName: <div className={style.totalNumber}>Total:</div>,
						cost: <div className={style.totalNumber}> {'$' + total.toFixed(2)}</div>,
					},
				]}
				columns={[
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>Driver Name</div>
								<CustomInput
									value={driverNameFilter}
									onChange={(e) => setDriverNameFilter(e.target.value)}
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'driverName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>Driver Phone No</div>
								<CustomInput
									value={driverPhoneNumberFilter}
									onChange={(e) => setDriverPhoneNumberFilter(e.target.value)}
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'driverPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>Passenger Name</div>
								<CustomInput
									value={passengerNameFilter}
									onChange={(e) => setPassengerNameFilter(e.target.value)}
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>Passenger Phone No</div>
								<CustomInput
									value={passengerPhoneNumberFilter}
									onChange={(e) => setPassengerPhoneNumberFilter(e.target.value)}
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 145,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onCostSortChange}>
									Cost{' '}
									<span>
										{costSort && (
											<span>
												{costSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={costFromFilter}
									onChange={(e) => setCostFromFilter(e.target.value)}
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={costFilter}
									onChange={(e) => setCostFilter(e.target.value)}
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'cost',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onStartDateSortChange}>
									Start date{' '}
									<span>
										{startDateSort && (
											<span>
												{startDateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{startDateFromFilterError && (
									<div className={style.dateErrorDiv}>{startDateFromFilterError}</div>
								)}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={startdateFromFilter}
										closeOnSelect
										viewDate={moment()}
										onChange={(date) => setStartDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{startDateFilterError && (
									<div className={style.dateErrorDiv}>{startDateFilterError}</div>
								)}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={startdateFilter}
										closeOnSelect
										viewDate={moment()}
										onChange={(date) => setStartDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'startDate',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onEndDateSortChange}>
									End date{' '}
									<span>
										{endDateSort && (
											<span>
												{endDateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{endDateFilterFromError && (
									<div className={style.dateErrorDiv}>{endDateFilterFromError}</div>
								)}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={endDateFromFilter}
										closeOnSelect
										viewDate={moment()}
										onChange={(date) => setEndDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{endDateFilterError && <div className={style.dateErrorDiv}>{endDateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={endDateFilter}
										closeOnSelect
										viewDate={moment()}
										onChange={(date) => setEndDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'endDate',
						sortable: false,
						filterable: false,
						width: 140,
					},
					{
						Header: (
							<div className="dropDownFilter">
								<div className={style.titleDiv}>Type</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={typeFilter}
										onClick={(e) => setTypeFilter(e)}
										dropdownList={tripTypeOptions}
									/>
								</div>
							</div>
						),
						accessor: 'type',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="dropDownFilter">
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={stateFilter}
										onClick={(e) => setStateFilter(e)}
										dropdownList={stateOptions}
									/>
								</div>
							</div>
						),
						accessor: 'state',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Procedure</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={endedFilter}
										onClick={(e) => setEndedFilter(e)}
										dropdownList={endedOptions}
									/>
								</div>
							</div>
						),
						accessor: 'ended',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
					},
				]}
				defaultPageSize={perPage + 1}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getTripsByAdminCount === 0 ? 1 : Math.ceil(countData.getTripsByAdminCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<TripDetails refetch={() => refetch()} close={() => setModal(false)} trip={tripDetail} />
				</DialogContent>
			</Dialog>
			<Dialog open={endModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setEndModal(false)} func={endTrip} text="Do you want to end this trip?" />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setEndModal(false);
							setErrorModal(false);
						}}
						type="notif"
						text={errorText ? errorText : 'Error ! Please Fill All Inputs Correctly And Try Again.'}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Trips;
