import React, { useState, useEffect } from "react";
import moment from "moment";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Badge from "components/Badge/Badge.js";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { endPoint, graphqlUrl } from "utils/config";
import style from "superAdminViews/ride/drivers/DriverDetails.module.scss";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import profileImage from "assets/img/faces/card-profile1-square.jpg";

const UserDetails = ({ user, close }) => {
  const profileImages = [`${graphqlUrl}${user.profileImageUrl}`];
  const [isProfileImagesOpen, setIsProfileImagesOpen] = useState(false);
  const [profileImageIndex, setProfileImageIndex] = useState(0);

  return (
    <div className={style.mainDiv}>
      <div className={style.header}>
        <div>
          <Button
            justIcon
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={close}
          >
            <Close />
          </Button>
        </div>
        <div className={style.title}>
          <h4>User details</h4>
        </div>
      </div>
      <div className={style.content}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  <div className={style.label}>User ID:</div>
                </GridItem>
                <GridItem xs={12} sm={8} md={8} lg={8}>
                  <div className={style.value}>{user._id}</div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  <div className={style.label}>Full name:</div>
                </GridItem>
                <GridItem xs={12} sm={8} md={8} lg={8}>
                  <div className={style.value}>{user.fullName}</div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  <div className={style.label}>Email:</div>
                </GridItem>
                <GridItem xs={12} sm={8} md={8} lg={8}>
                  <div className={style.value}>{user.email}</div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Phone number:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>{user.phoneNumber}</div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>State:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>{user.state}</div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Has notification:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>
                    {user.hasNotification ? (
                      <div className={style.iconCell}>
                        <CheckIcon
                          style={{ fontSize: "15px", color: "green" }}
                        />
                      </div>
                    ) : (
                      <div className={style.iconCell}>
                        <CloseIcon style={{ fontSize: "15px", color: "red" }} />
                      </div>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Verified:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>
                    {user.isVerified ? (
                      <div className={style.iconCell}>
                        <CheckIcon
                          style={{ fontSize: "15px", color: "green" }}
                        />
                      </div>
                    ) : (
                      <div className={style.iconCell}>
                        <CloseIcon style={{ fontSize: "15px", color: "red" }} />
                      </div>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Email verified:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>
                    {user.emailVerified ? (
                      <div className={style.iconCell}>
                        <CheckIcon
                          style={{ fontSize: "15px", color: "green" }}
                        />
                      </div>
                    ) : (
                      <div className={style.iconCell}>
                        <CloseIcon style={{ fontSize: "15px", color: "red" }} />
                      </div>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Phone number verified:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>
                    {user.phoneNumberVerified ? (
                      <div className={style.iconCell}>
                        <CheckIcon
                          style={{ fontSize: "15px", color: "green" }}
                        />
                      </div>
                    ) : (
                      <div className={style.iconCell}>
                        <CloseIcon style={{ fontSize: "15px", color: "red" }} />
                      </div>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Average rate:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>
                    {user.averageRate && user.averageRate.toFixed(1)}
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Number of rate:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>{user.numberOfRates}</div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Gender:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>{user.gender}</div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Created at:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>
                    {moment(user.createdAt).format("MM/DD/YYYY HH:mm")}
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Updated at:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>
                    {moment(user.updatedAt).format("MM/DD/YYYY HH:mm")}
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div style={{ margin: "10px 0" }}>Default address</div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Full:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>{user.defaultAddress.full}</div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Zip code:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>
                    {user.defaultAddress.zipCode}
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div style={{ margin: "10px 0" }}>Addresses</div>
            {user.addresses.map((address) => (
              <div>
              <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Full:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>{user.defaultAddress.full}</div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={style.rowContainer}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.label}>Zip code:</div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <div className={style.value}>
                    {user.defaultAddress.zipCode}
                  </div>
                </GridItem>
              </GridContainer>
            </div>
              </div>
            ))}
          </GridItem>
        </GridContainer>
      </div>
      <div className={style.footer}>
        <Button color="yellow" onClick={close}>Close</Button>
      </div>
      {isProfileImagesOpen && (
        <Lightbox
          reactModalStyle={{ zIndex: "5000" }}
          mainSrc={profileImages[profileImageIndex]}
          nextSrc={
            profileImages[(profileImageIndex + 1) % profileImages.length]
          }
          prevSrc={
            profileImages[
              (profileImageIndex + profileImages.length - 1) %
                profileImages.length
            ]
          }
          onCloseRequest={() => setIsProfileImagesOpen(false)}
          onMovePrevRequest={() =>
            setProfileImageIndex(
              (profileImageIndex + profileImages.length - 1) %
                profileImages.length
            )
          }
          onMoveNextRequest={() =>
            setProfileImageIndex((profileImageIndex + 1) % profileImages.length)
          }
        />
      )}
    </div>
  );
};
export default UserDetails;
