import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import EditDriverForm from './EditDriverForm';

const GET_DRIVER = gql`
	query getDriverByAdmin($id: ID!) {
		getDriverByAdmin(id: $id) {
			_id
			fullName
			email
			phoneNumber
			profileImageUrl
			birthDate
			emailVerified
			phoneNumberVerified
			hasNotification
			gender
			state
			workStatus
			address {
				full
				zipCode
			}
		}
	}
`;

const EditDriver = (props) => {
	const { loading, error, data } = useQuery(GET_DRIVER, {
		variables: { id: props.match.params.id },
	});
	if (data) {
		console.log(data);
	}

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}
	return <EditDriverForm driver={data.getDriverByAdmin} />;
};

export default EditDriver;
