import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { graphqlUrl } from 'utils/config';
import XLSX from 'xlsx';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReactTable from 'react-table';
import BarLoader from 'react-spinners/BarLoader';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import Files from 'react-butterfiles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import Pagination from 'commonComponents/pagination/Pagination';
import Modal from 'commonComponents/modal/Modal';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import style from 'superAdminViews/setting/defaults/DriverReadyComments.module.scss';
import EditProductOption from './EditProductOption';

const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!, $folderName: String!) {
		uploadFile(data: { file: $file, folderName: $folderName }) {
			url
		}
	}
`;

const ADD_CATEGORY = gql`
	mutation createCategoryByAdmin($parent: ID, $title: String!, $photoUrl: String) {
		createCategoryByAdmin(data: { parent: $parent, title: $title, photoUrl: $photoUrl }) {
			_id
			title
		}
	}
`;

const GET_CATEGORIES = gql`
	query getCategories($limit: Int, $skip: Int, $parent: ID) {
		getCategories(pagination: { limit: $limit, skip: $skip }, filters: { parent: $parent }) {
			_id
			parent {
				_id
				title
			}
			title
			photoUrl
		}
	}
`;

const GET_CATEGORIES_COUNT = gql`
	query getCategoriesCount {
		getCategoriesCount
	}
`;

const DELETE_CATEGORY = gql`
	mutation deleteCategoryByAdmin($idSet: [ID!]!) {
		deleteCategoryByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const Categories = (props) => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
	});

	const { loading, error, data } = useQuery(GET_CATEGORIES, {
		variables: {
			parent: null,
		},
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_CATEGORIES_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: mainLoading, error: mainError, data: mainData, refetch } = useQuery(GET_CATEGORIES, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const [title, setTitle] = useState('');
	const [parentName, setParentName] = useState(
		data && Array.from(data.getCategories, (category) => category.title)[0]
	);
	const [parentId, setparentId] = useState();
	const [image, setImage] = useState();
	const [imageError, setImageError] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const [createCategoryByAdmin, { data: addMutationData, error: addMutationError }] = useMutation(ADD_CATEGORY);

	const [uploadFile, { data: uploadData, error: uploadError, loading: uploadLoading }] = useMutation(UPLOAD_FILE);

	const uploadImage = (file) => {
		uploadFile({
			variables: { file: file.src.file, folderName: 'categoryImage' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			setImage(res.data.uploadFile.url);
		});
	};

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil((countData.getCategoriesCount - 2) / perPage)) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil((countData.getCategoriesCount - 2) / perPage));
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		if (data) {
			setParentName(data.getCategories[0].title);
		}
	}, [, data]);

	useEffect(() => {
		if (data) {
			if (parentName === 'root category') {
				setparentId(undefined);
			} else {
				setparentId(data.getCategories.find((category) => category.title === parentName)._id);
			}
		}
	}, [, parentName]);

	const [titleError, setTitleError] = useState(false);

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [categoryForDelete, setCategoryForDelete] = useState();

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [addModal, setAddModal] = useState(false);
	const [modal, setModal] = useState(false);
	const [categoryDetails, setCategoryDetails] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	// testing menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onSelectCategoryEdit = (category) => {
		// should change after backend fix some issues
		setModal(true);
		setCategoryDetails(category);
	};

	const [excelData, setExcelData] = useState([['title', 'parent']]);

	const [getCategories, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_CATEGORIES);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (countData && excelData.length === countData.getCategoriesCount - 1) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getCategories.filter((item) => item.parent), (item) => [
					item.title,
					item.parent ? item.parent.title : '-',
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Categories.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getCategories({ variables: { ...variables, skip: 0, limit: countData.getCategoriesCount } });
		}
	};

	const onDeleteCategory = (category) => {
		setDeleteModal(true);
		setCategoryForDelete([category._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const [deleteCategoryByAdmin] = useMutation(DELETE_CATEGORY);

	const deleteCategory = () => {
		deleteCategoryByAdmin({ variables: { idSet: categoryForDelete } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		deleteCategoryByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	useEffect(() => {
		if (mainData) {
			setCheckAll(true);
			mainData.getCategories
				.filter((category) => category.parent !== null)
				.map((item) => {
					if (!idsForDelete.includes(item._id)) {
						setCheckAll(false);
					}
				});
		}
	}, [mainData, mainLoading, idsForDelete, pageCount, perPage]);

	const onSubmit = () => {
		if (!title) {
			setTitleError(true);
		} else {
			setTitleError(false);
		}
		if (!image) {
			setImageError(true);
		} else {
			setImageError(false);
		}
		if (title && image) {
			setDisableButton(true);
			createCategoryByAdmin({
				variables: {
					title: title.trim(),
					parent: parentId,
					photoUrl: image,
				},
			})
				.then((res) => {
					setDisableButton(false);
					refetch();
					setTitle('');
					setImage('');
					setAddModal(true);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	if (mainLoading || loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (mainError || error) {
		console.log(mainError);
		return <ErrorPage />;
	}
	return (
		<div className={style.mainDiv}>
			<div>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}
				{idsForDelete.length > 0 && (
					<Button title="delete selected items" color="redButton" onClick={onDeleteSome}>
						Delete
					</Button>
				)}
			</div>

			<div className={style.inputRow}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12} lg={12}>
								<div className={style.inputContainer}>
									<GridContainer>
										<GridItem xs={12} sm={4} md={4}>
											<div className={style.inputLabel}>Title:</div>
										</GridItem>
										<GridItem xs={12} sm={8} md={8}>
											<CustomInput
												value={title}
												onChange={(e) => setTitle(e.target.value)}
												error={titleError}
												labelText={titleError ? "title can't be empty*" : 'title'}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</GridItem>
									</GridContainer>
								</div>
							</GridItem>

							{data && (
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<div className={style.dropdownRow}>
										<GridContainer>
											<GridItem xs={12} sm={6} md={6}>
												<div className={style.dropdownLabel}>Parent category:</div>
											</GridItem>
											<GridItem xs={12} sm={6} md={6}>
												<div className={style.dropdownContainer}>
													<CustomDropdown
														hoverColor="primary"
														buttonText={parentName}
														onClick={(e) => setParentName(e)}
														dropdownList={
															data &&
															Array.from(data.getCategories, (category) => category.title)
														}
													/>
												</div>
											</GridItem>
										</GridContainer>
									</div>
								</GridItem>
							)}
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12} lg={12}>
								<div style={{ marginTop: '23px' }} className={style.imageRow}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={5}>
											<div className={`${style.inputLabel} ${style.imageInputLabel}`}>
												Photo:
												<div className={style.imageHint}>(upload an image less than 10MB)</div>
											</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={7}>
											<div className={style.imageContainer}>
												<Files
													maxSize="10mb"
													multiple={false}
													convertToBase64={true}
													accept={['image/jpg', 'image/jpeg', 'image/png']}
													onSuccess={(file) => uploadImage(file[0])}
												>
													{({ browseFiles, getDropZoneProps, getLabelProps }) => (
														<div style={{ textAlign: 'right' }}>
															{image && (
																<div>
																	<img
																		style={{ width: '200px', height: '200px' }}
																		alt="advertisement photo"
																		src={`${graphqlUrl}${image}`}
																	/>
																</div>
															)}
															{uploadLoading && (
																<CircularProgress style={{ color: 'gray' }} />
															)}
															<div
																{...getDropZoneProps({
																	className: 'myDropZone',
																})}
															/>
															<Button
																style={{
																	// marginTop: '15px',
																	marginRight: '10px',
																}}
																color="whiteButton"
																onClick={browseFiles}
															>
																Select Photo...
															</Button>
															{imageError && (
																<p
																	style={{
																		color: 'red',
																		fontsize: '9px',
																	}}
																>
																	please enter image file less than 10MB size
																</p>
															)}
														</div>
													)}
												</Files>
											</div>
										</GridItem>
									</GridContainer>
								</div>
							</GridItem>
						</GridContainer>
					</GridItem>

					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={`${style.inputButtonContainer} ${style.middleButtonContainer}`}>
							<Button disabled={uploadLoading || disableButton} color="yellow" onClick={onSubmit}>
								Add
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<ReactTable
				data={mainData.getCategories
					.filter((category) => category.parent !== null)
					.map((category) => ({
						checkbox: (
							<div className={style.checkboxDivHeader}>
								<Checkbox
									color="default"
									checked={idsForDelete.includes(category._id)}
									onChange={() => {
										if (idsForDelete.includes(category._id)) {
											setIdsForDelete((idsForDelete) =>
												idsForDelete.filter((item) => item !== category._id)
											);
										} else {
											setIdsForDelete([...idsForDelete, category._id]);
										}
									}}
								/>
							</div>
						),
						photo: (
							<img
								style={{ width: '90px', height: '90px' }}
								alt="profile-photo"
								src={`${graphqlUrl}${category.photoUrl}`}
							/>
						),
						title: category.title,
						parent: category.parent.title,
						actions: (
							<div>
								<IconButton
									id={category._id}
									aria-controls="simple-menu"
									aria-haspopup="true"
									onClick={(e) => {
										handleClick(e, category._id);
									}}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									open={menuId === category._id}
									onClose={handleClose}
								>
									<MenuItem onClick={() => onSelectCategoryEdit(category)}>edit</MenuItem>
									<MenuItem onClick={() => onDeleteCategory(category)}>delete</MenuItem>
								</Menu>
							</div>
						),
					}))}
				columns={[
					{
						Header: (
							<div className={style.fitTitleDivCheckbox}>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											mainData.getCategories
												.filter((category) => category.parent !== null)
												.map((item) => {
													setIdsForDelete((idsForDelete) =>
														idsForDelete.filter((id) => id !== item._id)
													);
												});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(
													mainData.getCategories.filter(
														(category) => category.parent !== null
													),
													(item) => item._id
												),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: (
							<div className={style.fitTitleDiv}>
								<div>Photo</div>
							</div>
						),
						accessor: 'photo',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Title</div>,
						accessor: 'title',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Parent</div>,
						accessor: 'parent',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && countData.getCategoriesCount !== 2 && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getCategoriesCount !== 2 ? Math.ceil((countData.getCategoriesCount - 2) / perPage) : 1
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<EditProductOption
						refetch={() => refetch()}
						close={() => setModal(false)}
						type="category"
						item={categoryDetails}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text="Category added succesfully." />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
			{categoryForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteCategory}
							text="Do you want to delete this category?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setIdsForDelete([]);
							setDeleteSomeModal(false);
						}}
						func={deleteSome}
						text="Do you want to delete these categories?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Categories;
