import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { login } from "utils/auth";
import { endPoint } from "utils/config";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import style from "./Login.module.scss";

const LOGIN = gql`
  mutation adminLogin($emailOrPhoneNumber: String!, $password: String!) {
    adminLogin(
      input: { emailOrPhoneNumber: $emailOrPhoneNumber, password: $password }
    ) {
      type
      shop {
        _id
        rootCategory {
          _id
          title
        }
        stripeAccountId
      }
      token {
        accessToken
        refreshToken
      }
      fullName
      roles {
        permissions {
          name
        }
      }
    }
  }
`;

const Login = (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [visible, setVisible] = useState(false);

  const [emailErrorText, setEmailErrorText] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");

  const [adminLogin, { data, error }] = useMutation(LOGIN);

  // if (paymentStatusData && data) {
  //   if (
  //     data.adminLogin.shop &&
  //     paymentStatusData.getPaymentMethodStatus === "HAS_ACCOUNT"
  //   ) {
  //     window.location.href = endPoint + "/shopAdmin/dashboard";
  //     console.log(data);
  //     window.localStorage.setItem(
  //       "shopType",
  //       data.adminLogin.shop.rootCategory.title.toString()
  //     );
  //   } else if (
  //     data.adminLogin.shop &&
  //     paymentStatusData.getPaymentMethodStatus === "DOES_NOT_HAVE_ACCOUNT"
  //   ) {
  //     window.location.href = endPoint + "/api/v1/paymentMethod";
  //   }
  // }

  useEffect(() => {
    window.localStorage.removeItem("shopId");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("shopType");
    window.localStorage.removeItem("verificationCode");
    window.localStorage.removeItem("phoneNumber");
    window.localStorage.removeItem("permissions");
  }, [,]);

  const onSubmit = () => {
    if (!email) {
      setEmailErrorText(true);
    } else {
      setEmailErrorText(false);
    }
    if (!password) {
      setPasswordErrorText(true);
    } else {
      setPasswordErrorText(false);
    }
    if (password && email) {
      adminLogin({ variables: { emailOrPhoneNumber: email, password } })
        .then((res) => {
          login(
            res.data.adminLogin.token.accessToken.toString(),
            res.data.adminLogin.token.refreshToken.toString()
          );

          if (res.data.adminLogin.type === "SUPER-ADMIN") {
            // save permissions in local storage
            if (res.data.adminLogin.roles) {
              let array = [];
              res.data.adminLogin.roles.map((role) => {
                role.permissions.map((permission) =>
                  array.push(permission.name)
                );
              });
              window.localStorage.setItem("permissions", JSON.stringify(array));
            }
            window.location.href = endPoint + "/superAdmin/dashboard";
          } else if (res.data.adminLogin.type === "SHOP-ADMIN") {
            window.localStorage.setItem("permissions", JSON.stringify([]));
            if (!res.data.adminLogin.shop) {
              window.location.href = endPoint + "/addShop";
              window.localStorage.removeItem("shopId");
              window.localStorage.removeItem("shopType");
            } else if (res.data.adminLogin.shop) {
              // getPaymentMethodStatus();
              if (
                res.data.adminLogin.shop &&
                res.data.adminLogin.shop.stripeAccountId
              ) {
                console.log(res.data);
                window.localStorage.setItem(
                  "shopType",
                  res.data.adminLogin.shop.rootCategory.title.toString()
                );
                window.location.href = endPoint + "/shopAdmin/dashboard";
              } else if (
                res.data.adminLogin.shop &&
                !res.data.adminLogin.shop.stripeAccountId
              ) {
                window.location.href = endPoint + "/api/v1/paymentMethod";
              }
            }
          }
        })
        .catch((err) => {
          // window.localStorage.removeItem("token");
          console.log(JSON.parse(JSON.stringify(err)));
          if (
            err.graphQLErrors &&
            err.graphQLErrors.length > 0 &&
            err.graphQLErrors[0].extensions.code === "404"
          ) {
            setEmailErrorText("wrong email or phone number");
          } else {
            setEmailErrorText("");
          }
          if (
            err.graphQLErrors &&
            err.graphQLErrors.length > 0 &&
            err.graphQLErrors[0].extensions.code === "400"
          ) {
            setPasswordErrorText("wrong password");
          } else {
            setPasswordErrorText("");
          }
        });
    }
  };

  return (
    <div className={style.mainDiv}>
      <GridContainer justify="center">
        <GridItem xs={10} sm={7} md={5} lg={4}>
          <Card>
            <CardHeader color="warning">
              <div className={style.title}>Login</div>
            </CardHeader>
            <CardBody style={{ textAlign: "center" }}>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <div className={style.inputLabel}>Email : </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={7}>
                  <CustomInput
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    warning={!emailErrorText}
                    error={emailErrorText}
                    labelText={
                      emailErrorText ? emailErrorText : "email or phone number"
                    }
                    id="float"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "email",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <div className={style.inputLabel}>Password : </div>
                </GridItem>
                <GridItem xs={12} sm={8} md={7}>
                  <CustomInput
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    warning={!passwordErrorText}
                    error={passwordErrorText}
                    labelText={
                      passwordErrorText ? passwordErrorText : "password"
                    }
                    id="float"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: visible ? "text" : "password",
                      endAdornment: visible ? (
                        <VisibilityIcon
                          className={style.icon}
                          onClick={() => setVisible((visible) => !visible)}
                          style={{ fontSize: "18px", color: "gray" }}
                          position="end"
                        ></VisibilityIcon>
                      ) : (
                        <VisibilityOffIcon
                          className={style.icon}
                          onClick={() => setVisible((visible) => !visible)}
                          style={{ fontSize: "18px", color: "gray" }}
                          position="end"
                        ></VisibilityOffIcon>
                      ),
                    }}
                  />
                </GridItem>
              </GridContainer>
              {/** <div className={style.dropdownContainer}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <div className={style.inputLabel}>Role : </div>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <div className={style.dropdownWrapper}>
                      <CustomDropdown
                        hoverColor="warning"
                        buttonText={role}
                        onClick={(e) => setRole(e)}
                        dropdownList={roles}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                  </div> **/}
              <div className={style.buttonContainer}>
                <Button size="sm" onClick={onSubmit} color="warning">
                  Login
                </Button>
              </div>
              {props.type === "shopAdmin" && (
                <div className={style.buttonContainer}>
                  <Link style={{ color: "#ff6600" }} to="/submitPhone">
                    Sign Up
                  </Link>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Login;
