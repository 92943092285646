import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { endPoint } from 'utils/config';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import Modal from 'commonComponents/modal/Modal';
import Slide from '@material-ui/core/Slide';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CustomInput from 'components/CustomInput/CustomInput.js';
import style from 'superAdminViews/ride/addDriver/AddDriver.module.scss';

const SIGNUP_DRIVER = gql`
	mutation driverSignUpByAdmin($fullName: String!, $email: String!, $password: String!, $phoneNumber: String!) {
		driverSignUpByAdmin(
			input: { fullName: $fullName, email: $email, password: $password, phoneNumber: $phoneNumber }
		) {
			_id
			fullName
		}
	}
`;

const SignupDriver = () => {
	const [fullName, setFullName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [visible, setVisible] = useState(false);
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [fullNameError, setFullNameError] = useState(false);
	const [emailErrorText, setEmailErrorText] = useState('');
	const [passwordErrorText, setPasswordErrorText] = useState('');
	const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');

	const [phoneNumberErrorText, setPhoneNumberErrorText] = useState('');
	const [disableButton, setDisableButton] = useState(false);

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [addModal, setAddModal] = useState(false);
	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const emailRegex = /[\w-]+@([\w-]+\.)+[\w-]+/;

	// block navigation

	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [shouldLeavePage, setShouldLeavePage] = useState(false);

	useEffect(() => {
		if (shouldLeavePage) {
			window.onbeforeunload = undefined;
		} else {
			if (shouldBlockNavigation) {
				window.onbeforeunload = () => true;
			} else {
				window.onbeforeunload = undefined;
			}
		}
	}, [shouldBlockNavigation]);

	useEffect(() => {
		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	useEffect(() => {
		if (fullName || phoneNumber || email || password) {
			setShouldBlockNavigation(true);
		} else {
			setShouldBlockNavigation(false);
		}
	});

	const [driverSignUpByAdmin, { data: addMutationData, error: addMutationError }] = useMutation(SIGNUP_DRIVER);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	const onCancel = () => {
		window.onbeforeunload = null;
		setShouldBlockNavigation(false);
		setShouldLeavePage(true);
		window.location.href = endPoint + `/superAdmin/addDriver`;
	};

	const onSubmit = () => {
		if (!fullName) {
			setFullNameError(true);
		} else {
			setFullNameError(false);
		}
		if (!email) {
			setEmailErrorText("email can't be empty");
		} else {
			if (!email.match(emailRegex)) {
				setEmailErrorText('please enter a valid email');
			} else {
				setEmailErrorText('');
			}
		}
		if (!phoneNumber) {
			setPhoneNumberErrorText("phone number can't be empty");
		} else {
			if (String(phoneNumber).match(/^[0-9][A-Za-z0-9 -]*$/)) {
				setPhoneNumberErrorText('');
			} else {
				setPhoneNumberErrorText('enter a valid phone number');
			}
		}
		if (!password) {
			setPasswordErrorText("password can't be empty");
		} else {
			if (password.length < 8) {
				setPasswordErrorText('password should have at least 8 caracters');
			} else {
				setPasswordErrorText('');
			}
		}
		if (!confirmPassword) {
			setConfirmPasswordErrorText("confirm can't be empty");
		} else {
			if (password && password === confirmPassword) {
				setConfirmPasswordErrorText('');
			} else {
				setConfirmPasswordErrorText("password and confirm aren't equal");
			}
		}
		if (
			fullName &&
			email &&
			password &&
			phoneNumber &&
			password.length > 7 &&
			String(phoneNumber).match(/^[0-9][A-Za-z0-9 -]*$/) &&
			email.match(emailRegex) &&
			confirmPassword &&
			password === confirmPassword
		) {
			setDisableButton(true);
			window.onbeforeunload = undefined;
			setShouldBlockNavigation(false);
			setShouldLeavePage(true);
			driverSignUpByAdmin({
				variables: {
					fullName: fullName.trim(),
					password: password.trim(),
					email: email.trim(),
					phoneNumber: phoneNumber.trim(),
				},
			})
				.then((res) => {
					window.location.href = endPoint + '/superAdmin/addDriver';
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};
	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDiv}>
			<Prompt when={shouldBlockNavigation} message="You have unsaved changes, are you sure you want to leave?" />
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div className={style.leftSide}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Full name:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={fullName}
											onChange={(e) => setFullName(e.target.value)}
											error={fullNameError}
											labelText={fullNameError ? "full name can't be empty*" : 'full name'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Email:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											error={emailErrorText}
											labelText={emailErrorText ? emailErrorText : 'email'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Phone number:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={phoneNumber}
											onChange={(e) => setPhoneNumber(e.target.value)}
											error={phoneNumberErrorText}
											labelText={phoneNumberErrorText ? phoneNumberErrorText : 'phone number'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div className={style.rightSide}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Password:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											error={passwordErrorText}
											labelText={passwordErrorText ? passwordErrorText : 'password'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												type: visible ? 'text' : 'password',
												endAdornment: visible ? (
													<VisibilityIcon
														className={style.icon}
														onClick={() => setVisible((visible) => !visible)}
														style={{ fontSize: '18px', color: 'gray' }}
														position="end"
													></VisibilityIcon>
												) : (
													<VisibilityOffIcon
														className={style.icon}
														onClick={() => setVisible((visible) => !visible)}
														style={{ fontSize: '18px', color: 'gray' }}
														position="end"
													></VisibilityOffIcon>
												),
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Confirm password:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={confirmPassword}
											onChange={(e) => setConfirmPassword(e.target.value)}
											error={confirmPasswordErrorText}
											labelText={confirmPasswordErrorText ? confirmPasswordErrorText : 'password'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												type: confirmVisible ? 'text' : 'password',
												endAdornment: confirmVisible ? (
													<VisibilityIcon
														className={style.icon}
														onClick={() => setConfirmVisible((visible) => !visible)}
														style={{ fontSize: '18px', color: 'gray' }}
														position="end"
													></VisibilityIcon>
												) : (
													<VisibilityOffIcon
														className={style.icon}
														onClick={() => setConfirmVisible((visible) => !visible)}
														style={{ fontSize: '18px', color: 'gray' }}
														position="end"
													></VisibilityOffIcon>
												),
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div className={style.buttonContainer}>
						<Button color="whiteButton" onClick={onCancel}>
							Cancel
						</Button>
						<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
							Sign Up
						</Button>
					</div>
				</GridItem>
			</GridContainer>

			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text="Driver Signed up Succesfully" />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error ! Please Fill All Inputs Correctly And Try Again .'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default SignupDriver;
