import React, { useState, useContext, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import Modal from 'commonComponents/modal/Modal';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import BeatLoader from 'react-spinners/BeatLoader';
import ClearIcon from '@material-ui/icons/Clear';
import ReactTable from 'react-table';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import OrderDetails from 'shopAdminViews/orders/OrderDetails';
import style from 'shopAdminViews/orders/Orders.module.scss';
import { ThemeContext } from 'index';

const GET_ORDERS = gql`
	query getOrdersHistoryByShopAdmin(
		$limit: Int
		$skip: Int
		$_id: ID
		$user: ID
		$shipmentModel: OrderShipmentModel
		$finalPrice: Float
		$commission: Float
		$finished: Boolean
		$status: OrderStatus
		$createdAt: Date
		$createdAtSort: Int
		$finalPriceSort: Int
		$passengerName: String
		$passengerPhoneNumber: String
		$tracking: OrderTrackingQueryInput
		$createdAtFrom: Date
	) {
		getOrdersHistoryByShopAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				user: $user
				shipmentModel: $shipmentModel
				finalPrice: $finalPrice
				commission: $commission
				finished: $finished
				status: $status
				createdAt: $createdAt
				passengerName: $passengerName
				passengerPhoneNumber: $passengerPhoneNumber
				tracking: $tracking
				createdAtFrom: $createdAtFrom
			}
			sort: { createdAt: $createdAtSort, finalPrice: $finalPriceSort }
		) {
			_id
			user {
				_id
				fullName
				phoneNumber
			}
			shipmentModel
			finalPrice
			commission
			createdAt
			finished
			status
			trip {
				_id
				trackId
				origin {
					coordinates
				}
			}
			tracking {
				trackId
			}
			cart {
				products {
					quantity
					product {
						_id
						title
					}
				}
			}
		}
	}
`;

const GET_ORDERS_COUNT = gql`
	query getOrdersHistoryByShopAdminCount(
		$_id: ID
		$user: ID
		$shipmentModel: OrderShipmentModel
		$finalPrice: Float
		$commission: Float
		$finished: Boolean
		$status: OrderStatus
		$createdAt: Date
		$passengerName: String
		$passengerPhoneNumber: String
		$tracking: OrderTrackingQueryInput
		$createdAtFrom: Date
	) {
		getOrdersHistoryByShopAdminCount(
			filters: {
				_id: $_id
				user: $user
				shipmentModel: $shipmentModel
				finalPrice: $finalPrice
				commission: $commission
				finished: $finished
				status: $status
				createdAt: $createdAt
				passengerName: $passengerName
				passengerPhoneNumber: $passengerPhoneNumber
				tracking: $tracking
				createdAtFrom: $createdAtFrom
			}
		)
	}
`;

const OrdersHistory = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const statusOptions = [
		'ALL',
		'PENDING',
		'PAID',
		'SHIPPING',
		'DELIVERED',
		'REJECTED',
		'DELIVERY NOT ACCEPTED',
		'FINISHED DUE TO NOT PAYING',
	];

	const shipmentModelOptions = ['ALL', 'SHOP', 'SPARK'];

	const finishedOptions = ['ALL', 'FINISHED', 'UNFINISHED'];

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		createdAtSort: -1,
		status: undefined,
		createdAt: undefined,
		passengerName: undefined,
		passengerPhoneNumber: undefined,
		finalPrice: undefined,
		finalPriceSort: undefined,
		tracking: { trackId: undefined, estimatedDelivery: undefined },
		finished: undefined,
		createdAtFrom: undefined,
	});

	const { loading, error, data, refetch } = useQuery(GET_ORDERS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const themeContext = useContext(ThemeContext);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
		buttonColor: 'orange',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
		buttonColor: 'green',
	};
	const theme = themeContext === 'Purchase' ? greenTheme : orangeTheme;

	const [dateFromFilter, setDateFromFilter] = useState('');
	const [dateFromFilterError, setDateFromFilterError] = useState('');

	const [idFilter, setIdFilter] = useState('');
	const [trackIdFilter, setTrackIdFilter] = useState('');
	const [userIdFilter, setUserIdFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');
	const [passengerNameFilter, setPassengerNameFilter] = useState('');
	const [passengerPhoneNumberFilter, setPassengerPhoneNumberFilter] = useState('');

	const [status, setStatus] = useState(statusOptions[0]);
	const [shipmentModelFilter, setShipmentModelFilter] = useState(shipmentModelOptions[0]);
	const [finishedFilter, setFinishedFilter] = useState(finishedOptions[0]);

	const [dateSort, setDateSort] = useState('down');
	const [dateFilterError, setDateFilterError] = useState('');

	const [idFilterError, setIdFilterError] = useState(false);
	const [trackIdFilterError, setTrackIdFilterError] = useState(false);
	const [userIdFilterError, setUserIdFilterError] = useState(false);
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [modal, setModal] = useState(false);
	const [deliveryRequestModal, setDeliveryRequestModal] = useState(false);
	const [orderDelivery, setOrderDelivery] = useState();
	const [orderDetail, setOrderDetail] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectOrderDetail = (order) => {
		setModal(true);
		setOrderDetail(order);
	};

	const [excelData, setExcelData] = useState([
		['track ID', 'customer name', 'customer phone No', 'date', 'price', 'status', 'finished', 'products'],
	]);

	const [getOrdersHistoryByShopAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(
		GET_ORDERS
	);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getOrdersHistoryByShopAdminCount > 0 &&
			excelData.length === countData.getOrdersHistoryByShopAdminCount + 1
		) {
			setShowExcelButton('done');
		}
		if (countData) {
			console.log(countData.getOrdersHistoryByShopAdminCount);
			console.log(excelData.length);
		}
	}, [excelData, countData, countLoading, countError]);

	useEffect(() => {
		if (lazyData) {
			console.log('lazy data', lazyData);
			setExcelData([
				...excelData,
				...Array.from(lazyData.getOrdersHistoryByShopAdmin, (item) => [
					item.tracking.trackId,
					item.user ? item.user.fullName : '-',
					item.user ? item.user.phoneNumber : '-',
					moment(item.createdAt).format('MM-DD-YYYY'),
					item.finalPrice.toFixed(2),
					item.status.replace(/_/g, ' ').toLowerCase(),
					item.finished ? 'completed' : 'in process',
					Array.from(item.cart.products, (element) => `${element.product.title}(${element.quantity})`).join(
						', '
					),
				]),
			]);
		}
		if (lazyError) {
			console.log(lazyError);
		}
	}, [, lazyData, lazyLoading, lazyError]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'OrdersHistory.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getOrdersHistoryByShopAdmin({
				variables: {
					...variables,
					skip: 0,
					limit: countData.getOrdersHistoryByShopAdminCount,
				},
			});
		}
	};

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getOrdersHistoryByShopAdminCount / perPage) - 1 &&
			countData.getOrdersHistoryByShopAdminCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};

	const onLastPage = () => {
		if (countData.getOrdersHistoryByShopAdminCount !== 0) {
			setPageCount(Math.ceil(countData.getOrdersHistoryByShopAdminCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({
			...variables,
			skip: perPage * pageCount,
		}));
	}, [pageCount, countData]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_ORDERS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('up');
			}
		}
	};

	useEffect(() => {
		if (idFilter && idFilter.length !== 24) {
			setIdFilterError(true);
		} else {
			setIdFilterError(false);
		}
	}, [idFilter]);

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFromFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (userIdFilter && userIdFilter.length !== 24) {
			setUserIdFilterError(true);
		} else {
			setUserIdFilterError(false);
		}
	}, [userIdFilter]);

	useEffect(() => {
		if (trackIdFilter && trackIdFilter.length !== 10) {
			setTrackIdFilterError(true);
		} else {
			setTrackIdFilterError(false);
		}
	}, [trackIdFilter]);

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}
	}, [dateSort]);

	const onFilterSubmit = () => {
		let hasFilter =
			trackIdFilter ||
			variables.tracking.trackId ||
			passengerNameFilter ||
			variables.passengerName ||
			passengerPhoneNumberFilter ||
			variables.passengerPhoneNumber ||
			dateFromFilter ||
			variables.createdAtFrom ||
			dateFilter ||
			variables.createdAt ||
			!(status === 'ALL' && !variables.status) ||
			!(finishedFilter === 'ALL' && variables.finished === undefined);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');

			if (trackIdFilter && trackIdFilter.length === 10) {
				setVariables((variables) => ({
					...variables,
					tracking: {
						trackId: trackIdFilter.trim(),
						estimatedDelivery: undefined,
					},
				}));
			} else if (!trackIdFilter) {
				setVariables((variables) => ({
					...variables,
					tracking: { trackId: undefined, estimatedDelivery: undefined },
				}));
			}

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
				console.log(moment(dateFilter).format('YYYY-MM-DD'));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (passengerNameFilter) {
				setVariables((variables) => ({
					...variables,
					passengerName: passengerNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerName: undefined }));
			}

			if (passengerPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					passengerPhoneNumber: passengerPhoneNumberFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerPhoneNumber: undefined }));
			}

			if (status === 'ALL') {
				setVariables((variables) => ({
					...variables,
					status: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					status: status.replace(/ /g, '_').toUpperCase(),
				}));
			}

			if (finishedFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					finished: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					finished: finishedFilter === 'FINISHED' ? true : false,
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			createdAtSort: -1,
			status: undefined,
			createdAt: undefined,
			passengerName: undefined,
			passengerPhoneNumber: undefined,
			finalPrice: undefined,
			finalPriceSort: undefined,
			tracking: { trackId: undefined, estimatedDelivery: undefined },
			finished: undefined,
			createdAtFrom: undefined,
		});
		setDateFilter('');
		// setPriceFilter('');
		setTrackIdFilter('');
		setPassengerNameFilter('');
		setPassengerPhoneNumberFilter('');
		setStatus('ALL');
		setDateFromFilter('');
		// setPriceSort('');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onFilterSubmit();
		}
	};

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onFilterSubmit} color={theme.primaryColor}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>
								{countData.getOrdersHistoryByShopAdminCount === 0 ? 0 : pageCount * perPage + 1}
							</span>{' '}
							to{' '}
							<span>
								{pageCount * perPage + Math.min(perPage, data.getOrdersHistoryByShopAdmin.length)}
							</span>{' '}
							of <span>{countData.getOrdersHistoryByShopAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0);
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={data.getOrdersHistoryByShopAdmin.map((order) => ({
					trackId: (
						<CopyToClipboard text={order.tracking.trackId}>
							<span
								title="Click To Copy To Clipboard"
								className={style.copyClipboard}
								style={{ color: order.color && '#f78902' }}
							>
								{order.tracking.trackId}
							</span>
						</CopyToClipboard>
					),
					passengerName: order.user && order.user.fullName,
					passengerPhoneNumber: order.user && order.user.phoneNumber,
					date: moment(order.createdAt).format('MM/DD/YYYY HH:mm'),
					status: order.status && (
						<div
							className={`${style.inlineTag} ${
								style[order.status.replace(/_/g, '').toLowerCase() + 'order' + 'Tag']
							}`}
						>
							{order.status.replace(/_/g, ' ').toLowerCase()}
						</div>
					),
					finished: order.finished ? (
						<div className={`${style.inlineTag} ${style.paidTag}`}>finished</div>
					) : (
						<div className={`${style.inlineTag} ${style.rejectedTag}`}>during</div>
					),
					shipmentModel: order.shipmentModel,
					finalPrice: (
						<div className={style.textAlignLeft}>
							<button onClick={() => onSelectOrderDetail(order)} className={style.detailIconDiv}>
								<span>i</span>
							</button>
							{'$' + order.finalPrice.toFixed(2)}
						</div>
					),
					products: (
						<div style={{ height: '80px', overflowY: 'auto', overflowX: 'hidden', paddingTop: '25px' }}>
							{' '}
							{order.cart.products.map((item) => (
								<div
									key={item.product._id}
									className={`${style.inlineTag} ${style[theme.primaryColor + 'Tag']}`}
								>
									{item.quantity}-{item.product.title}
								</div>
							))}
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Track ID</div>
								{trackIdFilter && trackIdFilterError && (
									<div className={style.dateErrorDiv}>must be 10 character</div>
								)}
								<CustomInput
									value={trackIdFilter}
									onChange={(e) => setTrackIdFilter(e.target.value)}
									// id="regular"
									green={props.type === 'grocery'}
									warning={props.type === 'restaurant'}
									error={trackIdFilter && trackIdFilterError ? true : false}
									inputProps={{
										placeholder: 'track ID ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'trackId',
						sortable: false,
						filterable: false,
						width: 220,
					},

					{
						Header: (
							<div>
								<div className={style.titleDiv}>Customer Name</div>
								<CustomInput
									value={passengerNameFilter}
									onChange={(e) => setPassengerNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Customer Phone No</div>
								<CustomInput
									value={passengerPhoneNumberFilter}
									onChange={(e) => setPassengerPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Date{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},

					{
						Header: (
							<div>
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor={theme.primaryColor}
										buttonText={status}
										onClick={(e) => setStatus(e)}
										dropdownList={statusOptions}
									/>
								</div>
							</div>
						),
						accessor: 'status',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Finished</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor={theme.primaryColor}
										buttonText={finishedFilter}
										onClick={(e) => setFinishedFilter(e)}
										dropdownList={finishedOptions}
									/>
								</div>
							</div>
						),
						accessor: 'finished',
						sortable: false,
						filterable: false,
					},

					{
						Header: (
							<div>
								<div
									className={style.titleDiv}
									style={{
										display: 'inline-block',
										width: '100%',
										textAlign: 'center',
										marginBottom: '79px',
									}}
								>
									Products
								</div>
							</div>
						),
						accessor: 'products',
						sortable: false,
						filterable: false,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getOrdersHistoryByShopAdminCount === 0
							? 1
							: Math.ceil(countData.getOrdersHistoryByShopAdminCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<OrderDetails
						type={themeContext}
						refetch={() => refetch()}
						close={() => setModal(false)}
						order={orderDetail}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default OrdersHistory;
