import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { endPoint } from 'utils/config';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import style from './AddAdmin.module.scss';

const GET_ROLES = gql`
	query roles($limit: Int, $skip: Int) {
		roles(limit: $limit, skip: $skip) {
			id
			name
			description
		}
	}
`;

const ADD_ADMIN = gql`
	mutation createAdminByAdmin(
		$fullName: String!
		$email: String!
		$phoneNumber: String!
		$password: String!
		$state: AdminState
		$type: String
		$shop: ID
		$roles: [ID!]!
	) {
		createAdminByAdmin(
			input: {
				fullName: $fullName
				email: $email
				phoneNumber: $phoneNumber
				password: $password
				state: $state
				type: $type
				shop: $shop
				roles: $roles
			}
		) {
			_id
			roles {
				name
			}
		}
	}
`;

const EDIT_ADMIN_ROLES = gql`
	mutation updateAdminByAdmin(
		$id: ID!
		$fullName: String
		$email: String
		$phoneNumber: String
		$state: AdminState
		$type: String
		$shop: ID
		$roles: [ID!]!
	) {
		updateAdminByAdmin(
			adminId: $id
			input: {
				fullName: $fullName
				email: $email
				phoneNumber: $phoneNumber
				state: $state
				type: $type
				shop: $shop
				roles: $roles
			}
		) {
			_id
		}
	}
`;

const GET_SHOPS = gql`
	query getShopsByAdmin {
		getShopsByAdmin {
			_id
			name
		}
	}
`;

const AddAdmin = ({ admin }) => {
	const typeOptions = ['SUPER-ADMIN', 'SHOP-ADMIN'];
	const stateOptions = ['ACTIVE', 'SUSPENDED'];
	const [name, setName] = useState(admin ? admin.fullName : '');
	const [phoneNumber, setPhoneNumber] = useState(admin ? admin.phoneNumber : '');
	const [email, setEmail] = useState(admin ? admin.email : '');
	const [password, setPassword] = useState(admin ? admin.password : '');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [visible, setVisible] = useState(false);
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [type, setType] = useState(admin ? admin.type : typeOptions[0]);
	const [state, setState] = useState(admin ? admin.state : stateOptions[0]);
	const [disableButton, setDisableButton] = useState(false);

	const [shopId, setShopId] = useState(admin && admin.shop ? admin.shop._id : '');
	const [roles, setRoles] = useState(
		admin && admin.roles.length > 0 ? Array.from(admin.roles, (item) => item.id) : []
	);

	const [shopIdError, setShopIdError] = useState(false);

	const [shopSelectOptions, setShopSelectOptions] = useState([]);

	const { loading: shopsLoading, error: shopsError, data: shopsData } = useQuery(GET_SHOPS);

	useEffect(() => {
		if (shopsData) {
			setShopSelectOptions(
				Array.from(shopsData.getShopsByAdmin, (shop) => ({
					name: shop.name + ' - ' + shop._id,
					value: shop._id,
				}))
			);
		}
	}, [, shopsData, shopsLoading]);

	const [nameError, setNameError] = useState(false);
	const [phoneNumberError, setPhoneNumberError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const { loading, error, data } = useQuery(GET_ROLES);

	const [createAdminByAdmin, { data: addData, error: addError }] = useMutation(ADD_ADMIN);

	const [updateAdminByAdmin, { data: updateData, error: updateError }] = useMutation(EDIT_ADMIN_ROLES);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	// block navigation
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [shouldLeavePage, setShouldLeavePage] = useState(false);

	useEffect(() => {
		if (shouldLeavePage) {
			window.onbeforeunload = undefined;
		} else {
			if (shouldBlockNavigation) {
				window.onbeforeunload = () => true;
			} else {
				window.onbeforeunload = undefined;
			}
		}
	}, [shouldBlockNavigation]);

	useEffect(() => {
		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	useEffect(() => {
		if (admin) {
			if (
				name === admin.fullName &&
				phoneNumber == admin.phoneNumber &&
				email === admin.email &&
				state === admin.state &&
				(admin.roles.length === roles.length &&
					JSON.stringify(Array.from(admin.roles, (item) => item.id)) === JSON.stringify(roles))
			) {
				setShouldBlockNavigation(false);
			} else {
				setShouldBlockNavigation(true);
			}
		} else {
			if (name || phoneNumber || email || password) {
				setShouldBlockNavigation(true);
			} else {
				setShouldBlockNavigation(false);
			}
		}
	});

	const onCancel = () => {
		window.onbeforeunload = undefined;
		setShouldBlockNavigation(false);
		setShouldLeavePage(true);
		window.location.href = endPoint + '/superAdmin/admins';
	};

	const onSubmit = () => {
		if (!admin) {
			if (!name) {
				setNameError(true);
			} else {
				setNameError(false);
			}
			if (!email) {
				setEmailError(true);
			} else {
				setEmailError(false);
			}
			if (!phoneNumber) {
				setPhoneNumberError(true);
			} else {
				setPhoneNumberError(false);
			}
			if (!password) {
				setPasswordError(true);
			} else {
				setPasswordError(false);
			}
			if (!confirmPassword) {
				setConfirmPasswordErrorText("confirm can't be empty");
			} else {
				if (password && password === confirmPassword) {
					setConfirmPasswordErrorText('');
				} else {
					setConfirmPasswordErrorText("password and confirm aren't equal");
				}
			}

			if (name && email && phoneNumber && password && confirmPassword && password === confirmPassword) {
				setDisableButton(true);
				window.onbeforeunload = undefined;
				setShouldBlockNavigation(false);
				setShouldLeavePage(true);
				createAdminByAdmin({
					variables: {
						fullName: name.trim(),
						email: email.trim(),

						phoneNumber: phoneNumber.trim(),
						password: password.trim(),
						roles,
						state,
						shop: shopId ? shopId : undefined,
					},
				})
					.then((res) => {
						console.log(res);
						window.location.href = endPoint + '/superAdmin/admins';
					})
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}
		} else if (admin) {
			setDisableButton(true);
			window.onbeforeunload = undefined;
			setShouldBlockNavigation(false);
			setShouldLeavePage(true);
			updateAdminByAdmin({
				variables: {
					id: admin._id,
					fullName: name.trim(),
					email: email.trim(),
					type,
					phoneNumber: phoneNumber.trim(),
					roles,
					state,
					shop: shopId ? shopId : undefined,
				},
			})
				.then((res) => {
					console.log(res);
					window.location.href = endPoint + '/superAdmin/admins';
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDiv}>
			<Prompt when={shouldBlockNavigation} message="You have unsaved changes, are you sure you want to leave?" />
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.label}>State:</div>
								</GridItem>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="gray"
											buttonText={state}
											onClick={(e) => setState(e)}
											dropdownList={stateOptions}
											disabled
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>

						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Full name:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={name}
										onChange={(e) => setName(e.target.value)}
										error={nameError}
										labelText={nameError ? "name can't be empty*" : 'full name'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Phone number:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={phoneNumber}
										onChange={(e) => setPhoneNumber(e.target.value)}
										error={phoneNumberError}
										labelText={phoneNumberError ? "phone number can't be empty*" : 'phone number'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Email:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										error={emailError}
										labelText={emailError ? "email can't be empty*" : 'email'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						{!admin && (
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Password:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											error={passwordError}
											labelText={passwordError ? "password can't be empty*" : 'password'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												type: visible ? 'text' : 'password',
												endAdornment: visible ? (
													<VisibilityIcon
														className={style.icon}
														onClick={() => setVisible((visible) => !visible)}
														style={{ fontSize: '18px', color: 'gray' }}
														position="end"
													></VisibilityIcon>
												) : (
													<VisibilityOffIcon
														className={style.icon}
														onClick={() => setVisible((visible) => !visible)}
														style={{ fontSize: '18px', color: 'gray' }}
														position="end"
													></VisibilityOffIcon>
												),
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{!admin && (
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Confirm password:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={confirmPassword}
											onChange={(e) => setConfirmPassword(e.target.value)}
											error={confirmPasswordErrorText}
											labelText={confirmPasswordErrorText ? confirmPasswordErrorText : 'password'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												type: confirmVisible ? 'text' : 'password',
												endAdornment: confirmVisible ? (
													<VisibilityIcon
														className={style.icon}
														onClick={() => setConfirmVisible((visible) => !visible)}
														style={{ fontSize: '18px', color: 'gray' }}
														position="end"
													></VisibilityIcon>
												) : (
													<VisibilityOffIcon
														className={style.icon}
														onClick={() => setConfirmVisible((visible) => !visible)}
														style={{ fontSize: '18px', color: 'gray' }}
														position="end"
													></VisibilityOffIcon>
												),
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						)}
						<div className={style.roleRow}>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<div className={style.roleTitle}>Roles:</div>
								</GridItem>
								<GridItem xs={12} sm={12} md={12}>
									{data &&
										data.roles.map((role) => (
											<div className={style.roleContainer}>
												<GridContainer>
													<GridItem xs={10} sm={10} md={10} lg={10}>
														<div className={style.roleSubtitle}>{role.name}</div>
														<div className={style.roleDescription}>{role.description}</div>
													</GridItem>
													<GridItem xs={2} sm={2} md={2} lg={2}>
														<FormControlLabel
															control={
																<Checkbox
																	color="gray"
																	name={role.name}
																	checked={roles.includes(role.id)}
																	onChange={() => {
																		if (roles.includes(role.id)) {
																			setRoles(
																				roles.filter((id) => id !== role.id)
																			);
																		} else {
																			setRoles([...roles, role.id]);
																		}
																	}}
																/>
															}
														/>
													</GridItem>
												</GridContainer>
											</div>
										))}
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.buttonContainer}>
				<Button color="whiteButton" onClick={onCancel}>
					Cancel
				</Button>

				<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
					Submit
				</Button>
			</div>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default AddAdmin;
