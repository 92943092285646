import React from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import EditShop from "shopAdminViews/editShop/EditShop";
import style from "./ShopAddShop.module.scss";

const ShopEditShop = (props) => {
  return (
    <div className={style.mainDiv}>
      <GridContainer justify="center">
        <GridItem xs={10} sm={10} md={9} lg={7}>
          <div className={style.whiteCard}>
            <div className={style.header}>
              <div className={style.headerSentence}>Edit shop</div>
            </div>
            <EditShop editAfterRejection={true} theme="superAdmin" type="shopAdmin" />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ShopEditShop;