import React, { useState, useEffect } from 'react';
import Close from '@material-ui/icons/Close';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Files from 'react-butterfiles';
import { graphqlUrl } from 'utils/config';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import Modal from 'commonComponents/modal/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import style from 'superAdminViews/setting/defaults/EditItem.module.scss';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!, $folderName: String!) {
		uploadFile(data: { file: $file, folderName: $folderName }) {
			url
		}
	}
`;

const GET_CATEGORIES = gql`
	query getCategories($parent: ID) {
		getCategories(filters: { parent: $parent }) {
			_id
			title
			photoUrl
		}
	}
`;

const GET_ATTRIBUTE_GROUPS = gql`
	query getAttributeGroupsByAdmin($limit: Int, $skip: Int) {
		getAttributeGroupsByAdmin(pagination: { limit: $limit, skip: $skip }) {
			_id
			name
			attributes {
				_id
				name
			}
			rootCategory {
				_id
				title
			}
		}
	}
`;

const UPDATE_CATEGORY = gql`
	mutation updateCategoryByAdmin($_id: ID!, $parent: ID, $title: String, $photoUrl: String) {
		updateCategoryByAdmin(_id: $_id, data: { parent: $parent, photoUrl: $photoUrl, title: $title }) {
			_id
			title
		}
	}
`;

const UPDATE_ATTRIBUTE_GROUP = gql`
	mutation updateAttributeGroupByAdmin($_id: ID!, $rootCategory: ID!, $name: String!) {
		updateAttributeGroupByAdmin(_id: $_id, input: { rootCategory: $rootCategory, name: $name }) {
			_id
			name
			rootCategory {
				_id
				title
			}
		}
	}
`;

const UPDATE_ATTRIBUTE = gql`
	mutation updateAttributeByAdmin($id: ID!, $attributeGroup: ID!, $name: String!) {
		updateAttributeByAdmin(id: $id, inputs: { attributeGroup: $attributeGroup, name: $name }) {
			_id
			name
		}
	}
`;

const EditProductOption = ({ type, item, refetch, close }) => {
	const { loading: categoriesLoading, error: categoriesError, data: categoriesData } = useQuery(GET_CATEGORIES);

	const { loading: rootCategoriesLoading, error: rootCategoriesError, data: rootCategoriesData } = useQuery(
		GET_CATEGORIES,
		{
			variables: {
				parent: null,
			},
		}
	);

	const { loading: attributeGroupsLoading, error: attributeGroupsError, data: attributeGroupsData } = useQuery(
		GET_ATTRIBUTE_GROUPS,
		{
			variables: {
				limit: 50,
				skip: 0,
			},
		}
	);

	const [name, setName] = useState(type === 'category' ? item.title : item.name);
	const [parentId, setParentId] = useState(type === 'category' && item.parent && item.parent._id);
	const [parentName, setParentName] = useState(
		type === 'category' && (item.parent !== null ? item.parent.title : 'root category')
	);
	const [categoryId, setCategoryId] = useState(
		type === 'attributeGroup' && item.rootCategory && item.rootCategory._id
	);
	const [categoryName, setCategoryName] = useState(
		type === 'attributeGroup' && item.rootCategory && item.rootCategory.title
	);

	const [attributeGroupId, setAttributeGroupId] = useState(
		type === 'attribute' && item.attributeGroup && item.attributeGroup._id
	);
	const [attributeGroupName, setAttributeGroupName] = useState(
		type === 'attribute' && item.attributeGroup && item.attributeGroup.name
	);

	const [image, setImage] = useState(type === 'category' && item.photoUrl ? item.photoUrl : null);
	const [imageError, setImageError] = useState(false);

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [uploadFile, { data: uploadData, error: uploadError, loading: uploadLoading }] = useMutation(UPLOAD_FILE);

	const uploadImage = (file) => {
		uploadFile({
			variables: { file: file.src.file, folderName: '' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			setImage(res.data.uploadFile.url);
		});
	};

	useEffect(() => {
		if (rootCategoriesData && type === 'category') {
			if (parentName === 'root category' || item.parent === null) {
				setParentId(null);
			} else {
				setParentId(rootCategoriesData.getCategories.find((category) => category.title === parentName)._id);
			}
		}
	}, [, parentName]);

	useEffect(() => {
		if (categoriesData && type === 'attributeGroup') {
			setCategoryId(categoriesData.getCategories.find((category) => category.title === categoryName)._id);
		}
	}, [, categoryName]);

	useEffect(() => {
		if (attributeGroupsData && type === 'attribute') {
			setAttributeGroupId(
				attributeGroupsData.getAttributeGroupsByAdmin.find(
					(attributeGroup) => attributeGroup.name === attributeGroupName
				)._id
			);
		}
	}, [, attributeGroupName]);

	const [nameError, setNameError] = useState(false);

	const [
		updateAttributeGroupByAdmin,
		{ data: updateAttributeGroupData, error: updateAttributeGroupError },
	] = useMutation(UPDATE_ATTRIBUTE_GROUP);

	const [updateAttributeByAdmin, { data: updateAttributeData, error: updateAttributeError }] = useMutation(
		UPDATE_ATTRIBUTE
	);

	const [updateCategoryByAdmin, { data: updateCategoryData, error: updateCategoryError }] = useMutation(
		UPDATE_CATEGORY
	);

	const onChange = () => {
		if (!name) {
			setNameError(true);
		} else {
			setNameError(false);
		}
		if (type === 'category' && !image) {
			setImageError(true);
		} else {
			setImageError(false);
		}
		if (name && type === 'attributeGroup') {
			updateAttributeGroupByAdmin({
				variables: {
					_id: item._id,
					name: name.trim(),
					rootCategory: categoryId,
				},
			})
				.then((res) => {
					refetch();
					setName('');
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (name && type === 'attribute') {
			updateAttributeByAdmin({
				variables: {
					id: item._id,
					name: name.trim(),
					attributeGroup: attributeGroupId,
				},
			})
				.then((res) => {
					refetch();
					setName('');
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (name && type === 'category' && image) {
			updateCategoryByAdmin({
				variables: {
					_id: item._id,
					title: name.trim(),
					parent: parentId,
					photoUrl: image,
				},
			})
				.then((res) => {
					refetch();
					setName('');
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	if (rootCategoriesLoading || categoriesLoading || attributeGroupsLoading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (rootCategoriesError || categoriesError || attributeGroupsError) {
		// console.log(error);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Edit</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					{type === 'category' && rootCategoriesData && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={3} md={3} lg={3}>
										<div className={style.label}>Parent categoty: </div>
									</GridItem>
									<GridItem xs={12} sm={9} md={9} lg={9}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={parentName}
												onClick={(e) => setParentName(e)}
												dropdownList={
													rootCategoriesData &&
													Array.from(
														rootCategoriesData.getCategories,
														(category) => category.title
													)
												}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'attributeGroup' && categoriesData && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={3} md={3} lg={3}>
										<div className={style.label}>Categoty: </div>
									</GridItem>
									<GridItem xs={12} sm={9} md={9} lg={9}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={categoryName}
												onClick={(e) => setCategoryName(e)}
												dropdownList={
													categoriesData &&
													Array.from(
														categoriesData.getCategories,
														(category) => category.title
													)
												}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{attributeGroupsData && type === 'attribute' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={3} md={3} lg={3}>
										<div className={style.label}>Atribute group: </div>
									</GridItem>
									<GridItem xs={12} sm={9} md={9} lg={9}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={attributeGroupName}
												onClick={(e) => setAttributeGroupName(e)}
												dropdownList={
													attributeGroupsData &&
													Array.from(
														attributeGroupsData.getAttributeGroupsByAdmin,
														(category) => category.name
													)
												}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'category' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div style={{ marginTop: '35px' }} className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.inputLabel}>Photo:</div>
										<div className={style.imageHint}>(upload an image less than 10MB)</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.imageContainer}>
											<Files
												maxSize="10mb"
												multiple={false}
												convertToBase64={true}
												accept={['image/jpg', 'image/jpeg', 'image/png']}
												onSuccess={(file) => uploadImage(file[0])}
											>
												{({ browseFiles, getDropZoneProps, getLabelProps }) => (
													<div style={{ textAlign: 'right' }}>
														{image && (
															<div>
																<img
																	style={{ width: '200px', height: '200px' }}
																	alt="advertisement photo"
																	src={`${graphqlUrl}${image}`}
																/>
															</div>
														)}
														{uploadLoading && (
															<CircularProgress style={{ color: 'gray' }} />
														)}
														<div
															{...getDropZoneProps({
																className: 'myDropZone',
															})}
														/>
														<Button
															style={{
																marginTop: '15px',
																marginRight: '10px',
															}}
															color="whiteButton"
															onClick={browseFiles}
														>
															Select Photo...
														</Button>
														{imageError && (
															<p
																style={{
																	color: 'red',
																	fontsize: '9px',
																}}
															>
																please enter image file less than 10MB size
															</p>
														)}
													</div>
												)}
											</Files>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={5} md={5} lg={5}>
									<div className={style.inputLabel}>{type === 'category' ? 'Title' : 'Name'}</div>
								</GridItem>
								<GridItem xs={12} sm={7} md={7} lg={7}>
									<div className={style.dropdownContainer}>
										<CustomInput
											value={name}
											onChange={(e) => setName(e.target.value)}
											error={nameError}
											labelText={nameError ? "name can't be empty*" : 'name'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					{(type !== 'category' || (type === 'category' && item.parent !== null)) && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.buttonsContainer}>
								<Button color="whiteButton" onClick={() => close()}>
									Cancel
								</Button>
								<Button disabled={uploadLoading} color="yellow" onClick={onChange}>
									Submit
								</Button>
							</div>
						</GridItem>
					)}
				</GridContainer>
			</div>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default EditProductOption;
