import React, { useState, useEffect } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from 'react-google-maps';
import Geocode from 'react-geocode';
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const GET_LOCATION = gql`
	query getLocationByAddress($address: String!) {
		getLocationByAddress(address: $address) {
			lat
			long
		}
	}
`;

Geocode.setApiKey('AIzaSyDEWkX2jIiINMBNLYS8aFNTuan--QsvwYk');
Geocode.enableDebug();

const AsyncMap = withScriptjs(
	withGoogleMap((props) => (
		<GoogleMap
			google={props.google}
			onClick={props.onClick}
			defaultZoom={15}
			defaultCenter={{
				lat: props.position.lat,
				lng: props.position.lng,
			}}
			center={{
				lat: props.position.lat,
				lng: props.position.lng,
			}}
		>
			<Marker
				google={props.google}
				name={'Dolores park'}
				position={{
					lat: props.position.lat,
					lng: props.position.lng,
				}}
			/>
			<Marker />
			<div style={{ height: '30px' }}>
				<input
					onKeyPress={(e) => props.onKeyPress(e)}
					style={{ height: '100%', width: '70%', border: '2px solid gold', borderRadius: '2px' }}
					value={props.searchWord}
					placeholder="search location..."
					onChange={(e) => props.setSearchWord(e.target.value)}
				/>
				<button
					style={{
						height: '100%',
						width: '30%',
						color: 'white',
						backgroundColor: 'gray',
						border: 'none',
						cursor: 'pointer',
					}}
					onClick={props.onFindLocation}
				>
					Find location
				</button>
			</div>
		</GoogleMap>
	))
);

const FunMap = (props) => {
	const [position, setPosition] = useState({
		lat: props.position.lat,
		lng: props.position.lng,
	});

	const [searchWord, setSearchWord] = useState('');

	const [getLocationByAddress, { loading: locationLoading, error: locationError, data: locationData }] = useLazyQuery(
		GET_LOCATION
	);

	useEffect(() => {
		if (locationData) {
			setPosition({
				lat: locationData.getLocationByAddress.lat,
				lng: locationData.getLocationByAddress.long,
			});
			Geocode.fromLatLng(locationData.getLocationByAddress.lat, locationData.getLocationByAddress.long).then(
				(response) => {
					const address = response.results[0].formatted_address;
					const addressArray = response.results[0].address_components;
					const city = getCity(addressArray);
					const province = getProvince(addressArray);
					const street = getStreet(addressArray);
					const postalCode = getPostalCode(addressArray);
					// console.log('address is: ', address);
					if (city && city !== props.city) {
						props.setCity(city);
					}
					// console.log(city);
					props.setStreet(street);
					props.setProvince(province);
					props.setPostalCode(postalCode);
					props.setAddress(address);
				},
				(error) => {
					console.error(error);
				}
			);
		}
		
	}, [locationData]);

	const onFindLocation = () => {
		if (searchWord) {
			getLocationByAddress({ variables: { address: searchWord } });
		}
	};

	const onKeyPress = (e) => {
		if (e.charCode === 13) {
			onFindLocation();
		}
	};

	useEffect(() => {
		if (position.lat !== props.position.lat) {
			setPosition({ lat: props.position.lat, lng: props.position.lng });
		}
	}, [props.position]);

	// useEffect(() => {
	// 	Geocode.fromLatLng(props.position.lat, props.position.lng).then(
	// 		(response) => {
	// 			const address = response.results[0].formatted_address;
	// 			const addressArray = response.results[0].address_components;
	// 			const city = getCity(addressArray);
	// 			const province = getProvince(addressArray);
	// 			const street = getStreet(addressArray);
	// 			const postalCode = getPostalCode(addressArray);
	// 			if (city && city !== props.city) {
	// 				props.setCity(city);
	// 				console.log(city)
	// 			}
	// 			if (street) {
	// 				props.setStreet(street);
	// 			}
	// 			if (province) {
	// 				props.setProvince(province);
	// 			}
	// 			if (postalCode) {
	// 				props.setPostalCode(postalCode);
	// 			}
	// 			if (address) {
	// 				props.setAddress(address);
	// 			}
	// 		},
	// 		(error) => {
	// 			console.error(error);
	// 		}
	// 	);
	// }, [ props.position]);

	const onClickMap = (event) => {
		let newLat = event.latLng.lat(),
			newLng = event.latLng.lng(),
			addressArray = [];

		setPosition({ lat: newLat, lng: newLng });
		// props.onSetmarkerPosition(newLat, newLng);

		Geocode.fromLatLng(newLat, newLng).then(
			(response) => {
				const address = response.results[0].formatted_address;
				const addressArray = response.results[0].address_components;
				const city = getCity(addressArray);
				const province = getProvince(addressArray);
				const street = getStreet(addressArray);
				const postalCode = getPostalCode(addressArray);
				if (city && city !== props.city) {
					props.setCity(city);
				}
				props.setStreet(street);
				props.setProvince(province);
				props.setPostalCode(postalCode);
				props.setAddress(address);
			},
			(error) => {
				console.error(error);
			}
		);
	};

	useEffect(() => {
		props.onSetmarkerPosition(position.lat, position.lng);
	}, [position]);

	const getProvince = (addressArray) => {
		let province = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
				province = addressArray[i].long_name;
				return province;
			}
		}
	};
	const getCity = (addressArray) => {
		let city = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				(addressArray[i].types[0] === 'postal_town' || addressArray[i].types[0] === 'locality')
			) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	const getStreet = (addressArray) => {
		let street = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0] && 'route' === addressArray[i].types[0]) {
				street = addressArray[i].long_name;
				return street;
			}
		}
	};

	const getPostalCode = (addressArray) => {
		let postalCode = '';
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
				postalCode = addressArray[i].long_name;
				return postalCode;
			}
		}
	};

	// useEffect(() => {

	// }, [position]);

	return (
		<div>
			<AsyncMap
				position={position}
				onClick={onClickMap}
				searchWord={searchWord}
				onFindLocation={onFindLocation}
				onKeyPress={onKeyPress}
				setSearchWord={(word) => setSearchWord(word)}
				googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDEWkX2jIiINMBNLYS8aFNTuan--QsvwYk&libraries=places"
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div style={{ height: '300px' }} />}
				mapElement={<div style={{ height: `100%` }} />}
			/>
		</div>
	);
};

export default FunMap;
