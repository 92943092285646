import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ErrorPage from 'commonComponents/errorPage/ErrorPage'
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import EditUserForm from './EditUserForm'

const GET_USER = gql`
  query getUserByAdmin($id: ID!) {
    getUserByAdmin(id: $id) {
      _id
      fullName
      email
      phoneNumber
      profileImageUrl
      birthDate
      gender
      state
      isVerified
      emailVerified
      phoneNumberVerified
      addresses{
          full 
          zipCode
      }
      defaultAddress{
          full 
          zipCode
      }
    }
  }
`;

const EditUser = (props) => {
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id: props.match.params.id },
  });
  if(error){
      console.log(error)
  }

  
  if (loading)
  return (
    <div style={{ textAlign: "center", marginTop: "200px" }}>
      <BeatLoader
        css={css`
          display: block;
          margin: 0 auto;
          border-color: red;
        `}
        size={15}
        margin={2}
        color={"gray"}
        loading={true}
      />
    </div>
  );
if (error) {
  console.log(error);
  return <ErrorPage/>;
}
  return <EditUserForm user={data.getUserByAdmin}/>;
};

export default EditUser;
