import React, { useRef, useEffect } from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import PermissioRoute from "utils/PermissionRoute";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import FailedPayments from "superAdminViews/finance/payments/Payments";
import EditPromotion from "superAdminViews/ride/addPromotion/EditPromotion";
import EditShopPromotion from "superAdminViews/restaurant/promotions/EditShopPromotion";
import EditUser from "superAdminViews/users/EditUser";
import EditAdmin from "superAdminViews/admins/addAdmin/EditAdmin";
import AddPromotion from "superAdminViews/ride/addPromotion/AddPromotion";
import EditDriver from "superAdminViews/ride/drivers/EditDriver";
import AddAdmin from "superAdminViews/admins/addAdmin/AddAdmin";
import AddAdvertisement from "superAdminViews/advertisements/addAdvertisement/AddAdvertisement";
import SignupDriver from "superAdminViews/ride/addDriver/SignupDriver";
import ShopVerifications from "superAdminViews/restaurant/verifications/ShopVerifications";
import AddDriver from "superAdminViews/ride/addDriver/AddDriver";
import Verifications from "superAdminViews/ride/verifications/Verifications";
import SuperAddShop from "superAdminViews/restaurant/addShop/SuperAddShop";
import AddCar from "superAdminViews/ride/addCar/AddCar";
import EditCar from "superAdminViews/ride/addCar/EditCar";
import VerificationDetails from "superAdminViews/ride/verifications/VerificationDetails";
import ShopVerificationDetails from "superAdminViews/restaurant/verifications/ShopVerificationDetails";
import EditAdvertisement from "superAdminViews/advertisements/addAdvertisement/EditAdvertisement";
import TripHistory from "superAdminViews/ride/trips/Trips";
import ShopPage from "superAdminViews/restaurant/shopPage/ShopPage";
import sparkLogo from "assets/img/sparkLogo.svg";

var ps;

const useStyles = makeStyles(styles);

export default function ShopAdmin(props) {
  // change title of the page in browser tab
  useEffect(() => {
    let routeName = window.location.pathname.split("/")[2];

    let titleName = routeName
      ? routeName
          .replace(/([A-Z])/g, " $1")
          .replace(/_/g, " ")
          .toLowerCase()
      : "";
    let titleFinalName = titleName.charAt(0).toUpperCase() + titleName.slice(1);
    document.title = titleFinalName ? `Spark - ${titleFinalName}` : "Spark";
  }, [, window.location.pathname.split("/")[2]]);

  const scrollRef = useRef(null);
  useEffect(() => {
    scrollRef.current.scrollIntoView();
  }, [window.location.pathname]);
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/logo-white.svg"));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  // React.useEffect(() => {
  //   // if (navigator.platform.indexOf("Win") > -1) {
  //   //   ps = new PerfectScrollbar(mainPanel.current, {
  //   //     suppressScrollX: true,
  //   //     suppressScrollY: false,
  //   //   });
  //   //   document.body.style.overflow = "hidden";
  //   // }
  //   window.addEventListener("resize", resizeFunction);

  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  // });
  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (
        prop.layout === "/superAdmin" &&
        (!prop.permissionName ||
          (prop.permissionName &&
            JSON.parse(window.localStorage.getItem("permissions")).includes(
              prop.permissionName
            )))
      ) {
        return (
          <Route
            path={prop.layout + prop.path}
            // exact={true}
            render={() => (
              <prop.component type={props.type} key={Math.random()} />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  let routesArray = [];

  const getRoutesForSideBar = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse && prop.layout === "/superAdmin") {
        let newRoute = prop.views;
        let finalRoutes = newRoute.filter(
          (route) =>
            !route.permissionName ||
            (route.permissionName &&
              JSON.parse(window.localStorage.getItem("permissions")).includes(
                route.permissionName
              ))
        );
        routesArray.push({ ...prop, views: finalRoutes });
        return getRoutes(prop.views);
      } else if (
        prop.layout === "/superAdmin" &&
        (!prop.permissionName ||
          (prop.permissionName &&
            JSON.parse(window.localStorage.getItem("permissions")).includes(
              prop.permissionName
            )))
      ) {
        routesArray.push(prop);
      } else {
        return null;
      }
    });
  };

  getRoutesForSideBar(routes);

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routesArray}
        logoText={"Spark"}
        logo={sparkLogo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="white"
        bgColor={bgColor}
        type="superAdmin"
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <div ref={scrollRef}></div>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={window.location.pathname.split("/")[2]}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Route
                  path="/superAdmin/addAdmin"
                  render={() => <AddAdmin key={Math.random()} />}
                />
                <Route
                  path="/superAdmin/addAdvertisement"
                  render={() => <AddAdvertisement key={Math.random()} />}
                />
                <Route
                  path="/superAdmin/shopVerifications"
                  render={() => <ShopVerifications key={Math.random()} />}
                />
                <Route
                  path="/superAdmin/addShop"
                  render={() => <SuperAddShop key={Math.random()} />}
                />
                <Route
                  path="/superAdmin/addPromotion"
                  render={() => <AddPromotion key={Math.random()} />}
                />
                <Route
                  path="/superAdmin/signupDriver"
                  render={() => <SignupDriver key={Math.random()} />}
                />
                <Route
                  path="/superAdmin/addDriver"
                  render={() => <AddDriver key={Math.random()} />}
                />
                <Route
                  path="/superAdmin/verifications"
                  render={() => <Verifications key={Math.random()} />}
                />
                <Route
                  path="/superAdmin/addVehicle"
                  exact={true}
                  render={() => <AddCar />}
                />

                <Route
                  path="/superAdmin/failedPayments"
                  render={(props) => (
                    <FailedPayments failed={true} {...props} />
                  )}
                />
                <Route
                  path="/superAdmin/editPromotion/:id"
                  component={EditPromotion}
                />
                <Route
                  path="/superAdmin/editShopPromotion/:id"
                  component={EditShopPromotion}
                />
                <Route path="/superAdmin/editUser/:id" component={EditUser} />
                <Route path="/superAdmin/editAdmin/:id" component={EditAdmin} />
                <Route
                  path="/superAdmin/editDriver/:id"
                  component={EditDriver}
                />
                <Route
                  path="/superAdmin/editVehicle/:carId"
                  render={(props) => (
                    <EditCar carId={props.match.params.carId} />
                  )}
                />
                <Route
                  path="/superAdmin/editAdvertisement/:id"
                  component={EditAdvertisement}
                />
                <Route
                  path="/superAdmin/verificationDetails/:id"
                  component={VerificationDetails}
                />
                <Route
                  path="/superAdmin/tripHistory/:driverName/:driverPhoneNumber"
                  render={(props) => (
                    <TripHistory
                      driverName={props.match.params.driverName}
                      driverPhoneNumber={props.match.params.driverPhoneNumber}
                      history={true}
                    />
                  )}
                />
                <Route
                  path="/superAdmin/shopVerificationDetails/:id"
                  component={ShopVerificationDetails}
                />
                <Route path="/superAdmin/:shopName/:id" component={ShopPage} />
                <Redirect from="/superAdmin" to="/superAdmin/dashboard" />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              <Route
                path="/superAdmin/addAdmin"
                render={() => <AddAdmin key={Math.random()} />}
              />
              <Route
                path="/superAdmin/addAdvertisement"
                render={() => <AddAdvertisement key={Math.random()} />}
              />
              <Route
                path="/superAdmin/shopVerifications"
                render={() => <ShopVerifications key={Math.random()} />}
              />
              <Route
                path="/superAdmin/addShop"
                render={() => <SuperAddShop key={Math.random()} />}
              />
              <Route
                path="/superAdmin/addPromotion"
                render={() => <AddPromotion key={Math.random()} />}
              />
              <Route
                path="/superAdmin/signupDriver"
                render={() => <SignupDriver key={Math.random()} />}
              />
              <Route
                path="/superAdmin/addDriver"
                render={() => <AddDriver key={Math.random()} />}
              />
              <Route
                path="/superAdmin/verifications"
                render={() => <Verifications key={Math.random()} />}
              />
              <Route
                path="/superAdmin/addVehicle"
                exact={true}
                render={() => <AddCar />}
              />
              <Route
                path="/superAdmin/failedPayments"
                render={(props) => <FailedPayments failed={true} {...props} />}
              />
              <Route
                path="/superAdmin/editPromotion/:id"
                component={EditPromotion}
              />
              <Route
                path="/superAdmin/editShopPromotion/:id"
                component={EditShopPromotion}
              />
              <Route path="/superAdmin/editUser/:id" component={EditUser} />
              <Route path="/superAdmin/editAdmin/:id" component={EditAdmin} />
              <Route path="/superAdmin/editDriver/:id" component={EditDriver} />
              <Route
                path="/superAdmin/editVehicle/:carId"
                render={(props) => <EditCar carId={props.match.params.carId} />}
              />
              <Route
                path="/superAdmin/editAdvertisement/:id"
                component={EditAdvertisement}
              />
              <Route
                path="/superAdmin/verificationDetails/:id"
                component={VerificationDetails}
              />
              <Route
                path="/superAdmin/tripHistory/:driverName/:driverPhoneNumber"
                render={(props) => (
                  <TripHistory
                    driverName={props.match.params.driverName}
                    driverPhoneNumber={props.match.params.driverPhoneNumber}
                    history={true}
                  />
                )}
              />
              <Route
                path="/superAdmin/shopVerificationDetails/:id"
                component={ShopVerificationDetails}
              />
              <Route path="/superAdmin/:shopName/:id" component={ShopPage} />
              <Redirect from="/superAdmin" to="/superAdmin/dashboard" />
            </Switch>
          </div>
        )}
      </div>
    </div>
  );
}
