import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MoonLoader from 'react-spinners/MoonLoader';
import Files from 'react-butterfiles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReactTable from 'react-table';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import Checkbox from '@material-ui/core/Checkbox';
import { css } from '@emotion/core';
import Pagination from 'commonComponents/pagination/Pagination';
import BeatLoader from 'react-spinners/BeatLoader';
import { SketchPicker } from 'react-color';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Modal from 'commonComponents/modal/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import EditBrandAndModel from 'superAdminViews/setting/carOptions/EditBrandAndModel';
import style from 'superAdminViews/setting/defaults/DriverReadyComments.module.scss';

const ADD_COLOR_VIA_EXCEL = gql`
	mutation addCarColorViaExcel($excelFile: Upload!) {
		addCarColorViaExcel(excelFile: $excelFile)
	}
`;

const ADD_COLOR = gql`
	mutation createCarColorByAdmin($name: String!, $code: String!) {
		createCarColorByAdmin(input: { name: $name, code: $code }) {
			_id
			code
			name
		}
	}
`;

const GET_COLORS = gql`
	query getCarColors($limit: Int, $skip: Int) {
		getCarColors(pagination: { limit: $limit, skip: $skip }) {
			_id
			code
			name
		}
	}
`;

const GET_COLORS_COUNT = gql`
	query getCarColorsCount {
		getCarColorsCount
	}
`;

const DELETE_COLOR = gql`
	mutation deleteCarColorByAdmin($idSet: [ID!]!) {
		deleteCarColorByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const Colors = (props) => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
	});

	const [name, setName] = useState('');
	const [code, setCode] = useState('#FFFFFF');

	const [nameError, setNameError] = useState(false);
	const [codeError, setCodeError] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const [displayColorPicker, setDisplayColorPicker] = useState(false);

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);

	const [errorText, setErrorText] = useState('');
	const [errorModal, setErrorModal] = useState(false);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [colorForDelete, setColorForDelete] = useState();

	const [addModal, setAddModal] = useState(false);
	const [modal, setModal] = useState(false);

	const [colorDetails, setColorDetails] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const { loading, error, data, refetch } = useQuery(GET_COLORS, {
		variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData, refetch: refetchCount } = useQuery(
		GET_COLORS_COUNT,
		{
			variables,
			fetchPolicy: 'network-only',
			notifyOnNetworkStatusChange: true,
		}
	);

	const [
		addCarColorViaExcel,
		{ data: addViaExcelData, error: addViaExcelError, loading: addViaExcelLoading },
	] = useMutation(ADD_COLOR_VIA_EXCEL);

	const [showMultipleAdd, setShowMultipleAdd] = useState(false);
	const [disableMultiButton, setDisableMultiButton] = useState(false);
	const [excelFile, setExcelFile] = useState('');
	const [excelFileError, setExcelFileError] = useState(false);

	const arrayForTemplateExcel = [['Name', 'code'], ['Black', '#000000'], ['Red', '#FF0000'], ['Yellow', '#FFFF00']];

	const onDownloadTemplate = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(arrayForTemplateExcel);
		XLSX.utils.book_append_sheet(wb, ws, 'Template');
		XLSX.writeFile(wb, 'Spark-Colors-Template.xlsx');
	};

	const uploadExcelFile = (file) => {
		setExcelFile(file.src.file);
	};

	const onAddMultiple = () => {
		setDisableMultiButton(true);
		addCarColorViaExcel({
			variables: { excelFile: excelFile },
		})
			.then((res) => {
				console.log(res);
				setShowExcelButton('firstStatus');
				setShowMultipleAdd(false);
				setExcelFile('');
				refetch();
				refetchCount();
			})
			.catch((err) => {
				setDisableMultiButton(false);
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setErrorText(err.graphQLErrors[0].message);
					setErrorModal(true);
				}
			});
	};

	const onSelectColorEdit = (color) => {
		setModal(true);
		setColorDetails(color);
	};

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getCarColors.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	// testing menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getCarColorsCount / perPage) - 1 && countData.getCarColorsCount !== 0) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getCarColorsCount !== 0) {
			setPageCount(Math.ceil(countData.getCarColorsCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	const excelHeader = [['name', 'code']];
	const [excelData, setExcelData] = useState(excelHeader);

	const [getCarColors, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_COLORS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (countData && excelData.length === countData.getCarColorsCount + 1) {
			setShowExcelButton('done');
		} else {
			setShowExcelButton('firstStatus');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([...excelHeader, ...Array.from(lazyData.getCarColors, (item) => [item.name, item.code])]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Cars.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getCarColors({ variables: { skip: 0, limit: countData.getCarColorsCount } });
		}
	};

	const onDeleteColor = (color) => {
		setDeleteModal(true);
		setColorForDelete([color._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const [createCarColorByAdmin, { data: addMutationData, error: addMutationError }] = useMutation(ADD_COLOR);

	const [deleteCarColorByAdmin] = useMutation(DELETE_COLOR);

	const deleteColor = () => {
		deleteCarColorByAdmin({ variables: { idSet: colorForDelete } })
			.then((res) => {
				setDeleteModal(false);
				setShowExcelButton('firstStatus');
				refetch();
				refetchCount();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		deleteCarColorByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				setShowExcelButton('firstStatus');
				refetch();
				refetchCount();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const onSubmit = () => {
		if (!name) {
			setNameError(true);
		} else {
			setNameError(false);
		}
		if (name && code) {
			setDisableButton(true);
			createCarColorByAdmin({
				variables: { name: name.trim(), code: code },
			})
				.then((res) => {
					setShowExcelButton('firstStatus');
					refetch();
					refetchCount();
					setName('');
					setCode('FFFFFF');
					setAddModal(true);
					setDisableButton(false);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<div>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}
				{idsForDelete.length > 0 && (
					<Button title="delete selected items" color="redButton" onClick={onDeleteSome}>
						Delete
					</Button>
				)}
			</div>
			{showMultipleAdd && (
				<div className={style.multipleAddDiv}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<p>upload an excel file like this template.</p>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<div className={style.multipleButtonDiv}>
								<Button fullWidth color="whiteButton" onClick={() => onDownloadTemplate()}>
									<i class="fas fa-cloud-download-alt"></i> download template
								</Button>
							</div>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<Files
								maxSize="10mb"
								multiple={false}
								convertToBase64={true}
								accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
								onSuccess={(file) => uploadExcelFile(file[0])}
								onError={(errors) => {
									setExcelFileError(true);
									console.log(errors);
								}}
							>
								{({ browseFiles, getDropZoneProps, getLabelProps }) => (
									<div style={{ textAlign: 'center' }}>
										{excelFileError && (
											<p
												style={{
													color: 'red',
													fontsize: '9px',
												}}
											>
												please upload an excel file
											</p>
										)}

										<div {...getDropZoneProps({ className: 'myDropZone' })} />
										{excelFile && (
											<Button
												onClick={() => setExcelFile('')}
												size="sm"
												justIcon
												round
												color="danger"
											>
												<CloseIcon />
											</Button>
										)}

										<Button fullWidth onClick={browseFiles} color="whiteButton">
											<i class="fas fa-cloud-upload-alt"></i>{' '}
											{`${excelFile ? 'edit' : 'upload'} excel file`}
										</Button>
									</div>
								)}
							</Files>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<div className={style.multipleButtonDiv}>
								<Button
									fullWidth
									disabled={!excelFile || disableMultiButton}
									color="whiteButton"
									onClick={() => onAddMultiple()}
								>
									{addViaExcelLoading && (
										<MoonLoader
											css={css`
												margin: 0 5px;
											`}
											size={15}
											margin={2}
											color={'gray'}
											loading={true}
										/>
									)}
									Submit
								</Button>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			)}
			{!showMultipleAdd && (
				<div className={style.toggleDiv}>
					<button onClick={() => setShowMultipleAdd(true)}>
						<KeyboardArrowDownIcon />
						<div className={style.buttonSentence}>add all colors with excel file</div>
					</button>
				</div>
			)}
			{showMultipleAdd && (
				<div className={style.toggleDiv}>
					<button onClick={() => setShowMultipleAdd(false)}>
						<KeyboardArrowUpIcon />
						<div className={style.buttonSentence}>hide</div>
					</button>
				</div>
			)}
			<div className={style.inputRow}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={name}
										onChange={(e) => setName(e.target.value)}
										error={nameError}
										labelText={nameError ? "name can't be empty*" : 'name'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.colorLabel}>Code:</div>
								</GridItem>
								<div style={{ paddingTop: '40px', paddingLeft: '15px' }}>
									<div
										style={{
											padding: '5px',
											background: '#fff',
											borderRadius: '1px',
											boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
											display: 'inline-block',
											cursor: 'pointer',
										}}
										onClick={() =>
											setDisplayColorPicker((displayColorPicker) => !displayColorPicker)
										}
									>
										<div
											style={{
												width: '36px',
												height: '14px',
												borderRadius: '2px',
												background: code,
											}}
										></div>
									</div>
									{displayColorPicker ? (
										<div style={{ position: 'absolute', zIndex: '2' }}>
											<div
												style={{
													position: 'fixed',
													top: '0px',
													right: '0px',
													bottom: '0px',
													left: '0px',
												}}
												onClick={() => setDisplayColorPicker(false)}
											/>
											<SketchPicker color={code} onChange={(color) => setCode(color.hex)} />
										</div>
									) : null}
								</div>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={`${style.inputButtonContainer} ${style.middleButtonContainer}`}>
							<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
								Add Color
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<ReactTable
				data={data.getCarColors.map((color) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(color._id)}
								onChange={() => {
									if (idsForDelete.includes(color._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== color._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, color._id]);
									}
								}}
							/>
						</div>
					),
					name: color.name,
					code: color.code,
					colorBox: (
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
							<div
								style={{
									backgroundColor: color.code,
									height: '15px',
									width: '15px',
								}}
							></div>
						</div>
					),
					actions: (
						<div className="actions-right">
							<IconButton
								id={color._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, color._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === color._id}
								onClose={handleClose}
							>
								<MenuItem onClick={() => onSelectColorEdit(color)}>edit</MenuItem>
								<MenuItem onClick={() => onDeleteColor(color)}>delete</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div className={style.fitTitleDivCheckbox}>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getCarColors.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getCarColors, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: <div className={style.fitTitleDiv}>Name</div>,
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Code</div>,
						accessor: 'code',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Color</div>,
						accessor: 'colorBox',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getCarColorsCount === 0 ? 1 : Math.ceil(countData.getCarColorsCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<EditBrandAndModel
						refetch={() => {
							refetch();
							refetchCount();
						}}
						close={() => setModal(false)}
						type="color"
						item={colorDetails}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text="Color added succesfully." />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
			{colorForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteColor}
							text="Do you want to delete this color?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these colors?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Colors;
