import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClearIcon from '@material-ui/icons/Clear';
import { css } from '@emotion/core';
import Modal from 'commonComponents/modal/Modal';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import BeatLoader from 'react-spinners/BeatLoader';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import ReactTable from 'react-table';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SortIcon from '@material-ui/icons/Sort';
import style from 'shopAdminViews/orders/Orders.module.scss';
import RejectForm from 'superAdminViews/ride/verifications/RejectForm';
import ShopVerificationDetails from './ShopVerificationDetails';

const VERIFY_SHOP = gql`
	mutation verifyShop($id: ID!) {
		verifyShop(id: $id) {
			_id
			verified
		}
	}
`;

const GET_ATTRIBUTES = gql`
	query getAttributes($limit: Int, $skip: Int) {
		getAttributes(pagination: { limit: $limit, skip: $skip }) {
			_id
			name
		}
	}
`;

const GET_CATEGORIES = gql`
	query getCategories($parent: ID) {
		getCategories(filters: { parent: $parent }) {
			_id
			parent {
				title
			}
			title
			photoUrl
		}
	}
`;

const GET_SHOPS = gql`
	query getShopsByAdmin(
		$limit: Int
		$skip: Int
		$_id: ID
		$name: String
		$shopAdmin: ID
		$state: ShopStateInput
		$active: Boolean
		$budget: String
		$rootCategory: ID
		$attributes: [ID]
		$shopAdminName: String
		$verified: Boolean
		$createdAtSort: Int
		$isRejected: Boolean
	) {
		getShopsByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				name: $name
				shopAdmin: $shopAdmin
				active: $active
				state: $state
				budget: $budget
				rootCategory: $rootCategory
				attributes: $attributes
				shopAdminName: $shopAdminName
				verified: $verified
				isRejected: $isRejected
			}
			sort: { createdAt: $createdAtSort }
		) {
			_id
			name
			averageRate
			active
			state
			budget
			verified
			logoUrl
			bannerUrl
			shopAdmin {
				_id
				fullName
				email
			}
			shopMenu {
				_id
				subMenus {
					name
					products {
						_id
						title
					}
				}
			}
			rootCategory {
				title
			}
			categories {
				_id
				title
			}
			attributes {
				_id
				name
			}
		}
	}
`;

const GET_SHOPS_COUNT = gql`
	query getShopsByAdminCount(
		$_id: ID
		$name: String
		$shopAdmin: ID
		$state: ShopStateInput
		$active: Boolean
		$budget: String
		$rootCategory: ID
		$attributes: [ID]
		$shopAdminName: String
		$verified: Boolean
		$isRejected: Boolean
	) {
		getShopsByAdminCount(
			filters: {
				_id: $_id
				name: $name
				shopAdmin: $shopAdmin
				active: $active
				state: $state
				budget: $budget
				rootCategory: $rootCategory
				attributes: $attributes
				shopAdminName: $shopAdminName
				verified: $verified
				isRejected: $isRejected
			}
		)
	}
`;

const ShopVerifications = (props) => {
	const { loading: categoriesLoading, error: categoriesError, data: categoriesData } = useQuery(GET_CATEGORIES, {
		variables: {
			parent: null,
		},
	});

	const { loading: attributesLoading, error: attributesError, data: attributesData } = useQuery(GET_ATTRIBUTES, {
		variables: {
			limit: 50,
			skip: 0,
		},
	});

	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const stateOptions = ['ALL', 'ACTIVE', 'SUSPENDED'];
	const budgetOptions = ['ALL', 'B', 'BB', 'BBB'];

	const [nameSort, setNameSort] = useState('');
	const [priceRangeSort, setPriceRangeSort] = useState('');
	const [rateSort, setRateSort] = useState('');
	const [budgetSort, setBudgetSort] = useState('');

	const [idFilter, setIdFilter] = useState('');
	const [nameFilter, setNameFilter] = useState('');
	const [shopAdminIdFilter, setShopAdminIdFilter] = useState('');
	const [stateFilter, setStateFilter] = useState(stateOptions[0]);
	const [activeFilter, setActiveFilter] = useState('');
	const [attributeFilter, setAttributeFilter] = useState([]);
	const [categoryFilter, setCategoryFilter] = useState([]);
	const [rateFilter, setRateFilter] = useState('');
	const [budgetFilter, setBudgetFilter] = useState(budgetOptions[0]);

	const [modal, setModal] = useState(false);
	const [itemDetail, setItemDetail] = useState();

	const [approveModal, setApproveModal] = useState(false);
	const [itemForApprove, setItemForApprove] = useState();

	const [ignoreModal, setIgnoreModal] = useState(false);
	const [itemForIgnore, setItemForIgnore] = useState();

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const [noFilterModal, setNoFilterModal] = useState(false);
	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectItemDetail = (item) => {
		setModal(true);
		setItemDetail(item);
	};

	const onApproveItem = (item) => {
		setApproveModal(true);
		setItemForApprove(item);
	};

	const onIgnoreItem = (item) => {
		setIgnoreModal(true);
		setItemForIgnore(item);
	};

	const [shopAdminNameFilter, setShopAdminNameFilter] = useState('');

	const [rootCategoryName, setRootCategoryName] = useState('ALL');
	const [rootCategoryId, setRootCategoryId] = useState();

	const [idFilterError, setIdFilterError] = useState(false);
	const [shopAdminIdFilterError, setShopAdminIdFilterError] = useState(null);

	useEffect(() => {
		if (categoriesData && rootCategoryName !== 'ALL') {
			setRootCategoryId(categoriesData.getCategories.find((category) => category.title === rootCategoryName)._id);
		}
		if (rootCategoryName === 'ALL') {
			setRootCategoryId(undefined);
		}
	}, [, rootCategoryName]);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		name: undefined,
		shopAdminName: undefined,
		state: undefined,
		budget: undefined,
		verified: false,
		isRejected: false,
		rootCategory: undefined,
		averageRate: undefined,
		attributes: undefined,
		priceRangeSort: undefined,
		averageRateSort: undefined,
		createdAtSort: -1,
	});

	useEffect(() => {
		if (idFilter && idFilter.length !== 24) {
			setIdFilterError(true);
		} else {
			setIdFilterError(false);
		}
	}, [idFilter]);

	useEffect(() => {
		if (shopAdminIdFilter && shopAdminIdFilter.length !== 24) {
			setShopAdminIdFilterError(true);
		} else {
			setShopAdminIdFilterError(false);
		}
	}, [shopAdminIdFilter]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getShopsByAdminCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getShopsByAdminCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	const [excelData, setExcelData] = useState([
		['name', 'admin', 'state', 'budget', 'type', 'attributes'],
	]);

	const [getShopsByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_SHOPS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getShopsByAdminCount > 0 &&
			excelData.length === countData.getShopsByAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			console.log(lazyData);

			setExcelData([
				...excelData,
				...Array.from(lazyData.getShopsByAdmin, (item) => [
					item.name,
					item.shopAdmin ? item.shopAdmin.fullName : '-',
					item.state.toLowerCase(),
					item.budget,
					item.rootCategory.title.toLowerCase(),
					Array.from(item.attributes, (item) => item.name).join(','),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'ShopVerifications.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getShopsByAdmin({ variables: { ...variables, skip: 0, limit: countData.getShopsByAdminCount } });
		}
	};

	const onSubmitFilter = () => {
		let hasFilter =
			nameFilter ||
			variables.name ||
			shopAdminNameFilter ||
			variables.shopAdminName ||
			rootCategoryId ||
			variables.rootCategory ||
			rateFilter ||
			variables.averageRate ||
			!(stateFilter === 'ALL' && !variables.state) ||
			!(budgetFilter === 'ALL' && !variables.budget) ||
			!(attributeFilter.length === 0 && !variables.attributes);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');

			if (nameFilter) {
				setVariables((variables) => ({
					...variables,
					name: nameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, name: undefined }));
			}

			if (rootCategoryId) {
				setVariables((variables) => ({
					...variables,
					rootCategory: rootCategoryId,
				}));
			} else {
				setVariables((variables) => ({ ...variables, rootCategory: undefined }));
			}
			if (stateFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					state: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					state: stateFilter.toUpperCase(),
				}));
			}
			if (budgetFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					budget: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					budget: budgetFilter.toUpperCase(),
				}));
			}
			if (attributeFilter.length === 0) {
				setVariables((variables) => ({ ...variables, attributes: undefined }));
			} else {
				setVariables((variables) => ({
					...variables,
					attributes: attributeFilter,
				}));
			}
			if (priceRangeSort) {
				if (priceRangeSort === 'up') {
					setVariables((variables) => ({ ...variables, priceRangeSort: 0 }));
				} else if (priceRangeSort === 'down') {
					setVariables((variables) => ({ ...variables, priceRangeSort: -1 }));
				}
			} else {
				setVariables((variables) => ({
					...variables,
					priceRangeSort: undefined,
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			name: undefined,
			shopAdminName: undefined,
			state: undefined,
			budget: undefined,
			verified: false,
			isRejected: false,
			rootCategory: undefined,
			averageRate: undefined,
			attributes: undefined,
			priceRangeSort: undefined,
			averageRateSort: undefined,
			createdAtSort: -1,
		});
		setNameFilter('');
		setRateFilter('');
		setShopAdminNameFilter('');
		setAttributeFilter([]);
		setBudgetFilter('ALL');
		setStateFilter('ALL');
		setActiveFilter('ALL');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	const { loading, error, data, refetch } = useQuery(GET_SHOPS, {
		variables: variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData, refetch: refetchCount } = useQuery(
		GET_SHOPS_COUNT,
		{
			variables: variables,
			fetchPolicy: 'network-only',
			notifyOnNetworkStatusChange: true,
		}
	);

	const [verifyShop] = useMutation(VERIFY_SHOP);

	const approve = () => {
		verifyShop({ variables: { id: itemForApprove._id } })
			.then((res) => {
				setApproveModal(false);
				refetch();
				refetchCount();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setApproveModal(false);
					setErrorText(err.graphQLErrors[0].message);
					setErrorModal(true);
				}
			});
	};

	if (countError) {
		console.log(countError);
	}

	if (loading || categoriesLoading || attributesLoading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error || categoriesError || attributesError) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
			<div>
				<Button color="yellow" onClick={onSubmitFilter}>
					<SortIcon />
					Search
				</Button>
				<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
					<ClearIcon />
					Default
				</Button>
			</div>
			{data && countData && (
				<div className={style.rowConfigDriv}>
					<div className={style.itemsNumberDiv}>
						Displaying items{' '}
						<span>{countData.getShopsByAdminCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
						<span>{pageCount * perPage + Math.min(perPage, data.getShopsByAdmin.length)}</span> of{' '}
						<span>{countData.getShopsByAdminCount}</span>
					</div>
					<div className={style.rowDropDownDiv}>
						<div className={style.rowLabel}>Row:</div>{' '}
						<CustomDropdown
							hoverColor="primary"
							buttonText={String(perPage)}
							onClick={(e) => {
								setPageCount(0)
								setPerPage(parseInt(e));
							}}
							dropdownList={perPageOptions}
						/>
					</div>
				</div>
			)}</div>
			<ReactTable
				data={data.getShopsByAdmin.map((shop) => ({
					id: (
						<CopyToClipboard text={shop._id}>
							<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
								{shop._id}
							</span>
						</CopyToClipboard>
					),
					name: shop.name,
					shopAdminId: shop.shopAdmin && (
						<CopyToClipboard text={shop.shopAdmin._id}>
							<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
								{shop.shopAdmin._id}
							</span>
						</CopyToClipboard>
					),
					shopAdminName: shop.shopAdmin && shop.shopAdmin.fullName,
					state: shop.state && (
						<div className={`${style.inlineTag} ${style[shop.state.toLowerCase() + 'Tag']}`}>
							{shop.state.toLowerCase()}
						</div>
					),
					budget: shop.budget && (
						<div className={`${style.inlineTag} ${style[shop.budget.toLowerCase() + 'Tag']}`}>
							{shop.budget}
						</div>
					),
					priceRange: shop.priceRange ? shop.priceRange : '',
					attributes: (
						<div style={{ height: '80px', overflowY: 'auto', overflowX: 'hidden', paddingTop: '25px' }}>
							{' '}
							{shop.attributes.map((item) => (
								<div className={`${style.inlineTag} ${style.pendingTag}`} style={{ margin: '3px' }}>
									{item.name.toLowerCase()}
								</div>
							))}
						</div>
					),
					rate: <div style={{ textAlign: 'center' }}>{shop.averageRate.toFixed(2)}</div>,
					categories: shop.rootCategory && (
						<div className={`${style.inlineTag} ${style[shop.rootCategory.title.toLowerCase() + 'Tag']}`}>
							{shop.rootCategory.title.toLowerCase()}
						</div>
					),
					actions: (
						<div>
							<button onClick={() => onSelectItemDetail(shop)} className={style.detailIconDiv}>
								<span>i</span>
							</button>
							<IconButton
								id={shop._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, shop._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu id="simple-menu" anchorEl={anchorEl} open={menuId === shop._id} onClose={handleClose}>
								<MenuItem onClick={() => onApproveItem(shop)}>Approve</MenuItem>
								<MenuItem onClick={() => onIgnoreItem(shop)}>Reject</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDiv}>Name</div>
								<CustomInput
									value={nameFilter}
									onChange={(e) => setNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDiv}>Admin</div>
								<CustomInput
									value={shopAdminNameFilter}
									onChange={(e) => setShopAdminNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'shopAdminName',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="dropDownWithoutFrom">
								<div className={style.titleDiv}>State</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={stateFilter}
										onClick={(e) => setStateFilter(e)}
										dropdownList={stateOptions}
									/>
								</div>
							</div>
						),
						accessor: 'state',
						sortable: false,
						filterable: false,
					},

					{
						Header: (
							<div className="dropDownWithoutFrom">
								<div className={style.titleDiv}>Budget</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={budgetFilter}
										onClick={(e) => setBudgetFilter(e)}
										dropdownList={budgetOptions}
									/>
								</div>
							</div>
						),
						accessor: 'budget',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="dropDownWithoutFrom">
								<div className={style.titleDiv}>Type</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={rootCategoryName}
										onClick={(e) => setRootCategoryName(e)}
										dropdownList={
											categoriesData && [
												'ALL',
												...Array.from(
													categoriesData.getCategories,
													(category) => category.title
												),
											]
										}
									/>
								</div>
							</div>
						),
						accessor: 'categories',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDiv}>Attributes</div>
								<FormControl fullWidth>
									<Select
										multiple
										value={attributeFilter}
										onChange={(e) => setAttributeFilter(e.target.value)}
										inputProps={{
											name: 'multipleSelect',
											id: 'multiple-select',
										}}
									>
										{attributesData.getAttributes.map((attribute) => (
											<MenuItem value={attribute._id}>{attribute.name}</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						),
						accessor: 'attributes',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={Math.ceil(countData.getShopsByAdminCount / perPage)}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setErrorModal(false);
						}}
						type="notif"
						text={errorText ? errorText : 'Error ! Please Fill All Inputs Correctly And Try Again.'}
					/>
				</DialogContent>
			</Dialog>
			{itemForApprove && (
				<Dialog open={approveModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setApproveModal(false)}
							func={approve}
							text="Do you want to approve this shop?"
						/>
					</DialogContent>
				</Dialog>
			)}
			{itemForIgnore && (
				<Dialog open={ignoreModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<RejectForm
							id={itemForIgnore._id}
							refetch={refetch}
							refetchCount={refetchCount}
							close={() => setIgnoreModal(false)}
							type="shop"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<ShopVerificationDetails close={() => setModal(false)} item={itemDetail} />
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ShopVerifications;
