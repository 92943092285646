import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MoonLoader from 'react-spinners/MoonLoader';
import Files from 'react-butterfiles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import XLSX from 'xlsx';
import SelectSearch from 'react-select-search';
import BarLoader from 'react-spinners/BarLoader';
import ReactTable from 'react-table';
import Pagination from 'commonComponents/pagination/Pagination';
import Checkbox from '@material-ui/core/Checkbox';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Dialog from '@material-ui/core/Dialog';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import EditBrandAndModel from 'superAdminViews/setting/carOptions/EditBrandAndModel';
import style from 'superAdminViews/setting/defaults/DriverReadyComments.module.scss';

const ADD_MODEL_VIA_EXCEL = gql`
	mutation addCarModelViaExcel($excelFile: Upload!) {
		addCarModelViaExcel(excelFile: $excelFile)
	}
`;

const ADD_MODEL = gql`
	mutation createCarModelByAdmin($name: String!, $brand: ID!) {
		createCarModelByAdmin(input: { name: $name, brand: $brand }) {
			_id
			name
		}
	}
`;

const GET_MODELS = gql`
	query getCarModels($limit: Int, $skip: Int, $name: String, $brand: ID) {
		getCarModels(pagination: { limit: $limit, skip: $skip }, filters: { name: $name, brand: $brand }) {
			_id
			name
			admin {
				_id
			}
			brand {
				_id
				name
			}
		}
	}
`;

const GET_MODELS_COUNT = gql`
	query getCarModelsCount($name: String, $brand: ID) {
		getCarModelsCount(filters: { name: $name, brand: $brand })
	}
`;

const GET_BRANDS = gql`
	query getCarBrands($limit: Int, $skip: Int, $name: String) {
		getCarBrands(pagination: { limit: $limit, skip: $skip }, filters: { name: $name }) {
			_id
			name
		}
	}
`;

const DELETE_MODEL = gql`
	mutation deleteCarModelByAdmin($idSet: [ID!]!) {
		deleteCarModelByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const Models = () => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);

	const [brandSelectOptions, setBrandSelectOptions] = useState([]);

	const [
		getCarBrands,
		{ loading: brandsLazyLoading, error: brandsLazyError, data: brandsLazyData, fetchMore },
	] = useLazyQuery(GET_BRANDS);

	useEffect(() => {
		if (brandsLazyData) {
			setBrandSelectOptions(
				Array.from(brandsLazyData.getCarBrands, (brand) => ({
					name: brand.name,
					value: brand._id,
				}))
			);
		}
	}, [, brandsLazyData, brandsLazyLoading]);

	const [fetchMoreSkip, setFetchMoreSkip] = useState(10);
	const [search, setSearch] = useState('');

	useEffect(() => {
		const handler = setTimeout(() => {
			getCarBrands({
				variables: {
					limit: 10,
					skip: 0,
					name: search ? search.trim() : '',
				},
			});
		}, 200);
		return () => clearTimeout(handler);
	}, [search]);

	const onLoadMore = () => {
		fetchMore({
			query: GET_BRANDS,
			variables: {
				limit: 10,
				skip: fetchMoreSkip,
				name: search ? search : '',
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				setBrandSelectOptions((brandSelectOptions) => [
					...brandSelectOptions,
					...Array.from(fetchMoreResult.getCarBrands, (brand) => ({
						name: brand.name,
						value: brand._id,
					})),
				]);
			},
		});
		setFetchMoreSkip((fetchMoreSkip) => fetchMoreSkip + 10);
	};

	const handleScroll = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			onLoadMore();
		}
	};

	const [filterVariables, setFilterVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		name: undefined,
		brand: undefined,
	});

	const { loading, error, data, refetch } = useQuery(GET_MODELS, {
		variables: filterVariables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData, refetch: refetchCount } = useQuery(
		GET_MODELS_COUNT,
		{
			variables: filterVariables,
			fetchPolicy: 'network-only',
		}
	);

	const [
		addCarModelViaExcel,
		{ data: addViaExcelData, error: addViaExcelError, loading: addViaExcelLoading },
	] = useMutation(ADD_MODEL_VIA_EXCEL);

	const [showMultipleAdd, setShowMultipleAdd] = useState(false);
	const [disableMultiButton, setDisableMultiButton] = useState(false);
	const [excelFile, setExcelFile] = useState('');
	const [excelFileError, setExcelFileError] = useState(false);

	const arrayForTemplateExcel = [['Name', 'Brand'], ['i8', 'BMW'], ['Mustang', 'Ford'], ['TT', 'Audi']];

	const onDownloadTemplate = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(arrayForTemplateExcel);
		XLSX.utils.book_append_sheet(wb, ws, 'Template');
		XLSX.writeFile(wb, 'Spark-Models-Template.xlsx');
	};

	const uploadExcelFile = (file) => {
		setExcelFile(file.src.file);
	};

	const onAddMultiple = () => {
		setDisableMultiButton(true);
		addCarModelViaExcel({
			variables: { excelFile: excelFile },
		})
			.then((res) => {
				console.log(res);
				setShowExcelButton('firstStatus');
				setShowMultipleAdd(false);
				setExcelFile('');
				refetch();
				refetchCount();
			})
			.catch((err) => {
				setDisableMultiButton(false);
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setErrorText(err.graphQLErrors[0].message);
					setErrorModal(true);
				}
			});
	};

	const [nameFilter, setNameFilter] = useState('');
	const [brandFilter, setBrandFilter] = useState('ALL');
	const [brandIdFilter, setBrandIdFilter] = useState('ALL');

	const [name, setName] = useState('');
	const [brandId, setBrandId] = useState();
	const [brandError, setBrandError] = useState();
	const [disableButton, setDisableButton] = useState(false);

	const [nameError, setNameError] = useState(false);

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [modelForDelete, setModelForDelete] = useState([]);

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const [addModal, setAddModal] = useState(false);
	const [modal, setModal] = useState(false);

	const [modelDetails, setModelDetails] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectModelEdit = (model) => {
		setModal(true);
		setModelDetails(model);
	};

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getCarModels.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	const excelHeader = [['name', 'brand']];
	const [excelData, setExcelData] = useState(excelHeader);

	const [getCarModels, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_MODELS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (countData && excelData.length === countData.getCarModelsCount + 1) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelHeader,
				...Array.from(lazyData.getCarModels, (item) => [item.name, item.brand.name]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Models.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getCarModels({ variables: { skip: 0, limit: countData.getCarModelsCount } });
		}
	};

	const onDeleteModel = (model) => {
		setDeleteModal(true);
		setModelForDelete([model._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const [createCarModelByAdmin, { data: addMutationData, error: addMutationError }] = useMutation(ADD_MODEL);

	const [deleteCarModelByAdmin] = useMutation(DELETE_MODEL);

	const deleteModel = () => {
		deleteCarModelByAdmin({ variables: { idSet: modelForDelete } })
			.then((res) => {
				setShowExcelButton('firstStatus');
				setDeleteModal(false);
				setIdsForDelete([]);
				refetch();
				refetchCount();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		deleteCarModelByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setShowExcelButton('firstStatus');
				setDeleteSomeModal(false);
				refetch();
				refetchCount();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	// testing menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getCarModelsCount / perPage) - 1 && countData.getCarModelsCount !== 0) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getCarModelsCount !== 0) {
			setPageCount(Math.ceil(countData.getCarModelsCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setFilterVariables((variables) => ({
			...variables,
			skip: perPage * pageCount,
		}));
	}, [pageCount]);

	const onFilterSubmit = () => {
		setPageCount(0);
		if (nameFilter) {
			setFilterVariables((variables) => ({
				...variables,
				name: nameFilter.trim(),
			}));
		} else {
			setFilterVariables((variables) => ({ ...variables, name: undefined }));
		}
		if (brandIdFilter !== 'ALL') {
			setFilterVariables({ ...filterVariables, brand: brandIdFilter });
		} else {
			setFilterVariables({ ...filterVariables, brand: undefined });
		}
	};

	const onSubmit = () => {
		if (!name) {
			setNameError(true);
		} else {
			setNameError(false);
		}
		if (!brandId) {
			setBrandError(true);
		} else {
			setBrandError(false);
		}
		if (name && brandId) {
			setDisableButton(true);
			createCarModelByAdmin({
				variables: { name: name.trim(), brand: brandId },
			})
				.then((res) => {
					setShowExcelButton('firstStatus');
					refetch();
					refetchCount();
					setName('');
					setAddModal(true);
					setDisableButton(false);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<div>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}
				{idsForDelete.length > 0 && (
					<Button title="delete selected items" color="redButton" onClick={onDeleteSome}>
						Delete
					</Button>
				)}
			</div>
			{showMultipleAdd && (
				<div className={style.multipleAddDiv}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<p>upload an excel file like this template.</p>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<div className={style.multipleButtonDiv}>
								<Button fullWidth color="whiteButton" onClick={() => onDownloadTemplate()}>
									<i class="fas fa-cloud-download-alt"></i> download template
								</Button>
							</div>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<Files
								maxSize="10mb"
								multiple={false}
								convertToBase64={true}
								accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
								onSuccess={(file) => uploadExcelFile(file[0])}
								onError={(errors) => {
									setExcelFileError(true);
									console.log(errors);
								}}
							>
								{({ browseFiles, getDropZoneProps, getLabelProps }) => (
									<div style={{ textAlign: 'center' }}>
										{excelFileError && (
											<p
												style={{
													color: 'red',
													fontsize: '9px',
												}}
											>
												please upload an excel file
											</p>
										)}

										<div {...getDropZoneProps({ className: 'myDropZone' })} />
										{excelFile && (
											<Button
												onClick={() => setExcelFile('')}
												size="sm"
												justIcon
												round
												color="danger"
											>
												<CloseIcon />
											</Button>
										)}

										<Button fullWidth onClick={browseFiles} color="whiteButton">
											<i class="fas fa-cloud-upload-alt"></i>{' '}
											{`${excelFile ? 'edit' : 'upload'} excel file`}
										</Button>
									</div>
								)}
							</Files>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<div className={style.multipleButtonDiv}>
								<Button
									fullWidth
									disabled={!excelFile || disableMultiButton}
									color="whiteButton"
									onClick={() => onAddMultiple()}
								>
									{addViaExcelLoading && (
										<MoonLoader
											css={css`
												margin: 0 5px;
											`}
											size={15}
											margin={2}
											color={'gray'}
											loading={true}
										/>
									)}
									Submit
								</Button>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			)}
			{!showMultipleAdd && (
				<div className={style.toggleDiv}>
					<button onClick={() => setShowMultipleAdd(true)}>
						<KeyboardArrowDownIcon />
						<div className={style.buttonSentence}>add all models with excel file</div>
					</button>
				</div>
			)}
			{showMultipleAdd && (
				<div className={style.toggleDiv}>
					<button onClick={() => setShowMultipleAdd(false)}>
						<KeyboardArrowUpIcon />
						<div className={style.buttonSentence}>hide</div>
					</button>
				</div>
			)}
			<div className={style.inputRow}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={name}
										onChange={(e) => setName(e.target.value)}
										error={nameError}
										labelText={nameError ? "name can't be empty*" : 'name'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownLabel} style={{ paddingBottom: '3px' }}>
										Brand:
									</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.dropdownContainer}>
										<div>
											<SelectSearch
												options={brandSelectOptions}
												value={brandId}
												onScroll={(e) => handleScroll(e)}
												getOptions={(searchWord) => {
													setSearch(searchWord);
												}}
												name=""
												search
												placeholder="Choose brand"
												onChange={(value) => setBrandId(value)}
											/>
										</div>
									</div>
								</GridItem>
							</GridContainer>
							{brandError && <div className={style.selectSearchError}>brand can't be empty*</div>}
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={`${style.inputButtonContainer} ${style.middleButtonContainer}`}>
							<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
								Add Model
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<ReactTable
				data={data.getCarModels.map((model) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(model._id)}
								onChange={() => {
									if (idsForDelete.includes(model._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== model._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, model._id]);
									}
								}}
							/>
						</div>
					),
					name: model.name,
					brand: model.brand.name,
					actions: '',
					actions: (
						<div className="actions-right">
							<IconButton
								id={model._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, model._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === model._id}
								onClose={handleClose}
								onClick={handleClose}
							>
								<MenuItem onClick={() => onSelectModelEdit(model)}>edit</MenuItem>
								<MenuItem onClick={() => onDeleteModel(model)}>delete</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div className={style.fitTitleDivCheckbox}>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getCarModels.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getCarModels, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: <div className={style.fitTitleDiv}>Name</div>,
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Brand</div>,
						accessor: 'brand',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getCarModelsCount === 0 ? 1 : Math.ceil(countData.getCarModelsCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<EditBrandAndModel
						refetch={() => {
							refetch();
							refetchCount();
						}}
						close={() => setModal(false)}
						type="model"
						item={modelDetails}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text="Model added succesfully." />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
			{modelForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteModel}
							text="Do you want to delete this model?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these models?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Models;
