export const login = (token, refreshToken, status) => {
	window.localStorage.setItem('token', token);
	window.localStorage.setItem('refreshToken', refreshToken);
	console.log('token is:', token);
	console.log('refreshToken is:', refreshToken);
	if (status === 'refreshToken') {
		window.location.reload();
	}
};

export const logout = () => {
	window.localStorage.removeItem('token');
};

export const isLogin = () => {
	if (localStorage.getItem('token')) {
		return true;
	}

	return false;
};

export const hasPermission = (permissionName) => {
	if (
		window.localStorage.getItem('permissions') &&
		JSON.parse(window.localStorage.getItem('permissions')).includes(permissionName)
	) {
		return true;
	}

	return false;
};
