import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReactTable from 'react-table';
import Checkbox from '@material-ui/core/Checkbox';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import Dialog from '@material-ui/core/Dialog';
import Modal from 'commonComponents/modal/Modal';
import Pagination from 'commonComponents/pagination/Pagination';
import CustomInput from 'components/CustomInput/CustomInput.js';
import EditItem from 'superAdminViews/setting/defaults/EditItem';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import style from './CancelTripReason.module.scss';

const ADD_DRIVER_HOW_IT_WORKS = gql`
	mutation createDriverHowItWorksByAdmin($title: String!, $description: String!) {
		createDriverHowItWorksByAdmin(input: { title: $title, description: $description }) {
			_id
			title
		}
	}
`;

const GET_DRIVER_HOW_IT_WORKS = gql`
	query getDriverHowItWorks($limit: Int, $skip: Int) {
		getDriverHowItWorks(pagination: { limit: $limit, skip: $skip }) {
			_id
			title
			description
		}
	}
`;

const GET_DRIVER_HOW_IT_WORKS_COUNT = gql`
	query getDriverHowItWorksCount {
		getDriverHowItWorksCount
	}
`;

const DELETE_DRIVER_HOW_IT_WORKS = gql`
	mutation removeDriverHowItWorksByAdmin($idSet: [ID!]!) {
		removeDriverHowItWorksByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const DriverHowItWorks = (props) => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');

	const [titleError, setTitleError] = useState(false);
	const [descriptionError, setDescriptionError] = useState(false);

	const [addModal, setAddModal] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [itemForDelete, setItemForDelete] = useState();

	const [modal, setModal] = useState(false);
	const [commentDetails, setCommentDetails] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getDriverHowItWorks.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	// testing menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onDeleteItem = (item) => {
		setDeleteModal(true);
		setItemForDelete([item._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const deleteItem = () => {
		removeDriverHowItWorksByAdmin({ variables: { idSet: itemForDelete } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		removeDriverHowItWorksByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const [
		removeDriverHowItWorksByAdmin,
		{ data: deleteDriverHowItWorksData, error: deleteDriverHowItWorksError },
	] = useMutation(DELETE_DRIVER_HOW_IT_WORKS);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
	});

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getDriverHowItWorksCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getDriverHowItWorksCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	const onSelectCommentDetail = (comment) => {
		setModal(true);
		setCommentDetails(comment);
	};

	const { loading, error, data, refetch } = useQuery(GET_DRIVER_HOW_IT_WORKS, {
		variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_DRIVER_HOW_IT_WORKS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const [createDriverHowItWorksByAdmin, { data: addMutationData, error: addMutationError }] = useMutation(
		ADD_DRIVER_HOW_IT_WORKS
	);

	useEffect(() => {
		if (addMutationData) {
			setAddModal(true);
		}
	}, [addMutationData]);

	const onSubmit = () => {
		if (!title) {
			setTitleError(true);
		} else {
			setTitleError(false);
		}
		if (!description) {
			setDescriptionError(true);
		} else {
			setDescriptionError(false);
		}
		if (title && description) {
			setDisableButton(true);
			createDriverHowItWorksByAdmin({
				variables: {
					title: title.trim(),
					description: description.trim(),
				},
			})
				.then((res) => {
					refetch();
					setTitle('');
					setDescription('');
					setDisableButton(false);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
				});
		}
	};

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<div className={style.inputRow}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Title:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={title}
										onChange={(e) => setTitle(e.target.value)}
										error={titleError}
										labelText={titleError ? "title can't be empty*" : 'title'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Description:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										error={descriptionError}
										labelText={descriptionError ? "description can't be empty*" : 'description'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.inputButtonContainer}>
							<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
								Add
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			{idsForDelete.length > 0 && (
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<Button title="delete selected items" fullWidth color="redButton" onClick={onDeleteSome}>
							Delete
						</Button>
					</GridItem>
				</GridContainer>
			)}

			<ReactTable
				data={data.getDriverHowItWorks.map((comment) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(comment._id)}
								onChange={() => {
									if (idsForDelete.includes(comment._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== comment._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, comment._id]);
									}
								}}
							/>
						</div>
					),
					title: comment.title,
					description: comment.description,
					actions: (
						<div>
							<IconButton
								id={comment._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, comment._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === comment._id}
								onClose={handleClose}
							>
								<MenuItem onClick={() => onSelectCommentDetail(comment)}>edit</MenuItem>
								<MenuItem onClick={() => onDeleteItem(comment)}>delete</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div className={style.fitTitleDivCheckbox}>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getDriverHowItWorks.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getDriverHowItWorks, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: <div className={style.fitTitleDiv}>Title</div>,
						accessor: 'title',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Description</div>,
						accessor: 'description',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>

			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={Math.ceil(countData.getDriverHowItWorksCount / perPage)}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<EditItem
						refetch={() => refetch()}
						close={() => setModal(false)}
						type="driverHowItWorks"
						comment={commentDetails}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setAddModal(false)}
						type="notif"
						text="Driver how it works added succesfully."
					/>
				</DialogContent>
			</Dialog>
			{itemForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteItem}
							text="Do you want to delete this item?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these items?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default DriverHowItWorks;
