import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import { endPoint } from 'utils/config';
import Modal from 'commonComponents/modal/Modal';
import BeatLoader from 'react-spinners/BeatLoader';
import ClearIcon from '@material-ui/icons/Clear';
import ReactTable from 'react-table';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Checkbox from '@material-ui/core/Checkbox';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import SortIcon from '@material-ui/icons/Sort';
import style from 'shopAdminViews/orders/Orders.module.scss';

const GET_ADMINS = gql`
	query getAdminsByAdmin(
		$limit: Int
		$skip: Int
		$_id: ID
		$fullName: String
		$email: String
		$phoneNumber: String
		$state: AdminState
		$type: String
		$shop: ID
		$shopName: String
		$createdAtSort: Int
	) {
		getAdminsByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				email: $email
				phoneNumber: $phoneNumber
				fullName: $fullName
				type: $type
				state: $state
				shop: $shop
				shopName: $shopName
			}
			sort: { createdAt: $createdAtSort }
		) {
			_id
			fullName
			phoneNumber
			email
			shop {
				_id
				name
			}
			type
			state
		}
	}
`;

const GET_ADMINS_COUNT = gql`
	query getAdminsByAdminCount(
		$_id: ID
		$fullName: String
		$email: String
		$phoneNumber: String
		$state: AdminState
		$type: String
		$shop: ID
		$shopName: String
	) {
		getAdminsByAdminCount(
			filters: {
				_id: $_id
				email: $email
				phoneNumber: $phoneNumber
				fullName: $fullName
				type: $type
				state: $state
				shop: $shop
				shopName: $shopName
			}
		)
	}
`;

const DELETE_ADMIN = gql`
	mutation deleteAdminBySystemAdmin($idSet: [ID!]!) {
		deleteAdminBySystemAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const SUSPEND_ADMIN = gql`
	mutation suspendAdminByAdmin($idSet: [ID!]!) {
		suspendAdminByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const Admins = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const stateOptions = ['ALL', 'ACTIVE', 'SUSPENDED'];
	const typeOptions = ['ALL', 'SUPER-ADMIN', 'SHOP-ADMIN'];

	const [idFilter, setIdFilter] = useState('');
	const [shopNameFilter, setShopNameFilter] = useState('');
	const [shopIdFilter, setShopIdFilter] = useState('');
	const [nameFilter, setNameFilter] = useState('');
	const [phoneNumberFilter, setPhoneNumberFilter] = useState(null);
	const [emailFilter, setEmailFilter] = useState(null);
	const [stateFilter, setStateFilter] = useState(stateOptions[0]);
	const [typeFilter, setTypeFilter] = useState(stateOptions[0]);

	const [idFilterError, setIdFilterError] = useState(false);
	const [shopIdFilterError, setShopIdFilterError] = useState(false);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount,
		fullName: undefined,
		email: undefined,
		state: undefined,
		phoneNumber: undefined,
		createdAtSort: -1,
		type: undefined,
		shopName: undefined,
	});

	const [suspendErrorModal, setSuspendErrorModal] = useState(false);
	const [suspendErrorText, setSuspendErrorText] = useState('');

	const [checkAll, setCheckAll] = useState(false);
	const [suspendSomeModal, setSuspendSomeModal] = useState(false);
	const [actionWord, setActionWord] = useState('suspend');
	const [singleActionWord, setSingleActionWord] = useState('suspend');

	const [groupError, setGroupError] = useState(false);
	const [adminForSuspend, setAdminForSuspend] = useState([]);

	const [suspendModal, setSuspendModal] = useState(false);

	const [idsForDelete, setIdsForDelete] = useState([]);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [adminForDelete, setAdminForDelete] = useState([]);

	useEffect(() => {
		if (idsForDelete.length > 0) {
			let firstItem = data.getAdminsByAdmin.find((item) => item._id === idsForDelete[0]);
			if (firstItem.state === 'ACTIVE') {
				setActionWord('suspend');
			} else {
				setActionWord('activate');
			}
		}
	}, [idsForDelete]);

	useEffect(() => {
		if (data && adminForSuspend) {
			let selectedItem = data.getAdminsByAdmin.find((item) => item._id === adminForSuspend[0]);
			if (selectedItem.state === 'ACTIVE') {
				setSingleActionWord('suspend');
			} else {
				setSingleActionWord('activate');
			}
		}
	}, [adminForSuspend]);

	useEffect(() => {
		if (idsForDelete.length > 1) {
			idsForDelete.some((id) => {
				let firstItem = data.getAdminsByAdmin.find((item) => item._id === idsForDelete[0]);
				let currentItem = data.getAdminsByAdmin.find((item) => item._id === id);
				if (firstItem.state !== currentItem.state) {
					setGroupError(true);
					return -1;
				} else {
					setGroupError(false);
				}
			});
		} else {
			setGroupError(false);
		}
	}, [idsForDelete, checkAll]);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onDeleteAdmin = (admin) => {
		setDeleteModal(true);
		setAdminForDelete(admin._id);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const onSuspendAdmin = (admin) => {
		setSuspendModal(true);
		setAdminForSuspend([admin._id]);
	};

	const onSuspendSome = () => {
		setSuspendSomeModal(true);
	};

	const [excelData, setExcelData] = useState([['name', 'email', 'phone', 'shop', 'status', 'type']]);

	const [getAdminsByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_ADMINS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getAdminsByAdminCount > 0 &&
			excelData.length === countData.getAdminsByAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			console.log(lazyData);

			setExcelData([
				...excelData,
				...Array.from(lazyData.getAdminsByAdmin, (item) => [
					item.fullName,
					item.email,
					item.phoneNumber,
					item.shop ? item.shop.name : '-',
					item.state.replace(/_/g, ' ').toLowerCase(),
					item.type.replace(/_/g, ' ').toLowerCase(),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Admins.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getAdminsByAdmin({ variables: { ...variables, skip: 0, limit: countData.getAdminsByAdminCount } });
		}
	};

	const linkPage = (route) => {
		window.location.href = endPoint + `/superAdmin/${route}`;
	};

	const [deleteAdminBySystemAdmin] = useMutation(DELETE_ADMIN);
	const [suspendAdminByAdmin] = useMutation(SUSPEND_ADMIN);

	const deleteAdmin = () => {
		deleteAdminBySystemAdmin({ variables: { idSet: adminForDelete } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		deleteAdminBySystemAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const suspendAdmin = () => {
		suspendAdminByAdmin({ variables: { idSet: adminForSuspend } })
			.then((res) => {
				setSuspendModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setSuspendModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const suspendSome = () => {
		suspendAdminByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setSuspendSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setSuspendSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	useEffect(() => {
		if (idFilter && idFilter.length !== 24) {
			setIdFilterError(true);
		} else {
			setIdFilterError(false);
		}
	}, [idFilter]);

	useEffect(() => {
		if (shopIdFilter && shopIdFilter.length !== 24) {
			setShopIdFilterError(true);
		} else {
			setShopIdFilterError(false);
		}
	}, [shopIdFilter]);

	const onFilterSubmit = () => {
		let hasFilter =
			nameFilter ||
			variables.fullName ||
			phoneNumberFilter ||
			variables.phoneNumber ||
			emailFilter ||
			variables.email ||
			shopNameFilter ||
			variables.shopName ||
			!(stateFilter === 'ALL' && !variables.state) ||
			!(typeFilter === 'ALL' && !variables.type);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');

			if (nameFilter) {
				setVariables((variables) => ({
					...variables,
					fullName: nameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, fullName: undefined }));
			}

			if (shopNameFilter) {
				setVariables((variables) => ({
					...variables,
					shopName: shopNameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, shopName: undefined }));
			}

			if (emailFilter) {
				setVariables((variables) => ({
					...variables,
					email: emailFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, email: undefined }));
			}

			if (phoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					phoneNumber: phoneNumberFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, phoneNumber: undefined }));
			}

			if (stateFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					state: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					state: stateFilter.toUpperCase(),
				}));
			}

			if (typeFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					type: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					type: typeFilter.toUpperCase(),
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount,
			fullName: undefined,
			email: undefined,
			state: undefined,
			phoneNumber: undefined,
			createdAtSort: -1,
			type: undefined,
			shopName: undefined,
		});
		setNameFilter('');
		setShopNameFilter('');
		setTypeFilter('ALL');
		setEmailFilter('');
		setPhoneNumberFilter('');
		setStateFilter('ALL');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onFilterSubmit();
		}
	};

	const { loading, error, data, refetch } = useQuery(GET_ADMINS, {
		variables,
		fetchPolicy: 'network-only',
	});

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getAdminsByAdminCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getAdminsByAdminCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getAdminsByAdmin.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_ADMINS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}
	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			<div>
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<div className={style.linkButtonDiv}>
							<Button color="yellow" fullWidth onClick={() => linkPage('addAdmin')}>
								Add admin
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<div>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}
				{idsForDelete.length > 0 && (
					<Button title="delete selected items" color="redButton" onClick={onDeleteSome}>
						Delete
					</Button>
				)}
				{idsForDelete.length > 0 && !groupError && (
					<Button
						title={`${actionWord} selected items`}
						color={actionWord === 'suspend' ? 'redButton' : 'greenButton'}
						onClick={onSuspendSome}
					>
						{actionWord}
					</Button>
				)}
			</div>
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onFilterSubmit}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getAdminsByAdminCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getAdminsByAdmin.length)}</span> of{' '}
							<span>{countData.getAdminsByAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0);
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={data.getAdminsByAdmin.map((admin) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(admin._id)}
								onChange={() => {
									if (idsForDelete.includes(admin._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== admin._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, admin._id]);
									}
								}}
							/>
						</div>
					),
					name: admin.fullName,
					email: admin.email,
					phoneNumber: admin.phoneNumber,
					shopName: admin.shop && admin.shop.name,
					state: admin.state,
					type: admin.type,
					actions: (
						<div>
							<IconButton
								id={admin._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, admin._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === admin._id}
								onClose={handleClose}
								onClick={handleClose}
							>
								<MenuItem
									onClick={() =>
										(window.location.href = endPoint + `/superAdmin/editAdmin/${admin._id}`)
									}
								>
									edit
								</MenuItem>
								<MenuItem onClick={() => onSuspendAdmin(admin)}>
									{admin.state === 'ACTIVE' ? 'suspend' : 'activate'}
								</MenuItem>
								<MenuItem onClick={() => onDeleteAdmin(admin)}>delete</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div className="inputWithoutFrom">
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getAdminsByAdmin.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getAdminsByAdmin, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDiv}>Name</div>
								<CustomInput
									value={nameFilter}
									onChange={(e) => setNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDiv}>Email</div>
								<CustomInput
									value={emailFilter}
									onChange={(e) => setEmailFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'email ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'email',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDiv}>Phone</div>
								<CustomInput
									value={phoneNumberFilter}
									onChange={(e) => setPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone number ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'phoneNumber',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDiv}>Shop</div>
								<CustomInput
									value={shopNameFilter}
									onChange={(e) => setShopNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'shop name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'shopName',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="dropDownWithoutFrom">
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={stateFilter}
										onClick={(e) => setStateFilter(e)}
										dropdownList={stateOptions}
									/>
								</div>
							</div>
						),
						accessor: 'state',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="dropDownWithoutFrom">
								<div className={style.titleDiv}>Type</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={typeFilter}
										onClick={(e) => setTypeFilter(e)}
										dropdownList={typeOptions}
									/>
								</div>
							</div>
						),
						accessor: 'type',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="inputWithoutFrom">
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={Math.ceil(countData.getAdminsByAdminCount / perPage)}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={suspendSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setSuspendSomeModal(false);
							setIdsForDelete([]);
						}}
						func={suspendSome}
						text={`Do you want to ${actionWord} these admins?`}
					/>
				</DialogContent>
			</Dialog>
			{adminForSuspend && (
				<Dialog open={suspendModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setSuspendModal(false)}
							func={suspendAdmin}
							text={`Do you want to ${singleActionWord} this admin?`}
						/>
					</DialogContent>
				</Dialog>
			)}
			{adminForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteAdmin}
							text="Do you want to delete this admin?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these admins?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Admins;
