import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { endPoint } from 'utils/config';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { ThemeContext } from 'index';
import style from 'superAdminViews/ride/addPromotion/AddPromotion.module.scss';

const GET_TOKEN = gql`
	query getNewAccessTokenByAdmin($refreshToken: String!) {
		getNewAccessTokenByAdmin(refreshToken: $refreshToken) {
			token {
				refreshToken
				accessToken
			}
		}
	}
`;

const CHANGE_PASSWORD = gql`
	mutation changeShopAdminPassword($newPassword: String!, $currentPassword: String!) {
		changeShopAdminPassword(input: { newPassword: $newPassword, currentPassword: $currentPassword }) {
			_id
			token {
				accessToken
				refreshToken
			}
		}
	}
`;

const ChangePassword = (props) => {
	const themeContext = useContext(ThemeContext);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const theme = themeContext === 'Purchase' ? greenTheme : orangeTheme;

	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');

	const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
	const [newPasswordVisible, setNewPasswordVisible] = useState(false);
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

	const [currentPasswordError, setCurrentPasswordError] = useState(false);
	const [newPasswordError, setNewPasswordError] = useState(false);
	const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);

	const [addModal, setAddModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [changeShopAdminPassword] = useMutation(CHANGE_PASSWORD);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	const onCancel = () => {
		window.location.href = endPoint + '/shopAdmin/changePassword';
	};

	const onSubmit = () => {
		if (!currentPassword) {
			setCurrentPasswordError("password can't be empty");
		} else {
			setCurrentPasswordError('');
		}
		if (!newPassword) {
			setNewPasswordError("new password can't be empty");
		} else {
			if (newPassword.length < 8) {
				setNewPasswordError('enter at least 8 characters');
			} else {
				setNewPasswordError('');
			}
		}
		if (!confirmNewPassword) {
			setConfirmNewPasswordError("confirm password can't be empty");
		} else {
			if (newPassword !== confirmNewPassword) {
				setConfirmNewPasswordError('confirm should be equal to new password');
			} else {
				setConfirmNewPasswordError('');
			}
		}

		if (
			currentPassword &&
			newPassword &&
			newPassword.length > 7 &&
			confirmNewPassword &&
			newPassword === confirmNewPassword
		) {
			changeShopAdminPassword({
				variables: {
					newPassword: newPassword.trim(),
					currentPassword: currentPassword.trim(),
				},
			})
				.then((res) => {
					window.localStorage.setItem('password', btoa(newPassword));
					setAddModal(true);
					setCurrentPassword('');
					setNewPassword('');
					setConfirmNewPassword('');
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDiv}>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Current password:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={currentPassword}
										onChange={(e) => setCurrentPassword(e.target.value)}
										error={currentPasswordError}
										labelText={currentPasswordError ? currentPasswordError : 'current password'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: currentPasswordVisible ? 'text' : 'password',
											endAdornment: currentPasswordVisible ? (
												<VisibilityIcon
													className={style.icon}
													onClick={() => setCurrentPasswordVisible((visible) => !visible)}
													style={{ fontSize: '18px', color: 'gray' }}
													position="end"
												></VisibilityIcon>
											) : (
												<VisibilityOffIcon
													className={style.icon}
													onClick={() => setCurrentPasswordVisible((visible) => !visible)}
													style={{ fontSize: '18px', color: 'gray' }}
													position="end"
												></VisibilityOffIcon>
											),
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>New password:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={newPassword}
										onChange={(e) => setNewPassword(e.target.value)}
										error={newPasswordError}
										labelText={newPasswordError ? newPasswordError : 'new password'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: newPasswordVisible ? 'text' : 'password',
											endAdornment: newPasswordVisible ? (
												<VisibilityIcon
													className={style.icon}
													onClick={() => setNewPasswordVisible((visible) => !visible)}
													style={{ fontSize: '18px', color: 'gray' }}
													position="end"
												></VisibilityIcon>
											) : (
												<VisibilityOffIcon
													className={style.icon}
													onClick={() => setNewPasswordVisible((visible) => !visible)}
													style={{ fontSize: '18px', color: 'gray' }}
													position="end"
												></VisibilityOffIcon>
											),
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Confirm new password:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={confirmNewPassword}
										onChange={(e) => setConfirmNewPassword(e.target.value)}
										error={confirmNewPasswordError}
										labelText={
											confirmNewPasswordError ? confirmNewPasswordError : 'confirm password'
										}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: confirmPasswordVisible ? 'text' : 'password',
											endAdornment: confirmPasswordVisible ? (
												<VisibilityIcon
													className={style.icon}
													onClick={() => setConfirmPasswordVisible((visible) => !visible)}
													style={{ fontSize: '18px', color: 'gray' }}
													position="end"
												></VisibilityIcon>
											) : (
												<VisibilityOffIcon
													className={style.icon}
													onClick={() => setConfirmPasswordVisible((visible) => !visible)}
													style={{ fontSize: '18px', color: 'gray' }}
													position="end"
												></VisibilityOffIcon>
											),
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.buttonContainer}>
				<Button color="whiteButton" onClick={onCancel}>
					Cancel
				</Button>
				<Button onClick={onSubmit} color={theme.primaryColor}>
					Submit
				</Button>
			</div>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text="PassWord changed successfully." />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ChangePassword;
