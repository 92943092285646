import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ErrorPage from "commonComponents/errorPage/ErrorPage";
import { css } from "@emotion/core";
import { Prompt } from "react-router";
import BeatLoader from "react-spinners/BeatLoader";
import { graphqlUrl, endPoint } from "utils/config";
import Files from "react-butterfiles";
import CloseIcon from "@material-ui/icons/Close";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import SelectSearch from "react-select-search";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "commonComponents/modal/Modal";
import GridItem from "components/Grid/GridItem.js";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import CustomInput from "components/CustomInput/CustomInput.js";
import Datetime from "react-datetime";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import style from "superAdminViews/ride/addCar/AddCar.module.scss";
import moment from "moment";

const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!, $folderName: String!) {
    uploadFile(data: { file: $file, folderName: $folderName }) {
      url
    }
  }
`;

const ADD_CAR = gql`
  mutation addCarByAdmin(
    $driverId: ID!
    $color: ID!
    $pictures: pictures
    $insurance: InsuranceInput
    $registrationDocumentUrl: String
    $brand: ID!
    $plate: String
    $carType: ID!
    $manufacturingYear: Int
    $carOptions: CarOptionsInput
    $description: String
    $ride: Boolean!
    $delivery: Boolean!
    $model: ID!
  ) {
    addCarByAdmin(
      driverId: $driverId
      input: {
        color: $color
        registrationDocumentUrl: $registrationDocumentUrl
        plate: $plate
        pictures: $pictures
        insurance: $insurance
        carOptions: $carOptions
        manufacturingYear: $manufacturingYear
        carType: $carType
        brand: $brand
        model: $model
        description: $description
        ride: $ride
        delivery: $delivery
      }
    ) {
      _id
      plate
    }
  }
`;

const EDIT_CAR = gql`
  mutation updateCarByAdmin(
    $carId: ID!
    $color: ID!
    $pictures: pictures
    $insurance: InsuranceInput
    $registrationDocumentUrl: String
    $brand: ID!
    $plate: String
    $carType: ID!
    $manufacturingYear: Int
    $carOptions: CarOptionsInput
    $description: String
    $ride: Boolean!
    $delivery: Boolean!
    $model: ID!
  ) {
    updateCarByAdmin(
      carId: $carId
      input: {
        color: $color
        registrationDocumentUrl: $registrationDocumentUrl
        plate: $plate
        pictures: $pictures
        insurance: $insurance
        carOptions: $carOptions
        manufacturingYear: $manufacturingYear
        carType: $carType
        brand: $brand
        model: $model
        description: $description
        ride: $ride
        delivery: $delivery
      }
    ) {
      _id
      plate
    }
  }
`;

const GET_BRANDS = gql`
  query getCarBrands($limit: Int, $skip: Int, $name: String) {
    getCarBrands(
      pagination: { limit: $limit, skip: $skip }
      filters: { name: $name }
    ) {
      _id
      name
    }
  }
`;

const GET_MODELS = gql`
  query getCarModels($brand: ID) {
    getCarModels(filters: { brand: $brand }) {
      _id
      name
      brand {
        _id
      }
    }
  }
`;

const GET_COLORS = gql`
  query getCarColors {
    getCarColors {
      _id
      code
      name
    }
  }
`;

const GET_CAR_TYPES = gql`
  query getCarTypes {
    getCarTypes {
      _id
      type
    }
  }
`;

const GET_DRIVERS = gql`
  query getDriversByAdmin($limit: Int, $skip: Int, $fullName: String) {
    getDriversByAdmin(
      pagination: { limit: $limit, skip: $skip }
      filters: { fullName: $fullName }
    ) {
      _id
      fullName
      phoneNumber
    }
  }
`;

const AddCar = (props) => {
  const [brandSelectOptions, setBrandSelectOptions] = useState([]);

  const [
    getCarBrands,
    {
      loading: brandsLazyLoading,
      error: brandsLazyError,
      data: brandsLazyData,
      fetchMore: fetchMoreBrands,
    },
  ] = useLazyQuery(GET_BRANDS);

  useEffect(() => {
    if (brandsLazyData) {
      setBrandSelectOptions(
        Array.from(brandsLazyData.getCarBrands, (brand) => ({
          name: brand.name,
          value: brand._id,
        }))
      );
    }
  }, [, brandsLazyData, brandsLazyLoading]);

  const [fetchMoreSkipBrand, setFetchMoreSkipBrand] = useState(10);
  const [searchBrand, setSearchBrand] = useState(
    props.car ? props.car.brand.name : ""
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      getCarBrands({
        variables: {
          limit: 10,
          skip: 0,
          name: searchBrand ? searchBrand.trim() : "",
        },
      });
    }, 200);
    return () => clearTimeout(handler);
  }, [searchBrand]);

  const onLoadMoreBrands = () => {
    fetchMoreBrands({
      query: GET_BRANDS,
      variables: {
        limit: 10,
        skip: fetchMoreSkipBrand,
        name: searchBrand ? searchBrand : "",
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setBrandSelectOptions((brandSelectOptions) => [
          ...brandSelectOptions,
          ...Array.from(fetchMoreResult.getCarBrands, (brand) => ({
            name: brand.name,
            value: brand._id,
          })),
        ]);
      },
    });
    setFetchMoreSkipBrand((fetchMoreSkipBrand) => fetchMoreSkipBrand + 10);
  };

  const handleScrollBrands = (e) => {
    let element = e.target;
    if (
      Number(element.scrollHeight).toFixed(0) -
        Number(element.scrollTop).toFixed(0) ==
      Number(element.clientHeight).toFixed(0)
    ) {
      onLoadMoreBrands();
    }
  };

  const manufacturingYearOptions = [];
  const [disableButton, setDisableButton] = useState(false);

  for (let i = 1920; i < moment().year() + 1; i++) {
    manufacturingYearOptions.push(i);
  }

  const [addModal, setAddModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [warning, setWarning] = useState("");
  const [warningModal, setWarningModal] = useState(false);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const [spinnerIndex, setSpinnerIndex] = useState();

  const [showBox, setShowBox] = useState(false);

  const [driverSelectOptions, setDriverSelectOptions] = useState([]);

  const [driversReactSelectOptions, setDriverReactSelectOptions] = useState([]);

  const {
    loading: colorsLoading,
    error: colorsError,
    data: colorsData,
  } = useQuery(GET_COLORS, {
    fetchPolicy: "network-only",
  });

  const {
    loading: carTypesLoading,
    error: carTypesError,
    data: carTypesData,
  } = useQuery(GET_CAR_TYPES, {
    fetchPolicy: "network-only",
  });

  const {
    loading: driversLoading,
    error: driversError,
    data: driversData,
  } = useQuery(GET_DRIVERS, {
    variables: { limit: 10, skip: 0, fullName: "" },
    fetchPolicy: "network-only",
  });

  const [
    getDriversByAdmin,
    {
      loading: driversLazyLoading,
      error: driversLazyError,
      data: driversLazyData,
      fetchMore,
    },
  ] = useLazyQuery(GET_DRIVERS);

  const [fetchMoreSkip, setFetchMoreSkip] = useState(10);

  const onLoadMore = () => {
    fetchMore({
      query: GET_DRIVERS,
      variables: {
        limit: 10,
        skip: fetchMoreSkip,
        fullName: search ? search : "",
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        console.log("this is new results:", fetchMoreResult.getDriversByAdmin);
        setDriverSelectOptions((driverSelectOptions) => [
          ...driverSelectOptions,
          ...Array.from(fetchMoreResult.getDriversByAdmin, (driver) => ({
            name: driver.fullName + " - " + driver.phoneNumber,
            value: driver._id,
          })),
        ]);
      },
    });
    setFetchMoreSkip((fetchMoreSkip) => fetchMoreSkip + 10);
  };

  const [modelsVariables, setModelsVariables] = useState({
    brand: undefined,
  });

  const {
    loading: modelsLoading,
    error: modelsError,
    data: modelsData,
  } = useQuery(GET_MODELS, {
    variables: modelsVariables,
    fetchPolicy: "network-only",
  });

  const [modelId, setModelId] = useState(props.car ? props.car.model._id : "");
  const [noModelError, setNoModelError] = useState("");

  const [modelName, setModelName] = useState(
    props.car
      ? props.car.model.name
      : modelsData &&
          modelsData.getCarModels.length > 0 &&
          modelsData.getCarModels[0].name
  );

  useEffect(() => {
    if (brandId) {
      if (modelsData && modelsData.getCarModels.length > 0 && !props.car) {
        setModelName(modelsData.getCarModels[0].name);
      }
      if (modelsData && modelsData.getCarModels.length === 0 && !props.car) {
        setModelName("");
      }
    }
  }, [, modelsData]);

  useEffect(() => {
    if (modelsData && modelName) {
      setModelId(
        modelsData.getCarModels.find((model) => model.name === modelName)._id
      );
    }
  }, [, modelName]);

  const [modelOptions, setModelOptions] = useState(
    modelsData && Array.from(modelsData.getCarModels, (model) => model.name)
  );

  useEffect(() => {
    setModelsVariables((modelsVariables) => ({
      ...modelsVariables,
      brand: brandId ? brandId : undefined,
    }));
  }, [, modelsData, brandId]);

  const [colorId, setColorId] = useState(props.car ? props.car.color._id : "");

  const [colorName, setColorName] = useState(
    props.car
      ? props.car.color.name
      : colorsData &&
          Array.from(colorsData.getCarColors, (color) => color.name)[0]
  );

  useEffect(() => {
    if (colorsData && !props.car) {
      setColorName(colorsData.getCarColors[0].name);
    }
  }, [, colorsData]);

  useEffect(() => {
    if (colorsData) {
      setColorId(
        colorsData.getCarColors.find((color) => color.name === colorName)._id
      );
    }
  }, [, colorName]);

  const [carTypeId, setCarTypeId] = useState(
    props.car ? props.car.carType._id : ""
  );

  const [carTypeName, setCarTypeName] = useState(
    props.car
      ? props.car.carType.type
      : carTypesData &&
          Array.from(carTypesData.getCarTypes, (carType) => carType.type)[0]
  );

  useEffect(() => {
    if (carTypesData && !props.car) {
      setCarTypeName(carTypesData.getCarTypes[0].type);
    }
  }, [, carTypesData]);

  useEffect(() => {
    if (carTypesData) {
      setCarTypeId(
        carTypesData.getCarTypes.find((carType) => carType.type === carTypeName)
          ._id
      );
    }
  }, [, carTypeName]);

  const [description, setDescription] = useState(
    props.car ? props.car.description : ""
  );
  const [plate, setPlate] = useState(props.car ? props.car.plate : "");
  const [driverId, setDriverId] = useState("");
  const [useCase, setUseCase] = useState([]);
  const [brandId, setBrandId] = useState(props.car ? props.car.brand._id : "");
  const [brandError, setBrandError] = useState(false);
  const [carOptions, setCarOptions] = useState([]);
  const [insuranceImage, setInsuranceImage] = useState(
    props.car && props.car.carType.type !== "bike"
      ? props.car.insurance.insuranceImageUrl
      : ""
  );
  const [registrationImage, setRegistrationImage] = useState(
    props.car ? props.car.registrationDocumentUrl : ""
  );
  const [insuranceExpire, setInsuranceExpire] = useState(
    props.car && props.car.carType.type !== "bike"
      ? moment(props.car.insurance.expireDate).utc()
      : ""
  );
  const [manufacturingYear, setManufacturingYear] = useState(
    props.car
      ? props.car.manufacturingYear
      : manufacturingYearOptions[manufacturingYearOptions.length - 1]
  );
  const [firstInnerImage, setFirstInnerImage] = useState(
    props.car &&
      !(
        props.car.carType.type === "bike" ||
        props.car.carType.type === "motorcycle"
      )
      ? props.car.pictures.inner[0].url
      : ""
  );
  const [SecondInnerImage, setSecondInnerImage] = useState(
    props.car &&
      !(
        props.car.carType.type === "bike" ||
        props.car.carType.type === "motorcycle"
      ) &&
      props.car.pictures.inner[1]
      ? props.car.pictures.inner[1].url
      : ""
  );
  const [firstOuterImage, setFirstOuterImage] = useState(
    props.car ? props.car.pictures.outer[0].url : ""
  );
  const [secondOuterImage, setSecondOuterImage] = useState(
    props.car && props.car.pictures.outer[1]
      ? props.car.pictures.outer[1].url
      : ""
  );

  const [driverIdError, setDriverIdError] = useState(false);
  const [useCaseError, setUseCaseEror] = useState(false);
  const [plateError, setPlateError] = useState(false);
  const [insuranceExpireError, setInsuranceExpireError] = useState("");
  const [insuranceImageError, setInsuranceImageError] = useState(false);
  const [registrationImageError, setRegistrationImageError] = useState(false);
  const [innerImageError, setInnerImageError] = useState(false);
  const [secondInnerImageError, setSecondInnerImageError] = useState(false);
  const [outerImageError, setOuterImageError] = useState(false);
  const [secondOuterImageError, setSecondOuterImageError] = useState(false);

  useEffect(() => {
    setModelsVariables((modelsVariables) => ({
      // ...modelsVariables,
      brand: brandId,
    }));
  }, [brandId]);
  const useCases = ["ride", "delivery"];
  const options = [
    "inHurry",
    "orderingForSomeoneElse",
    "pet",
    "bagsWithMe",
    "reserved",
    "airConditioner",
    "welcomeSign",
    "driverAssistant",
    "withInfant",
    "waitTimesInMinutes",
  ];

  const optionsAlternate = {
    inHurry: "in hurry",
    orderingForSomeoneElse: "ordering for someone else",
    pet: "pet",
    bagsWithMe: "bags with me",
    reserved: "reserved",
    airConditioner: "air conditioner",
    welcomeSign: "welcome sign",
    driverAssistant: "driver assistant",
    withInfant: "with infant",
    waitTimesInMinutes: "waiting time (in minutes)",
  };

  useEffect(() => {
    if (props.car) {
      Object.keys(props.car.carOptions).map((item) => {
        if (props.car.carOptions[item] && item !== "tipValue") {
          setCarOptions((carOptions) => [...carOptions, item]);
        }
      });
    }
  }, [,]);

  useEffect(() => {
    if (props.car) {
      if (props.car.ride) {
        setUseCase((useCase) => [...useCase, "ride"]);
      }
      if (props.car.delivery) {
        setUseCase((useCase) => [...useCase, "delivery"]);
      }
    } else {
      setUseCase((useCase) => [...useCase, "delivery"]);
    }
  }, [,]);

  const handleCarOptionCheck = (e) => {
    const value = e.target.checked;
    const name = e.target.name;
    if (value) {
      setCarOptions([...carOptions, name]);
    } else {
      setCarOptions(carOptions.filter((eachOption) => eachOption !== name));
    }
  };
  const handleUseCaseCheck = (e) => {
    const value = e.target.checked;
    const name = e.target.name;
    if (value) {
      setUseCase([...useCase, name]);
    } else {
      setUseCase(useCase.filter((useCase) => useCase !== name));
    }
  };

  const [addCarByAdmin, { data: addData, error: addError }] = useMutation(
    ADD_CAR
  );

  const [
    uploadFile,
    { data: uploadData, error: uploadError, loading: uploadLoading },
  ] = useMutation(UPLOAD_FILE);

  const uploadInsuranceImage = (file) => {
    setSpinnerIndex(1);
    uploadFile({
      variables: { file: file.src.file, folderName: "advertisementImage" },
    }).then((res) => {
      setInsuranceImage(res.data.uploadFile.url);
    });
  };
  const uploadRegistrationImage = (file) => {
    setSpinnerIndex(2);
    uploadFile({
      variables: { file: file.src.file, folderName: "advertisementImage" },
    }).then((res) => {
      setRegistrationImage(res.data.uploadFile.url);
    });
  };
  const uploadFirstInnerImage = (file) => {
    setSpinnerIndex(3);
    uploadFile({
      variables: { file: file.src.file, folderName: "advertisementImage" },
    }).then((res) => {
      setFirstInnerImage(res.data.uploadFile.url);
    });
  };
  const uploadSecondInnerImage = (file) => {
    setSpinnerIndex(4);
    uploadFile({
      variables: { file: file.src.file, folderName: "advertisementImage" },
    }).then((res) => {
      setSecondInnerImage(res.data.uploadFile.url);
    });
  };
  const uploadFirstOuterImage = (file) => {
    setSpinnerIndex(5);
    uploadFile({
      variables: { file: file.src.file, folderName: "advertisementImage" },
    }).then((res) => {
      setFirstOuterImage(res.data.uploadFile.url);
    });
  };
  const uploadSecondOuterImage = (file) => {
    setSpinnerIndex(6);
    uploadFile({
      variables: { file: file.src.file, folderName: "advertisementImage" },
    }).then((res) => {
      setSecondOuterImage(res.data.uploadFile.url);
    });
  };

  const [updateCarByAdmin, { editData, editError }] = useMutation(EDIT_CAR);

  const handleEnter = (e) => {
    if (e.charCode === 13) {
      onSubmit();
    }
  };

  const [search, setSearch] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      getDriversByAdmin({
        variables: {
          limit: 10,
          skip: 0,
          fullName: search ? search.trim() : "",
        },
      });
    }, 200);
    return () => clearTimeout(handler);
  }, [search]);

  // block navigation
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [shouldLeavePage, setShouldLeavePage] = useState(false);

  useEffect(() => {
    if (shouldLeavePage) {
      window.onbeforeunload = undefined;
    } else {
      if (shouldBlockNavigation) {
        window.onbeforeunload = () => true;
      } else {
        window.onbeforeunload = undefined;
      }
    }
  }, [shouldBlockNavigation]);

  useEffect(() => {
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // check for changes in edit and add form, to warn while leaving the page
  useEffect(() => {
    if (props.car) {
      if (
        plate === props.car.plate &&
        description === props.car.description &&
        colorId === props.car.color._id &&
        modelId === props.car.model._id &&
        brandId === props.car.brand._id &&
        carTypeId === props.car.carType._id &&
        registrationImage === props.car.registrationDocumentUrl &&
        (!props.car.insurance.insuranceImageUrl ||
          (props.car.insurance.insuranceImageUrl &&
            insuranceImage === props.car.insurance.insuranceImageUrl)) &&
        (props.car.carType.type === "bike" ||
          props.car.carType.type === "motorcycle" ||
          ((props.car.pictures.inner.length === 0 && !firstInnerImage) ||
            (props.car.pictures.inner.length > 0 &&
              props.car.pictures.inner[0].url &&
              firstInnerImage === props.car.pictures.inner[0].url))) &&
        (props.car.carType.type === "bike" ||
          props.car.carType.type === "motorcycle" ||
          ((props.car.pictures.inner.length === 1 && !SecondInnerImage) ||
            (props.car.pictures.inner.length > 1 &&
              props.car.pictures.inner[1].url &&
              SecondInnerImage === props.car.pictures.inner[1].url))) &&
        ((props.car.pictures.outer.length === 0 && !firstOuterImage) ||
          (props.car.pictures.outer.length > 0 &&
            props.car.pictures.outer[0].url &&
            firstOuterImage === props.car.pictures.outer[0].url)) &&
        ((props.car.pictures.outer.length === 1 && !secondOuterImage) ||
          (props.car.pictures.outer.length > 1 &&
            props.car.pictures.outer[1].url &&
            secondOuterImage === props.car.pictures.outer[1].url))
      ) {
        setShouldBlockNavigation(false);
      } else {
        setShouldBlockNavigation(true);
      }
    } else {
      if (
        driverId ||
        plate ||
        insuranceImage ||
        registrationImage ||
        firstInnerImage ||
        SecondInnerImage ||
        firstOuterImage ||
        secondOuterImage ||
        description ||
        insuranceExpire
      ) {
        setShouldBlockNavigation(true);
      } else {
        setShouldBlockNavigation(false);
      }
    }
  });

  const onCancel = () => {
    window.onbeforeunload = undefined;
    setShouldBlockNavigation(false);
    setShouldLeavePage(true);
    window.location.href = endPoint + "/superAdmin/vehicles";
  };

  const onSubmit = () => {
    if (!insuranceImage) {
      if (carTypeName !== "bike") {
        setInsuranceImageError(true);
      }
    } else {
      setInsuranceImageError(false);
    }

    if (!insuranceExpire) {
      setInsuranceExpireError("expire date can't be empty*");
    } else {
      if (
        moment(insuranceExpire, "MM/DD/YYYY", true).isValid() ||
        moment(insuranceExpire, "MM-DD-YYYY", true).isValid()
      ) {
        if (moment(insuranceExpire) < moment().add(3, "months")) {
          setInsuranceExpireError(
            "expire date must be at least 3 month later from now*"
          );
        } else {
          setInsuranceExpireError("");
        }
      } else {
        setInsuranceExpireError("invalid date");
      }
    }
    if (!driverId && !props.car) {
      setDriverIdError(true);
    } else {
      setDriverIdError(false);
    }
    if (!brandId) {
      setBrandError(true);
    } else {
      setBrandError(false);
    }
    if (!plate) {
      setPlateError(true);
    } else {
      setPlateError(false);
    }
    if (!firstInnerImage) {
      if (!(carTypeName === "bike" || carTypeName === "motorcycle")) {
        setInnerImageError(true);
      }
    } else {
      setInnerImageError(false);
    }
    if (!firstOuterImage) {
      setOuterImageError(true);
    } else {
      setOuterImageError(false);
    }
    if (!registrationImage) {
      setRegistrationImageError(true);
    } else {
      setRegistrationImageError(false);
    }
    if (useCase.length === 0) {
      setUseCaseEror(true);
    } else {
      setUseCaseEror(false);
    }

    if (
      plate &&
      brandId &&
      (props.car || driverId) &&
      (moment(insuranceExpire, "MM/DD/YYYY", true).isValid() ||
        moment(insuranceExpire, "MM-DD-YYYY", true).isValid()) &&
      useCase.length > 0 &&
      registrationImage &&
      (carTypeName === "bike" ||
        (insuranceImage &&
          insuranceExpire &&
          moment(insuranceExpire) >= moment().add(3, "months"))) &&
      (carTypeName === "bike" ||
        carTypeName === "motorcycle" ||
        firstInnerImage) &&
      firstOuterImage
    ) {
      setDisableButton(true);
      window.onbeforeunload = undefined;
      setShouldBlockNavigation(false);
      setShouldLeavePage(true);
      if (props.car) {
        updateCarByAdmin({
          variables: {
            carId: props.car._id,
            plate,
            color: colorId,
            brand: brandId,
            model: modelId,
            manufacturingYear: parseInt(manufacturingYear),
            insurance:
              carTypeName === "bike"
                ? {}
                : {
                    insuranceImageUrl: insuranceImage,
                    expireDate: moment(insuranceExpire).utc(),
                  },
            pictures: {
              inner:
                carTypeName === "bike" || carTypeName === "motorcycle"
                  ? []
                  : Array.from(
                      [firstInnerImage, SecondInnerImage].filter(
                        (item) =>
                          item !== "" && item !== undefined && item !== null
                      ),
                      (image) => ({ url: image })
                    ),
              outer: Array.from(
                [firstOuterImage, secondOuterImage].filter(
                  (item) => item !== "" && item !== undefined && item !== null
                ),
                (image) => ({ url: image })
              ),
            },
            registrationDocumentUrl: registrationImage && registrationImage,
            carType: carTypeId,
            description,
            ride: useCase.includes("ride"),
            delivery: useCase.includes("delivery"),
            carOptions: {
              inHurry: carOptions.includes("inHurry"),
              orderingForSomeoneElse: carOptions.includes(
                "orderingForSomeoneElse"
              ),
              pet: carOptions.includes("pet"),
              bagsWithMe: carOptions.includes("bagsWithMe"),
              reserved: carOptions.includes("reserved"),
              airConditioner: carOptions.includes("airConditioner"),
              welcomeSign: carOptions.includes("welcomeSign"),
              driverAssistant: carOptions.includes("driverAssistant"),
              withInfant: carOptions.includes("withInfant"),
              waitTimesInMinutes: carOptions.includes("waitTimesInMinutes"),
              tipValue: false,
            },
          },
        })
          .then((res) => {
            if (moment().add(6, "months") > moment(insuranceExpire)) {
              setWarning(
                "Warning! passport expires in 6 month, please update it."
              );
              setWarningModal(true);
            } else {
              window.location.href = endPoint + "/superAdmin/vehicles";
            }
          })
          .catch((err) => {
            setDisableButton(false);
            setShouldBlockNavigation(true);
            console.log(err);
            if (err.graphQLErrors && err.graphQLErrors.length > 0) {
              setErrorText(err.graphQLErrors[0].message);
              setErrorModal(true);
            }
          });
      } else {
        addCarByAdmin({
          variables: {
            driverId,
            plate,
            color: colorId,
            brand: brandId,
            model: modelId,
            manufacturingYear: parseInt(manufacturingYear),
            insurance:
              carTypeName === "bike"
                ? {}
                : {
                    insuranceImageUrl: insuranceImage,
                    expireDate: moment(insuranceExpire).utc(),
                  },
            pictures: {
              inner:
                carTypeName === "bike" || carTypeName === "motorcycle"
                  ? []
                  : Array.from(
                      [firstInnerImage, SecondInnerImage].filter(
                        (item) =>
                          item !== "" && item !== undefined && item !== null
                      ),
                      (image) => ({ url: image })
                    ),
              outer: Array.from(
                [firstOuterImage, secondOuterImage].filter(
                  (item) => item !== "" && item !== undefined && item !== null
                ),
                (image) => ({ url: image })
              ),
            },
            registrationDocumentUrl: registrationImage && registrationImage,
            carType: carTypeId,
            description,
            ride: useCase.includes("ride"),
            delivery: useCase.includes("delivery"),
            carOptions: {
              inHurry: carOptions.includes("inHurry"),
              orderingForSomeoneElse: carOptions.includes(
                "orderingForSomeoneElse"
              ),
              pet: carOptions.includes("pet"),
              bagsWithMe: carOptions.includes("bagsWithMe"),
              reserved: carOptions.includes("reserved"),
              airConditioner: carOptions.includes("airConditioner"),
              welcomeSign: carOptions.includes("welcomeSign"),
              driverAssistant: carOptions.includes("driverAssistant"),
              withInfant: carOptions.includes("withInfant"),
              waitTimesInMinutes: carOptions.includes("waitTimesInMinutes"),
              tipValue: false,
            },
          },
        })
          .then((res) => {
            if (moment().add(6, "months") > moment(insuranceExpire)) {
              setWarning(
                "Warning! passport expires in 6 month, please update it."
              );
              setWarningModal(true);
            } else {
              window.location.href = endPoint + "/superAdmin/vehicles";
            }
          })
          .catch((err) => {
            setDisableButton(false);
            setShouldBlockNavigation(true);
            console.log(err);
            if (err.graphQLErrors && err.graphQLErrors.length > 0) {
              setErrorText(err.graphQLErrors[0].message);
              setErrorModal(true);
            }
          });
      }
    }
  };

  useEffect(() => {
    if (driversLazyData) {
      setDriverSelectOptions(
        Array.from(driversLazyData.getDriversByAdmin, (driver) => ({
          name: driver.fullName + " - " + driver.phoneNumber,
          value: driver._id,
        }))
      );
    }
  }, [driversLazyLoading, driversLazyData]);

  const handleScroll = (e) => {
    let element = e.target;
    if (
      Number(element.scrollHeight).toFixed(0) -
        Number(element.scrollTop).toFixed(0) ==
      Number(element.clientHeight).toFixed(0)
    ) {
      onLoadMore();
    }
  };

  if (colorsLoading || carTypesLoading)
    return (
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <BeatLoader
          css={css`
            display: block;
            margin: 0 auto;
            border-color: red;
          `}
          size={15}
          margin={2}
          color={"gray"}
          loading={true}
        />
      </div>
    );

  return (
    <React.Fragment>
      <div className={style.mainDiv}>
        <Prompt
          when={shouldBlockNavigation}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <div className={style.leftSide}>
              {!props.car && (
                <div style={{ marginTop: "40px" }}>
                  <GridContainer>
                    <GridItem xs={12} sm={5} md={5}>
                      <div className={style.label}>Driver:</div>
                    </GridItem>
                    <GridItem xs={12} sm={7} md={7}>
                      {driverIdError && (
                        <div className={style.selectSearchError}>
                          driver can't be empty*
                        </div>
                      )}
                      <div>
                        <SelectSearch
                          options={driverSelectOptions}
                          value={driverId}
                          name=""
                          search
                          getOptions={(searchWord) => {
                            setSearch(searchWord);
                          }}
                          onScroll={(e) => handleScroll(e)}
                          placeholder="Choose driver"
                          onChange={(e) => {
                            setDriverId(e);
                          }}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              )}

              {carTypesData && (
                <div className={style.dropdownRow}>
                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                      <div className={style.label}>Car type:</div>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <div className={style.dropdownContainer}>
                        <CustomDropdown
                          hoverColor="primary"
                          buttonText={carTypeName}
                          onClick={(e) => setCarTypeName(e)}
                          dropdownList={
                            carTypesData &&
                            Array.from(
                              carTypesData.getCarTypes,
                              (carType) => carType.type
                            )
                          }
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              )}
              {colorsData && (
                <div className={style.dropdownRow}>
                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                      <div className={style.label}>Color:</div>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <div className={style.dropdownContainer}>
                        <CustomDropdown
                          hoverColor="primary"
                          buttonText={colorName}
                          onClick={(e) => setColorName(e)}
                          dropdownList={
                            colorsData &&
                            Array.from(
                              colorsData.getCarColors,
                              (color) => color.name
                            )
                          }
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              )}

              <div className={style.dropdownRow}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <div className={style.label}>Brand:</div>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <div className={style.dropdownContainer}>
                      {brandError && (
                        <div className={style.selectSearchError}>
                          brand can't be empty*
                        </div>
                      )}
                      <div>
                        <SelectSearch
                          options={brandSelectOptions}
                          value={brandId}
                          onScroll={(e) => handleScrollBrands(e)}
                          getOptions={(searchWord) => {
                            setSearchBrand(searchWord);
                          }}
                          name=""
                          search
                          placeholder="Choose brand"
                          onChange={(value) => setBrandId(value)}
                        />
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>

              {modelsData && (
                <div className={style.dropdownRow}>
                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                      <div className={style.label}>Model:</div>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <div className={style.dropdownContainer}>
                        <CustomDropdown
                          hoverColor="primary"
                          buttonText={modelName}
                          onClick={(e) => setModelName(e)}
                          disabled
                          dropdownList={
                            modelsData &&
                            Array.from(
                              modelsData.getCarModels,
                              (model) => model.name
                            )
                          }
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              )}
              {carTypeName !== "bike" && (
                <div className={style.imageRow}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={5}>
                      <div className={style.imageLabel}>Insurance photo:</div>
                      <div className={style.imageHint}>
                        (upload an image or pdf less than 10MB)
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={7}>
                      <div className={style.imageContainer}>
                        <Files
                          maxSize="2mb"
                          multiple={false}
                          convertToBase64={true}
                          accept={[
                            "image/jpg",
                            "image/jpeg",
                            "image/png",
                            "application/pdf",
                          ]}
                          onSuccess={(file) => uploadInsuranceImage(file[0])}
                          onError={(errors) => setInsuranceImageError(true)}
                        >
                          {({
                            browseFiles,
                            getDropZoneProps,
                            getLabelProps,
                          }) => (
                            <div style={{ textAlign: "right" }}>
                              {insuranceImage &&
                                (insuranceImage.match(/\.pdf$/) ? (
                                  <a
                                    title="open file"
                                    target="_blank"
                                    href={`${graphqlUrl}/${insuranceImage}`}
                                  >
                                    <InsertDriveFileIcon
                                      style={{
                                        color: "gray",
                                        fontSize: "50px",
                                      }}
                                    />
                                  </a>
                                ) : (
                                  <div>
                                    <img
                                      style={{
                                        width: "200px",
                                        height: "200px",
                                      }}
                                      alt="advertisement photo"
                                      src={`${graphqlUrl}${insuranceImage}`}
                                    />
                                  </div>
                                ))}
                              {uploadLoading && spinnerIndex === 1 && (
                                <CircularProgress style={{ color: "gray" }} />
                              )}
                              <div
                                {...getDropZoneProps({
                                  className: "myDropZone",
                                })}
                              />
                              <Button onClick={browseFiles} color="whiteButton">
                                {`${
                                  insuranceImage ? "edit" : "add"
                                } photo or pdf`}
                              </Button>
                              {insuranceImageError && (
                                <p className={style.imageError}>
                                  please enter image file less than 2mb
                                </p>
                              )}
                            </div>
                          )}
                        </Files>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              )}
              <div className={style.imageRow}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={5}>
                    <div className={style.imageLabel}>
                      Registration document:
                    </div>
                    <div className={style.imageHint}>
                      (upload an image or pdf less than 10MB)
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={7}>
                    <div className={style.imageContainer}>
                      <Files
                        maxSize="2mb"
                        multiple={false}
                        convertToBase64={true}
                        accept={[
                          "image/jpg",
                          "image/jpeg",
                          "image/png",
                          "application/pdf",
                        ]}
                        onSuccess={(file) => uploadRegistrationImage(file[0])}
                      >
                        {({ browseFiles, getDropZoneProps, getLabelProps }) => (
                          <div style={{ textAlign: "right" }}>
                            {registrationImage &&
                              (registrationImage.match(/\.pdf$/) ? (
                                <a
                                  title="open file"
                                  target="_blank"
                                  href={`${graphqlUrl}/${registrationImage}`}
                                >
                                  <InsertDriveFileIcon
                                    style={{ color: "gray", fontSize: "50px" }}
                                  />
                                </a>
                              ) : (
                                <div>
                                  <img
                                    style={{ width: "200px", height: "200px" }}
                                    alt="advertisement photo"
                                    src={`${graphqlUrl}${registrationImage}`}
                                  />
                                </div>
                              ))}
                            {uploadLoading && spinnerIndex === 2 && (
                              <CircularProgress style={{ color: "gray" }} />
                            )}
                            <div
                              {...getDropZoneProps({ className: "myDropZone" })}
                            />
                            <Button onClick={browseFiles} color="whiteButton">
                              {`${
                                registrationImage ? "edit" : "add"
                              } photo or pdf`}
                            </Button>
                            {registrationImageError && (
                              <p className={style.imageError}>
                                please enter image file less than 2mb
                              </p>
                            )}
                          </div>
                        )}
                      </Files>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
              {carTypeName !== "bike" && (
                <div
                  onKeyPress={(e) => handleEnter(e)}
                  className={style.dateRow}
                >
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={5}>
                      <div className={style.dateLabel}>Insurance expire:</div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={7}>
                      {insuranceExpireError && (
                        <div
                          style={{ lineHeight: "18px" }}
                          className={style.dateError}
                        >
                          {insuranceExpireError}
                        </div>
                      )}
                      <Datetime
                        timeFormat={false}
                        disableOnClickOutside
                        value={insuranceExpire}
                        closeOnSelect
                        onChange={(date) => setInsuranceExpire(date)}
                        inputProps={{
                          placeholder: "insurance expire",
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </div>
              )}
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <div onKeyPress={(e) => handleEnter(e)} className={style.rightSide}>
              <div className={style.row}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={5}>
                    <div className={style.label}>Plate:</div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={7}>
                    <CustomInput
                      value={plate}
                      onChange={(e) => setPlate(e.target.value)}
                      error={plateError}
                      labelText={plateError ? "plate can't be empty*" : "plate"}
                      id="float"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </div>
              <div className={style.row}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={5}>
                    <div className={style.label}>Description:</div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={7}>
                    <CustomInput
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      labelText="description"
                      id="float"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </div>
              <div className={style.dropdownRow}>
                <GridContainer>
                  <GridItem xs={12} sm={8} md={8}>
                    <div className={style.label}>Manufacturing year:</div>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <div className={style.dropdownContainer}>
                      <CustomDropdown
                        hoverColor="primary"
                        buttonText={manufacturingYear}
                        onClick={(e) => setManufacturingYear(e)}
                        dropdownList={manufacturingYearOptions}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
              <div className={style.checkboxRow}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={2} lg={12}>
                    <div className={style.checkboxLabel}>Options:</div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={10} lg={12}>
                    <GridContainer>
                      {options.map((option) => (
                        <GridItem key={option} xs={12} sm={6} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="default"
                                name={option}
                                checked={carOptions.includes(option)}
                                onChange={(e) => handleCarOptionCheck(e)}
                              />
                            }
                            label={optionsAlternate[option]}
                          />
                        </GridItem>
                      ))}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </div>
              <div className={style.useCaseCheckboxRow}>
                <GridContainer>
                  {/* <GridItem xs={12} sm={4} md={2} lg={12}>
                    <div className={style.checkboxLabel}>Use case:</div>
                  </GridItem> */}
                  <GridItem xs={12} sm={8} md={10} lg={12}>
                    <GridContainer>
                      {useCases.map((item) => (
                        <GridItem key={item} xs={12} sm={6} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="default"
                                name={item}
                                checked={useCase.includes(item)}
                                onChange={(e) => handleUseCaseCheck(e)}
                              />
                            }
                            label={item}
                          />
                        </GridItem>
                      ))}
                    </GridContainer>
                  </GridItem>
                  {useCaseError && (
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <div className={style.useCaseError}>
                        please select at least one use case*
                      </div>
                    </GridItem>
                  )}
                </GridContainer>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div onKeyPress={(e) => handleEnter(e)} className={style.downSide}>
              <GridContainer>
                {!(carTypeName === "bike" || carTypeName === "motorcycle") && (
                  <GridItem xs={12} sm={12} md={12} lg={6}>
                    <div className={style.imageRow}>
                      <GridContainer>
                        <GridItem xs={12} sm={4} md={4} lg={12}>
                          <div className={style.imageLabel}>
                            Interior images:
                          </div>
                          <div className={style.imageHint}>
                            (upload an image less than 10MB)
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8} lg={12}>
                          <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={12}>
                              <div className={style.imageContainer}>
                                <Files
                                  maxSize="2mb"
                                  multiple={false}
                                  convertToBase64={true}
                                  accept={[
                                    "image/jpg",
                                    "image/jpeg",
                                    "image/png",
                                  ]}
                                  onSuccess={(file) =>
                                    uploadFirstInnerImage(file[0])
                                  }
                                >
                                  {({
                                    browseFiles,
                                    getDropZoneProps,
                                    getLabelProps,
                                  }) => (
                                    <div style={{ textAlign: "right" }}>
                                      {firstInnerImage && (
                                        <div>
                                          <img
                                            style={{
                                              width: "200px",
                                              height: "200px",
                                            }}
                                            alt="advertisement photo"
                                            src={`${graphqlUrl}${firstInnerImage}`}
                                          />
                                        </div>
                                      )}
                                      {uploadLoading && spinnerIndex === 3 && (
                                        <CircularProgress
                                          style={{ color: "gray" }}
                                        />
                                      )}
                                      <div
                                        {...getDropZoneProps({
                                          className: "myDropZone",
                                        })}
                                      />
                                      <Button
                                        onClick={browseFiles}
                                        color="whiteButton"
                                      >
                                        {`${
                                          firstInnerImage ? "edit" : "add"
                                        } photo`}
                                      </Button>
                                      {innerImageError && (
                                        <p className={style.imageError}>
                                          please enter image file less than 10mb
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Files>
                              </div>
                            </GridItem>
                            {firstInnerImage && (
                              <GridItem xs={12} sm={6} md={6} lg={12}>
                                <div className={style.imageContainer}>
                                  <Files
                                    maxSize="2mb"
                                    multiple={false}
                                    convertToBase64={true}
                                    accept={[
                                      "image/jpg",
                                      "image/jpeg",
                                      "image/png",
                                    ]}
                                    onSuccess={(file) =>
                                      uploadSecondInnerImage(file[0])
                                    }
                                  >
                                    {({
                                      browseFiles,
                                      getDropZoneProps,
                                      getLabelProps,
                                    }) => (
                                      <div style={{ textAlign: "right" }}>
                                        {SecondInnerImage && (
                                          <div>
                                            <img
                                              style={{
                                                width: "200px",
                                                height: "200px",
                                              }}
                                              alt="advertisement photo"
                                              src={`${graphqlUrl}${SecondInnerImage}`}
                                            />
                                          </div>
                                        )}
                                        {uploadLoading &&
                                          spinnerIndex === 4 && (
                                            <CircularProgress
                                              style={{ color: "gray" }}
                                            />
                                          )}
                                        <div
                                          {...getDropZoneProps({
                                            className: "myDropZone",
                                          })}
                                        />
                                        {SecondInnerImage && (
                                          <Button
                                            onClick={() =>
                                              setSecondInnerImage("")
                                            }
                                            size="sm"
                                            justIcon
                                            round
                                            color="danger"
                                          >
                                            <CloseIcon />
                                          </Button>
                                        )}
                                        <Button
                                          onClick={browseFiles}
                                          color="whiteButton"
                                        >
                                          {`${
                                            SecondInnerImage ? "edit" : "add"
                                          } photo`}
                                        </Button>
                                        {secondInnerImageError && (
                                          <p className={style.imageError}>
                                            please enter image file less than
                                            10MB
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </Files>
                                </div>
                              </GridItem>
                            )}
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </GridItem>
                )}
                <GridItem xs={12} sm={12} md={12} lg={6}>
                  <div className={style.imageRow}>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4} lg={12}>
                        <div className={style.imageLabel}>Exterior images:</div>
                        <div className={style.imageHint}>
                          (upload an image less than 10MB)
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={8} lg={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6} lg={12}>
                            <div className={style.imageContainer}>
                              <Files
                                maxSize="2mb"
                                multiple={false}
                                convertToBase64={true}
                                accept={[
                                  "image/jpg",
                                  "image/jpeg",
                                  "image/png",
                                ]}
                                onSuccess={(file) =>
                                  uploadFirstOuterImage(file[0])
                                }
                              >
                                {({
                                  browseFiles,
                                  getDropZoneProps,
                                  getLabelProps,
                                }) => (
                                  <div style={{ textAlign: "right" }}>
                                    {firstOuterImage && (
                                      <div>
                                        <img
                                          style={{
                                            width: "200px",
                                            height: "200px",
                                          }}
                                          alt="advertisement photo"
                                          src={`${graphqlUrl}${firstOuterImage}`}
                                        />
                                      </div>
                                    )}
                                    {uploadLoading && spinnerIndex === 5 && (
                                      <CircularProgress
                                        style={{ color: "gray" }}
                                      />
                                    )}
                                    <div
                                      {...getDropZoneProps({
                                        className: "myDropZone",
                                      })}
                                    />
                                    <Button
                                      onClick={browseFiles}
                                      color="whiteButton"
                                    >
                                      {`${
                                        firstOuterImage ? "edit" : "add"
                                      } photo`}
                                    </Button>
                                    {outerImageError && (
                                      <p className={style.imageError}>
                                        please enter image file less than 10MB
                                      </p>
                                    )}
                                  </div>
                                )}
                              </Files>
                            </div>
                          </GridItem>
                          {firstOuterImage && (
                            <GridItem xs={12} sm={6} md={6} lg={12}>
                              <div className={style.imageContainer}>
                                <Files
                                  maxSize="2mb"
                                  multiple={false}
                                  convertToBase64={true}
                                  accept={[
                                    "image/jpg",
                                    "image/jpeg",
                                    "image/png",
                                  ]}
                                  onSuccess={(file) =>
                                    uploadSecondOuterImage(file[0])
                                  }
                                >
                                  {({
                                    browseFiles,
                                    getDropZoneProps,
                                    getLabelProps,
                                  }) => (
                                    <div style={{ textAlign: "right" }}>
                                      {secondOuterImage && (
                                        <div>
                                          <img
                                            style={{
                                              width: "200px",
                                              height: "200px",
                                            }}
                                            alt="advertisement photo"
                                            src={`${graphqlUrl}${secondOuterImage}`}
                                          />
                                        </div>
                                      )}
                                      {uploadLoading && spinnerIndex === 6 && (
                                        <CircularProgress
                                          style={{ color: "gray" }}
                                        />
                                      )}
                                      <div
                                        {...getDropZoneProps({
                                          className: "myDropZone",
                                        })}
                                      />
                                      {secondOuterImage && (
                                        <Button
                                          onClick={() =>
                                            setSecondOuterImage("")
                                          }
                                          size="sm"
                                          justIcon
                                          round
                                          color="danger"
                                        >
                                          <CloseIcon />
                                        </Button>
                                      )}
                                      <Button
                                        onClick={browseFiles}
                                        color="whiteButton"
                                      >
                                        {`${
                                          secondOuterImage ? "edit" : "add"
                                        } photo`}
                                      </Button>
                                      {secondOuterImageError && (
                                        <p className={style.imageError}>
                                          please enter image file less than 10mb
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Files>
                              </div>
                            </GridItem>
                          )}
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div className={style.buttonContainer}>
              <Button color="whiteButton" onClick={onCancel}>
                Cancel
              </Button>

              <Button
                disabled={uploadLoading || disableButton}
                color="yellow"
                onClick={onSubmit}
              >
                Submit
              </Button>
            </div>
          </GridItem>
        </GridContainer>
        <Dialog open={addModal} transition={Transition}>
          <DialogContent id="modal-slide-description">
            <Modal
              close={() => setAddModal(false)}
              type="notif"
              text="Car added succesfully."
            />
          </DialogContent>
        </Dialog>
        <Dialog open={errorModal} transition={Transition}>
          <DialogContent id="modal-slide-description">
            <Modal
              close={() => setErrorModal(false)}
              type="notif"
              text={
                errorText
                  ? errorText
                  : "Error ! Please Fill All Inputs Correctly And Try Again ."
              }
            />
          </DialogContent>
        </Dialog>
        <Dialog open={warningModal} transition={Transition}>
          <DialogContent id="modal-slide-description">
            <Modal
              close={() => {
                window.location.href = endPoint + "/superAdmin/vehicles";
              }}
              type="notif"
              text={warning}
            />
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default AddCar;
