import React, { useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import Close from '@material-ui/icons/Close';
import Modal from 'commonComponents/modal/Modal';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.js';
import Badge from 'components/Badge/Badge.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import style from './OrderDetails.module.scss';

const REJECT = gql`
	mutation rejectOrderByShopAdmin($orderId: ID!, $rejectedFor: OrderRejectedFor!) {
		rejectOrderByShopAdmin(orderId: $orderId, rejectedFor: $rejectedFor) {
			_id
		}
	}
`;

const RejectOrder = ({ orderId, close, type }) => {
	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const grayTheme = {
		checkboxColor: 'gray',
		primaryColor: 'primary',
	};

	let theme = orangeTheme;
	if (type === 'Purchase') {
		theme = greenTheme;
	} else if (type === 'Restaurant') {
		theme = orangeTheme;
	} else if (type === 'superAdmin') {
		theme = grayTheme;
	}

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const reasonOptions = [
		'packingDamaged',
		'damagedForPacking',
		'differentProduct',
		'noReceiver',
		'deliveryWasNotAccepted',
	];

	const altReasons = {
		'packing damaged': 'packingDamaged',
		'damaged for packing': 'damagedForPacking',
		'different product': 'differentProduct',
		'no receiver': 'noReceiver',
		'delivery was not accepted': 'deliveryWasNotAccepted',
	}

	const [reason, setReason] = useState(Object.keys(altReasons)[0]);

	const [rejectOrderByShopAdmin, { data, error, loading }] = useMutation(REJECT);

	const onSubmit = () => {
		rejectOrderByShopAdmin({
			variables: {
				orderId,
				rejectedFor: altReasons[reason],
			},
		})
			.then((res) => {
				close();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setErrorText(err.graphQLErrors[0].message);
					setErrorModal(true);
				}
			});
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Reject</h4>
				</div>
			</div>
			<div className={style.infoContainer}>
				<div className={style.rowContainer}>
					<div
						className={style.titleDiv}
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-around',
						}}
					>
						Reason:
					</div>
					<div className={style.dropdownWrapper}>
						<CustomDropdown
							hoverColor={theme.primaryColor}
							buttonText={reason}
							onClick={(e) => setReason(e)}
							dropdownList={Object.keys(altReasons)}
						/>
					</div>
				</div>
			</div>
			<div className={style.footerShow}>
				<Button onClick={close}>Cancel</Button>
				<Button onClick={onSubmit} color={theme.primaryColor}>
					Reject
				</Button>
			</div>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setErrorModal(false)} type="notif" text={errorText ? errorText : 'Error !'} />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default RejectOrder;
