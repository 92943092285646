import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Modal from 'commonComponents/modal/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import style from './Signup.module.scss';
import { endPoint } from 'utils/config';

const SIGNUP = gql`
	mutation shopAdminSignUp(
		$fullName: String!
		$email: String!
		$password: String!
		$phoneNumber: String!
		$phoneSignUpCode: String!
	) {
		shopAdminSignUp(
			input: {
				fullName: $fullName
				email: $email
				password: $password
				phoneNumber: $phoneNumber
				phoneSignUpCode: $phoneSignUpCode
			}
		) {
			_id
			fullName
			email
			token {
				accessToken
				refreshToken
			}
			shop {
				_id
			}
		}
	}
`;

const Signup = (props) => {
	const types = ['Restaurant', 'Grocery'];
	const deliveries = ['Spark', 'Personal'];

	const [disableButton, setDisableButton] = useState(false);

	const [email, setEmail] = useState('');
	const [firstname, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [visible, setVisible] = useState(false);
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [firstNameError, setFirstNameError] = useState(false);
	const [lastNameError, setLastNameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState('');
	const [confirmPasswordError, setConfirmPasswordError] = useState('');

	const [addModal, setAddModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onCloseError = () => {
		setAddModal(false);
		window.location.href = endPoint + '/login';
	};

	const [shopAdminSignUp, { data, error }] = useMutation(SIGNUP);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	const onSubmit = () => {
		if (!firstname) {
			setFirstNameError(true);
		} else {
			setFirstNameError(false);
		}
		if (!lastName) {
			setLastNameError(true);
		} else {
			setLastNameError(false);
		}
		if (!email) {
			setEmailError(true);
		} else {
			setEmailError(false);
		}

		if (!password) {
			setPasswordError("password can't be empty*");
		} else {
			if (password.length > 7) {
				setPasswordError('');
			} else {
				setPasswordError('enter at least 8 characters');
			}
		}

		if (!confirmPassword) {
			setConfirmPasswordError("confirm can't be empty*");
		} else {
			if (password && password === confirmPassword) {
				setConfirmPasswordError('');
			} else {
				setConfirmPasswordError("password and confirm aren't equal");
			}
		}

		if (
			email &&
			firstname &&
			lastName &&
			password &&
			password.length > 7 &&
			confirmPassword &&
			confirmPassword === password
		) {
			setDisableButton(true);
			shopAdminSignUp({
				variables: {
					fullName: firstname.trim() + ' ' + lastName.trim(),
					email: email.trim(),
					password: password.trim(),
					phoneNumber: window.localStorage.getItem('phoneNumber'),
					phoneSignUpCode: window.localStorage.getItem('signupCode'),
				},
			})
				.then((res) => {
					console.log(res);
					setDisableButton(false);
					setAddModal(true);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDiv}>
			<GridContainer justify="center">
				<GridItem xs={10} sm={10} md={9} lg={7}>
					<div className={style.whiteCard}>
						<div className={style.header}>
							<div className={style.headerSentence}>Sign up form</div>
						</div>
						<div>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>First name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={7}>
									<CustomInput
										value={firstname}
										onChange={(e) => setFirstName(e.target.value)}
										// warning={!firstNameError}
										error={firstNameError}
										labelText={firstNameError ? "first name can't be empty*" : 'first name'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Last name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={7}>
									<CustomInput
										value={lastName}
										onChange={(e) => setLastName(e.target.value)}
										error={lastNameError}
										labelText={lastNameError ? "last name can't be empty*" : 'last name'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Email:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={7}>
									<CustomInput
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										error={emailError}
										labelText={emailError ? "email can't be empty*" : 'example@gmail.com'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: 'email',
										}}
									/>
								</GridItem>
							</GridContainer>

							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Password:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={7}>
									<CustomInput
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										error={passwordError}
										labelText={passwordError ? passwordError : 'at least 8 character'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: visible ? 'text' : 'password',
											endAdornment: visible ? (
												<VisibilityIcon
													className={style.icon}
													onClick={() => setVisible((visible) => !visible)}
													style={{ fontSize: '18px', color: 'gray' }}
													position="end"
												></VisibilityIcon>
											) : (
												<VisibilityOffIcon
													className={style.icon}
													onClick={() => setVisible((visible) => !visible)}
													style={{ fontSize: '18px', color: 'gray' }}
													position="end"
												></VisibilityOffIcon>
											),
										}}
									/>
								</GridItem>
							</GridContainer>

							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Confirm password:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={7}>
									<CustomInput
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
										error={confirmPasswordError}
										labelText={confirmPasswordError ? confirmPasswordError : 'repeat password'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: confirmVisible ? 'text' : 'password',
											endAdornment: confirmVisible ? (
												<VisibilityIcon
													className={style.icon}
													onClick={() => setVisible((visible) => !visible)}
													style={{ fontSize: '18px', color: 'gray' }}
													position="end"
												></VisibilityIcon>
											) : (
												<VisibilityOffIcon
													className={style.icon}
													onClick={() => setVisible((visible) => !visible)}
													style={{ fontSize: '18px', color: 'gray' }}
													position="end"
												></VisibilityOffIcon>
											),
										}}
									/>
								</GridItem>
							</GridContainer>

							<div className={style.buttonContainer}>
								<Button disabled={disableButton} color="yellow" size="lg" onClick={onSubmit}>
									Sign up
								</Button>
							</div>
						</div>
					</div>
				</GridItem>
			</GridContainer>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={onCloseError} type="notif" text="You signed up successfully, you can login now." />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Signup;
