import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {  useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import style from './EnterCode.module.scss';
import logo from 'assets/img/sparkLoginLogo.svg';
import { endPoint } from 'utils/config';

const GET_CODE = gql`
	mutation getAdminForgotPasswordCode($emailOrPhoneNumber: String!) {
		getAdminForgotPasswordCode(emailOrPhoneNumber: $emailOrPhoneNumber) {
			forgotPasswordCode
			phoneForgotPasswordCodeExpireTime
		}
	}
`;

const ForgetPassword = (props) => {
	const [email, setEmail] = useState();
	const [emailErrorText, setEmailErrorText] = useState('');

	const [disableButton, setDisableButton] = useState(false);

	const [getAdminForgotPasswordCode] = useMutation(GET_CODE);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	const onSubmit = () => {
		if (!email) {
			setEmailErrorText("email can't be empty");
		} else {
			setEmailErrorText('');
		}
		if (email) {
			setDisableButton(true);
			getAdminForgotPasswordCode({ variables: { emailOrPhoneNumber: email.trim() } })
				.then((res) => {
                    console.log(res);
					window.localStorage.setItem('emailForgetPassword', email);
					window.localStorage.setItem(
						'time',
						res.data.getAdminForgotPasswordCode.phoneForgotPasswordCodeExpireTime
					);
					window.location.href = endPoint + '/enterForgetPasswordCode';
                })
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors[0].message) {
						setEmailErrorText(err.graphQLErrors[0].message);
					}
				});
		}
	};

	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDiv}>
			<div className={style.imageContainer}>
				<img alt="spark" src={logo} />
			</div>
			<div className={style.formContainer}>
				<div>
					<CustomInput
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						error={emailErrorText}
						labelText={emailErrorText ? emailErrorText : 'email or phone number'}
						id="float"
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							type: 'text',
							autoFocus: true,
						}}
					/>
				</div>
				<div className={style.buttonContainer}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={5}>
							<Link to="/login">
								<Button
									fullWidth
									size="lg"
									style={{ marginRight: '5px' }}
									onClick={onSubmit}
									color="whiteButton"
								>
									Back
								</Button>
							</Link>
						</GridItem>
						<GridItem xs={12} sm={12} md={7}>
							<Button disabled={disableButton} size="lg" fullWidth onClick={onSubmit} color="yellow">
								Next
							</Button>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
};

export default ForgetPassword;
