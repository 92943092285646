import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import Modal from "commonComponents/modal/Modal";
import { login } from "utils/auth";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { endPoint } from "utils/config";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import logo from "assets/img/sparkLoginLogo.svg";
import style from "./Login.module.scss";

const LOGIN = gql`
  mutation adminLogin($emailOrPhoneNumber: String!, $password: String!) {
    adminLogin(
      input: { emailOrPhoneNumber: $emailOrPhoneNumber, password: $password }
    ) {
      type
      shop {
        _id
        verified
        isRejected
        rejectionMessage
        rootCategory {
          _id
          title
        }
        stripeAccountId
      }
      token {
        accessToken
        refreshToken
      }
      fullName
      roles {
        permissions {
          name
        }
      }
    }
  }
`;

const GET_VERIFICATION_CODE = gql`
  mutation getShopAdminPhoneVerificationCode($phoneNumber: String!) {
    getShopAdminPhoneVerificationCode(phoneNumber: $phoneNumber) {
      phoneVerificationCode
      phoneVerificationCodeExpireTime
    }
  }
`;

const ShopLogin = (prop) => {
  // login variables
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [visible, setVisible] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [remember, setRemember] = useState(false);

  const [disableButton, setDisableButton] = useState(false);
  const [disableLoginButton, setDisableLoginButton] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  useEffect(() => {
    if (
      window.localStorage.getItem("userName") &&
      window.localStorage.getItem("password")
    ) {
      setEmail(atob(window.localStorage.getItem("userName")));
      setPassword(atob(window.localStorage.getItem("password")));
      setRemember(true);
    }
  }, [,]);

  const onRememberPassword = () => {
    window.localStorage.setItem("userName", btoa(email));
    window.localStorage.setItem("password", btoa(password));
  };

  const onForgetPassword = () => {
    window.localStorage.removeItem("userName");
    window.localStorage.removeItem("password");
  };

  //   signup variables
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErrorText, setPhoneNumberErrorText] = useState("");

  const [getShopAdminPhoneVerificationCode] = useMutation(
    GET_VERIFICATION_CODE
  );

  const [adminLogin] = useMutation(LOGIN);

  useEffect(() => {
    window.localStorage.removeItem("shopId");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("shopType");
    window.localStorage.removeItem("verificationCode");
    window.localStorage.removeItem("phoneNumber");
    window.localStorage.removeItem("permissions");
  }, [,]);

  const handleEnterLogin = (e) => {
    if (e.charCode === 13) {
      onSubmitLogin();
    }
  };

  const handleEnterSignup = (e) => {
    if (e.charCode === 13) {
      onSubmitSignup();
    }
  };

  const onSubmitSignup = () => {
    if (!phoneNumber) {
      setPhoneNumberErrorText("phone number can't be empty");
    } else {
      if (isNaN(phoneNumber)) {
        setPhoneNumberErrorText("please enter a valid phone number");
      } else {
        setPhoneNumberErrorText("");
      }
    }
    if (phoneNumber && !isNaN(phoneNumber)) {
      setDisableButton(true);
      getShopAdminPhoneVerificationCode({
        variables: { phoneNumber: phoneNumber.trim() },
      })
        .then((res) => {
          console.log(res);
          window.localStorage.setItem("phoneNumber", phoneNumber);
          window.localStorage.setItem(
            "time",
            res.data.getShopAdminPhoneVerificationCode
              .phoneVerificationCodeExpireTime
          );
          window.location.href = endPoint + "/submitCode";
        })
        .catch((err) => {
          setDisableButton(false);
          console.log(JSON.parse(JSON.stringify(err)));
          if (
            err.graphQLErrors[0].extensions.code === "INTERNAL_SERVER_ERROR"
          ) {
            setPhoneNumberErrorText("this phone number is taken");
          } else {
            setPhoneNumberErrorText("");
          }
        });
    }
  };

  const onSubmitLogin = () => {
    if (!email) {
      setEmailErrorText("email can't be empty");
    } else {
      setEmailErrorText("");
    }
    if (!password) {
      setPasswordErrorText("password can't be empty");
    } else {
      setPasswordErrorText("");
    }
    if (password && email) {
      setDisableLoginButton(true);
      adminLogin({ variables: { emailOrPhoneNumber: email, password } })
        .then((res) => {
          console.log("this is login data:", res);
          login(
            res.data.adminLogin.token.accessToken.toString(),
            res.data.adminLogin.token.refreshToken.toString()
          );
          if (remember) {
            onRememberPassword();
          } else {
            onForgetPassword();
          }
          if (res.data.adminLogin.type === "SUPER-ADMIN") {
            // save permissions in local storage
            if (res.data.adminLogin.roles) {
              let array = [];
              res.data.adminLogin.roles.map((role) => {
                role.permissions.map((permission) =>
                  array.push(permission.name)
                );
              });
              window.localStorage.setItem("permissions", JSON.stringify(array));

              window.location.href = endPoint + "/superAdmin/dashboard";
            }
          } else if (res.data.adminLogin.type === "SHOP-ADMIN") {
            window.localStorage.setItem("permissions", JSON.stringify([]));
            if (!res.data.adminLogin.shop) {
              window.localStorage.setItem("nextStep", "/addShop");
              window.localStorage.removeItem("shopId");
              window.localStorage.removeItem("shopType");
              window.location.href = endPoint + "/rules";
            } else if (res.data.adminLogin.shop) {
              if (
                res.data.adminLogin.shop &&
                res.data.adminLogin.shop.stripeAccountId
              ) {
                if (res.data.adminLogin.shop.verified) {
                  window.localStorage.setItem(
                    "shopType",
                    res.data.adminLogin.shop.rootCategory.title.toString()
                  );
                  window.location.href = endPoint + "/shopAdmin/dashboard";
                } else {
                  if (res.data.adminLogin.shop.isRejected === true) {
                    if (res.data.adminLogin.shop.rejectionMessage) {
                      window.localStorage.setItem(
                        "rejectionMessage",
                        res.data.adminLogin.shop.rejectionMessage
                      );
                    }
                    window.localStorage.setItem(
                      "nextStep",
                      "/shopAdmin/dashboard"
                    );
                    window.location.href = endPoint + "/editShop";
                  } else {
                    window.localStorage.setItem(
                      "shopType",
                      res.data.adminLogin.shop.rootCategory.title.toString()
                    );
                    window.localStorage.setItem(
                      "nextStep",
                      "/shopAdmin/dashboard"
                    );
                    window.location.href = endPoint + "/rules";
                  }
                }
              } else if (
                res.data.adminLogin.shop &&
                !res.data.adminLogin.shop.stripeAccountId
              ) {
                if (res.data.adminLogin.shop.isRejected) {
                  if (res.data.adminLogin.shop.rejectionMessage) {
                    window.localStorage.setItem(
                      "rejectionMessage",
                      res.data.adminLogin.shop.rejectionMessage
                    );
                  }
                  window.localStorage.setItem(
                    "nextStep",
                    "/api/v1/paymentMethod"
                  );
                  window.location.href = endPoint + "/editShop";
                } else {
                  window.localStorage.setItem(
                    "shopType",
                    res.data.adminLogin.shop.rootCategory.title.toString()
                  );
                  if (res.data.adminLogin.shop.verified) {
                    window.location.href = endPoint + "/api/v1/paymentMethod";
                  } else {
                    window.localStorage.setItem(
                      "nextStep",
                      "/api/v1/paymentMethod"
                    );
                    window.location.href = endPoint + "/rules";
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          setDisableLoginButton(false);
          // window.localStorage.removeItem("token");
          console.log(JSON.parse(JSON.stringify(err)));
          console.log(err);
          if (
            err.graphQLErrors &&
            err.graphQLErrors.length > 0 &&
            err.graphQLErrors[0].extensions.code === "404"
          ) {
            setEmailErrorText("wrong email or phone number");
          } else {
            setEmailErrorText("");
          }

          if (
            err.graphQLErrors &&
            err.graphQLErrors.length > 0 &&
            err.graphQLErrors[0].extensions.code === "400"
          ) {
            setPasswordErrorText("wrong password");
          } else {
            setPasswordErrorText("");
          }

          if (
            err.graphQLErrors &&
            err.graphQLErrors.length > 0 &&
            err.graphQLErrors[0].extensions.code === "403"
          ) {
            setErrorText(err.graphQLErrors[0].message);
            setErrorModal(true);
          }
        });
    }
  };

  return (
    <div className={style.mainDiv}>
      <div className={style.headerContainer}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div className={style.logoContainer}>
              <img alt="spark" src={logo} />
            </div>
            <div className={style.headerSentence}>Admin panel</div>
          </GridItem>
        </GridContainer>
      </div>
      <div className={style.contentContainer}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <div
              onKeyPress={(e) => handleEnterLogin(e)}
              className={style.leftForm}
            >
              <div className={style.formHeader}>Log in</div>
              <div className={style.formSentence}>
                If you own a restaurant or a shop, log in here.
              </div>
              <div>
                <CustomInput
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={emailErrorText}
                  labelText={
                    emailErrorText ? emailErrorText : "email or phone number"
                  }
                  id="float"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                    autoFocus: true,
                  }}
                />
              </div>
              <div>
                <CustomInput
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={passwordErrorText}
                  labelText={passwordErrorText ? passwordErrorText : "password"}
                  id="float"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: visible ? "text" : "password",
                    endAdornment: visible ? (
                      <VisibilityIcon
                        className={style.icon}
                        onClick={() => setVisible((visible) => !visible)}
                        style={{ fontSize: "18px", color: "gray" }}
                        position="end"
                      ></VisibilityIcon>
                    ) : (
                      <VisibilityOffIcon
                        className={style.icon}
                        onClick={() => setVisible((visible) => !visible)}
                        style={{ fontSize: "18px", color: "gray" }}
                        position="end"
                      ></VisibilityOffIcon>
                    ),
                  }}
                />
              </div>
              <div
                style={{ marginTop: "25px" }}
                className={style.buttonContainer}
              >
                <Button
                  disabled={disableLoginButton}
                  size="lg"
                  fullWidth
                  onClick={onSubmitLogin}
                  color="yellow"
                >
                  Log in
                </Button>
              </div>
              <div>
                <FormControlLabel
                  label="remember me"
                  control={
                    <Checkbox
                      style={{ color: "gray" }}
                      color="gray"
                      // name="remember me"
                      checked={remember}
                      onChange={(e) => setRemember((remember) => !remember)}
                    />
                  }
                />
              </div>
              <div
                onClick={() => {
                  window.location.href = endPoint + "/forgetPassword";
                }}
                className={style.forgetPasswordLink}
              >
                I forgot my password
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <div
              onKeyPress={(e) => handleEnterSignup(e)}
              className={style.rightForm}
            >
              <div className={style.formHeader}>Sign up</div>
              <div className={style.formSentence}>
                If you own a restaurant or a shop, sign up here.
              </div>
              <div>
                <CustomInput
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  error={phoneNumberErrorText}
                  labelText={
                    phoneNumberErrorText ? phoneNumberErrorText : "phone number"
                  }
                  id="float"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </div>

              <div
                style={{ marginTop: "99px" }}
                className={style.buttonContainer}
              >
                <Button
                  disabled={disableButton}
                  size="lg"
                  fullWidth
                  onClick={onSubmitSignup}
                  color="yellow"
                >
                  Sign up
                </Button>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <Dialog open={errorModal} transition={Transition}>
        <DialogContent id="modal-slide-description">
          <Modal
            close={() => setErrorModal(false)}
            type="notif"
            text={errorText}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ShopLogin;
