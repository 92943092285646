import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.js';
import style from 'superAdminViews/ride/trips/TripDetails.module.scss';

const CHANGE_TO_PREPARING = gql`
	mutation changeOrderStatusToPreparingByShopAdmin($orderId: ID!) {
		changeOrderStatusToPreparingByShopAdmin(orderId: $orderId) {
			_id
			status
		}
	}
`;

const CHANGE_TO_SHIPPING = gql`
	mutation changeOrderStatusToShippingByShopAdmin($orderId: ID!) {
		changeOrderStatusToShippingByShopAdmin(orderId: $orderId) {
			_id
			status
		}
	}
`;

const UPDATE_ORDERS_SUBSCRIPTION = gql`
	subscription updateOrder($orderId: ID!) {
		updateOrder(orderId: $orderId) {
			_id
			user {
				_id
			}
			shipmentModel
			type
			finalPrice
			commission
			createdAt
			finished
			status
			trip {
				_id
				trackId
				origin {
					coordinates
				}
			}
			tracking {
				trackId
			}
			cart {
				products {
					quantity
					product {
						_id
						title
					}
				}
			}
		}
	}
`;

const OrderDetails = ({ order: item, close, type }) => {
	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const grayTheme = {
		checkboxColor: 'gray',
		primaryColor: 'primary',
	};

	let theme = orangeTheme;
	if (type === 'Purchase') {
		theme = greenTheme;
	} else if (type === 'Restaurant') {
		theme = orangeTheme;
	} else if (type === 'superAdmin') {
		theme = grayTheme;
	}

	const statusOptions = ['WAITING', 'PAID', 'STOCK', 'SHIPPING', 'DELIVERED', 'REJECTED'];

	const [order, setOrder] = useState(item);

	const [showMap, setShowMap] = useState(false);

	const { error: subUpdateError, data: subUpdateData, loading: subUpdateLoading } = useSubscription(
		UPDATE_ORDERS_SUBSCRIPTION,
		{
			variables: { orderId: order._id },
		}
	);

	useEffect(() => {
		if (subUpdateData) {
			setOrder(subUpdateData.updateOrder);
		}
	}, [subUpdateData, subUpdateLoading]);

	const [changeOrderStatusToPreparingByShopAdmin, { data: preparingData, error: preparingError }] = useMutation(
		CHANGE_TO_PREPARING
	);

	const [changeOrderStatusToShippingByShopAdmin, { data: shippingData, error: shippingError }] = useMutation(
		CHANGE_TO_SHIPPING
	);

	const changeToPreparing = () => {
		changeOrderStatusToPreparingByShopAdmin({
			variables: {
				orderId: order._id,
			},
		})
			.then((res) => {
				close();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const changeToShipping = () => {
		changeOrderStatusToShippingByShopAdmin({
			variables: {
				orderId: order._id,
			},
		})
			.then((res) => {
				close();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Order details</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Status:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{order.status.replace(/_/g, ' ').toLowerCase()}</div>
								</GridItem>
							</GridContainer>
						</div>

						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Order track ID:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									{order.tracking && order.tracking.trackId && (
										<div className={style.value}>
											<CopyToClipboard text={order.tracking.trackId}>
												<span
													title="Click To Copy To Clipboard"
													className={style.copyClipboard}
												>
													{order.tracking.trackId}
												</span>
											</CopyToClipboard>
										</div>
									)}
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Customer:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{order.user.fullName}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Total price:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>${order.finalPrice.toFixed(2)}</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Created At:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										{moment(order.createdAt).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={3} md={3}>
									<div className={style.label}>Products:</div>
								</GridItem>
								<GridItem xs={12} sm={9} md={9}>
									<div className={`${style.value} ${style.productsContainer}`}>
										{order.cart.products &&
											order.cart.products.map((item) => {
												if (item && item.product) {
													return (
														<div
															key={item.product._id}
															className={`${style.inlineTag} ${
																style[theme.primaryColor + 'Tag']
															}`}
														>
															{item.quantity}-{item.product.title}
														</div>
													);
												}
											})}
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.footer}>
				<Button onClick={close}>Close</Button>
			</div>
		</div>
	);
};

export default OrderDetails;
