import React, { useState } from 'react';
import moment from 'moment';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Close from '@material-ui/icons/Close';
import { graphqlUrl } from 'utils/config';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import style from 'superAdminViews/ride/trips/TripDetails.module.scss';
import Slide from '@material-ui/core/Slide';

const ShopVerificationDetails = ({ item, close }) => {

    const profileImages = item && [`${graphqlUrl}${item.logoUrl}`];
	const [isProfileImagesOpen, setIsProfileImagesOpen] = useState(false);
	const [profileImageIndex, setProfileImageIndex] = useState(0);

	const licenceImages = item && [`${graphqlUrl}${item.bannerUrl}`];
	const [isLicenceImagesOpen, setIsLicenceImagesOpen] = useState(false);
    const [licenceImageIndex, setLicenceImageIndex] = useState(0);
    
	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Verification details</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>ID:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{item._id}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{item.name}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Email:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{item.shopAdmin && item.shopAdmin.email}</div>
								</GridItem>
							</GridContainer>
                        </div>
                        <div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>State:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{item.state}</div>
								</GridItem>
							</GridContainer>
                        </div>
                        <div className={style.rowContainer}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <div className={style.label}>Type:</div>
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8}>
                                <div className={style.value}>{item.rootCategory && item.rootCategory.title}</div>
                            </GridItem>
                        </GridContainer>
                    </div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Phone number:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{item.phoneNumbers && item.phoneNumbers[0]}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Logo image:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>
										<img
											onClick={() => setIsProfileImagesOpen(true)}
											className={style.profileImage}
											src={`${graphqlUrl}${item.logoUrl}`}
										/>
									</div>
								</GridItem>
							</GridContainer>
                        </div>
                        {item.bannerUrl && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4} lg={4}>
										<div className={style.label}>Banner image:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8} lg={8}>
										<div className={style.value}>
											<img
												onClick={() => setIsLicenceImagesOpen(true)}
												className={style.profileImage}
												src={`${graphqlUrl}${item.bannerUrl}`}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
                       
					</GridItem>
				</GridContainer>
            </div>
            {isProfileImagesOpen && (
				<Lightbox
					mainSrc={profileImages[profileImageIndex]}
					nextSrc={profileImages[(profileImageIndex + 1) % profileImages.length]}
					prevSrc={profileImages[(profileImageIndex + profileImages.length - 1) % profileImages.length]}
					onCloseRequest={() => setIsProfileImagesOpen(false)}
					onMovePrevRequest={() =>
						setProfileImageIndex((profileImageIndex + profileImages.length - 1) % profileImages.length)
					}
					onMoveNextRequest={() => setProfileImageIndex((profileImageIndex + 1) % profileImages.length)}
				/>
			)}
			{isLicenceImagesOpen && (
				<Lightbox
					mainSrc={licenceImages[licenceImageIndex]}
					nextSrc={licenceImages[(licenceImageIndex + 1) % licenceImages.length]}
					prevSrc={licenceImages[(licenceImageIndex + licenceImages.length - 1) % licenceImages.length]}
					onCloseRequest={() => setIsLicenceImagesOpen(false)}
					onMovePrevRequest={() =>
						setLicenceImageIndex((licenceImageIndex + licenceImages.length - 1) % licenceImages.length)
					}
					onMoveNextRequest={() => setLicenceImageIndex((licenceImageIndex + 1) % licenceImages.length)}
				/>
			)}
		</div>
	);
};

export default ShopVerificationDetails;
