import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { endPoint } from 'utils/config';
import SelectSearch from 'react-select-search';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import style from 'superAdminViews/setting/sendNotif/SendNotif.module.scss';

const SEND_NOTIFICATION_TO_ALL_DRIVERS = gql`
	mutation sendNotificationsToAllDriversByAdmin($title: String!, $body: String!, $type: NotificationType!) {
		sendNotificationsToAllDriversByAdmin(title: $title, body: $body, type: $type) {
			message
		}
	}
`;

const SEND_NOTIFICATION_TO_ALL_USERS = gql`
	mutation sendNotificationsToAllUsersByAdmin($title: String!, $body: String!, $type: NotificationType!) {
		sendNotificationsToAllUsersByAdmin(title: $title, body: $body, type: $type) {
			message
		}
	}
`;

const SEND_NOTIFICATION_TO_SINGLE_DRIVER_OR_USER = gql`
	mutation createNotificationByAdmin(
		$driver: ID
		$user: ID
		$title: String!
		$body: String!
		$type: NotificationType!
		$for: ForInput!
	) {
		createNotificationByAdmin(
			input: { user: $user, driver: $driver, title: $title, body: $body, type: $type, for: $for }
		) {
			_id
			body
		}
	}
`;

const SEND_NOTIFICATION_TO_SOME_DRIVERS_AND_USERS = gql`
	mutation sendNotificationsToMultiDriverOrUserByAdmin(
		$drivers: [ID!]
		$users: [ID!]
		$title: String!
		$body: String!
		$type: NotificationType!
	) {
		sendNotificationsToMultiDriverOrUserByAdmin(
			input: { users: $users, drivers: $drivers, title: $title, body: $body, type: $type }
		) {
			message
		}
	}
`;

const GET_DRIVERS = gql`
	query getDriversByAdmin($limit: Int, $skip: Int, $fullName: String) {
		getDriversByAdmin(pagination: { limit: $limit, skip: $skip }, filters: { fullName: $fullName }) {
			_id
			fullName
			phoneNumber
		}
	}
`;

const GET_USERS = gql`
	query getUsersByAdmin($fullName: String, $limit: Int, $skip: Int) {
		getUsersByAdmin(filters: { fullName: $fullName }, pagination: { limit: $limit, skip: $skip }) {
			_id
			fullName
			phoneNumber
			shopUser
		}
	}
`;

const SendNotif = (props) => {
	const [title, setTitle] = useState('');
	const [text, setText] = useState('');
	const [includeDrivers, setIncludeDrivers] = useState([]);
	const [includeUsers, setIncludeUsers] = useState([]);
	const [driverId, setDriverId] = useState('');
	const [userId, setUserId] = useState('');

	const toTypeOptions = ['Single User', 'Single Driver', 'All Drivers', 'All Users', 'Some Of Both'];
	const typeOptions = ['IMPORTANT', 'GENERAL', 'PRIVATE'];

	const [toType, setToType] = useState(toTypeOptions[0]);
	const [type, setType] = useState(typeOptions[0]);

	const [titleError, setTitleError] = useState(false);
	const [driverIdError, setDriverIdError] = useState(false);
	const [userIdError, setUserIdError] = useState(false);
	const [textError, setTextError] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const [addModal, setAddModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [driverSelectOptions, setDriverSelectOptions] = useState([]);
	const [driverSelectArray, setDriverSelectArray] = useState([]);

	const [userSelectOptions, setUserSelectOptions] = useState([]);
	const [userSelectArray, setUserSelectArray] = useState([]);

	const { loading: driversLoading, error: driversError, data: driversData } = useQuery(GET_DRIVERS);

	const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_USERS);

	const [
		getDriversByAdmin,
		{ loading: driversLazyLoading, error: driversLazyError, data: driversLazyData, fetchMore },
	] = useLazyQuery(GET_DRIVERS);

	const [
		getUsersByAdmin,
		{ loading: usersLazyLoading, error: usersLazyError, data: usersLazyData, fetchMore: fetchMoreUsers },
	] = useLazyQuery(GET_USERS);

	const [fetchMoreSkip, setFetchMoreSkip] = useState(10);
	const [fetchMoreSkipUsers, setFetchMoreSkipUsers] = useState(10);

	const [search, setSearch] = useState('');
	const [searchUser, setSearchUser] = useState('');

	useEffect(() => {
		if (driversLazyData) {
			setDriverSelectOptions(
				Array.from(driversLazyData.getDriversByAdmin, (driver) => ({
					name: driver.fullName + ' - ' + driver.phoneNumber,
					value: driver._id,
				}))
			);
			setDriverSelectArray((driverSelectArray) => [
				...driverSelectArray,
				...Array.from(driversLazyData.getDriversByAdmin, (driver) => ({
					name: driver.fullName + ' - ' + driver.phoneNumber,
					value: driver._id,
				})),
			]);
		}
	}, [driversLazyLoading, driversLazyData]);

	useEffect(() => {
		if (usersLazyData) {
			setUserSelectOptions(
				Array.from(usersLazyData.getUsersByAdmin, (user) => ({
					name: user.fullName + ' - ' + user.phoneNumber,
					value: user._id,
				}))
			);
			setUserSelectArray((userSelectArray) => [
				...userSelectArray,
				...Array.from(usersLazyData.getUsersByAdmin, (user) => ({
					name: user.fullName + ' - ' + user.phoneNumber,
					value: user._id,
				})),
			]);
		}
		if (usersLazyError) {
			console.log(usersLazyError);
		}
	}, [usersLazyLoading, usersLazyData, usersLazyError]);
	useEffect(() => {
		const handler = setTimeout(() => {
			getDriversByAdmin({
				variables: {
					limit: 10,
					skip: 0,
					fullName: search ? search.trim() : '',
				},
			});
		}, 200);
		return () => clearTimeout(handler);
	}, [search]);

	useEffect(() => {
		const handlerUsers = setTimeout(() => {
			getUsersByAdmin({
				variables: {
					limit: 10,
					skip: 0,
					fullName: searchUser ? searchUser.trim() : undefined,
				},
			});
		}, 200);
		return () => clearTimeout(handlerUsers);
	}, [searchUser]);

	const handleScroll = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			onLoadMore();
		}
	};

	const onLoadMore = () => {
		fetchMore({
			query: GET_DRIVERS,
			variables: { limit: 10, skip: fetchMoreSkip, fullName: search ? search : '' },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				console.log('this is new results:', fetchMoreResult.getDriversByAdmin);
				setDriverSelectOptions((driverSelectOptions) => [
					...driverSelectOptions,
					...Array.from(fetchMoreResult.getDriversByAdmin, (driver) => ({
						name: driver.fullName + ' - ' + driver.phoneNumber,
						value: driver._id,
					})),
				]);
				setDriverSelectArray((driverSelectOptions) => [
					...driverSelectOptions,
					...Array.from(fetchMoreResult.getDriversByAdmin, (driver) => ({
						name: driver.fullName + ' - ' + driver.phoneNumber,
						value: driver._id,
					})),
				]);
			},
		});
		setFetchMoreSkip((fetchMoreSkip) => fetchMoreSkip + 10);
	};

	const onLoadMoreUsers = () => {
		fetchMoreUsers({
			query: GET_USERS,
			variables: { limit: 10, skip: fetchMoreSkipUsers, fullName: searchUser ? searchUser : undefined },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				console.log('this is new results:', fetchMoreResult.getUsersByAdmin);
				setUserSelectOptions((userSelectOptions) => [
					...userSelectOptions,
					...Array.from(fetchMoreResult.getUsersByAdmin, (user) => ({
						name: user.fullName + ' - ' + user.phoneNumber,
						value: user._id,
					})),
				]);
				setUserSelectArray((userSelectArray) => [
					...userSelectArray,
					...Array.from(fetchMoreResult.getUsersByAdmin, (user) => ({
						name: user.fullName + ' - ' + user.phoneNumber,
						value: user._id,
					})),
				]);
			},
		});
		setFetchMoreSkipUsers((fetchMoreSkipUsers) => fetchMoreSkipUsers + 10);
	};

	const handleScrollUsers = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			onLoadMoreUsers();
		}
	};

	const [
		sendNotificationsToAllDriversByAdmin,
		{ data: sendNotificationToAllDriversData, error: sendNotificationToAllDriversError },
	] = useMutation(SEND_NOTIFICATION_TO_ALL_DRIVERS);

	const [
		sendNotificationsToAllUsersByAdmin,
		{ data: sendNotificationToAllUsersData, error: sendNotificationToAllUsersError },
	] = useMutation(SEND_NOTIFICATION_TO_ALL_USERS);

	const [
		createNotificationByAdmin,
		{ data: sendNotificationToSingleDriverOrUserData, error: sendNotificationToSingleDriverOrUserError },
	] = useMutation(SEND_NOTIFICATION_TO_SINGLE_DRIVER_OR_USER);

	const [
		sendNotificationsToMultiDriverOrUserByAdmin,
		{ data: sendNotificationToSomeDriversAndUsersData, error: sendNotificationToSomeDriversAndUsersError },
	] = useMutation(SEND_NOTIFICATION_TO_SOME_DRIVERS_AND_USERS);

	if (sendNotificationToSomeDriversAndUsersError) {
		console.log(sendNotificationToSomeDriversAndUsersError);
	}

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	const onCancel = () => {
		window.onbeforeunload = undefined;
		window.location.href = endPoint + '/superAdmin/sendNotification';
	};

	const onSubmit = () => {
		if (!title) {
			setTitleError(true);
		} else {
			setTitleError(false);
		}
		if (!text) {
			setTextError(true);
		} else {
			setTextError(false);
		}
		if (toType === 'Single User' && !userId) {
			setUserIdError(true);
		} else {
			setUserIdError(false);
		}
		if (toType === 'Single Driver' && !driverId) {
			setDriverIdError(true);
		} else {
			setDriverIdError(false);
		}
		if (text && title && toType === 'All Drivers') {
			setDisableButton(true);
			sendNotificationsToAllDriversByAdmin({
				variables: { title: title.trim(), body: text.trim(), type },
			})
				.then((res) => {
					setAddModal(true);
					setDriverId('');
					setUserId('');
					setText('');
					setTitle('');
					setIncludeDrivers([]);
					setIncludeUsers([]);
					setDisableButton(false);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (text && title && toType === 'All Users') {
			setDisableButton(true);
			sendNotificationsToAllUsersByAdmin({
				variables: { title: title.trim(), body: text.trim(), type },
			})
				.then((res) => {
					setAddModal(true);
					setDriverId('');
					setUserId('');
					setText('');
					setTitle('');
					setIncludeDrivers([]);
					setIncludeUsers([]);
					setDisableButton(false);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (text && title && driverId && toType === 'Single Driver') {
			setDisableButton(true);
			createNotificationByAdmin({
				variables: {
					title: title.trim(),
					body: text.trim(),
					type,
					driver: driverId.trim(),
					user: undefined,
					for: 'DRIVER',
				},
			})
				.then((res) => {
					setAddModal(true);
					setDriverId('');
					setUserId('');
					setText('');
					setTitle('');
					setIncludeDrivers([]);
					setIncludeUsers([]);
					setDisableButton(false);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (text && title && userId && toType === 'Single User') {
			setDisableButton(true);
			createNotificationByAdmin({
				variables: {
					title: title.trim(),
					body: text.trim(),
					type,
					user: userId.trim(),
					for: 'USER',
				},
			})
				.then((res) => {
					setDisableButton(false);
					setAddModal(true);
					setDriverId('');
					setUserId('');
					setText('');
					setTitle('');
					setIncludeDrivers([]);
					setIncludeUsers([]);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (text && title && toType === 'Some Of Both') {
			setDisableButton(true);
			sendNotificationsToMultiDriverOrUserByAdmin({
				variables: {
					title: title.trim(),
					body: text.trim(),
					type,
					users: includeUsers.length > 0 ? includeUsers : undefined,
					drivers: includeDrivers.length > 0 ? includeDrivers : undefined,
				},
			})
				.then((res) => {
					setAddModal(true);
					setDriverId('');
					setUserId('');
					setText('');
					setTitle('');
					setIncludeDrivers([]);
					setIncludeUsers([]);
					setDisableButton(false);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<div onKeyPress={(e) => handleEnter(e)} className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.dropdownLabel}>Priority:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={type}
											onClick={(e) => setType(e)}
											dropdownList={typeOptions}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div onKeyPress={(e) => handleEnter(e)} className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.dropdownLabel}>To:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={toType}
											onClick={(e) => {
												setToType(e);
												setSearch('');
												setSearchUser('');
												setIncludeDrivers([]);
												setIncludeUsers([]);
												setDriverId('');
												setUserId();
												setFetchMoreSkip(10);
												setFetchMoreSkipUsers(10);
											}}
											dropdownList={toTypeOptions}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
						{toType === 'Some Of Both' && (
							<div onKeyPress={(e) => handleEnter(e)} className={style.tagRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.tagLabel}>For Users:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.tagsContainer}>
											{includeUsers.map((includeId) => (
												<div key={includeId} className={style.idDiv}>
													{userSelectArray.some((item) => item.value === includeId) &&
														userSelectArray.find((item) => item.value === includeId).name}
													<div className={style.removeIconDiv}>
														<CancelRoundedIcon
															onClick={() =>
																setIncludeUsers(
																	includeUsers.filter((i) => i !== includeId)
																)
															}
														/>
													</div>
												</div>
											))}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{toType === 'Some Of Both' && (
							<div className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5}>
										<div className={style.label}>For Users:</div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7}>
										<div>
											<SelectSearch
												options={userSelectOptions}
												value={includeUsers}
												name=""
												getOptions={(searchWord) => {
													setSearchUser(searchWord);
												}}
												onScroll={(e) => handleScrollUsers(e)}
												closeOnSelect={false}
												printOptions="on-focus"
												multiple
												search
												placeholder="Choose users"
												onChange={(value) => setIncludeUsers(value)}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{toType === 'Some Of Both' && (
							<div className={style.tagRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.tagLabel}>For Drivers:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.tagsContainer}>
											{includeDrivers.map((includeId) => (
												<div key={includeId} className={style.idDiv}>
													{driverSelectArray.some((item) => item.value === includeId) &&
														driverSelectArray.find((item) => item.value === includeId).name}
													<div className={style.removeIconDiv}>
														<CancelRoundedIcon
															onClick={() =>
																setIncludeDrivers(
																	includeDrivers.filter((i) => i !== includeId)
																)
															}
														/>
													</div>
												</div>
											))}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{toType === 'Some Of Both' && (
							<div className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5}>
										<div className={style.label}>For Drivers:</div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7}>
										<div>
											<SelectSearch
												options={driverSelectOptions}
												value={includeDrivers}
												name=""
												getOptions={(searchWord) => {
													setSearch(searchWord);
												}}
												onScroll={(e) => handleScroll(e)}
												closeOnSelect={false}
												printOptions="on-focus"
												multiple
												search
												placeholder="Choose drivers"
												onChange={(value) => setIncludeDrivers(value)}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{toType === 'Single Driver' && (
							<div className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5}>
										<div className={style.label}>Driver:</div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7}>
										{driverIdError && (
											<div className={style.selectSearchError}>driver can't be empty*</div>
										)}
										<div>
											<SelectSearch
												options={driverSelectOptions}
												value={driverId}
												getOptions={(searchWord) => {
													setSearch(searchWord);
												}}
												onScroll={(e) => handleScroll(e)}
												name=""
												search
												placeholder="Choose driver"
												onChange={(value) => setDriverId(value)}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{toType === 'Single User' && (
							<div className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5}>
										<div className={style.label}>User:</div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7}>
										{userIdError && (
											<div className={style.selectSearchError}>user can't be empty*</div>
										)}
										<div>
											<SelectSearch
												options={userSelectOptions}
												value={userId}
												name=""
												getOptions={(searchWord) => {
													setSearchUser(searchWord);
												}}
												onScroll={(e) => handleScrollUsers(e)}
												search
												placeholder="Choose user"
												onChange={(value) => setUserId(value)}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						<div onKeyPress={(e) => handleEnter(e)} className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Title:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={title}
										onChange={(e) => setTitle(e.target.value)}
										error={titleError}
										labelText={titleError ? "title can't be empty*" : 'title'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div onKeyPress={(e) => handleEnter(e)} className={style.textAreaRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.texAreaLabel}>Message:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<textarea
										className={style.textArea}
										value={text}
										onChange={(e) => setText(e.target.value)}
										placeholder="write text here ..."
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<div>
				<GridContainer>
					<GridItem xs={12} sm={6} md={5}>
						<div></div>
					</GridItem>
					<GridItem xs={12} sm={6} md={7}>
						<div className={style.buttonContainer}>
							<Button color="whiteButton" onClick={onCancel}>
								Cancel
							</Button>
							<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
								Send
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text="Notification sent successfully." />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default SendNotif;
