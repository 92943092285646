import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import ReactTable from 'react-table';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Modal from 'commonComponents/modal/Modal';
import Dialog from '@material-ui/core/Dialog';
import Pagination from 'commonComponents/pagination/Pagination';
import DialogContent from '@material-ui/core/DialogContent';
import SortIcon from '@material-ui/icons/Sort';
import style from 'shopAdminViews/orders/Orders.module.scss';
import PromotionDetails from 'superAdminViews/ride/promotions/PromotionDetails';

const GET_SHOP_PROMOTIONS = gql`
	query getOrderPromotionsByAdmin(
		$limit: Int
		$skip: Int
		$shopName: String
		$_id: ID
		$condition: OrderPromotionCondition
		$type: promotionTypeEnum
		$fromFrom: Date
		$from: Date
		$toFrom: Date
		$to: Date
		$percentFrom: Float
		$percent: Float
		$promotionCode: String
		$maximumPromotionFrom: Float
		$maximumPromotion: Float
		$createdAtSort: Int
	) {
		getOrderPromotionsByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				condition: $condition
				shopName: $shopName
				type: $type
				fromFrom: $fromFrom
				from: $from
				toFrom: $toFrom
				to: $to
				percentFrom: $percentFrom
				percent: $percent
				promotionCode: $promotionCode
				maximumPromotionFrom: $maximumPromotionFrom
				maximumPromotion: $maximumPromotion
			}
			sort: { createdAt: $createdAtSort }
		) {
			_id
			condition
			type
			percent
			maximumPromotion
			promotionCode
			from
			to
			useLimitCount
			shop {
				_id
				name
			}
		}
	}
`;

const GET_SHOP_PROMOTIONS_COUNT = gql`
	query getOrderPromotionsByAdminCount(
		$_id: ID
		$shopName: String
		$condition: OrderPromotionCondition
		$type: promotionTypeEnum
		$percentFrom: Float
		$percent: Float
		$fromFrom: Date
		$from: Date
		$toFrom: Date
		$to: Date
		$promotionCode: String
		$maximumPromotionFrom: Float
		$maximumPromotion: Float
	) {
		getOrderPromotionsByAdminCount(
			filters: {
				_id: $_id
				condition: $condition
				type: $type
				fromFrom: $fromFrom
				from: $from
				toFrom: $toFrom
				to: $to
				shopName: $shopName
				percentFrom: $percentFrom
				percent: $percent
				promotionCode: $promotionCode
				maximumPromotionFrom: $maximumPromotionFrom
				maximumPromotion: $maximumPromotion
			}
		)
	}
`;

const Promotions = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const conditionOptions = ['ALL', 'TIMELY', 'FIRST ORDER', 'PERCENTAGE'];
	const typeOptions = ['ALL', 'FIXED', 'PERCENT'];

	const [codeFilter, setCodeFilter] = useState('');
	const [amountFromFilter, setAmountFromFilter] = useState('');
	const [amountFilter, setAmountFilter] = useState('');
	const [percentFromFilter, setPercentFromFilter] = useState('');
	const [percentFilter, setPercentFilter] = useState('');
	const [startDateFromFilter, setStartDateFromFilter] = useState('');
	const [startDateFilter, setStartDateFilter] = useState('');
	const [endDateFromFilter, setEndDateFromFilter] = useState('');
	const [endDateFilter, setEndDateFilter] = useState('');
	const [typeFilter, setTypeFilter] = useState(typeOptions[0]);
	const [conditionFilter, setConditionFilter] = useState(conditionOptions[0]);

	const [shopNameFilter, setShopNameFilter] = useState('');

	const [startDateFromFilterError, setStartDateFromFilterError] = useState('');
	const [startDateFilterError, setStartDateFilterError] = useState('');
	const [endDateFromFilterError, setEndDateFromFilterError] = useState('');
	const [endDateFilterError, setEndDateFilterError] = useState('');
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [detailsModal, setDetailsModal] = useState(false);
	const [promotionDetail, setPromotionDetail] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectPromotionDetail = (promotion) => {
		setDetailsModal(true);
		setPromotionDetail(promotion);
	};

	const [excelData, setExcelData] = useState([
		['shop', 'code', 'maximum', 'percent', 'start date', 'end date', 'type', 'condition'],
	]);

	const [getOrderPromotionsByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(
		GET_SHOP_PROMOTIONS
	);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getOrderPromotionsByAdminCount > 0 &&
			excelData.length === countData.getOrderPromotionsByAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getOrderPromotionsByAdmin, (item) => [
					item.shop ? item.shop.name : '-',
					item.promotionCode,
					item.maximumPromotion ? item.maximumPromotion.toFixed(2) : '-',
					item.percent ? item.percent : '-',
					moment(item.startDate).format('MM-DD-YYYY'),
					moment(item.endDate).format('MM-DD-YYYY'),
					item.type.replace(/_/g, ' ').toLowerCase(),
					item.condition.replace(/_/g, ' ').toLowerCase(),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Promotions.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getOrderPromotionsByAdmin({
				variables: { ...variables, skip: 0, limit: countData.getOrderPromotionsByAdminCount },
			});
		}
	};

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		type: undefined,
		_id: undefined,
		shopName: undefined,
		condition: undefined,
		fromFrom: undefined,
		from: undefined,
		toFrom: undefined,
		to: undefined,
		percentFrom: undefined,
		percent: undefined,
		maximumPromotionFrom: undefined,
		maximumPromotion: undefined,
		promotionCode: undefined,
		createdAtSort: -1,
	});

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getOrderPromotionsByAdminCount / perPage) - 1 &&
			countData.getOrderPromotionsByAdminCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getOrderPromotionsByAdminCount !== 0) {
			setPageCount(Math.ceil(countData.getOrderPromotionsByAdminCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (!startDateFilter) {
			setStartDateFilterError('');
		} else {
			if (
				moment(startDateFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(startDateFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setStartDateFilterError('');
			} else {
				setStartDateFilterError('invalid date');
			}
		}
	}, [startDateFilter]);

	useEffect(() => {
		if (!endDateFilter) {
			setEndDateFilterError('');
		} else {
			if (
				moment(endDateFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(endDateFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setEndDateFilterError('');
			} else {
				setEndDateFilterError('invalid date');
			}
		}
	}, [endDateFilter]);

	const onSubmitFilter = () => {
		let hasFilter =
			shopNameFilter ||
			variables.shopName ||
			codeFilter ||
			variables.promotionCode ||
			percentFromFilter ||
			variables.percentFrom ||
			percentFilter ||
			variables.percent ||
			amountFromFilter ||
			variables.maximumPromotionFrom ||
			amountFilter ||
			variables.maximumPromotion ||
			startDateFromFilter ||
			variables.fromFrom ||
			startDateFilter ||
			variables.from ||
			endDateFromFilter ||
			variables.toFrom ||
			endDateFilter ||
			variables.to ||
			!(typeFilter === 'ALL' && !variables.type) ||
			!(conditionFilter === 'ALL' && !variables.condition);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');
			if (codeFilter) {
				setVariables((variables) => ({
					...variables,
					promotionCode: codeFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, promotionCode: undefined }));
			}
			if (shopNameFilter) {
				setVariables((variables) => ({
					...variables,
					shopName: shopNameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, shopName: undefined }));
			}
			if (
				startDateFromFilter &&
				(moment(startDateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(startDateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					fromFrom: moment(startDateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, fromFrom: undefined }));
			}
			if (
				startDateFilter &&
				(moment(startDateFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(startDateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					from: moment(startDateFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, from: undefined }));
			}
			if (
				endDateFromFilter &&
				(moment(endDateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(endDateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					toFrom: moment(endDateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, toFrom: undefined }));
			}
			if (
				endDateFilter &&
				(moment(endDateFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(endDateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					to: moment(endDateFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, to: undefined }));
			}
			if (percentFromFilter) {
				setVariables((variables) => ({
					...variables,
					percentFrom: parseInt(percentFromFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, percentFrom: undefined }));
			}
			if (percentFilter) {
				setVariables((variables) => ({
					...variables,
					percent: parseInt(percentFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, percent: undefined }));
			}
			if (amountFromFilter) {
				setVariables((variables) => ({
					...variables,
					maximumPromotionFrom: parseInt(amountFromFilter),
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					maximumPromotionFrom: undefined,
				}));
			}
			if (amountFilter) {
				setVariables((variables) => ({
					...variables,
					maximumPromotion: parseInt(amountFilter),
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					maximumPromotion: undefined,
				}));
			}

			if (conditionFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					condition: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					condition: conditionFilter.replace(/ /g, '_').toUpperCase(),
				}));
			}
			if (typeFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					type: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					type: typeFilter.toUpperCase(),
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			type: undefined,
			_id: undefined,
			shopName: undefined,
			condition: undefined,
			fromFrom: undefined,
			from: undefined,
			toFrom: undefined,
			to: undefined,
			percentFrom: undefined,
			percent: undefined,
			maximumPromotionFrom: undefined,
			maximumPromotion: undefined,
			promotionCode: undefined,
			createdAtSort: -1,
		});
		setCodeFilter('');
		setCodeFilter('');
		setAmountFilter('');
		setPercentFilter('');
		setShopNameFilter('');
		setEndDateFilter('');
		setStartDateFilter('');
		setConditionFilter('ALL');
		setTypeFilter('ALL');
		setAmountFromFilter('');
		setPercentFromFilter('');
		setStartDateFromFilter('');
		setEndDateFromFilter('');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	const { loading, error, data } = useQuery(GET_SHOP_PROMOTIONS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_SHOP_PROMOTIONS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onSubmitFilter}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getOrderPromotionsByAdminCount === 0 ? 0 : pageCount * perPage + 1}</span>{' '}
							to{' '}
							<span>
								{pageCount * perPage + Math.min(perPage, data.getOrderPromotionsByAdmin.length)}
							</span>{' '}
							of <span>{countData.getOrderPromotionsByAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0);
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={data.getOrderPromotionsByAdmin.map((promotion) => ({
					id: (
						<CopyToClipboard text={promotion._id}>
							<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
								{promotion._id}
							</span>
						</CopyToClipboard>
					),
					code: (
						<CopyToClipboard text={promotion.promotionCode}>
							<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
								{promotion.promotionCode}
							</span>
						</CopyToClipboard>
					),
					shopName: promotion.shop && promotion.shop.name,
					type: promotion.type && (
						<div className={`${style.inlineTag} ${style[promotion.type.toLowerCase() + 'Tag']}`}>
							{promotion.type.toLowerCase()}
							<button onClick={() => onSelectPromotionDetail(promotion)} className={style.detailIconDiv}>
								<span>i</span>
							</button>
						</div>
					),
					condition: promotion.condition && (
						<div
							className={`${style.inlineTag} ${
								style[promotion.condition.replace(/_/g, '').toLowerCase() + 'Tag']
							}`}
						>
							{promotion.condition.toLowerCase()}
						</div>
					),
					shopId: (
						<CopyToClipboard text={promotion.shop && promotion.shop._id}>
							<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
								{promotion.shop && promotion.shop._id}
							</span>
						</CopyToClipboard>
					),
					percent: <div>{promotion.percent && promotion.percent + '%'} </div>,
					maximumPromotion: (
						<div>{promotion.maximumPromotion ? '$' + promotion.maximumPromotion.toFixed(2) : ''}</div>
					),
					startDate: moment(promotion.from).format('MM/DD/YYYY HH:mm'),
					endDate: moment(promotion.to).format('MM/DD/YYYY HH:mm'),
				}))}
				columns={[
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Shop</div>
								<CustomInput
									value={shopNameFilter}
									onChange={(e) => setShopNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'shopName',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Code</div>
								<CustomInput
									value={codeFilter}
									onChange={(e) => setCodeFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'code ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'code',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.titleDiv}>Maximum</div>
								<CustomInput
									value={amountFromFilter}
									onChange={(e) => setAmountFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={amountFilter}
									onChange={(e) => setAmountFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'maximumPromotion',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.titleDiv}>Percent</div>
								<CustomInput
									value={percentFromFilter}
									onChange={(e) => setPercentFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={percentFilter}
									onChange={(e) => setPercentFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'percent',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.titleDiv}>Start date</div>
								{startDateFromFilterError && (
									<div className={style.dateErrorDiv}>{startDateFromFilterError}</div>
								)}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={startDateFromFilter}
										closeOnSelect
										onChange={(date) => setStartDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{startDateFilterError && (
									<div className={style.dateErrorDiv}>{startDateFilterError}</div>
								)}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={startDateFilter}
										viewDate={startDateFilter}
										closeOnSelect
										onChange={(date) => setStartDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'startDate',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.titleDiv}>End date</div>
								{endDateFromFilterError && (
									<div className={style.dateErrorDiv}>{endDateFromFilterError}</div>
								)}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={endDateFromFilter}
										closeOnSelect
										onChange={(date) => setEndDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{endDateFilterError && <div className={style.dateErrorDiv}>{endDateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={endDateFilter}
										viewDate={endDateFilter}
										closeOnSelect
										onChange={(date) => setEndDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'endDate',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Type</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={typeFilter}
										onClick={(e) => setTypeFilter(e)}
										dropdownList={typeOptions}
									/>
								</div>
							</div>
						),
						accessor: 'type',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Condition</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={conditionFilter}
										onClick={(e) => setConditionFilter(e)}
										dropdownList={conditionOptions}
									/>
								</div>
							</div>
						),
						accessor: 'condition',
						sortable: false,
						filterable: false,
					},
					{
						Header: '',
						accessor: 'fix',
						sortable: false,
						filterable: false,
						width: 0,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getOrderPromotionsByAdminCount === 0
							? 1
							: Math.ceil(countData.getOrderPromotionsByAdminCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={detailsModal} transition={Transition} onClose={() => setDetailsModal(false)}>
				<DialogContent id="modal-slide-description">
					<PromotionDetails close={() => setDetailsModal(false)} promotion={promotionDetail} />
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Promotions;
