import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {  useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import style from './EnterCode.module.scss';
import logo from 'assets/img/sparkLoginLogo.svg';
import { endPoint } from 'utils/config';

const CHECK_VERIFICATION_CODE = gql`
	query checkShopAdminPhoneVerificationCode($phoneNumber: String!, $phoneVerificationCode: String!) {
		checkShopAdminPhoneVerificationCode(
			input: { phoneNumber: $phoneNumber, phoneVerificationCode: $phoneVerificationCode }
		) {
			phoneSignUpCode
		}
	}
`;

const EnterCode = (props) => {
	const [code, setCode] = useState('');
	const [disableButton, setDisableButton] = useState(false);

	const [codeErrortext, setCodeErrorText] = useState('');

	const [time, setTime] = useState(parseInt(window.localStorage.getItem('time')) || 0);

	const [seconds, setSeconds] = useState(time > 0 ? Math.floor(time % 60) : 0);
	const [minutes, setMinutes] = useState(time > 0 ? Math.floor(time / 60) : 0);

	let interval = useRef(null);

	useEffect(() => {
		interval.current = setInterval(function() {
			setTime((time) => time - 1);
		}, 1000);
	}, [,]);

	useEffect(() => {
		setSeconds(Math.floor(time % 60));
		setMinutes(Math.floor(time / 60));
	}, [, time]);

	useEffect(() => {
		window.localStorage.setItem('time', time);
		if (time === 0) {
			clearInterval(interval.current);
			window.location.href = endPoint + "/login";
		}
	}, [time]);

	const [checkShopAdminPhoneVerificationCode, { loading, error, data }] = useLazyQuery(CHECK_VERIFICATION_CODE);

	if (data) {
		console.log(data);
		window.localStorage.setItem('signupCode', data.checkShopAdminPhoneVerificationCode.phoneSignUpCode);
		window.location.href = endPoint + '/signup';
	}

	useEffect(() => {
		if (error) {
			setDisableButton(false);
			// window.location.href = endPoint + "/submitPhone";
			console.log(error);
			if (error.graphQLErrors && error.graphQLErrors.length > 0) {
				setCodeErrorText(error.graphQLErrors[0].message);
			}
		}
	}, [error, loading]);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	const onSubmit = () => {
		if (!code) {
			setCodeErrorText("code can't be empty");
		} else {
			setCodeErrorText('');
		}
		if (code) {
			setDisableButton(true);
			checkShopAdminPhoneVerificationCode({
				variables: {
					phoneVerificationCode: code.trim(),
					phoneNumber: window.localStorage.getItem('phoneNumber'),
				},
			});
		}
	};

	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDiv}>
			<div className={style.imageContainer}>
				<img alt="spark" src={logo} />
			</div>
			<div className={style.formContainer}>
				<div className={style.timeContainer}>
					remaining time : <span className={style.time}>{minutes}m</span> and{' '}
					<span className={style.time}>{seconds}s</span>
				</div>
				<div>
					<CustomInput
						value={code}
						onChange={(e) => setCode(e.target.value)}
						error={codeErrortext}
						labelText={codeErrortext ? codeErrortext : 'verification code'}
						id="float"
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							autoFocus: true,
						}}
					/>
				</div>
				<div className={style.buttonContainer}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={5}>
							<Link to="/login">
								<Button
									fullWidth
									size="lg"
									style={{ marginRight: '5px' }}
									onClick={onSubmit}
									color="whiteButton"
								>
									Edit phone
								</Button>
							</Link>
						</GridItem>
						<GridItem xs={12} sm={12} md={7}>
							<Button disabled={disableButton} size="lg" fullWidth onClick={onSubmit} color="yellow">
								Next
							</Button>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
};

export default EnterCode;
