import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import style from './EnterCode.module.scss';
import logo from 'assets/img/sparkLoginLogo.svg';
import { endPoint } from 'utils/config';

const CHECK_CODE = gql`
	mutation checkAdminForgotPasswordCode(
		$emailOrPhoneNumber: String!
		$emailOrPhoneNumberForgotPasswordCode: String!
	) {
		checkAdminForgotPasswordCode(
			checkAdminForgotPasswordInput: {
				emailOrPhoneNumber: $emailOrPhoneNumber
				emailOrPhoneNumberForgotPasswordCode: $emailOrPhoneNumberForgotPasswordCode
			}
		) {
			changePasswordCode
		}
	}
`;

const EnterForgetPasswordCode = (props) => {
	const [code, setCode] = useState('');
	const [disableButton, setDisableButton] = useState(false);

	const [codeErrortext, setCodeErrorText] = useState('');

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [time, setTime] = useState(parseInt(window.localStorage.getItem('time')) || 0);

	const [seconds, setSeconds] = useState(time > 0 ? Math.floor(time % 60) : 0);
	const [minutes, setMinutes] = useState(time > 0 ? Math.floor(time / 60) : 0);

	let interval = useRef(null);

	useEffect(() => {
		interval.current = setInterval(function() {
			setTime((time) => time - 1);
		}, 1000);
	}, [,]);

	useEffect(() => {
		setSeconds(Math.floor(time % 60));
		setMinutes(Math.floor(time / 60));
	}, [, time]);

	useEffect(() => {
		window.localStorage.setItem('time', time);
		if (time === 0) {
			clearInterval(interval.current);
			window.location.href = endPoint + '/login';
		}
	}, [time]);

	const [checkAdminForgotPasswordCode] = useMutation(CHECK_CODE);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	const onSubmit = () => {
		if (!code) {
			setCodeErrorText("code can't be empty");
		} else {
			setCodeErrorText('');
		}
		if (code) {
			setDisableButton(true);
			checkAdminForgotPasswordCode({
				variables: {
					emailOrPhoneNumber: window.localStorage.getItem('emailForgetPassword'),
					emailOrPhoneNumberForgotPasswordCode: code.trim(),
				},
			})
				.then((res) => {
					console.log(res);
					window.localStorage.setItem(
						'changePasswordCode',
						res.data.checkAdminForgotPasswordCode.changePasswordCode
					);
					window.location.href = endPoint + '/forgetPasswordChange';
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDiv}>
			<div className={style.imageContainer}>
				<img alt="spark" src={logo} />
			</div>
			<div className={style.formContainer}>
				<div className={style.timeContainer}>
					remaining time : <span className={style.time}>{minutes}m</span> and{' '}
					<span className={style.time}>{seconds}s</span>
				</div>
				<div>
					<CustomInput
						value={code}
						onChange={(e) => setCode(e.target.value)}
						error={codeErrortext}
						labelText={codeErrortext ? codeErrortext : 'verification code'}
						id="float"
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							autoFocus: true,
						}}
					/>
				</div>
				<div className={style.buttonContainer}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={7}>
							<Link to="/forgetPassword">
								<Button
									fullWidth
									size="lg"
									style={{ marginRight: '5px' }}
									onClick={onSubmit}
									color="whiteButton"
								>
									Edit phone or email
								</Button>
							</Link>
						</GridItem>
						<GridItem xs={12} sm={12} md={5}>
							<Button disabled={disableButton} size="lg" fullWidth onClick={onSubmit} color="yellow">
								Next
							</Button>
						</GridItem>
					</GridContainer>
				</div>
			</div>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default EnterForgetPasswordCode;
