import React, { useState } from 'react';
import Close from '@material-ui/icons/Close';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import style from 'superAdminViews/setting/defaults/EditItem.module.scss';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';

const UPDATE_CONSTANT = gql`
	mutation updateConstantByAdmin(
		$value: String!
		$typeOfAttribute: ConstantTypeOfAttribute!
		$description: String!
		$_id: ID
	) {
		updateConstantByAdmin(
			filters: { _id: $_id }
			data: { value: $value, description: $description, typeOfAttribute: $typeOfAttribute }
		) {
			_id
			value
			attribute
			description
			typeOfAttribute
		}
	}
`;

const EditConstant = ({ constant, close, refetch }) => {
	const typeOfAttributeOptions = ['NUMBER', 'PERCENTAGE'];

	const [value, setValue] = useState(constant.value);
	const [description, setDescription] = useState(constant.description);

	const [typeOfAttribute, setTypeOfAttribute] = useState(constant.typeOfAttribute);

	const [valueError, setValueError] = useState(false);
	const [descriptionError, setDescriptionError] = useState(false);

	const [updateConstantByAdmin, { data, error }] = useMutation(UPDATE_CONSTANT);

	const onChange = () => {
		if (!value) {
			setValueError(true);
		} else {
			setValueError(false);
		}
		if (!description) {
			setDescriptionError(true);
		} else {
			setDescriptionError(false);
		}
		if (value && description) {
			updateConstantByAdmin({
				variables: { value, typeOfAttribute, description, _id: constant._id },
			})
				.then((res) => {
					console.log(res);
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Edit</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={5} md={5} lg={5}>
									<div className={style.inputLabel}>Cost: </div>
								</GridItem>
								<GridItem xs={12} sm={7} md={7} lg={7}>
									<div className={style.dropdownContainer}>
										<CustomInput
											value={value}
											onChange={(e) => setValue(e.target.value)}
											error={valueError}
											labelText={valueError ? "value can't be empty*" : 'value'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={5} md={5} lg={5}>
									<div className={style.inputLabel}>Description: </div>
								</GridItem>
								<GridItem xs={12} sm={7} md={7} lg={7}>
									<div className={style.dropdownContainer}>
										<CustomInput
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											error={descriptionError}
											labelText={descriptionError ? "description can't be empty*" : 'description'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>

					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={5} md={5} lg={5}>
									<div className={style.inputLabel}>Type of the number: </div>
								</GridItem>
								<GridItem xs={12} sm={7} md={7} lg={7}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={typeOfAttribute}
											onClick={(e) => setTypeOfAttribute(e)}
											dropdownList={typeOfAttributeOptions}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.buttonsContainer}>
							<Button color="whiteButton" onClick={() => close()}>
								Cancel
							</Button>
							<Button color="yellow" onClick={onChange}>
								Submit
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
		</div>
	);
};

export default EditConstant;
