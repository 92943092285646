import React from "react";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ErrorPage from 'commonComponents/errorPage/ErrorPage'
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

import AddProduct from "commonComponents/addProduct/AddProduct";

const GET_PRODUCT = gql`
  query getProductByAdmin($_id: ID!) {
    getProductByAdmin(_id: $_id) {
      _id
      title
      description
      price
      photoUrl
      stock
      shopMenuName
      subMenuId
      promotion{
        percent
        discountFrom 
        discountTo
      }
      reqCarTypes{
        _id 
        name
      }
      attributes{
        attributeGroup{
          _id 
          name
        }
        att{
          _id 
          name
        }
      }
    }
  }
`;

const EditProduct = (props) => {
  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: { _id: props.productId },
    fetchPolicy: 'no-cache',

  });

  if(data){
    console.log(data)
  }

  console.log(props)

  if (loading)
    return (
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <BeatLoader
          css={css`
            display: block;
            margin: 0 auto;
            border-color: red;
          `}
          size={15}
          margin={2}
          color={"gray"}
          loading={true}
        />
      </div>
    );
  if (error) {
    console.log(error);
    return <ErrorPage/>;
  }

  return <AddProduct shopName={props.shopName} id={props.id} theme="superAdmin" type="superAdmin" product={data.getProductByAdmin} />;
};

export default EditProduct;