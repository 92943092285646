import React, { useState, useEffect } from 'react';
import moment from 'moment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Close from '@material-ui/icons/Close';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import {  graphqlUrl } from 'utils/config';
import style from './DriverDetails.module.scss';

const CarDetails = ({ car, close }) => {
	const innerImages =
		car.pictures.inner.length === 2
			? [`${graphqlUrl}${car.pictures.inner[0].url}`, `${graphqlUrl}${car.pictures.inner[1].url}`]
			: car.pictures.inner.length === 1
			? [`${graphqlUrl}${car.pictures.inner[0].url}`]
			: [];
	const [isInnerImagesOpen, setIsinnerImagesOpen] = useState(false);
	const [innerImageIndex, setInnerImageIndex] = useState(0);

	const outerImages =
		car.pictures.outer.length === 2
			? [`${graphqlUrl}${car.pictures.outer[0].url}`, `${graphqlUrl}${car.pictures.outer[1].url}`]
			: car.pictures.inner.length === 1
			? [`${graphqlUrl}${car.pictures.outer[0].url}`]
			: [];
	const [isOuterImagesOpen, setIsouterImagesOpen] = useState(false);
	const [outerImageIndex, setOuterImageIndex] = useState(0);

	const licenceImages = [`${graphqlUrl}${car.insurance.insuranceImageUrl}`];
	const [isLicenceImagesOpen, setIsLicenceImagesOpen] = useState(false);
	const [licenceImageIndex, setLicenceImageIndex] = useState(0);

	const registrationImages = [`${graphqlUrl}${car.registrationDocumentUrl}`];
	const [isregistrationImagesOpen, setIsRegistrationImagesOpen] = useState(false);
	const [registrationImageIndex, setRegistrationImageIndex] = useState(0);
	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Car details</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Car ID:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{car._id}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Plate:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{car.plate}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Registration document:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{car.registrationDocumentUrl.match(/\.pdf$/) ? (
											<a
												title="open file"
												target="_blank"
												href={`${graphqlUrl}/${car.registrationDocumentUrl}`}
											>
												<InsertDriveFileIcon style={{ color: 'gray', fontSize: '50px' }} />
											</a>
										) : (
											<img
												onClick={() => setIsRegistrationImagesOpen(true)}
												className={style.profileImage}
												src={`${graphqlUrl}${car.registrationDocumentUrl}`}
											/>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Brand:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{car.brand.name}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Model:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{car.model.name}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Type:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{car.carType && car.carType.type}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Description:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{car.description}</div>
								</GridItem>
							</GridContainer>
						</div>
						{!(car.carType.type === 'bike' || car.carType.type === 'motorcycle') && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<div className={style.label}>Inner photo:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<div className={style.value}>
											{car.pictures.inner[0] && car.pictures.inner[0].url && (
												<img
													onClick={() => setIsinnerImagesOpen(true)}
													className={style.profileImage}
													src={`${graphqlUrl}${car.pictures.inner[0].url}`}
												/>
											)}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Outer photo:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{car.pictures.outer[0] && car.pictures.outer[0].url && (
											<img
												onClick={() => setIsouterImagesOpen(true)}
												className={style.profileImage}
												src={`${graphqlUrl}${car.pictures.outer[0].url}`}
											/>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Manufacturing year:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{car.manufacturingYear}</div>
								</GridItem>
							</GridContainer>
						</div>

						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Ride:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{car.ride ? (
											<div className={style.iconCell}>
												<CheckIcon style={{ fontSize: '15px', color: 'green' }} />
											</div>
										) : (
											<div className={style.iconCell}>
												<CloseIcon style={{ fontSize: '15px', color: 'red' }} />
											</div>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Delivery:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{car.delivery ? (
											<div className={style.iconCell}>
												<CheckIcon style={{ fontSize: '15px', color: 'green' }} />
											</div>
										) : (
											<div className={style.iconCell}>
												<CloseIcon style={{ fontSize: '15px', color: 'red' }} />
											</div>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						{car.carType.type !== 'bike' && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<div className={style.label}>Insurance expire date:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<div className={style.value}>
											{car.insurance && moment(car.insurance.expireDate).format('MM/DD/YYYY')}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{car.carType.type !== 'bike' && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<div className={style.label}>Insurance photo:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<div className={style.value}>
											{car.insurance.insuranceImageUrl.match(/\.pdf$/) ? (
												<a
													title="open file"
													target="_blank"
													href={`${graphqlUrl}/${car.insurance.insuranceImageUrl}`}
												>
													<InsertDriveFileIcon style={{ color: 'gray', fontSize: '50px' }} />
												</a>
											) : (
												<img
													onClick={() => setIsLicenceImagesOpen(true)}
													className={style.profileImage}
													src={`${graphqlUrl}${car.insurance.insuranceImageUrl}`}
												/>
											)}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}

						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Color:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{car.color.name}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div>Car options</div>
						{Object.keys(car.carOptions)
							.filter((item) => item !== '__typename')
							.map((carOption) => (
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>
												{carOption.replace(/([A-Z])/g, ' $1').toLowerCase()}:
											</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												{car.carOptions[carOption] ? (
													<div className={style.iconCell}>
														<CheckIcon style={{ fontSize: '15px', color: 'green' }} />
													</div>
												) : (
													<div className={style.iconCell}>
														<CloseIcon style={{ fontSize: '15px', color: 'red' }} />
													</div>
												)}
											</div>
										</GridItem>
									</GridContainer>
								</div>
							))}
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.footer}>
				<Button color="yellow" onClick={close}>
					Close
				</Button>
			</div>
			{isInnerImagesOpen && (
				<Lightbox
					reactModalStyle={{ zIndex: '5000' }}
					mainSrc={innerImages[innerImageIndex]}
					nextSrc={innerImages[(innerImageIndex + 1) % innerImages.length]}
					prevSrc={innerImages[(innerImageIndex + innerImages.length - 1) % innerImages.length]}
					onCloseRequest={() => setIsinnerImagesOpen(false)}
					onMovePrevRequest={() =>
						setInnerImageIndex((innerImageIndex + innerImages.length - 1) % innerImages.length)
					}
					onMoveNextRequest={() => setInnerImageIndex((innerImageIndex + 1) % innerImages.length)}
				/>
			)}
			{isOuterImagesOpen && (
				<Lightbox
					reactModalStyle={{ zIndex: '5000' }}
					mainSrc={outerImages[outerImageIndex]}
					nextSrc={outerImages[(outerImageIndex + 1) % outerImages.length]}
					prevSrc={outerImages[(outerImageIndex + outerImages.length - 1) % outerImages.length]}
					onCloseRequest={() => setIsouterImagesOpen(false)}
					onMovePrevRequest={() =>
						setOuterImageIndex((outerImageIndex + outerImages.length - 1) % outerImages.length)
					}
					onMoveNextRequest={() => setOuterImageIndex((outerImageIndex + 1) % outerImages.length)}
				/>
			)}
			{isLicenceImagesOpen && (
				<Lightbox
					reactModalStyle={{ zIndex: '5000' }}
					mainSrc={licenceImages[licenceImageIndex]}
					nextSrc={licenceImages[(licenceImageIndex + 1) % licenceImages.length]}
					prevSrc={licenceImages[(licenceImageIndex + licenceImages.length - 1) % licenceImages.length]}
					onCloseRequest={() => setIsLicenceImagesOpen(false)}
					onMovePrevRequest={() =>
						setLicenceImageIndex((licenceImageIndex + licenceImages.length - 1) % licenceImages.length)
					}
					onMoveNextRequest={() => setLicenceImageIndex((licenceImageIndex + 1) % licenceImages.length)}
				/>
			)}
			{isregistrationImagesOpen && (
				<Lightbox
					reactModalStyle={{ zIndex: '5000' }}
					mainSrc={registrationImages[registrationImageIndex]}
					nextSrc={registrationImages[(registrationImageIndex + 1) % registrationImages.length]}
					prevSrc={
						registrationImages[
							(registrationImageIndex + registrationImages.length - 1) % registrationImages.length
						]
					}
					onCloseRequest={() => setIsRegistrationImagesOpen(false)}
					onMovePrevRequest={() =>
						setRegistrationImageIndex(
							(registrationImageIndex + registrationImages.length - 1) % registrationImages.length
						)
					}
					setRegistrationImageIndex={() =>
						setLicenceImageIndex((registrationImageIndex + 1) % registrationImages.length)
					}
				/>
			)}
		</div>
	);
};
export default CarDetails;
