import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import {endPoint} from 'utils/config';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from 'utils/PrivateRoute';
import ShopAdminLayout from 'layouts/shopAdmin/ShopAdmin.js';
import SuperAdminLayout from 'layouts/superAdmin/SuperAdmin';
import Login from 'layouts/shopAdmin/Login';
import ForgetPassword from 'layouts/shopAdmin/ForgetPassword';
import EnterForgetPasswordCode from 'layouts/shopAdmin/EnterForgetPasswordCode';
import ForgetPasswordChange from 'layouts/shopAdmin/ForgetPasswordChange';
import Success from 'layouts/shopAdmin/Success';
import Fail from 'layouts/shopAdmin/Fail';
import SuperLogin from 'layouts/superAdmin/Login';
import EnterCode from 'layouts/shopAdmin/EnterCode';
import BankInfo from 'layouts/shopAdmin/BankInfo';
import OrderPayment from 'layouts/shopAdmin/orderPayment/OrderPayment';
import { login } from 'utils/auth';
import Signup from 'layouts/shopAdmin/Signup';
import ShopAddShop from 'layouts/shopAdmin/ShopAddShop';
import EditShop from 'layouts/shopAdmin/EditShop'
import Rules from 'layouts/shopAdmin/Rules';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { gql } from 'apollo-boost';
import ApolloClient from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

import { ApolloProvider } from '@apollo/react-hooks';

import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0';

const hist = createBrowserHistory();

const token = localStorage.getItem('token');
const uri = `${window.env.API_URL}/graphql`;
const link = createUploadLink({
	headers: {
		authorization: token ? token : '',
	},
	uri,
});

const wsLink = new WebSocketLink({
	uri: window.env.API_SOCKET_URL,

	options: {
		reconnect: true,
		
		connectionParams: {
			authorization: token ? token : '',
		},
	},
});

const withErrorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		if (
			graphQLErrors[0].message.toLowerCase() === 'unauthorized' ||
			graphQLErrors[0].message.toLowerCase() === 'invalid token'
		) {
			getNewAccessToken();
		}
	}
	// if (networkError) {
	//   console.log("network error");
	// }
});

const AllLinks = ApolloLink.from([withErrorLink, link]);

const newLink = split(
	// split based on operation type
	({ query }) => {
		const definition = getMainDefinition(query);
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
	},
	wsLink,
	AllLinks
);

const client = new ApolloClient({
	connectToDevTools: true,
	cache: new InMemoryCache(),
	link: newLink,
});

const getNewAccessToken = () => {
	const refreshToken = window.localStorage.getItem('refreshToken');
	client
		.query({
			query: gql`
				query getNewAccessTokenByAdmin($refreshToken: String!) {
					getNewAccessTokenByAdmin(refreshToken: $refreshToken) {
						_id
						token {
							refreshToken
							accessToken
						}
					}
				}
			`,
			variables: {
				refreshToken: refreshToken,
			},
		})
		.then((res) => {
			console.log('got the refresh token!', res);
			login(
				res.data.getNewAccessTokenByAdmin.token.accessToken.toString(),
				res.data.getNewAccessTokenByAdmin.token.refreshToken.toString(),
				'refreshToken'
			);
		})
		.catch((err) => {
			console.log('didnt work', err);
			window.location.href = `${endPoint}/login`;
		});
};

const shopType = localStorage.getItem('shopType') ? localStorage.getItem('shopType') : 'Restaurant';
export const ThemeContext = createContext(shopType);

ReactDOM.render(
	<ApolloProvider client={client}>
		<ThemeContext.Provider value={shopType}>
			<Router history={hist}>
				<Switch>
					{/** <Route path="/rtl" component={RtlLayout} />
          <Route path="/auth" component={AuthLayout} />
<Route path="/admin" component={AdminLayout} /> **/}
					<Route path="/login" component={Login} />
					<Route path="/forgetPassword" component={ForgetPassword} />
					<Route path="/enterForgetPasswordCode" component={EnterForgetPasswordCode} />
					<Route path="/forgetPasswordChange" component={ForgetPasswordChange} />
					<Route path="//V1/Spark/api/login/superAdmin" component={SuperLogin} />
					<Route path="/signup" component={Signup} />
					<Route path="/rules" component={Rules} />
					<Route path="/submitCode" component={EnterCode} />
					<Route path="/addShop" component={ShopAddShop} />
					<Route path="/editShop" component={EditShop} />
					<Route path="/api/v1/paymentMethod" render={(props) => <BankInfo {...props} />} />
					<Route path="/payment/paymentMethod" render={(props) => <BankInfo forDriver={true} {...props} />} />
					<Route path="/payment/success" render={(props) => <Success {...props} />} />
					<Route path="/payment/fail" render={(props) => <Fail {...props} />} />
					<Route
						path="/api/v1/payment/order/:orderId"
						render={(props) => <OrderPayment for="order" {...props} />}
					/>
					<Route
						path="/api/v1/pay/trip/:tripId"
						render={(props) => <OrderPayment for="tripPay" {...props} />}
					/>
					<Route
						path="/api/v1/payment/trip/:tripId"
						render={(props) => <OrderPayment for="trip" {...props} />}
					/>
					<PrivateRoute component={ShopAdminLayout} path="/shopAdmin" type={shopType} />
					<PrivateRoute component={SuperAdminLayout} path="/superAdmin" />
					<Redirect from="/" to="/login" />
				</Switch>
			</Router>
		</ThemeContext.Provider>
	</ApolloProvider>,
	document.getElementById('root')
);
