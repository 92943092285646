import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactTable from 'react-table';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Modal from 'commonComponents/modal/Modal';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from '../orders/Orders.module.scss';
import ClearIcon from '@material-ui/icons/Clear';
import Reply from './Reply';
import ReviewDetails from './ReviewDetails';
import { ThemeContext } from 'index';

const GET_COMMENTS = gql`
	query getShopAdminCommentsOnShop(
		$limit: Int
		$skip: Int
		$_id: ID
		$passengerName: String
		$userComment: String
		$createdAtSort: Int
		$createdAt: Date
		$createdAtFrom: Date
	) {
		getShopAdminCommentsOnShop(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				passengerName: $passengerName
				userComment: $userComment
				createdAt: $createdAt
				createdAtFrom: $createdAtFrom
			}
			sort: { createdAt: $createdAtSort }
		) {
			_id
			readyComments {
				rate
				readyComment {
					_id
					type
				}
			}
			userComment
			shopAdminReply {
				comment {
					userComment
				}
			}
			user {
				_id
				fullName
			}
			order {
				_id
			}
			createdAt
		}
	}
`;

const GET_COMMENTS_COUNT = gql`
	query getCommentsOnShopByShopAdminCount(
		$_id: ID
		$passengerName: String
		$order: ID
		$createdAt: Date
		$createdAtFrom: Date
	) {
		getCommentsOnShopByShopAdminCount(
			filters: {
				_id: $_id
				passengerName: $passengerName
				order: $order
				createdAt: $createdAt
				createdAtFrom: $createdAtFrom
			}
		)
	}
`;

const DELETE_COMMENT = gql`
	mutation removeCommentOnShopByShopAdmin($id: ID!) {
		removeCommentOnShopByShopAdmin(id: $id) {
			message
		}
	}
`;

const Reviews = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		userComment: undefined,
		passengerName: undefined,
		createdAtSort: -1,
		createdAt: undefined,
		createdAtFrom: undefined,
	});

	const themeContext = useContext(ThemeContext);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
		buttonColor: 'orange',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
		buttonColor: 'green',
	};
	const theme = themeContext === 'Purchase' ? greenTheme : orangeTheme;

	const [idFilter, setIdFilter] = useState('');
	const [passengerNameFilter, setPassengerNameFilter] = useState('');
	const [textFilter, setTextFilter] = useState('');
	const [dateFromFilter, setDateFromFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');
	const [rateFilter, setRateFilter] = useState('All');
	const [typeFilter, setTypeFilter] = useState('All');

	const [idSort, setIdSort] = useState('');
	const [userIdSort, setUserIdSort] = useState('');
	const [rateSort, setRateSort] = useState('');
	const [dateSort, setDateSort] = useState('down');
	const [dateFromFilterError, setDateFromFilterError] = useState('');
	const [dateFilterError, setDateFilterError] = useState('');

	const [idFilterError, setIdFilterError] = useState(false);
	const [userIdFilterError, setUserIdFilterError] = useState(false);

	const [modal, setModal] = useState(false);
	const [replyReview, setReplyReview] = useState();

	const [detailModal, setDetailModal] = useState(false);
	const [detailReview, setDetailReview] = useState();

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [deleteModal, setDeleteModal] = useState(false);
	const [commentForDelete, setCommentForDelete] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectReview = (review) => {
		setModal(true);
		setReplyReview(review);
	};

	const onSelectReviewDetail = (review) => {
		setDetailModal(true);
		setDetailReview(review);
	};

	const onDeleteComment = (comment) => {
		setDeleteModal(true);
		setCommentForDelete(comment);
	};

	const [excelData, setExcelData] = useState([['customer', 'text', 'date']]);

	const [getShopAdminCommentsOnShop, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(
		GET_COMMENTS
	);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getCommentsOnShopByShopAdminCount > 0 &&
			excelData.length === countData.getCommentsOnShopByShopAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getShopAdminCommentsOnShop, (item) => [
					item.user ? item.user.fullName : '-',
					item.userComment,
					moment(item.createdAt).format('MM-DD-YYYY'),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Comments.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getShopAdminCommentsOnShop({
				variables: { ...variables, skip: 0, limit: countData.getCommentsOnShopByShopAdminCount },
			});
		}
	};

	const [removeCommentOnShopByShopAdmin] = useMutation(DELETE_COMMENT);

	const deleteComment = () => {
		removeCommentOnShopByShopAdmin({ variables: { id: commentForDelete._id } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const onIdSortChange = () => {
		if (!idSort) {
			setIdSort('up');
		} else {
			if (idSort === 'up') {
				setIdSort('down');
			} else {
				setIdSort('up');
			}
		}
	};

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('up');
			}
		}
	};

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}
	}, [dateSort]);

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFromFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getCommentsOnShopByShopAdminCount / perPage) - 1 &&
			countData.getCommentsOnShopByShopAdminCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getCommentsOnShopByShopAdminCount !== 0) {
			setPageCount(Math.ceil(countData.getCommentsOnShopByShopAdminCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	const { loading, error, data, refetch } = useQuery(GET_COMMENTS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_COMMENTS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (idFilter && idFilter.length !== 24) {
			setIdFilterError(true);
		} else {
			setIdFilterError(false);
		}
	}, [idFilter]);

	// useEffect(() => {
	// 	if (dateSort) {
	// 		if (dateSort === 'up') {
	// 			setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
	// 		} else if (dateSort === 'down') {
	// 			setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
	// 		}
	// 	} else {
	// 		setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
	// 	}
	// }, [dateSort]);

	const onFilterSubmit = () => {
		let hasFilter =
			dateFilter ||
			variables.createdAt ||
			dateFromFilter ||
			variables.createdAtFrom ||
			passengerNameFilter ||
			variables.passengerName ||
			textFilter ||
			variables.userComment;

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
				console.log(moment(dateFilter).format('YYYY-MM-DD'));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (passengerNameFilter) {
				setVariables((variables) => ({
					...variables,
					passengerName: passengerNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerName: undefined }));
			}

			if (textFilter) {
				setVariables((variables) => ({
					...variables,
					userComment: textFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, userComment: undefined }));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			userComment: undefined,
			passengerName: undefined,
			createdAtSort: -1,
			createdAt: undefined,
			createdAtFrom: undefined,
		});
		setDateFilter('');
		setDateFromFilter('');
		setDateSort('down');
		setTextFilter('');
		setRateFilter('');
		setTypeFilter('ALL');
		setPassengerNameFilter('');
		setRateSort('');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onFilterSubmit();
		}
	};

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}
	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onFilterSubmit} color={theme.primaryColor}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>
								{countData.getCommentsOnShopByShopAdminCount === 0 ? 0 : pageCount * perPage + 1}
							</span>{' '}
							to{' '}
							<span>
								{pageCount * perPage + Math.min(perPage, data.getShopAdminCommentsOnShop.length)}
							</span>{' '}
							of <span>{countData.getCommentsOnShopByShopAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0)
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={data.getShopAdminCommentsOnShop.map((review) => ({
					passengerName: review.user && review.user.fullName,
					date: moment(review.createdAt).format('MM/DD/YYYY HH:mm'),
					text: (
						<div
							style={{
								textAlign: 'center',
								maxHeight: '60px',
								overflow: 'hidden',
							}}
						>
							{review.userComment}
						</div>
					),
					actions: (
						<div className="actions-right">
							<button onClick={() => onSelectReviewDetail(review)} className={style.detailIconDiv}>
								<span>i</span>
							</button>
							{review.userComment && (
								<button
									onClick={() => onSelectReview(review)}
									className={`${style.inlineButton} ${style.inlineEditButton}`}
								>
									{review.shopAdminReply &&
									review.shopAdminReply.comment &&
									review.shopAdminReply.comment.userComment
										? 'view messages'
										: 'reply'}
								</button>
							)}
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Customer</div>
								<CustomInput
									value={passengerNameFilter}
									onChange={(e) => setPassengerNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerName',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Text</div>
								<CustomInput
									value={textFilter}
									onChange={(e) => setTextFilter(e.target.value)}
									id="regular"
									green={props.type === 'grocery'}
									warning={props.type === 'restaurant'}
									inputProps={{
										placeholder: 'text ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'text',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Date{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},

					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getCommentsOnShopByShopAdminCount === 0
							? 1
							: Math.ceil(countData.getCommentsOnShopByShopAdminCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Reply
						type={props.type}
						close={() => setModal(false)}
						refetch={() => refetch()}
						review={replyReview}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={detailModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<ReviewDetails type={props.type} close={() => setDetailModal(false)} review={detailReview} />
				</DialogContent>
			</Dialog>
			{commentForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteComment}
							text="Do you want to delete this comment?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Reviews;
