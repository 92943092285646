import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import Pagination from 'commonComponents/pagination/Pagination';

import ParcelWeightAndVolume from 'superAdminViews/setting/defaults/ParcelWeightAndVolume';

const GET_PARCEL_WEIGHTS = gql`
	query getParcelWeightsByAdmin($limit: Int, $skip: Int) {
		getParcelWeightsByAdmin(pagination: { limit: $limit, skip: $skip }) {
			_id
			name
			value
			order
			typeOfAttribute
		}
	}
`;

const GET_PARCEL_WEIGHTS_COUNT = gql`
	query getParcelWeightsByAdminCount {
		getParcelWeightsByAdminCount
	}
`;

const ParcelWeight = (props) => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
	});

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getParcelWeightsByAdminCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getParcelWeightsByAdminCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	const { loading, error, data, refetch } = useQuery(GET_PARCEL_WEIGHTS, {
		variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_PARCEL_WEIGHTS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		return <ErrorPage />;
	}
	return (
		<div>
			<ParcelWeightAndVolume
				refetch={() => refetch()}
				parcels={data.getParcelWeightsByAdmin}
				type="parcelWeight"
				perPage={perPage}
				pageCount={pageCount}
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={Math.ceil(countData.getParcelWeightsByAdminCount / perPage)}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
		</div>
	);
};

export default ParcelWeight;
