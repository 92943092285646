import React, { useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.js';
import style from 'shopAdminViews/orders/OrderDetails.module.scss';

const ReviewDetails = ({ review, close, type }) => {
	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const grayTheme = {
		checkboxColor: 'gray',
		primaryColor: 'primary',
	};

	let theme = orangeTheme;
	if (type === 'Purchase') {
		theme = greenTheme;
	} else if (type === 'Restaurant') {
		theme = orangeTheme;
	} else if (type === 'superAdmin') {
		theme = grayTheme;
	}

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Review Details</h4>
				</div>
			</div>
			<div className={style.infoContainer}>
				<div className={style.rowContainer}>
					<div className={style.label}>ID:</div>
					<div>{review._id}</div>
				</div>
				<div className={style.rowContainer}>
					<div className={style.label}>Customer ID:</div>
					<div>{review.user._id}</div>
				</div>
				<div className={style.rowContainer}>
					<div className={style.label}>Customer name:</div>
					<div>{review.user.fullName}</div>
				</div>
				<div className={style.rowContainer}>
					<div className={style.label}>order ID:</div>
					<div>{review.order._id}</div>
				</div>
				<div>
					<div className={style.rowContainer}>Rates:</div>
					{review.readyComments.map((readyComment) => (
						<div className={style.rowContainer}>
							<div className={style.label}>{readyComment.readyComment.type}:</div>
							<div>{readyComment.rate}</div>
						</div>
					))}
				</div>
			</div>
			<div className={style.footer}>
				<Button onClick={close}>Close</Button>
			</div>
		</div>
	);
};

export default ReviewDetails;
