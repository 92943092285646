import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { endPoint } from 'utils/config';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { css } from '@emotion/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BeatLoader from 'react-spinners/BeatLoader';
import Slide from '@material-ui/core/Slide';
import Modal from 'commonComponents/modal/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import style from 'commonComponents/addShopMenu/AddShopMenu.module.scss';

const GET_PRODUCTS_BY_ADMIN = gql`
	query getProductsByAdmin($shop: ID) {
		getProductsByAdmin(filters: { shop: $shop }) {
			_id
			title
		}
	}
`;

const ADD_SHOP_MENU_BY_ADMIN = gql`
	mutation createShopMenuByAdmin($shopId: ID!, $subMenus: [SubMenuInput]!) {
		createShopMenuByAdmin(inputs: { shopId: $shopId, subMenus: $subMenus }) {
			_id
			subMenus {
				name
			}
		}
	}
`;

const UPDATE_SHOP_MENU_BY_ADMIN = gql`
	mutation updateShopMenuByAdmin($_id: ID!, $subMenus: [SubMenuInput]) {
		updateShopMenuByAdmin(_id: $_id, inputs: { subMenus: $subMenus }) {
			_id
			subMenus {
				name
			}
		}
	}
`;

const GET_SHOPS = gql`
	query getShopsByAdmin($limit: Int, $skip: Int, $_id: ID) {
		getShopsByAdmin(pagination: { limit: $limit, skip: $skip }, filters: { _id: $_id }) {
			_id
			name
			rootCategory {
				_id
				title
			}
			shopMenu {
				_id
				subMenus {
					_id
					name
					products {
						_id
						title
					}
				}
			}
		}
	}
`;

const AddShopMenu = (props) => {
	const grayTheme = {
		checkboxColor: 'gray',
		primaryColor: 'yellow',
	};

	let theme = grayTheme;

	const [shopName, setShopName] = useState('restaurant');
	const [categoryText, setCategoryText] = useState('Menu item');
	const [mainText, setMainText] = useState('Menu');

	const [subMenus, setSubMenus] = useState([]);

	const [subMenuNameError, setSubMenuNameError] = useState(false);

	const [productNumber, setProductNumber] = useState([
		{ subMenuName: '', productIds: [], error: false, showAllProducts: false, isNew: true },
	]);

	const [showAllProducts, setShowAllProducts] = useState(false);

	const [addModal, setAddModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [noShopMenuModal, setNoShopMenuModal] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const { data: productsSuperData, error: productsSuperError, loading: productsSuperLoading } = useQuery(
		GET_PRODUCTS_BY_ADMIN,
		{ variables: { shop: props.id } }
	);

	const [createShopMenuByAdmin, { data: addData, error: addError }] = useMutation(ADD_SHOP_MENU_BY_ADMIN);

	const [updateShopMenuByAdmin, { data: updateData, error: updateError }] = useMutation(UPDATE_SHOP_MENU_BY_ADMIN);

	const { data: superShopData, loading: superShopLoading, error: superShopError, refetch: superRefetch } = useQuery(
		GET_SHOPS,
		{
			variables: {
				limit: 1,
				skip: 0,
				_id: props.id,
			},
		}
	);

	useEffect(() => {
		if (superShopData && superShopData.getShopsByAdmin[0].rootCategory) {
			if (superShopData.getShopsByAdmin[0].rootCategory.title === 'Restaurant') {
				setShopName('restaurant')
				setCategoryText('Menu item');
				setMainText('Menu');
			} else {
				setShopName('shop')
				setCategoryText('Category');
				setMainText('Categories');
			}
		}
	}, [, superShopData, superShopLoading]);

	useEffect(() => {
		if (props.type === 'superAdmin' && superShopData && superShopData.getShopsByAdmin[0].shopMenu !== null) {
			let array = [];
			superShopData.getShopsByAdmin[0].shopMenu.subMenus.map((subMenu) =>
				array.push({
					id: subMenu._id,
					subMenuName: subMenu.name,
					productIds: Array.from(subMenu.products, (product) => product._id),
				})
			);
			setProductNumber(array);
			console.log(array);
		}
	}, [, superShopData]);

	const onSubMenuNameChange = (e, i) => {
		console.log(e.target.value, i);

		setProductNumber(
			productNumber.map((sub, index) => (index === i ? { ...sub, subMenuName: e.target.value } : sub))
		);
	};

	const onSubMenuProductIdsChange = (e, i) => {
		setProductNumber(productNumber.map((sub, index) => (index === i ? { ...sub, productIds: e } : sub)));
	};

	const onAddProductClick = (SubMenuId) => {
		window.location.href = `${endPoint}/superAdmin/${props.shopName}/${props.id}/addProductInShopMenu/${SubMenuId}`;
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	const onSubmit = () => {
		if (productNumber.some((item) => !item.subMenuName)) {
			setErrorModal(true);
			setErrorText(`All ${mainText.toLowerCase()} should have a name.`);
		}
		if (!productNumber.some((item) => !item.subMenuName)) {
			// for super admin
			if (props.type === 'superAdmin' && superShopData && superShopData.getShopsByAdmin[0].shopMenu) {
				updateShopMenuByAdmin({
					variables: {
						_id: superShopData.getShopsByAdmin[0].shopMenu._id,
						subMenus: Array.from(productNumber, (subMenu) => ({
							name: subMenu.subMenuName,
							products: subMenu.productIds,
						})),
					},
				})
					.then((res) => {
						superRefetch();
						setAddModal(true);
					})
					.catch((err) => {
						console.log(err);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors[0].message) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			} else if (props.type === 'superAdmin') {
				createShopMenuByAdmin({
					variables: {
						shopId: props.id,
						subMenus: Array.from(productNumber, (subMenu) => ({
							name: subMenu.subMenuName,
							products: subMenu.productIds,
						})),
					},
				})
					.then((res) => {
						superRefetch();
						setAddModal(true);
					})
					.catch((err) => {
						console.log(err);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors[0].message) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}
		}
	};

	if (superShopLoading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (superShopError) {
		console.log(superShopError);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<div className={style.content}>
				<GridContainer>
					{productNumber.length < 1 && (
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<div style={{ padding: '20px 0', textAlign: 'center' }}>
									Your {shopName} does not have any {categoryText.toLowerCase()} yet, please add your list
									here.
								</div>
							</GridItem>
						</GridContainer>
					)}
					<div className={style.upperSentence}>
						* remember to save changes by pressing the "submit changes" button after each change.
					</div>
					{productNumber.map((sub, i) => (
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<div className={style.subContainer}>
									<GridItem xs={12} sm={12} md={12}>
										<div className={style.subMenuTitle}>
											{categoryText} {i + 1}
										</div>
									</GridItem>
									<GridItem xs={12} sm={12} md={12}>
										<div onKeyPress={(e) => handleEnter(e)} className={style.row}>
											<GridContainer>
												<GridItem xs={12} sm={5} md={5}>
													<div className={style.label}>{categoryText} name :</div>
												</GridItem>
												<GridItem xs={12} sm={7} md={7}>
													<CustomInput
														value={productNumber[i].subMenuName}
														onChange={(e) => onSubMenuNameChange(e, i)}
														error={sub.error}
														labelText={sub.error && `${categoryText} name can't be empty`}
														id="float"
														inputProps={{
															placeholder: `${categoryText} name`,
														}}
														formControlProps={{
															fullWidth: true,
														}}
													/>
												</GridItem>
											</GridContainer>
										</div>
									</GridItem>
									{!sub.showAllProducts && (
										<div className={style.toggleDiv}>
											<button
												onClick={() => {
													setProductNumber(
														productNumber.map((subMenu, index) =>
															index === i
																? {
																		...subMenu,
																		showAllProducts: true,
																  }
																: subMenu
														)
													);
												}}
											>
												<KeyboardArrowDownIcon />{' '}
												<div className={style.buttonSentence}>
													select from added{' '}
													{superShopData.getShopsByAdmin[0].rootCategory.title ===
													'Restaurant'
														? 'foods'
														: 'products'}
												</div>
											</button>
										</div>
									)}
									{sub.showAllProducts && (
										<div className={style.toggleDiv}>
											<button
												onClick={() => {
													setProductNumber(
														productNumber.map((subMenu, index) =>
															index === i
																? {
																		...subMenu,
																		showAllProducts: false,
																  }
																: subMenu
														)
													);
												}}
											>
												<KeyboardArrowUpIcon />
												<div className={style.buttonSentence}>
													hide{' '}
													{superShopData.getShopsByAdmin[0].rootCategory.title ===
													'Restaurant'
														? 'foods'
														: 'products'}
												</div>
											</button>
										</div>
									)}
									{sub.showAllProducts && (
										<GridItem xs={12} sm={12} md={12}>
											<div className={style.tagRow}>
												<GridContainer>
													<GridItem xs={12} sm={5} md={5}>
														<div className={style.tagLabel}>
															{`${
																superShopData.getShopsByAdmin[0].rootCategory.title ===
																'Restaurant'
																	? 'Foods'
																	: 'Products'
															} selected in this ${categoryText.toLowerCase()}`}{' '}
															:
														</div>
													</GridItem>
													<GridItem xs={12} sm={7} md={7}>
														<GridContainer>
															{productsSuperData &&
																productsSuperData.getProductsByAdmin.map((product) => (
																	<GridItem xs={12} sm={6} md={6} lg={4}>
																		<FormControlLabel
																			control={
																				<Checkbox
																					style={{
																						color: theme.checkboxColor,
																					}}
																					name={product.title}
																					checked={productNumber
																						.find(
																							(subMenu, index) =>
																								i === index &&
																								subMenu.subMenuName ===
																									sub.subMenuName
																						)
																						.productIds.includes(
																							product._id
																						)}
																					onChange={() => {
																						if (
																							productNumber
																								.find(
																									(subMenu, index) =>
																										i === index &&
																										subMenu.subMenuName ===
																											sub.subMenuName
																								)
																								.productIds.includes(
																									product._id
																								)
																						) {
																							setProductNumber(
																								productNumber.map(
																									(subMenu, index) =>
																										index === i
																											? {
																													...subMenu,
																													productIds: subMenu.productIds.filter(
																														(
																															item
																														) =>
																															item !==
																															product._id
																													),
																											  }
																											: subMenu
																								)
																							);
																						} else {
																							setProductNumber(
																								productNumber.map(
																									(subMenu, index) =>
																										index === i
																											? {
																													...subMenu,
																													productIds: [
																														...subMenu.productIds,
																														product._id,
																													],
																											  }
																											: subMenu
																								)
																							);
																						}
																					}}
																				/>
																			}
																			label={product.title}
																		/>
																	</GridItem>
																))}
														</GridContainer>
													</GridItem>
												</GridContainer>
											</div>
										</GridItem>
									)}
									<GridItem xs={12} sm={12} md={12}>
										<div className={style.tagRow}>
											<GridContainer>
												<GridItem xs={12} sm={12} md={12}>
													<div className={style.tagLabel}>
														{superShopData.getShopsByAdmin[0].rootCategory.title ===
														'Restaurant'
															? 'Foods'
															: 'Products'}
														:
													</div>
												</GridItem>
												<GridItem xs={12} sm={12} md={12}>
													<GridContainer>
														{productsSuperData &&
															productsSuperData.getProductsByAdmin
																.filter((product) =>
																	productNumber
																		.find(
																			(subMenu, index) =>
																				i === index &&
																				subMenu.subMenuName === sub.subMenuName
																		)
																		.productIds.includes(product._id)
																)
																.map((product) => (
																	<GridItem xs={12} sm={6} md={6} lg={4}>
																		<div className={style.productDiv}>
																			<div className={style.productTitle}>
																				{product.title}
																			</div>
																			<div>
																				<Link
																					to={`/superAdmin/${props.shopName}/${props.id}/editProduct/${product._id}`}
																				>
																					<button
																						className={`${style.inlineButton} ${style.inlineEditButton}`}
																					>
																						edit
																					</button>
																				</Link>
																				<button
																					onClick={() => {
																						setProductNumber(
																							productNumber.map(
																								(subMenu, index) =>
																									index === i
																										? {
																												...subMenu,
																												productIds: subMenu.productIds.filter(
																													(
																														item
																													) =>
																														item !==
																														product._id
																												),
																										  }
																										: subMenu
																							)
																						);
																					}}
																					className={`${style.inlineButton} ${style.inlineDeleteButton}`}
																				>
																					remove
																				</button>
																			</div>
																		</div>
																	</GridItem>
																))}
													</GridContainer>
												</GridItem>
											</GridContainer>
										</div>
									</GridItem>
									<GridItem xs={12} sm={12} md={12}>
										<div className={style.buttonsContainer}>
											<Button
												color="redButton"
												onClick={() =>
													setProductNumber(
														productNumber.filter(
															(item) =>
																item.subMenuName !== sub.subMenuName &&
																item.productIds !== sub.productIds
														)
													)
												}
											>
												Remove
											</Button>
											{superShopData &&
												superShopData.getShopsByAdmin[0].shopMenu !== null &&
												(!sub.isNew && (
													<Button onClick={() => onAddProductClick(sub.id)}>
														Add{' '}
														{superShopData.getShopsByAdmin[0].rootCategory.title ===
														'Restaurant'
															? 'food'
															: 'product'}
													</Button>
												))}

											{sub.isNew && (
												<Button disabled={sub.subMenuName ? false : true} onClick={onSubmit}>
													Submit
												</Button>
											)}
										</div>
									</GridItem>
								</div>
							</GridItem>
						</GridContainer>
					))}

					<GridItem xs={12} sm={12} md={12}>
						<div className={style.buttonsContainer}>
							<Button
								onClick={() =>
									setProductNumber((productNumber) => [
										...productNumber,
										{ subMenuName: '', productIds: [], error: false, isNew: true },
									])
								}
							>
								Add More {categoryText}
							</Button>
							<Button onClick={onSubmit} color={theme.primaryColor}>
								Submit Changes
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text={`${mainText} updated successfully.`} />
				</DialogContent>
			</Dialog>
			<Dialog open={noShopMenuModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setNoShopMenuModal(false)}
						type="notif"
						text={`Your ${shopName} does not have any ${categoryText.toLowerCase()} yet, please add your list here.`}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! fill the inputs correctly.'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default AddShopMenu;
