import React, { useState } from 'react';
import moment from 'moment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Close from '@material-ui/icons/Close';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import { endPoint, graphqlUrl } from 'utils/config';
import style from './DriverDetails.module.scss';

const DriverDetails = ({ driver, close }) => {
	const profileImages = [`${graphqlUrl}${driver.profileImageUrl}`];
	const [isProfileImagesOpen, setIsProfileImagesOpen] = useState(false);
	const [profileImageIndex, setProfileImageIndex] = useState(0);

	const licenceImages = [`${graphqlUrl}${driver.drivingLicence.photoUrl}`];
	const [isLicenceImagesOpen, setIsLicenceImagesOpen] = useState(false);
	const [licenceImageIndex, setLicenceImageIndex] = useState(0);
	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Driver details</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Full name:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{driver.fullName}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Profile image:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										<img
											onClick={() => setIsProfileImagesOpen(true)}
											className={style.profileImage}
											src={`${graphqlUrl}${driver.profileImageUrl}`}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Email:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>{driver.email}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Phone number:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{driver.phoneNumber}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Average rate:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{driver.averageRate && driver.averageRate.toFixed(2)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Number of rate:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{driver.numberOfRates}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>State:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{driver.state}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Has notification:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{driver.hasNotification ? (
											<div className={style.iconCell}>
												<CheckIcon style={{ fontSize: '15px', color: 'green' }} />
											</div>
										) : (
											<div className={style.iconCell}>
												<CloseIcon style={{ fontSize: '15px', color: 'red' }} />
											</div>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Verified:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{driver.isVerified ? (
											<div className={style.iconCell}>
												<CheckIcon style={{ fontSize: '15px', color: 'green' }} />
											</div>
										) : (
											<div className={style.iconCell}>
												<CloseIcon style={{ fontSize: '15px', color: 'red' }} />
											</div>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Email verified:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{driver.emailVerified ? (
											<div className={style.iconCell}>
												<CheckIcon style={{ fontSize: '15px', color: 'green' }} />
											</div>
										) : (
											<div className={style.iconCell}>
												<CloseIcon style={{ fontSize: '15px', color: 'red' }} />
											</div>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Phone number verified:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{driver.phoneNumberVerified ? (
											<div className={style.iconCell}>
												<CheckIcon style={{ fontSize: '15px', color: 'green' }} />
											</div>
										) : (
											<div className={style.iconCell}>
												<CloseIcon style={{ fontSize: '15px', color: 'red' }} />
											</div>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>License expire date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{driver.drivingLicence &&
											moment(driver.drivingLicence.expireDate).format('MM/DD/YYYY')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>License:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{driver.drivingLicence.photoUrl.match(/\.pdf$/) ? (
											<a
												title="open file"
												target="_blank"
												href={`${graphqlUrl}/${driver.drivingLicence.photoUrl}`}
											>
												<InsertDriveFileIcon style={{ color: 'gray', fontSize: '50px' }} />
											</a>
										) : (
											<img
												onClick={() => setIsLicenceImagesOpen(true)}
												className={style.profileImage}
												src={`${graphqlUrl}${driver.drivingLicence.photoUrl}`}
											/>
										)}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Gender:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{driver.gender}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Birth date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{driver.birthDate && moment(driver.birthDate).format('MM/DD/YYYY')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Added at:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{moment(driver.createdAt).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Stripe account ID:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{driver.stripeAccountId}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Default car ID:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>{driver.defaultCar && driver.defaultCar._id}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={5} md={5} lg={5}>
									<div className={style.label}>Default car plate:</div>
								</GridItem>
								<GridItem xs={12} sm={7} md={7} lg={7}>
									<div className={style.value}>{driver.defaultCar && driver.defaultCar.plate}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div>Address</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>Full:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>{driver.address.full}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Zip code:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{driver.address.zipCode}</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.footerShow}>
				<Button onClick={close}>Close</Button>
				<Button
					color="yellow"
					onClick={() => {
						window.location.href =
							endPoint + `/superAdmin/tripHistory/${driver.fullName}/${driver.phoneNumber}`;
					}}
				>
					Show trips history
				</Button>
			</div>
			{isProfileImagesOpen && (
				<Lightbox
					reactModalStyle={{ zIndex: '5000' }}
					mainSrc={profileImages[profileImageIndex]}
					nextSrc={profileImages[(profileImageIndex + 1) % profileImages.length]}
					prevSrc={profileImages[(profileImageIndex + profileImages.length - 1) % profileImages.length]}
					onCloseRequest={() => setIsProfileImagesOpen(false)}
					onMovePrevRequest={() =>
						setProfileImageIndex((profileImageIndex + profileImages.length - 1) % profileImages.length)
					}
					onMoveNextRequest={() => setProfileImageIndex((profileImageIndex + 1) % profileImages.length)}
				/>
			)}
			{isLicenceImagesOpen && (
				<Lightbox
					reactModalStyle={{ zIndex: '5000' }}
					mainSrc={licenceImages[licenceImageIndex]}
					nextSrc={licenceImages[(licenceImageIndex + 1) % licenceImages.length]}
					prevSrc={licenceImages[(licenceImageIndex + licenceImages.length - 1) % licenceImages.length]}
					onCloseRequest={() => setIsLicenceImagesOpen(false)}
					onMovePrevRequest={() =>
						setLicenceImageIndex((licenceImageIndex + licenceImages.length - 1) % licenceImages.length)
					}
					onMoveNextRequest={() => setLicenceImageIndex((licenceImageIndex + 1) % licenceImages.length)}
				/>
			)}
		</div>
	);
};
export default DriverDetails;
