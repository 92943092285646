import React, { useState, useEffect } from 'react';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactTable from 'react-table';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { graphqlUrl, endPoint } from 'utils/config';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import style from 'shopAdminViews/orders/Orders.module.scss';
import Modal from 'commonComponents/modal/Modal';

const GET_ADVERTISEMENTS = gql`
	query getAdvertisements($limit: Int, $skip: Int) {
		getAdvertisements(pagination: { limit: $limit, skip: $skip }) {
			_id
			title
			description
			redirectTo
			startAt
			endAt
			createdAt
			photoUrl
		}
	}
`;

const REMOVE_ADVERTISEMENT = gql`
	mutation removeAdvertisementByAdmin($idSet: [ID!]!) {
		removeAdvertisementByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const Advertisements = (props) => {
	const perPage = 100;
	const [pageCount, setPageCount] = useState(0);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount,
	});

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [deleteAdvertisement, setDeleteAdvertisement] = useState([]);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onSelectAdvertisementDelete = (id) => {
		setDeleteModal(true);
		setDeleteAdvertisement([id]);
	};

	const onSelectSomeDelete = () => {
		setDeleteSomeModal(true);
	};

	const { loading, error, data, refetch } = useQuery(GET_ADVERTISEMENTS, {
		variables: variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const [removeAdvertisementByAdmin, { data: mutationData }] = useMutation(REMOVE_ADVERTISEMENT);

	const onDeleteAdvertisement = () => {
		removeAdvertisementByAdmin({ variables: { idSet: deleteAdvertisement } })
			.then((res) => {
				refetch();
				setDeleteModal(false);
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const onDeleteSome = () => {
		removeAdvertisementByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				refetch();
				setIdsForDelete([]);
				setDeleteSomeModal(false);
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const linkPage = (route) => {
		window.location.href = endPoint + `/superAdmin/${route}`;
	};

	// for pagination
	const onNextPage = () => {
		setPageCount((pageCount) => pageCount + 1);
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		if (pageCount !== 0) {
			setPageCount(0);
		}
	};
	const onLastPage = () => {
		// TODO
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getAdvertisements.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div>
			<div>
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<div className={style.linkButtonDiv}>
							<Button color="yellow" fullWidth onClick={() => linkPage('addAdvertisement')}>
								Add advertisement
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			{idsForDelete.length > 0 && (
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<Button title="delete selected items" fullWidth color="redButton" onClick={onSelectSomeDelete}>
							Delete
						</Button>
					</GridItem>
				</GridContainer>
			)}
			<ReactTable
				data={data.getAdvertisements.map((advertisement) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(advertisement._id)}
								onChange={() => {
									if (idsForDelete.includes(advertisement._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== advertisement._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, advertisement._id]);
									}
								}}
							/>
						</div>
					),
					photo: (
						<img
							style={{ width: '90px', height: '90px' }}
							alt="profile-photo"
							src={`${graphqlUrl}${advertisement.photoUrl}`}
						/>
					),

					title: advertisement.title,
					description: advertisement.description,
					redirectTo: advertisement.redirectTo,
					startDate: moment(advertisement.startAt).format('MM/DD/YYYY HH:mm'),
					endDate: moment(advertisement.endAt).format('MM/DD/YYYY HH:mm'),
					date: moment(advertisement.createdAt).format('MM/DD/YYYY HH:mm'),
					actions: (
						<div className="actions-right">
							<IconButton
								id={advertisement._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, advertisement._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === advertisement._id}
								onClose={handleClose}
								onClick={handleClose}
							>
								<MenuItem
									onClick={() =>
										(window.location.href =
											endPoint + `/superAdmin/editAdvertisement/${advertisement._id}`)
									}
								>
									edit
								</MenuItem>
								<MenuItem onClick={() => onSelectAdvertisementDelete(advertisement._id)}>
									delete
								</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div className={style.fitTitleDivCheckbox}>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getAdvertisements.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getAdvertisements, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						minWidth: 60,
					},
					{
						Header: (
							<div className={style.fitTitleDiv}>
								<div>Photo </div>
							</div>
						),
						accessor: 'photo',
						sortable: false,
						filterable: false,
					},

					{
						Header: <div className={style.fitTitleDiv}>Title</div>,
						accessor: 'title',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Description</div>,
						accessor: 'description',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Link</div>,
						accessor: 'redirectTo',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Start date</div>,
						accessor: 'startDate',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: <div className={style.fitTitleDiv}>End date</div>,
						accessor: 'endDate',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: <div className={style.fitTitleDiv}>Created at</div>,
						accessor: 'date',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						minWidth: 60,
					},
				]}
				defaultPageSize={data.getAdvertisements.length > 0 ? data.getAdvertisements.length : 5}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			<Dialog open={deleteModal} transition={Transition} onClose={() => setDeleteModal(false)}>
				<DialogContent id="modal-slide-description">
					<Modal
						text="Do you want to delete this item?"
						close={() => setDeleteModal(false)}
						func={onDeleteAdvertisement}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						text="Do you want to delete these items?"
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={onDeleteSome}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Advertisements;
