import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import style from './Success.module.scss';

const Success = () => {
	return (
        <div className={style.mainDiv}>
        <div className={style.successIcon}><CheckCircleIcon/></div>
			<div>The process finished successfully.</div>
		</div>
	);
};

export default Success;
