import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import AddCar from 'superAdminViews/ride/addCar/AddCar';

const GET_CARS = gql`
	query getCarsByAdmin($_id: ID) {
		getCarsByAdmin(filters: { _id: $_id }) {
			_id
			plate
			carType {
				_id
				type
			}
			color {
				_id
				name
			}
			pictures {
				inner {
					url
				}
				outer {
					url
				}
			}
			insurance {
				insuranceImageUrl
				expireDate
			}
			registrationDocumentUrl
			description
			ride
			delivery
			carOptions {
				inHurry
				orderingForSomeoneElse
				pet
				bagsWithMe
				reserved
				airConditioner
				welcomeSign
				driverAssistant
				withInfant
				waitTimesInMinutes
				tipValue
			}
			brand {
				_id
				name
			}
			model {
				_id
				name
			}
			manufacturingYear
		}
	}
`;

const EditCar = (props) => {
	const { loading, error, data, refetch } = useQuery(GET_CARS, {
		variables: { _id: props.carId },
	});

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	if(data){
		console.log(data)
	}

	return <AddCar car={data.getCarsByAdmin[0]} />;
};

export default EditCar;
