import React from 'react';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';

import RepliedConversations from './RepliedConversation';
import UnrepliedConversations from './UnrepliedConversation';
import ClosedConversations from './ClosedConversations';
import style from 'superAdminViews/restaurant/shopPage/ShopPage.module.scss';

const Conversations = (props) => {
	return (
		<div className={style.mainDiv}>
			<div className={style.navContainer}>
				<div>
					<NavLink
						className={style.nav}
						activeClassName={style.activeNav}
						to={`/superAdmin/tickets/ClosedTickets`}
					>
						Closed conversations
					</NavLink>
				</div>
				<div>
					<NavLink
						className={style.nav}
						activeClassName={style.activeNav}
						to={`/superAdmin/tickets/RepliedTickets`}
					>
						Replied conversations
					</NavLink>
				</div>
				<div>
					<NavLink
						className={style.nav}
						activeClassName={style.activeNav}
						to={`/superAdmin/tickets/UnrepliedTickets`}
					>
						Unreplied conversations
					</NavLink>
				</div>
			</div>
			<div>
				<Switch>
					<Route
						exact={true}
						path="/superAdmin/tickets/ClosedTickets"
						render={() => <ClosedConversations key={Math.random()} />}
					/>
					<Route
						exact={true}
						path="/superAdmin/tickets/RepliedTickets"
						render={() => <RepliedConversations key={Math.random()} />}
					/>
					<Route
						exact={true}
						path="/superAdmin/tickets/UnrepliedTickets"
						render={() => <UnrepliedConversations key={Math.random()} />}
					/>

					<Redirect from="/superAdmin/tickets" to="/superAdmin/tickets/ClosedTickets" />
				</Switch>
			</div>
		</div>
	);
};

export default Conversations;
