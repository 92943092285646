import React, { useContext } from "react";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ErrorPage from 'commonComponents/errorPage/ErrorPage'
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

import { ThemeContext } from "index";
import AddPromotionForm from "commonComponents/addPromotion/AddPromotionForm";

const GET_PROMOTION = gql`
  query getOrderPromotionsByShopAdmin($_id: ID!, $limit: Int, $skip: Int) {
    getOrderPromotionsByShopAdmin(
      filters: { _id: $_id }
      pagination: { limit: $limit, skip: $skip }
    ) {
      _id
      condition
      type
      from
      to
      useLimitCount
      percent
      maximumPromotion
      promotionCode
    }
  }
`;

const ShopEditPromotion = (props) => {
  const theme = useContext(ThemeContext);

  const { loading, error, data } = useQuery(GET_PROMOTION, {
    variables: { _id: props.match.params.id, limit: 1, skip: 0 },
  });

  if (data) {
    console.log(data);
  }

  if (loading)
    return (
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <BeatLoader
          css={css`
            display: block;
            margin: 0 auto;
            border-color: red;
          `}
          size={15}
          margin={2}
          color={"gray"}
          loading={true}
        />
      </div>
    );
  if (error) {
    console.log(error);
    return <ErrorPage/>;
  }

  return <AddPromotionForm model="shopAdmin" theme={theme} promotion={data.getOrderPromotionsByShopAdmin[0]} />;
};

export default ShopEditPromotion;
