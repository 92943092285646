import React, { useState, useEffect } from 'react';
import Modal from 'commonComponents/modal/Modal';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import { endPoint } from 'utils/config';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ClearIcon from '@material-ui/icons/Clear';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactTable from 'react-table';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';

const SUSPEND_SHOP = gql`
	mutation suspendShopByAdmin($idSet: [ID!]!) {
		suspendShopByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const GET_ATTRIBUTES = gql`
	query getAttributes {
		getAttributes {
			_id
			name
		}
	}
`;

const GET_CATEGORIES = gql`
	query getCategories($limit: Int, $skip: Int, $parent: ID) {
		getCategories(pagination: { limit: $limit, skip: $skip }, filters: { parent: $parent }) {
			_id
			parent {
				title
			}
			title
			photoUrl
		}
	}
`;

const GET_SHOPS = gql`
	query getShopsByAdmin(
		$limit: Int
		$skip: Int
		$_id: ID
		$name: String
		$shopAdmin: ID
		$state: ShopStateInput
		$active: Boolean
		$budget: String
		$rootCategory: ID
		$averageRateFrom: Float
		$averageRate: Float
		$averageRateSort: Int
		$priceRangeSort: Int
		$attributes: [ID]
		$shopAdminName: String
		$createdAtSort: Int
		$verified: Boolean
	) {
		getShopsByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				name: $name
				shopAdmin: $shopAdmin
				active: $active
				state: $state
				budget: $budget
				averageRateFrom: $averageRateFrom
				averageRate: $averageRate
				rootCategory: $rootCategory
				attributes: $attributes
				shopAdminName: $shopAdminName
				verified: $verified
			}
			sort: { averageRate: $averageRateSort, priceRange: $priceRangeSort, createdAt: $createdAtSort }
		) {
			_id
			name
			averageRate
			active
			state
			budget
			shopAdmin {
				_id
				fullName
				email
				phoneNumber
			}
			shopMenu {
				_id
				subMenus {
					name
					products {
						_id
						title
					}
				}
			}
			rootCategory {
				title
			}
			categories {
				title
			}
			attributes {
				name
			}
		}
	}
`;

const GET_SHOPS_COUNT = gql`
	query getShopsByAdminCount(
		$_id: ID
		$name: String
		$shopAdmin: ID
		$state: ShopStateInput
		$active: Boolean
		$budget: String
		$rootCategory: ID
		$averageRateFrom: Float
		$averageRate: Float
		$attributes: [ID]
		$shopAdminName: String
		$verified: Boolean
	) {
		getShopsByAdminCount(
			filters: {
				_id: $_id
				name: $name
				shopAdmin: $shopAdmin
				active: $active
				averageRateFrom: $averageRateFrom
				averageRate: $averageRate
				state: $state
				budget: $budget
				rootCategory: $rootCategory
				attributes: $attributes
				shopAdminName: $shopAdminName
				verified: $verified
			}
		)
	}
`;

const DELETE_SHOP = gql`
	mutation deleteShopByAdmin($idSet: [ID!]!) {
		deleteShopByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const Restaurants = (props) => {
	const { loading: categoriesLoading, error: categoriesError, data: categoriesData } = useQuery(GET_CATEGORIES, {
		variables: {
			limit: 50,
			skip: 0,
			parent: null,
		},
	});

	const { loading: attributesLoading, error: attributesError, data: attributesData } = useQuery(GET_ATTRIBUTES);

	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const stateOptions = ['ALL', 'ACTIVE', 'SUSPENDED'];
	const budgetOptions = ['ALL', 'B', 'BB', 'BBB'];

	const [nameSort, setNameSort] = useState('');
	const [priceRangeSort, setPriceRangeSort] = useState('');
	const [rateSort, setRateSort] = useState('');
	const [budgetSort, setBudgetSort] = useState('');

	const [idFilter, setIdFilter] = useState('');
	const [nameFilter, setNameFilter] = useState('');
	const [shopAdminIdFilter, setShopAdminIdFilter] = useState('');
	const [stateFilter, setStateFilter] = useState(stateOptions[0]);
	const [activeFilter, setActiveFilter] = useState('');
	const [attributeFilter, setAttributeFilter] = useState([]);
	const [categoryFilter, setCategoryFilter] = useState([]);
	const [rateFromFilter, setRateFromFilter] = useState('');
	const [rateFilter, setRateFilter] = useState('');
	const [budgetFilter, setBudgetFilter] = useState(budgetOptions[0]);

	const [shopAdminNameFilter, setShopAdminNameFilter] = useState('');

	const [rootCategoryName, setRootCategoryName] = useState('ALL');
	const [rootCategoryId, setRootCategoryId] = useState();

	const [idFilterError, setIdFilterError] = useState(false);
	const [shopAdminIdFilterError, setShopAdminIdFilterError] = useState(null);
	const [checkAll, setCheckAll] = useState(false);
	const [suspendSomeModal, setSuspendSomeModal] = useState(false);
	const [actionWord, setActionWord] = useState('suspend');
	const [singleActionWord, setSingleActionWord] = useState('suspend');
	const [groupError, setGroupError] = useState(false);
	const [shopForSuspend, setShopForSuspend] = useState([]);

	const [suspendModal, setSuspendModal] = useState(false);

	const [idsForDelete, setIdsForDelete] = useState([]);

	const rootCategoryAlt = { Restaurant: 'restaurant', Purchase: 'shop' };

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [shopForDelete, setShopForDelete] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	useEffect(() => {
		if (idsForDelete.length > 0) {
			let firstItem = data.getShopsByAdmin.find((item) => item._id === idsForDelete[0]);
			if (firstItem.state === 'ACTIVE') {
				setActionWord('suspend');
			} else {
				setActionWord('activate');
			}
		}
	}, [idsForDelete]);

	useEffect(() => {
		if (shopForSuspend.length > 0) {
			let firstItem = data.getShopsByAdmin.find((item) => item._id === shopForSuspend[0]);
			if (firstItem.state === 'ACTIVE') {
				setSingleActionWord('suspend');
			} else {
				setSingleActionWord('activate');
			}
		}
	}, [shopForSuspend]);

	useEffect(() => {
		if (idsForDelete.length > 1) {
			idsForDelete.some((id) => {
				let firstItem = data.getShopsByAdmin.find((item) => item._id === idsForDelete[0]);
				let currentItem = data.getShopsByAdmin.find((item) => item._id === id);
				if (firstItem.state !== currentItem.state) {
					setGroupError(true);
					return -1;
				} else {
					setGroupError(false);
				}
			});
		} else {
			setGroupError(false);
		}
	}, [idsForDelete, checkAll]);

	const [excelData, setExcelData] = useState([['name', 'admin', 'rate', 'state', 'budget', 'type', 'attributes']]);

	const [getShopsByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_SHOPS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getShopsByAdminCount > 0 &&
			excelData.length === countData.getShopsByAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			console.log(lazyData);

			setExcelData([
				...excelData,
				...Array.from(lazyData.getShopsByAdmin, (item) => [
					item.name,
					item.shopAdmin ? item.shopAdmin.fullName : '-',
					item.rate,
					item.state.toLowerCase(),
					item.budget,
					item.rootCategory.title.toLowerCase(),
					Array.from(item.attributes, (item) => item.name).join(','),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Shops.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getShopsByAdmin({ variables: { ...variables, skip: 0, limit: countData.getShopsByAdminCount } });
		}
	};

	const onDeleteShop = (shop) => {
		setDeleteModal(true);
		setShopForDelete([shop._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const onSuspendShop = (shop) => {
		setSuspendModal(true);
		setShopForSuspend([shop._id]);
	};

	const onSuspendSome = () => {
		setSuspendSomeModal(true);
	};

	const [deleteShopByAdmin] = useMutation(DELETE_SHOP);
	const [suspendShopByAdmin] = useMutation(SUSPEND_SHOP);

	const deleteShop = () => {
		deleteShopByAdmin({ variables: { idSet: shopForDelete } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		deleteShopByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const suspendShop = () => {
		suspendShopByAdmin({ variables: { idSet: shopForSuspend } })
			.then((res) => {
				setSuspendModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setSuspendModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const suspendSome = () => {
		suspendShopByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setSuspendSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setSuspendSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const linkPage = (route) => {
		window.location.href = endPoint + `/superAdmin/${route}`;
	};

	useEffect(() => {
		if (categoriesData && rootCategoryName !== 'ALL') {
			if (rootCategoryName === 'shop') {
				setRootCategoryId(categoriesData.getCategories.find((category) => category.title === 'Purchase')._id);
			} else {
				setRootCategoryId(categoriesData.getCategories.find((category) => category.title === 'Restaurant')._id);
			}
		}
		if (rootCategoryName === 'ALL') {
			setRootCategoryId(undefined);
		}
	}, [, rootCategoryName]);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		name: undefined,
		shopAdminName: undefined,
		state: undefined,
		budget: undefined,
		verified: true,
		rootCategory: undefined,
		averageRateFrom: undefined,
		averageRate: undefined,
		attributes: undefined,
		priceRangeSort: undefined,
		averageRateSort: undefined,
		createdAtSort: -1,
	});

	useEffect(() => {
		if (idFilter && idFilter.length !== 24) {
			setIdFilterError(true);
		} else {
			setIdFilterError(false);
		}
	}, [idFilter]);

	useEffect(() => {
		if (shopAdminIdFilter && shopAdminIdFilter.length !== 24) {
			setShopAdminIdFilterError(true);
		} else {
			setShopAdminIdFilterError(false);
		}
	}, [shopAdminIdFilter]);

	const onRateSortChange = () => {
		if (!rateSort) {
			setRateSort('up');
		} else {
			if (rateSort === 'up') {
				setRateSort('down');
			} else {
				setRateSort('');
			}
		}
	};
	const onPriceRangeSortChange = () => {
		if (!priceRangeSort) {
			setPriceRangeSort('up');
		} else {
			if (priceRangeSort === 'up') {
				setPriceRangeSort('down');
			} else {
				setPriceRangeSort('');
			}
		}
	};

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getShopsByAdminCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getShopsByAdminCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getShopsByAdmin.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	useEffect(() => {
		if (priceRangeSort) {
			if (priceRangeSort === 'up') {
				setVariables((variables) => ({ ...variables, priceRangeSort: 0 }));
			} else if (priceRangeSort === 'down') {
				setVariables((variables) => ({ ...variables, priceRangeSort: -1 }));
			}
		} else {
			setVariables((variables) => ({
				...variables,
				priceRangeSort: undefined,
			}));
		}

		if (rateSort) {
			if (rateSort === 'up') {
				setVariables((variables) => ({ ...variables, averageRateSort: 0 }));
			} else if (rateSort === 'down') {
				setVariables((variables) => ({ ...variables, averageRateSort: -1 }));
			}
		} else {
			setVariables((variables) => ({
				...variables,
				averageRateSort: undefined,
			}));
		}
	}, [priceRangeSort, rateSort]);

	const onSubmitFilter = () => {
		let hasFilter =
			nameFilter ||
			variables.name ||
			shopAdminNameFilter ||
			variables.shopAdminName ||
			rootCategoryId ||
			variables.rootCategory ||
			rateFromFilter ||
			variables.averageRateFrom ||
			rateFilter ||
			variables.averageRate ||
			!(stateFilter === 'ALL' && !variables.state) ||
			!(budgetFilter === 'ALL' && !variables.budget) ||
			!(attributeFilter.length === 0 && !variables.attributes);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');

			if (nameFilter) {
				setVariables((variables) => ({
					...variables,
					name: nameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, name: undefined }));
			}
			if (rateFromFilter) {
				setVariables((variables) => ({
					...variables,
					averageRateFrom: parseFloat(rateFromFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, averageRateFrom: undefined }));
			}
			if (rateFilter) {
				setVariables((variables) => ({
					...variables,
					averageRate: parseFloat(rateFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, averageRate: undefined }));
			}
			if (shopAdminNameFilter) {
				setVariables((variables) => ({
					...variables,
					shopAdminName: shopAdminNameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, shopAdminName: undefined }));
			}

			if (rootCategoryId) {
				setVariables((variables) => ({
					...variables,
					rootCategory: rootCategoryId,
				}));
			} else {
				setVariables((variables) => ({ ...variables, rootCategory: undefined }));
			}
			if (stateFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					state: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					state: stateFilter.toUpperCase(),
				}));
			}
			if (budgetFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					budget: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					budget: budgetFilter.toUpperCase(),
				}));
			}
			if (attributeFilter.length === 0) {
				setVariables((variables) => ({ ...variables, attributes: undefined }));
			} else {
				setVariables((variables) => ({
					...variables,
					attributes: attributeFilter,
				}));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			name: undefined,
			shopAdminName: undefined,
			state: undefined,
			budget: undefined,
			verified: true,
			rootCategory: undefined,
			averageRateFrom: undefined,
			averageRate: undefined,
			attributes: undefined,
			priceRangeSort: undefined,
			averageRateSort: undefined,
			createdAtSort: -1,
		});
		setNameFilter('');
		setRateFromFilter('');
		setRateFilter('');
		setRateSort('');
		setPriceRangeSort('');
		setShopAdminNameFilter('');
		setAttributeFilter([]);
		setBudgetFilter('ALL');
		setStateFilter('ALL');
		setActiveFilter('ALL');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	const { loading, error, data, refetch } = useQuery(GET_SHOPS, {
		variables: variables,
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_SHOPS_COUNT, {
		variables: variables,
		fetchPolicy: 'no-cache',
	});

	if (loading || categoriesLoading || attributesLoading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error || categoriesError || attributesError) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			<div>
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<div className={style.linkButtonDiv}>
							<Button color="yellow" fullWidth onClick={() => linkPage('addShop')}>
								Add shop
							</Button>
						</div>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<div className={style.linkButtonDiv}>
							<Button color="yellow" fullWidth onClick={() => linkPage('shopVerifications')}>
								Verifications
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<div>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}
				{idsForDelete.length > 0 && (
					<Button title="delete selected items" color="redButton" onClick={onDeleteSome}>
						Delete
					</Button>
				)}
				{idsForDelete.length > 0 && !groupError && (
					<Button
						title={`${actionWord} selected items`}
						color={actionWord === 'suspend' ? 'redButton' : 'greenButton'}
						onClick={onSuspendSome}
					>
						{actionWord}
					</Button>
				)}
			</div>
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onSubmitFilter}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getShopsByAdminCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getShopsByAdmin.length)}</span> of{' '}
							<span>{countData.getShopsByAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0);
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={data.getShopsByAdmin.map((shop) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(shop._id)}
								onChange={() => {
									if (idsForDelete.includes(shop._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== shop._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, shop._id]);
									}
								}}
							/>
						</div>
					),
					name: shop.name,
					shopAdminName: shop.shopAdmin && shop.shopAdmin.fullName,
					state: shop.state && (
						<div className={`${style.inlineTag} ${style[shop.state.toLowerCase() + 'Tag']}`}>
							{shop.state.toLowerCase()}
						</div>
					),
					budget: shop.budget && (
						<div className={`${style.inlineTag} ${style[shop.budget.toLowerCase() + 'Tag']}`}>
							{shop.budget}
						</div>
					),
					priceRange: shop.priceRange ? shop.priceRange : '',
					attributes: (
						<div style={{ height: '80px', overflowY: 'auto', overflowX: 'hidden', paddingTop: '25px' }}>
							{' '}
							{shop.attributes.map((item) => (
								<div className={`${style.inlineTag} ${style.pendingTag}`} style={{ margin: '3px' }}>
									{item.name.toLowerCase()}
								</div>
							))}
						</div>
					),
					rate: <div>{shop.averageRate.toFixed(2)}</div>,
					categories: shop.rootCategory && (
						<div className={`${style.inlineTag} ${style[shop.rootCategory.title.toLowerCase() + 'Tag']}`}>
							{rootCategoryAlt[shop.rootCategory.title]}
						</div>
					),
					actions: (
						<div className="actions-right">
							<IconButton
								id={shop._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, shop._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === shop._id}
								onClick={handleClose}
								onClose={handleClose}
							>
								<MenuItem
									onClick={() => {
										window.location.href =
											endPoint + `/superAdmin/${shop.name.replace(/\s+/g, '_')}/${shop._id}`;
									}}
								>
									view more
								</MenuItem>
								<MenuItem onClick={() => onSuspendShop(shop)}>
									{shop.state === 'ACTIVE' ? 'suspend' : 'activate'}
								</MenuItem>
								<MenuItem onClick={() => onDeleteShop(shop)}>delete</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getShopsByAdmin.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getShopsByAdmin, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Name</div>
								<CustomInput
									value={nameFilter}
									onChange={(e) => setNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Admin</div>
								<CustomInput
									value={shopAdminNameFilter}
									onChange={(e) => setShopAdminNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'shopAdminName',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onRateSortChange}>
									Rate{' '}
									<span>
										{rateSort && (
											<span>
												{rateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={rateFromFilter}
									onChange={(e) => setRateFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={rateFilter}
									onChange={(e) => setRateFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'rate',
						sortable: false,
						filterable: false,
					},

					{
						Header: (
							<div>
								<div className={style.sortDiv}>State</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={stateFilter}
										onClick={(e) => setStateFilter(e)}
										dropdownList={stateOptions}
									/>
								</div>
							</div>
						),
						accessor: 'state',
						sortable: false,
						filterable: false,
					},

					{
						Header: (
							<div>
								<div className={style.sortDiv} onClick={onPriceRangeSortChange}>
									Budget{' '}
									<span>
										{priceRangeSort && (
											<span>
												{priceRangeSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={budgetFilter}
										onClick={(e) => setBudgetFilter(e)}
										dropdownList={budgetOptions}
									/>
								</div>
							</div>
						),
						accessor: 'budget',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Type</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={rootCategoryName}
										onClick={(e) => setRootCategoryName(e)}
										dropdownList={
											categoriesData && [
												'ALL',
												...Array.from(
													categoriesData.getCategories,
													(category) => rootCategoryAlt[category.title]
												),
											]
										}
									/>
								</div>
							</div>
						),
						accessor: 'categories',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Attributes</div>
								<FormControl fullWidth>
									<Select
										multiple
										value={attributeFilter}
										onChange={(e) => setAttributeFilter(e.target.value)}
										inputProps={{
											name: 'multipleSelect',
											id: 'multiple-select',
										}}
									>
										{attributesData.getAttributes.map((attribute) => (
											<MenuItem value={attribute._id}>{attribute.name}</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						),
						accessor: 'attributes',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={Math.ceil(countData.getShopsByAdminCount / perPage)}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={suspendSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setSuspendSomeModal(false);
							setIdsForDelete([]);
						}}
						func={suspendSome}
						text={`Do you want to ${actionWord} these shops?`}
					/>
				</DialogContent>
			</Dialog>
			{shopForSuspend && (
				<Dialog open={suspendModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setSuspendModal(false)}
							func={suspendShop}
							text={`Do you want to ${singleActionWord} this shop?`}
						/>
					</DialogContent>
				</Dialog>
			)}
			{shopForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteShop}
							text="Do you want to delete this shop?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these shops?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Restaurants;
