import React from 'react';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';

import Brands from 'superAdminViews/setting/carOptions/Brands';
import CarTypes from 'superAdminViews/setting/carOptions/CarTypes';
import Models from 'superAdminViews/setting/carOptions/Models';
import Colors from 'superAdminViews/setting/carOptions/Colors';
import style from 'superAdminViews/restaurant/shopPage/ShopPage.module.scss';

const CarOptions = (props) => {
	return (
		<div className={style.mainDiv}>
			<div className={style.navContainer}>
				<div>
					<NavLink
						className={style.nav}
						activeClassName={style.activeNav}
						to={`/superAdmin/vehicleOptions/brands`}
					>
						Brands
					</NavLink>
				</div>
				<div>
					<NavLink
						className={style.nav}
						activeClassName={style.activeNav}
						to={`/superAdmin/vehicleOptions/models`}
					>
						{' '}
						Models
					</NavLink>
				</div>
				<div>
					<NavLink
						className={style.nav}
						activeClassName={style.activeNav}
						to={`/superAdmin/vehicleOptions/carTypes`}
					>
						Car types
					</NavLink>
				</div>
				<div>
					<NavLink
						className={style.nav}
						activeClassName={style.activeNav}
						to={`/superAdmin/vehicleOptions/colors`}
					>
						{' '}
						Colors
					</NavLink>
				</div>
			</div>
			<div>
				<Switch>
					<Route
						exact={true}
						path="/superAdmin/vehicleOptions/brands"
						render={() => <Brands key={Math.random()} />}
					/>
					<Route
						exact={true}
						path="/superAdmin/vehicleOptions/models"
						render={() => <Models key={Math.random()} />}
					/>
					<Route
						exact={true}
						path="/superAdmin/vehicleOptions/carTypes"
						render={() => <CarTypes key={Math.random()} />}
					/>
					<Route
						exact={true}
						path="/superAdmin/vehicleOptions/colors"
						render={() => <Colors key={Math.random()} />}
					/>
					<Redirect from="/superAdmin/vehicleOptions" to="/superAdmin/vehicleOptions/brands" />
				</Switch>
			</div>
		</div>
	);
};

export default CarOptions;
