import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { graphqlUrl, endPoint } from 'utils/config';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import { gql } from 'apollo-boost';
import Modal from 'commonComponents/modal/Modal';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactTable from 'react-table';
import Button from 'components/CustomButtons/Button.js';
import Pagination from 'commonComponents/pagination/Pagination';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';
import DriverDetails from './DriverDetails';
import Cars from './Cars';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ClearIcon from '@material-ui/icons/Clear';

const GET_DRIVRES = gql`
	query getDriversByAdmin(
		$limit: Int
		$skip: Int
		$_id: ID
		$fullName: String
		$averageRateFrom: Float
		$averageRate: Float
		$phoneNumber: String
		$state: DriverState
		$gender: String
		$createdAtFrom: Date
		$createdAt: Date
		$fullNameSort: Int
		$averageRateSort: Int
		$createdAtSort: Int
		$isVerified: Boolean
		$IncompleteInformationDrivers: Boolean
	) {
		getDriversByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				fullName: $fullName
				averageRateFrom: $averageRateFrom
				averageRate: $averageRate
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
				phoneNumber: $phoneNumber
				state: $state
				gender: $gender
				isVerified: $isVerified
				IncompleteInformationDrivers: $IncompleteInformationDrivers
			}
			sort: { fullName: $fullNameSort, averageRate: $averageRateSort, createdAt: $createdAtSort }
		) {
			_id
			fullName
			phoneNumber
			gender
			profileImageUrl
			workStatus
			email
			drivingLicence {
				photoUrl
				licenceId
				expireDate
			}
			state
			createdAt
			hasNotification
			birthDate
			isVerified
			emailVerified
			phoneNumberVerified
			address {
				full
				zipCode
			}
			stripeAccountId
			averageRate

			numberOfRates
			car {
				_id
				plate
				color {
					name
				}
				pictures {
					inner {
						url
					}
					outer {
						url
					}
				}
				insurance {
					insuranceImageUrl
					expireDate
				}
				registrationDocumentUrl
				description
				ride
				delivery
				carOptions {
					inHurry
					orderingForSomeoneElse
					pet
					bagsWithMe
					reserved
					airConditioner
					welcomeSign
					driverAssistant
					withInfant
					waitTimesInMinutes
					tipValue
				}
				brand {
					name
				}
				model {
					name
				}
				carType {
					type
				}
				manufacturingYear
			}
			defaultCar {
				_id
				plate
				color {
					name
				}
				pictures {
					inner {
						url
					}
					outer {
						url
					}
				}
				insurance {
					insuranceImageUrl
					expireDate
				}
				registrationDocumentUrl
				description
				ride
				delivery
				carOptions {
					inHurry
					orderingForSomeoneElse
					pet
					bagsWithMe
					reserved
					airConditioner
					welcomeSign
					driverAssistant
					withInfant
					waitTimesInMinutes
					tipValue
				}
				brand {
					name
				}
				model {
					name
				}
				carType {
					type
				}
				manufacturingYear
			}
		}
	}
`;

const GET_DRIVRES_COUNT = gql`
	query getDriversCountByAdmin(
		$_id: ID
		$fullName: String
		$averageRateFrom: Float
		$averageRate: Float
		$phoneNumber: String
		$createdAtFrom: Date
		$createdAt: Date
		$state: DriverState
		$gender: String
		$isVerified: Boolean
		$IncompleteInformationDrivers: Boolean
	) {
		getDriversCountByAdmin(
			filters: {
				_id: $_id
				fullName: $fullName
				averageRateFrom: $averageRateFrom
				averageRate: $averageRate
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
				phoneNumber: $phoneNumber
				state: $state
				gender: $gender
				isVerified: $isVerified
				IncompleteInformationDrivers: $IncompleteInformationDrivers
			}
		)
	}
`;

const SUSPEND_DRIVER = gql`
	mutation suspendDriverByAdmin($idSet: [ID!]!) {
		suspendDriverByAdmin(idSet: $idSet) {
			_id
			state
		}
	}
`;

const Drivers = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const genderOptions = ['ALL', 'MALE', 'FEMALE'];
	const stateOptions = ['ALL', 'ACTIVE', 'SUSPENDED'];
	const [nameSort, setNameSort] = useState('');
	const [rateSort, setRateSort] = useState('');
	const [dateSort, setDateSort] = useState('down');
	const [dateFromFilterError, setDateFromFilterError] = useState('');
	const [dateFilterError, setDateFilterError] = useState('');

	const [idFilter, setIdFilter] = useState('');
	const [nameFilter, setNameFilter] = useState('');
	const [phoneNumberFilter, setPhoneNumberFilter] = useState('');
	const [rateFromFilter, setRateFilter] = useState('');
	const [rateFilter, setRateFromFilter] = useState('');
	const [stateFilter, setStateFilter] = useState(stateOptions[0]);
	const [genderFilter, setGenderFilter] = useState(genderOptions[0]);
	const [dateFromFilter, setDateFromFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		_id: undefined,
		fullName: undefined,
		state: undefined,
		gender: undefined,
		phoneNumber: undefined,
		averageRateFrom: undefined,
		averageRate: undefined,
		createdAtFrom: undefined,
		createdAt: undefined,
		fullNameSort: undefined,
		averageRateSort: undefined,
		createdAtSort: -1,
		isVerified: true,
		IncompleteInformationDrivers: false,
	});

	const { loading, error, data, refetch } = useQuery(GET_DRIVRES, {
		variables: variables,
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,
	});

	const { data: countData, error: countError, loading: countLoading } = useQuery(GET_DRIVRES_COUNT, {
		variables: variables,
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,
	});

	const [checkAll, setCheckAll] = useState(false);
	const [idsForSuspend, setIdsForSuspend] = useState([]);
	const [suspendSomeModal, setSuspendSomeModal] = useState(false);
	const [actionWord, setActionWord] = useState('suspend');
	const [groupError, setGroupError] = useState(false);

	const [suspendErrorModal, setSuspendErrorModal] = useState(false);
	const [suspendErrorText, setSuspendErrorText] = useState('');

	const [modal, setModal] = useState(false);
	const [driverDetail, setDriverDetail] = useState();

	const [suspendModal, setSuspendModal] = useState(false);
	const [driverForSuspend, setDriverForSuspend] = useState([]);

	useEffect(() => {
		if (idsForSuspend.length > 0) {
			let firstItem = data.getDriversByAdmin.find((item) => item._id === idsForSuspend[0]);
			if (firstItem.state === 'ACTIVE') {
				setActionWord('suspend');
			} else {
				setActionWord('activate');
			}
		}
	}, [idsForSuspend]);

	useEffect(() => {
		if (idsForSuspend.length > 1) {
			idsForSuspend.some((id) => {
				let firstItem = data.getDriversByAdmin.find((item) => item._id === idsForSuspend[0]);
				let currentItem = data.getDriversByAdmin.find((item) => item._id === id);
				if (firstItem.state !== currentItem.state) {
					setGroupError(true);
					return -1;
				} else {
					setGroupError(false);
				}
			});
		} else {
			setGroupError(false);
		}
	}, [idsForSuspend, checkAll]);

	const [carsModal, setCarsModal] = useState(false);
	const [driverCarsDetail, setDriverCarsDetail] = useState();
	const [noFilterModal, setNoFilterModal] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectDriverDetail = (driver) => {
		setModal(true);
		setDriverDetail(driver);
	};

	const onSelectDriverCars = (driver) => {
		setCarsModal(true);
		setDriverCarsDetail(driver);
	};

	const onSuspendDriver = (driver) => {
		setSuspendModal(true);
		setDriverForSuspend([driver._id]);
	};

	const onSuspendSome = () => {
		setSuspendSomeModal(true);
	};

	const [excelData, setExcelData] = useState([
		['full name', 'phone number', 'created at', 'rate', 'state', 'gender'],
	]);

	const [getDriversByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_DRIVRES);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getDriversCountByAdmin > 0 &&
			excelData.length === countData.getDriversCountByAdmin + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getDriversByAdmin, (item) => [
					item.fullName,
					item.phoneNumber,
					moment(item.createdAt).format('MM-DD-YYYY'),
					item.averageRate.toFixed(1),
					item.state.replace(/_/g, ' ').toLowerCase(),
					item.gender ? item.gender.toLowerCase() : '-',
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Drivers.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getDriversByAdmin({ variables: { ...variables, skip: 0, limit: countData.getDriversCountByAdmin } });
		}
	};

	const onNameSortChange = () => {
		if (!nameSort) {
			setNameSort('up');
		} else {
			if (nameSort === 'up') {
				setNameSort('down');
			} else {
				setNameSort('');
			}
		}
	};
	const onRateSortChange = () => {
		if (!rateSort) {
			setRateSort('up');
		} else {
			if (rateSort === 'up') {
				setRateSort('down');
			} else {
				setRateSort('');
			}
		}
	};
	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('');
			}
		}
	};

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (
				moment(dateFilter, 'MM/DD/YYYY', true).isValid() ||
				moment('06/22/2015', 'MM-DD-YYYY', true).isValid()
			) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	const [suspendDriverByAdmin] = useMutation(SUSPEND_DRIVER);

	const suspendDriver = () => {
		suspendDriverByAdmin({ variables: { idSet: driverForSuspend } })
			.then((res) => {
				setSuspendModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setSuspendModal(false);
					setSuspendErrorText(err.graphQLErrors[0].message);
					setSuspendErrorModal(true);
				}
			});
	};

	const suspendSome = () => {
		suspendDriverByAdmin({ variables: { idSet: idsForSuspend } })
			.then((res) => {
				setSuspendSomeModal(false);
				setIdsForSuspend([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setSuspendSomeModal(false);
					setIdsForSuspend([]);
					setSuspendErrorText(err.graphQLErrors[0].message);
					setSuspendErrorModal(true);
				}
			});
	};

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getDriversByAdmin.map((item) => {
				if (!idsForSuspend.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForSuspend, pageCount, perPage]);

	const linkPage = (route) => {
		window.location.href = endPoint + `/superAdmin/${route}`;
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getDriversCountByAdmin / perPage) - 1 &&
			countData.getDriversCountByAdmin !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getDriversCountByAdmin !== 0) {
			setPageCount(Math.ceil(countData.getDriversCountByAdmin / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (rateSort) {
			if (rateSort === 'up') {
				setVariables((variables) => ({ ...variables, averageRateSort: 1 }));
			} else if (rateSort === 'down') {
				setVariables((variables) => ({ ...variables, averageRateSort: -1 }));
			}
		} else {
			setVariables((variables) => ({
				...variables,
				averageRateSort: undefined,
			}));
		}

		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}

		if (nameSort) {
			if (nameSort === 'up') {
				setVariables((variables) => ({ ...variables, fullNameSort: 1 }));
			} else if (nameSort === 'down') {
				setVariables((variables) => ({ ...variables, fullNameSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, fullNameSort: undefined }));
		}
	}, [rateSort, dateSort, nameSort]);

	const onSubmitFilter = () => {
		let hasFilter =
			nameFilter ||
			variables.fullName ||
			phoneNumberFilter ||
			variables.phoneNumber ||
			dateFromFilter ||
			variables.createdAtFrom ||
			dateFilter ||
			variables.createdAt ||
			rateFromFilter ||
			variables.averageRateFrom ||
			rateFilter ||
			variables.averageRate ||
			!(stateFilter === 'ALL' && !variables.state) ||
			!(genderFilter === 'ALL' && !variables.gender);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);

			if (nameFilter) {
				setVariables((variables) => ({
					...variables,
					fullName: nameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, fullName: undefined }));
			}

			if (phoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					phoneNumber: phoneNumberFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, phoneNumber: undefined }));
			}

			if (rateFromFilter) {
				setVariables((variables) => ({
					...variables,
					averageRateFrom: parseFloat(rateFromFilter.trim()),
				}));
			} else {
				setVariables((variables) => ({ ...variables, averageRateFrom: undefined }));
			}

			if (rateFilter) {
				setVariables((variables) => ({
					...variables,
					averageRate: parseFloat(rateFilter.trim()),
				}));
			} else {
				setVariables((variables) => ({ ...variables, averageRate: undefined }));
			}

			if (stateFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					state: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					state: stateFilter.toUpperCase(),
				}));
			}

			if (genderFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					gender: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					gender: genderFilter.toUpperCase(),
				}));
			}

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
				console.log(moment(dateFilter).format('YYYY-MM-DD'));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}
		}
	};

	const onClearFilters = () => {
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			_id: undefined,
			fullName: undefined,
			state: undefined,
			gender: undefined,
			phoneNumber: undefined,
			averageRateFrom: undefined,
			averageRate: undefined,
			createdAtFrom: undefined,
			createdAt: undefined,
			fullNameSort: undefined,
			averageRateSort: undefined,
			createdAtSort: -1,
			isVerified: true,
			IncompleteInformationDrivers: false,
		});
		setNameFilter('');
		setPhoneNumberFilter('');
		setDateFromFilter('');
		setDateFilter('');
		setRateFilter('');
		setRateFilter('');
		setRateSort('');
		setDateSort('down');
		setStateFilter('ALL');
		setGenderFilter('ALL');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		// window.location.reload();
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			<div>
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<div className={style.linkButtonDiv}>
							<Button color="yellow" fullWidth onClick={() => linkPage('signupDriver')}>
								Sign up driver
							</Button>
						</div>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<div className={style.linkButtonDiv}>
							<Button color="yellow" fullWidth onClick={() => linkPage('addDriver')}>
								Add driver
							</Button>
						</div>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<div className={style.linkButtonDiv}>
							<Button color="yellow" fullWidth onClick={() => linkPage('verifications')}>
								Verifications
							</Button>
						</div>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<div className={style.linkButtonDiv}>
							<Button color="yellow" fullWidth onClick={() => linkPage('addVehicle')}>
								Add Vehicle For Driver
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<div>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}
				{idsForSuspend.length > 0 && !groupError && (
					<Button
						title={`${actionWord} selected items`}
						color={actionWord === 'suspend' ? 'redButton' : 'greenButton'}
						onClick={onSuspendSome}
					>
						{actionWord}
					</Button>
				)}
				{idsForSuspend.length > 0 && groupError && (
					<div className={style.groupErrorDiv}>selected items have different states.</div>
				)}
			</div>
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onSubmitFilter}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getDriversCountByAdmin === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getDriversByAdmin.length)}</span> of{' '}
							<span>{countData.getDriversCountByAdmin}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0)
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={data.getDriversByAdmin.map((driver) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForSuspend.includes(driver._id)}
								onChange={() => {
									if (idsForSuspend.includes(driver._id)) {
										setIdsForSuspend((idsForSuspend) =>
											idsForSuspend.filter((item) => item !== driver._id)
										);
									} else {
										setIdsForSuspend([...idsForSuspend, driver._id]);
									}
								}}
							/>
						</div>
					),
					photo: (
						<img
							style={{ width: '90px', height: '90px' }}
							alt="profile-photo"
							src={`${graphqlUrl}${driver.profileImageUrl}`}
						/>
					),
					id: (
						<CopyToClipboard text={driver._id}>
							<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
								{driver._id}
							</span>
						</CopyToClipboard>
					),
					name: driver.fullName,
					phoneNumber: driver.phoneNumber,
					rate: <div>{driver.averageRate.toFixed(1)} </div>,
					date: moment(driver.createdAt).format('MM/DD/YYYY'),
					state: driver.state && (
						<div className={`${style.inlineTag} ${style[driver.state.toLowerCase() + 'Tag']}`}>
							{driver.state.toLowerCase()}
						</div>
					),
					gender: driver.gender && (
						<div className={`${style.inlineTag} ${style[driver.gender.toLowerCase() + 'Tag']}`}>
							{driver.gender.toLowerCase()}
						</div>
					),
					actions: (
						<div className="actions-right">
							<button onClick={() => onSelectDriverDetail(driver)} className={style.detailIconDiv}>
								<span>i</span>
							</button>
							<IconButton
								id={driver._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, driver._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === driver._id}
								onClose={handleClose}
								onClick={handleClose}
							>
								<MenuItem
									onClick={() =>
										(window.location.href = endPoint + `/superAdmin/editDriver/${driver._id}`)
									}
								>
									edit
								</MenuItem>
								<MenuItem onClick={() => onSelectDriverCars(driver)}>cars</MenuItem>
								<MenuItem onClick={() => onSuspendDriver(driver)}>
									{driver.state === 'ACTIVE' ? 'suspend' : 'activate'}
								</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getDriversByAdmin.map((item) => {
												setIdsForSuspend((idsForSuspend) =>
													idsForSuspend.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForSuspend((idsForSuspend) => [
												...idsForSuspend,
												...Array.from(data.getDriversByAdmin, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Photo </div>
							</div>
						),
						accessor: 'photo',
						sortable: false,
						filterable: false,
					},

					{
						Header: (
							<div>
								<div className={style.sortDiv} onClick={onNameSortChange}>
									Name{' '}
									<span>
										{nameSort && (
											<span>
												{nameSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={nameFilter}
									onChange={(e) => setNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Phone</div>
								<CustomInput
									value={phoneNumberFilter}
									onChange={(e) => setPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone number ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'phoneNumber',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Date{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onRateSortChange}>
									Rate{' '}
									<span>
										{rateSort && (
											<span>
												{rateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={rateFromFilter}
									onChange={(e) => setRateFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'rate ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={rateFilter}
									onChange={(e) => setRateFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'rate ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'rate',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="gray"
										buttonText={stateFilter}
										onClick={(e) => setStateFilter(e)}
										dropdownList={stateOptions}
									/>
								</div>
							</div>
						),
						accessor: 'state',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Gender </div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="gray"
										buttonText={genderFilter}
										onClick={(e) => setGenderFilter(e)}
										dropdownList={genderOptions}
									/>
								</div>
							</div>
						),
						accessor: 'gender',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
						minWidth: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getDriversCountByAdmin !== 0
							? Math.ceil(countData.getDriversCountByAdmin / perPage)
							: 1
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<DriverDetails close={() => setModal(false)} driver={driverDetail} />
				</DialogContent>
			</Dialog>
			<Dialog open={carsModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Cars refetch={() => refetch()} close={() => setCarsModal(false)} driver={driverCarsDetail} />
				</DialogContent>
			</Dialog>
			<Dialog open={suspendSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setSuspendSomeModal(false);
							setIdsForSuspend([]);
						}}
						func={suspendSome}
						text={`Do you want to ${actionWord} these drivers?`}
					/>
				</DialogContent>
			</Dialog>
			{driverForSuspend && (
				<Dialog open={suspendModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setSuspendModal(false)}
							func={suspendDriver}
							text={`Do you want to ${
								driverForSuspend.state === 'ACTIVE' ? 'suspend' : 'activate'
							} this driver?`}
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={suspendErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setSuspendModal(false);
							setSuspendErrorModal(false);
						}}
						type="notif"
						text={
							suspendErrorText
								? suspendErrorText
								: 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Drivers;
