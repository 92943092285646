import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import style from './Success.module.scss';

const Fail = () => {
	return (
		<div className={style.mainDiv}>
			<div className={style.failIcon}>
				<CancelIcon />
			</div>
			<div>Error! The process was not successfull.</div>
		</div>
	);
};

export default Fail;
