import React, { useContext, useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.js';
import Badge from 'components/Badge/Badge.js';
import pizzaImage from 'assets/img/american-pizza.jpg';
import style from './Reply.module.scss';
import { ThemeContext } from 'index';

const REPLY = gql`
	mutation setShopAdminReplyOnComment($comment: ID!, $text: String!) {
		setShopAdminReplyOnComment(input: { comment: $comment, text: $text }) {
			_id
		}
	}
`;

const Reply = ({ review, close, type, refetch }) => {
	const themeContext = useContext(ThemeContext);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const theme = themeContext === 'Grocery' ? greenTheme : orangeTheme;

	const [text, setText] = useState('');
	const [disableButton, setDisableButton] = useState(false);

	const [setShopAdminReplyOnComment, { data, error, loading }] = useMutation(REPLY);

	const onSubmit = () => {
		if (text) {
			setDisableButton(true)
			setShopAdminReplyOnComment({ variables: { comment: review._id, text } })
				.then((res) => {
					refetch();
					close();
				})
				.catch((err) => {
					setDisableButton(false)
					console.log(err);
				});
		}
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div className={style.title}>
					<div className={style.headerTitle}>{review.user.fullName}</div>
					<div className={style.headerDate}>{moment(review.createdAt).format('MM/DD/YYYY HH:mm')}</div>
				</div>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
			</div>
			<div className={style.chatContainer}>
				<div className={style.chatWrapper}>
					<div className={style.senderName}>{review.user.fullName}</div>
					<div className={style.userCommentChatContainer}>
						<div className={style.userChat}>
							{review.userComment}{' '}
							<span className={style.messageDate}>
								{moment(review.createdAt).format('MM/DD/YYYY HH:mm')}
							</span>
						</div>
					</div>
				</div>
				{review.shopAdminReply.comment && (
					<div className={style.adminCommentChatContainer}>
						<div className={style.adminChat}>
							{review.shopAdminReply.comment.userComment}
							<span className={style.adminMessageDate}>
								{moment(review.shopAdminReply.comment.createdAt).format('MM/DD/YYYY HH:mm')}
							</span>
						</div>
					</div>
				)}
			</div>

			{!review.shopAdminReply.comment && (
				<div className={style.footerPart}>
					<input
						className={style.input}
						value={text}
						placeholder="Type your message..."
						onChange={(e) => setText(e.target.value)}
					/>
					<Button disabled={disableButton} color={theme.primaryColor} onClick={onSubmit}>
						Send
					</Button>
				</div>
			)}
		</div>
	);
};

export default Reply;
