import React, { useState, useEffect } from 'react';
import moment from 'moment';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import Modal from 'commonComponents/modal/Modal';
import ReactTable from 'react-table';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Button from 'components/CustomButtons/Button.js';
import Pagination from 'commonComponents/pagination/Pagination';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';
import CommentDetails from './CommentDetails';
import ClearIcon from '@material-ui/icons/Clear';

const GET_COMMENTS = gql`
	query getCommentsOnDriverByAdmin(
		$limit: Int
		$skip: Int
		$_id: ID
		$car: ID
		$driver: ID
		$createdAtFrom: Date
		$createdAt: Date
		$passenger: ID
		$userComment: String
		$trip: ID
		$createdAtSort: Int
		$driverName: String
		$passengerName: String
		$driverPhoneNumber: String
		$passengerPhoneNumber: String
	) {
		getCommentsOnDriverByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				trip: $trip
				car: $car
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
				driver: $driver
				passenger: $passenger
				userComment: $userComment
				driverName: $driverName
				passengerName: $passengerName
				driverPhoneNumber: $driverPhoneNumber
				passengerPhoneNumber: $passengerPhoneNumber
			}
			sort: { createdAt: $createdAtSort }
		) {
			_id
			readyComments {
				rate
				readyComment {
					_id
					type
				}
			}
			trip {
				_id
			}
			driver {
				_id
				fullName
				phoneNumber
			}
			passenger {
				_id
				fullName
				phoneNumber
			}
			createdAt
			userComment
		}
	}
`;

const GET_COMMENTS_COUNT = gql`
	query getCommentsOnDriverByAdminCount(
		$_id: ID
		$car: ID
		$driver: ID
		$passenger: ID
		$userComment: String
		$createdAtFrom: Date
		$createdAt: Date
		$trip: ID
		$driverName: String
		$passengerName: String
		$driverPhoneNumber: String
		$passengerPhoneNumber: String
	) {
		getCommentsOnDriverByAdminCount(
			filters: {
				_id: $_id
				car: $car
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
				driver: $driver
				passenger: $passenger
				userComment: $userComment
				trip: $trip
				driverName: $driverName
				passengerName: $passengerName
				driverPhoneNumber: $driverPhoneNumber
				passengerPhoneNumber: $passengerPhoneNumber
			}
		)
	}
`;

const DELETE_COMMENT = gql`
	mutation removeCommentOnDriverByAdmin($id: ID!) {
		removeCommentOnDriverByAdmin(id: $id) {
			_id
		}
	}
`;

const Comments = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const [dateSort, setDateSort] = useState('down');

	const [idFilter, setIdFilter] = useState('');
	const [tripIdFilter, setTripIdFilter] = useState('');
	const [passengerIdFilter, setPassengerIdFilter] = useState('');
	const [driverIdFilter, setDriverIdFilter] = useState('');
	const [dateFromFilter, setDateFromFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');
	const [textFilter, setTextFilter] = useState('');
	const [passengerNameFilter, setPassengerNameFilter] = useState('');
	const [driverNameFilter, setDriverNameFilter] = useState('');
	const [driverPhoneNumberFilter, setDriverPhoneNumberFilter] = useState('');
	const [passengerPhoneNumberFilter, setPassengerPhoneNumberFilter] = useState('');

	const [idFilterError, setIdFilterError] = useState(false);
	const [tripIdFilterError, setTripIdFilterError] = useState(false);
	const [driverIdFilterError, setDriverIdFilterError] = useState(false);
	const [passengerIdFilterError, setPassengerIdFilterError] = useState(false);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		userComment: undefined,
		createdAtSort: -1,
		createdAt: undefined,
		driverName: undefined,
		passengerName: undefined,
		driverPhoneNumber: undefined,
		passengerPhoneNumber: undefined,
	});

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');
	const [dateFromFilterError, setDateFromFilterError] = useState('');
	const [dateFilterError, setDateFilterError] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [commentForDelete, setCommentForDelete] = useState();
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [modal, setModal] = useState(false);
	const [commentDetail, setCommentDetail] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectCommentDetail = (trip) => {
		setModal(true);
		setCommentDetail(trip);
	};

	const [excelData, setExcelData] = useState([
		['passenger name', 'passenger phone No', 'driver name', 'driver phone No', 'date', 'text'],
	]);

	const [getCommentsOnDriverByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(
		GET_COMMENTS
	);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getCommentsOnDriverByAdminCount > 0 &&
			excelData.length === countData.getCommentsOnDriverByAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getCommentsOnDriverByAdmin, (item) => [
					item.passenger.fullName,
					item.passenger.phoneNumber,
					item.driver.fullName,
					item.driver.phoneNumber,
					moment(item.createdAt).format('MM-DD-YYYY'),
					item.userComment ? item.userComment : '-',
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Comments.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getCommentsOnDriverByAdmin({
				variables: { ...variables, skip: 0, limit: countData.getCommentsOnDriverByAdminCount },
			});
		}
	};

	const [removeCommentOnDriverByAdmin] = useMutation(DELETE_COMMENT);

	const deleteComment = () => {
		removeCommentOnDriverByAdmin({ variables: { id: commentForDelete._id } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	useEffect(() => {
		if (idFilter && idFilter.length !== 24) {
			setIdFilterError(true);
		} else {
			setIdFilterError(false);
		}
	}, [idFilter]);

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment('06/22/2015', 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFromFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (
				moment(dateFilter, 'MM/DD/YYYY', true).isValid() ||
				moment('06/22/2015', 'MM-DD-YYYY', true).isValid()
			) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (tripIdFilter && tripIdFilter.length !== 24) {
			setTripIdFilterError(true);
		} else {
			setTripIdFilterError(false);
		}
	}, [idFilter]);

	useEffect(() => {
		if (driverIdFilter && driverIdFilter.length !== 24) {
			setDriverIdFilterError(true);
		} else {
			setDriverIdFilterError(false);
		}
	}, [driverIdFilter]);

	useEffect(() => {
		if (passengerIdFilter && passengerIdFilter.length !== 24) {
			setPassengerIdFilterError(true);
		} else {
			setPassengerIdFilterError(false);
		}
	}, [passengerIdFilter]);

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('up');
			}
		}
	};

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getCommentsOnDriverByAdminCount / perPage) - 1 &&
			countData.getCommentsOnDriverByAdminCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getCommentsOnDriverByAdminCount !== 0) {
			setPageCount(Math.ceil(countData.getCommentsOnDriverByAdminCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (tripIdFilter && tripIdFilter.length !== 24) {
			setTripIdFilterError(true);
		} else {
			setTripIdFilterError(false);
		}
	}, [tripIdFilter]);

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}
	}, [dateSort]);

	const onSubmitFilter = () => {
		let hasFilter =
			textFilter ||
			variables.userComment ||
			driverNameFilter ||
			variables.driverName ||
			driverPhoneNumberFilter ||
			variables.driverPhoneNumber ||
			passengerNameFilter ||
			variables.passengerName ||
			passengerPhoneNumberFilter ||
			variables.passengerPhoneNumber ||
			dateFromFilter ||
			variables.createdAtFrom ||
			dateFilter ||
			variables.createdAt;

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');

			if (driverNameFilter) {
				setVariables((variables) => ({
					...variables,
					driverName: driverNameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, driverName: undefined }));
			}

			if (passengerNameFilter) {
				setVariables((variables) => ({
					...variables,
					passengerName: passengerNameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerName: undefined }));
			}

			if (driverPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					driverPhoneNumber: driverPhoneNumberFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, driverPhoneNumber: undefined }));
			}

			if (passengerPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					passengerPhoneNumber: passengerPhoneNumberFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerPhoneNumber: undefined }));
			}

			if (textFilter) {
				setVariables((variables) => ({
					...variables,
					userComment: textFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, userComment: undefined }));
			}

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			userComment: undefined,
			createdAtSort: -1,
			createdAt: undefined,
			driverName: undefined,
			passengerName: undefined,
			driverPhoneNumber: undefined,
			passengerPhoneNumber: undefined,
		});
		setDriverNameFilter('');
		setDriverPhoneNumberFilter('');
		setPassengerNameFilter('');
		setPassengerPhoneNumberFilter('');
		setDateFromFilter('');
		setDateFilter('');
		setTextFilter('');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	const { loading, error, data, refetch } = useQuery(GET_COMMENTS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_COMMENTS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	if (data) {
		console.log(data);
	}

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onSubmitFilter}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{data.getCommentsOnDriverByAdmin.length === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>
								{pageCount * perPage + Math.min(perPage, data.getCommentsOnDriverByAdmin.length)}
							</span>{' '}
							of <span>{countData.getCommentsOnDriverByAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0);
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={data.getCommentsOnDriverByAdmin.map((comment) => ({
					passengerName: comment.passenger.fullName,
					passengerPhoneNumber: comment.passenger.phoneNumber,

					driverName: comment.driver.fullName,
					driverPhoneNumber: comment.driver.phoneNumber,
					date: (
						<span>
							{moment(comment.createdAt).format('MM/DD/YYYY HH:mm')}
							<button onClick={() => onSelectCommentDetail(comment)} className={style.detailIconDiv}>
								<span>i</span>
							</button>
						</span>
					),
					text: comment.userComment,
				}))}
				columns={[
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>Passenger Name</div>
								<CustomInput
									value={passengerNameFilter}
									onChange={(e) => setPassengerNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>Passenger Phone No</div>
								<CustomInput
									value={passengerPhoneNumberFilter}
									onChange={(e) => setPassengerPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'passengerPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 145,
					},
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>Driver Name</div>
								<CustomInput
									value={driverNameFilter}
									onChange={(e) => setDriverNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'driverName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>Driver Phone No</div>
								<CustomInput
									value={driverPhoneNumberFilter}
									onChange={(e) => setDriverPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'driverPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Date{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Text</div>
								<CustomInput
									value={textFilter}
									onChange={(e) => setTextFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'text ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'text',
						sortable: false,
						filterable: false,
					},

					// {
					// 	Header: (
					// 		<div className={style.bottonButton}>
					// 			<div className={style.titleDivAction}>Actions</div>
					// 		</div>
					// 	),
					// 	accessor: 'actions',
					// 	sortable: false,
					// 	filterable: false,
					// },
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getCommentsOnDriverByAdminCount !== 0
							? Math.ceil(countData.getCommentsOnDriverByAdminCount / perPage)
							: 1
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<CommentDetails close={() => setModal(false)} comment={commentDetail} />
				</DialogContent>
			</Dialog>
			{commentForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteComment}
							text="Do you want to delete this comment?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Comments;
