import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import moment from 'moment';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { endPoint } from 'utils/config';
import SelectSearch from 'react-select-search';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Datetime from 'react-datetime';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import style from 'superAdminViews/ride/addPromotion/AddPromotion.module.scss';

const ADD_PROMOTION_BY_SHOP_ADMIN = gql`
	mutation createOrderPromotionByShopAdmin(
		$condition: OrderPromotionCondition!
		$type: promotionTypeEnum!
		$from: Date
		$to: Date
		$percent: Float
		$useLimitCount: Int
		$maximumPromotion: Float
		$promotionCode: String!
	) {
		createOrderPromotionByShopAdmin(
			data: {
				condition: $condition
				type: $type
				from: $from
				to: $to
				percent: $percent
				useLimitCount: $useLimitCount
				maximumPromotion: $maximumPromotion
				promotionCode: $promotionCode
			}
		) {
			_id
		}
	}
`;

const UPDATE_PROMOTION_BY_SHOP_ADMIN = gql`
	mutation updateOrderPromotionByShopAdmin(
		$promotionId: ID!
		$condition: OrderPromotionCondition!
		$type: promotionTypeEnum!
		$from: Date
		$to: Date
		$percent: Float
		$useLimitCount: Int
		$maximumPromotion: Float
		$promotionCode: String!
	) {
		updateOrderPromotionByShopAdmin(
			promotionId: $promotionId
			data: {
				condition: $condition
				type: $type
				from: $from
				to: $to
				percent: $percent
				useLimitCount: $useLimitCount
				maximumPromotion: $maximumPromotion
				promotionCode: $promotionCode
			}
		) {
			_id
		}
	}
`;

const UPDATE_PROMOTION = gql`
	mutation updateTripPromotionByAdmin(
		$promotionId: ID!
		$condition: promotionConditionEnum
		$type: promotionTypeEnum
		$canUse: [ID]
		$canNotUse: [ID]
		$useLimitCount: Int
		$for: forEnum
		$from: Date
		$to: Date
		$percent: Float
		$maximumPromotion: Float
		$promotionCode: String!
	) {
		updateTripPromotionByAdmin(
			promotionId: $promotionId
			data: {
				condition: $condition
				type: $type
				canUse: $canUse
				canNotUse: $canNotUse
				from: $from
				useLimitCount: $useLimitCount
				to: $to
				for: $for
				percent: $percent
				maximumPromotion: $maximumPromotion
				promotionCode: $promotionCode
			}
		) {
			_id
		}
	}
`;

const ADD_PROMOTION = gql`
	mutation createTripPromotionByAdmin(
		$condition: promotionConditionEnum
		$type: promotionTypeEnum
		$canUse: [ID]
		$canNotUse: [ID]
		$from: Date
		$for: forEnum
		$useLimitCount: Int
		$to: Date
		$percent: Float
		$maximumPromotion: Float
		$promotionCode: String!
	) {
		createTripPromotionByAdmin(
			data: {
				condition: $condition
				type: $type
				canUse: $canUse
				for: $for
				canNotUse: $canNotUse
				useLimitCount: $useLimitCount
				from: $from
				to: $to
				percent: $percent
				maximumPromotion: $maximumPromotion
				promotionCode: $promotionCode
			}
		) {
			_id
		}
	}
`;

const GET_USERS = gql`
	query getUsersByAdmin($fullName: String, $limit: Int, $skip: Int) {
		getUsersByAdmin(filters: { fullName: $fullName }, pagination: { limit: $limit, skip: $skip }) {
			_id
			fullName
			phoneNumber
			shopUser
		}
	}
`;

const GET_INITIAL_USERS = gql`
	query getUsersByAdmin($_id: ID) {
		getInitialUsersByAdmin: getUsersByAdmin(filters: { _id: $_id }) {
			_id
			fullName
			phoneNumber
			shopUser
		}
	}
`;

const GET_NO_USERS = gql`
	query getNoUsersByAdmin($fullName: String, $limit: Int, $skip: Int) {
		getNoUsersByAdmin: getUsersByAdmin(
			filters: { fullName: $fullName }
			pagination: { limit: $limit, skip: $skip }
		) {
			_id
			fullName
			phoneNumber
			shopUser
		}
	}
`;

const AddPromotionForm = ({ promotion, model, theme }) => {
	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const grayTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'yellow',
	};

	let themeColor = orangeTheme;
	if (theme === 'Purchase') {
		themeColor = greenTheme;
	} else if (theme === 'Restaurant') {
		themeColor = orangeTheme;
	} else if (theme === 'superAdmin') {
		themeColor = grayTheme;
	}

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	// const typeOptions = ['FIXED', 'PERCENT'];
	const typeOptions = ['PERCENT'];
	const conditionOptions = ['TIMELY', 'FIRST_TRIP'];
	const shopConditionOptions = ['TIMELY', 'FIRST_ORDER', 'PERCENTAGE'];
	const forOptions = ['ALL', 'SINGLE'];
	const forTripOptions = ['ALL', 'RIDE', 'DELIVERY'];
	const shopTypeOptions = ['GROCERY', 'FOOD', 'ALL'];

	const [promotionCode, setPromotionCode] = useState(promotion ? promotion.promotionCode : '');
	const [useLimitCount, setUseLimitCount] = useState(promotion ? promotion.useLimitCount : '');
	const [maxDiscountAmount, setMaxDiscountAmount] = useState(promotion ? promotion.maximumPromotion : '');
	const [discountPercentage, setDiscountPercentage] = useState(promotion ? promotion.percent : '');
	const [startDate, setStartDate] = useState(promotion ? moment(promotion.from).format('MM/DD/YYYY HH:mm') : '');
	const [expireDate, setExpireDate] = useState(promotion ? moment(promotion.to).format('MM/DD/YYYY HH:mm') : '');
	const [condition, setCondition] = useState(promotion ? promotion.condition : shopConditionOptions[0]);

	const [type, setType] = useState(promotion ? promotion.type : typeOptions[0]);
	const [forVariable, setForVairable] = useState(model === 'shop' && promotion ? promotion.for : forOptions[0]);
	const [forTripVariable, setForTripVairable] = useState(
		model === 'super' && promotion ? promotion.for : forOptions[0]
	);

	const [promotionCodeError, setPromotionCodeError] = useState(false);
	const [useLimitCountError, setUseLimitCountError] = useState(false);
	const [maxDiscountAmountError, setMaxDiscountAmountError] = useState(false);
	const [discountPercentageError, setDiscountPercentageError] = useState(false);
	const [startDateError, setStartDateError] = useState('');
	const [expireDateError, setExpireDateError] = useState('');
	const [isForAll, setIsForAll] = useState(
		promotion && promotion.canUse && promotion.canUse.length > 0 ? false : true
	);
	const [hasExeption, setHasExeption] = useState(
		promotion && promotion.canNotUse && promotion.canNotUse.length > 0 ? true : false
	);

	const [includeIds, setIncludeIds] = useState(promotion && type !== 'shopAdmin' ? promotion.canUse : []);
	const [doesntIncludeIds, setDoesntIncludeIds] = useState(
		promotion && type !== 'shopAdmin' ? promotion.canNotUse : []
	);

	const [userSelectOptions, setUserSelectOptions] = useState([]);
	const [userSelectArray, setUserSelectArray] = useState([]);

	const [noUserSelectOptions, setNoUserSelectOptions] = useState([]);
	const [noUserSelectArray, setNoUserSelectArray] = useState([]);

	const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_USERS, {
		variables: { limit: 10, skip: 0, fullName: undefined },
	});

	const [
		getUsersByAdmin,
		{ loading: usersLazyLoading, error: usersLazyError, data: usersLazyData, fetchMore },
	] = useLazyQuery(GET_USERS);

	const [
		getNoUsersByAdmin,
		{ loading: noUsersLazyLoading, error: noUsersLazyError, data: noUsersLazyData, fetchMore: noFetchMore },
	] = useLazyQuery(GET_NO_USERS);

	const [getInitialUsersByAdmin, { loading: initialLoading, error: initialError, data: initialData }] = useLazyQuery(
		GET_INITIAL_USERS
	);

	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (promotion && promotion.canUse && promotion.canUse.length > 0) {
			getInitialUsersByAdmin({ variables: { _id: promotion.canUse[index] } });
		}
	}, [, index]);

	useEffect(() => {
		if (promotion && promotion.canUse && promotion.canUse.length > 0 && initialData) {
			setUserSelectArray((userSelectArray) => [
				...userSelectArray,
				{
					name:
						initialData.getInitialUsersByAdmin[0].fullName +
						' - ' +
						initialData.getInitialUsersByAdmin[0].phoneNumber,
					value: initialData.getInitialUsersByAdmin[0]._id,
				},
			]);
			console.log(initialData);
			if (index !== promotion.canUse.length - 1) {
				setIndex((index) => index + 1);
			}
		}
	}, [initialLoading, initialData]);

	useEffect(() => {
		if (promotion && promotion.canNotUse && promotion.canNotUse.length > 0) {
			getInitialUsersByAdmin({ variables: { _id: promotion.canNotUse[index] } });
		}
	}, [, index]);

	useEffect(() => {
		if (promotion && promotion.canNotUse && promotion.canNotUse.length > 0 && initialData) {
			setNoUserSelectArray((noUserSelectArray) => [
				...noUserSelectArray,
				{
					name:
						initialData.getInitialUsersByAdmin[0].fullName +
						' - ' +
						initialData.getInitialUsersByAdmin[0].phoneNumber,
					value: initialData.getInitialUsersByAdmin[0]._id,
				},
			]);
			console.log(initialData);
			if (index !== promotion.canNotUse.length - 1) {
				setIndex((index) => index + 1);
			}
		}
	}, [initialLoading, initialData]);

	const [fetchMoreSkip, setFetchMoreSkip] = useState(10);
	const [noFetchMoreSkip, setNoFetchMoreSkip] = useState(10);
	const [search, setSearch] = useState('');
	const [noSearch, setNoSearch] = useState('');

	useEffect(() => {
		const handler = setTimeout(() => {
			getUsersByAdmin({
				variables: {
					limit: 10,
					skip: 0,
					fullName: search ? search.trim() : undefined,
				},
			});
		}, 200);
		return () => clearTimeout(handler);
	}, [search]);

	useEffect(() => {
		const noHandler = setTimeout(() => {
			getNoUsersByAdmin({
				variables: {
					limit: 10,
					skip: 0,
					fullName: noSearch ? noSearch.trim() : undefined,
				},
			});
		}, 200);
		return () => clearTimeout(noHandler);
	}, [noSearch]);

	const onLoadMore = () => {
		fetchMore({
			query: GET_USERS,
			variables: { limit: 10, skip: fetchMoreSkip, fullName: search ? search : undefined },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				console.log('this is new results:', fetchMoreResult.getUsersByAdmin);
				setUserSelectOptions((userSelectOptions) => [
					...userSelectOptions,
					...Array.from(fetchMoreResult.getUsersByAdmin, (user) => ({
						name: user.fullName + ' - ' + user.phoneNumber,
						value: user._id,
					})),
				]);
				setUserSelectArray((userSelectOptions) => [
					...userSelectOptions,
					...Array.from(fetchMoreResult.getUsersByAdmin, (user) => ({
						name: user.fullName + ' - ' + user.phoneNumber,
						value: user._id,
					})),
				]);
			},
		});
		setFetchMoreSkip((fetchMoreSkip) => fetchMoreSkip + 10);
	};

	const noOnLoadMore = () => {
		noFetchMore({
			query: GET_NO_USERS,
			variables: { limit: 10, skip: noFetchMoreSkip, fullName: noSearch ? noSearch : undefined },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				console.log('this is new results:', fetchMoreResult.getNoUsersByAdmin);
				setNoUserSelectOptions((noUserSelectOptions) => [
					...noUserSelectOptions,
					...Array.from(fetchMoreResult.getNoUsersByAdmin, (user) => ({
						name: user.fullName + ' - ' + user.phoneNumber,
						value: user._id,
					})),
				]);
				setNoUserSelectArray((noUserSelectArray) => [
					...noUserSelectArray,
					...Array.from(fetchMoreResult.getNoUsersByAdmin, (user) => ({
						name: user.fullName + ' - ' + user.phoneNumber,
						value: user._id,
					})),
				]);
			},
		});
		setNoFetchMoreSkip((noFetchMoreSkip) => noFetchMoreSkip + 10);
	};

	const handleScroll = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			onLoadMore();
		}
	};

	const noHandleScroll = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			noOnLoadMore();
		}
	};

	useEffect(() => {
		if (usersLazyData) {
			console.log(usersLazyData);
			setUserSelectOptions(
				Array.from(usersLazyData.getUsersByAdmin, (user) => ({
					name: user.fullName + ' - ' + user.phoneNumber,
					value: user._id,
				}))
			);
			setUserSelectArray((userSelectArray) => [
				...userSelectArray,
				...Array.from(usersLazyData.getUsersByAdmin, (user) => ({
					name: user.fullName + ' - ' + user.phoneNumber,
					value: user._id,
				})),
			]);
		}
	}, [usersLazyData, usersLazyLoading]);

	useEffect(() => {
		if (noUsersLazyData) {
			console.log(usersLazyData);
			setNoUserSelectOptions(
				Array.from(noUsersLazyData.getNoUsersByAdmin, (user) => ({
					name: user.fullName + ' - ' + user.phoneNumber,
					value: user._id,
				}))
			);
			setNoUserSelectArray((noUserSelectArray) => [
				...noUserSelectArray,
				...Array.from(noUsersLazyData.getNoUsersByAdmin, (user) => ({
					name: user.fullName + ' - ' + user.phoneNumber,
					value: user._id,
				})),
			]);
		}
		if (noUsersLazyError) {
			console.log(noUsersLazyError);
		}
	}, [noUsersLazyData, noUsersLazyLoading, noUsersLazyError]);

	const [createTripPromotionByAdmin, { data: addMutationData, error: addMutationError }] = useMutation(ADD_PROMOTION);

	const [createOrderPromotionByShopAdmin, { data: addShopMutationData, error: addShopMutationError }] = useMutation(
		ADD_PROMOTION_BY_SHOP_ADMIN
	);

	const [
		updateOrderPromotionByShopAdmin,
		{ data: updateShopMutationData, error: updateShopMutationError },
	] = useMutation(UPDATE_PROMOTION_BY_SHOP_ADMIN);

	const [updateTripPromotionByAdmin, { data: updateMutationData, error: updateMutationError }] = useMutation(
		UPDATE_PROMOTION
	);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	// block navigation
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [shouldLeavePage, setShouldLeavePage] = useState(false);

	useEffect(() => {
		if (shouldLeavePage) {
			window.onbeforeunload = undefined;
		} else {
			if (shouldBlockNavigation) {
				window.onbeforeunload = () => true;
			} else {
				window.onbeforeunload = undefined;
			}
		}
	}, [shouldBlockNavigation]);

	useEffect(() => {
		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	useEffect(() => {
		if (promotion) {
			if (
				promotionCode === promotion.promotionCode &&
				type === promotion.type &&
				condition === promotion.condition &&
				maxDiscountAmount == promotion.maximumPromotion &&
				discountPercentage == promotion.percent &&
				useLimitCount == promotion.useLimitCount &&
				// (promotion.canUse.length === includeIds.length &&
				// 	promotion.canUse.every((val, index) => val === includeIds[index])) &&
				(type !== 'shopAdmin' && JSON.stringify(promotion.canUse) === JSON.stringify(includeIds)) &&
				(type !== 'shopAdmin' && JSON.stringify(promotion.canNotUse) === JSON.stringify(doesntIncludeIds)) &&
				moment(startDate).format('YYYY-DD-MM HH:mm') === moment(promotion.from).format('YYYY-DD-MM HH:mm') &&
				moment(expireDate).format('YYYY-DD-MM HH:mm') === moment(promotion.to).format('YYYY-DD-MM HH:mm')
			) {
				setShouldBlockNavigation(false);
			} else {
				setShouldBlockNavigation(true);
			}
		} else {
			if (promotionCode || maxDiscountAmount || discountPercentage || startDate || expireDate) {
				setShouldBlockNavigation(true);
			} else {
				setShouldBlockNavigation(false);
			}
		}
	});

	const onCancel = () => {
		window.onbeforeunload = undefined;
		setShouldBlockNavigation(false);
		if (model === 'shopAdmin') {
			window.location.href = endPoint + '/shopAdmin/promotions';
		} else {
			window.location.href = endPoint + '/superAdmin/tripsPromotions';
		}
	};

	const onSubmit = () => {
		if (!promotionCode) {
			setPromotionCodeError(true);
		} else {
			setPromotionCodeError(false);
		}
		if (!useLimitCount) {
			setUseLimitCountError("use limit can't be empty");
		} else {
			if (!String(useLimitCount).match(/^[1-9](?:\d{0,2}(?:,\d{3})+|\d*)$/)) {
				setUseLimitCountError('must be a number more than 0');
			} else {
				setUseLimitCountError(false);
			}
		}
		if (!discountPercentage && type === 'PERCENT') {
			setDiscountPercentageError(true);
		} else {
			setDiscountPercentageError(false);
		}
		if (!maxDiscountAmount) {
			setMaxDiscountAmountError(true);
		} else {
			setMaxDiscountAmountError(false);
		}
		if (!startDate) {
			setStartDateError("start date can't be empty*");
		} else {
			if (
				moment(startDate, 'MM/DD/YYYY HH:mm', true).isValid() ||
				moment(startDate, 'MM/DD/YYYY HH:mm', true).isValid()
			) {
				if (promotion || moment(startDate).isAfter(moment())) {
					setStartDateError('');
				} else {
					setStartDateError('start date should be from today or later*');
				}
			} else {
				setStartDateError('invalid date');
			}
		}
		if (!expireDate) {
			setExpireDateError("expire date can't be empty*");
		} else {
			if (
				moment(expireDate, 'MM/DD/YYYY HH:mm', true).isValid() ||
				moment(expireDate, 'MM/DD/YYYY HH:mm', true).isValid()
			) {
				if (moment(expireDate).isAfter(startDate)) {
					setExpireDateError('');
				} else {
					setExpireDateError('expire date should be after start date*');
				}
			} else {
				setExpireDateError('invalid date');
			}
		}

		if (
			promotionCode &&
			startDate &&
			expireDate &&
			(moment(startDate, 'MM/DD/YYYY HH:mm', true).isValid() ||
				moment(startDate, 'MM/DD/YYYY HH:mm', true).isValid()) &&
			(moment(expireDate, 'MM/DD/YYYY HH:mm', true).isValid() ||
				moment(expireDate, 'MM/DD/YYYY HH:mm', true).isValid()) &&
			useLimitCount &&
			String(useLimitCount).match(/^[1-9](?:\d{0,2}(?:,\d{3})+|\d*)$/) &&
			maxDiscountAmount &&
			moment(expireDate).isAfter(startDate) &&
			(promotion || moment(startDate).isAfter(moment())) &&
			((type === 'PERCENT' && discountPercentage) || type === 'FIXED')
		) {
			setDisableButton(true);
			window.onbeforeunload = undefined;
			setShouldBlockNavigation(false);
			setShouldLeavePage(true);
			if (model === 'shopAdmin' && !promotion) {
				createOrderPromotionByShopAdmin({
					variables: {
						type,
						condition,
						for: forTripVariable,
						promotionCode: promotionCode.trim(),
						maximumPromotion: parseFloat(maxDiscountAmount),
						percent: type === 'PERCENT' ? parseFloat(discountPercentage) : null,
						from: moment(startDate).utc(),
						to: moment(expireDate).utc(),
						useLimitCount: parseInt(useLimitCount),
					},
				})
					.then((res) => (window.location.href = endPoint + '/shopAdmin/promotions'))
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			} else if (model === 'shopAdmin' && promotion) {
				updateOrderPromotionByShopAdmin({
					variables: {
						promotionId: promotion._id,
						type,
						condition,
						promotionCode: promotionCode.trim(),
						maximumPromotion: parseFloat(maxDiscountAmount),
						percent: type === 'PERCENT' ? parseFloat(discountPercentage) : null,
						from: moment(startDate).utc(),
						to: moment(expireDate).utc(),
						useLimitCount: parseInt(useLimitCount),
					},
				})
					.then((res) => (window.location.href = endPoint + '/shopAdmin/promotions'))
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			} else if (model === 'super' && !promotion) {
				createTripPromotionByAdmin({
					variables: {
						type,
						condition,
						promotionCode: promotionCode.trim(),
						maximumPromotion: parseFloat(maxDiscountAmount),
						for: forTripVariable,
						percent: type === 'PERCENT' ? parseFloat(discountPercentage) : null,
						from: moment(startDate).utc(),
						to: moment(expireDate).utc(),
						useLimitCount: parseInt(useLimitCount),
						canUse: !isForAll ? includeIds : undefined,
						canNotUse: isForAll && hasExeption ? doesntIncludeIds : undefined,
					},
				})
					.then((res) => (window.location.href = endPoint + '/superAdmin/tripsPromotions'))
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			} else if (model === 'super' && promotion) {
				updateTripPromotionByAdmin({
					variables: {
						promotionId: promotion._id,
						type,
						condition,
						for: forTripVariable,
						promotionCode: promotionCode.trim(),
						maximumPromotion: parseFloat(maxDiscountAmount),
						percent: type === 'PERCENT' ? parseFloat(discountPercentage) : null,
						from: moment(startDate).utc(),
						to: moment(expireDate).utc(),
						useLimitCount: parseInt(useLimitCount),
						canUse: !isForAll ? includeIds : [],
						canNotUse: isForAll && hasExeption ? doesntIncludeIds : [],
					},
				})
					.then((res) => (window.location.href = endPoint + '/superAdmin/tripsPromotions'))
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}
		}
	};

	return (
		<div className={style.mainDiv}>
			<Prompt when={shouldBlockNavigation} message="You have unsaved changes, are you sure you want to leave?" />
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div onKeyPress={(e) => handleEnter(e)} className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.label}>Condition:</div>
								</GridItem>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="gray"
											buttonText={condition}
											onClick={(e) => setCondition(e)}
											dropdownList={
												model === 'shopAdmin' ? shopConditionOptions : conditionOptions
											}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div onKeyPress={(e) => handleEnter(e)} className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.label}>Type:</div>
								</GridItem>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={type}
											onClick={(e) => setType(e)}
											dropdownList={typeOptions}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>

						{model === 'shop' && (
							<div onKeyPress={(e) => handleEnter(e)} className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.label}>For:</div>
									</GridItem>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={forVariable}
												onClick={(e) => setForVairable(e)}
												dropdownList={forOptions}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{model === 'super' && (
							<div onKeyPress={(e) => handleEnter(e)} className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.label}>For:</div>
									</GridItem>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={forTripVariable}
												onClick={(e) => setForTripVairable(e)}
												dropdownList={forTripOptions}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						<div onKeyPress={(e) => handleEnter(e)} className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Promotion code:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={promotionCode}
										onChange={(e) => setPromotionCode(e.target.value)}
										error={promotionCodeError}
										labelText={promotionCodeError ? "code can't be empty*" : 'promotion code'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div onKeyPress={(e) => handleEnter(e)} className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Use limit per user:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={useLimitCount}
										onChange={(e) => setUseLimitCount(e.target.value)}
										error={useLimitCountError}
										labelText={useLimitCountError ? useLimitCountError : 'use limit count'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						{type === 'PERCENT' && (
							<div onKeyPress={(e) => handleEnter(e)} className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Discount percentage:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={discountPercentage}
											onChange={(e) => setDiscountPercentage(e.target.value)}
											error={discountPercentageError}
											labelText={
												discountPercentageError
													? "percentage can't be empty*"
													: 'discount percentage'
											}
											inputProps={{
												type: 'number',
												endAdornment: (
													<i
														position="end"
														style={{
															fontSize: '10px',
															color: 'gray',
															paddingRight: '7px',
														}}
														gray
														class="fa fa-percent"
													></i>
												),
											}}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						)}
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={6}>
						<div onKeyPress={(e) => handleEnter(e)} className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Maximum discount:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={maxDiscountAmount}
										onChange={(e) => setMaxDiscountAmount(e.target.value)}
										error={maxDiscountAmountError}
										labelText={
											maxDiscountAmountError ? "maximum promotion can't be empty*" : 'CAD$'
										}
										inputProps={{
											type: 'number',
											endAdornment: (
												<AttachMoneyIcon
													style={{ fontSize: '18px', color: 'gray' }}
													position="end"
												></AttachMoneyIcon>
											),
										}}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div onKeyPress={(e) => handleEnter(e)} className={style.dateRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.dateLabel}>Start date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									{startDateError && <div className={style.dateError}>{startDateError}</div>}
									<Datetime
										dateFormat="MM/DD/YYYY"
										timeFormat="HH:mm"
										initialViewDate={moment()}
										disableOnClickOutside
										value={startDate}
										closeOnSelect
										// renderInput={(props,openCalendar, closeCalendar) => (
										// 	<input
										// 		{...props}
										// 		value={startDate}
										// 		onChange={(e) => {
										// 			console.log('changed')
										// 			setStartDate(moment(e.target.value).format("MM/DD/YYYY HH:MM"));
										// 			console.log(e.target.value)
										// 		}}
										// 	/>
										// )}
										onChange={(date) => setStartDate(date)}
										inputProps={{
											placeholder: 'set start date',
											// onChang: (e) => setStartDate(moment(e.target.value)),
											// value: startDate
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div onKeyPress={(e) => handleEnter(e)} className={`${style.dateRow}`}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.dateLabel}>Expire date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									{expireDateError && <div className={style.dateError}>{expireDateError}</div>}
									<Datetime
										dateFormat="MM/DD/YYYY"
										timeFormat="HH:mm"
										disableOnClickOutside
										initialViewDate={moment()}
										value={expireDate}
										closeOnSelect
										onChange={(date) => {
											setExpireDate(date);
											console.log(date);
										}}
										inputProps={{
											placeholder: 'set expire date',
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						{model !== 'shopAdmin' && (
							<div
								onKeyPress={(e) => handleEnter(e)}
								className={`${style.row} ${style.configurationRow}`}
							>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={`${style.label} ${style.configurationLabel}`}>
											Configuration:
										</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<FormControlLabel
											control={
												<Checkbox
													color="gray"
													name="For All Users"
													checked={isForAll}
													onChange={(e) => {
														setIsForAll((isForAll) => !isForAll);
														setSearch('');
														setIncludeIds([]);
														setFetchMoreSkip(10);
														setNoSearch('');
														setDoesntIncludeIds([]);
														setNoFetchMoreSkip(10);
													}}
												/>
											}
											label="For All Users"
										/>
										{isForAll && (
											<FormControlLabel
												control={
													<Checkbox
														color="gray"
														name="Has Exception"
														checked={hasExeption}
														onChange={(e) => {
															setHasExeption((hasExeption) => !hasExeption);
															setNoSearch('');
															setDoesntIncludeIds([]);
															setNoFetchMoreSkip(10);
															setSearch('');
															setIncludeIds([]);
															setFetchMoreSkip(10);
														}}
													/>
												}
												label="Has Exception"
											/>
										)}
									</GridItem>
								</GridContainer>
							</div>
						)}

						{model !== 'shopAdmin' && !isForAll && (
							<div onKeyPress={(e) => handleEnter(e)} className={style.tagRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.tagLabel}>Include:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.tagsContainer}>
											{includeIds.map((includeId) => (
												<div key={includeId} className={style.idDiv}>
													{userSelectArray.some((item) => item.value === includeId) &&
														userSelectArray.find((item) => item.value === includeId).name}
													<div className={style.removeIconDiv}>
														<CancelRoundedIcon
															onClick={() =>
																setIncludeIds(includeIds.filter((i) => i !== includeId))
															}
														/>
													</div>
												</div>
											))}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{model !== 'shopAdmin' && !isForAll && (
							<div className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5}>
										<div className={style.label}>Include users:</div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7}>
										<div>
											<SelectSearch
												options={userSelectOptions}
												value={includeIds}
												name=""
												closeOnSelect={false}
												getOptions={(searchWord) => {
													setSearch(searchWord);
												}}
												onScroll={(e) => handleScroll(e)}
												printOptions="on-focus"
												multiple
												search
												placeholder="Choose users"
												onChange={(value) => {
													setIncludeIds(value);
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{type !== 'shopAdmin' && isForAll && hasExeption && (
							<div onKeyPress={(e) => handleEnter(e)} className={style.tagRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.tagLabel}>Exceptions:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<div className={style.tagsContainer}>
											{doesntIncludeIds.map((doesntIncludeId) => (
												<div key={doesntIncludeId} className={style.idDiv}>
													{noUserSelectArray.some((item) => item.value === doesntIncludeId) &&
														noUserSelectArray.find((item) => item.value === doesntIncludeId)
															.name}
													<div className={style.removeIconDiv}>
														<CancelRoundedIcon
															onClick={() =>
																setDoesntIncludeIds(
																	doesntIncludeIds.filter(
																		(i) => i !== doesntIncludeId
																	)
																)
															}
														/>
													</div>
												</div>
											))}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{type !== 'shopAdmin' && isForAll && hasExeption && (
							<div className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5}>
										<div className={style.label}>Exceptions:</div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7}>
										<div>
											<SelectSearch
												options={noUserSelectOptions}
												value={doesntIncludeIds}
												name=""
												closeOnSelect={false}
												getOptions={(searchWord) => {
													setNoSearch(searchWord);
												}}
												onScroll={(e) => noHandleScroll(e)}
												printOptions="on-focus"
												multiple
												search
												placeholder="Choose users"
												onChange={(value) => setDoesntIncludeIds(value)}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.buttonContainer}>
				<Button color="whiteButton" onClick={onCancel}>
					Cancel
				</Button>

				<Button disabled={disableButton} onClick={onSubmit} color={themeColor.primaryColor}>
					Submit
				</Button>
			</div>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default AddPromotionForm;
