import React, { useState } from 'react';

import Close from '@material-ui/icons/Close';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Button from 'components/CustomButtons/Button.js';
import { graphqlUrl } from 'utils/config';
import style from './ProductDetails.module.scss';

const ProductDetails = ({ product, close, type , theme: propTheme }) => {
	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const grayTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'primary',
	};

	let theme = orangeTheme;
	if (type === 'Purchase') {
		theme = greenTheme;
	} else if (type === 'Restaurant') {
		theme = orangeTheme;
	} else if (type === 'superAdmin') {
		theme = grayTheme;
	}

	const profileImages = Array.from(product.photoUrl, (item) => `${graphqlUrl}${item}`);
	const [isProfileImagesOpen, setIsProfileImagesOpen] = useState(false);
	const [profileImageIndex, setProfileImageIndex] = useState(0);

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>{propTheme === 'Restaurant' ? 'Food' : 'Product'} detail</h4>
				</div>
			</div>
			<div className={style.imageContainer}>
				<img
					title="view all photos"
					onClick={() => setIsProfileImagesOpen(true)}
					className={style.image}
					src={`${graphqlUrl}${product.photoUrl[0]}`}
				/>
			</div>
			<div className={style.infoContainer}>
				<div className={style.rowContainer}>
					<div>Title:</div>
					<div>{product.title}</div>
				</div>
				<div className={style.rowContainer}>
					<div>Price:</div>
					<div>{'$' + product.price}</div>
				</div>

				<div className={style.rowContainer}>
					<div>Description: </div>
					<div style={{ textAlign: 'right' }}>{product.description}</div>
				</div>

				<div className={style.rowContainer}>
					<div>Stock:</div>
					<div>{product.stock}</div>
				</div>
			</div>
			<div className={style.footer}>
				<Button onClick={close} color={theme.primaryColor}>
					Close
				</Button>
			</div>
			{isProfileImagesOpen && (
				<Lightbox
					mainSrc={profileImages[profileImageIndex]}
					nextSrc={profileImages[(profileImageIndex + 1) % profileImages.length]}
					prevSrc={profileImages[(profileImageIndex + profileImages.length - 1) % profileImages.length]}
					onCloseRequest={() => setIsProfileImagesOpen(false)}
					onMovePrevRequest={() =>
						setProfileImageIndex((profileImageIndex + profileImages.length - 1) % profileImages.length)
					}
					onMoveNextRequest={() => setProfileImageIndex((profileImageIndex + 1) % profileImages.length)}
				/>
			)}
		</div>
	);
};

export default ProductDetails;
