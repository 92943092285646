import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ReactTable from 'react-table';
import SelectSearch from 'react-select-search';
import Dialog from '@material-ui/core/Dialog';
import Pagination from 'commonComponents/pagination/Pagination';
import Modal from 'commonComponents/modal/Modal';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PaymentDetails from 'superAdminViews/finance/pay/PaymentDetails';
import style from 'commonComponents/finance/Finance.module.scss';
import { endPoint } from 'utils/config';

const CREATE_TRANSACTION = gql`
	mutation createTransactionByAdmin($payments: [ID!]!, $for: TransactionFor!, $id: ID!) {
		createTransactionByAdmin(input: { payments: $payments, data: { for: $for, id: $id } }) {
			_id
		}
	}
`;

const GET_PAYMENTS = gql`
	query getPaymentsByAdmin(
		$limit: Int
		$skip: Int
		$driver: ID
		$shop: ID
		$from: Date
		$to: Date
		$status: PaymentStatus
		$type: PaymentType
		$createdAtSort: Int
	) {
		getPaymentsByAdmin(
			filters: { shop: $shop, driver: $driver, status: $status, type: $type, from: $from, to: $to }
			sort: { createdAt: $createdAtSort }
			pagination: { limit: $limit, skip: $skip }
		) {
			_id
			for
			user {
				_id
				fullName
			}
			driver {
				_id
				fullName
			}
			shop {
				_id
				shopAdmin {
					_id
				}
			}
			transactionId {
				_id
				transactionId
				status
				paidAt
				type
				amount
				payments {
					_id
				}
				user {
					_id
				}
				driver {
					_id
				}
				shop {
					_id
				}
				paymentIntent
				createdAt
				updatedAt
			}
			status
			createdAt
			amount
			type
		}
	}
`;

const GET_PAYMENTS_COUNT = gql`
	query getPaymentsByAdminCount(
		$driver: ID
		$shop: ID
		$from: Date
		$to: Date
		$status: PaymentStatus
		$type: PaymentType
	) {
		getPaymentsByAdminCount(
			filters: { shop: $shop, driver: $driver, status: $status, type: $type, from: $from, to: $to }
		)
	}
`;

const GET_DRIVERS = gql`
	query getDriversByAdmin($limit: Int, $skip: Int, $fullName: String) {
		getDriversByAdmin(pagination: { limit: $limit, skip: $skip }, filters: { fullName: $fullName }) {
			_id
			fullName
			phoneNumber
		}
	}
`;

const GET_SHOPS = gql`
	query getShopsByAdmin($limit: Int, $skip: Int, $name: String) {
		getShopsByAdmin(pagination: { limit: $limit, skip: $skip }, filters: { name: $name }) {
			_id
			name
			shopAdmin {
				fullName
				phoneNumber
			}
		}
	}
`;

const Pay = (props) => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);

	const forOptions = ['driver', 'shop'];

	const [forVariable, setForVariable] = useState(forOptions[0]);
	const [driverId, setDriverId] = useState('');
	const [shopId, setShopId] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [paymentIds, setPaymentIds] = useState([]);
	const [driverSelectOptions, setDriverSelectOptions] = useState([]);
	const [shopSelectOptions, setShopSelectOptions] = useState([]);
	const [startDateError, setStartDateError] = useState('');
	const [endDateError, setEndDateError] = useState('');
	const [paymentDetails, setPaymentDetails] = useState();

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [modal, setModal] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [checkAll, setCheckAll] = useState(false);

	useEffect(() => {
		setPaymentIds([]);
	}, [forVariable]);

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getPaymentsByAdminCount / perPage) - 1 &&
			countData.getPaymentsByAdminCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
			setCheckAll(false);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
			setCheckAll(false);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
		setCheckAll(false);
	};
	const onLastPage = () => {
		if (countData.getPaymentsByAdminCount !== 0) {
			setPageCount(Math.ceil(countData.getPaymentsByAdminCount / perPage) - 1);
			setCheckAll(false);
		}
	};

	useEffect(() => {


		// setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
		if (forVariable === 'driver' && driverId) {
			getPaymentsByAdmin({
				variables: {
					limit: perPage,
					skip: pageCount * perPage,
					driver: driverId.trim(),
					from: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
					to: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
					status: 'UNPAID',
					type: 'PAY_TO_DRIVER',
					createdAtSort: -1,
				},
			});
			getPaymentsByAdminCount({
				variables: {
					driver: driverId.trim(),
					from: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
					to: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
					status: 'UNPAID',
					type: 'PAY_TO_DRIVER',
				},
			});
		}
		if (forVariable === 'shop' && shopId) {
			getPaymentsByAdmin({
				variables: {
					limit: perPage,
					skip: pageCount * perPage,
					shop: shopId.trim(),
					from: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
					to: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
					status: 'UNPAID',
					type: 'PAY_TO_SHOP',
					createdAtSort: -1,
				},
			});
			getPaymentsByAdminCount({
				variables: {
					shop: shopId.trim(),
					from: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
					to: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
					status: 'UNPAID',
					type: 'PAY_TO_SHOP',
				},
			});
		}
	}, [pageCount, forVariable]);

	const onSelectPaymentDetails = (payment) => {
		setPaymentDetails(payment);
		setModal(true);
	};

	const { loading: driversLoading, error: driversError, data: driversData } = useQuery(GET_DRIVERS, {
		variables: { limit: 10, skip: 0, fullName: '' },
		fetchPolicy: 'network-only',
	});

	const { loading: shopsLoading, error: shopsError, data: shopsData } = useQuery(GET_SHOPS, {
		variables: { limit: 10, skip: 0, name: '' },
		fetchPolicy: 'network-only',
	});

	const [
		getDriversByAdmin,
		{ loading: driversLazyLoading, error: driversLazyError, data: driversLazyData, fetchMore },
	] = useLazyQuery(GET_DRIVERS);

	const [
		getShopsByAdmin,
		{ loading: shopsLazyLoading, error: shopsLazyError, data: shopsLazyData, fetchMore: fetchMoreShops },
	] = useLazyQuery(GET_SHOPS);

	const [fetchMoreSkip, setFetchMoreSkip] = useState(10);
	const [fetchMoreSkipShop, setFetchMoreSkipShop] = useState(10);

	const onLoadMore = () => {
		fetchMore({
			query: GET_DRIVERS,
			variables: { limit: 10, skip: fetchMoreSkip, fullName: search ? search : '' },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				console.log('this is new results:', fetchMoreResult.getDriversByAdmin);
				setDriverSelectOptions((driverSelectOptions) => [
					...driverSelectOptions,
					...Array.from(fetchMoreResult.getDriversByAdmin, (driver) => ({
						name: driver.fullName + ' - ' + driver.phoneNumber,
						value: driver._id,
					})),
				]);
			},
		});
		setFetchMoreSkip((fetchMoreSkip) => fetchMoreSkip + 10);
	};

	const onLoadMoreShops = () => {
		fetchMoreShops({
			query: GET_SHOPS,
			variables: { limit: 10, skip: fetchMoreSkipShop, name: shopSearch ? shopSearch : '' },
			updateQuery: (previousResult, { fetchMoreResult }) => {
				console.log('this is new results:', fetchMoreResult.getShopsByAdmin);
				setShopSelectOptions((shopSelectOptions) => [
					...shopSelectOptions,
					...Array.from(
						fetchMoreResult.getShopsByAdmin.filter((shop) => shop.shopAdmin && shop.shopAdmin.phoneNumber),
						(shop) => ({
							name: shop.name + ' - ' + shop.shopAdmin.phoneNumber,
							value: shop._id,
						})
					),
				]);
			},
		});
		setFetchMoreSkipShop((fetchMoreSkipShop) => fetchMoreSkipShop + 10);
	};

	const handleScroll = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			onLoadMore();
		}
	};

	const handleScrollShops = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			onLoadMoreShops();
		}
	};

	useEffect(() => {
		if (driversLazyData) {
			setDriverSelectOptions(
				Array.from(driversLazyData.getDriversByAdmin, (driver) => ({
					name: driver.fullName + ' - ' + driver.phoneNumber,
					value: driver._id,
				}))
			);
		}
	}, [driversLazyLoading, driversLazyData]);

	useEffect(() => {
		if (shopsLazyData) {

			console.log("shopsLazyData.getShopsByAdmin: ",JSON.stringify(shopsLazyData.getShopsByAdmin.filter((shop) => shop.shopAdmin)))
			setShopSelectOptions(
				Array.from(shopsLazyData.getShopsByAdmin.filter((shop) => shop.shopAdmin), (shop) => ({
					name: shop.name + ' - ' + shop.shopAdmin.phoneNumber,
					value: shop._id,
				}))
			);
		}
	}, [, shopsLazyData, shopsLazyLoading]);

	const [search, setSearch] = useState('');
	const [shopSearch, setShopSearch] = useState('');

	useEffect(() => {
		const handler = setTimeout(() => {
			getDriversByAdmin({
				variables: {
					limit: 10,
					skip: 0,
					fullName: search ? search.trim() : '',
				},
			});
		}, 200);
		return () => clearTimeout(handler);
	}, [search]);

	useEffect(() => {
		const handler = setTimeout(() => {
			getShopsByAdmin({
				variables: {
					limit: 10,
					skip: 0,
					name: shopSearch ? shopSearch.trim() : '',
				},
			});
		}, 200);
		return () => clearTimeout(handler);
	}, [shopSearch]);

	const [getPaymentsByAdmin, { loading, error, data }] = useLazyQuery(GET_PAYMENTS);
	const [getPaymentsByAdminCount, { loading: countLoading, error: countError, data: countData }] = useLazyQuery(
		GET_PAYMENTS_COUNT
	);

	const [createTransactionByAdmin, { data: mutationData, error: mutationError }] = useMutation(CREATE_TRANSACTION);

	if (error) {
		console.log(error);
	}

	useEffect(() => {
		if (data) {
			// console.log('paymentIds:', paymentIds);
			console.log(data);
			console.log('new payment ids', Array.from(data.getPaymentsByAdmin, (item) => item._id));
			setCheckAll(true);
			data.getPaymentsByAdmin.map((payment) => {
				if (!paymentIds.includes(payment._id)) {
					console.log('its not hereee');
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, paymentIds, pageCount]);

	const filterPayments = () => {
		if (!startDate) {
			setStartDateError("start date can't be empty*");
		} else {
			if (moment(startDate, 'MM/DD/YYYY', true).isValid() || moment(startDate, 'MM-DD-YYYY', true).isValid()) {
				setStartDateError('');
			} else {
				setStartDateError('invalid date');
			}
		}
		if (!endDate) {
			setEndDateError("end date can't be empty*");
		} else {
			if (moment(endDate, 'MM/DD/YYYY', true).isValid() || moment(endDate, 'MM-DD-YYYY', true).isValid()) {
				setEndDateError('');
			} else {
				setEndDateError('invalid date');
			}
		}
		if (
			startDate &&
			endDate &&
			(moment(startDate, 'MM/DD/YYYY', true).isValid() || moment(startDate, 'MM-DD-YYYY', true).isValid()) &&
			(moment(endDate, 'MM/DD/YYYY', true).isValid() || moment(endDate, 'MM-DD-YYYY', true).isValid())
		) {
			if (forVariable === 'driver' && driverId) {
				getPaymentsByAdmin({
					variables: {
						limit: perPage,
						skip: pageCount * perPage,
						driver: driverId.trim(),
						from: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
						to: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
						status: 'UNPAID',
						type: 'PAY_TO_DRIVER',
						createdAtSort: -1,
					},
				});
				getPaymentsByAdminCount({
					variables: {
						driver: driverId.trim(),
						from: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
						to: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
						status: 'UNPAID',
						type: 'PAY_TO_DRIVER',
					},
				});
			}
			if (forVariable === 'shop' && shopId) {
				getPaymentsByAdmin({
					variables: {
						limit: perPage,
						skip: pageCount * perPage,
						shop: shopId.trim(),
						from: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
						to: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
						status: 'UNPAID',
						type: 'PAY_TO_SHOP',
						createdAtSort: -1,
					},
				});
				getPaymentsByAdminCount({
					variables: {
						shop: shopId.trim(),
						from: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
						to: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
						status: 'UNPAID',
						type: 'PAY_TO_SHOP',
					},
				});
			}
		}
	};

	const submitTransaction = () => {
		if (((forVariable === 'driver' && driverId) || (forVariable === 'shop' && shopId)) && paymentIds.length > 0) {
			createTransactionByAdmin({
				variables: {
					for: forVariable.toLowerCase(),
					id: forVariable === 'driver' ? driverId.trim() : shopId.trim(),
					payments: paymentIds,
				},
			})
				.then((res) => {
					window.location.href = endPoint + '/superAdmin/transactions';
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	return (
		<div className={style.mainDiv}>
			{data && data.getPaymentsByAdmin.length === 0 && (
				<GridItem xs={12} sm={12} md={12}>
					<div style={{ textAlign: 'center', fontWeight: '500', color: 'red', fontSize: '16px' }}>
						No Unpaid Payment Found!
					</div>
				</GridItem>
			)}
			{data && data.getPaymentsByAdmin.length > 0 && (
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div className={style.row}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								Payments:
							</GridItem>

							{data && (
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<div className={style.buttonContainer}>
										<Button
											color="yellow"
											disabled={paymentIds.length === 0 ? true : false}
											onClick={submitTransaction}
										>
											Pay
										</Button>
									</div>
								</GridItem>
							)}
						</GridContainer>
					</div>
				</GridItem>
			)}

			{data && data.getPaymentsByAdmin.length > 0 && (
				<ReactTable
					data={data.getPaymentsByAdmin.map((comment) => ({
						checkbox: (
							<div>
								<Checkbox
									color="default"
									checked={paymentIds.includes(comment._id)}
									onChange={() => {
										if (paymentIds.includes(comment._id)) {
											setPaymentIds(paymentIds.filter((id) => id !== comment._id));
										} else {
											setPaymentIds([...paymentIds, comment._id]);
										}
									}}
								/>
							</div>
						),
						amount: (
							<div>
								<button onClick={() => onSelectPaymentDetails(comment)} className={style.detailIconDiv}>
									<span>i</span>
								</button>
								{comment.amount.toFixed(2)}
							</div>
						),
						date: moment(comment.createdAt).format('MM/DD/YYYY HH:mm'),
					}))}
					columns={[
						{
							Header: (
								<div className={style.fitTitleDivCheckbox}>
									<Checkbox
										color="default"
										name="Select All"
										checked={checkAll}
										onChange={() => {
											if (checkAll) {
												data.getPaymentsByAdmin.map((payment) => {
													setPaymentIds((paymentIds) =>
														paymentIds.filter((id) => id !== payment._id)
													);
												});
												setCheckAll(false);
											} else {
												setPaymentIds((paymentIds) => [
													...paymentIds,
													...Array.from(data.getPaymentsByAdmin, (item) => item._id),
												]);
												setCheckAll(true);
											}
										}}
									/>
								</div>
							),
							accessor: 'checkbox',
							sortable: false,
							filterable: false,
							width: 60,
						},
						{
							Header: <div className={style.fitTitleDiv}>Amount</div>,
							accessor: 'amount',
							sortable: false,
							filterable: false,
						},
						{
							Header: <div className={style.fitTitleDiv}>Date</div>,
							accessor: 'date',
							sortable: false,
							filterable: false,
						},
					]}
					defaultPageSize={perPage}
					showPaginationTop={false}
					showPaginationBottom={false}
					className="-striped -highlight"
				/>
			)}
			{countData && countData.getPaymentsByAdminCount > 0 && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getPaymentsByAdminCount === 0
							? 1
							: Math.ceil(countData.getPaymentsByAdminCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div className={style.dropdownRow}>
						<GridContainer>
							<GridItem xs={12} sm={5} md={5}>
								<div className={style.label}>For:</div>
							</GridItem>
							<GridItem xs={12} sm={7} md={7}>
								<div className={style.dropdownContainer}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={forVariable}
										onClick={(e) => setForVariable(e)}
										dropdownList={forOptions}
									/>
								</div>
							</GridItem>
						</GridContainer>
					</div>
					{forVariable === 'driver' && (
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={5} md={5}>
									<div className={style.label}>Driver:</div>
								</GridItem>
								<GridItem xs={12} sm={7} md={7}>
									<div>
										<SelectSearch
											options={driverSelectOptions}
											value={driverId}
											getOptions={(searchWord) => {
												setSearch(searchWord);
											}}
											onScroll={(e) => handleScroll(e)}
											name=""
											search
											placeholder="Choose driver"
											onChange={(value) => setDriverId(value)}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					)}
					{forVariable === 'shop' && (
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={5} md={5}>
									<div className={style.label}>Shop:</div>
								</GridItem>
								<GridItem xs={12} sm={7} md={7}>
									<div>
										<SelectSearch
											options={shopSelectOptions}
											value={shopId}
											getOptions={(searchWord) => {
												setShopSearch(searchWord);
											}}
											onScroll={(e) => handleScrollShops(e)}
											name=""
											search
											placeholder="Choose shop"
											onChange={(value) => setShopId(value)}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					)}
					<div className={style.dateRow}>
						<GridContainer>
							<GridItem xs={12} sm={6} md={5}>
								<div className={style.dateLabel}>Start date:</div>
							</GridItem>
							<GridItem xs={12} sm={6} md={7}>
								{startDateError && <div className={style.dateError}>{startDateError}</div>}
								<Datetime
									timeFormat={false}
									disableOnClickOutside
									value={startDate}
									closeOnSelect
									onChange={(date) => setStartDate(date)}
									inputProps={{
										placeholder: 'set start date',
									}}
								/>
							</GridItem>
						</GridContainer>
					</div>
					<div className={style.dateRow}>
						<GridContainer>
							<GridItem xs={12} sm={6} md={5}>
								<div className={style.dateLabel}>End date:</div>
							</GridItem>
							<GridItem xs={12} sm={6} md={7}>
								{endDateError && <div className={style.dateError}>{endDateError}</div>}
								<Datetime
									timeFormat={false}
									disableOnClickOutside
									value={endDate}
									closeOnSelect
									onChange={(date) => setEndDate(date)}
									inputProps={{
										placeholder: 'set end date',
									}}
								/>
							</GridItem>
						</GridContainer>
					</div>
					<div style={{ paddingBottom: '10px' }}>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.buttonContainer}>
								<Button color="whiteButton" onClick={filterPayments}>
									Show payments
								</Button>
							</div>
						</GridItem>
					</div>
				</GridItem>
			</GridContainer>
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<PaymentDetails close={() => setModal(false)} payment={paymentDetails} />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setErrorModal(false)} type="notif" text={errorText} />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Pay;
