import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ErrorPage from 'commonComponents/errorPage/ErrorPage'
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

import AddPromotionForm from "commonComponents/addPromotion/AddPromotionForm";

const GET_SHOP_PROMOTION = gql`
  query getOrderPromotionsByAdmin($limit: Int, $skip: Int $_id: ID) {
    getOrderPromotionsByAdmin(pagination: { limit: $limit, skip: $skip } filters: {_id: $_id}) {
      _id
      condition
      type
      canUse{
          _id
      }
      canNotUse{
          _id
      }
    #   percent
      maximumPromotion
      promotionCode
      from 
      to
      for
      shop{
          _id
          name
      }
      shopType
    }
  }
`;

const EditShopPromotion = (props) => {
  const { loading, error, data } = useQuery(GET_SHOP_PROMOTION, {
    variables: { promotionId: props.match.params.id , limit: 1 , skip: 0 },
  });

  console.log(props)

  if (loading)
    return (
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <BeatLoader
          css={css`
            display: block;
            margin: 0 auto;
            border-color: red;
          `}
          size={15}
          margin={2}
          color={"gray"}
          loading={true}
        />
      </div>
    );
  if (error) {
    console.log(error);
    return <ErrorPage/>;
  }

  return <AddPromotionForm theme="superAdmin" model="shop" promotion={data.getOrderPromotionsByAdmin[0]} />;
};

export default EditShopPromotion;