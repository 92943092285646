import React , {useContext} from 'react'

import AddShopMenu from 'commonComponents/addShopMenu/AddShopMenu'
import {ThemeContext} from 'index'

const ShopAddShopMenu = props => {
    const theme = useContext(ThemeContext)
    return <AddShopMenu theme={theme} type="shopAdmin" />
}

export default ShopAddShopMenu