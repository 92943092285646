import React from "react";

import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ErrorPage from 'commonComponents/errorPage/ErrorPage'
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

import AddPromotion from "superAdminViews/ride/addPromotion/AddPromotion";
import AddPromotionForm from "commonComponents/addPromotion/AddPromotionForm";

const GET_PROMOTION = gql`
  query getTripPromotion($promotionId: ID!) {
    getTripPromotion(promotionId: $promotionId) {
      _id
      condition
      type
      canUse
      for 
      useLimitCount
      canNotUse
      percent
      maximumPromotion
      promotionCode
      from
      to
    }
  }
`;

const EditPromotion = (props) => {
  const { loading, error, data } = useQuery(GET_PROMOTION, {
    variables: { promotionId: props.match.params.id },
  });

  if (loading)
    return (
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <BeatLoader
          css={css`
            display: block;
            margin: 0 auto;
            border-color: red;
          `}
          size={15}
          margin={2}
          color={"gray"}
          loading={true}
        />
      </div>
    );
  if (error) {
    console.log(error);
    return <ErrorPage/>;
  }

  return <AddPromotionForm theme="superAdmin" model="super" promotion={data.getTripPromotion} />;
};

export default EditPromotion;
