import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import style from './EnterCode.module.scss';
import logo from 'assets/img/sparkLoginLogo.svg';
import { endPoint } from 'utils/config';

const CHANGE_PASSWORD = gql`
	mutation changeAdminPassword($emailOrPhoneNumber: String!, $password: String!, $phoneChangePasswordCode: String!) {
		changeAdminPassword(
			changePasswordInput: {
				emailOrPhoneNumber: $emailOrPhoneNumber
				password: $password
				phoneChangePasswordCode: $phoneChangePasswordCode
			}
		) {
			message
		}
	}
`;

const ForgetPasswordChange = (props) => {
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');

	const [newPasswordVisible, setNewPasswordVisible] = useState(false);
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

	const [newPasswordError, setNewPasswordError] = useState(false);
	const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);

	const [disableButton, setDisableButton] = useState(false);

	const [addModal, setAddModal] = useState(false);
	const [addText, setAddText] = useState('');
	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [changeAdminPassword] = useMutation(CHANGE_PASSWORD);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	const onSubmit = () => {
		if (!newPassword) {
			setNewPasswordError("new password can't be empty");
		} else {
			if (newPassword.length < 8) {
				setNewPasswordError('enter at least 8 characters');
			} else {
				setNewPasswordError('');
			}
		}
		if (!confirmNewPassword) {
			setConfirmNewPasswordError("confirm password can't be empty");
		} else {
			if (newPassword !== confirmNewPassword) {
				setConfirmNewPasswordError('confirm should be equal to new password');
			} else {
				setConfirmNewPasswordError('');
			}
		}
		if (newPassword && newPassword.length > 7 && confirmNewPassword && newPassword === confirmNewPassword) {
			setDisableButton(true);
			changeAdminPassword({
				variables: {
					emailOrPhoneNumber: window.localStorage.getItem('emailForgetPassword'),
					password: newPassword.trim(),
					phoneChangePasswordCode: window.localStorage.getItem('changePasswordCode'),
				},
			})
				.then((res) => {
					console.log(res);
					setAddText(res.data.changeAdminPassword.message);
					setAddModal(true);
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDivBig}>
			<div className={style.imageContainer}>
				<img alt="spark" src={logo} />
			</div>
			<div className={style.formContainer}>
				<div className={style.row}>
					<GridContainer>
						<GridItem xs={12} sm={6} md={5}>
							<div className={style.label}>New password:</div>
						</GridItem>
						<GridItem xs={12} sm={6} md={7}>
							<CustomInput
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								error={newPasswordError}
								labelText={newPasswordError ? newPasswordError : 'new password'}
								id="float"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: newPasswordVisible ? 'text' : 'password',
									endAdornment: newPasswordVisible ? (
										<VisibilityIcon
											className={style.icon}
											onClick={() => setNewPasswordVisible((visible) => !visible)}
											style={{ fontSize: '18px', color: 'gray' }}
											position="end"
										></VisibilityIcon>
									) : (
										<VisibilityOffIcon
											className={style.icon}
											onClick={() => setNewPasswordVisible((visible) => !visible)}
											style={{ fontSize: '18px', color: 'gray' }}
											position="end"
										></VisibilityOffIcon>
									),
								}}
							/>
						</GridItem>
					</GridContainer>
				</div>
				<div className={style.row}>
					<GridContainer>
						<GridItem xs={12} sm={6} md={5}>
							<div className={style.label}>Confirm new password:</div>
						</GridItem>
						<GridItem xs={12} sm={6} md={7}>
							<CustomInput
								value={confirmNewPassword}
								onChange={(e) => setConfirmNewPassword(e.target.value)}
								error={confirmNewPasswordError}
								labelText={confirmNewPasswordError ? confirmNewPasswordError : 'confirm password'}
								id="float"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									type: confirmPasswordVisible ? 'text' : 'password',
									endAdornment: confirmPasswordVisible ? (
										<VisibilityIcon
											className={style.icon}
											onClick={() => setConfirmPasswordVisible((visible) => !visible)}
											style={{ fontSize: '18px', color: 'gray' }}
											position="end"
										></VisibilityIcon>
									) : (
										<VisibilityOffIcon
											className={style.icon}
											onClick={() => setConfirmPasswordVisible((visible) => !visible)}
											style={{ fontSize: '18px', color: 'gray' }}
											position="end"
										></VisibilityOffIcon>
									),
								}}
							/>
						</GridItem>
					</GridContainer>
				</div>
				<div className={style.buttonContainer}>
					<GridContainer>
						
						<GridItem xs={12} sm={12} md={12}>
							<Button disabled={disableButton} size="lg" fullWidth onClick={onSubmit} color="yellow">
								Submit
							</Button>
						</GridItem>
					</GridContainer>
				</div>
			</div>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => (window.location.href = endPoint + '/login')} type="notif" text={addText} />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ForgetPasswordChange;
