import React from "react";
import { NavLink, Route, Switch, Redirect } from "react-router-dom";

import Comments from "./Comments";
import CommentsOnPassenger from "./CommentsOnPassenger";
import style from "superAdminViews/restaurant/shopPage/ShopPage.module.scss";

const CommentPage = (props) => {
  return (
    <div className={style.mainDiv}>
      <div className={style.navContainer}>
        <div>
          <NavLink
            className={style.nav}
            activeClassName={style.activeNav}
            to={`/superAdmin/evaluations/commentsOnDriver`}
          >
            Driver Reviews
          </NavLink>
        </div>
        <div>
          <NavLink
            className={style.nav}
            activeClassName={style.activeNav}
            to={`/superAdmin/evaluations/commentsOnPassenger`}
          >
            {" "}
            Passenger Reviews
          </NavLink>
        </div>
      </div>
      <div>
        <Switch>
          <Route
            exact={true}
            path="/superAdmin/evaluations/commentsOnDriver"
            component={Comments}
          />
          <Route
            exact={true}
            path="/superAdmin/evaluations/commentsOnPassenger"
            component={CommentsOnPassenger}
          />

          <Redirect
            from="/superAdmin/evaluations"
            to="/superAdmin/evaluations/commentsOnDriver"
          />
        </Switch>
      </div>
    </div>
  );
};

export default CommentPage;
