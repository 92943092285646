import React from 'react'
import { NavLink, Route, Switch , Redirect } from "react-router-dom";

import DriverReadyComments from 'superAdminViews/setting/defaults/DriverReadyComments'
import PassengerReadyComments from 'superAdminViews/setting/defaults/PassengerReadyComments'
import ShopReadyComments from 'superAdminViews/setting/defaults/ShopReadyComments'
import CancelTripReason from 'superAdminViews/setting/defaults/CancelTripReason'
import ReadyMessages from 'superAdminViews/setting/defaults/ReadyMessages'
import ParcelVolume from 'superAdminViews/setting/defaults/ParcelVolume'
import ParcelWeight from 'superAdminViews/setting/defaults/ParcelWeight'
import DriverHowItWorks from 'superAdminViews/setting/defaults/DriverHowItWorks'
import Legals from 'superAdminViews/setting/defaults/Legals'
import Helps from 'superAdminViews/setting/defaults/Helps'
import style from 'superAdminViews/restaurant/shopPage/ShopPage.module.scss'


const Defaults = props => {
    return (
      <div className={style.mainDiv}>
      <div className={style.navContainer}>
      <div>
          <NavLink
          className={style.nav}
          activeClassName={style.activeNav}
            to={`/superAdmin/defaults/driverReadyComment`}
          >
            Driver ready comment
          </NavLink>
        </div>
        <div>
          <NavLink
          className={style.nav}
          activeClassName={style.activeNav}
            to={`/superAdmin/defaults/passengerReadyComment`}
          >
            Passenger ready comment
          </NavLink>
        </div>
        <div>
          <NavLink
          className={style.nav}
          activeClassName={style.activeNav}
            to={`/superAdmin/defaults/shopReadyComment`}
          >
            {" "}
            Shop ready comment
          </NavLink>
        </div>
        <div>
          <NavLink
          className={style.nav}
          activeClassName={style.activeNav}
            to={`/superAdmin/defaults/cancelTripReason`}
          >
            Cancel trip reason
          </NavLink>
        </div>
        <div>
          <NavLink
          className={style.nav}
          activeClassName={style.activeNav}
            to={`/superAdmin/defaults/readyMessage`}
          >
            Ready message
          </NavLink>
        </div>
        <div>
          <NavLink
          className={style.nav}
          activeClassName={style.activeNav}
            to={`/superAdmin/defaults/driverHowItWorks`}
          >
            Driver how it works
          </NavLink>
        </div>
        <div>
          <NavLink
          className={style.nav}
          activeClassName={style.activeNav}
            to={`/superAdmin/defaults/parcelVolume`}
          >
            Parcel volume
          </NavLink>
        </div>
        <div>
          <NavLink
          className={style.nav}
          activeClassName={style.activeNav}
            to={`/superAdmin/defaults/parcelWeight`}
          >
            Parcel weight
          </NavLink>
        </div>
        <div>
          <NavLink
          className={style.nav}
          activeClassName={style.activeNav}
            to={`/superAdmin/defaults/policies`}
          >
          Policies
          </NavLink>
        </div>
        <div>
          <NavLink
          className={style.nav}
          activeClassName={style.activeNav}
            to={`/superAdmin/defaults/help`}
          >
            Help
          </NavLink>
        </div>
      </div>
      <div>
        <Switch>
          <Route
          exact={true}
            path="/superAdmin/defaults/driverReadyComment"
            component={DriverReadyComments}
          />
          <Route
          exact={true}
            path="/superAdmin/defaults/passengerReadyComment"
            component={PassengerReadyComments}
          />
          <Route
          exact={true}
            path="/superAdmin/defaults/shopReadyComment"
            component={ShopReadyComments}
          />
          <Route
          exact
            path="/superAdmin/defaults/cancelTripReason"
            component={CancelTripReason}
          />
          <Route
          exact={true}
            path="/superAdmin/defaults/readyMessage"
            component={ReadyMessages}
          />
          <Route
          exact={true}
            path="/superAdmin/defaults/driverHowItWorks"
            component={DriverHowItWorks}
          />
          <Route
          exact={true}
            path="/superAdmin/defaults/parcelVolume"
            component={ParcelVolume}
          />
          <Route
          exact={true}
            path="/superAdmin/defaults/parcelWeight"
            component={ParcelWeight}
          />
          <Route
          exact={true}
            path="/superAdmin/defaults/policies"
            component={Legals}
          />
          <Route
          exact={true}
            path="/superAdmin/defaults/help"
            component={Helps}
          />
          <Redirect from="/superAdmin/defaults" to="/superAdmin/defaults/driverReadyComment" />

        </Switch>
      </div>

      
    </div>
    )
}

export default Defaults