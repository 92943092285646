import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { endPoint } from 'utils/config';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Datetime from 'react-datetime';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import style from './EditUserForm.module.scss';

const EDIT_USER = gql`
	mutation updateUserByAdmin(
		$userId: ID!
		$fullName: String
		$email: String!
		$phoneNumber: String!
		$isVerified: Boolean
		$emailVerified: Boolean
		$phoneNumberVerified: Boolean
		$birthDate: Date
		$gender: UserGender
		$state: UserStateInput
	) {
		updateUserByAdmin(
			userId: $userId
			input: {
				fullName: $fullName
				isVerified: $isVerified
				emailVerified: $emailVerified
				phoneNumberVerified: $phoneNumberVerified
				email: $email
				phoneNumber: $phoneNumber
				birthDate: $birthDate
				gender: $gender
				state: $state
			}
		) {
			_id
			fullName
		}
	}
`;

const EditUserForm = ({ user }) => {
	const genderOptions = ['MALE', 'FEMALE'];
	const stateOptions = ['ACTIVE', 'SUSPENDED'];

	const [name, setName] = useState(user.fullName);
	const [email, setEmail] = useState(user.email);
	const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
	const [birthDate, setBirthDate] = useState(moment(user.birthDate).format('DD/MM/YYYY'));
	const [state, setState] = useState(user.state);
	const [gender, setGender] = useState(user.gender);
	const [isVerified, setIsVerified] = useState(user.isVerified);
	const [emailVerified, setEmailVerified] = useState(user.emailVerified);
	const [phoneNumberVerified, setPhoneNumberVerified] = useState(user.phoneNumberVerified);
	const [addresses, setAddresses] = useState(user.addresses);
	const [disableButton, setDisableButton] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [phoneNumberError, setPhoneNumberError] = useState(false);
	const [birthDateError, setBirthDateError] = useState(false);

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [updateUserByAdmin, { data, error }] = useMutation(EDIT_USER);

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	// block navigation
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [shouldLeavePage, setShouldLeavePage] = useState(false);

	useEffect(() => {
		if (shouldLeavePage) {
			window.onbeforeunload = undefined;
		} else {
			if (shouldBlockNavigation) {
				window.onbeforeunload = () => true;
			} else {
				window.onbeforeunload = undefined;
			}
		}
	}, [shouldBlockNavigation]);

	useEffect(() => {
		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	useEffect(() => {
		if (
			name === user.fullName &&
			email === user.email &&
			phoneNumber == user.phoneNumber &&
			isVerified === user.isVerified &&
			phoneNumberVerified === user.phoneNumberVerified &&
			emailVerified === user.emailVerified &&
			state === user.state &&
			((!user.gender && !gender) || (user.gender && gender === user.gender)) &&
			moment(birthDate).format('YYYY-DD-MM HH:mm') === moment(user.birthDate).format('YYYY-DD-MM HH:mm')
		) {
			setShouldBlockNavigation(false);
		} else {
			setShouldBlockNavigation(true);
		}
	});

	const onCancel = () => {
		window.onbeforeunload = undefined;
		setShouldBlockNavigation(false);
		setShouldLeavePage(true);
		window.location.href = endPoint + '/superAdmin/customers';
	};

	const onSubmit = () => {
		if (name && email && phoneNumber) {
			setDisableButton(true);
			window.onbeforeunload = undefined;
			setShouldBlockNavigation(false);
			setShouldLeavePage(true);
			updateUserByAdmin({
				variables: {
					userId: user._id,
					fullName: name,
					phoneNumber,
					isVerified,
					emailVerified,
					phoneNumberVerified,
					email,
					state,
					gender,
				},
			})
				.then((res) => {
					window.location.href = endPoint + '/superAdmin/customers';
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDiv}>
			<Prompt when={shouldBlockNavigation} message="You have unsaved changes, are you sure you want to leave?" />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={6}>
					<div className={style.leftSide}>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Full name:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={name}
										onChange={(e) => setName(e.target.value)}
										error={nameError}
										labelText={nameError ? "full name can't be empty*" : 'full name'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Email:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										error={emailError}
										labelText={emailError ? "email can't be empty*" : 'email'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Phone number:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={phoneNumber}
										onChange={(e) => setPhoneNumber(e.target.value)}
										error={phoneNumberError}
										labelText={phoneNumberError ? "phone number can't be empty*" : 'phone number'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.dateRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.dateLabel}>Birth date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									{birthDateError && (
										<div className={style.dateError}>Birth Date Can't Be Empty *</div>
									)}
									<Datetime
										timeFormat={false}
										disableOnClickOutside
										value={birthDate}
										closeOnSelect
										onChange={(date) => setBirthDate(date)}
										inputProps={{
											placeholder: 'set birth date',
											readOnly: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.checkboxRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.checkboxLabel}>Verified:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6}>
											<FormControlLabel
												control={
													<Checkbox
														color="gray"
														name="isVerified"
														checked={isVerified}
														onChange={(e) => setIsVerified((isVerified) => !isVerified)}
													/>
												}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.checkboxRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.checkboxLabel}>Email verified:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6}>
											<FormControlLabel
												control={
													<Checkbox
														color="gray"
														name="emailVerified"
														checked={emailVerified}
														onChange={(e) =>
															setEmailVerified((emailVerified) => !emailVerified)
														}
													/>
												}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.checkboxRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.checkboxLabel}>Phone verified:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6}>
											<FormControlLabel
												control={
													<Checkbox
														color="gray"
														name="phoneNumberVerified"
														checked={phoneNumberVerified}
														onChange={(e) =>
															setPhoneNumberVerified(
																(phoneNumberVerified) => !phoneNumberVerified
															)
														}
													/>
												}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</div>
					</div>
				</GridItem>
				<GridItem xs={12} sm={12} md={12} lg={6}>
					<div className={style.rightSide}>
						<div className={style.row}>
							{addresses.map((address, index) => (
								<div>
									<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
											<div className={style.label}>Address {index + 1}:</div>
										</GridItem>
										<GridItem xs={12} sm={12} md={12}>
											<div className={style.row}>
												<GridContainer>
													<GridItem xs={12} sm={6} md={5}>
														<div className={style.label}>Full:</div>
													</GridItem>
													<GridItem xs={12} sm={6} md={7}>
														<CustomInput
															value={address.full}
															//   onChange={(e) => setPhoneNumber(e.target.value)}
															labelText="full address"
															id="float"
															formControlProps={{
																fullWidth: true,
															}}
														/>
													</GridItem>
												</GridContainer>
											</div>
										</GridItem>
									</GridContainer>
								</div>
							))}
						</div>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.label}>State:</div>
								</GridItem>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={state}
											onClick={(e) => setState(e)}
											dropdownList={stateOptions}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.label}>Gender:</div>
								</GridItem>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={gender}
											onClick={(e) => setGender(e)}
											dropdownList={genderOptions}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</div>
				</GridItem>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div className={style.buttonContainer}>
						{user && (
							<Button color="whiteButton" onClick={onCancel}>
								Cancel
							</Button>
						)}
						<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
							Submit
						</Button>
					</div>
				</GridItem>
			</GridContainer>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default EditUserForm;
