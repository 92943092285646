import React, { useState, useEffect } from 'react';
import { graphqlUrl, endPoint } from 'utils/config';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import XLSX from 'xlsx';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Datetime from 'react-datetime';
import MoonLoader from 'react-spinners/MoonLoader';
import Files from 'react-butterfiles';
import FormControl from '@material-ui/core/FormControl';
import BarLoader from 'react-spinners/BarLoader';
import Modal from 'commonComponents/modal/Modal';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactTable from 'react-table';
import Button from 'components/CustomButtons/Button.js';
import Pagination from 'commonComponents/pagination/Pagination';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';
import ProductDetails from './ProductDetails';

const ADD_PRODUCTS_VIA_EXCEL = gql`
	mutation addProductViaExcel($zipFile: Upload!) {
		addProductViaExcel(input: { zipFile: $zipFile }) {
			message
		}
	}
`;

const GET_PRODUCTS = gql`
	query getProductsByShopAdmin(
		$limit: Int
		$skip: Int
		$_id: ID
		$title: String
		$price: Float
		$stock: Int
		$stockSort: Int
		$priceSort: Int
		$createdAt: Date
		$createdAtSort: Int
		$shopMenu: String
		$priceFrom: Float
		$stockFrom: Int
		$createdAtFrom: Date
	) {
		getProductsByShopAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				title: $title
				price: $price
				stock: $stock
				createdAt: $createdAt
				shopMenu: $shopMenu
				priceFrom: $priceFrom
				stockFrom: $stockFrom
				createdAtFrom: $createdAtFrom
			}
			sort: { price: $priceSort, createdAt: $createdAtSort, stock: $stockSort }
		) {
			_id
			title
			shopMenuName
			stock
			createdAt
			description
			price
			photoUrl
			attributes {
				attributeGroup {
					_id
					name
				}
				att {
					_id
					name
				}
			}
			promotion {
				percent
				discountFrom
				discountTo
			}
		}
	}
`;

const GET_PRODUCTS_COUNT = gql`
	query getProductsByShopAdminCount(
		$title: String
		$price: Float
		$stock: Int
		$createdAt: Date
		$shopMenu: String
		$priceFrom: Float
		$stockFrom: Int
		$createdAtFrom: Date
	) {
		getProductsByShopAdminCount(
			filters: {
				title: $title
				price: $price
				createdAt: $createdAt
				stock: $stock
				shopMenu: $shopMenu
				priceFrom: $priceFrom
				stockFrom: $stockFrom
				createdAtFrom: $createdAtFrom
			}
		)
	}
`;

const GET_ATTRIBUTES = gql`
	query getAttributes($limit: Int, $skip: Int) {
		getAttributes(pagination: { limit: $limit, skip: $skip }) {
			_id
			name
		}
	}
`;

const DELETE_PRODUCT_BY_SHOP_ADMIN = gql`
	mutation removeProductByShopAdmin($idSet: [ID!]!) {
		removeProductByShopAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const GET_SHOP_BY_SHOP_ADMIN = gql`
	query getShopByShopAdmin {
		getShopByShopAdmin {
			_id
			rootCategory {
				_id
				title
			}
		}
	}
`;

const Products = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
		buttonColor: 'orange',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
		buttonColor: 'green',
	};
	const grayTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'yellow',
	};

	let theme = orangeTheme;
	if (props.theme === 'Purchase') {
		theme = greenTheme;
	} else if (props.theme === 'Restaurant') {
		theme = orangeTheme;
	} else if (props.theme === 'superAdmin') {
		theme = grayTheme;
	}

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: perPage * pageCount,
		_id: undefined,
		title: undefined,
		price: undefined,
		stock: undefined,
		stockSort: undefined,
		priceSort: undefined,
		createdAt: undefined,
		shopMenu: undefined,
		createdAtSort: -1,
		priceFrom: undefined,
		stockFrom: undefined,
		createdAtFrom: undefined,
	});

	const { loading: attributesLoading, error: attributesError, data: attributesData } = useQuery(GET_ATTRIBUTES, {
		variables: {
			limit: 50,
			skip: 0,
		},
	});

	const [
		addProductViaExcel,
		{ data: addProductViaExcelData, error: addProductViaExcelError, loading: addViaExcelLoading },
	] = useMutation(ADD_PRODUCTS_VIA_EXCEL);

	const [categoryText, setCategoryText] = useState('Submenu');

	const [disableMultiButton, setDisableMultiButton] = useState(false);
	const [idFilter, setIdFilter] = useState('');
	const [nameFilter, setNameFilter] = useState('');
	const [shopMenuFilter, setShopMenuFilter] = useState('');
	const [priceFilter, setPriceFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');
	const [dateSort, setDateSort] = useState('down');
	const [stockFilter, setStockFilter] = useState('');
	const [stockSort, setStockSort] = useState('');
	const [priceSort, setPriceSort] = useState('');
	const [modal, setModal] = useState(false);
	const [productDetail, setProductDetail] = useState();
	const [dateFilterError, setDateFilterError] = useState('');

	const [priceFromFilter, setPriceFromFilter] = useState('');
	const [dateFromFilter, setDateFromFilter] = useState('');
	const [stockFromFilter, setStockFromFilter] = useState('');
	const [dateFromFilterError, setDateFromFilterError] = useState('');

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [addErrorModal, setaddErrorModal] = useState(false);
	const [addErrorText, setaddErrorText] = useState('');
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [deleteModal, setDeleteModal] = useState(false);
	const [productForDelete, setProductForDelete] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const { data: ShopData, loading: ShopLoading, error: ShopError } = useQuery(GET_SHOP_BY_SHOP_ADMIN);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const [excelData, setExcelData] = useState([['title', categoryText.toLowerCase(), 'price', 'stock', 'date']]);

	useEffect(() => {
		if (ShopData && ShopData.getShopByShopAdmin.rootCategory) {
			if (ShopData.getShopByShopAdmin.rootCategory.title === 'Restaurant') {
				setCategoryText('Submenu');
				setExcelData([['title', 'submenu', 'price', 'stock', 'date']]);
			} else {
				setCategoryText('Category');
				setExcelData([['title', 'category', 'price', 'stock', 'date']]);
			}
		}
	}, [, ShopData, ShopLoading]);

	const [
		getProductsByShopAdmin,
		{ data: shopLazyData, loading: shopLazyLoading, error: shopLazyError },
	] = useLazyQuery(GET_PRODUCTS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getProductsByShopAdminCount > 0 &&
			excelData.length === countData.getProductsByShopAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (shopLazyData) {
			setExcelData([
				...excelData,
				...Array.from(shopLazyData.getProductsByShopAdmin, (item) => [
					item.title,
					item.shopMenuName,
					item.price.toFixed(2),
					item.stock,
					moment(item.createdAt).format('MM/DD/YYYY HH:mm'),
				]),
			]);
		}
	}, [, shopLazyData, shopLazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Products.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getProductsByShopAdmin({
				variables: { ...variables, skip: 0, limit: countData.getProductsByShopAdminCount },
			});
		}
	};

	const linkPage = (route) => {
		window.location.href = endPoint + `/shopAdmin/${route}`;
	};

	const [showMultipleAdd, setShowMultipleAdd] = useState(false);
	const [zipFile, setZipFile] = useState('');
	const [zipFileError, setZipFileError] = useState(false);

	const arrayForTemplateExcel = [
		[
			'Title',
			'Price',
			'Stock',
			'Description',
			'Discount',
			'Discount percentage',
			'Discount start?',
			'Discount end?',
			'Parent',
			'Delivery vehicle',
		],
		['valid example1', 25, 2, 'test description', '', '', '', '', '', 'cars'],
		[
			'valid example2',
			25,
			2,
			'test description',
			'yes',
			10,
			'2021-11-25T11:00:00.000Z',
			'2021-11-27T11:00:00.000Z',
			'test1 category',
			'cars',
		],
		['valid example3', 24, 2, 'test description', '-', '-', '-', '-', '', 'truck_trailer'],
		['valid example4', 25, 2, 'test description', 'no', '-', '-', '-', '', 'truck_trailer'],
	];

	const onDownloadTemplate = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(arrayForTemplateExcel);
		XLSX.utils.book_append_sheet(wb, ws, 'Template');
		XLSX.writeFile(wb, 'Spark-Products-Template.xlsx');
	};

	const uploadZipFile = (file) => {
		console.log('upload file');
		setZipFile(file.src.file);
	};

	const onAddMultiple = () => {
		setDisableMultiButton(true);
		addProductViaExcel({
			variables: { zipFile: zipFile },
		})
			.then((res) => {
				console.log(res);
				window.location.href = endPoint + `/shopAdmin/${props.theme === 'Restaurant' ? 'foods' : 'products'}`;
			})
			.catch((err) => {
				setDisableMultiButton(false);
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors[0].message) {
					setaddErrorText(err.graphQLErrors[0].message);
					setaddErrorModal(true);
				}
			});
	};

	const onSelectProductDetail = (product) => {
		setModal(true);
		setProductDetail(product);
	};

	const onDeleteProduct = (product) => {
		setDeleteModal(true);
		setProductForDelete([product._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const [removeProductByShopAdmin] = useMutation(DELETE_PRODUCT_BY_SHOP_ADMIN);

	const deleteProduct = () => {
		removeProductByShopAdmin({ variables: { idSet: productForDelete } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		removeProductByShopAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const onPriceSortChange = () => {
		if (!priceSort) {
			setPriceSort('up');
		} else {
			if (priceSort === 'up') {
				setPriceSort('down');
			} else {
				setPriceSort('');
			}
		}
	};

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('');
			}
		}
	};

	const onStockSortChange = () => {
		if (!stockSort) {
			setStockSort('up');
		} else {
			if (stockSort === 'up') {
				setStockSort('down');
			} else {
				setStockSort('');
			}
		}
	};

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFromFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}
	}, [dateSort]);

	const { data, loading, error, refetch } = useQuery(GET_PRODUCTS, {
		variables,
		fetchPolicy: 'network-only',
	});

	const { data: countData, error: countError, loading: countLoading } = useQuery(GET_PRODUCTS_COUNT, {
		variables,
		fetchPolicy: 'network-only',
	});

	if (countError) {
		console.log(countError);
	}

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getProductsByShopAdminCount / perPage) - 1 &&
			countData.getProductsByShopAdminCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getProductsByShopAdminCount !== 0) {
			setPageCount(Math.ceil(countData.getProductsByShopAdminCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getProductsByShopAdmin.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	useEffect(() => {
		if (priceSort) {
			if (priceSort === 'up') {
				setVariables((variables) => ({ ...variables, priceSort: 0 }));
			} else if (priceSort === 'down') {
				setVariables((variables) => ({ ...variables, priceSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, priceSort: undefined }));
		}
	}, [priceSort]);

	useEffect(() => {
		if (stockSort) {
			if (stockSort === 'up') {
				setVariables((variables) => ({ ...variables, stockSort: 1 }));
			} else if (stockSort === 'down') {
				setVariables((variables) => ({ ...variables, stockSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, stockSort: undefined }));
		}
	}, [stockSort]);

	const onFilterSubmit = () => {
		let hasFilter =
			nameFilter ||
			variables.title ||
			stockFilter ||
			variables.stock ||
			dateFilter ||
			variables.createdAt ||
			priceFilter ||
			variables.price ||
			priceFromFilter ||
			variables.priceFrom ||
			dateFromFilter ||
			variables.createdAtFrom ||
			stockFromFilter ||
			variables.stockFrom ||
			shopMenuFilter ||
			variables.shopMenu;

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');

			if (nameFilter) {
				setVariables((variables) => ({ ...variables, title: nameFilter.trim() }));
			} else {
				setVariables((variables) => ({ ...variables, title: undefined }));
			}

			if (shopMenuFilter) {
				setVariables((variables) => ({ ...variables, shopMenu: shopMenuFilter.trim() }));
			} else {
				setVariables((variables) => ({ ...variables, shopMenu: undefined }));
			}

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
				console.log(moment(dateFilter).format('YYYY-MM-DD'));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (stockFromFilter) {
				setVariables((variables) => ({
					...variables,
					stockFrom: parseFloat(stockFromFilter.trim()),
				}));
			} else {
				setVariables((variables) => ({ ...variables, stockFrom: undefined }));
			}

			if (stockFilter) {
				setVariables((variables) => ({
					...variables,
					stock: parseFloat(stockFilter.trim()),
				}));
			} else {
				setVariables((variables) => ({ ...variables, stock: undefined }));
			}

			if (priceFromFilter) {
				setVariables((variables) => ({
					...variables,
					priceFrom: parseFloat(priceFromFilter.trim()),
				}));
			} else {
				setVariables((variables) => ({ ...variables, priceFrom: undefined }));
			}

			if (priceFilter) {
				setVariables((variables) => ({
					...variables,
					price: parseFloat(priceFilter.trim()),
				}));
			} else {
				setVariables((variables) => ({ ...variables, price: undefined }));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: perPage * pageCount,
			_id: undefined,
			title: undefined,
			price: undefined,
			stock: undefined,
			stockSort: undefined,
			priceSort: undefined,
			createdAt: undefined,
			shopMenu: undefined,
			createdAtSort: -1,
			priceFrom: undefined,
			stockFrom: undefined,
			createdAtFrom: undefined,
		});
		setNameFilter('');
		setPriceFilter('');
		setPriceSort('');
		setDateFilter('');
		setStockFilter('');
		setDateSort('down');
		setStockSort('');
		setPriceFromFilter('');
		setStockFromFilter('');
		setDateFromFilter('');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onFilterSubmit();
		}
	};

	// calculate total price
	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (data) {
			let number = 0;
			data.getProductsByShopAdmin.map((item) => {
				number = number + item.price;
			});
			setTotal(number);
		}
	}, [data, loading]);

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			<div>
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<div className={style.linkButtonDiv}>
							<Button
								color={theme.primaryColor}
								fullWidth
								onClick={() => linkPage(props.theme === 'Restaurant' ? 'addFood' : 'addProduct')}
							>
								Add {props.theme === 'Restaurant' ? 'food' : 'product'}
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<div className={style.upperButtonsContainerDiv}>
				{showExcelButton === 'done' && (
					<Button color="whiteButton" onClick={() => onExcelFinished()}>
						<i class="fas fa-cloud-download-alt"></i> download excel
					</Button>
				)}

				{showExcelButton === 'firstStatus' && (
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				)}
				{showExcelButton === 'downloading' && (
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
							display: inline-block;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				)}

				{idsForDelete.length > 0 && (
					<Button title="delete selected items" color="redButton" onClick={onDeleteSome}>
						Delete
					</Button>
				)}
				{!showMultipleAdd && (
					<div className={style.toggleDiv}>
						<button onClick={() => setShowMultipleAdd(true)}>
							<KeyboardArrowDownIcon />
							<div className={style.buttonSentence}>
								add all {props.theme === 'Restaurant' ? 'foods' : 'products'} with zip file
							</div>
						</button>
					</div>
				)}
				{showMultipleAdd && (
					<div className={style.toggleDiv}>
						<button onClick={() => setShowMultipleAdd(false)}>
							<KeyboardArrowUpIcon />
							<div className={style.buttonSentence}>hide</div>
						</button>
					</div>
				)}
			</div>
			{showMultipleAdd && (
				<div className={style.multipleAddDiv}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<p>
								upload a zip file including an excel file like this template and photos named like this
								: [product's row number]-[photo number].
							</p>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<div className={style.multipleButtonDiv}>
								<Button fullWidth color="whiteButton" onClick={() => onDownloadTemplate()}>
									<i class="fas fa-cloud-download-alt"></i> download template
								</Button>
							</div>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<Files
								maxSize="10mb"
								multiple={false}
								convertToBase64={true}
								accept={['application/x-zip-compressed']}
								onSuccess={(file) => uploadZipFile(file[0])}
								onError={(errors) => {
									setZipFileError(true);
									console.log(errors);
								}}
							>
								{({ browseFiles, getDropZoneProps, getLabelProps }) => (
									<div style={{ textAlign: 'center' }}>
										{zipFileError && (
											<p
												style={{
													color: 'red',
													fontsize: '9px',
												}}
											>
												please upload a zip file
											</p>
										)}

										<div {...getDropZoneProps({ className: 'myDropZone' })} />
										{zipFile && (
											<Button
												onClick={() => setZipFile('')}
												size="sm"
												justIcon
												round
												color="danger"
											>
												<CloseIcon />
											</Button>
										)}

										<Button fullWidth onClick={browseFiles} color="whiteButton">
											<i class="fas fa-cloud-upload-alt"></i>{' '}
											{`${zipFile ? 'edit' : 'upload'} zip file`}
										</Button>
									</div>
								)}
							</Files>
						</GridItem>

						<GridItem xs={12} sm={6} md={3}>
							<div className={style.multipleButtonDiv}>
								<Button
									fullWidth
									disabled={!zipFile || disableMultiButton}
									color="whiteButton"
									onClick={() => onAddMultiple()}
								>
									{addViaExcelLoading && (
										<MoonLoader
											css={css`
												margin: 0 5px;
											`}
											size={15}
											margin={2}
											color={'gray'}
											loading={true}
										/>
									)}
									Submit
								</Button>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onFilterSubmit} color={theme.primaryColor}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getProductsByShopAdminCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getProductsByShopAdmin.length)}</span>{' '}
							of <span>{countData.getProductsByShopAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0);
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={[
					...data.getProductsByShopAdmin.map((product) => ({
						checkbox: (
							<div className={style.checkboxDivHeader}>
								<Checkbox
									color="default"
									checked={idsForDelete.includes(product._id)}
									onChange={() => {
										if (idsForDelete.includes(product._id)) {
											setIdsForDelete((idsForDelete) =>
												idsForDelete.filter((item) => item !== product._id)
											);
										} else {
											setIdsForDelete([...idsForDelete, product._id]);
										}
									}}
								/>
							</div>
						),
						photo: (
							<img
								style={{ width: '90px', height: '90px' }}
								alt="product-photo"
								src={
									product.photoUrl && product.photoUrl.length > 0
										? `${graphqlUrl}${product.photoUrl[0]}`
										: ''
								}
							/>
						),
						name: product.title,
						shopMenu: product.shopMenuName,
						category: '',
						price: <div>{'$' + product.price}</div>,
						stock: <div className={style.textAlignLeft}>{product.stock}</div>,
						date: moment(product.createdAt).format('MM/DD/YYYY HH:mm'),

						actions: (
							<div>
								<button onClick={() => onSelectProductDetail(product)} className={style.detailIconDiv}>
									<span>i</span>
								</button>
								<IconButton
									id={product._id}
									aria-controls="simple-menu"
									aria-haspopup="true"
									onClick={(e) => {
										handleClick(e, product._id);
									}}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									open={menuId === product._id}
									onClose={handleClose}
								>
									<MenuItem
										onClick={() => {
											if (props.theme === 'Restaurant') {
												window.location.href = endPoint + `/shopAdmin/editFood/${product._id}`;
											} else {
												window.location.href =
													endPoint + `/shopAdmin/editProduct/${product._id}`;
											}
										}}
									>
										edit
									</MenuItem>
									<MenuItem onClick={() => onDeleteProduct(product)}>delete</MenuItem>
								</Menu>
							</div>
						),
					})),
					data.getProductsByShopAdmin.length > 0 && {
						checkbox: <div className={style.totalNumber}>Total:</div>,
						price: <div className={style.totalNumber}> {'$' + total.toFixed(2)}</div>,
					},
				]}
				columns={[
					{
						Header: (
							<div>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getProductsByShopAdmin.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getProductsByShopAdmin, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: <div className={style.titleDiv}>Photo</div>,
						accessor: 'photo',
						sortable: false,
						filterable: false,
					},

					{
						Header: (
							<div>
								<div className={style.titleDiv}>Title</div>
								<CustomInput
									value={nameFilter}
									onChange={(e) => setNameFilter(e.target.value)}
									id="regular"
									green={props.type === 'grocery'}
									warning={props.type === 'restaurant'}
									inputProps={{
										placeholder: 'title ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>{categoryText}</div>
								<CustomInput
									value={shopMenuFilter}
									onChange={(e) => setShopMenuFilter(e.target.value)}
									id="regular"
									green={props.type === 'grocery'}
									warning={props.type === 'restaurant'}
									inputProps={{
										placeholder: `${categoryText.toLowerCase()} ...`,
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'shopMenu',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onPriceSortChange}>
									Price{' '}
									<span>
										{priceSort && (
											<span>
												{priceSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={priceFromFilter}
									onChange={(e) => setPriceFromFilter(e.target.value)}
									id="regular"
									green={props.type === 'grocery'}
									warning={props.type === 'restaurant'}
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={priceFilter}
									onChange={(e) => setPriceFilter(e.target.value)}
									id="regular"
									green={props.type === 'grocery'}
									warning={props.type === 'restaurant'}
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'price',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onStockSortChange}>
									Stock{' '}
									<span>
										{stockSort && (
											<span>
												{stockSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={stockFromFilter}
									onChange={(e) => setStockFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
										type: 'number',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={stockFilter}
									onChange={(e) => setStockFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
										type: 'number',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'stock',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Date{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
						minWidth: 60,
					},
				]}
				defaultPageSize={perPage + 1}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getProductsByShopAdminCount === 0
							? 1
							: Math.ceil(countData.getProductsByShopAdminCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<ProductDetails
						type={props.theme}
						theme={props.theme}
						close={() => setModal(false)}
						product={productDetail}
					/>
				</DialogContent>
			</Dialog>
			{productForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteProduct}
							text="Do you want to delete this item?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these items?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={addErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setaddErrorModal(false)} type="notif" text={addErrorText} />
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Products;
