import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReactTable from 'react-table';
import Dialog from '@material-ui/core/Dialog';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import EditItem from 'superAdminViews/setting/defaults/EditItem';
import Modal from 'commonComponents/modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Button from 'components/CustomButtons/Button.js';
import style from './CancelTripReason.module.scss';

const ADD_PARCEL_VOLUME = gql`
	mutation createParcelVolumeByAdmin(
		$name: String!
		$value: Float!
		$order: Int
		$typeOfAttribute: ParcelVolumeTypeOfAttribute!
	) {
		createParcelVolumeByAdmin(
			input: { name: $name, value: $value, order: $order, typeOfAttribute: $typeOfAttribute }
		) {
			_id
		}
	}
`;

const ADD_PARCEL_WEIGHT = gql`
	mutation createParcelWeightByAdmin(
		$name: String!
		$value: Float!
		$order: Int
		$typeOfAttribute: ParcelWeightTypeOfAttribute!
	) {
		createParcelWeightByAdmin(
			input: { name: $name, value: $value, order: $order, typeOfAttribute: $typeOfAttribute }
		) {
			_id
		}
	}
`;

const DELETE_PARCEL_VOLUME = gql`
	mutation removeParcelVolumeByAdmin($idSet: [ID!]!) {
		removeParcelVolumeByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const DELETE_PARCEL_WEIGHT = gql`
	mutation removeParcelWeightByAdmin($idSet: [ID!]!) {
		removeParcelWeightByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const ParcelWeightAndVolume = ({ type, parcels, refetch, perPage, pageCount }) => {
	const parcelTypeOptions = ['PERCENTAGE', 'NUMBER'];

	const [name, setName] = useState('');
	const [value, setValue] = useState('');
	const [order, setOrder] = useState('');
	const [parcelType, setParcelType] = useState(parcelTypeOptions[0]);

	const [nameError, setNameError] = useState(false);
	const [valueError, setValueError] = useState(false);
	const [orderError, setOrderError] = useState(false);

	const [addModal, setAddModal] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const [modal, setModal] = useState(false);
	const [parcelDetail, setParcelDetail] = useState();

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [itemForDelete, setItemForDelete] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	useEffect(() => {
		if (parcels) {
			setCheckAll(true);
			parcels.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [parcels, idsForDelete, pageCount, perPage]);

	// testing menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onDeleteItem = (item) => {
		setDeleteModal(true);
		setItemForDelete([item._id]);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const deleteItem = () => {
		if (type === 'parcelVolume') {
			removeParcelVolumeByAdmin({ variables: { idSet: itemForDelete } })
				.then((res) => {
					setDeleteModal(false);
					refetch();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setDeleteModal(false);
						setDeleteErrorText(err.graphQLErrors[0].message);
						setDeleteErrorModal(true);
					}
				});
		} else {
			removeParcelWeightByAdmin({ variables: { idSet: itemForDelete } })
				.then((res) => {
					setDeleteModal(false);
					refetch();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setDeleteModal(false);
						setDeleteErrorText(err.graphQLErrors[0].message);
						setDeleteErrorModal(true);
					}
				});
		}
	};

	const deleteSome = () => {
		if (type === 'parcelVolume') {
			removeParcelVolumeByAdmin({ variables: { idSet: idsForDelete } })
				.then((res) => {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					refetch();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setDeleteSomeModal(false);
						setIdsForDelete([]);
						setDeleteErrorText(err.graphQLErrors[0].message);
						setDeleteErrorModal(true);
					}
				});
		} else {
			removeParcelWeightByAdmin({ variables: { idSet: idsForDelete } })
				.then((res) => {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					refetch();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setDeleteSomeModal(false);
						setIdsForDelete([]);
						setDeleteErrorText(err.graphQLErrors[0].message);
						setDeleteErrorModal(true);
					}
				});
		}
	};

	const [removeParcelVolumeByAdmin, { data: deleteParcelVolumeData, error: deleteParcelVolumeError }] = useMutation(
		DELETE_PARCEL_VOLUME
	);

	const [removeParcelWeightByAdmin, { data: deleteParcelWeightData, error: deleteParcelWeightError }] = useMutation(
		DELETE_PARCEL_WEIGHT
	);

	const onSelectParcelEdit = (parcel) => {
		setModal(true);
		setParcelDetail(parcel);
	};

	const [createParcelVolumeByAdmin, { data: addParcelVolumeData, error: addParcelVolumeError }] = useMutation(
		ADD_PARCEL_VOLUME
	);

	const [createParcelWeightByAdmin, { data: addParcelWeightData, error: addParcelWeightError }] = useMutation(
		ADD_PARCEL_WEIGHT
	);

	useEffect(() => {
		if (addParcelVolumeData || addParcelWeightData) {
			setAddModal(true);
		}
	}, [addParcelVolumeData, addParcelWeightData]);

	const onSubmit = () => {
		if (!name) {
			setNameError(true);
		} else {
			setNameError(false);
		}
		if (!value) {
			setValueError("value can't be empty*");
		} else {
			if (value.match(/^(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*)))$/)) {
				setValueError('');
			} else {
				setValueError('enter valid number');
			}
		}
		if (!order) {
			setOrderError("order can't be empty*");
		} else {
			if (order.match(/^(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*)))$/)) {
				setOrderError('');
			} else {
				setOrderError('enter valid number');
			}
		}

		if (
			name &&
			value &&
			value.match(/^(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*)))$/) &&
			order &&
			order.match(/^(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*)))$/)
		) {
			setDisableButton(true);
			if (type === 'parcelVolume') {
				createParcelVolumeByAdmin({
					variables: {
						name: name.trim(),
						value: Number(value),
						order: order && Number(order),
						typeOfAttribute: parcelType,
					},
				})
					.then((res) => {
						setDisableButton(false);
						refetch();
						setName('');
						setValue('');
						setOrder('');
					})
					.catch((err) => {
						console.log(err);
						setDisableButton(false);
					});
			}
			if (type === 'parcelWeight') {
				createParcelWeightByAdmin({
					variables: {
						name: name.trim(),
						value: Number(value),
						order: order && Number(order),
						typeOfAttribute: parcelType,
					},
				})
					.then((res) => {
						setDisableButton(false);
						refetch();
						setName('');
						setValue('');
						setOrder('');
					})
					.catch((err) => {
						console.log(err);
						setDisableButton(false);
					});
			}
		}
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.inputRow}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={name}
										onChange={(e) => setName(e.target.value)}
										error={nameError}
										labelText={nameError ? "name can't be empty*" : 'name'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Value:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={value}
										onChange={(e) => setValue(e.target.value)}
										error={valueError}
										labelText={valueError ? valueError : 'value'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={6} md={6} lg={6}>
						<div>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Order:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={order}
										onChange={(e) => setOrder(e.target.value)}
										id="float"
										error={orderError}
										labelText={
											orderError
												? orderError
												: `order to sort ${type === 'parcelWeight' ? 'weight' : 'volume'}`
										}
										inputProps={{
											type: 'number',
										}}
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
							<div style={{ paddingTop: '23px' }}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4}>
										<div className={style.inputLabel}>Type:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8}>
										<div className={style.dropdownContainer}>
											<CustomDropdown
												hoverColor="primary"
												buttonText={parcelType}
												onClick={(e) => setParcelType(e)}
												dropdownList={parcelTypeOptions}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.inputButtonContainer}>
							<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
								Add
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			{idsForDelete.length > 0 && (
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<Button title="delete selected items" fullWidth color="redButton" onClick={onDeleteSome}>
							Delete
						</Button>
					</GridItem>
				</GridContainer>
			)}

			<ReactTable
				data={parcels
					.sort((a, b) => a.order - b.order)
					.map((comment) => ({
						checkbox: (
							<div className={style.checkboxDivHeader}>
								<Checkbox
									color="default"
									checked={idsForDelete.includes(comment._id)}
									onChange={() => {
										if (idsForDelete.includes(comment._id)) {
											setIdsForDelete((idsForDelete) =>
												idsForDelete.filter((item) => item !== comment._id)
											);
										} else {
											setIdsForDelete([...idsForDelete, comment._id]);
										}
									}}
								/>
							</div>
						),
						name: comment.name,
						value: comment.value,
						order: comment.order,
						type: comment.typeOfAttribute.toLowerCase(),
						actions: (
							<div>
								<IconButton
									id={comment._id}
									aria-controls="simple-menu"
									aria-haspopup="true"
									onClick={(e) => {
										handleClick(e, comment._id);
									}}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									open={menuId === comment._id}
									onClose={handleClose}
								>
									<MenuItem onClick={() => onSelectParcelEdit(comment)}>edit</MenuItem>
									<MenuItem onClick={() => onDeleteItem(comment)}>delete</MenuItem>
								</Menu>
							</div>
						),
					}))}
				columns={[
					{
						Header: (
							<div className={style.fitTitleDivCheckbox}>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											parcels.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(parcels, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: <div className={style.fitTitleDiv}>Name</div>,
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Value</div>,
						accessor: 'value',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Order</div>,
						accessor: 'order',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Type</div>,
						accessor: 'type',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>

			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<EditItem
						refetch={() => refetch()}
						close={() => setModal(false)}
						type={type}
						comment={parcelDetail}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text="Added succesfully." />
				</DialogContent>
			</Dialog>
			{itemForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteItem}
							text="Do you want to delete this item?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteSomeModal(false);
							setIdsForDelete([]);
						}}
						func={deleteSome}
						text="Do you want to delete these items?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ParcelWeightAndVolume;
