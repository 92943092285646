import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import Dialog from '@material-ui/core/Dialog';
import EditConstant from 'superAdminViews/setting/constants/EditConstant';
import Pagination from 'commonComponents/pagination/Pagination';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import style from 'superAdminViews/setting/defaults/CancelTripReason.module.scss';

const GET_CONSTANTS = gql`
	query getConstants($limit: Int, $skip: Int) {
		getConstants(pagination: { limit: $limit, skip: $skip }) {
			_id
			value
			attribute
			description
			typeOfAttribute
		}
	}
`;

const GET_CONSTANTS_COUNT = gql`
	query getConstantsCount($_id: ID, $attribute: String, $value: String, $typeOfAttribute: String) {
		getConstantsCount(
			filters: { _id: $_id, attribute: $attribute, value: $value, typeOfAttribute: $typeOfAttribute }
		)
	}
`;

const Constants = (props) => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);
	const [modal, setModal] = useState(false);
	const [constantDetail, setConstantDetail] = useState();

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
	});

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectConstantDetail = (constant) => {
		setModal(true);
		setConstantDetail(constant);
	};

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getConstantsCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getConstantsCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	const { loading, error, data, refetch } = useQuery(GET_CONSTANTS, {
		variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_CONSTANTS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}
	return (
		<div className={style.mainDiv}>
			<ReactTable
				data={data.getConstants.map((comment) => ({
					cost: comment.value,
					option: comment.attribute,
					type: comment.typeOfAttribute,
					description: comment.description,
					actions: (
						<div>
							<button
								onClick={() => onSelectConstantDetail(comment)}
								className={`${style.inlineButton} ${style.inlineEditButton}`}
							>
								edit
							</button>
						</div>
					),
				}))}
				columns={[
					{
						Header: <div className={style.fitTitleDiv}>Cost</div>,
						accessor: 'cost',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Option</div>,
						accessor: 'option',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Type of the number</div>,
						accessor: 'type',
						sortable: false,
						filterable: false,
						minWidth: 150,
					},
					{
						Header: <div className={style.fitTitleDiv}>Description</div>,
						accessor: 'description',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>

			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={Math.ceil(countData.getConstantsCount / perPage)}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<EditConstant refetch={() => refetch()} close={() => setModal(false)} constant={constantDetail} />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Constants;
