import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Modal from 'commonComponents/modal/Modal';
import Slide from '@material-ui/core/Slide';
import style from 'superAdminViews/setting/defaults/EditItem.module.scss';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.js';

const REJECT_SHOP = gql`
	mutation rejectShopByAdmin($id: ID!, $rejectionMessage: String!) {
		rejectShopByAdmin(id: $id, rejectionMessage: $rejectionMessage) {
			_id
		}
	}
`;

const REJECT_VERIFICATION = gql`
	mutation rejectDriverVerificationRequestByAdmin($driverId: ID!, $message: String) {
		rejectDriverVerificationRequestByAdmin(driverId: $driverId, message: $message) {
			_id
			fullName
		}
	}
`;

const RejectForm = ({ close, id, refetch, refetchCount, type }) => {
	const [messageError, setMessageError] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [message, setMessage] = useState('');

	const [rejectDriverVerificationRequestByAdmin, { data, error }] = useMutation(REJECT_VERIFICATION);
	const [rejectShopByAdmin] = useMutation(REJECT_SHOP);

	const onReject = () => {
		if (!message) {
			setMessageError(true);
		} else {
			if (type === 'driver') {
				rejectDriverVerificationRequestByAdmin({ variables: { driverId: id, message } })
					.then((res) => {
						refetch();
						refetchCount();
						close();
					})
					.catch((err) => {
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			} else {
				rejectShopByAdmin({ variables: { id: id, rejectionMessage: message } })
					.then((res) => {
						refetch();
						refetchCount();
						close();
					})
					.catch((err) => {
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}
		}
	};

	return (
		<div style={{ padding: '15px' }} className={style.mainDiv}>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div className={style.row}>
						<GridContainer>
							<GridItem xs={12} sm={3} md={3} lg={3}>
								<div className={style.label}>Reject message: </div>
							</GridItem>
							<GridItem xs={12} sm={9} md={9} lg={9}>
								<div className={style.textAreaContainer}>
									{messageError && (
										<div style={{ color: 'red', fontSize: '12px', fontWeight: '400' }}>
											message can't be empty*
										</div>
									)}
									<textarea
										value={message}
										onChange={(e) => setMessage(e.target.value)}
										placeholder="write reject message here ..."
										className={style.textArea}
									/>
								</div>
							</GridItem>
						</GridContainer>
					</div>
				</GridItem>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div className={style.buttonsContainer}>
						<Button onClick={() => close()}>Cancel</Button>
						<Button color="redButton" onClick={onReject}>
							Reject
						</Button>
					</div>
				</GridItem>
			</GridContainer>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setErrorModal(false);
						}}
						type="notif"
						text={errorText ? errorText : 'Error ! Please Fill All Inputs Correctly And Try Again.'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default RejectForm;
