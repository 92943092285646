import React, { useState, useEffect, useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClearIcon from '@material-ui/icons/Clear';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import XLSX from 'xlsx';
import Modal from 'commonComponents/modal/Modal';
import BarLoader from 'react-spinners/BarLoader';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactTable from 'react-table';
import PaymentDetails from 'superAdminViews/finance/pay/PaymentDetails';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';
import TransactionDetails from 'superAdminViews/finance/transactions/TransactionDetails';
import { ThemeContext } from 'index';

const GET_PAYMENTS = gql`
	query getShopPayments(
		$limit: Int
		$skip: Int
		$_id: ID
		$from: Date
		$to: Date
		$transactionId: String
		$status: PaymentStatus
		$amount: Float
		$createdAt: Date
		$createdAtSort: Int
		$amountFrom: Float
		$createdAtFrom: Date
	) {
		getShopPayments(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				transactionId: $transactionId
				status: $status
				amountFrom: $amountFrom
				amount: $amount
				createdAtFrom: $createdAtFrom
				from: $from
				createdAt: $createdAt
				to: $to
			}
			sort: { createdAt: $createdAtSort }
		) {
			_id

			transactionId {
				_id
				transactionId
				status
				paidAt
				type
				amount
				reversed
				payments {
					_id
					trip {
						_id
					}
					order {
						_id
					}
					status
					for
					description
					createdAt
					amount
					type
				}
				user {
					_id
				}
				driver {
					_id
				}
				shop {
					_id
					name
				}
				paymentIntent
				createdAt

				updatedAt
			}
			trip {
				_id
			}
			order {
				_id
			}
			status
			for
			description
			createdAt
			amount
			type
		}
	}
`;

const GET_PAYMENTS_COUNT = gql`
	query getShopPaymentsCount(
		$_id: ID
		$from: Date
		$to: Date
		$transactionId: String
		$status: PaymentStatus
		$createdAt: Date
		$amount: Float
		$amountFrom: Float
		$createdAtFrom: Date
	) {
		getShopPaymentsCount(
			filters: {
				_id: $_id
				transactionId: $transactionId
				status: $status
				createdAt: $createdAt
				amountFrom: $amountFrom
				amount: $amount
				createdAtFrom: $createdAtFrom
				from: $from
				to: $to
			}
		)
	}
`;

const Payments = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		_id: undefined,
		amount: undefined,
		status: undefined,
		createdAt: undefined,
		type: undefined,
		createdAtSort: -1,
		amountSort: undefined,
		transactionId: undefined,
	});

	const themeContext = useContext(ThemeContext);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
		buttonColor: 'orange',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
		buttonColor: 'green',
	};
	const theme = themeContext === 'Purchase' ? greenTheme : orangeTheme;

	const statusOptions = ['ALL', 'PAID', 'UNPAID', 'FAILED'];
	const typeOptions = ['ALL', 'PAY_TO_SPARK', 'PAY_TO_SHOP', 'PAY_TO_DRIVER'];

	const [amountFromFilter, setAmountFromFilter] = useState('');
	const [dateFromFilter, setDateFromFilter] = useState('');
	const [dateFromFilterError, setDateFromFilterError] = useState('');

	const [idFilter, setIdFilter] = useState(null);
	const [transactionIdFilter, setTransactionIdFilter] = useState('');
	const [amountFilter, setAmountFilter] = useState('');
	const [statusFilter, setStatusFilter] = useState(props.failed ? 'FAILED' : statusOptions[0]);
	const [dateFilter, setDateFilter] = useState('');

	const [idFilterError, setIdFilterError] = useState(false);
	const [transactionIdFilterError, setTransactionIdFilterError] = useState(false);

	const [dateSort, setDateSort] = useState('down');
	const [amountSort, setAmountSort] = useState(null);
	const [dateFilterError, setDateFilterError] = useState('');
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [paymentDetails, setPaymentDetails] = useState();
	const [modal, setModal] = useState(false);
	const [transactionDetailId, setTransactionDetailId] = useState();
	const [transactionModal, setTransactionModal] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectPaymentDetails = (payment) => {
		setPaymentDetails(payment);
		setModal(true);
	};

	const onSelectTransactionDetails = (transactionId) => {
		setTransactionDetailId(transactionId);
		setTransactionModal(true);
	};

	const [excelData, setExcelData] = useState([[ 'transaction ID', 'amount', 'created at', 'status']]);

	const [getShopPayments, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_PAYMENTS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getShopPaymentsCount > 0 &&
			excelData.length === countData.getShopPaymentsCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getShopPayments, (item) => [
					item.transactionId && item.transactionId.transactionId ? item.transactionId.transactionId : '-',
					item.amount.toFixed(2),
					moment(item.createdAt).format('MM-DD-YYYY'),
					item.status.replace(/_/g, ' ').toLowerCase(),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Payments.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getShopPayments({ variables: { ...variables, skip: 0, limit: countData.getShopPaymentsCount } });
		}
	};

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('');
			}
		}
	};

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}
	}, [dateSort]);

	const onAmountSortChange = () => {
		if (!amountSort) {
			setAmountSort('up');
		} else {
			if (amountSort === 'up') {
				setAmountSort('down');
			} else {
				setAmountSort('');
			}
		}
	};

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getShopPaymentsCount / perPage) - 1 &&
			countData.getShopPaymentsCount !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getShopPaymentsCount !== 0) {
			setPageCount(Math.ceil(countData.getShopPaymentsCount / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (idFilter && idFilter.length !== 24) {
			setIdFilterError(true);
		} else {
			setIdFilterError(false);
		}
	}, [idFilter]);

	useEffect(() => {
		if (transactionIdFilter && transactionIdFilter.length !== 24) {
			setTransactionIdFilterError(true);
		} else {
			setTransactionIdFilterError(false);
		}
	}, [transactionIdFilter]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onSubmitFilter = () => {
		let hasFilter =
			idFilter ||
			variables._id ||
			transactionIdFilter ||
			variables.transactionId ||
			dateFilter ||
			variables.createdAt ||
			amountFilter ||
			variables.amount ||
			dateFromFilter ||
			variables.createdAtFrom ||
			amountFromFilter ||
			variables.amountFrom ||
			!(statusFilter === 'ALL' && !variables.status);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');
			if (idFilter && idFilter.length === 24) {
				setVariables((variables) => ({
					...variables,
					_id: idFilter.trim(),
				}));
			} else if (!idFilter) {
				setVariables((variables) => ({ ...variables, _id: undefined }));
			}
			if (transactionIdFilter && transactionIdFilter.length === 24) {
				setVariables((variables) => ({
					...variables,
					transactionId: transactionIdFilter.trim(),
				}));
			} else if (!transactionIdFilter) {
				setVariables((variables) => ({ ...variables, transactionId: undefined }));
			}

			if (amountFromFilter) {
				setVariables((variables) => ({
					...variables,
					amountFrom: parseFloat(amountFromFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, amountFrom: undefined }));
			}

			if (amountFilter) {
				setVariables((variables) => ({
					...variables,
					amount: parseFloat(amountFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, amount: undefined }));
			}

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
				console.log(moment(dateFilter).format('YYYY-MM-DD'));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (statusFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					status: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					status: statusFilter.toUpperCase(),
				}));
			}

			if (dateSort) {
				if (dateSort === 'up') {
					setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
				} else if (dateSort === 'down') {
					setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
				}
			} else {
				setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
			}

			if (amountSort) {
				if (amountSort === 'up') {
					setVariables((variables) => ({ ...variables, amountSort: 1 }));
				} else if (amountSort === 'down') {
					setVariables((variables) => ({ ...variables, amountSort: -1 }));
				}
			} else {
				setVariables((variables) => ({ ...variables, amountSort: undefined }));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			_id: undefined,
			amount: undefined,
			status: undefined,
			createdAt: undefined,
			type: undefined,
			createdAtSort: -1,
			amountSort: undefined,
			transactionId: undefined,
			createdAtFrom: undefined,
			amountFrom: undefined,
		});
		setIdFilter('');
		setDateFilter('');
		setAmountFilter('');
		setStatusFilter('ALL');
		setTransactionIdFilter('');
		setAmountSort('');
		setDateSort('down');
		setAmountFromFilter('');
		setDateFromFilter('');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	const { loading, error, data, refetch } = useQuery(GET_PAYMENTS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_PAYMENTS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	// calculate total price
	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (data) {
			let number = 0;
			data.getShopPayments.map((item) => {
				number = number + item.amount;
			});
			setTotal(number);
		}
	}, [data, loading]);

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onSubmitFilter} color={theme.primaryColor}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getShopPaymentsCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getShopPayments.length)}</span> of{' '}
							<span>{countData.getShopPaymentsCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0)
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>
			<ReactTable
				data={[
					...data.getShopPayments.map((payment) => ({
						id: (
							<CopyToClipboard text={payment._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{payment._id}
								</span>
							</CopyToClipboard>
						),
						transactionId: (
							<CopyToClipboard
								text={
									payment.transactionId &&
									payment.transactionId.transactionId &&
									payment.transactionId.transactionId
								}
							>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{payment.transactionId &&
										payment.transactionId.transactionId &&
										payment.transactionId.transactionId}
								</span>
							</CopyToClipboard>
						),
						user: payment.user && (
							<CopyToClipboard text={payment.user._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{payment.user._id}
								</span>
							</CopyToClipboard>
						),
						driver: payment.driver && (
							<CopyToClipboard text={payment.driver._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{payment.driver._id}
								</span>
							</CopyToClipboard>
						),
						shop: payment.shop && (
							<CopyToClipboard text={payment.shop._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{payment.shop._id}
								</span>
							</CopyToClipboard>
						),

						status: payment.status && (
							<div
								className={`${style.inlineTag} ${
									style[payment.status.replace(/_/g, '').toLowerCase() + 'Tag']
								}`}
							>
								{payment.status.toLowerCase()}
							</div>
						),
						amount: payment.amount && <div>{'$' + payment.amount.toFixed(2)}</div>,
						date: moment(payment.createdAt).format('MM/DD/YYYY HH:mm'),
						actions: (
							<div className="actions-right">
								<IconButton
									id={payment._id}
									aria-controls="simple-menu"
									aria-haspopup="true"
									onClick={(e) => {
										handleClick(e, payment._id);
									}}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									open={menuId === payment._id}
									onClose={handleClose}
								>
									{payment.transactionId && (
										<MenuItem onClick={() => onSelectTransactionDetails(payment.transactionId)}>
											transaction details
										</MenuItem>
									)}
									<MenuItem onClick={() => onSelectPaymentDetails(payment)}>payment details</MenuItem>
								</Menu>
							</div>
						),
					})),
					data.getShopPayments.length > 0 && {
						transactionId: <div className={style.totalNumber}>Total:</div>,
						amount: <div className={style.totalNumber}> {'$' + total.toFixed(2)}</div>,
					},
				]}
				columns={[
					// {
					// 	Header: (
					// 		<div>
					// 			<div className={style.titleDiv}>ID</div>
					// 			{idFilterError && <div className={style.dateErrorDiv}>must be 24 character</div>}
					// 			<CustomInput
					// 				value={idFilter}
					// 				onChange={(e) => setIdFilter(e.target.value)}
					// 				id="regular"
					// 				error={idFilterError && idFilter}
					// 				inputProps={{
					// 					placeholder: 'id',
					// 				}}
					// 				formControlProps={{
					// 					fullWidth: true,
					// 				}}
					// 			/>
					// 		</div>
					// 	),
					// 	accessor: 'id',
					// 	sortable: false,
					// 	filterable: false,
					// 	width: 220,
					// },
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Transaction ID</div>
								{transactionIdFilterError && (
									<div className={style.dateErrorDiv}>must be 24 character</div>
								)}
								<CustomInput
									value={transactionIdFilter}
									onChange={(e) => setTransactionIdFilter(e.target.value)}
									id="regular"
									error={transactionIdFilterError && transactionIdFilter}
									inputProps={{
										placeholder: 'transaction ID',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'transactionId',
						sortable: false,
						filterable: false,
						width: 220,
					},

					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onAmountSortChange}>
									Amount{' '}
									<span>
										{amountSort && (
											<span>
												{amountSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={amountFromFilter}
									onChange={(e) => setAmountFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={amountFilter}
									onChange={(e) => setAmountFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'amount',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Created at{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										viewDate={moment(dateFromFilter).format('MM/DD/YYYY')}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={statusFilter}
										onClick={(e) => setStatusFilter(e)}
										dropdownList={statusOptions}
									/>
								</div>
							</div>
						),
						accessor: 'status',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
						minWidth: 60,
					},
				]}
				defaultPageSize={perPage + 1}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getShopPaymentsCount === 0 ? 1 : Math.ceil(countData.getShopPaymentsCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<PaymentDetails
						close={() => setModal(false)}
						refetch={() => refetch()}
						payment={paymentDetails}
						hasResolver={false}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={transactionModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<TransactionDetails close={() => setTransactionModal(false)} transaction={transactionDetailId} />
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Payments;
