import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { graphqlUrl, endPoint } from 'utils/config';
import Files from 'react-butterfiles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Modal from 'commonComponents/modal/Modal';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Datetime from 'react-datetime';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import style from 'superAdminViews/users/EditUserForm.module.scss';

const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!, $folderName: String!) {
		uploadFile(data: { file: $file, folderName: $folderName }) {
			url
		}
	}
`;

const EDIT_DRIVER = gql`
	mutation updateDriverInfoByAdmin(
		$driverId: ID!
		$gender: DriverGender
		$address: DriverAddressInput
		$birthDate: Date
		$profileImageUrl: String
		$fullName: String
		$emailVerified: Boolean
		$phoneNumberVerified: Boolean
		$hasNotification: Boolean
		$email: String
		$phoneNumber: String
	) {
		updateDriverInfoByAdmin(
			driverId: $driverId
			input: {
				gender: $gender
				address: $address
				emailVerified: $emailVerified
				hasNotification: $hasNotification
				phoneNumberVerified: $phoneNumberVerified
				birthDate: $birthDate
				profileImageUrl: $profileImageUrl
				fullName: $fullName
				email: $email
				phoneNumber: $phoneNumber
			}
		) {
			_id
			fullName
		}
	}
`;

const EditDriverForm = ({ driver }) => {
	const genderOptions = ['MALE', 'FEMALE'];

	const [name, setName] = useState(driver.fullName);
	const [emailVerified, setEmailVerified] = useState(driver.emailVerified);
	const [hasNotification, setHasNotification] = useState(driver.hasNotification);
	const [phoneNumberVerified, setPhoneNumberVerified] = useState(driver.phoneNumberVerified);
	const [email, setEmail] = useState(driver.email);
	const [phoneNumber, setPhoneNumber] = useState(driver.phoneNumber);
	const [birthDate, setBirthDate] = useState(moment(driver.birthDate));
	const [gender, setGender] = useState(driver.gender);
	const [address, setAddress] = useState(driver.address);
	const [profileImage, setProfileImage] = useState(driver.profileImageUrl);

	const [nameError, setNameError] = useState(false);
	const [fullAddressErrorText, setFullAddressErrorText] = useState(false);
	const [zipCodeErrorText, setZipCodeErrorText] = useState(false);
	const [profileImageError, setProfileImageError] = useState(false);
	const [emailErrorText, setEmailErrorText] = useState('');
	const [phoneNumberErrorText, setPhoneNumberErrorText] = useState('');
	const [birthDateError, setBirthDateError] = useState(false);

	const emailRegex = /[\w-]+@([\w-]+\.)+[\w-]+/;

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const [modal, setModal] = useState(false);
	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [updateDriverInfoByAdmin, { data, error }] = useMutation(EDIT_DRIVER);

	const [uploadFile, { data: uploadData, error: uploadError, loading: uploadLoading }] = useMutation(UPLOAD_FILE);

	const uploadProfileImage = (file) => {
		uploadFile({
			variables: { file: file.src.file, folderName: 'driverProfileImage' },
		}).then((res) => {
			console.log(res.data.uploadFile.url);
			setProfileImage(res.data.uploadFile.url);
		});
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	// block navigation
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [shouldLeavePage, setShouldLeavePage] = useState(false);

	useEffect(() => {
		if (shouldLeavePage) {
			window.onbeforeunload = undefined;
		} else {
			if (shouldBlockNavigation) {
				window.onbeforeunload = () => true;
			} else {
				window.onbeforeunload = undefined;
			}
		}
	}, [shouldBlockNavigation]);

	useEffect(() => {
		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	useEffect(() => {
		if (
			name === driver.fullName &&
			phoneNumber == driver.phoneNumber &&
			email === driver.email &&
			profileImage === driver.profileImageUrl &&
			moment(birthDate).format('YYYY-DD-MM HH:mm') === moment(driver.birthDate).format('YYYY-DD-MM HH:mm') &&
			address.full === driver.address.full &&
			address.zipCode == driver.address.zipCode &&
			((!driver.gender && !gender) || (driver.gender && gender === driver.gender))
		) {
			setShouldBlockNavigation(false);
		} else {
			setShouldBlockNavigation(true);
		}
	});

	const onCancel = () => {
		window.onbeforeunload = undefined;
		setShouldBlockNavigation(false);
		setShouldLeavePage(true);
		window.location.href = endPoint + '/superAdmin/drivers';
	};

	const onSubmit = () => {
		if (!birthDate) {
			setBirthDateError("birth date can't be empty*");
		} else {
			if (moment().subtract(20, 'years') < moment(birthDate)) {
				setBirthDateError('driver should be at least 20 years old*');
			} else {
				setBirthDateError(false);
			}
		}
		if (!name) {
			setNameError(true);
		} else {
			setNameError(false);
		}
		if (!address.full) {
			setFullAddressErrorText("full address can't be empty");
		} else {
			setFullAddressErrorText('');
		}
		if (!address.zipCode) {
			setZipCodeErrorText("zip code can't be empty");
		} else {
			setZipCodeErrorText('');
		}
		if (!profileImage) {
			setProfileImageError(true);
		} else {
			setProfileImageError(false);
		}
		if (!email) {
			setEmailErrorText("email can't be empty");
		} else {
			if (!email.match(emailRegex)) {
				setEmailErrorText('please enter a valid email');
			} else {
				setEmailErrorText('');
			}
		}
		if (!phoneNumber) {
			setPhoneNumberErrorText("phone number can't be empty");
		} else {
			if (isNaN(phoneNumber)) {
				setPhoneNumberErrorText('enter a valid phone number');
			} else {
				setPhoneNumberErrorText('');
			}
		}

		if (
			name &&
			email &&
			phoneNumber &&
			profileImage &&
			address.full &&
			address.zipCode &&
			!isNaN(phoneNumber) &&
			email.match(emailRegex) &&
			moment().subtract(20, 'years') >= moment(birthDate)
		) {
			window.onbeforeunload = undefined;
			setShouldBlockNavigation(false);
			setShouldLeavePage(true);
			updateDriverInfoByAdmin({
				variables: {
					driverId: driver._id,
					fullName: name.trim(),
					birthDate: moment(birthDate).utc(),
					address: {
						full: address.full,
						zipCode: address.zipCode,
					},
					emailVerified,
					phoneNumberVerified,
					hasNotification,
					gender,
					profileImageUrl: profileImage,
					email: email.trim(),
					phoneNumber: phoneNumber.trim(),
				},
			})
				.then((res) => {
					console.log(res);
					window.location.href = `${endPoint}/superAdmin/drivers`;
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	return (
		<div onKeyPress={(e) => handleEnter(e)} className={style.mainDiv}>
			<Prompt when={shouldBlockNavigation} message="You have unsaved changes, are you sure you want to leave?" />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={6}>
					<div className={style.leftSide}>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Full name:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={name}
										onChange={(e) => setName(e.target.value)}
										error={nameError}
										labelText={nameError ? "full name can't be empty*" : 'full name'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Email:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										error={emailErrorText}
										labelText={emailErrorText ? emailErrorText : 'email'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.row}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Phone number:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={phoneNumber}
										onChange={(e) => setPhoneNumber(e.target.value)}
										error={phoneNumberErrorText}
										labelText={phoneNumberErrorText ? phoneNumberErrorText : 'phone number'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.dateRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.dateLabel}>Birth date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									{birthDateError && <div className={style.dateError}>{birthDateError}</div>}
									<Datetime
										timeFormat={false}
										disableOnClickOutside
										value={birthDate}
										closeOnSelect
										onChange={(date) => setBirthDate(date)}
										inputProps={{
											placeholder: 'set birth date',
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.checkboxRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.checkboxLabel}>Email verified:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6}>
											<FormControlLabel
												control={
													<Checkbox
														color="gray"
														name="emailVerified"
														checked={emailVerified}
														onChange={(e) =>
															setEmailVerified((emailVerified) => !emailVerified)
														}
													/>
												}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.checkboxRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.checkboxLabel}>Phone verified:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6}>
											<FormControlLabel
												control={
													<Checkbox
														color="gray"
														name="phoneNumberVerified"
														checked={phoneNumberVerified}
														onChange={(e) =>
															setPhoneNumberVerified(
																(phoneNumberVerified) => !phoneNumberVerified
															)
														}
													/>
												}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.checkboxRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.checkboxLabel}>Has notification:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6}>
											<FormControlLabel
												control={
													<Checkbox
														color="gray"
														name="hasNotification"
														checked={hasNotification}
														onChange={(e) =>
															setHasNotification((hasNotification) => !hasNotification)
														}
													/>
												}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</div>
					</div>
				</GridItem>
				<GridItem xs={12} sm={12} md={12} lg={6}>
					<div className={style.rightSide}>
						<div className={style.row}>
							<div>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										<div className={style.row}>
											<GridContainer>
												<GridItem xs={12} sm={6} md={5}>
													<div className={style.label}>Address (full):</div>
												</GridItem>
												<GridItem xs={12} sm={6} md={7}>
													<CustomInput
														value={address.full}
														onChange={(e) =>
															setAddress({ ...address, full: e.target.value })
														}
														error={fullAddressErrorText}
														labelText={
															fullAddressErrorText ? fullAddressErrorText : 'full address'
														}
														id="float"
														formControlProps={{
															fullWidth: true,
														}}
													/>
												</GridItem>
											</GridContainer>
										</div>
									</GridItem>
									<GridItem xs={12} sm={12} md={12}>
										<div className={style.row}>
											<GridContainer>
												<GridItem xs={12} sm={6} md={5}>
													<div className={style.label}>Address (zip code):</div>
												</GridItem>
												<GridItem xs={12} sm={6} md={7}>
													<CustomInput
														value={address.zipCode}
														onChange={(e) =>
															setAddress({
																...address,
																zipCode: e.target.value,
															})
														}
														error={zipCodeErrorText}
														labelText={zipCodeErrorText ? zipCodeErrorText : 'zip code'}
														id="float"
														formControlProps={{
															fullWidth: true,
														}}
													/>
												</GridItem>
											</GridContainer>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</div>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.label}>Gender:</div>
								</GridItem>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={gender}
											onClick={(e) => setGender(e)}
											dropdownList={genderOptions}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.imageRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.imageLabel}>Profile photo:</div>
									<div className={style.imageHint}>(upload an image less than 10MB)</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<div className={style.imageContainer}>
										<Files
											maxSize="10mb"
											multiple={false}
											convertToBase64={true}
											accept={['image/jpg', 'image/jpeg', 'image/png']}
											onSuccess={(file) => uploadProfileImage(file[0])}
											onError={(errors) => setProfileImageError(true)}
										>
											{({ browseFiles, getDropZoneProps, getLabelProps }) => (
												<div style={{ textAlign: 'right' }}>
													{profileImage && (
														<div>
															<img
																style={{ width: '200px', height: '200px' }}
																alt="advertisement photo"
																src={`${graphqlUrl}${profileImage}`}
															/>
														</div>
													)}
													<div {...getDropZoneProps({ className: 'myDropZone' })} />
													<Button
														color="whiteButton"
														style={{ marginTop: '15px', marginRight: '10px' }}
														onClick={browseFiles}
													>
														Select Photo...
													</Button>
													{profileImageError && (
														<p
															style={{
																color: 'red',
																fontsize: '9px',
															}}
														>
															please enter image file less than 10MB
														</p>
													)}
												</div>
											)}
										</Files>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</div>
				</GridItem>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<div className={style.buttonContainer}>
						{driver && (
							<Button color="whiteButton" onClick={onCancel}>
								Cancel
							</Button>
						)}
						<Button disabled={uploadLoading} color="yellow" onClick={onSubmit}>
							Submit
						</Button>
					</div>
				</GridItem>
			</GridContainer>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error ! Please Fill All Inputs Correctly And Try Again .'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default EditDriverForm;
