import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { endPoint } from 'utils/config';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { css } from '@emotion/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BeatLoader from 'react-spinners/BeatLoader';
import Slide from '@material-ui/core/Slide';
import Modal from 'commonComponents/modal/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import style from './AddShopMenu.module.scss';

const GET_PRODUCTS = gql`
	query getProductsByShopAdmin {
		getProductsByShopAdmin {
			_id
			title
		}
	}
`;

const UPDATE_SHOP_BY_SHOP_ADMIN = gql`
	mutation updateShopByShopAdmin($active: Boolean, $location: ShopLocationInput!) {
		updateShopByShopAdmin(data: { active: $active, location: $location }) {
			_id
		}
	}
`;

const ADD_SHOP_MENU_BY_SHOP_ADMIN = gql`
	mutation createShopMenuByShopAdmin($subMenus: [SubMenuInput]) {
		createShopMenuByShopAdmin(inputs: { subMenus: $subMenus }) {
			_id
			subMenus {
				name
			}
		}
	}
`;

const UPDATE_SHOP_MENU_BY_SHOP_ADMIN = gql`
	mutation updateShopMenuByShopAdmin($_id: ID!, $subMenus: [SubMenuInput]) {
		updateShopMenuByShopAdmin(_id: $_id, inputs: { subMenus: $subMenus }) {
			_id
			subMenus {
				name
			}
		}
	}
`;

const GET_SHOP_BY_SHOP_ADMIN = gql`
	query getShopByShopAdmin {
		getShopByShopAdmin {
			_id
			state
			active
			acceptCash
			name
			address
			budget
			phoneNumbers
			origin
			shopMenu {
				_id
				subMenus {
					_id
					name
					products {
						_id
						title
					}
				}
			}
			location {
				type
				coordinates
			}
			workingHoursInMinutes {
				type
				from
				to
			}
			notWorkingDays {
				type
			}
			description
			bannerUrl
			logoUrl
			rootCategory {
				_id
				title
			}
			categories {
				_id
				title
			}
			attributes {
				_id
				name
			}
		}
	}
`;

const AddShopMenu = (props) => {
	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const grayTheme = {
		checkboxColor: 'gray',
		primaryColor: 'yellow',
	};

	let theme = orangeTheme;
	if (props.theme === 'Purchase') {
		theme = greenTheme;
	} else if (props.theme === 'Restaurant') {
		theme = orangeTheme;
	} else if (props.theme === 'superAdmin') {
		theme = grayTheme;
	}

	const [shopName, setShopName] = useState('restaurant');
	const [categoryText, setCategoryText] = useState('Menu item');
	const [mainText, setMainText] = useState('Menu');

	const [subMenus, setSubMenus] = useState([]);

	const [subMenuNameError, setSubMenuNameError] = useState(false);

	const [productNumber, setProductNumber] = useState([
		{ subMenuName: '', productIds: [], error: false, showAllProducts: false, isNew: true },
	]);

	const [showAllProducts, setShowAllProducts] = useState(false);

	const [addModal, setAddModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [noShopMenuModal, setNoShopMenuModal] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const { data: productsData, error: productsError, loading: productsLoading } = useQuery(GET_PRODUCTS);

	const [createShopMenuByShopAdmin, { data: addShopData, error: addShopError }] = useMutation(
		ADD_SHOP_MENU_BY_SHOP_ADMIN
	);

	if (ShopError) {
		console.log(ShopError);
	}

	const [updateShopByShopAdmin, { data: updateShopByShopAdminData, error: updateShopByShopAdminError }] = useMutation(
		UPDATE_SHOP_BY_SHOP_ADMIN
	);

	const [updateShopMenuByShopAdmin, { data: updateShopData, error: updateShopError }] = useMutation(
		UPDATE_SHOP_MENU_BY_SHOP_ADMIN
	);

	if (updateShopData) {
		console.log(updateShopData);
	}
	if (updateShopError) {
		console.log(updateShopError);
	}

	const { data: ShopData, loading: ShopLoading, error: ShopError, refetch } = useQuery(GET_SHOP_BY_SHOP_ADMIN);

	useEffect(() => {
		if (ShopData && ShopData.getShopByShopAdmin.rootCategory) {
			if (ShopData.getShopByShopAdmin.rootCategory.title === 'Restaurant') {
				setShopName('restaurant')
				setCategoryText('Menu item');
				setMainText('Menu');
			} else {
				setShopName('shop')
				setCategoryText('Category');
				setMainText('Categories');
			}
		}
	}, [, ShopData, ShopLoading]);

	if (ShopData) {
		console.log(ShopData);
	}

	useEffect(() => {
		if (ShopData && ShopData.getShopByShopAdmin.shopMenu !== null) {
			let array = [];
			ShopData.getShopByShopAdmin.shopMenu.subMenus.map((subMenu) =>
				array.push({
					id: subMenu._id,
					subMenuName: subMenu.name,
					productIds: Array.from(subMenu.products, (product) => product._id),
				})
			);
			setProductNumber(array);
			console.log(array);
		} else if (ShopData && ShopData.getShopByShopAdmin.shopMenu === null) {
			setNoShopMenuModal(true);
		}
	}, [, ShopData]);

	const onSubMenuNameChange = (e, i) => {
		console.log(e.target.value, i);

		setProductNumber(
			productNumber.map((sub, index) => (index === i ? { ...sub, subMenuName: e.target.value } : sub))
		);
	};

	const onSubMenuProductIdsChange = (e, i) => {
		setProductNumber(productNumber.map((sub, index) => (index === i ? { ...sub, productIds: e } : sub)));
	};

	const onUpdateShop = () => {
		updateShopByShopAdmin({
			variables: {
				location: ShopData.getShopByShopAdmin.location,
				active: true,
			},
		})
			.then((res) => {
				refetch();
				setAddModal(true);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const onAddProductClick = (SubMenuId) => {
		window.location.href = `${endPoint}/shopAdmin/add${
			props.theme === 'Restaurant' ? 'Food' : 'Prodcut'
		}InShopMenu/${SubMenuId}`;
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmit();
		}
	};

	const onSubmit = () => {
		if (productNumber.some((item) => !item.subMenuName)) {
			setErrorModal(true);
			setErrorText(`All ${mainText.toLowerCase()} should have a name.`);
		}
		if (!productNumber.some((item) => !item.subMenuName)) {
			if (ShopData && ShopData.getShopByShopAdmin.shopMenu) {
				updateShopMenuByShopAdmin({
					variables: {
						_id: ShopData.getShopByShopAdmin.shopMenu._id,
						subMenus: Array.from(productNumber, (subMenu) => ({
							name: subMenu.subMenuName,
							products: subMenu.productIds,
						})),
					},
				})
					.then((res) => {
						refetch();
						setAddModal(true);
					})
					.catch((err) => {
						console.log(err);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors[0].message) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			} else {
				createShopMenuByShopAdmin({
					variables: {
						subMenus: Array.from(productNumber, (subMenu) => ({
							name: subMenu.subMenuName,
							products: subMenu.productIds,
						})),
					},
				})
					.then((res) => {
						refetch();
						setAddModal(true);
					})
					.catch((err) => {
						console.log(err);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors[0].message) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}
		}
	};

	if (ShopLoading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (ShopError) {
		console.log(ShopError);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<div className={style.content}>
				<GridContainer>
					{productNumber.length < 1 && (
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<div style={{ padding: '20px 0', textAlign: 'center' }}>
									Your {shopName} does not have any {categoryText.toLowerCase()} yet, please add your list
									here.
								</div>
							</GridItem>
						</GridContainer>
					)}
					<div className={style.upperSentence}>
						* remember to save changes by pressing the "submit changes" button after each change.
					</div>
					{productNumber.map((sub, i) => (
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<div className={style.subContainer}>
									<GridItem xs={12} sm={12} md={12}>
										<div className={style.subMenuTitle}>
											{categoryText} {i + 1}
										</div>
									</GridItem>
									<GridItem xs={12} sm={12} md={12}>
										<div onKeyPress={(e) => handleEnter(e)} className={style.row}>
											<GridContainer>
												<GridItem xs={12} sm={5} md={5}>
													<div className={style.label}>{categoryText} name :</div>
												</GridItem>
												<GridItem xs={12} sm={7} md={7}>
													<CustomInput
														value={productNumber[i].subMenuName}
														onChange={(e) => onSubMenuNameChange(e, i)}
														green={props.type === 'grocery' && !sub.error}
														warning={props.type === 'restaurant' && !sub.error}
														error={sub.error}
														labelText={sub.error && `${categoryText} name can't be empty`}
														id="float"
														inputProps={{
															placeholder: `${categoryText} name`,
														}}
														formControlProps={{
															fullWidth: true,
														}}
													/>
												</GridItem>
											</GridContainer>
										</div>
									</GridItem>
									{!sub.showAllProducts && (
										<div className={style.toggleDiv}>
											<button
												onClick={() => {
													setProductNumber(
														productNumber.map((subMenu, index) =>
															index === i
																? {
																		...subMenu,
																		showAllProducts: true,
																  }
																: subMenu
														)
													);
												}}
											>
												<KeyboardArrowDownIcon />{' '}
												<div className={style.buttonSentence}>
													select from added{' '}
													{props.theme === 'Restaurant' ? 'foods' : 'products'}
												</div>
											</button>
										</div>
									)}
									{sub.showAllProducts && (
										<div className={style.toggleDiv}>
											<button
												onClick={() => {
													setProductNumber(
														productNumber.map((subMenu, index) =>
															index === i
																? {
																		...subMenu,
																		showAllProducts: false,
																  }
																: subMenu
														)
													);
												}}
											>
												<KeyboardArrowUpIcon />
												<div className={style.buttonSentence}>
													{' '}
													hide {props.theme === 'Restaurant' ? 'foods' : 'products'}
												</div>
											</button>
										</div>
									)}
									{sub.showAllProducts && (
										<GridItem xs={12} sm={12} md={12}>
											<div className={style.tagRow}>
												<GridContainer>
													<GridItem xs={12} sm={5} md={5}>
														<div className={style.tagLabel}>
															{`${
																props.theme === 'Restaurant' ? 'foods' : 'products'
															} selected in this ${categoryText.toLowerCase()}`}{' '}
															:
														</div>
													</GridItem>
													<GridItem xs={12} sm={7} md={7}>
														<GridContainer>
															{productsData &&
																productsData.getProductsByShopAdmin.map((product) => (
																	<GridItem xs={12} sm={6} md={6} lg={4}>
																		<FormControlLabel
																			control={
																				<Checkbox
																					style={{
																						color: theme.checkboxColor,
																					}}
																					name={product.title}
																					checked={productNumber
																						.find(
																							(subMenu, index) =>
																								i === index &&
																								subMenu.subMenuName ===
																									sub.subMenuName
																						)
																						.productIds.includes(
																							product._id
																						)}
																					onChange={() => {
																						if (
																							productNumber
																								.find(
																									(subMenu, index) =>
																										i === index &&
																										subMenu.subMenuName ===
																											sub.subMenuName
																								)
																								.productIds.includes(
																									product._id
																								)
																						) {
																							setProductNumber(
																								productNumber.map(
																									(subMenu, index) =>
																										index === i
																											? {
																													...subMenu,
																													productIds: subMenu.productIds.filter(
																														(
																															item
																														) =>
																															item !==
																															product._id
																													),
																											  }
																											: subMenu
																								)
																							);
																						} else {
																							setProductNumber(
																								productNumber.map(
																									(subMenu, index) =>
																										index === i
																											? {
																													...subMenu,
																													productIds: [
																														...subMenu.productIds,
																														product._id,
																													],
																											  }
																											: subMenu
																								)
																							);
																						}
																					}}
																				/>
																			}
																			label={product.title}
																		/>
																	</GridItem>
																))}
														</GridContainer>
													</GridItem>
												</GridContainer>
											</div>
										</GridItem>
									)}
									<GridItem xs={12} sm={12} md={12}>
										<div className={style.tagRow}>
											<GridContainer>
												<GridItem xs={12} sm={12} md={12}>
													<div className={style.tagLabel}>
														{props.theme === 'Restaurant' ? 'Foods' : 'Products'}:
													</div>
												</GridItem>
												<GridItem xs={12} sm={12} md={12}>
													<GridContainer>
														{productsData &&
															productsData.getProductsByShopAdmin
																.filter((product) =>
																	productNumber
																		.find(
																			(subMenu, index) =>
																				i === index &&
																				subMenu.subMenuName === sub.subMenuName
																		)
																		.productIds.includes(product._id)
																)
																.map((product) => (
																	<GridItem xs={12} sm={6} md={6} lg={4}>
																		<div className={style.productDiv}>
																			<div className={style.productTitle}>
																				{product.title}
																			</div>
																			<div>
																				<Link
																					to={
																						props.type !== 'superAdmin'
																							? `/shopAdmin/${
																									props.theme ===
																									'Restaurant'
																										? 'editFood'
																										: 'editProduct'
																							  }/${product._id}`
																							: `/superAdmin/${props.shopName}/${props.id}/editProduct/${product._id}`
																					}
																				>
																					<button
																						className={`${style.inlineButton} ${style.inlineEditButton}`}
																					>
																						edit
																					</button>
																				</Link>
																				<button
																					onClick={() => {
																						setProductNumber(
																							productNumber.map(
																								(subMenu, index) =>
																									index === i
																										? {
																												...subMenu,
																												productIds: subMenu.productIds.filter(
																													(
																														item
																													) =>
																														item !==
																														product._id
																												),
																										  }
																										: subMenu
																							)
																						);
																					}}
																					className={`${style.inlineButton} ${style.inlineDeleteButton}`}
																				>
																					remove
																				</button>
																			</div>
																		</div>
																	</GridItem>
																))}
													</GridContainer>
												</GridItem>
											</GridContainer>
										</div>
									</GridItem>
									<GridItem xs={12} sm={12} md={12}>
										<div className={style.buttonsContainer}>
											<Button
												color="redButton"
												onClick={() =>
													setProductNumber(
														productNumber.filter(
															(item) =>
																item.subMenuName !== sub.subMenuName &&
																item.productIds !== sub.productIds
														)
													)
												}
											>
												Remove
											</Button>
											{ShopData &&
												ShopData.getShopByShopAdmin.shopMenu !== null &&
												(!sub.isNew && (
													<Button onClick={() => onAddProductClick(sub.id)}>
														Add {props.theme === 'Restaurant' ? 'food' : 'product'}
													</Button>
												))}

											{sub.isNew && (
												<Button disabled={sub.subMenuName ? false : true} onClick={onSubmit}>
													Submit
												</Button>
											)}
										</div>
									</GridItem>
								</div>
							</GridItem>
						</GridContainer>
					))}

					<GridItem xs={12} sm={12} md={12}>
						<div className={style.buttonsContainer}>
							<Button
								onClick={() =>
									setProductNumber((productNumber) => [
										...productNumber,
										{ subMenuName: '', productIds: [], error: false, isNew: true },
									])
								}
							>
								Add More {categoryText}
							</Button>
							<Button onClick={onSubmit} color={theme.primaryColor}>
								Submit Changes
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text={`${mainText} updated successfully.`} />
				</DialogContent>
			</Dialog>
			<Dialog open={noShopMenuModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setNoShopMenuModal(false)}
						type="notif"
						text={`Your ${shopName} does not have any ${categoryText.toLowerCase()} yet, please add your list here.`}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! fill the inputs correctly.'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default AddShopMenu;
