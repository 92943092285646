import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Close from '@material-ui/icons/Close';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Badge from 'components/Badge/Badge.js';
import style from './PromotionDetails.module.scss';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const PromotionDetails = ({ promotion, close, type, theme }) => {
	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Promotion details</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Promotion ID:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{promotion._id}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Promotion code:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{promotion.promotionCode}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Limit use:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{promotion.useLimitCount}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Type:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{promotion.type && promotion.type.toLowerCase()}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Condition:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{promotion.condition && promotion.condition.toLowerCase()}
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Discount percentage:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{promotion.percent && promotion.percent + '%'}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Maximum amount:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{promotion.maximumPromotion && '$' + promotion.maximumPromotion}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Start date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{moment(promotion.from).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Expire date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{moment(promotion.to).format('MM/DD/YYYY HH:mm')}</div>
								</GridItem>
							</GridContainer>
						</div>
						{type === 'trip' && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4} lg={4}>
										<div className={style.label}>For users:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8} lg={8}>
										<div className={style.badgeContainer}>
											{promotion.canUse.map((id) => (
												<Badge color="gray">{id}</Badge>
											))}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{type === 'trip' && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={4} md={4} lg={4}>
										<div className={style.label}>Execptions:</div>
									</GridItem>
									<GridItem xs={12} sm={8} md={8} lg={8}>
										<div className={style.badgeContainer}>
											{promotion.canNotUse.map((id) => (
												<Badge color="gray">{id}</Badge>
											))}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.footer}>
				<Button color="yellow" onClick={close}>
					Close
				</Button>
			</div>
		</div>
	);
};

export default PromotionDetails;
