import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ClearIcon from '@material-ui/icons/Clear';
import XLSX from 'xlsx';
import { endPoint } from 'utils/config';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Modal from 'commonComponents/modal/Modal';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import BarLoader from 'react-spinners/BarLoader';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactTable from 'react-table';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';
import VerificationDetails from './VerificationDetails';
import RejectForm from './RejectForm';

const VERIFY_DRIVER = gql`
	mutation approveDriverVerificationRequestByAdmin($driverId: ID!) {
		approveDriverVerificationRequestByAdmin(driverId: $driverId) {
			_id
			fullName
		}
	}
`;

const GET_DRIVERS_VERIFICATIONS = gql`
	query getDriversVerificationRequestsByAdmin(
		$limit: Int
		$skip: Int
		$status: DriverVerificationRequestsStatus
		$fullName: String
		$_id: ID
		$createdAtFrom: Date
		$createdAt: Date
		$createdAtSort: Int
		$phoneNumber: String 
	) {
		getDriversVerificationRequestsByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				status: $status
				fullName: $fullName
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
				_id: $_id
				phoneNumber: $phoneNumber
			}
			sort: { createdAt: $createdAtSort }
		) {
			_id
			fullName
			email
			phoneNumber
			gender
			birthDate
			state
			verificationRequests {
				status

				submitDate
				verificationDetails {
					profileImageUrl

					gender
					birthDate
					address {
						full
						zipCode
					}
					drivingLicence {
						photoUrl
						licenceId
						expireDate
					}
					drivingRecordPhotoUrl
					canadianVerificationPhotoUrl
					canadianVerificationExpireDate
					backgroundCheckDocumentPhotoUrl
				}
			}
			defaultCar {
				_id
				plate
				carType {
					type
				}
			}
			drivingLicence {
				licenceId
				expireDate
			}
			createdAt
		}
	}
`;

const GET_DRIVERS_VERIFICATIONS_COUNT = gql`
	query getDriversVerificationRequestsCountByAdmin(
		$status: DriverVerificationRequestsStatus
		$fullName: String
		$_id: ID
		$createdAtFrom: Date
		$createdAt: Date
		$phoneNumber: String
	) {
		getDriversVerificationRequestsCountByAdmin(
			filters: {
				status: $status
				fullName: $fullName
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
				_id: $_id
				phoneNumber: $phoneNumber
			}
		)
	}
`;

const Verifications = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const [idFilter, setIdFilter] = useState();
	const [idFilterError, setIdFilterError] = useState(false);
	const [dateFromFilter, setDateFromFilter] = useState();
	const [dateFilter, setDateFilter] = useState();
	const [nameFilter, setNameFilter] = useState();
	const [phoneNumberFilter, setPhoneNumberFilter] = useState();
	const [dateFromFilterError, setDateFromFilterError] = useState('');
	const [dateFilterError, setDateFilterError] = useState('');

	const [dateSort, setDateSort] = useState('down');
	const statusOptions = ['All', 'Approved', 'Rejected', 'Pending'];

	const [statusFilter, setStatusFilter] = useState(statusOptions[0]);

	const [noFilterModal, setNoFilterModal] = useState(false);

	const [approveModal, setApproveModal] = useState(false);
	const [itemForApprove, setItemForApprove] = useState();

	const [modal, setModal] = useState(false);
	const [itemDetail, setItemDetail] = useState();

	const [ignoreModal, setIgnoreModal] = useState(false);
	const [itemForIgnore, setItemForIgnore] = useState();

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [approveDriverVerificationRequestByAdmin, { data: verifyData, error: verifyError }] = useMutation(
		VERIFY_DRIVER
	);

	const onSelectItemDetail = (item) => {
		setModal(true);
		setItemDetail(item);
	};

	const onApproveItem = (item) => {
		setApproveModal(true);
		setItemForApprove(item);
	};

	const onIgnoreItem = (item) => {
		setIgnoreModal(true);
		setItemForIgnore(item);
	};

	const approve = () => {
		approveDriverVerificationRequestByAdmin({ variables: { driverId: itemForApprove._id } })
			.then((res) => {
				setApproveModal(false);
				refetch();
				refetchCount();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setApproveModal(false);
					setErrorText(err.graphQLErrors[0].message);
					setErrorModal(true);
				}
			});
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		status: undefined,
		fullName: undefined,
		phoneNumber: undefined,
		createdAtSort: -1,
		createdAtFrom: undefined,
		createdAt: undefined,
	});

	useEffect(() => {
		if (idFilter && idFilter.length !== 24) {
			setIdFilterError(true);
		} else {
			setIdFilterError(false);
		}
	}, [idFilter]);

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('up');
			}
		}
	};

	// for pagination
	const onNextPage = () => {
		if (
			pageCount !== Math.ceil(countData.getDriversVerificationRequestsCountByAdmin / perPage) - 1 &&
			countData.getDriversVerificationRequestsCountByAdmin !== 0
		) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		if (countData.getDriversVerificationRequestsCountByAdmin !== 0) {
			setPageCount(Math.ceil(countData.getDriversVerificationRequestsCountByAdmin / perPage) - 1);
		}
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFromFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (
				moment(dateFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}
	}, [dateSort]);

	const [excelData, setExcelData] = useState([['name', 'phone', 'date', 'status']]);

	const [
		getDriversVerificationRequestsByAdmin,
		{ data: lazyData, loading: lazyLoading, error: lazyError },
	] = useLazyQuery(GET_DRIVERS_VERIFICATIONS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getDriversVerificationRequestsCountByAdmin > 0 &&
			excelData.length === countData.getDriversVerificationRequestsCountByAdmin + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			setExcelData([
				...excelData,
				...Array.from(lazyData.getDriversVerificationRequestsByAdmin, (item) => [
					item.fullName,
					item.phoneNumber,
					moment(item.createdAt).format('MM-DD-YYYY'),
					item.verificationRequests.length > 0
						? item.verificationRequests[item.verificationRequests.length - 1].status
								.replace(/_/g, ' ')
								.toLowerCase()
						: '-',
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'DriverVerifications.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getDriversVerificationRequestsByAdmin({
				variables: { ...variables, skip: 0, limit: countData.getDriversVerificationRequestsCountByAdmin },
			});
		}
	};

	const linkPage = (route) => {
		window.location.href = endPoint + `/superAdmin/${route}`;
	};

	const onSubmitFilter = () => {
		let hasFilter =
			nameFilter ||
			variables.fullName ||
			phoneNumberFilter ||
			variables.phoneNumber ||
			dateFilter ||
			variables.createdAt ||
			dateFromFilter ||
			variables.createdAtFrom ||
			!(statusFilter === 'All' && !variables.status);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');
			if (statusFilter === 'All') {
				setVariables((variables) => ({
					...variables,
					status: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					status: statusFilter.toUpperCase(),
				}));
			}

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (nameFilter) {
				setVariables((variables) => ({
					...variables,
					fullName: nameFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, fullName: undefined }));
			}

			if (phoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					phoneNumber: phoneNumberFilter.trim(),
				}));
			} else {
				setVariables((variables) => ({ ...variables, phoneNumber: undefined }));
			}
		}
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			status: undefined,
			fullName: undefined,
			phoneNumber: undefined,
			createdAtSort: -1,
			createdAtFrom: undefined,
			createdAt: undefined,
		});
		setStatusFilter('All');
		setDateFilter('');
		setDateFromFilter('');
		setNameFilter('');
		setPhoneNumberFilter('');
		setDateSort('down');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	useEffect(() => {
		if (countData) {
			console.log(countData.getDriversVerificationRequestsCountByAdmin);
		}
	}, [countData, countLoading]);

	const { loading, error, data, refetch } = useQuery(GET_DRIVERS_VERIFICATIONS, {
		variables: variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData, refetch: refetchCount } = useQuery(
		GET_DRIVERS_VERIFICATIONS_COUNT,
		{
			variables: variables,
			fetchPolicy: 'network-only',
			notifyOnNetworkStatusChange: true,
		}
	);

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	if (data) {
		console.log(data);
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			<div>
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<div style={{ padding: '0' }} className={style.linkButtonDiv}>
							<Button color="yellow" fullWidth onClick={() => linkPage('drivers')}>
								Drivers
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
			<div >
				<Button color="yellow" onClick={onSubmitFilter}>
					<SortIcon />
					Search
				</Button>
				<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
					<ClearIcon />
					Default
				</Button>
			</div>
			{data && data.getDriversVerificationRequestsByAdmin && countData && (
				<div className={style.rowConfigDriv}>
					<div className={style.itemsNumberDiv}>
						Displaying items{' '}
						<span>
							{countData.getDriversVerificationRequestsCountByAdmin === 0 ? 0 : pageCount * perPage + 1}
						</span>{' '}
						to{' '}
						<span>
							{pageCount * perPage + Math.min(perPage, data.getDriversVerificationRequestsByAdmin.length)}
						</span>{' '}
						of <span>{countData.getDriversVerificationRequestsCountByAdmin}</span>
					</div>
					<div className={style.rowDropDownDiv}>
						<div className={style.rowLabel}>Row:</div>{' '}
						<CustomDropdown
							hoverColor="primary"
							buttonText={String(perPage)}
							onClick={(e) => {
								setPageCount(0)
								setPerPage(parseInt(e));
							}}
							dropdownList={perPageOptions}
						/>
					</div>
				</div>
			)}</div>
			<ReactTable
				data={data.getDriversVerificationRequestsByAdmin
					.filter((driver) => driver.verificationRequests.length > 0)
					.map((verification) => ({
						driverId: (
							<CopyToClipboard text={verification._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{verification._id}
								</span>
							</CopyToClipboard>
						),
						date: moment(verification.createdAt).format('MM/DD/YYYY HH:mm'),
						status:
							verification.verificationRequests.length > 0
								? verification.verificationRequests[verification.verificationRequests.length - 1].status
								: '',
						name: verification.fullName,
						phoneNumber: verification.phoneNumber,
						email: verification.email,
						actions: (
							<div >
								<button
									onClick={() => onSelectItemDetail(verification)}
									className={style.detailIconDiv}
								>
									<span>i</span>
								</button>
								<IconButton
									id={verification._id}
									aria-controls="simple-menu"
									aria-haspopup="true"
									onClick={(e) => {
										handleClick(e, verification._id);
									}}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									open={menuId === verification._id}
									onClose={handleClose}
								>
									<MenuItem onClick={() => onApproveItem(verification)}>Approve</MenuItem>
									<MenuItem onClick={() => onIgnoreItem(verification)}>Reject</MenuItem>
								</Menu>
							</div>
						),
					}))}
				columns={[
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Name</div>
								<CustomInput
									value={nameFilter}
									onChange={(e) => setNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Phone</div>
								<CustomInput
									value={phoneNumberFilter}
									onChange={(e) => setPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone number ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'phoneNumber',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Date{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={statusFilter}
										onClick={(e) => setStatusFilter(e)}
										dropdownList={statusOptions}
									/>
								</div>
							</div>
						),
						accessor: 'status',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getDriversVerificationRequestsCountByAdmin !== 0
							? Math.ceil(countData.getDriversVerificationRequestsCountByAdmin / perPage)
							: 1
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setErrorModal(false);
						}}
						type="notif"
						text={errorText ? errorText : 'Error ! Please Fill All Inputs Correctly And Try Again.'}
					/>
				</DialogContent>
			</Dialog>
			{itemForApprove && (
				<Dialog open={approveModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setApproveModal(false)}
							func={approve}
							text="Do you want to approve this verification?"
						/>
					</DialogContent>
				</Dialog>
			)}
			{itemForIgnore && (
				<Dialog open={ignoreModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<RejectForm
							id={itemForIgnore._id}
							refetch={refetch}
							refetchCount={refetchCount}
							close={() => setIgnoreModal(false)}
							type="driver"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<VerificationDetails close={() => setModal(false)} item={itemDetail} />
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Verifications;
