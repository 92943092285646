import React, { useState } from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import AddShop from "commonComponents/addShop/AddShop";
import style from "./ShopAddShop.module.scss";

const ShopAddShop = (props) => {
  return (
    <div className={style.mainDiv}>
      <GridContainer justify="center">
        <GridItem xs={10} sm={10} md={9} lg={7}>
          <div className={style.whiteCard}>
            <div className={style.header}>
              <div className={style.headerSentence}>Add shop</div>
            </div>
            <AddShop theme="superAdmin" type="shopAdmin" />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ShopAddShop;
