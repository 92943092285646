import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReactTable from 'react-table';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Pagination from 'commonComponents/pagination/Pagination';
import EditItem from 'superAdminViews/setting/defaults/EditItem';
import DialogContent from '@material-ui/core/DialogContent';
import Modal from 'commonComponents/modal/Modal';
import Slide from '@material-ui/core/Slide';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.js';
import style from './CancelTripReason.module.scss';

const ADD_CANCEL_REASON = gql`
	mutation createCanceledTripReasonByAdmin(
		$title: String!
		$by: CanceledTripReasonBy!
		$when: CanceledTripReasonWhen!
		$type: CanceledTripReasonType!
	) {
		createCanceledTripReasonByAdmin(input: { title: $title, by: $by, when: $when, type: $type }) {
			_id
			title
		}
	}
`;

const GET_CANCEL_TRIP_REASON = gql`
	query getCanceledTripReasonsByAdmin(
		$limit: Int
		$skip: Int
		$by: CanceledTripReasonBy
		$when: CanceledTripReasonWhen
	) {
		getCanceledTripReasonsByAdmin(pagination: { limit: $limit, skip: $skip }, filters: { by: $by, when: $when }) {
			_id
			title
			by
			when
			type
		}
	}
`;

const GET_CANCEL_TRIP_REASON_COUNT = gql`
	query getCanceledTripReasonsByAdminCount($by: CanceledTripReasonBy, $when: CanceledTripReasonWhen) {
		getCanceledTripReasonsByAdminCount(filters: { by: $by, when: $when })
	}
`;

const DELETE_CANCEL_TRIP_REASON = gql`
	mutation removeCanceledTripReasonByAdmin($idSet: [ID!]!) {
		removeCanceledTripReasonByAdmin(idSet: $idSet) {
			_id
		}
	}
`;

const CancelTripReason = (props) => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);
	const whenOptions = ['BEFORE_PICK_UP', 'DURING_TRIP'];
	const byOptions = ['DRIVER', 'PASSENGER', 'ADMIN'];
	const typeOptions = ['DELIVERY', 'RIDE'];

	const [text, setText] = useState('');
	const [by, setBy] = useState(byOptions[0]);
	const [when, setWhen] = useState(whenOptions[0]);
	const [type, setType] = useState(typeOptions[0]);

	const [whenFilter, setWhenFilter] = useState('ALL');
	const [byFilter, setByFilter] = useState('ALL');

	const [textError, setTextError] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const [checkAll, setCheckAll] = useState(false);
	const [idsForDelete, setIdsForDelete] = useState([]);

	const [deleteErrorModal, setDeleteErrorModal] = useState(false);
	const [deleteErrorText, setDeleteErrorText] = useState('');

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteSomeModal, setDeleteSomeModal] = useState(false);
	const [itemForDelete, setItemForDelete] = useState([]);

	const [addModal, setAddModal] = useState(false);

	const [modal, setModal] = useState(false);
	const [commentDetails, setCommentDetails] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	useEffect(() => {
		if (data) {
			setCheckAll(true);
			data.getCanceledTripReasonsByAdmin.map((item) => {
				if (!idsForDelete.includes(item._id)) {
					setCheckAll(false);
				}
			});
		}
	}, [data, loading, idsForDelete, pageCount, perPage]);

	// testing menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onDeleteItem = (item) => {
		setDeleteModal(true);
		setItemForDelete(item._id);
	};

	const onDeleteSome = () => {
		setDeleteSomeModal(true);
	};

	const deleteItem = () => {
		removeCanceledTripReasonByAdmin({ variables: { idSet: itemForDelete } })
			.then((res) => {
				setDeleteModal(false);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteModal(false);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const deleteSome = () => {
		removeCanceledTripReasonByAdmin({ variables: { idSet: idsForDelete } })
			.then((res) => {
				setDeleteSomeModal(false);
				setIdsForDelete([]);
				refetch();
			})
			.catch((err) => {
				console.log(err);
				if (err.graphQLErrors && err.graphQLErrors.length > 0) {
					setDeleteSomeModal(false);
					setIdsForDelete([]);
					setDeleteErrorText(err.graphQLErrors[0].message);
					setDeleteErrorModal(true);
				}
			});
	};

	const [
		removeCanceledTripReasonByAdmin,
		{ data: deleteCancelTripReasonData, error: deleteCancelTripReasonError },
	] = useMutation(DELETE_CANCEL_TRIP_REASON);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		by: undefined,
		when: undefined,
	});

	const onSelectCommentDetail = (comment) => {
		setModal(true);
		setCommentDetails(comment);
	};

	const [createCanceledTripReasonByAdmin, { data: addMutationData, error: addMutationError }] = useMutation(
		ADD_CANCEL_REASON
	);

	useEffect(() => {
		if (addMutationData) {
			setAddModal(true);
		}
	}, [addMutationData]);

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getCanceledTripReasonsByAdminCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getCanceledTripReasonsByAdminCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	const { loading, error, data, refetch } = useQuery(GET_CANCEL_TRIP_REASON, {
		variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_CANCEL_TRIP_REASON_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const submitBy = (e) => {
		setByFilter(e);
		if (e !== 'ALL') {
			setVariables((variables) => ({ ...variables, by: e }));
		} else {
			setVariables((variables) => ({ ...variables, by: undefined }));
		}
	};
	const submitWhen = (e) => {
		setWhenFilter(e);
		if (e !== 'ALL') {
			setVariables((variables) => ({ ...variables, when: e }));
		} else {
			setVariables((variables) => ({ ...variables, when: undefined }));
		}
	};

	const onSubmit = () => {
		if (!text) {
			setTextError(true);
		} else {
			setTextError(false);
		}

		if (text) {
			setDisableButton(true);
			createCanceledTripReasonByAdmin({
				variables: { title: text, by, when, type },
			})
				.then((res) => {
					setText('');
					refetch();
					setDisableButton(false);
				})
				.catch((err) => {
					setDisableButton(false);
					console.log(err);
				});
		}
	};

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}
	return (
		<div className={style.mainDiv}>
			<div className={style.inputRow}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.inputContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.inputLabel}>Title:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<CustomInput
										value={text}
										onChange={(e) => setText(e.target.value)}
										error={textError}
										labelText={textError ? "title can't be empty*" : 'title'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={6} md={6} lg={6}>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownLabel}>Type:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={type}
											onClick={(e) => setType(e)}
											dropdownList={typeOptions}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={6} md={6} lg={6}>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownLabel}>By:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={by}
											onClick={(e) => setBy(e)}
											dropdownList={byOptions}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={6} md={6} lg={6}>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownLabel}>When:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={when}
											onClick={(e) => setWhen(e)}
											dropdownList={whenOptions}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.inputButtonContainer}>
							<Button disabled={disableButton} color="yellow" onClick={onSubmit}>
								Add
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			{/** <div className={style.inputRow}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownLabel}>When:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={whenFilter}
											onClick={(e) => submitWhen(e)}
											dropdownList={['ALL', ...whenOptions]}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.dropdownLabel}>By:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.dropdownContainer}>
										<CustomDropdown
											hoverColor="primary"
											buttonText={byFilter}
											onClick={(e) => submitBy(e)}
											dropdownList={['ALL', ...byOptions]}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				</GridContainer>
									</div> **/}
			{idsForDelete.length > 0 && (
				<GridContainer>
					<GridItem xs={12} sm={6} md={3}>
						<Button title="delete selected items" fullWidth color="redButton" onClick={onDeleteSome}>
							Delete
						</Button>
					</GridItem>
				</GridContainer>
			)}

			<ReactTable
				data={data.getCanceledTripReasonsByAdmin.map((comment) => ({
					checkbox: (
						<div className={style.checkboxDivHeader}>
							<Checkbox
								color="default"
								checked={idsForDelete.includes(comment._id)}
								onChange={() => {
									if (idsForDelete.includes(comment._id)) {
										setIdsForDelete((idsForDelete) =>
											idsForDelete.filter((item) => item !== comment._id)
										);
									} else {
										setIdsForDelete([...idsForDelete, comment._id]);
									}
								}}
							/>
						</div>
					),
					text: comment.title,
					by: comment.by,
					when: comment.when,
					actions: (
						<div>
							<IconButton
								id={comment._id}
								aria-controls="simple-menu"
								aria-haspopup="true"
								onClick={(e) => {
									handleClick(e, comment._id);
								}}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={menuId === comment._id}
								onClose={handleClose}
							>
								<MenuItem onClick={() => onSelectCommentDetail(comment)}>edit</MenuItem>
								<MenuItem onClick={() => onDeleteItem(comment)}>delete</MenuItem>
							</Menu>
						</div>
					),
				}))}
				columns={[
					{
						Header: (
							<div className={style.fitTitleDivCheckbox}>
								<Checkbox
									color="default"
									name="Select All"
									checked={checkAll}
									onChange={() => {
										if (checkAll) {
											data.getCanceledTripReasonsByAdmin.map((item) => {
												setIdsForDelete((idsForDelete) =>
													idsForDelete.filter((id) => id !== item._id)
												);
											});
											setCheckAll(false);
										} else {
											setIdsForDelete((idsForDelete) => [
												...idsForDelete,
												...Array.from(data.getCanceledTripReasonsByAdmin, (item) => item._id),
											]);
											setCheckAll(true);
										}
									}}
								/>
							</div>
						),
						accessor: 'checkbox',
						sortable: false,
						filterable: false,
						width: 60,
					},
					{
						Header: <div className={style.fitTitleDiv}>Text</div>,
						accessor: 'text',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>By</div>,
						accessor: 'by',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>When</div>,
						accessor: 'when',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>

			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={Math.ceil(countData.getCanceledTripReasonsByAdminCount / perPage)}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<EditItem
						refetch={() => refetch()}
						close={() => setModal(false)}
						type="cancelTrip"
						comment={commentDetails}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={addModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setAddModal(false)} type="notif" text="Cancel trip reason added succesfully." />
				</DialogContent>
			</Dialog>
			{itemForDelete && (
				<Dialog open={deleteModal} transition={Transition}>
					<DialogContent id="modal-slide-description">
						<Modal
							close={() => setDeleteModal(false)}
							func={deleteItem}
							text="Do you want to delete this item?"
						/>
					</DialogContent>
				</Dialog>
			)}
			<Dialog open={deleteSomeModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setIdsForDelete([]);
							setDeleteSomeModal(false);
						}}
						func={deleteSome}
						text="Do you want to delete these items?"
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={deleteErrorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setDeleteModal(false);
							setDeleteErrorModal(false);
						}}
						type="notif"
						text={
							deleteErrorText ? deleteErrorText : 'Error! please fill all inputs correctly and try again.'
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default CancelTripReason;
