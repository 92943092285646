import React from 'react'

import Finance from 'commonComponents/finance/Finance'

const TotalFinance = props => {
    return (
        <Finance for="total" />
    )
}

export default TotalFinance