import React, { useState } from 'react';
import moment from 'moment';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Close from '@material-ui/icons/Close';
import { graphqlUrl } from 'utils/config';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import style from './TripDetails.module.scss';
import Slide from '@material-ui/core/Slide';

const END_TRIP = gql`
	mutation endTripByAdmin($tripId: ID!) {
		endTripByAdmin(tripId: $tripId) {
			_id
		}
	}
`;

const GET_PASSENGER_TRIP_RECEIPT = gql`
	query getTripReceiptForPassengerByAdmin($tripId: ID!) {
		getTripReceiptForPassengerByAdmin(tripId: $tripId) {
			BaseFare
			Distance
			WaitTimes
			BookingFee
			CarType
			MoreOption
			Promotion
			RequestFromFarPrice
			SubTotal
			HST
			Total
			CancelDescription
		}
	}
`;

const GET_Driver_TRIP_RECEIPT = gql`
	query getTripReceiptForDriverByAdmin($tripId: ID!) {
		getTripReceiptForDriverByAdmin(tripId: $tripId) {
			driverTotalPrice
			tripDistance
			holdTimesInMinutes
			tripTimeInMinutes
			cancelReason
		}
	}
`;

const TripDetails = ({ trip, close, refetch }) => {
	const alternates = {
		IN_HURRY: 'in hurry',
		TRIP_BAGS_WITH_ME: 'bags with me',
		RESERVED: 'reserved',
		TRIP_PET_WITH_CARRIER: 'has pet with carrier',
		TRIP_PET_WITHOUT_CARRIER: 'has pet without carrier',
		TRIP_WITH_INFANT: 'with infant',
		TRIP_WELCOME_SIGN: 'selcome sign',
		TRIP_AIR_CONDITIONER: 'air conditioner',
		TRIP_DRIVER_ASSISTANT: 'driver assistant',
		WAIT_TIMES_IN_MINUTES: 'static wait time',
		DOOR_TO_DOOR_IN_BUILDING: 'door to door in building',
		ACCOMPANY_PARCEL: 'accompany parcel',
		PARCEL_PACKED: 'parcel packed',
		SIGNITURE_NEEDED: 'signiture needed',
		ID_NEEDED: 'id needed',
		ORDERING_FOR_SOMEONE_ELSE: 'ordering for someone else',
	};
	const [showOptionsDetails, setShowOptionsDetails] = useState(false);
	const profileImages = [`${graphqlUrl}${trip.staticMapImageUrl}`];
	const [isProfileImagesOpen, setIsProfileImagesOpen] = useState(false);
	const [profileImageIndex, setProfileImageIndex] = useState(0);

	const [modal, setModal] = useState(false);
	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [endTripByAdmin, { data: mutationData, error: mutationError }] = useMutation(END_TRIP);

	const { data, error, loading } = useQuery(GET_PASSENGER_TRIP_RECEIPT, {
		variables: { tripId: trip._id },
	});

	const endTrip = () => {
		endTripByAdmin({ variables: { tripId: trip._id } })
			.then((res) => {
				setModal(false);
				close();
				refetch();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const { data: driverData, error: driverError, loading: driverLoading } = useQuery(GET_Driver_TRIP_RECEIPT, {
		variables: { tripId: trip._id },
	});

	if (loading || driverLoading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error || driverError) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Trip details</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Trip ID:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{trip._id}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Driver name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{trip.driver && trip.driver.fullName}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Driver phone number:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{trip.driver && trip.driver.phoneNumber}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Passenger name:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{trip.passengerDetail.fullName}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Passenger phone number:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{trip.passengerDetail.phoneNumber}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.label}>Rate:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									<div className={style.value}>{trip.rate ? trip.rate : '-'}</div>
								</GridItem>
							</GridContainer>
						</div>
						{trip.staticMapImageUrl && (
							<div className={style.rowContainer}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<div className={style.label}>Map:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={6} lg={6}>
										<div className={style.value}>
											<img
												onClick={() => setIsProfileImagesOpen(true)}
												className={style.profileImage}
												src={`${graphqlUrl}${trip.staticMapImageUrl}`}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Tip value:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>${trip.tipValue}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Wait time price:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>${trip.waitTimePrice}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Options price:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>${trip.optionsPrice}</div>
								</GridItem>
							</GridContainer>
						</div>
						{trip.optionsPriceDetails.length > 0 && (
							<div>
								{!showOptionsDetails && (
									<div className={style.toggleDiv}>
										<button onClick={() => setShowOptionsDetails(true)}>
											<KeyboardArrowDownIcon />{' '}
											<div className={style.buttonSentence}>show options details</div>
										</button>
									</div>
								)}
								{showOptionsDetails && (
									<div className={style.toggleDiv}>
										<button onClick={() => setShowOptionsDetails(false)}>
											<KeyboardArrowUpIcon />
											<div className={style.buttonSentence}>hide options details</div>
										</button>
									</div>
								)}
							</div>
						)}
						{showOptionsDetails &&
							trip.optionsPriceDetails.map((item) => (
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>
												{!isNaN(item.option[0])
													? `parcel ${item.option}`
													: alternates[item.option]
													? alternates[item.option]
													: item.option}
												:
											</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>${item.price}</div>
										</GridItem>
									</GridContainer>
								</div>
							))}
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Trip type:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{trip.tripType}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Cost:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>${trip.cost}</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Distance in km:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>{trip.tripDistance} Km</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.label}>Date:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={6} lg={6}>
									<div className={style.value}>
										{moment(trip.createdAt).format('MM/DD/YYYY HH:mm')}
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.rowContainer}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4} lg={4}>
									<div className={style.label}>State:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8} lg={8}>
									<div className={style.value}>{trip.state.replace(/_/g, ' ').toLowerCase()}</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} lg={6}>
						<div>
							<div>Passenger receipt</div>
							<div className={style.receiptContainer}>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Base fare:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												${data.getTripReceiptForPassengerByAdmin.BaseFare}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Distance in km:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												{data.getTripReceiptForPassengerByAdmin.Distance}km
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Wait times:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												{data.getTripReceiptForPassengerByAdmin.WaitTimes}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Booking fee:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												${data.getTripReceiptForPassengerByAdmin.BookingFee}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Car type:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												${data.getTripReceiptForPassengerByAdmin.CarType}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>More options:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												${data.getTripReceiptForPassengerByAdmin.MoreOption}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Promotion:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												${data.getTripReceiptForPassengerByAdmin.Promotion}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Search area expansion:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												${data.getTripReceiptForPassengerByAdmin.RequestFromFarPrice}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Sub total:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												${data.getTripReceiptForPassengerByAdmin.SubTotal}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>HST:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												${data.getTripReceiptForPassengerByAdmin.HST}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Total:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												${data.getTripReceiptForPassengerByAdmin.Total}
											</div>
										</GridItem>
									</GridContainer>
								</div>

								{
									(data.getTripReceiptForPassengerByAdmin.CancelDescription) && (<div className={style.rowContainer}>
										<GridContainer>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<div className={style.label}>Cancel Description:</div>
											</GridItem>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<div className={style.value}>
													{data.getTripReceiptForPassengerByAdmin.CancelDescription}
												</div>
											</GridItem>
										</GridContainer>
									</div>)
								}
								


								
							</div>
						</div>
						<div>
							<div>Driver receipt</div>
							<div className={style.receiptContainer}>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Total price:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												${driverData.getTripReceiptForDriverByAdmin.driverTotalPrice}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Distance in km:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												{driverData.getTripReceiptForDriverByAdmin.tripDistance}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Hold time in minutes:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												{driverData.getTripReceiptForDriverByAdmin.holdTimesInMinutes}
											</div>
										</GridItem>
									</GridContainer>
								</div>
								<div className={style.rowContainer}>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.label}>Trip time in minutes:</div>
										</GridItem>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<div className={style.value}>
												{driverData.getTripReceiptForDriverByAdmin.tripTimeInMinutes}
											</div>
										</GridItem>
									</GridContainer>
								</div>






								{
									(driverData.getTripReceiptForDriverByAdmin.cancelReason) && (<div className={style.rowContainer}>
										<GridContainer>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<div className={style.label}>Cancel Description:</div>
											</GridItem>
											<GridItem xs={12} sm={6} md={6} lg={6}>
												<div className={style.value}>
													{driverData.getTripReceiptForDriverByAdmin.cancelReason}
												</div>
											</GridItem>
										</GridContainer>
									</div>)
								}


								



							</div>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			{isProfileImagesOpen && (
				<Lightbox
					reactModalStyle={{ zIndex: '5000' }}
					mainSrc={profileImages[profileImageIndex]}
					nextSrc={profileImages[(profileImageIndex + 1) % profileImages.length]}
					prevSrc={profileImages[(profileImageIndex + profileImages.length - 1) % profileImages.length]}
					onCloseRequest={() => setIsProfileImagesOpen(false)}
					onMovePrevRequest={() =>
						setProfileImageIndex((profileImageIndex + profileImages.length - 1) % profileImages.length)
					}
					onMoveNextRequest={() => setProfileImageIndex((profileImageIndex + 1) % profileImages.length)}
				/>
			)}
		</div>
	);
};

export default TripDetails;
