import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ErrorPage from 'commonComponents/errorPage/ErrorPage'
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

import AddAdmin from "superAdminViews/admins/addAdmin/AddAdmin";

const GET_ADMIN = gql`
  query getAdminByAdmin($id: ID!) {
    getAdminByAdmin(id: $id) {
      _id
      fullName
      phoneNumber
      email
    #   password
      roles{
          id 
          name
      }
      shop {
        _id
      }
      type
      state
    }
  }
`;

const EditAdmin = (props) => {
  const { loading, error, data } = useQuery(GET_ADMIN, {
    variables: { id: props.match.params.id },
  });

  if (error) {
    console.log(error);
  }

  if (loading)
    return (
      <div style={{ textAlign: "center", marginTop: "200px" }}>
        <BeatLoader
          css={css`
            display: block;
            margin: 0 auto;
            border-color: red;
          `}
          size={15}
          margin={2}
          color={"gray"}
          loading={true}
        />
      </div>
    );
  if (error) {
    console.log(error);
    return <ErrorPage/>;
  }

  return <AddAdmin admin={data.getAdminByAdmin} />;
};

export default EditAdmin;
